import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { Clipboard } from '@keboola/design';

import { MetadataKeys } from '@/modules/components/MetadataConstants';
import type { StoreSource } from '@/modules/stream/store';

export const DataStreamIcon = (props: { table: Map<string, any>; sources: StoreSource[] }) => {
  const sourceId = props.table
    .get('metadata', List())
    .find((row: Map<string, any>) => row.get('key') === MetadataKeys.STREAM_SOURCE_ID, null, Map())
    .get('value');

  const sourceUrl = props.sources.find((source) => source.sourceId === sourceId)?.http?.url;

  if (!sourceUrl) {
    return null;
  }

  return (
    <Clipboard
      inline
      stopEventPropagation
      showIcon={false}
      className="tw-ml-2 hover:tw-text-secondary-500"
      tooltipPlacement="top"
      tooltipText="Copy Data Stream URL"
      label={<FontAwesomeIcon icon={['fas', 'webhook']} />}
      text={sourceUrl}
    />
  );
};
