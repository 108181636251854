import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import type { NodeData } from '@/modules/lineage/rfTypes';

type GraphStore = {
  focusedNodeId: string | null;
  searchQuery: string;
  onSearch: (newQuery: string) => void;
  setNodeFocus: (nodeId: string | null) => void;
  nodeDetail: NodeData | null;
  setNodeDetail: (node: NodeData | null) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

export const useGraphStore = create<GraphStore>()(
  devtools(
    (set) => ({
      searchQuery: '',
      focusedNodeId: null,
      nodeDetail: null,
      isLoading: true,
      onSearch: (searchQuery) => set(() => ({ searchQuery }), void 0, 'onSearch'),
      setNodeFocus: (focusedNodeId) => set(() => ({ focusedNodeId }), void 0, 'setNodeFocus'),
      setNodeDetail: (nodeDetail) => set(() => ({ nodeDetail }), void 0, 'setNodeDetail'),
      setIsLoading: (isLoading) => set(() => ({ isLoading }), void 0, 'setIsLoading'),
    }),
    { name: 'Lineage graph' },
  ),
);
