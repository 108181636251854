import React from 'react';

import { Badge } from '@keboola/design';

import ApplicationStore from '@/stores/ApplicationStore';

const NativeTypesLabel = (props: { isTyped: boolean; className?: string }) => {
  if (!props.isTyped) {
    return null;
  }

  return (
    <Badge variant="purple-inverse" className={props.className} placement="right">
      {ApplicationStore.hasNewNativeTypes() ? 'Auto-Typed' : 'Native Types'}
    </Badge>
  );
};

export default NativeTypesLabel;
