import BluebirdPromise from 'bluebird';

import type { AgentMessage, AutomationDetail, UserMessage } from '@/api/routes/aiService';
import Dispatcher from '@/Dispatcher';
import * as AiApi from '@/modules/ai/api';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { prepareBranchHref } from '@/modules/dev-branches/helpers';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { actionTypes, routeNames } from './constants';
import { constructMessage } from './helpers';

const createAutomation = (initialMessage?: string) => {
  const resolveWhenReady = (automation: AutomationDetail): BluebirdPromise<AutomationDetail> => {
    if (!automation.id) throw new Error('Automation creation failed');

    return AiApi.getAutomation(automation.id).then((response) => {
      if (response.status === 'provisioningSandbox' || response.status === 'ready') return response;
      if (response.status === 'failed') throw new Error('Automation creation failed');
      return BluebirdPromise.delay(500).then(() => resolveWhenReady(response));
    });
  };

  return AiApi.createAutomation()
    .then(resolveWhenReady)
    .tap((automation) => {
      if (!automation.id) return;

      return sendMessage(automation.id, constructMessage({ text: initialMessage }));
    });
};

const redirectToAutomation = (automation: AutomationDetail) => {
  if (!automation.id) return;

  const branchId = parseInt(automation.branchId ?? '', 10);

  if (!branchId || (DevBranchesStore.getCurrentId() as number | null) === branchId) {
    RoutesStore.getRouter().transitionTo(routeNames.DETAIL, { id: automation.id });
    return;
  }

  window.location.href = prepareBranchHref(
    ApplicationStore.getProjectBaseUrl(),
    RoutesStore.getRouter().createHref(routeNames.DETAIL, { id: automation.id }),
    branchId,
    DevBranchesStore.getCurrentId(),
  );
};

const loadAutomations = () => {
  return AiApi.getAutomations().then((automations) => {
    Dispatcher.handleViewAction({ type: actionTypes.LOAD_AUTOMATIONS, automations });
  });
};

const loadAutomation = (id: string) => {
  return AiApi.getAutomation(id).then((automation) => {
    Dispatcher.handleViewAction({ type: actionTypes.LOAD_AUTOMATION, automation });

    return automation;
  });
};

const deleteAutomation = (id: string) => {
  return AiApi.deleteAutomation(id).then(() => {
    Dispatcher.handleViewAction({ type: actionTypes.DELETE_AUTOMATION, id });
  });
};

const sendMessage = (automationId: string, messageObject: UserMessage) => {
  return AiApi.sendMessage(automationId, messageObject).tap((response) =>
    processMessageResponse(automationId, response),
  );
};

const processMessageResponse = (automationId: string, response: AgentMessage) => {
  switch (response.type) {
    case 'flowGenerated':
      return Promise.all([
        InstalledComponentsActionCreators.loadInstalledComponentsForce({
          include: 'configuration',
        }),
        loadAutomation(automationId),
      ]);

    default:
      return;
  }
};

const getAutomationMessages = (automationId: string) => {
  return AiApi.getAutomationMessages(automationId).then((response) => response.messages);
};

export {
  createAutomation,
  redirectToAutomation,
  loadAutomation,
  loadAutomations,
  deleteAutomation,
  sendMessage,
  getAutomationMessages,
};
