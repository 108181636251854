import React, { useState } from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';
import Switch from 'rc-switch';

import Form from '@/react/common/KeyValueBox/Form';
import string from '@/utils/string';

const KBC_TOKEN = 'kbc_token';
const KBC_URL = 'kbc_url';
const RESERVED_KEYWORDS = [KBC_TOKEN, KBC_URL];

const getDefaultName = (name?: string) => string.ltrim(name || '', '#');

type Props = {
  name?: string;
  value?: string;
  secrets: Map<string, string>;
  onSave: (name: string, value: string) => Promise<void>;
  onReset: () => void;
  isSaving: boolean;
  readOnly: boolean;
};

const SecretForm = (props: Props) => {
  const [isEncrypted, setIsEncrypted] = useState(false);

  const isNameDuplicate = (newName: string) => {
    if (props.name === newName) {
      return false;
    }

    return props.secrets.some((value, name) => {
      return name !== props.name && string.ltrim(name || '', '#') === newName;
    });
  };

  const isEditingEncryptedSecret = props.name?.startsWith('#');

  const includesReservedKeywords = (name: string) => {
    return RESERVED_KEYWORDS.includes(name.toLowerCase());
  };

  const getErrorMessage = (name: string) => {
    if (!includesReservedKeywords(name)) {
      return null;
    }

    switch (name.toLowerCase()) {
      case KBC_TOKEN:
        return (
          <div className="tw-w-max tw-max-w-md">
            Secret with this name is automatically generated upon deployment, but it is not
            available for use in your application.
          </div>
        );

      case KBC_URL:
        return (
          <div className="tw-w-max tw-max-w-lg">
            Secret with this name is automatically generated upon deployment, and you can use it in
            your application. It represents your project location, such as connection.keboola.com.
          </div>
        );

      default:
        return 'Secret with this name is created automatically upon deploy.';
    }
  };

  return (
    <Form
      onSubmit={(name, value) => {
        const encrypted = isEncrypted || isEditingEncryptedSecret;

        props.onSave(`${encrypted ? '#' : ''}${name}`, value).then(props.onReset);
      }}
      isSaving={props.isSaving}
      checkDuplicate={isNameDuplicate}
      checkDisabled={includesReservedKeywords}
      onReset={props.onReset}
      defaultKey={getDefaultName(props.name)}
      defaultValue={props.value}
      nameAddition={isEncrypted || isEditingEncryptedSecret ? '#' : ''}
      valueInputType={isEditingEncryptedSecret ? 'password' : 'text'}
      additionalInput={
        !isEditingEncryptedSecret ? (
          <FormGroup className="font-sans flex-container flex-start inline-flex clickable mb-0 ml-1">
            <Switch
              prefixCls="switch"
              className="btn-icon wider"
              checked={isEncrypted}
              onChange={(isEncrypted) => setIsEncrypted(isEncrypted)}
            />
            <ControlLabel
              className="clickable text-muted f-13 mb-0"
              onClick={() => setIsEncrypted((prevState) => !prevState)}
            >
              Encrypted
            </ControlLabel>
          </FormGroup>
        ) : null
      }
      getAdditionalErrorMessage={getErrorMessage}
      allowEmptyValue
    />
  );
};

export default SecretForm;
