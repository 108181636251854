import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '@keboola/design';

import Loader from './Loader';

const RefreshIcon = (props: {
  isLoading: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
}) => {
  if (props.isLoading) {
    return <Loader />;
  }

  return (
    <FontAwesomeIcon
      fixedWidth
      icon="arrows-rotate"
      title={!!props.onClick ? 'Refresh' : ''}
      className={cn({ clickable: !!props.onClick })}
      onClick={props.onClick}
    />
  );
};

export default RefreshIcon;
