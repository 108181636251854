import React from 'react';
import type { ReactNode } from 'react';

import { buttonStyles } from './styles';
import type { CommonButtonProps } from './types';

type ButtonProps = {
  children: ReactNode;
  onClick?: (e: React.MouseEvent) => void;
};

export const Button = ({
  children,
  onClick,
  size = 'medium',
  variant = 'primary',
  disabled = false,
  className,
  type = 'button',
  ...buttonProps
}: CommonButtonProps &
  ButtonProps &
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  const styles = buttonStyles({ variant, size, disabled, className });
  return (
    <button {...buttonProps} type={type} onClick={onClick} disabled={disabled} className={styles}>
      {children}
    </button>
  );
};
