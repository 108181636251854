import React from 'react';
import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { findCreatedFromMetadata, getFolderFromMetadata } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { mergeConfigurations } from '@/modules/sandboxes/helpers';
import { routeNames as transformationRoutes } from '@/modules/transformations-v2/constants';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import { folderLabel } from '@/react/common/selectLabels';
import RoutesStore from '@/stores/RoutesStore';

type Props = {
  config: Map<string, any>;
  metadata: Map<string, any>;
  transformationComponent: Map<string, any>;
  existingTransformations: Map<string, any>;
  show: boolean;
  onHide: () => void;
};

const UpdateTransformationModal = (props: Props) => {
  const [tempData, setTempData] = React.useState({
    transformation: '',
    preserve: true,
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const prepareOptions = React.useMemo(() => {
    return props.existingTransformations
      .map((transformation) => {
        const folder = getFolderFromMetadata(
          props.metadata.getIn([props.transformationComponent.get('id'), transformation.get('id')]),
        );

        return {
          value: transformation.get('id'),
          label: folderLabel(transformation.get('name'), folder),
          name: `${folder} ${transformation.get('name')}`,
          hasFolder: !!folder,
        };
      })
      .sort((rowA, rowB) => {
        if (rowA.hasFolder && !rowB.hasFolder) return -1;
        if (!rowA.hasFolder && rowB.hasFolder) return 1;

        return rowA.name.localeCompare(rowB.name);
      })
      .toArray();
  }, [props.existingTransformations, props.metadata, props.transformationComponent]);

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.SyntheticEvent<Element, Event>,
  ) => {
    e.preventDefault();

    const transformation = props.existingTransformations.get(tempData.transformation);
    const data = tempData.preserve
      ? mergeConfigurations(
          transformation.get('configuration', Map()),
          props.config.get('configuration', Map()),
          { skipPackages: true },
        )
      : transformation
          .get('configuration', Map())
          .set('storage', props.config.getIn(['configuration', 'storage'], Map()));

    setIsLoading(true);
    return InstalledComponentsActionCreators.updateComponentConfiguration(
      props.transformationComponent.get('id'),
      transformation.get('id'),
      {
        configuration: JSON.stringify(data),
      },
      `Use mapping from "${props.config.get('name')}" workspace`,
    )
      .then(() => {
        RoutesStore.getRouter().transitionTo(transformationRoutes.GENERIC_TRANSFORMATION_CONFIG, {
          component: props.transformationComponent.get('id'),
          config: transformation.get('id'),
        });
      })
      .catch((error) => {
        setIsLoading(false);
        throw error;
      });
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onEnter={() => {
        const data = findCreatedFromMetadata(
          KEBOOLA_SANDBOXES,
          props.config.get('id'),
          props.metadata,
        );

        const optionExists = props.existingTransformations.has(data?.configurationId);

        setTempData((prevValue) => ({
          ...prevValue,
          transformation: optionExists ? data.configurationId : '',
        }));
      }}
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Select Existing Transformation</Modal.Title>
          <ModalIcon icon="gear" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Transformation</ControlLabel>
            <Select
              options={prepareOptions}
              value={tempData.transformation}
              onChange={(value: string) => {
                setTempData((prevValue) => ({
                  ...prevValue,
                  transformation: value,
                }));
              }}
              placeholder={
                !props.existingTransformations.isEmpty()
                  ? 'Select transformation'
                  : 'No transformation created yet'
              }
              disabled={props.existingTransformations.isEmpty()}
            />
          </FormGroup>
          <FormGroup>
            <Checkbox
              checked={tempData.preserve}
              onChange={(checked) => {
                setTempData((prevValue) => ({
                  ...prevValue,
                  preserve: checked,
                }));
              }}
              disabled={props.existingTransformations.isEmpty()}
            >
              Merge input and output mappings from the workspace to the transformation
            </Checkbox>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel={isLoading ? 'Updating Transformation...' : 'Use Selected Transformation'}
            isSaving={isLoading}
            onSave={handleSubmit}
            isDisabled={!tempData.transformation || isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default UpdateTransformationModal;
