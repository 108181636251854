import React from 'react';
import { Button } from 'react-bootstrap';

import { Tooltip } from '@keboola/design';

import ConfirmModal from './ConfirmModal';
import Loader from './Loader';

const ResetAutomaticLoadTypeButton = ({ onClick }: { onClick: () => Promise<void> }) => {
  const [isPending, setIsPending] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const button = (
    <Tooltip
      tooltip="Clears information about automatic incremental load."
      placement="top"
      type="explanatory"
    >
      {isPending ? (
        <Button bsStyle="link" className="btn-link-inline disabled">
          Reset <Loader />
        </Button>
      ) : (
        <Button bsStyle="link" className="btn-link-inline" onClick={() => setShowModal(true)}>
          Reset
        </Button>
      )}
    </Tooltip>
  );

  const clearState = () => {
    setIsPending(true);
    return onClick().catch((error) => {
      setIsPending(false);
      throw error;
    });
  };

  return (
    <>
      {button}
      <ConfirmModal
        closeAfterResolve
        show={showModal}
        onHide={() => setShowModal(false)}
        icon="arrow-rotate-left"
        title="Reset automatic incremental load"
        text={
          <p>
            This will clear the information about automatic incremental load. Next execution will
            load all data in the table.
          </p>
        }
        buttonLabel="Reset automatic incremental load"
        buttonType="success"
        onConfirm={clearState}
        isLoading={isPending}
      />
    </>
  );
};

export default ResetAutomaticLoadTypeButton;
