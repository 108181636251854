import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { Card, FormGroup, HelpBlock, Label, TextInput, Tooltip } from '@keboola/design';

import { KDS_TEAM_WR_EXASOL, KDS_TEAM_WR_FIREBOLT } from '@/constants/componentIds';
import { getHostnameValue, hasSshTunnel, hasSsl } from '@/modules/wr-db/helpers';
import { getComponentFields } from '@/modules/wr-db/templates/credentialsFields';
import hasValidCredentials from '@/modules/wr-db/templates/hasValidCredentials';
import PasswordControl from '@/react/common/PasswordControl';
import SaveButtons from '@/react/common/SaveButtons';
import Select from '@/react/common/Select';
import SshForm from '@/react/common/SshForm';
import SSLForm from '@/react/common/SSLForm';
import TestCredentialsButtonGroup from '@/react/common/TestCredentialsButtonGroup';
import string from '@/utils/string';
import ProvisionedCredentials from './ProvisionedCredentials';

const FORM_GROUP_CLASS_NAMES = 'tw-grid tw-grid-cols-3 tw-items-baseline tw-gap-4';

const CredentialsForm = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool.isRequired,
    credentials: PropTypes.instanceOf(Map).isRequired,
    globalCredentails: PropTypes.instanceOf(Map).isRequired,
    savedCredentials: PropTypes.instanceOf(Map).isRequired,
    onChangeFn: PropTypes.func.isRequired,
    changeCredentialsFn: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isProvisioningCredentials: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    testCredentialsFn: PropTypes.func.isRequired,
    cancelEditingFn: PropTypes.func.isRequired,
    saveCredentialsFn: PropTypes.func.isRequired,
    componentSupportsProvisionedCredentials: PropTypes.bool.isRequired,
    isWorkspaceCredentials: PropTypes.bool.isRequired,
    forgetPasswordFn: PropTypes.func.isRequired,
    approvedHostnames: PropTypes.instanceOf(List),
    credentialsType: PropTypes.string,
  },

  render() {
    return (
      <Card>
        {this.props.componentSupportsProvisionedCredentials && (
          <Card.Header>
            <Card.Title>
              {this.hasCredentialsProvidedByKeboola()
                ? 'Keboola provided database credentials'
                : 'User specified database credentials'}
            </Card.Title>
          </Card.Header>
        )}

        <Card.Content className="tw-flex tw-flex-col tw-gap-4">
          {this.renderSaveButtons()}
          {this.renderCredentials()}
          {this.renderSshTunnelRow()}
          {this.renderSSLForm()}
          {this.renderTestCredentialsButton()}
        </Card.Content>
      </Card>
    );
  },

  hasCredentialsProvidedByKeboola() {
    return this.props.isProvisioningCredentials || this.props.isWorkspaceCredentials;
  },

  renderSaveButtons() {
    if (this.props.readOnly || this.hasCredentialsProvidedByKeboola()) {
      return null;
    }

    return (
      <div className="save-buttons">
        <SaveButtons
          isSaving={this.props.isSaving}
          isChanged={!this.props.savedCredentials.toMap().equals(this.props.credentials.toMap())}
          disabled={
            this.props.isSaving ||
            !hasValidCredentials(this.props.componentId, this.props.credentials)
          }
          onReset={this.props.cancelEditingFn}
          onSave={this.props.saveCredentialsFn}
        />
      </div>
    );
  },

  renderCredentials() {
    if (this.hasCredentialsProvidedByKeboola()) {
      return (
        <ProvisionedCredentials
          readOnly={this.props.readOnly}
          credentials={this.props.credentials}
          componentId={this.props.componentId}
          isWorkspaceCredentials={this.props.isWorkspaceCredentials}
          resetWorkspacePasswordFn={this.props.resetWorkspacePasswordFn}
          forgetPasswordFn={this.props.forgetPasswordFn}
        />
      );
    }

    if (!this.props.isEditing) {
      return null;
    }

    let componentFields = getComponentFields(this.props.componentId);

    if (this.props.componentId === KDS_TEAM_WR_EXASOL) {
      componentFields = componentFields.filter(({ name }) =>
        this.props.credentialsType === 'saas' || this.props.credentials.has('#refresh_token')
          ? name !== '#password'
          : name !== '#refresh_token',
      );
    }

    return componentFields.map(this.createInput);
  },

  renderSshTunnelRow() {
    if (!hasSshTunnel(this.props.componentId) || this.hasCredentialsProvidedByKeboola()) {
      return null;
    }

    return (
      <SshForm
        readOnly={this.props.readOnly}
        onChange={(newSshData) => {
          return this.props.changeCredentialsFn(this.props.credentials.set('ssh', newSshData));
        }}
        data={this.props.credentials.get('ssh') || Map()}
        globalData={this.props.globalCredentails.get('ssh', Map())}
        isEnabled={this.props.isEditing}
      />
    );
  },

  renderSSLForm() {
    if (!hasSsl(this.props.componentId) || this.hasCredentialsProvidedByKeboola()) {
      return null;
    }

    return (
      <SSLForm
        readOnly={this.props.readOnly}
        componentId={this.props.componentId}
        isEditing={this.props.isEditing}
        data={this.props.credentials.get('ssl', Map())}
        globalData={this.props.globalCredentails.get('ssl', Map())}
        onChange={(sslObject) => {
          return this.props.changeCredentialsFn(this.props.credentials.set('ssl', sslObject));
        }}
      />
    );
  },

  renderTestCredentialsButton() {
    if (
      this.props.readOnly ||
      this.hasCredentialsProvidedByKeboola() ||
      this.props.componentId === KDS_TEAM_WR_FIREBOLT ||
      this.props.componentId === KDS_TEAM_WR_EXASOL
    ) {
      return null;
    }

    return (
      <TestCredentialsButtonGroup
        testCredentialsFn={() => {
          return this.props.testCredentialsFn(this.props.credentials);
        }}
        componentId={this.props.componentId}
        configId={this.props.configId}
        isEditing={this.props.isEditing}
        disabled={!hasValidCredentials(this.props.componentId, this.props.credentials)}
      />
    );
  },

  createInput(field) {
    let fieldName = field.name;
    let isHashed = fieldName.startsWith('#');

    if (this.props.isProvisioningCredentials && isHashed) {
      fieldName = fieldName.slice(1, fieldName.length);
      isHashed = false;
    }

    const value = this.props.credentials.get(fieldName);
    const defaultValue = this.props.credentials.get(fieldName, field.defaultValue);

    const selected = this.props.approvedHostnames?.find((option) => {
      if (!option.get('port')) {
        return `${option.get('host')}` === `${this.props.credentials.get('host')}`;
      }
      return (
        `${option.get('host')}:${option.get('port')}` ===
        `${this.props.credentials.get('host')}:${this.props.credentials.get('port')}`
      );
    });

    if (this.props.approvedHostnames && field.name === 'host') {
      return (
        <FormGroup key={fieldName} className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="hostname">
            Hostname{selected && selected?.get('port') ? '/Port' : ''}
          </Label>
          <Select
            id="hostname"
            className="tw-col-span-2"
            clearable={false}
            placeholder="Select host"
            value={getHostnameValue(selected?.get('host'), selected?.get('port'))}
            options={this.props.approvedHostnames
              .map((option) => {
                const value = getHostnameValue(option.get('host'), option.get('port'));
                return { value, label: value };
              })
              .toArray()}
            onChange={(value) => {
              const option = this.props.approvedHostnames.find((option) => {
                return getHostnameValue(option.get('host'), option.get('port')) === value;
              });

              const newCredentials = this.props.credentials.set('host', option.get('host'));
              this.props.changeCredentialsFn(
                option.get('port')
                  ? newCredentials.set('port', option.get('port'))
                  : newCredentials,
              );
            }}
            disabled={this.isDisabled(fieldName)}
          />
        </FormGroup>
      );
    } else if (this.props.approvedHostnames && field.name === 'port' && selected?.get('port')) {
      return null;
    }

    if (isHashed) {
      return (
        <FormGroup key={fieldName} className={FORM_GROUP_CLASS_NAMES}>
          <Label
            htmlFor={string.webalize(field.label)}
            className="tw-inline-flex tw-items-center tw-gap-1"
          >
            {field.label}{' '}
            <Tooltip
              placement="top"
              type="explanatory"
              tooltip={`${field.label} will be stored securely encrypted.`}
            >
              <small>
                <FontAwesomeIcon icon="circle-question" fixedWidth />
              </small>
            </Tooltip>
          </Label>

          <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
            <PasswordControl
              id={string.webalize(field.label)}
              value={value}
              disabled={this.isDisabled(fieldName)}
              onChange={(event) => this.props.onChangeFn(fieldName, event.target.value)}
            />
            <HelpBlock>{field.help}</HelpBlock>
          </div>
        </FormGroup>
      );
    }

    switch (field.type) {
      case 'select':
        return (
          <FormGroup key={fieldName} className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor={string.webalize(field.label)}>{field.label}</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <Select
                id={string.webalize(field.label)}
                clearable={false}
                value={value || defaultValue}
                options={field.options}
                onChange={(value) => this.props.onChangeFn(fieldName, value)}
                disabled={this.isDisabled(fieldName)}
              />
              <HelpBlock>{field.help}</HelpBlock>
            </div>
          </FormGroup>
        );

      case 'textarea':
        return (
          <FormGroup key={fieldName} className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor={string.webalize(field.label)}>{field.label}</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <Textarea
                id={string.webalize(field.label)}
                minRows={4}
                className="form-control"
                disabled={this.isDisabled(fieldName)}
                value={value || defaultValue}
                onChange={(event) => this.props.onChangeFn(fieldName, event.target.value)}
              />
              <HelpBlock>{field.help}</HelpBlock>
            </div>
          </FormGroup>
        );
      case 'text':
        return (
          <FormGroup key={fieldName} className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor={string.webalize(field.label)}>{field.label}</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <TextInput
                id={string.webalize(field.label)}
                variant="secondary"
                disabled={this.isDisabled(fieldName)}
                value={value || defaultValue}
                onChange={(value) => this.props.onChangeFn(fieldName, value)}
              />
              <HelpBlock className="tw-mt-1">{field.help}</HelpBlock>
            </div>
          </FormGroup>
        );

      default:
        return (
          <FormGroup key={fieldName} className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor={string.webalize(field.label)}>{field.label}</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <FormControl
                id={string.webalize(field.label)}
                type={field.type}
                disabled={this.isDisabled(fieldName)}
                value={value || defaultValue}
                onChange={(event) => {
                  const value =
                    !!event.target.value.length && field.type === 'number'
                      ? parseInt(event.target.value, 10)
                      : event.target.value;

                  this.props.onChangeFn(fieldName, value);
                }}
              />
              <HelpBlock className="tw-mt-1">{field.help}</HelpBlock>
            </div>
          </FormGroup>
        );
    }
  },

  isDisabled(fildName) {
    if (this.props.isDisabled || this.props.readOnly) {
      return true;
    }

    return this.props.globalCredentails.has(fildName);
  },
});

export default CredentialsForm;
