import { List, type Map } from 'immutable';

import {
  KEBOOLA_EX_AWS_S_3,
  KEBOOLA_EX_GOOGLE_ANALYTICS_V_4,
  KEBOOLA_EX_GOOGLE_DRIVE,
  KEBOOLA_EX_HTTP,
} from '@/constants/componentIds';
import { FEATURE_HAS_SIMPLIFIED_COMPONENTS } from '@/constants/features';
import { features as componentFeatures, uiModes } from '@/modules/components/Constants';
import ApplicationStore from '@/stores/ApplicationStore';
import { getFields } from './credentials';

const hasVerifiedSimplifiedUI = (componentId: string): boolean => {
  if (!!getFields(componentId)) {
    return true;
  }

  return [
    KEBOOLA_EX_GOOGLE_DRIVE,
    KEBOOLA_EX_HTTP,
    KEBOOLA_EX_AWS_S_3,
    KEBOOLA_EX_GOOGLE_ANALYTICS_V_4,
  ].includes(componentId);
};

const supportsSimplifiedUi = (component: Map<string, any>) => {
  if (!component.get('features', List()).includes(componentFeatures.HAS_SIMPLIFIED_UI))
    return false;

  return (
    hasVerifiedSimplifiedUI(component.get('id')) ||
    (ApplicationStore.hasCurrentProjectFeature(FEATURE_HAS_SIMPLIFIED_COMPONENTS) as boolean)
  );
};

const shouldShowSimplifiedUi = (component: Map<string, any>, configuration: Map<string, any>) => {
  if (!supportsSimplifiedUi(component)) return false;

  return configuration.getIn(['runtime', 'uiMode']) === uiModes.SIMPLE;
};

export { supportsSimplifiedUi, shouldShowSimplifiedUi };
