import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { fromJS } from 'immutable';

import { Button, ButtonGroup } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { isInternal } from '@/constants/helpers';
import { routeNames } from '@/modules/settings/constants';
import TokensActions from '@/modules/tokens/actionCreators';
import RefreshTokenModal from '@/modules/tokens/react/modals/RefreshTokenModal';
import SendTokenModal from '@/modules/tokens/react/modals/SendTokenModal';
import TokensStore from '@/modules/tokens/StorageTokensStore';
import Confirm from '@/react/common/Confirm';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const TokenHeaderButtons = createReactClass({
  mixins: [createStoreMixin(TokensStore, ApplicationStore)],

  getStateFromStores() {
    const tokenId = RoutesStore.getCurrentRouteParam('tokenId');
    const token = TokensStore.getAll().find((t) => t.get('id') === tokenId) || fromJS({});

    return {
      tokenId,
      token,
      description: token.get('description'),
      isMaster: token.get('isMasterToken', false),
      isDeleting: TokensStore.isDeletingToken(tokenId),
      isSending: TokensStore.isSendingToken(tokenId),
      isRefreshing: TokensStore.isRefreshingToken(tokenId),
      sapiToken: ApplicationStore.getSapiToken(),
      hasNewQueue: ApplicationStore.hasNewQueue(),
    };
  },

  getInitialState() {
    return {
      sendModal: false,
      refreshModal: false,
    };
  },

  render() {
    if (!this.state.token.count()) {
      return null;
    }

    return (
      <>
        <ButtonGroup>
          {this.renderSendButton()}
          {!isInternal(this.state.token.get('description')) && (
            <>
              {this.renderRefreshButton()}
              {this.renderDeleteButton()}
            </>
          )}
        </ButtonGroup>

        {this.renderRefreshModal()}
        {this.renderSendModal()}
      </>
    );
  },

  renderDeleteButton() {
    if (this.state.token.has('admin')) {
      return null;
    }

    return (
      <Confirm
        icon="trash"
        title="Delete Token"
        text={`Are you sure you want to delete the token ${this.state.description} (${this.state.tokenId})?`}
        buttonLabel="Delete"
        onConfirm={this.deleteToken}
        isLoading={this.state.isDeleting}
        closeAfterResolve
      >
        <Button variant="danger">
          <FontAwesomeIcon icon="trash" />
          Delete
        </Button>
      </Confirm>
    );
  },

  renderSendButton() {
    if (this.state.isMaster) {
      return null;
    }

    return (
      <Button onClick={this.openSendModal} variant="outline">
        <FontAwesomeIcon icon="share" />
        Send via email
      </Button>
    );
  },

  renderRefreshButton() {
    return (
      <Button onClick={this.openRefreshModal} variant="outline">
        <FontAwesomeIcon icon="arrows-rotate" />
        Refresh
      </Button>
    );
  },

  renderRefreshModal() {
    return (
      <RefreshTokenModal
        token={this.state.token}
        show={!!this.state.refreshModal}
        onHideFn={this.closeRefreshModal}
        onRefreshFn={this.refreshToken}
        isRefreshing={!!this.state.isRefreshing}
        sapiToken={this.state.sapiToken}
        hasNewQueue={this.state.hasNewQueue}
      />
    );
  },

  renderSendModal() {
    return (
      <SendTokenModal
        token={this.state.token}
        show={!!this.state.sendModal}
        onHideFn={this.closeSendModal}
        onSendFn={this.sendToken}
        isSending={!!this.state.isSending}
      />
    );
  },

  openSendModal() {
    this.setState({
      sendModal: true,
    });
  },

  closeSendModal() {
    this.setState({
      sendModal: false,
    });
  },

  openRefreshModal() {
    this.setState({
      refreshModal: true,
    });
  },

  closeRefreshModal() {
    this.setState({
      refreshModal: false,
    });
  },

  sendToken(params) {
    return TokensActions.sendToken(this.state.tokenId, params).then(() =>
      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: () => {
          return (
            <>
              Token <b>{this.state.description}</b> sent to ${params.email}.
            </>
          );
        },
      }),
    );
  },

  deleteToken() {
    const description = this.state.description;

    return TokensActions.deleteToken(this.state.token).then(() => {
      RoutesStore.getRouter().transitionTo(routeNames.TOKENS);

      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: () => {
          return (
            <>
              Token <b>{description}</b> has been removed.
            </>
          );
        },
      });
    });
  },

  refreshToken() {
    return TokensActions.refreshToken(this.state.token);
  },
});

export default TokenHeaderButtons;
