import { SHARING } from './buckets';

export const FILTERS_GROUP = {
  SHARING: 'sharing',
  ENTITY: 'entity',
  OWNERSHIP: 'ownership',
} as const;

export const FILTERS = {
  ALL: '',
  TABLES: 'tables',
  BUCKETS: 'buckets',
  DEV: 'dev',
  MINE: 'mine',
  COMPONENTS: 'components',
  ...SHARING,
} as const;
