import { useQuery } from '@tanstack/react-query';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import installedComponentsApi from '@/modules/components/InstalledComponentsApi';
import type { DeletedComponent } from '@/modules/trash/types';
import { containsOrchestratorWithFlows, mapOrchestratorToFlow } from '@/modules/trash/utils';
import { RQ_KEYS } from './keys';

const prepareDeletedComponents = () => {
  return installedComponentsApi.getDeletedComponents().then((data: DeletedComponent[]) => {
    if (containsOrchestratorWithFlows(data)) {
      return data.map((component) => {
        if (component.id === KEBOOLA_ORCHESTRATOR) {
          return mapOrchestratorToFlow(component);
        }
        return component;
      });
    }
    return data;
  });
};

export const useDeletedComponents = () => {
  return useQuery({
    queryKey: RQ_KEYS.TRASH,
    queryFn: prepareDeletedComponents,
  });
};
