import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type Promise from 'bluebird';
import type { Map } from 'immutable';

import { Button, Tooltip } from '@keboola/design';

import { CreatedDate, Truncated } from '@/react/common';
import Confirm from '@/react/common/Confirm';
import Gravatar from '@/react/common/Gravatar';

const AuthorizedBox = ({
  credentials,
  admins,
  onReset,
  readOnly,
  simpleMode,
  suffixToTrim = '',
}: {
  credentials: { creator: { description: string }; created: string; authorizedFor: string };
  admins: Map<string, Map<string, any>>;
  onReset: () => Promise<unknown>;
  readOnly?: boolean;
  simpleMode?: boolean;
  suffixToTrim?: string;
}) => {
  const [isResetting, setIsResetting] = useState(false);
  const description =
    suffixToTrim && credentials.authorizedFor.endsWith(suffixToTrim)
      ? credentials.authorizedFor.slice(0, -suffixToTrim.length)
      : credentials.authorizedFor;

  return (
    <div className="tw-flex tw-items-center tw-justify-start tw-rounded-lg tw-bg-neutral-50 tw-p-4">
      <Gravatar
        className="tw-mr-3"
        user={admins.get(credentials.creator.description)}
        fallback={credentials.creator.description}
      />
      <div>
        <Truncated text={description} twoLines className="tw-font-medium" />
        {!simpleMode && (
          <div>
            {description !== credentials.creator.description && credentials.creator.description} (
            <CreatedDate createdTime={credentials.created} className="text-muted" />)
          </div>
        )}
      </div>
      {!readOnly && (
        <div className="ml-auto">
          {simpleMode ? (
            <ResetButton onReset={onReset} />
          ) : (
            <Confirm
              closeAfterResolve
              title="Reset Authorization"
              text="Are you sure you want to reset the authorized account?"
              buttonLabel="Reset"
              isLoading={isResetting}
              onConfirm={() => {
                setIsResetting(true);

                return onReset().finally(() => setIsResetting(false));
              }}
            >
              <ResetButton />
            </Confirm>
          )}
        </div>
      )}
    </div>
  );
};

const ResetButton = ({ onReset }: { onReset?: () => Promise<unknown> }) => (
  <Tooltip tooltip="Reset Authorization" placement="top">
    <Button variant="outline" onClick={onReset}>
      <FontAwesomeIcon icon="arrow-rotate-left" />
      Reset
    </Button>
  </Tooltip>
);

export default AuthorizedBox;
