import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, Radio } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { HelpBlock } from '@keboola/design';

import StorageTablesStore from '@/modules/components/stores/StorageTablesStore.js';
import loadType from '@/modules/wr-google-bigquery-v2/adapters/loadType';
import AutomaticLoadTypeLastUpdated from '@/react/common/AutomaticLoadTypeLastUpdated';
import ChangedSinceInput from '@/react/common/ChangedSinceInput';
import createStoreMixin from '@/react/mixins/createStoreMixin.js';

const LoadTypeSection = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      loadType: PropTypes.oneOf([
        loadType.constants.FULL,
        loadType.constants.INCREMENTAL,
        loadType.constants.ADAPTIVE,
      ]),
      changedSince: PropTypes.string.isRequired,
      source: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  mixins: [createStoreMixin(StorageTablesStore)],

  getStateFromStores() {
    return {
      isExternal: StorageTablesStore.getAll().getIn([
        this.props.value.source,
        'bucket',
        'hasExternalSchema',
      ]),
    };
  },

  renderChangedInLast() {
    if (this.props.value.loadType === loadType.constants.INCREMENTAL) {
      return (
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Changed in last</ControlLabel>
          </div>
          <div className="col-xs-8">
            <ChangedSinceInput
              value={this.props.value.changedSince}
              onChange={(newValue) => this.props.onChange({ changedSince: newValue })}
              disabled={this.props.disabled}
              tableId={this.props.value.source}
            />
          </div>
        </FormGroup>
      );
    }
  },

  render() {
    const { value, onChange, disabled } = this.props;
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Load Type</h2>
        </div>
        <div className="box-content">
          <FormGroup>
            <div className="col-xs-8 col-xs-offset-4">
              <Radio
                type="radio"
                title="Full load"
                disabled={disabled}
                onChange={() => onChange({ loadType: loadType.constants.FULL, changedSince: '' })}
                checked={value.loadType === loadType.constants.FULL}
              >
                Full load
              </Radio>
              <HelpBlock className="tw-mt-1">
                Replace all existing rows in the destination table.
              </HelpBlock>
              {!this.state.isExternal && (
                <>
                  <Radio
                    type="radio"
                    title="Automatic incremental load"
                    disabled={disabled}
                    onChange={() =>
                      onChange({ loadType: loadType.constants.ADAPTIVE, changedSince: '' })
                    }
                    checked={value.loadType === loadType.constants.ADAPTIVE}
                  >
                    Automatic incremental load
                  </Radio>
                  <HelpBlock className="tw-mt-1">
                    Append all data that has been added or changed since the last successful run.
                  </HelpBlock>
                  {value.loadType === loadType.constants.ADAPTIVE && (
                    <AutomaticLoadTypeLastUpdated tableId={this.props.value.source} />
                  )}
                </>
              )}
              <Radio
                type="radio"
                title="Manual incremental load"
                disabled={disabled}
                onChange={() => onChange({ loadType: loadType.constants.INCREMENTAL })}
                checked={value.loadType === loadType.constants.INCREMENTAL}
              >
                Manual incremental load
              </Radio>
              <HelpBlock className="tw-mt-1">Append all selected data.</HelpBlock>
            </div>
          </FormGroup>
          {this.renderChangedInLast()}
        </div>
      </div>
    );
  },
});

export default LoadTypeSection;
