import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { Alert, cn } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { KEBOOLA_SHARED_CODE, KEBOOLA_VARIABLES } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import componentsActions from '@/modules/components/InstalledComponentsActionCreators';
import ConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import { prepareScriptsBeforeSave } from '@/modules/components/react/components/generic/code-blocks/helpers';
import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { routeNames as transformationRoutes } from '@/modules/transformations-v2/constants';
import { CreatedDate, Truncated } from '@/react/common';
import CodeEditorModal from '@/react/common/CodeEditorModal';
import CodeEditorPreview from '@/react/common/CodeEditorPreview';
import ConfirmModal from '@/react/common/ConfirmModal';
import ReadOnlyTooltip from '@/react/common/ReadOnlyTooltip';
import SaveButtons from '@/react/common/SaveButtons';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import CodeUsagesPanel from './components/CodeUsagesPanel';
import CreateSharedCodeFromSourceModal from './components/CreateSharedCodeFromSourceModal';
import DefineVariables from './components/DefineVariables';
import SharedCodesActions from './Actions';
import { getConfigurations, prepareCodeString, resolveEditorMode } from './helpers';

const Detail = createReactClass({
  mixins: [
    createStoreMixin(ApplicationStore, StackFeaturesStore, InstalledComponentsStore, RoutesStore),
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const config = InstalledComponentsStore.getConfig(KEBOOLA_SHARED_CODE, configId);
    const rowId = RoutesStore.getCurrentRouteParam('row');
    const row = config.get('rows', List()).find((row) => row.get('id') === rowId);
    const componentId = config.getIn(['configuration', 'componentId']);
    const variablesId = row.getIn(['configuration', 'variables_id']);
    const transformations = InstalledComponentsStore.getAllForType(componentTypes.TRANSFORMATION);

    const code = prepareCodeString(row.getIn(['configuration', 'code_content']));
    const variables = InstalledComponentsStore.getConfigData(KEBOOLA_VARIABLES, variablesId).get(
      'variables',
      List(),
    );

    return {
      config,
      configId,
      row,
      rowId,
      componentId,
      variablesId,
      code,
      variables,
      copySource: Map({ type: componentId, name: row.get('name'), code, variablesId }),
      usages: getConfigurations(
        transformations,
        Map({ type: componentId, rowId, name: row.get('name') }),
      ),
      createdByUser: Map({
        user: row.getIn(['creatorToken', 'description']),
        createdAt: row.get('created'),
      }),
      readOnly: ApplicationStore.isReadOnly(),
      hasFlows: ApplicationStore.hasFlows(),
    };
  },

  getInitialState() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const config = InstalledComponentsStore.getConfig(KEBOOLA_SHARED_CODE, configId);
    const rowId = RoutesStore.getCurrentRouteParam('row');
    const row = config.get('rows', List()).find((row) => row.get('id') === rowId);

    return {
      showCopyModal: false,
      showDeleteModal: false,
      showCodeEditorModal: false,
      isSavingCode: false,
      newCode: prepareCodeString(row.getIn(['configuration', 'code_content'], '')),
    };
  },

  render() {
    return (
      <GenericConfigBody
        key={`${KEBOOLA_SHARED_CODE}-${this.state.configId}-${this.state.rowId}`}
        componentId={KEBOOLA_SHARED_CODE}
        configId={this.state.configId}
        rowId={this.state.rowId}
        sidebarProps={{
          hideRun: true,
          hideCopy: true,
          hideJobs: true,
          hideSchedule: true,
          versionsLinkTo: transformationRoutes.SHARED_CODE_VERSIONS,
          additionalButtons: (
            <ReadOnlyTooltip readOnly={this.state.readOnly}>
              <Button
                key="copy-shared-code-button"
                onClick={() => !this.state.readOnly && this.setState({ showCopyModal: true })}
                bsStyle="link"
                className={cn('btn-block', { disabled: this.state.readOnly })}
              >
                <FontAwesomeIcon icon="clone" fixedWidth />
                Copy shared code
              </Button>
            </ReadOnlyTooltip>
          ),
          delete: (
            <Button
              onClick={() => this.setState({ showDeleteModal: true })}
              bsStyle="link"
              className="btn-block"
            >
              <FontAwesomeIcon icon="trash" fixedWidth />
              Delete shared code
            </Button>
          ),
          createdByUser: this.state.createdByUser,
        }}
      >
        {this.renderUsage()}
        {this.renderVariables()}
        {this.renderCode()}
        {this.renderCopyModal()}
        {this.renderDeleteModal()}
        {this.renderCodeEditorModal()}
      </GenericConfigBody>
    );
  },

  renderUsage() {
    const usages = this.state.usages
      .sortBy((config) => config.get('name').toLowerCase())
      .toArray()
      .map((config, index) => this.renderUsageRow(config, index === this.state.usages.count() - 1));
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Usage</h2>
        </div>
        <div className="box-content">
          {usages.length > 0 ? usages : 'This shared code is not used in any transformation.'}
        </div>
      </div>
    );
  },

  renderUsageRow(config, isLast) {
    return (
      <div key={config.get('id')} className={cn('flex-container', { 'mb-1': !isLast })}>
        <ConfigurationLink
          className="dark"
          configId={config.get('id')}
          componentId={this.state.componentId}
          hasFlows={this.state.hasFlows}
        >
          <Truncated text={config.get('name')} />
        </ConfigurationLink>
        <div className="text-muted no-wrap">
          Last changed <CreatedDate createdTime={config.getIn(['currentVersion', 'created'])} />
        </div>
      </div>
    );
  },

  renderVariables() {
    return (
      <DefineVariables
        readOnly={this.state.readOnly}
        variables={this.state.variables}
        onChange={this.handleUpdateVariables}
      />
    );
  },

  renderCode() {
    if (!this.state.code && !this.state.readOnly) {
      return (
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Code</h2>
          </div>
          <div className="box-content text-center">
            <Button bsStyle="success" onClick={() => this.setState({ showCodeEditorModal: true })}>
              Add Code
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Code</h2>
          <Button bsStyle="success" onClick={this.handleOpenEditor}>
            {this.state.readOnly ? 'Show Code' : 'Edit Code'}
          </Button>
        </div>
        <div className="box-content">
          <CodeEditorPreview
            readOnly={this.state.readOnly}
            code={this.state.code}
            mode={resolveEditorMode(this.state.componentId)}
            onClick={this.handleOpenEditor}
          />
        </div>
      </div>
    );
  },

  renderCodeEditorModal() {
    if (!this.state.showCodeEditorModal) {
      return null;
    }

    return (
      <CodeEditorModal
        withAutocomplete
        editorKey={`shared-code-${this.state.configId}-${this.state.rowId}`}
        title="Code"
        value={this.state.newCode}
        onSave={this.handleSaveCode}
        onChange={this.handleChangeCode}
        onClose={this.handleCloseEditor}
        onReset={this.handleResetCode}
        isChanged={this.isChangedCode()}
        renderAdditionalButtons={() => (
          <SaveButtons
            isSaving={this.state.isSavingCode}
            isChanged={this.isChangedCode()}
            disabled={this.state.isSavingCode}
            onReset={this.handleResetCode}
            onSave={this.handleSaveCode}
          />
        )}
        codeMirrorOptions={this.getCodeMirrorOptions()}
        warning={
          !this.state.usages.isEmpty()
            ? 'There are transformations that use this code. Changing it may cause them to break.'
            : ''
        }
      />
    );
  },

  renderCopyModal() {
    return (
      <CreateSharedCodeFromSourceModal
        show={this.state.showCopyModal}
        onSubmit={(type, name, codes, variables) => {
          return SharedCodesActions.createSharedCode(type, name, codes, variables).then(
            (newConfig) => {
              this.setState({ showCopyModal: false });
              RoutesStore.getRouter().transitionTo(transformationRoutes.SHARED_CODE, {
                config: newConfig.sharedCodesConfigurationId,
                row: newConfig.sharedCodesConfigurationRowId,
              });
              return newConfig;
            },
          );
        }}
        onHide={() => this.setState({ showCopyModal: false })}
        sourceCode={this.state.copySource}
        copy
      />
    );
  },

  renderDeleteModal() {
    return (
      <ConfirmModal
        show={this.state.showDeleteModal}
        onHide={() => this.setState({ showDeleteModal: false })}
        icon="trash"
        buttonType="danger"
        buttonLabel="Delete Code"
        title="Delete Code"
        text={
          <>
            <p>
              Are you sure you want to delete the code <b>{this.state.row.get('name')}</b>?
            </p>
            {this.state.usages.count() > 0 && (
              <>
                <Alert variant="warning" className="tw-mb-5">
                  There are transformations that use this code. They will no longer work.
                </Alert>
                <CodeUsagesPanel
                  configurations={this.state.usages}
                  componentId={this.state.componentId}
                  hasFlows={this.state.hasFlows}
                />
              </>
            )}
          </>
        }
        onConfirm={this.handleDelete}
        loadOnEnter={() => componentsActions.loadComponentConfigsData(this.state.componentId)}
      />
    );
  },

  handleDelete() {
    RoutesStore.getRouter().transitionTo(transformationRoutes.SHARED_CODES);
    SharedCodesActions.deleteSharedCode(
      this.state.configId,
      this.state.rowId,
      this.state.variablesId,
    ).then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: () => (
          <>
            Shared code <b>{this.state.row.get('name')}</b> has been deleted.
          </>
        ),
      });
    });
  },

  handleCloseEditor() {
    this.setState({ showCodeEditorModal: false });
  },

  handleUpdateVariables(newVariables) {
    return SharedCodesActions.editSharedCode(
      this.state.configId,
      this.state.rowId,
      this.state.variablesId,
      this.state.name,
      this.state.row.getIn(['configuration', 'code_content'], List()),
      newVariables.map((variable) => variable.get('name')),
      'Update variables',
    );
  },

  handleChangeCode(newCode) {
    this.setState({ newCode });
  },

  handleResetCode() {
    this.handleChangeCode(this.state.code);
  },

  handleOpenEditor() {
    this.handleResetCode();
    this.setState({ showCodeEditorModal: true });
  },

  handleSaveCode() {
    if (this.state.isSavingCode) {
      return Promise.resolve();
    }

    this.setState({ isSavingCode: true });

    return prepareScriptsBeforeSave(this.state.componentId, this.state.newCode)
      .then((normalizedCode) => {
        this.setState({ newCode: prepareCodeString(normalizedCode) });

        return SharedCodesActions.editSharedCode(
          this.state.configId,
          this.state.rowId,
          this.state.variablesId,
          this.state.name,
          normalizedCode,
          this.state.variables.map((variable) => variable.get('name')),
          'Update code',
        );
      })
      .finally(() => {
        this.setState({ isSavingCode: false });
      });
  },

  isChangedCode() {
    return this.state.code !== this.state.newCode;
  },

  getCodeMirrorOptions() {
    const commonOptions = {
      mode: resolveEditorMode(this.state.componentId),
      placeholder: '-- Your code goes here',
    };

    if (this.state.readOnly) {
      return { ...commonOptions, cursorHeight: 0, readOnly: true };
    }

    return {
      ...commonOptions,
      hintOptions: {
        completeSingle: false,
        container: document.querySelector('.full-screen-modal.full-screen-editor'),
        variables: this.getVariablesHints(),
      },
    };
  },

  getVariablesHints() {
    return this.state.variables
      .map((variable) => ({ name: variable.get('name'), value: variable.get('value') }))
      .toArray();
  },
});

export default Detail;
