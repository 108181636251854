import React from 'react';
import PropTypes from 'prop-types';
import { fromJS, Map } from 'immutable';
import memoizeOne from 'memoize-one';

import { STAGE } from '@/constants';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import { filterProductionAndCurrentDevBranchTables } from '@/modules/dev-branches/helpers';
import { tableDisplayNameWithBucketAndStage, tableName } from '@/modules/storage/helpers';
import Select from '@/react/common/Select';
import { tableLabel } from '@/react/common/selectLabels';

const prepareOptions = memoizeOne((buckets, tables, allowedBuckets, excludeTableFn) => {
  return filterProductionAndCurrentDevBranchTables(tables, buckets)
    .filter((table) => {
      return (
        allowedBuckets.includes(table.getIn(['bucket', 'stage'])) &&
        !excludeTableFn(table.get('id'), table)
      );
    })
    .sortBy((table) => tableDisplayNameWithBucketAndStage(table))
    .map((table) => ({
      value: table.get('id'),
      label: tableLabel(table, {
        className: { 'text-muted': !tables.has(table.get('id')) },
      }),
      name: tableName(table),
    }))
    .toArray();
});

class SapiTableSelector extends React.PureComponent {
  state = {
    isLoading: false,
  };

  render() {
    return (
      <Select
        id={this.props.id}
        autoFocus={this.props.autoFocus}
        disabled={this.props.disabled}
        isLoading={this.state.isLoading}
        clearable={this.props.clearable}
        placeholder={this.props.placeholder}
        value={this.props.value}
        onChange={this.handleChange}
        options={prepareOptions(
          this.props.buckets,
          this.props.tables,
          this.props.allowedBuckets,
          this.props.excludeTableFn,
        )}
      />
    );
  }

  handleChange = (tableId) => {
    this.setState({ isLoading: true });
    return StorageActionCreators.loadTableDetail(tableId)
      .then((table) => this.props.onSelectTableFn(tableId, fromJS(table)))
      .finally(() => this.setState({ isLoading: false }));
  };
}

SapiTableSelector.propTypes = {
  tables: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  onSelectTableFn: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  excludeTableFn: PropTypes.func,
  allowedBuckets: PropTypes.array,
  disabled: PropTypes.bool,
  clearable: PropTypes.bool,
  autoFocus: PropTypes.bool,
  id: PropTypes.string,
};

SapiTableSelector.defaultProps = {
  excludeTableFn: () => false,
  allowedBuckets: Object.values(STAGE),
  disabled: false,
  autoFocus: false,
  clearable: false,
};

export default SapiTableSelector;
