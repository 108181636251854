import React from 'react';
import { type Map } from 'immutable';

import { Badge, Tooltip } from '@keboola/design';

import NativeTypesLabel from '@/modules/storage/components/NativeTypesLabel';
import { isTableShared } from '@/modules/storage/helpers';
import ExternalTableLabel from './ExternalTableLabel';

type Props = {
  table: Map<string, any>;
  withNativeLabel?: boolean;
  withExternalLabel?: boolean;
  withSharedLabel?: boolean;
};

export const TableLabels = ({
  table,
  withNativeLabel = true,
  withExternalLabel = true,
  withSharedLabel = true,
}: Props) => {
  if (!table || table.isEmpty()) return null;

  return (
    <div className="tw-ml-2 tw-inline-flex tw-gap-2">
      {withNativeLabel && <NativeTypesLabel isTyped={table.get('isTyped')} />}
      {withExternalLabel && (
        <ExternalTableLabel
          tableType={table.get('tableType')}
          hasExternalSchema={table.getIn(['bucket', 'hasExternalSchema'])}
        />
      )}
      {withSharedLabel && isTableShared(table) && (
        <Tooltip
          tooltip="The object is being shared with other projects"
          placement="top"
          type="explanatory"
        >
          <Badge text="Shared" variant="purple-inverse" />
        </Tooltip>
      )}
    </div>
  );
};
