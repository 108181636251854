import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { Button, Tooltip } from '@keboola/design';

import { deleteTrigger } from '@/modules/event-trigger/actions';
import TriggerInfo from '@/modules/event-trigger/components/TriggerInfo';
import TriggerSwitch from '@/modules/event-trigger/components/TriggerSwitch';
import { getScheduleTooltipMessage } from '@/modules/flows/helpers';
import { removeScheduler } from '@/modules/scheduler/actions';
import { isActiveScheduler, prettyCron } from '@/modules/scheduler/helpers';
import type { StoreSource } from '@/modules/stream/store';
import ScheduleModal from './ScheduleModal';
import ScheduleRow from './ScheduleRow';
import ScheduleSwitch from './ScheduleSwitch';

type ModalState =
  | { type: 'hide' }
  | { type: 'create' }
  | { type: 'edit-trigger'; trigger: Map<string, any> }
  | { type: 'edit-scheduler'; scheduler: Map<string, any> };

const Schedules = (props: {
  configId: string;
  tables: Map<string, any>;
  buckets: Map<string, any>;
  triggers: Map<string, any>;
  schedulers: Map<string, any>;
  hasDataStreams: boolean;
  dataStreamSources: StoreSource[];
  isDevModeActive: boolean;
  canManageTriggers: boolean;
  canManageSchedule: boolean;
  hasProtectedDefaultBranch: boolean;
}) => {
  const [modalType, setModalType] = React.useState<ModalState>({ type: 'hide' });

  const renderCreateButton = (variant: 'primary' | 'outline') => {
    const isDisabled = !props.canManageSchedule && !props.canManageTriggers;

    const tooltipMessage = getScheduleTooltipMessage(
      props.hasProtectedDefaultBranch,
      props.isDevModeActive,
      isDisabled,
    );

    return (
      <Tooltip placement="top" type="explanatory" tooltip={tooltipMessage} forceHide={!isDisabled}>
        <Button
          variant={variant}
          onClick={() => setModalType({ type: 'create' })}
          disabled={isDisabled}
        >
          <FontAwesomeIcon icon="plus" />
          Create Schedule
        </Button>
      </Tooltip>
    );
  };

  return (
    <>
      <div className="box">
        <div className="box-content">
          {props.triggers.isEmpty() && props.schedulers.isEmpty() ? (
            <div className="tw-mx-auto tw-flex tw-max-w-xl tw-flex-col tw-items-center tw-p-4">
              <h2 className="tw-mb-1 tw-mt-0 tw-text-base">There are no planned schedules</h2>
              <p className="tw-mb-4 tw-text-center tw-text-neutral-400">
                You can create a schedule based on date and time or table triggers. If you have
                multiple schedules set for a flow, they function independently, activating the flow
                whenever any schedule is triggered.
              </p>
              {renderCreateButton('primary')}
            </div>
          ) : (
            <>
              <div className="tw-flex tw-items-center tw-justify-between">
                <div>
                  <h2 className="tw-mb-1 tw-mt-0 tw-text-base">Schedules</h2>
                  <p className="tw-m-0 tw-text-neutral-400">
                    If multiple schedules are set for a row, they work independently, activating the
                    flow when any schedule is triggered.
                  </p>
                </div>
                {renderCreateButton('outline')}
              </div>
              <div className="tw-mt-4 tw-flex tw-flex-col tw-gap-3">
                {props.schedulers
                  .map((scheduler: Map<string, any>) => {
                    const id = scheduler.get('id');
                    const schedule = scheduler.getIn(['configuration', 'schedule']);

                    return (
                      <ScheduleRow
                        key={id}
                        type="scheduler"
                        value={prettyCron(schedule.get('cronTab'), schedule.get('timezone'))}
                        disabled={!isActiveScheduler(scheduler)}
                        {...(props.canManageSchedule && {
                          onEdit: () => setModalType({ type: 'edit-scheduler', scheduler }),
                          onDelete: () => removeScheduler(id),
                        })}
                      >
                        <ScheduleSwitch
                          scheduler={scheduler}
                          canManageSchedule={props.canManageSchedule}
                          hasProtectedDefaultBranch={props.hasProtectedDefaultBranch}
                        />
                      </ScheduleRow>
                    );
                  })
                  .toArray()}
                {props.triggers
                  .map((trigger: Map<string, any>) => {
                    const id = trigger.get('id');
                    const onEdit = () => setModalType({ type: 'edit-trigger', trigger });
                    const onDelete = () => deleteTrigger(id);

                    return (
                      <ScheduleRow
                        key={id}
                        type="trigger"
                        value={
                          <TriggerInfo
                            tables={trigger.get('tables').toJS()}
                            coolDownPeriodMinutes={trigger.get('coolDownPeriodMinutes')}
                            {...(props.canManageTriggers && { onEdit })}
                          />
                        }
                        {...(props.canManageTriggers && { onEdit, onDelete })}
                      >
                        <TriggerSwitch />
                      </ScheduleRow>
                    );
                  })
                  .toArray()}
              </div>
            </>
          )}
        </div>
      </div>
      <ScheduleModal
        show={modalType.type !== 'hide'}
        onHide={() => setModalType({ type: 'hide' })}
        configId={props.configId}
        tables={props.tables}
        buckets={props.buckets}
        hasDataStreams={props.hasDataStreams}
        dataStreamSources={props.dataStreamSources}
        canManageSchedule={props.canManageSchedule}
        canManageTriggers={props.canManageTriggers}
        hasProtectedDefaultBranch={props.hasProtectedDefaultBranch}
        {...(modalType.type === 'edit-trigger' && { trigger: modalType.trigger })}
        {...(modalType.type === 'edit-scheduler' && { scheduler: modalType.scheduler })}
      />
    </>
  );
};

export default Schedules;
