import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';
import { List, Map } from 'immutable';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import { loadNotifications } from '@/modules/notifications/actions';
import { RouterLink as Link } from '@/react/common';
import { HTTP_STATUS_CODE_NOT_FOUND } from '@/utils/errors/helpers';
import string from '@/utils/string';
import DetailButtons from './components/JobDetailButtons';
import DetailStatus from './components/JobDetailStatus';
import JobsActionCreators from './actions';
import { JOBS_STATUS, routeNames } from './constants';
import Detail from './Detail';
import { isContainerJob } from './helpers';
import Index from './Index';
import JobsStore from './store';

const INDEX_POLL_INTERVAL = 10 * 1000; // 10 seconds
const DETAIL_POLL_INTERVAL = 5 * 1000; // 5 seconds

const routes = {
  name: routeNames.JOBS,
  title: 'Jobs',
  defaultRouteHandler: Index,
  poll: {
    skipFirst: true,
    interval: INDEX_POLL_INTERVAL,
    action: () => {
      if (JobsStore.getIsLoading()) {
        return Promise.resolve();
      }

      return JobsActionCreators.loadJobsForce(
        { ...JobsStore.getParams().toJS(), limit: JobsStore.getAll().count() },
        { resetPreviousJobs: true },
      );
    },
  },
  requireData: [
    () => loadNotifications(),
    () => {
      return InstalledComponentsActionCreators.reloadInstalledComponents({
        include: 'configuration,rows',
      });
    },
    ({ jobId }, query) => {
      // init loading without waiting, we will show loading on the index page
      if (!jobId) {
        JobsActionCreators.loadJobsForce(query ?? {}, { resetPreviousJobs: true });
      }
    },
  ],
  childRoutes: [
    {
      name: routeNames.JOB_DETAIL,
      path: ':jobId',
      title: (routerState) => `Job ${routerState.getIn(['params', 'jobId'])}`,
      breadcrumbHandler: ({ breadcrumbs, params }) => {
        const parentJobId = string.strRightBack(
          JobsStore.get(params.jobId).get('parentRunId'),
          '.',
        );

        if (!parentJobId) {
          return breadcrumbs;
        }

        return (
          <>
            {breadcrumbs}
            <FontAwesomeIcon icon={['far', 'angle-right']} />
            <Link className="dark muted" to={routeNames.JOB_DETAIL} params={{ jobId: parentJobId }}>
              Parent job
            </Link>
          </>
        );
      },
      defaultRouteHandler: ({ params }) => <Detail key={params.jobId} />,
      labelHandler: DetailStatus,
      headerButtonsHandler: DetailButtons,
      isRunning(routerState) {
        const jobId = routerState.getIn(['params', 'jobId']);
        const job = JobsStore.get(jobId);
        return job && !job.get('isFinished', true);
      },
      poll: {
        interval: DETAIL_POLL_INTERVAL,
        skip({ jobId }) {
          return ![
            JOBS_STATUS.CREATED,
            JOBS_STATUS.WAITING,
            JOBS_STATUS.PROCESSING,
            JOBS_STATUS.TERMINATING,
          ].includes(JobsStore.get(jobId).get('status'));
        },
        action: ({ jobId }) => {
          return JobsActionCreators.loadJobForce(jobId).then(() => {
            const job = JobsStore.get(jobId, Map());

            if (isContainerJob(job)) {
              return JobsActionCreators.loadChildJobsForce(job);
            }
          });
        },
      },
      requireData: [
        () => loadNotifications(),
        () => StorageActionCreators.loadBucketsAndTables(),
        ({ jobId }) => {
          return JobsActionCreators.loadJob(jobId).then(() => {
            const job = JobsStore.get(jobId);

            if (isContainerJob(job)) {
              return InstalledComponentsActionCreators.loadComponentConfigData(
                job.get('component'),
                job.get('config'),
              ).catch((error) => {
                if (error?.response?.status === HTTP_STATUS_CODE_NOT_FOUND) {
                  return {};
                }
                throw error;
              });
            } else if (job.get('config') && !job.get('configRowIds', List()).isEmpty()) {
              return InstalledComponentsActionCreators.loadComponentConfigsData(
                job.get('component'),
              );
            }
          });
        },
      ],
    },
  ],
};

export default routes;
