import React from 'react';
import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { Alert } from '@keboola/design';

import { KEBOOLA_LEGACY_TRANSFORMATION } from '@/constants/componentIds';
import { FEATURE_TRANSFORMATIONS_MIXED_BACKENDS } from '@/constants/features';
import { ioType } from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import TableInputMapping from '@/modules/components/react/components/generic/TableInputMapping';
import TableOutputMapping from '@/modules/components/react/components/generic/TableOutputMapping';
import MappingsWrapper from '@/modules/components/react/components/MappingsWrapper';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import ActionCreators from '@/modules/legacy-transformation/ActionCreators';
import { transformationType } from '@/modules/legacy-transformation/Constants';
import BackendVersionWarning from '@/modules/legacy-transformation/react/components/backend-version/Warning';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import ApplicationStore from '@/stores/ApplicationStore';
import DependentTransformations from './DependentTransformations';
import Packages from './Packages';
import Queries from './Queries';
import Requires from './Requires';
import SavedFiles from './SavedFiles';
import Scripts from './Scripts';

const TransformationDetailStatic = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    bucket: PropTypes.object.isRequired,
    transformation: PropTypes.object.isRequired,
    editingFields: PropTypes.object.isRequired,
    isEditingValid: PropTypes.bool.isRequired,
    isQueriesProcessing: PropTypes.bool.isRequired,
    transformations: PropTypes.object.isRequired,
    pendingActions: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    bucketId: PropTypes.string.isRequired,
    transformationId: PropTypes.string.isRequired,
    showDetails: PropTypes.bool.isRequired,
    highlightQueryNumber: PropTypes.number,
  },

  render() {
    if (!this.props.showDetails) {
      return (
        <Alert variant="warning" className="tw-mb-5">
          This transformation is not supported in the UI.
        </Alert>
      );
    }

    return (
      <>
        {this.renderBackendVersionWarning()}
        {this.renderDescription()}
        {this.renderRequires()}
        <MappingsWrapper>
          {this.tableInputMapping()}
          {this.tableOutputMapping()}
        </MappingsWrapper>
        {this.renderPackages()}
        <CatchUnsavedChanges
          isDirty={this.props.editingFields.get('queriesChanged', false)}
          onSave={() => {
            if (this.props.transformation.get('backend') === 'docker') {
              return this.editScriptsSubmit();
            }
            return this.editQueriesSubmit();
          }}
          onDirtyLeave={() => {
            if (this.props.transformation.get('backend') === 'docker') {
              return this.editScriptsCancel();
            }
            return this.editQueriesCancel();
          }}
        >
          {this.renderCodeEditor()}
        </CatchUnsavedChanges>
      </>
    );
  },

  renderBackendVersionWarning() {
    if (!this.props.transformation.has('imageTag')) {
      return null;
    }

    return <BackendVersionWarning />;
  },

  renderDescription() {
    return (
      <ComponentDescription
        key={`${this.props.bucketId}-${this.props.transformationId}`}
        componentId={KEBOOLA_LEGACY_TRANSFORMATION}
        configId={this.props.bucketId}
        rowId={this.props.transformationId}
        placeholderEntity="Transformation"
      />
    );
  },

  renderRequires() {
    const backend = this.props.transformation.get('backend');
    const hasMixedBackends = ApplicationStore.hasCurrentProjectFeature(
      FEATURE_TRANSFORMATIONS_MIXED_BACKENDS,
    );
    const supportedType = [transformationType.PYTHON, transformationType.R].includes(
      this.props.transformation.get('type'),
    );

    if (backend === 'docker' && (!supportedType || !hasMixedBackends)) {
      return null;
    }

    return (
      <div className="box info-row">
        <div className="info-row-section">
          <Requires
            transformation={this.props.transformation}
            transformations={this.props.transformations}
            isSaving={this.props.pendingActions.has('save-requires')}
            disabled={this.isDisabled()}
            requires={this.props.editingFields.get(
              'requires',
              this.props.transformation.get('requires'),
            )}
            bucketId={this.props.bucketId}
            onEditChange={(newValue) => {
              ActionCreators.updateTransformationEditingField(
                this.props.bucketId,
                this.props.transformationId,
                'requires',
                newValue,
              );
              return ActionCreators.saveTransformationEditingField(
                this.props.bucketId,
                this.props.transformationId,
                'requires',
              );
            }}
          />
        </div>
        <div className="info-row-section">
          <DependentTransformations
            transformations={this.props.transformations}
            transformation={this.props.transformation}
            bucket={this.props.bucket}
          />
        </div>
      </div>
    );
  },

  tableInputMapping() {
    return (
      <TableInputMapping
        readOnly={this.props.readOnly}
        rowId={this.props.transformationId}
        configId={this.props.bucketId}
        componentId={KEBOOLA_LEGACY_TRANSFORMATION}
        onDeleteMappings={this.handleDeleteMappings}
        value={this.props.transformation.get('input', List())}
        tables={this.props.tables}
        buckets={this.props.buckets}
        destinationType={
          this.props.transformation.get('backend') === 'docker' ? ioType.FILE : ioType.TABLE
        }
        hasPayAsYouGo={false}
      />
    );
  },

  tableOutputMapping() {
    return (
      <TableOutputMapping
        readOnly={this.props.readOnly}
        rowId={this.props.transformationId}
        configId={this.props.bucketId}
        componentId={KEBOOLA_LEGACY_TRANSFORMATION}
        configName={this.props.transformation.get('name')}
        onDeleteMappings={this.handleDeleteMappings}
        value={this.props.transformation.get('output', List())}
        tables={this.props.tables}
        buckets={this.props.buckets}
        sourceType={this.props.transformation.get('backend') ? ioType.FILE : ioType.TABLE}
      />
    );
  },

  renderPackages() {
    if (this.props.transformation.get('backend') !== 'docker') {
      return null;
    }

    return (
      <>
        <Packages
          transformationType={this.props.transformation.get('type')}
          disabled={this.isDisabled()}
          packages={this.props.editingFields.get(
            'packages',
            this.props.transformation.get('packages', List()),
          )}
          onEditChange={(newValue) => {
            ActionCreators.updateTransformationEditingField(
              this.props.bucketId,
              this.props.transformationId,
              'packages',
              newValue,
            );
            return ActionCreators.saveTransformationEditingField(
              this.props.bucketId,
              this.props.transformationId,
              'packages',
            );
          }}
        />
        <SavedFiles
          isSaving={this.props.pendingActions.has('save-tags')}
          disabled={this.isDisabled()}
          tags={this.props.editingFields.get('tags', this.props.transformation.get('tags', List()))}
          onEditChange={(newValue) => {
            ActionCreators.updateTransformationEditingField(
              this.props.bucketId,
              this.props.transformationId,
              'tags',
              newValue,
            );
            return ActionCreators.saveTransformationEditingField(
              this.props.bucketId,
              this.props.transformationId,
              'tags',
            );
          }}
        />
      </>
    );
  },

  renderCodeEditor() {
    if (this.props.transformation.get('backend') === 'docker') {
      return (
        <Scripts
          transformation={this.props.transformation}
          isSaving={this.props.pendingActions.has('save-queries')}
          disabled={this.isDisabled()}
          scripts={this.props.editingFields.get(
            'queriesString',
            this.props.transformation.get('queriesString'),
          )}
          isEditingValid={this.props.isEditingValid}
          isChanged={this.props.editingFields.get('queriesChanged', false)}
          changeDescription={this.props.editingFields.get('description', '')}
          onDescriptionChange={this.onDescriptionChange}
          onEditCancel={this.editScriptsCancel}
          onEditChange={this.editScriptsChange}
          onEditSubmit={this.editScriptsSubmit}
        />
      );
    }

    return (
      <Queries
        transformation={this.props.transformation}
        isSaving={this.props.pendingActions.has('save-queries')}
        disabled={this.isDisabled()}
        queries={this.props.editingFields.get(
          'queriesString',
          this.props.transformation.get('queriesString'),
        )}
        splitQueries={this.props.editingFields.get(
          'splitQueries',
          this.props.transformation.get('queries'),
        )}
        isQueriesProcessing={this.props.isQueriesProcessing}
        isChanged={this.props.editingFields.get('queriesChanged', false)}
        highlightQueryNumber={this.props.highlightQueryNumber}
        changeDescription={this.props.editingFields.get('description', '')}
        onDescriptionChange={this.onDescriptionChange}
        onEditCancel={this.editQueriesCancel}
        onEditChange={this.editQueriesChange}
        onEditSubmit={this.editQueriesSubmit}
      />
    );
  },

  isDisabled() {
    return (
      this.props.readOnly || this.props.pendingActions.delete('queries-processing').count() > 0
    );
  },

  onDescriptionChange(description) {
    return ActionCreators.updateTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'description',
      description,
    );
  },

  editScriptsCancel() {
    ActionCreators.cancelTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'queriesString',
    );
    return ActionCreators.cancelTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'queriesChanged',
    );
  },

  editQueriesCancel() {
    ActionCreators.cancelTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'splitQueries',
    );
    ActionCreators.cancelTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'queriesString',
    );
    return ActionCreators.cancelTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'queriesChanged',
    );
  },

  editScriptsChange(newValue) {
    ActionCreators.updateTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'queriesString',
      newValue,
    );
    if (!this.props.editingFields.get('queriesChanged', false)) {
      return ActionCreators.updateTransformationEditingField(
        this.props.bucketId,
        this.props.transformationId,
        'queriesChanged',
        true,
      );
    }
  },

  editQueriesChange(newValue) {
    ActionCreators.updateTransformationEditingField(
      this.props.bucketId,
      this.props.transformationId,
      'queriesString',
      newValue,
    );
    ActionCreators.updateTransformationEditingFieldQueriesString(
      this.props.bucketId,
      this.props.transformationId,
      newValue,
    );
    if (!this.props.editingFields.get('queriesChanged', false)) {
      return ActionCreators.updateTransformationEditingField(
        this.props.bucketId,
        this.props.transformationId,
        'queriesChanged',
        true,
      );
    }
  },

  editScriptsSubmit() {
    return ActionCreators.saveTransformationScript(
      this.props.bucketId,
      this.props.transformationId,
    );
  },

  editQueriesSubmit() {
    return ActionCreators.saveTransformationQueries(
      this.props.bucketId,
      this.props.transformationId,
    );
  },

  handleDeleteMappings(...args) {
    const configData = ConfigurationRowsStore.getConfiguration(
      KEBOOLA_LEGACY_TRANSFORMATION,
      this.props.bucketId,
      this.props.transformationId,
    );

    return InstalledComponentsActionCreators.deleteMappings(configData, ...args);
  },
});

export default TransformationDetailStatic;
