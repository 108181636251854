import React from 'react';
import type { ComponentProps } from 'react';
import * as RRadioGroup from '@radix-ui/react-radio-group';
import { cva } from 'class-variance-authority';

import { cn } from '../../utils';
import { Label } from '../Label';

import { useRadioGroupContext } from './context';

const itemVariants = cva(
  [
    // reset
    'tw-bg-transparent',
    'tw-p-0',
    'tw-peer',
    'tw-cursor-pointer',

    // position
    'tw-flex',
    'tw-items-center',
    'tw-justify-center',

    // disabled
    'disabled:tw-opacity-40',
    'disabled:tw-cursor-not-allowed',
  ],
  {
    defaultVariants: {
      variant: 'radio',
    },
    variants: {
      variant: {
        radio: [
          'tw-border-none',

          'tw-size-2.5',
          'tw-rounded-full',
          'tw-outline',
          'tw-outline-1',
          'tw-outline-offset-2',

          'data-[state=unchecked]:hover:tw-outline-secondary-500',

          'data-[state=unchecked]:tw-outline-neutral-200',
          'data-[state=checked]:tw-outline-secondary-500',
          'data-[state=checked]:tw-bg-secondary-500',

          'focus-visible:tw-ring-2',
          'focus-visible:tw-ring-secondary-200',
        ],
        button: [
          'tw-px-2',
          'tw-py-1.5',
          'tw-text-secondary-500',
          'tw-uppercase',
          'tw-font-semibold',
          'tw-font-sans',
          'tw-leading-[18px]',
          'tw-text-[11px]',
          'tw-w-full',

          // border
          'tw-border',
          'tw-border-solid',
          'tw-border-secondary-500',
          'tw-rounded',

          // hover
          'data-[state=unchecked]:hover:tw-bg-secondary-500',
          'data-[state=unchecked]:hover:tw-text-white',

          // disabled
          'data-[state=unchecked]:disabled:hover:tw-bg-white',
          'data-[state=unchecked]:disabled:hover:tw-text-secondary-500',
          'data-[state=checked]:disabled:tw-cursor-not-allowed',

          // states
          'data-[state=unchecked]:tw-bg-white',
          'data-[state=checked]:tw-bg-secondary-500',
          'data-[state=checked]:tw-text-white',
          'data-[state=checked]:tw-cursor-default',

          'focus-visible:tw-outline',
          'focus-visible:tw-outline-secondary-500',
          'focus-visible:tw-ring-4',
          'focus-visible:tw-ring-secondary-200',
        ],
      },
    },
  },
);

type Props = Omit<ComponentProps<typeof RRadioGroup.Item>, 'asChild'>;

export const RadioGroupItem = ({ children, value, className, ...props }: Props) => {
  const { variant } = useRadioGroupContext();
  const isRadioVariant = variant === 'radio';

  return (
    <div
      className={cn({
        'tw-flex tw-items-center tw-gap-2': isRadioVariant,
      })}
    >
      <RRadioGroup.Item
        id={value}
        value={value}
        className={itemVariants({ variant, className })}
        {...props}
      >
        {!isRadioVariant && children}
      </RRadioGroup.Item>
      {isRadioVariant && (
        <Label className="peer-disabled:tw-opacity-40" htmlFor={value}>
          {children}
        </Label>
      )}
    </div>
  );
};
