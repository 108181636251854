import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import keyCodes from '@/constants/keyCodes';
import { routeNames } from '@/modules/flows/constants';
import { RouterLink as Link } from '@/react/common';
import RoutesStore from '@/stores/RoutesStore';

const BackToFlowButton = (props: { flow: Map<string, any> }) => {
  const params = { config: props.flow.get('id') };
  const state = { scroll: props.flow.get('scroll') };

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        !document.body.classList.contains('modal-open') &&
        !document.body.classList.contains('full-screen-modal-open') &&
        event.key === keyCodes.ESCAPE
      ) {
        event.stopPropagation();
        RoutesStore.getRouter().transitionTo(routeNames.DETAIL, params, null, null, state);
      }
    };

    document.addEventListener('keydown', handleKeyDown, { passive: true });
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <Link className="btn btn-default" to={routeNames.DETAIL} params={params} state={state}>
      <FontAwesomeIcon icon="xmark" />
    </Link>
  );
};

export default BackToFlowButton;
