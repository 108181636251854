import type { GenericFetchClient } from '../managementClient';

import type {
  CreateProjectFeature,
  CreateProjectFeaturePath,
  DeleteProjectFeaturePath,
} from './types';

export class Projects {
  constructor(private readonly client: GenericFetchClient) {}

  async createFeature(projectId: string, signal?: AbortSignal) {
    const { data } = await this.client.post<CreateProjectFeature, CreateProjectFeaturePath>(
      '/projects/{projectId}/features',
      { path: { projectId } },
      { signal },
    );

    return data;
  }

  async deleteFeature(path: DeleteProjectFeaturePath, signal?: AbortSignal) {
    const { data } = await this.client.delete<null, DeleteProjectFeaturePath>(
      '/features/{projectId}/{feature}',
      {
        path,
      },
      { signal },
    );

    return data;
  }
}
