import React from 'react';

import { cn } from '../../utils';

type Height = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

type SkeletonProps = {
  height?: Height;
  className?: string;
  count?: number;
};

const HEIGHT_CLASS_NAME: Record<Height, string> = {
  xxs: 'tw-h-3 tw-rounded',
  xs: 'tw-h-4 tw-rounded',
  sm: 'tw-h-5 tw-rounded',
  md: 'tw-h-6 tw-rounded-md',
  lg: 'tw-h-8 tw-rounded-lg',
  xl: 'tw-h-10 tw-rounded-lg',
  '2xl': 'tw-h-12 tw-rounded-lg',
};

const ANIMATION_CLASS_NAME = cn(
  // base styles
  'tw-relative',
  'tw-overflow-hidden',
  'tw-pointer-events-none',
  'tw-bg-neutral-150',

  // before
  'before:tw-content-[""]',
  'before:tw-opacity-100',
  'before:tw-absolute',
  'before:tw-inset-0',
  'before:-tw-translate-x-full',
  'before:tw-animate-[shimmer_2s_infinite]',
  'before:tw-bg-gradient-to-r',
  'before:tw-from-transparent',
  'before:tw-to-transparent',
  'before:tw-via-neutral-50',
);

export const Skeleton = ({ height = 'md', className, count = 1 }: SkeletonProps) => {
  return Array(count)
    .fill(0)
    .map((_, index) => (
      <div
        key={index}
        className={cn(HEIGHT_CLASS_NAME[height], ANIMATION_CLASS_NAME, className)}
      ></div>
    ));
};
