import React from 'react';

import { cn } from '../../utils';

type Props = {
  children: React.ReactNode;
  space?: 'small' | 'medium' | 'large';
  className?: string;
};

export const ButtonGroup = ({ children, space = 'small', className }: Props) => {
  return (
    <div
      className={cn(className, 'tw-inline-flex tw-items-center', {
        'tw-gap-3': space === 'small',
        'tw-gap-4': space === 'medium',
        'tw-gap-6': space === 'large',
      })}
    >
      {children}
    </div>
  );
};
