import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import { Link, Tooltip } from '@keboola/design';

import { QUERY_TYPE } from '@/modules/ex-facebook/constants';

const API_URL = 'https://developers.facebook.com/tools/explorer';

const GraphAPIExplorerLink = createReactClass({
  propTypes: {
    ids: PropTypes.object.isRequired,
    query: PropTypes.object.isRequired,
    apiVersion: PropTypes.string.isRequired,
    queryType: PropTypes.string,
    isAsyncInsightQueryChecked: PropTypes.bool,
  },

  render() {
    if (this.isQueryValid()) {
      return this.renderLink();
    } else if (this.props.isAsyncInsightQueryChecked) {
      return null;
    } else {
      return this.renderDisabledLink();
    }
  },

  renderDisabledLink() {
    return (
      <Tooltip
        placement="top"
        tooltip={this.tooltip}
        type="explanatory"
        triggerClassName="btn btn-default !tw-block"
      >
        <FontAwesomeIcon icon="arrow-up-right-from-square" className="icon-addon-right" />
        {this.linkText}
      </Tooltip>
    );
  },

  linkText: 'Try Query',

  tooltip: 'Opens configured query in Facebook Graph API Explorer tool',

  generateQueryParams() {
    const version = `version=${this.props.apiVersion}`;
    const path = this.query('path', '');
    const queryIds = this.query('ids');
    const ids = queryIds ? queryIds : this.props.ids.take(50).join(',');
    const paramsStr = this.props.query.delete('path').reduce(
      (memo, value, key) => {
        const pair = key === 'ids' ? `ids=${ids}` : `${key}=${value}`;
        const result = memo.first ? `${memo.result}?${pair}` : `${memo.result}&${pair}`;
        return { first: false, result: result };
      },
      { first: true, result: path },
    );
    return `path=${encodeURIComponent(paramsStr.result)}&${version}`;
  },

  renderLink() {
    const params = this.generateQueryParams();
    const url = `${API_URL}?method=GET&${params}`;
    return (
      <Tooltip
        placement="top"
        tooltip={this.tooltip}
        type="explanatory"
        triggerClassName="tw-block"
      >
        <Link href={url} className="btn btn-default tw-block">
          <FontAwesomeIcon icon="arrow-up-right-from-square" className="icon-addon-right" />
          {this.linkText}
        </Link>
      </Tooltip>
    );
  },

  isQueryValid() {
    const isFields = !!this.query('fields');
    const isParameters = !!this.query('parameters');
    const isEndpoint = !!this.query('path');
    return isFields || isParameters || isEndpoint;
  },

  query(path, defaultValue) {
    let query = this.props.query;

    if (this.props.queryType === QUERY_TYPE.ASYNC_INSIGHTS) {
      query = query.delete('fields').delete('path');
    } else {
      query = query.delete('parameters');
    }

    return query.getIn([].concat(path), defaultValue);
  },
});

export default GraphAPIExplorerLink;
