import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, Radio } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { URLS } from '@keboola/constants';
import { HelpBlock, Link } from '@keboola/design';

import ExternalBucketLink from '@/modules/components/react/components/ExternalBucketLink';
import ExternalProjectLink from '@/modules/components/react/components/ExternalProjectLink';
import SyncActionSimpleValue from '@/modules/configurations/react/components/SyncActionSimpleValue';

const Destination = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      destination: PropTypes.string.isRequired,
      mode: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    actions: PropTypes.object,
  },

  render() {
    const infoAction = this.props.actions.get('info');

    return (
      <div className="box">
        <div className="box-header big-padding bb">
          <h3 className="box-title">Destination</h3>
        </div>
        <div className="box-content">
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Project</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl.Static>
                <ExternalProjectLink
                  stackUrl={infoAction.getIn(['request', 'configData', 'parameters', 'url'])}
                  projectId={infoAction.getIn(['data', 'projectId'])}
                >
                  <SyncActionSimpleValue action={infoAction} valueKey="projectName" />
                </ExternalProjectLink>
              </FormControl.Static>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Bucket</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl.Static>
                <ExternalBucketLink
                  stackUrl={infoAction.getIn(['request', 'configData', 'parameters', 'url'])}
                  projectId={infoAction.getIn(['data', 'projectId'])}
                  bucketId={infoAction.getIn(['data', 'bucket'])}
                >
                  <SyncActionSimpleValue action={infoAction} valueKey="bucket" />
                </ExternalBucketLink>
              </FormControl.Static>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Table Name</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.value.destination}
                onChange={(event) =>
                  this.props.onChange({ destination: event.target.value.trim() })
                }
                placeholder="mytable"
                disabled={this.props.disabled}
              />
              <HelpBlock className="tw-mt-1">
                Name of the table stored in the target project&apos;s bucket.
              </HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Mode</ControlLabel>
            </div>
            <div className="col-xs-8">
              <Radio
                value="update"
                checked={this.props.value.mode === 'update'}
                onChange={(event) => this.props.onChange({ mode: event.target.value })}
                disabled={this.props.disabled}
              >
                Update
              </Radio>
              <HelpBlock className="tw-mt-1">
                Use{' '}
                <Link href={`${URLS.USER_DOCUMENTATION}/storage/tables/#incremental-loading`}>
                  incremental loading
                </Link>{' '}
                on the target table.
              </HelpBlock>
              <Radio
                value="replace"
                checked={this.props.value.mode === 'replace'}
                onChange={(event) => this.props.onChange({ mode: event.target.value })}
                disabled={this.props.disabled}
              >
                Replace
              </Radio>
              <HelpBlock className="tw-mt-1">
                Replace data in the target table. If the structures of the source and destination
                tables do not match, an error will be reported.
              </HelpBlock>
              <Radio
                value="recreate"
                checked={this.props.value.mode === 'recreate'}
                onChange={(event) => this.props.onChange({ mode: event.target.value })}
                disabled={this.props.disabled}
              >
                Recreate
              </Radio>
              <HelpBlock className="tw-mt-1">
                Drop and create the target table. This will make sure that the structure of the
                destination table matches that of the source table.
              </HelpBlock>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  },
});

export default Destination;
