import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { Alert, Button, HelpBlock, Tooltip } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import type { ExistingApp } from '@/api/routes/dataScienceService';
import date from '@/date';
import { updateApp } from '@/modules/data-apps/actions';
import {
  AUTO_SLEEP_NOTE,
  DEFAULT_EXPIRATION,
  EXPIRATION_PATH,
  EXPIRATION_PATH_FALLBACK,
  MINIMUM_EXPIRTION,
  SIZE_PATH,
} from '@/modules/data-apps/constants';
import { isAppConfigured, prepareExpiration } from '@/modules/data-apps/helpers';
import WorkspaceSizeControl from '@/modules/sandboxes/components/WorkspaceSizeControl';
import { SANDBOX_SIZES, SANDBOX_TYPE } from '@/modules/sandboxes/Constants';
import { RowActionMenuItem } from '@/react/common';
import ConfirmModal from '@/react/common/ConfirmModal';
import InfoTooltip from '@/react/common/InfoTooltip';
import { EXPIRATION_OPTIONS } from '@/react/common/TimeoutControl/constants';
import TimeoutControl from '@/react/common/TimeoutControl/TimeoutControl';

const DeployDataApp = (props: {
  mode: 'sidebar' | 'menuitem';
  config: Map<string, any>;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  app?: ExistingApp;
  onKeyDown?: (event: React.KeyboardEvent) => void;
}) => {
  const [size, setSize] = React.useState(SANDBOX_SIZES.TINY);
  const [expiration, setExpiration] = React.useState(DEFAULT_EXPIRATION);

  const [deploying, setDeploying] = React.useState(false);
  const [showConfirmModal, setShowConfirmModal] = React.useState(false);
  const [showCustomExpiration, setCustomExpiration] = React.useState(false);

  const isConfigured = isAppConfigured(props.config);

  const isDisabled = props.readOnly || !isConfigured || deploying;

  const handleSetExpiration = (value: number, customExpiration: boolean) => {
    setExpiration(value);
    setCustomExpiration(customExpiration);
  };

  const handleDeploy = () => {
    const config = props.config
      .setIn(SIZE_PATH, size)
      .setIn(EXPIRATION_PATH, expiration)
      .deleteIn(EXPIRATION_PATH_FALLBACK);

    setDeploying(true);
    return updateApp(props.app, config, 'running')
      .then(() => {
        ApplicationActionCreators.sendNotification({
          message: () => {
            return (
              <>
                <strong>{config.get('name')}</strong> data app is starting.
              </>
            );
          },
        });

        // prevent another deployment for 10 seconds
        setTimeout(() => setDeploying(false), 10000);
      })
      .catch(() => setDeploying(false));
  };

  const handleClick = () => setShowConfirmModal(true);

  const label = `${!props.app?.url ? 'Deploy' : 'Start'} Data App`;

  const renderDeployAction = () => {
    if (props.mode === 'sidebar') {
      return (
        <Button className="tw-w-full" disabled={isDisabled} onClick={handleClick}>
          <FontAwesomeIcon icon="play-circle" />
          {label}
        </Button>
      );
    }

    return (
      <RowActionMenuItem onSelect={handleClick} disabled={isDisabled} onKeyDown={props.onKeyDown}>
        <FontAwesomeIcon icon="play-circle" fixedWidth />
        {label}
      </RowActionMenuItem>
    );
  };

  return (
    <>
      {isConfigured ? (
        renderDeployAction()
      ) : (
        <Tooltip tooltip="Data App is not configured" placement="top">
          {renderDeployAction()}
        </Tooltip>
      )}
      <ConfirmModal
        closeAfterResolve
        isLoading={deploying}
        isDisabled={expiration < MINIMUM_EXPIRTION}
        show={showConfirmModal}
        onEnter={() => {
          const expiration = prepareExpiration(props.config);

          setSize(props.config.getIn(SIZE_PATH, SANDBOX_SIZES.TINY));
          handleSetExpiration(
            expiration,
            !EXPIRATION_OPTIONS.some(({ value }) => value === expiration),
          );
        }}
        onHide={() => setShowConfirmModal(false)}
        icon="play-circle"
        title={label}
        buttonLabel={label}
        text={
          <>
            {props.app?.state === 'running' && (
              <Alert className="tw-mb-5">
                App is currently running. Are you sure you want to deploy a new version?
              </Alert>
            )}
            <WorkspaceSizeControl
              type={SANDBOX_TYPE.STREAMLIT}
              value={size}
              onChange={setSize}
              isDisabled={isDisabled}
              hasPayAsYouGo={props.hasPayAsYouGo}
            />
            <TimeoutControl
              value={expiration}
              onChange={handleSetExpiration}
              isDisabled={isDisabled}
              showCustom={showCustomExpiration}
              label={
                <>
                  Inactivity Timeout
                  <InfoTooltip tooltip={AUTO_SLEEP_NOTE} />
                </>
              }
              help={
                <HelpBlock
                  className="tw-mt-1"
                  state={expiration < MINIMUM_EXPIRTION ? 'error' : 'default'}
                >
                  The minimum timeout is {date.duration(MINIMUM_EXPIRTION, 'second').humanize()}.
                </HelpBlock>
              }
            />
          </>
        }
        onConfirm={handleDeploy}
      />
    </>
  );
};

export default DeployDataApp;
