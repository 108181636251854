import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map, Set } from 'immutable';

import { Clipboard, cn, Tooltip } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { isInternal } from '@/constants/helpers';
import { deleteFiles } from '@/modules/storage/actions';
import { fileExpirationStatus } from '@/modules/storage/helpers';
import { CreatedDate, FileSize } from '@/react/common';
import ConfirmModal from '@/react/common/ConfirmModal';
import FileLink from '@/react/common/FileLink';
import Loader from '@/react/common/Loader';
import MultiActionsHeader from '@/react/common/MultiActionsHeader';
import MultiActionsSelectCheckbox from '@/react/common/MultiActionsSelectCheckbox';
import FileLinkButton from './FileLinkButton';
import FileTags from './FileTags';
import MultipleDownloadModal from './MultipleDownloadModal';

const FilesTable = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    canDownloadSlicedFile: PropTypes.bool.isRequired,
    files: PropTypes.instanceOf(Map).isRequired,
    admins: PropTypes.instanceOf(Map).isRequired,
    downloadingFiles: PropTypes.instanceOf(List).isRequired,
    onSearchQuery: PropTypes.func.isRequired,
    onDeleteFile: PropTypes.func.isRequired,
    isDeleting: PropTypes.object.isRequired,
    searchQuery: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      selected: Set(),
      showDeleteModal: false,
      showMultipleDeleteModal: false,
      showMultipleDownloadModal: false,
      deleteFile: null,
    };
  },

  render() {
    if (!this.props.files.count()) {
      return null;
    }

    return (
      <>
        {this.renderDeleteModal()}
        {this.renderMultipleDeleteModal()}
        {this.renderMultipleDownloadModal()}
        <table className="table table-hover overflow-break-anywhere">
          <thead>
            <tr>
              <th>
                <MultiActionsHeader
                  entity="file"
                  hide={this.props.readOnly}
                  totalCount={this.props.files.count()}
                  selectedCount={this.state.selected.count()}
                  disabled={!this.props.isDeleting.isEmpty()}
                  onToggleAll={(checked) => {
                    this.setState({
                      selected: !checked
                        ? Set()
                        : this.props.files.map((file) => file.get('id')).toSet(),
                    });
                  }}
                  placeholder="Name"
                >
                  <div className="table-action-buttons">
                    <Tooltip placement="top" tooltip="Delete Selected">
                      <Button
                        bsStyle="link"
                        className="text-muted"
                        onClick={() => this.setState({ showMultipleDeleteModal: true })}
                        disabled={!this.props.isDeleting.isEmpty()}
                      >
                        {!this.props.isDeleting.isEmpty() ? (
                          <Loader />
                        ) : (
                          <FontAwesomeIcon icon="trash" fixedWidth />
                        )}
                      </Button>
                    </Tooltip>
                    <Tooltip placement="top" tooltip="Download Selected">
                      <Button
                        bsStyle="link"
                        className="text-muted"
                        onClick={() => this.setState({ showMultipleDownloadModal: true })}
                      >
                        <FontAwesomeIcon icon="circle-down" fixedWidth />
                      </Button>
                    </Tooltip>
                  </div>
                </MultiActionsHeader>
              </th>
              <th className="w-150">File ID</th>
              <th className="w-300">Detail</th>
              <th className="w-200">Status</th>
            </tr>
          </thead>
          <tbody>
            {this.props.files
              .sortBy((file) => -1 * file.get('id'))
              .map(this.renderRow)
              .toArray()}
          </tbody>
        </table>
      </>
    );
  },

  renderRow(file) {
    const isDeleting = this.props.isDeleting.get(file.get('id'), false);
    const admin = this.props.admins.get(file.getIn(['creatorToken', 'description']));
    const expirationStatus = fileExpirationStatus(file);

    return (
      <tr key={file.get('id')} className="hoverable-actions-with-replacement">
        <td>
          <div className="flex-container flex-start">
            {!this.props.readOnly && (
              <MultiActionsSelectCheckbox
                entity="file"
                isChecked={this.state.selected.has(file.get('id'))}
                isDisabled={isDeleting}
                onToggle={(checked) => {
                  this.setState({
                    selected: checked
                      ? this.state.selected.add(file.get('id'))
                      : this.state.selected.delete(file.get('id')),
                  });
                }}
              />
            )}
            <span className={cn({ 'ml-1': !this.props.readOnly })}>
              {this.props.readOnly || expirationStatus.expired ? (
                file.get('name')
              ) : (
                <FileLink file={file} showFilesize={false} linkClass="color-inherit" />
              )}
              {this.renderClipboard(file, expirationStatus)}

              <FileTags
                file={file}
                readOnly={this.props.readOnly}
                onSearchQuery={this.props.onSearchQuery}
              />
            </span>
          </div>
        </td>
        <td>{this.renderFileLink(file)}</td>
        <td>
          <div className="flex-container">
            <span>File size</span>
            <FileSize size={file.get('sizeBytes')} />
          </div>
          <div className="flex-container">
            <span className="no-wrap">Created</span>
            <span className="text-right">
              {admin
                ? admin.get('name') || admin.get('email')
                : isInternal(file.getIn(['creatorToken', 'description']), 'schedule')
                  ? 'Scheduled run'
                  : file.getIn(['creatorToken', 'description'])}
            </span>
          </div>
          <div className="flex-container">
            <span>Uploaded</span>
            <CreatedDate createdTime={file.get('created')} />
          </div>
        </td>
        {this.props.readOnly || expirationStatus.expired ? (
          <td>{this.renderStatus(file, expirationStatus)}</td>
        ) : (
          <td className="no-wrap">
            {this.props.downloadingFiles.includes(file.get('id')) ? (
              <span className="text-muted">
                <Loader className="btn-icon" />
                Preparing file
              </span>
            ) : (
              <div className="actions-container">
                <div className="not-actions">{this.renderStatus(file, expirationStatus)}</div>
                <div className="actions">
                  <FileLinkButton file={file} />
                  {this.renderDeleteFile(file)}
                </div>
              </div>
            )}
          </td>
        )}
      </tr>
    );
  },

  renderStatus(file, expirationStatus) {
    return (
      <>
        {file.get('isPublic') && <div className="text-muted">Public</div>}
        {!file.get('isEncrypted') && <div className="text-muted">Not encrypted</div>}
        {this.expiration(expirationStatus)}
      </>
    );
  },

  renderFileLink(file) {
    const preparedSearchQuery = `id:${file.get('id')}`;

    if (this.props.searchQuery === preparedSearchQuery) {
      return file.get('id');
    }

    return (
      <Button
        bsStyle="link"
        className="btn-link-inline color-inherit"
        onClick={() => this.props.onSearchQuery(preparedSearchQuery)}
      >
        {file.get('id')}
      </Button>
    );
  },

  renderDeleteModal() {
    const file = this.state.deleteFile;

    if (!file) {
      return null;
    }

    return (
      <ConfirmModal
        show={this.state.showDeleteModal}
        onHide={this.closeDeleteModal}
        icon="trash"
        title="Delete File"
        text={
          <p>
            Are you sure you want to delete the file {file.get('id')} ({file.get('name')})?
          </p>
        }
        buttonLabel="Delete"
        buttonType="danger"
        onConfirm={() =>
          this.props.onDeleteFile(file.get('id')).then(() => {
            ApplicationActionCreators.sendNotification({
              type: 'info',
              message: () => (
                <>
                  File <b>{file.get('name')}</b> has been removed.
                </>
              ),
            });
          })
        }
      />
    );
  },

  renderMultipleDeleteModal() {
    return (
      <ConfirmModal
        closeAfterResolve
        show={this.state.showMultipleDeleteModal}
        icon="trash"
        title="Delete Selected"
        text={`Are you sure you want to delete the ${
          this.state.selected.count() > 1 ? 'selected files' : 'file'
        }?`}
        buttonLabel="Delete"
        buttonType="danger"
        onConfirm={() => {
          return deleteFiles(this.state.selected.toArray()).then(() =>
            this.setState({ selected: Set() }),
          );
        }}
        onHide={() => this.setState({ showMultipleDeleteModal: false })}
        isLoading={!this.props.isDeleting.isEmpty()}
      />
    );
  },

  renderMultipleDownloadModal() {
    return (
      <MultipleDownloadModal
        show={this.state.showMultipleDownloadModal}
        files={this.props.files}
        selectedIds={this.state.selected}
        canDownloadSlicedFile={this.props.canDownloadSlicedFile}
        downloadingFiles={this.props.downloadingFiles}
        onConfirm={() => this.setState({ selected: Set() })}
        onHide={() => this.setState({ showMultipleDownloadModal: false })}
      />
    );
  },

  expiration(status) {
    if (status.permanent) {
      return <div className="text-muted">{status.text}</div>;
    }

    return <div className={status.expired ? 'text-danger' : 'text-success'}>{status.text}</div>;
  },

  renderClipboard(file, expirationStatus) {
    if (file.get('isSliced') || expirationStatus.expired) {
      return null;
    }

    return (
      <Clipboard
        tooltipText="Copy file URL to clipboard"
        tooltipPlacement="top"
        text={file.get('url')}
      />
    );
  },

  renderDeleteFile(file) {
    const isDeleting = this.props.isDeleting.get(file.get('id'), false);

    return (
      <Tooltip tooltip="Delete file" placement="top">
        <Button
          bsStyle="link"
          className="text-muted"
          disabled={isDeleting}
          onClick={() => this.openDeleteModal(file)}
        >
          {isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
        </Button>
      </Tooltip>
    );
  },

  openDeleteModal(file) {
    this.setState({
      showDeleteModal: true,
      deleteFile: file,
    });
  },

  closeDeleteModal() {
    this.setState({
      showDeleteModal: false,
      deleteFile: null,
    });
  },
});

export default FilesTable;
