import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { ButtonInline } from '@keboola/design';

import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import {
  DEFAULT_DATE_RANGES,
  endpointTypes,
  MCF_DIMENSIONS,
  MCF_METRICS,
  PREFERED_DATA_API_DIMENSTIONS,
  PREFERED_DATA_API_METRICS,
  PREFERED_DIMENSIONS,
  PREFERED_METRICS,
} from '@/modules/ex-google-analytics-v4/constants';
import string from '@/utils/string';
import AntiSamplingModal from './AntiSamplingModal';
import DataApiFilters from './DataApiFilters';
import DateRangesSelector from './DateRangesSelector';
import EndpointSelect from './EndpointSelect';
import GaMultiSelect from './GaMultiSelect';
import ProfileSelector from './ProfileSelector';
import QuerySample from './QuerySample';

const QueryEditor = createReactClass({
  propTypes: {
    allProfiles: PropTypes.instanceOf(List).isRequired,
    hasOldProfiles: PropTypes.bool.isRequired,
    hasNewProfiles: PropTypes.bool.isRequired,
    query: PropTypes.instanceOf(Map).isRequired,
    endpoint: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onRunQuery: PropTypes.func.isRequired,
    metadata: PropTypes.instanceOf(Map).isRequired,
    sampleDataInfo: PropTypes.instanceOf(Map).isRequired,
    accountSegments: PropTypes.instanceOf(Map).isRequired,
    isQueryValid: PropTypes.bool.isRequired,
    isLoadingMetadata: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    outputBucket: PropTypes.string.isRequired,
    isNewDataApiActive: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      showAntiSamplingModal: false,
    };
  },

  render() {
    const {
      allProfiles,
      hasOldProfiles,
      hasNewProfiles,
      query,
      endpoint,
      onRunQuery,
      sampleDataInfo,
      accountSegments,
      isQueryValid,
      isLoadingMetadata,
      readOnly,
      outputBucket,
      isNewDataApiActive,
    } = this.props;

    return (
      <>
        <div className="form-horizontal">
          {hasOldProfiles && (
            <EndpointSelect
              selectedValue={endpoint}
              onSelectValue={this.onSelectEndpoint}
              name={'Endpoint'}
              disabled={false}
              hasNewProfiles={hasNewProfiles}
            />
          )}
          <div className="form-group">
            <label className="col-md-2 control-label" htmlFor="outputTable">
              Output Table
            </label>
            <div className="col-md-10">
              {!readOnly ? (
                <input
                  type="text"
                  className="form-control"
                  id="outputTable"
                  value={query.get('outputTable', '')}
                  placeholder={string.sanitizeKbcTableIdString(query.get('name') || '')}
                  onChange={(e) => {
                    this.props.onChange(
                      query.set('outputTable', string.sanitizeKbcTableIdString(e.target.value)),
                    );
                  }}
                />
              ) : (
                <p className="form-control-static" id="outputTable">
                  <StorageApiTableLinkEx tableId={`${outputBucket}.${query.get('outputTable')}`} />
                </p>
              )}
            </div>
          </div>
          <ProfileSelector
            isEditing={!readOnly}
            isNewDataApiActive={isNewDataApiActive}
            allProfiles={allProfiles}
            selectedProfile={query.getIn(['query', 'viewId'], '')}
            onSelectProfile={this.onChangePropertyFn(['query', 'viewId'])}
          />
          <GaMultiSelect
            isEditing={!readOnly}
            isLoadingMetadata={isLoadingMetadata}
            metadata={this.getMetrics(endpoint)}
            name="Metrics"
            preferedOrderIds={this.getPreferredMetrics(endpoint)}
            onSelectValue={this.onSelectMetric}
            selectedValues={this.getSelectedMetrics()}
          />
          <GaMultiSelect
            isEditing={!readOnly}
            isLoadingMetadata={isLoadingMetadata}
            metadata={this.getDimensions(endpoint)}
            name="Dimensions"
            preferedOrderIds={this.getPreferredDimensions(endpoint)}
            onSelectValue={this.onSelectDimension}
            selectedValues={this.getSelectedDimensions()}
          />
          {!isNewDataApiActive && endpoint === endpointTypes.ENDPOINT_REPORT && (
            <GaMultiSelect
              isEditing={!readOnly}
              isLoadingMetadata={accountSegments.get('isLoading', false)}
              metadata={accountSegments.get('data', List()).toJS()}
              name="Segments (optional)"
              onSelectValue={this.onSelectSegment}
              selectedValues={this.getSelectedSegments()}
            />
          )}
          {isNewDataApiActive ? (
            <DataApiFilters
              query={query.get('query', Map())}
              onChange={(updateQuery) => this.props.onChange(query.set('query', updateQuery))}
            />
          ) : (
            <div className="form-group">
              <div className="col-md-2 control-label">Filters (optional)</div>
              <div className="col-md-10">
                <FormControl
                  placeholder="e.g ga:sourceMedium=~(something)"
                  value={query.getIn(['query', 'filtersExpression']) || ''}
                  onChange={(e) => {
                    this.props.onChange(
                      query.setIn(['query', 'filtersExpression'], e.target.value),
                    );
                  }}
                />
              </div>
            </div>
          )}
          <div className="form-group">
            <div className="col-md-2 control-label">Anti-sampling</div>
            <div className="col-md-10">
              <FormControl.Static>
                <ButtonInline
                  variant="dark"
                  onClick={() => this.setState({ showAntiSamplingModal: true })}
                >
                  {query.get('antisampling') || 'None'}
                  <FontAwesomeIcon icon="pen" fixedWidth className="tw-ml-1" />
                </ButtonInline>
              </FormControl.Static>
            </div>
          </div>
          <DateRangesSelector
            isEditing={!readOnly}
            ranges={query.getIn(['query', 'dateRanges'], DEFAULT_DATE_RANGES)}
            onChange={this.onChangePropertyFn(['query', 'dateRanges'])}
            maxRanges={this.getMaxRanges()}
          />
        </div>
        {!readOnly && !isNewDataApiActive && (
          <QuerySample
            onRunQuery={onRunQuery}
            isQueryValid={isQueryValid}
            sampleDataInfo={sampleDataInfo}
          />
        )}
        <AntiSamplingModal
          show={this.state.showAntiSamplingModal}
          onHide={() => this.setState({ showAntiSamplingModal: false })}
          hasAdaptive={!isNewDataApiActive && endpoint === endpointTypes.ENDPOINT_REPORT}
          onSave={(value) => {
            const newQuery = value
              ? query.set('antisampling', value)
              : query.delete('antisampling');

            this.props.onChange(newQuery);
          }}
          value={query.get('antisampling', '')}
        />
      </>
    );
  },

  getMetrics(endpoint) {
    if (endpoint === endpointTypes.ENDPOINT_MCF) {
      return MCF_METRICS.map((item) => ({
        id: item,
        attributes: {
          uiName: item,
          description: '',
        },
      }));
    }

    return this.props.metadata
      .get(this.props.isNewDataApiActive ? 'dataApiMetrics' : 'metrics', List())
      .toJS();
  },

  getDimensions(endpoint) {
    if (endpoint === endpointTypes.ENDPOINT_MCF) {
      return MCF_DIMENSIONS.map((item) => ({
        id: item,
        attributes: {
          uiName: item,
          description: '',
        },
      }));
    }

    return this.props.metadata
      .get(this.props.isNewDataApiActive ? 'dataApiDimensions' : 'dimensions', List())
      .toJS();
  },

  getPreferredMetrics(endpoint) {
    if (this.props.isNewDataApiActive) {
      return PREFERED_DATA_API_METRICS;
    }

    if (endpoint === endpointTypes.ENDPOINT_MCF) {
      return MCF_METRICS;
    }

    return PREFERED_METRICS;
  },

  getPreferredDimensions(endpoint) {
    if (this.props.isNewDataApiActive) {
      return PREFERED_DATA_API_DIMENSTIONS;
    }

    if (endpoint === endpointTypes.ENDPOINT_MCF) {
      return MCF_DIMENSIONS;
    }

    return PREFERED_DIMENSIONS;
  },

  onSelectMetric(metrics) {
    const newMetrics = metrics.map((value) =>
      Map(this.props.isNewDataApiActive ? { name: value } : { expression: value }),
    );
    const newQuery = this.props.query.setIn(['query', 'metrics'], newMetrics);

    this.props.onChange(newQuery);
  },

  getSelectedMetrics() {
    return this.props.query
      .getIn(['query', 'metrics'], List())
      .map((metric) => metric.get(this.props.isNewDataApiActive ? 'name' : 'expression'));
  },

  onSelectDimension(dimensions) {
    const newDimensions = dimensions.map((dimension) => Map({ name: dimension }));
    const newQuery = this.props.query.setIn(['query', 'dimensions'], newDimensions);

    this.props.onChange(newQuery);
  },

  getSelectedDimensions() {
    return this.props.query
      .getIn(['query', 'dimensions'], List())
      .map((dimension) => dimension.get('name'));
  },

  onSelectSegment(segments) {
    const newSegments = segments.map((segment) => Map({ segmentId: segment }));
    const newQuery = this.props.query.setIn(['query', 'segments'], newSegments);

    this.props.onChange(newQuery);
  },

  getSelectedSegments() {
    return this.props.query
      .getIn(['query', 'segments'], List())
      .map((segment) => segment.get('segmentId'));
  },

  onChangePropertyFn(propName, getValueFnParam) {
    return (event) => {
      const value = getValueFnParam?.(event) || event;
      const newQuery = this.props.query.setIn([].concat(propName), value);

      this.props.onChange(newQuery);
    };
  },

  onSelectEndpoint(selectedEndpoint) {
    if (this.props.query.get('endpoint') !== selectedEndpoint) {
      this.props.onChange(
        Map({
          id: this.props.query.get('id'),
          name: this.props.query.get('name'),
          enabled: this.props.query.get('enabled'),
          outputTable: this.props.query.get('outputTable'),
          endpoint: selectedEndpoint,
          query: Map({
            dateRanges: DEFAULT_DATE_RANGES,
          }),
        }),
      );
    }
  },

  getMaxRanges() {
    if (this.props.isNewDataApiActive) {
      return 4;
    }

    if (this.props.query.get('endpoint') === endpointTypes.ENDPOINT_MCF) {
      return 1;
    }

    return 2;
  },
});

export default QueryEditor;
