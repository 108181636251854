import React from 'react';
import createReactClass from 'create-react-class';

import { FILTERS, FILTERS_GROUP } from '@/constants';
import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '@/constants/features';
import { canExportTable, canManageBuckets, canWriteBucket } from '@/modules/admin/privileges';
import { getAllowedTransformations } from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import {
  filterDevBranchBuckets,
  filterProductionAndCurrentDevBranchBuckets,
  filterProductionBuckets,
} from '@/modules/dev-branches/helpers';
import { prepareSandboxes } from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import { factory as eventsFactory } from '@/modules/sapi-events/BucketEventsService';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { TabLink, TabNav } from '@/react/common';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import BucketActions from './components/BucketActions';
import BucketOverview from './components/BucketOverview';
import BucketEvents from './components/Events';
import SearchContextDropdown from './components/SearchContextDropdown';
import { toggleContextFilter } from './actions';
import { bucketTabs, routeNames } from './constants';
import StorageStore from './store';

const Bucket = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      StorageStore,
      ComponentsStore,
      InstalledComponentsStore,
      SandboxesStore,
      RoutesStore,
      BucketsStore,
      TablesStore,
    ),
  ],

  getStateFromStores() {
    const bucketId = RoutesStore.getCurrentRouteParam('bucketId');
    const bucket = BucketsStore.getBucket(bucketId);
    const sapiToken = ApplicationStore.getSapiToken();
    const allBuckets = BucketsStore.getAll();
    const allTables = TablesStore.getAll();
    const tables = allTables.filter((table) => table.getIn(['bucket', 'id']) === bucketId);
    const searchFilters = StorageStore.getSearchFilters();
    const contextFilter = StorageStore.getContextFilter();
    const isDevModeActive = DevBranchesStore.isDevModeActive();
    const availableBuckets = isDevModeActive
      ? filterProductionAndCurrentDevBranchBuckets(allBuckets)
      : contextFilter && searchFilters.get(FILTERS_GROUP.ENTITY) === FILTERS.DEV
        ? filterDevBranchBuckets(allBuckets)
        : filterProductionBuckets(allBuckets);

    return {
      bucket,
      sapiToken,
      tables,
      allTables,
      allBuckets,
      contextFilter,
      availableBuckets,
      isDevModeActive,
      activeTab: RoutesStore.getCurrentRouteParam('bucketTab') || bucketTabs.OVERVIEW,
      indexSearchFilters: searchFilters,
      indexSearchQuery: StorageStore.getSearchQuery(),
      configurations: InstalledComponentsStore.getAll(),
      components: ComponentsStore.getAll(),
      updatingBucket: BucketsStore.isUpdatingBucket(bucketId),
      pendingActions: BucketsStore.getPendingBucketsActions(),
      deletingTables: TablesStore.getDeletingTables(),
      exportingTables: TablesStore.getExportingTables(),
      createSnapshotsTables: TablesStore.getCreatingSnapshotsTables(),
      truncatingTables: TablesStore.getTruncatingTables(),
      canExportTable: canExportTable(sapiToken),
      canWriteBucket: canWriteBucket(sapiToken, bucket),
      canManageBuckets: canManageBuckets(sapiToken),
      admins: ApplicationStore.getAdmins(),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      availableUsersOptions: ApplicationStore.getSharingToAdminsData(),
      availableProjectsOptions: ApplicationStore.getSharingToProjectsData(),
      sandboxes: prepareSandboxes(
        SandboxesStore.getSandboxes(),
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES),
      ),
      allowedTransformationComponents: getAllowedTransformations(
        ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        ApplicationStore.getSapiToken(),
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll(),
      ),
      hasFlows: ApplicationStore.hasFlows(),
      hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
      ),
    };
  },

  render() {
    if (!this.state.bucket) {
      return <p>Bucket not found</p>;
    }

    return (
      <>
        <div className="tabs-with-border-wrapper flex-container flex-start align-top">
          <SearchContextDropdown
            activeBucket={this.state.bucket.get('id')}
            searchQuery={this.state.indexSearchQuery}
            searchFilters={this.state.indexSearchFilters}
            contextFilter={this.state.contextFilter}
            toggleContextFilter={toggleContextFilter}
            tables={this.state.allTables}
            buckets={this.state.availableBuckets}
            activeTab={this.state.activeTab}
          />
          <TabNav>
            {this.renderTabLink(bucketTabs.OVERVIEW, 'Overview', 'ml-1')}
            {this.renderTabLink(bucketTabs.EVENTS, 'Events')}
          </TabNav>
          <div className="ml-auto flex-container flex-end">
            <BucketActions bucket={this.state.bucket} />
          </div>
        </div>
        <div key={this.state.bucket.get('id')}>{this.renderTabContent()}</div>
      </>
    );
  },

  renderTabLink(tab, label, className) {
    return (
      <TabLink
        active={this.state.activeTab === tab}
        to={routeNames.BUCKET}
        params={{ bucketId: this.state.bucket.get('id'), bucketTab: tab }}
        className={className}
      >
        {label}
      </TabLink>
    );
  },

  renderTabContent() {
    if (this.state.activeTab === bucketTabs.EVENTS) {
      return (
        <BucketEvents
          eventsFactory={eventsFactory(this.state.bucket.get('id'))}
          admins={this.state.admins}
        />
      );
    }

    return (
      <BucketOverview
        components={this.state.components}
        configurations={this.state.configurations}
        allowedTransformationComponents={this.state.allowedTransformationComponents}
        sandboxes={this.state.sandboxes}
        canManageBuckets={this.state.canManageBuckets}
        sapiToken={this.state.sapiToken}
        buckets={this.state.allBuckets}
        bucket={this.state.bucket}
        tables={this.state.tables}
        allTables={this.state.allTables}
        isDevModeActive={this.state.isDevModeActive}
        updatingBucket={this.state.updatingBucket}
        pendingActions={this.state.pendingActions}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
        canExportTable={this.state.canExportTable}
        hasFlows={this.state.hasFlows}
        canWriteBucket={this.state.canWriteBucket}
        urlTemplates={this.state.urlTemplates}
        deletingTables={this.state.deletingTables}
        truncatingTables={this.state.truncatingTables}
        exportingTables={this.state.exportingTables}
        createSnapshotsTables={this.state.createSnapshotsTables}
        availableUsersOptions={this.state.availableUsersOptions}
        availableProjectsOptions={this.state.availableProjectsOptions}
        hasSnowflakePartnerConnectLimited={this.state.hasSnowflakePartnerConnectLimited}
      />
    );
  },
});

export default Bucket;
