import React from 'react';
import { ControlLabel, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import type { Map } from 'immutable';

import { URLS } from '@keboola/constants';
import { ButtonInline, IconButton, Link, Tooltip } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import SapiTableLink from '@/modules/components/react/components/StorageApiTableLink';
import { filterProductionAndCurrentDevBranchTables } from '@/modules/dev-branches/helpers';
import { EXTERNAL_DATASET_DISABLE_TOOLTIP, routeNames } from '@/modules/storage/constants';
import { tableDisplayNameWithBucketAndStage, tableName } from '@/modules/storage/helpers';
import dataStreamActions from '@/modules/stream/actions';
import NewStreamModal from '@/modules/stream/components/NewStreamModal';
import type { StoreSource } from '@/modules/stream/store';
import { CreatedDate, RouterLink } from '@/react/common';
import BucketStageLabel from '@/react/common/BucketStageLabel';
import DevBranchLabel from '@/react/common/DevBranchLabel';
import InfoTooltip from '@/react/common/InfoTooltip';
import Select from '@/react/common/Select';
import { tableLabel } from '@/react/common/selectLabels';
import { DataStreamIcon } from './DataStreamIcon';

type Props = {
  tables: Map<string, any>;
  buckets: Map<string, any>;
  period: string;
  selected: string[];
  hasDataStreams: boolean;
  onAddTable: (tableId: string) => void;
  onChangePeriod: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRemoveTable: (tableId: string) => void;
  dataStreamSources?: StoreSource[];
};

const EventTrigger = (props: Props) => {
  const [showNewStreamModal, setShowNewStreamModal] = React.useState(false);

  React.useEffect(() => {
    if (props.hasDataStreams && !props.dataStreamSources?.length) {
      dataStreamActions.loadSources();
    }
  }, [props.hasDataStreams, props.dataStreamSources]);

  const selectedTables = props.tables.filter((table: Map<string, any>) => {
    return props.selected.includes(table.get('id'));
  });

  return (
    <>
      <FormGroup>
        <div className="tw-flex tw-items-center tw-justify-between">
          <ControlLabel>
            Set tables
            <InfoTooltip
              tooltip={
                <>
                  Once all selected tables are updated since the last Flow execution, the Flow will
                  run.{' '}
                  <Link href={`${URLS.USER_DOCUMENTATION}/orchestrator/running/#event-trigger`}>
                    Learn More
                  </Link>
                </>
              }
            />
          </ControlLabel>
          {props.hasDataStreams && (
            <ButtonInline onClick={() => setShowNewStreamModal(true)}>
              Create stream table trigger
            </ButtonInline>
          )}
        </div>
        <Select
          clearable={false}
          placeholder="Add tables"
          options={filterProductionAndCurrentDevBranchTables(props.tables, props.buckets)
            .filter((table: Map<string, any>) => !props.selected.includes(table.get('id')))
            .sortBy((table: Map<string, any>) => tableDisplayNameWithBucketAndStage(table))
            .map((table: Map<string, any>) => {
              const isExternal = props.buckets.getIn(
                [table.getIn(['bucket', 'id']), 'hasExternalSchema'],
                false,
              );

              return {
                value: table.get('id'),
                label: tableLabel(table),
                name: tableName(table),
                isDisabled: isExternal,
                disabledReason: EXTERNAL_DATASET_DISABLE_TOOLTIP.TRIGGER,
              };
            })
            .toArray()}
          onChange={props.onAddTable}
        />
      </FormGroup>
      {!selectedTables.isEmpty() && (
        <>
          <div className="well with-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Selected Tables</th>
                  <th className="no-wrap">Last Import</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {selectedTables
                  .valueSeq()
                  .map((table: Map<string, any>) => (
                    <tr key={table.get('id')}>
                      <td className="overflow-break-anywhere">
                        <SapiTableLink
                          tableId={table.get('id')}
                          className="tw-flex tw-items-center"
                        >
                          <BucketStageLabel
                            placement="left"
                            stage={table.getIn(['bucket', 'stage'])}
                          />
                          <DevBranchLabel bucket={table.get('bucket')} />
                          {tableName(table)}
                          {props.hasDataStreams && props.dataStreamSources && (
                            <DataStreamIcon table={table} sources={props.dataStreamSources} />
                          )}
                        </SapiTableLink>
                      </td>
                      <td className="no-wrap text-muted f-13">
                        <CreatedDate
                          createdTime={table.get('lastImportDate')}
                          fallback="Not yet imported"
                        />
                      </td>
                      <td className="pl-0 pr-0">
                        <Tooltip
                          placement="top"
                          tooltip="Remove table"
                          triggerClassName="tw-justify-end"
                        >
                          <IconButton
                            variant="inline"
                            onClick={() => props.onRemoveTable(table.get('id'))}
                            icon="trash"
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  ))
                  .toArray()}
              </tbody>
            </table>
          </div>
          <div className="form-horizontal">
            <InputGroup className="input-period">
              <InputGroup.Addon>
                <InfoTooltip
                  className="tw-ml-0 tw-mr-1.5"
                  triggerClassName="!tw-inline-block"
                  tooltip={
                    <>
                      <p className="tooltip-title">Cooldown period</p>
                      <p>
                        If an event was triggered, it could only be triggered again after this
                        period.
                      </p>
                    </>
                  }
                />
                Run max once per
              </InputGroup.Addon>
              <FormControl
                min="1"
                type="number"
                value={props.period}
                onChange={props.onChangePeriod}
              />
              <InputGroup.Addon>Mins</InputGroup.Addon>
            </InputGroup>
          </div>
        </>
      )}
      {props.hasDataStreams && (
        <NewStreamModal
          show={showNewStreamModal}
          tables={props.tables}
          onHide={() => setShowNewStreamModal(false)}
          onCreated={(sourceName, sourceId, tableId) => {
            setShowNewStreamModal(false);
            props.onAddTable(tableId);

            ApplicationActionCreators.sendNotification({
              message: () => {
                return (
                  <>
                    Data Stream{' '}
                    <RouterLink to={routeNames.STREAM_DETAIL} params={{ sourceId }}>
                      {sourceName}
                    </RouterLink>{' '}
                    has been created.
                  </>
                );
              },
            });
          }}
          sources={props.dataStreamSources}
        />
      )}
    </>
  );
};

export default EventTrigger;
