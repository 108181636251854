import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import type { List, Map } from 'immutable';

import { cn } from '@keboola/design';

import { canManageNotifications } from '@/modules/admin/privileges';
import OneTimeNotificationButton from '@/modules/notifications/components/OneTimeNotificationButton';
import ActionCreators from '@/modules/queue/actions';
import { JOB_RUNNING_STATUSES, routeNames } from '@/modules/queue/constants';
import {
  getUserRunnedParentJob,
  isInternalDataAppJob,
  isPhaseJob,
  isRowJob,
  isScheduledJob,
  isTriggeredJob,
  parseJobsQuery,
} from '@/modules/queue/helpers';
import { getComponentByJob, getConfigurationName } from '@/modules/queue/jobResolver';
import { CircleIcon, CreatedDate, RouterLink, SortIcon, Truncated } from '@/react/common';
import BlockButton from '@/react/common/BlockButton';
import ComponentWithIconAndType from '@/react/common/ComponentWithIconAndType';
import JobDuration from '@/react/common/JobDuration';
import JobPartialRunLabel from '@/react/common/JobPartialRunLabel';
import JobStatusLabel from '@/react/common/JobStatusLabel';
import Loader from '@/react/common/Loader';
import TimeAndUser from '@/react/common/TimeAndUser';
import InternalDataAppJob from './InternalDataAppJob';
import ScheduledJob from './ScheduledJob';
import TriggeredJob from './TriggeredJob';

const JobsTable = ({
  jobs,
  admins,
  sapiToken,
  currentAdmin,
  notifications,
  isLoading,
  isLoadMore,
  offset,
  changeQuery,
  query,
}: {
  jobs: Map<string, any>;
  admins: Map<string, any>;
  sapiToken: Map<string, any>;
  currentAdmin: Map<string, any>;
  notifications: List<any>;
  isLoading: boolean;
  isLoadMore: boolean;
  offset: number;
  changeQuery: (key: string, value: string) => void;
  query?: Map<string, any>;
}) => {
  const parsedQuery = parseJobsQuery(query);
  const canSetupNotification = canManageNotifications(sapiToken);

  const handleLoadMore = () => {
    if (isLoading) {
      return;
    }

    return ActionCreators.loadJobsForce({ ...query?.toJS(), offset });
  };

  const renderCreated = (job: Map<string, any>) => {
    if (isScheduledJob(job)) {
      return <ScheduledJob time={job.get('createdTime')} absolute />;
    }

    if (isTriggeredJob(job)) {
      return (
        <div className="flex-container inline-flex flex-start">
          <div>
            <CreatedDate createdTime={job.get('createdTime')} absolute />
            <br />
            <TriggeredJob />
          </div>
          <span className="line-height-1 icon-addon-left">
            <CircleIcon
              bold
              color="blue"
              icon={['far', 'calendar-days']}
              className="job-icon bigger"
            />
          </span>
        </div>
      );
    }

    if (isInternalDataAppJob(job)) {
      return <InternalDataAppJob time={job.get('createdTime')} />;
    }

    return (
      <TimeAndUser
        absolute
        admin={admins.get(job.getIn(['token', 'description']))}
        time={job.get('createdTime')}
        fallbackName={job.getIn(['token', 'description'])}
      />
    );
  };

  const renderSortHeader = (label: string, sortBy: 'id' | 'durationSeconds') => {
    const isSorted = parsedQuery.get('sortBy', 'id') === sortBy;

    return (
      <span
        className="clickable"
        title={`Sort by ${label.toLowerCase()}`}
        onClick={() => {
          if (isSorted) {
            changeQuery(
              'sortOrder',
              parsedQuery.get('sortOrder', 'desc') === 'desc' ? 'asc' : 'desc',
            );
          } else {
            changeQuery('sortBy', sortBy);
          }
        }}
      >
        <SortIcon
          isSorted={isSorted}
          isSortedDesc={parsedQuery.get('sortOrder', 'desc') === 'desc'}
        />
        {label}
      </span>
    );
  };

  return (
    <div className="box">
      <div className="table table-hover">
        <div className="thead">
          <div className="tr">
            <span className="th w-250">Component</span>
            <span className="th">Configuration</span>
            <span className="th w-150 text-right">
              {renderSortHeader('Duration', 'durationSeconds')}
            </span>
            <span className="th w-250 text-right">{renderSortHeader('Created', 'id')}</span>
            <span className="th w-125">Status</span>
          </div>
        </div>
        {jobs.isEmpty() && (
          <div className="tbody">
            <div className="tr no-hover">
              <div className="td w-250">
                {isLoading ? (
                  <>
                    <Loader /> loading jobs..
                  </>
                ) : (
                  'No jobs found.'
                )}
              </div>
            </div>
          </div>
        )}
        <InfiniteScroll
          initialLoad={false}
          className="tbody"
          hasMore={isLoadMore}
          loadMore={handleLoadMore}
        >
          {jobs
            .map((job) => {
              const component = getComponentByJob(job);
              const userRunnedJob = getUserRunnedParentJob(job, jobs);
              const phaseJob = isPhaseJob(job);
              const rowJob = isRowJob(job);
              const isProcessing = JOB_RUNNING_STATUSES.includes(job.get('status'));
              const hasHoverableActions = isProcessing && canSetupNotification;

              return (
                <RouterLink
                  key={job.get('id')}
                  to={routeNames.JOB_DETAIL}
                  params={{ jobId: job.get('id') }}
                  className={cn('tr', {
                    'hoverable-actions-with-replacement': hasHoverableActions,
                  })}
                >
                  <div className="td w-250">
                    <ComponentWithIconAndType
                      component={component}
                      nameSuffix={phaseJob ? ' phase' : rowJob ? ' row' : ''}
                      isPhase={phaseJob}
                    />
                  </div>
                  <div className="td">
                    <div className="flex-container flex-start">
                      <JobPartialRunLabel hasNewQueue job={userRunnedJob} />
                      <Truncated text={getConfigurationName(userRunnedJob)} />
                    </div>
                  </div>
                  <div className="td w-150 text-right">
                    <JobDuration
                      status={job.get('status')}
                      startTime={job.get('startTime')}
                      endTime={job.get('endTime')}
                    />
                  </div>
                  <div className="td w-250 text-right">{renderCreated(job)}</div>
                  <div className="td w-100">
                    {hasHoverableActions ? (
                      <div className="actions-container">
                        <div className="not-actions">
                          <JobStatusLabel status={job.get('status')} />
                        </div>
                        <div className="actions">
                          <OneTimeNotificationButton
                            inline
                            job={job}
                            notifications={notifications}
                            admin={currentAdmin}
                          />
                        </div>
                      </div>
                    ) : (
                      <JobStatusLabel status={job.get('status')} />
                    )}
                  </div>
                </RouterLink>
              );
            })
            .toArray()}
        </InfiniteScroll>
      </div>
      {isLoadMore && !jobs.isEmpty() && (
        <BlockButton onClick={handleLoadMore} isLoading={isLoading} />
      )}
    </div>
  );
};

export default JobsTable;
