import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import { Button } from '@keboola/design';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import FilesStore from '@/modules/components/stores/StorageFilesStore';
import { loadFiles } from '@/modules/storage/actions';
import FilesLocalStore from '@/modules/storage/FilesLocalStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import UploadModal from './UploadModal';

const UploadFileButton = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, FilesStore, FilesLocalStore)],

  getStateFromStores() {
    return {
      readOnly: ApplicationStore.isReadOnly(),
      uploadingProgress: FilesStore.getAllUploadingProgress(),
      searchQuery: FilesLocalStore.getSearchQuery(),
    };
  },

  getInitialState() {
    return {
      openUploadModal: false,
    };
  },

  render() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <>
        <Button
          onClick={() => this.setState({ openUploadModal: true })}
          className={NEW_ENTITY_BUTTON}
        >
          <FontAwesomeIcon icon="circle-arrow-up" />
          Upload Files
        </Button>
        <UploadModal
          show={this.state.openUploadModal}
          uploadingProgress={this.state.uploadingProgress}
          onUpload={this.reloadFiles}
          onHide={() => this.setState({ openUploadModal: false })}
        />
      </>
    );
  },

  reloadFiles() {
    return loadFiles({ offset: 0, ...(this.state.searchQuery && { q: this.state.searchQuery }) });
  },
});

export default UploadFileButton;
