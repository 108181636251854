import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { Link } from '@keboola/design';

import MarkedText from '@/react/common/MarkedText';

const AccountLink = createReactClass({
  propTypes: {
    account: PropTypes.object.isRequired,
    componentId: PropTypes.string.isRequired,
    searchQuery: PropTypes.string.isRequired,
  },

  render() {
    if (this.props.componentId === 'keboola.ex-instagram')
      return this.renderFbPageLink('fb_page_id');
    if (this.props.componentId === 'keboola.ex-facebook') return this.renderFbPageLink();
    if (this.props.componentId === 'keboola.ex-facebook-ads') return this.renderFbAdAccountLink();

    return 'Unknown component ' + this.props.componentId;
  },

  renderFbPageLink(idProperty) {
    const pageId = this.props.account.get(idProperty || 'id');
    const pageName = this.props.account.get('name');

    if (pageId) {
      return (
        <Link href={`https://www.facebook.com/${pageId}`}>
          {this.renderText(pageName || pageId)}
        </Link>
      );
    }

    return this.renderText(pageName, 'Unknown page');
  },

  renderFbAdAccountLink() {
    const id = this.props.account.get('id');
    const accountId = this.props.account.get('account_id');
    const accountName = this.props.account.get('name');
    const businessName = this.props.account.get('business_name');

    if (accountId) {
      return (
        <Link href={`https://www.facebook.com/ads/manager/account/campaigns/?act=${accountId}`}>
          {this.renderText(accountName || businessName || accountId)}
        </Link>
      );
    }

    return this.renderText(accountName || businessName || id, 'Unknown Ad Account');
  },

  renderText(text, fallback) {
    if (!text) {
      return fallback;
    }

    return <MarkedText source={text} mark={this.props.searchQuery} />;
  },
});

export default AccountLink;
