import React from 'react';

import { cn } from '@keboola/design';

import Loader from './Loader';

const ActionLoader = ({ loadingActionName }: { loadingActionName?: string | null }) => (
  <span className="text-muted">
    <Loader className={cn({ 'btn-icon': !!loadingActionName })} />
    {loadingActionName}
  </span>
);

export default ActionLoader;
