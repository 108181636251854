import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import Switch from 'rc-switch';

import { Badge, cn, Tooltip } from '@keboola/design';

import { CREDIT_OPTIONS, CREDITS_DISCOUNTS } from '@/modules/billing/constants';
import {
  convertCreditsToMinutes,
  convertCreditsToPrice,
  convertCreditsToPriceWithoutDiscount,
} from '@/modules/billing/helpers';
import Select from '@/react/common/Select';
import { type CreditAmount } from './CreditsPicker';

export type TopUpSetting = { amount: CreditAmount; limit: number };

export const TopUpSwitch = (props: {
  checked: boolean;
  onClick: (checked: boolean) => void;
  label: string;
  withNewLabel?: boolean;
  disabled?: boolean;
  className?: string;
}) => {
  return (
    <ControlLabel
      className={cn('flex-container flex-start inline-flex clickable', props.className)}
    >
      <Switch
        prefixCls="switch"
        className="btn-icon wider"
        checked={props.checked}
        onClick={props.onClick}
        disabled={!!props.disabled}
      />
      {props.label}
      {props.withNewLabel && <Badge text="New" variant="cyan" placement="right" />}
    </ControlLabel>
  );
};

export const TopUpFormInputs = (props: {
  value: TopUpSetting;
  onChange: (data: TopUpSetting) => void;
  disabled: boolean;
  creditPrice: number;
  className?: string;
}) => {
  return (
    <FormGroup className={props.className}>
      <div className="top-up-form-inputs gap-16">
        <div>
          <ControlLabel>When out of minutes automatically recharge</ControlLabel>
          <Select
            clearable={false}
            value={props.value.amount}
            options={CREDIT_OPTIONS.map((amount) => {
              const discount = CREDITS_DISCOUNTS[amount as 32 | 64 | 128] || 0;

              return {
                value: amount,
                label: (
                  <div className="flex-container">
                    <div>
                      {convertCreditsToMinutes(amount)} minutes{' '}
                      <span className={discount ? 'color-success-muted' : 'text-muted'}>
                        ({convertCreditsToPrice(amount, props.creditPrice)})
                      </span>
                    </div>
                    {!!discount && (
                      <div>
                        <span className="f-12 line-height-20 text-muted line-through">
                          {convertCreditsToPriceWithoutDiscount(amount, props.creditPrice)}
                        </span>
                        <Badge text={`-${discount}%`} variant="green-darker" placement="right" />
                      </div>
                    )}
                  </div>
                ),
                name: convertCreditsToMinutes(amount),
              };
            })}
            onChange={(selected: any) =>
              props.onChange({ ...props.value, amount: selected as TopUpSetting['amount'] })
            }
            disabled={props.disabled}
          />
        </div>
        <div>
          <ControlLabel>Max number of monthly recharges</ControlLabel>
          <FormControl
            min={1}
            type="number"
            value={props.value.limit > 0 ? props.value.limit : ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.onChange({ ...props.value, limit: parseInt(event.target.value) || 0 });
            }}
            disabled={props.disabled}
          />
        </div>
      </div>
    </FormGroup>
  );
};

export const TopUpDescription = (props: { className?: string }) => {
  return (
    <p className={cn('text-muted line-height-20 mr-1', props.className)}>
      We&apos;ll automatically detect when you&apos;re running out of minutes and recharge your
      account with the selected amount. To prevent excessive charges, you can set a maximum number
      of monthly recharges.
    </p>
  );
};

const TopUpForm = (props: {
  enabled: boolean;
  value: TopUpSetting;
  onToggle: (enabled: boolean) => void;
  onChange: (data: TopUpSetting) => void;
  disabled: boolean;
  creditPrice: number;
}) => {
  return (
    <div className={cn('top-up-form', { disabled: props.disabled })}>
      <FormGroup className="with-margin">
        <Tooltip
          placement="top"
          type="explanatory"
          forceHide={!props.disabled}
          tooltip="You need to save your card to use automatic top-up."
        >
          <TopUpSwitch
            checked={props.enabled}
            onClick={props.onToggle}
            label="Automatic top-up enabled"
            disabled={props.disabled}
          />
        </Tooltip>
      </FormGroup>
      <TopUpFormInputs
        value={props.value}
        onChange={props.onChange}
        className="with-margin mb-0"
        disabled={props.disabled || !props.enabled}
        creditPrice={props.creditPrice}
      />
    </div>
  );
};

export default TopUpForm;
