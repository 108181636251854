import React from 'react';

import type { BadgeProps } from '@keboola/design';
import { Badge, cn } from '@keboola/design';

import { STAGE } from '@/constants';

type Props = {
  stage?: string;
  round?: boolean;
  className?: string;
  placement?: BadgeProps['placement'];
};

const BucketStageLabel = ({ stage, round, className, placement }: Props) => {
  if (!stage) return null;

  return (
    <Badge
      text={stage}
      asTag={!round}
      placement={placement}
      variant={stage === STAGE.IN ? 'blue-inverse' : 'green-inverse'}
      className={cn('tw-min-w-10 tw-uppercase', className)}
    />
  );
};

export default BucketStageLabel;
