import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import Immutable from 'immutable';

import { Link } from '@keboola/design';

import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import { getConflictsForBucket } from '@/modules/transformations/react/components/duplicite-output-mapping/detect';
import CollapsibleAlert from '@/react/common/CollapsibleAlert';

const TransformationParallelUnloads = createReactClass({
  propTypes: {
    transformations: PropTypes.object,
    transformationBuckets: PropTypes.object,
  },

  render() {
    if (!this.props.transformations) {
      return null;
    }
    let duplicitTransformationsByBucket = new Immutable.Map();
    this.props.transformations.forEach(function (bucket, bucketId) {
      const bucketConflicts = getConflictsForBucket(bucket);
      if (bucketConflicts.count() > 0) {
        // deduplicate by transformation id
        const deduplicatedBucketConflicts = bucketConflicts
          .groupBy((conflict) => {
            return conflict.get('id');
          })
          .map((groupedConflicts) => {
            return groupedConflicts.first();
          })
          .toList();
        duplicitTransformationsByBucket = duplicitTransformationsByBucket.set(
          bucketId,
          deduplicatedBucketConflicts,
        );
      }
    });

    if (duplicitTransformationsByBucket.isEmpty()) {
      return (
        <CollapsibleAlert
          title="Speed up output of your transformations"
          id="transformation-parallel-unloads-1"
          variant="warning"
          className="tw-mb-6"
        >
          <p>
            Your project is ready. If you wish to parallelize output processing for your
            transformations, please use the support button to let us know.
          </p>
        </CollapsibleAlert>
      );
    }

    const transformations = this.props.transformations;
    const transformationBuckets = this.props.transformationBuckets;
    return (
      <CollapsibleAlert
        title="Transformations slowing down your output"
        id="transformation-parallel-unloads-2"
        variant="warning"
        className="tw-mb-6"
      >
        <p>
          We cannot turn on parallel output processing for this project as these transformations
          write outputs to the same table in Storage. Please fix the transformations so we can turn
          on faster output processing for this project.
        </p>
        <div className="row">
          {duplicitTransformationsByBucket
            .map((bucketConflicts, bucketId) => (
              <div className="col-md-6" key={bucketId}>
                <h3 className="f-14 mt-0">
                  {transformationBuckets.getIn([bucketId, 'name'], bucketId)}
                </h3>
                <ul className="mlp-2">
                  {bucketConflicts
                    .map((conflict, conflictIndex) => (
                      <li key={conflictIndex} className="color-primary">
                        <ComponentConfigurationLink
                          componentId="transformation"
                          configId={bucketId}
                          rowId={conflict.get('id')}
                        >
                          {transformations.getIn(
                            [bucketId, conflict.get('id'), 'name'],
                            conflict.get('id'),
                          )}
                        </ComponentConfigurationLink>
                      </li>
                    ))
                    .toSeq()
                    .toArray()}
                </ul>
              </div>
            ))
            .toSeq()
            .toArray()}
        </div>
        <p>
          Read more about speeding up the output by using{' '}
          <Link href="https://status.keboola.com/speeding-up-transformation-outputs-in-your-projects">
            parallel unloads
          </Link>
          .
        </p>
      </CollapsibleAlert>
    );
  },
});

export default TransformationParallelUnloads;
