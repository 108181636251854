import React, { useState } from 'react';
import { ButtonToolbar } from 'react-bootstrap';
import type { Map } from 'immutable';

import { cn, Tooltip } from '@keboola/design';

import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import actions from '@/modules/templates/actions';
import { routeNames } from '@/modules/templates/constants';
import TemplatesStore from '@/modules/templates/store';
import { RouterLink } from '@/react/common';
import DeleteButton from '@/react/common/DeleteButton';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const InstanceDetailHeader = () => {
  const [isDeleting, setIsDeleting] = useState(false);
  const { instanceId, version, mainConfig, existingConfiguration, isDeprecated, readOnly } =
    useStores(
      () => {
        const { instanceId, version, mainConfig, templateDetail } =
          TemplatesStore.getStore().instanceDetail || {};

        return {
          instanceId,
          version,
          mainConfig,
          existingConfiguration: InstalledComponentsStore.getConfig(
            mainConfig?.componentId,
            mainConfig?.configId,
          ) as Map<string, any>,
          isDeprecated: templateDetail?.deprecated,
          readOnly: ApplicationStore.isReadOnly(),
        };
      },
      [],
      [ApplicationStore, TemplatesStore, InstalledComponentsStore],
    );

  if (!instanceId) return null;

  return (
    <ButtonToolbar>
      {!readOnly && (
        <>
          <DeleteButton
            hideIcon
            label="Delete Template Configuration"
            confirm={{
              title: 'Delete Template Configuration',
              text: 'Are you sure you want to delete the template configuration?',
              onConfirm: () => {
                setIsDeleting(true);
                return actions
                  .deleteInstance(instanceId)
                  .then(() => {
                    if (RoutesStore.getCurrentRouteParam('instanceId') === instanceId) {
                      return RoutesStore.getRouter().transitionTo(routeNames.ROOT);
                    }
                  })
                  .finally(() => setIsDeleting(false));
              },
              closeAfterResolve: true,
            }}
            isPending={isDeleting}
            buttonStyle="default"
          />
          <Tooltip
            tooltip="You cannot edit a configuration of a deprecated template."
            placement="top"
            type="explanatory"
            forceHide={!isDeprecated}
          >
            <RouterLink
              to={routeNames.INSTANCE_DETAIL_EDIT}
              params={{ instanceId }}
              query={{ v: version }}
              className={cn('btn btn-default', { disabled: isDeprecated })}
              disabled={isDeprecated}
            >
              Edit Template Configuration
            </RouterLink>
          </Tooltip>
        </>
      )}
      <RunComponentButton
        noTooltip
        disabled={existingConfiguration.isEmpty()}
        componentId={mainConfig?.componentId}
        runParams={() => ({ config: mainConfig?.configId })}
        label="Run Template"
        title="Run Template"
        buttonIcon="circle-play"
        buttonBsStyle="success"
      >
        You are about to run the template.
      </RunComponentButton>
    </ButtonToolbar>
  );
};

export default InstanceDetailHeader;
