import React from 'react';
import type { ReactNode } from 'react';
import type { Map } from 'immutable';

import { URLS } from '@keboola/constants';
import type { ValidityState } from '@keboola/design';
import { FormGroup, Label, Link } from '@keboola/design';

import string from '@/utils/string';

const UrlPathControl = (props: {
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
  autoFocus?: boolean;
  showUsedWarning?: boolean;
}) => {
  const renderLearMoreLink = () => {
    return (
      <Link
        href={`${URLS.DEVELOPERS_DOCUMENTATION}/extend/generic-extractor/configuration/config/jobs/#endpoint`}
      >
        Learn more
      </Link>
    );
  };

  const infoMsg = props.editing.get('childJob') ? (
    <>
      A relative or absolute URL of the resource with placeholders linking the parent job response,
      e.g., <code>{'user/{user_id}/detail'}</code>. {renderLearMoreLink()}
    </>
  ) : (
    <>
      Relative or absolute URL of the resource, e.g., <code>users</code>. {renderLearMoreLink()}
    </>
  );

  const state = props.showUsedWarning ? 'warning' : 'default';

  const messages: Partial<Record<ValidityState, ReactNode>> = {
    default: infoMsg,
    warning: 'Endpoint with the same path already exists.',
  };

  return (
    <FormGroup className="tw-mb-4" state={state}>
      <Label htmlFor="url-path">URL Path</Label>

      <FormGroup.TextInput
        id="url-path"
        variant="secondary"
        autoFocus={props.autoFocus}
        value={props.editing.get('endpoint', '')}
        onChange={(value) => {
          const endpoint = string.sanitizeDiacritics(value);
          props.setEditing(props.editing.set('endpoint', endpoint));
        }}
      />

      <FormGroup.Help>{messages[state]}</FormGroup.Help>
    </FormGroup>
  );
};

export default UrlPathControl;
