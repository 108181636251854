import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import { HelpBlock, PanelWithDetails } from '@keboola/design';

import { KEBOOLA_WR_DB_SNOWFLAKE } from '@/constants/componentIds';
import {
  getTableColumnMetadata,
  hasTableColumnMetadataDatatypes,
} from '@/modules/components/utils/tableMetadataHelper';
import { canBeTableCloned, isInputMappingSourceBucket } from '@/modules/transformations/helpers';
import { getComponentDataTypes } from '@/modules/wr-db/templates/dataTypes';
import ChangedSinceInput from '@/react/common/ChangedSinceInput';
import Select from '@/react/common/Select';
import whereOperatorConstants from '@/react/common/whereOperatorConstants';
import DatatypeForm from './input/DatatypeForm';
import {
  buildDatatypes,
  getInitialDatatypes,
  getMappingFromDatatypes,
  getMetadataDataTypes,
  prepareSourceMapping,
} from './InputMappingRowSnowflakeEditorHelper';
import InputMappingSource from './InputMappingSource';

const InputMappingRowSnowflakeEditor = createReactClass({
  propTypes: {
    value: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    initialShowDetails: PropTypes.bool.isRequired,
    isDestinationDuplicate: PropTypes.bool.isRequired,
    mode: PropTypes.string.isRequired,
  },

  getInitialState() {
    const source = this.props.value.get('source');

    return {
      tableColumnMetadata: getTableColumnMetadata(this.props.tables.get(source, Map())),
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const source = nextProps.value.get('source');

    if (source !== this.props.value.get('source') && source !== '') {
      this.setState({
        tableColumnMetadata: getTableColumnMetadata(this.props.tables.get(source, Map())),
      });
    }
  },

  _handleChangeSource(selected) {
    return this.props.onChange(prepareSourceMapping(selected, this.props.value, this.props.tables));
  },

  _handleChangeDestination(e) {
    return this.props.onChange(this.props.value.set('destination', e.target.value.trim()));
  },

  _handleChangeChangedSince(changedSince) {
    let value = this.props.value;
    if (this.props.value.has('days')) {
      value = value.delete('days');
    }
    value = value.set('changedSince', changedSince);
    return this.props.onChange(value);
  },

  canClone() {
    if (this.props.value.get('tables', Map()).count()) {
      return this.props.tables
        .filter((table) => this.props.value.hasIn(['tables', table.get('id')]))
        .every(canBeTableCloned);
    }

    return canBeTableCloned(this.getSelectedTable());
  },

  handleChangeLoadType(newValue) {
    const newMapping = this.props.value.withMutations((mapping) => {
      if (newValue && newValue !== '') {
        mapping.set('loadType', newValue);
      } else {
        mapping.delete('loadType');
      }

      if (mapping.has('tables')) {
        mapping.update('tables', (tables) =>
          tables.map((table) => {
            if (mapping.has('loadType')) {
              return table.set('datatypes', Map());
            }

            return table.set(
              'datatypes',
              getInitialDatatypes(this.props.tables.get(table.get('source'))),
            );
          }),
        );
      } else if (mapping.has('loadType')) {
        mapping.set('datatypes', Map());
      } else {
        mapping.set('datatypes', getInitialDatatypes(this.props.tables.get(mapping.get('source'))));
      }

      mapping.delete('changedSince');
      mapping.set('whereColumn', '');
      mapping.set('whereValues', List());
      mapping.set('whereOperator', 'eq');
      mapping.set('columns', List());
    });

    return this.props.onChange(newMapping);
  },

  _handleChangeColumns(newValue) {
    const mutatedValue = this.props.value.withMutations((mapping) => {
      let mutation = mapping.set('columns', newValue);
      const initialDatatypes = getInitialDatatypes(this.props.tables.get(mapping.get('source')));
      if (newValue.count()) {
        const columns = mutation.get('columns');
        const currentDatatypes = this.getDatatypes();
        let newDatatypes = Map();
        columns.forEach((column) => {
          if (currentDatatypes.has(column)) {
            newDatatypes = newDatatypes.set(column, currentDatatypes.get(column));
          } else {
            newDatatypes = newDatatypes.set(column, initialDatatypes.get(column));
          }
        });
        mutation = mutation.set('datatypes', fromJS(newDatatypes || Map()));
      } else {
        mutation = mutation.set('datatypes', fromJS(initialDatatypes || Map()));
      }
      return mutation;
    });
    return this.props.onChange(mutatedValue);
  },

  _handleChangeWhereColumn(string) {
    return this.props.onChange(this.props.value.set('whereColumn', string));
  },

  _handleChangeWhereOperator(newValue) {
    return this.props.onChange(this.props.value.set('whereOperator', newValue));
  },

  _handleChangeWhereValues(newValue) {
    return this.props.onChange(this.props.value.set('whereValues', newValue));
  },

  _handleChangeDataTypes(datatypes) {
    return this.props.onChange(this.props.value.set('datatypes', datatypes));
  },

  toggleDatatypes(selected) {
    if (selected === 'none') {
      return this.props.onChange(this.props.value.set('datatypes', List()));
    }

    this.props.onChange(
      this.props.value.set(
        'datatypes',
        getInitialDatatypes(this.props.tables.get(this.props.value.get('source'))),
      ),
    );
  },

  getSelectedTable() {
    if (!this.props.value.get('source')) {
      return Map();
    }
    return this.props.tables.find(
      (table) => {
        return table.get('id') === this.props.value.get('source');
      },
      null,
      Map(),
    );
  },

  _getColumns() {
    return this.getSelectedTable() ? this.getSelectedTable().get('columns', List()) : List();
  },

  _getColumnsOptions() {
    return this._getColumns()
      .map((column) => {
        return {
          label: column,
          value: column,
        };
      })
      .toJS();
  },

  _getFilteredColumns() {
    return this.props.value.get('columns', List()).count() > 0
      ? this.props.value.get('columns')
      : this._getColumns();
  },

  _getFilteredColumnsOptions() {
    return this._getFilteredColumns()
      .map((column) => {
        return {
          label: column,
          value: column,
        };
      })
      .toJS();
  },

  getChangedSinceValue() {
    if (this.props.value.get('changedSince')) {
      return this.props.value.get('changedSince');
    } else if (this.props.value.get('days') > 0) {
      return '-' + this.props.value.get('days') + ' days';
    }
    return null;
  },

  getDefaultDatatypes() {
    const selectedTable = this.props.tables.get(this.props.value.get('source'));
    const filteredColumns = this._getFilteredColumns();

    if (selectedTable && hasTableColumnMetadataDatatypes(selectedTable)) {
      const metadataSet = this.state.tableColumnMetadata.filter((metadata, colname) => {
        return filteredColumns.indexOf(colname) > -1;
      });
      return buildDatatypes(
        filteredColumns,
        getMetadataDataTypes(metadataSet, selectedTable.get('primaryKey', List())),
      );
    }

    return buildDatatypes(filteredColumns);
  },

  getDatatypes() {
    return this.getDefaultDatatypes().map((defaultType) => {
      const existingTypeFilter = this.props.value
        .get('datatypes', Map())
        .filter((existingType, columnName) => {
          const column = Map.isMap(existingType) ? existingType.get('column') : columnName;
          return column === defaultType.get('column');
        });
      if (existingTypeFilter.count() > 0) {
        return existingTypeFilter.get(defaultType.get('column'));
      } else {
        return defaultType;
      }
    });
  },

  render() {
    const isCloneTable = this.props.value.get('loadType') === 'clone';
    const isSelectedBucket = isInputMappingSourceBucket(this.props.value);
    const isDisabled = this.props.disabled || !this.props.value.get('source');
    const hasColumnTypes = !this.props.value.get('datatypes', List()).isEmpty();
    const filteredColumns = this._getFilteredColumns();

    return (
      <form className="form-horizontal">
        <FormGroup>
          <div className="col-xs-2">
            <ControlLabel>Source</ControlLabel>
          </div>
          <div className="col-xs-10">
            <InputMappingSource
              disableMultiSelect
              mode={this.props.mode}
              tables={this.props.tables}
              buckets={this.props.buckets}
              value={this.props.value}
              onChange={this._handleChangeSource}
              disabled={this.props.disabled}
              isDestinationDuplicate={this.props.isDestinationDuplicate}
            />
          </div>
        </FormGroup>
        {!isSelectedBucket && (
          <FormGroup validationState={this.props.isDestinationDuplicate ? 'error' : null}>
            <div className="col-xs-2">
              <ControlLabel>Destination</ControlLabel>
            </div>
            <div className="col-xs-10">
              <FormControl
                type="text"
                value={this.props.value.get('destination', '')}
                disabled={this.props.disabled}
                placeholder="Destination table name in transformation DB"
                onChange={this._handleChangeDestination}
              />
              {this.props.isDestinationDuplicate && (
                <HelpBlock className="tw-mt-1" state="error">
                  Duplicate destination <code>{this.props.value.get('destination')}</code>
                </HelpBlock>
              )}
            </div>
          </FormGroup>
        )}
        <FormGroup>
          <div className="col-xs-2">
            <ControlLabel>Load Type</ControlLabel>
          </div>
          <div className="col-xs-10">
            <Select
              searchable={false}
              value={this.props.value.get('loadType', '')}
              disabled={this.props.disabled || !this.props.value.get('source')}
              placeholder="Copy table (default)"
              clearable={false}
              onChange={this.handleChangeLoadType}
              options={[
                { label: 'Copy Table (default)', value: '' },
                { label: 'Clone Table', value: 'clone', isDisabled: !this.canClone() },
              ]}
            />
            <HelpBlock className="tw-mt-1">
              Type of table load from Storage into a workspace. The <code>Clone</code> load type can
              be applied only to Snowflake tables without any filtering parameters.
            </HelpBlock>
          </div>
        </FormGroup>
        {isSelectedBucket && !isCloneTable && this.renderChangeInLastInput(isDisabled)}
        {!isSelectedBucket && !isCloneTable && (
          <PanelWithDetails defaultExpanded={this.props.initialShowDetails}>
            <div>
              <FormGroup>
                <div className="col-xs-2">
                  <ControlLabel>Columns</ControlLabel>
                </div>
                <div className="col-xs-10">
                  <Select
                    multi
                    value={this.props.value.get('columns', List()).toJS()}
                    disabled={isDisabled}
                    placeholder="All columns will be imported"
                    onChange={this._handleChangeColumns}
                    options={this._getColumnsOptions()}
                  />
                  <HelpBlock className="tw-mt-1">Import only the specified columns</HelpBlock>
                </div>
              </FormGroup>
              {this.renderChangeInLastInput(isDisabled)}
              <FormGroup>
                <div className="col-xs-2">
                  <ControlLabel>Data filter</ControlLabel>
                </div>
                <div className="col-xs-3 pr-0">
                  <Select
                    value={this.props.value.get('whereColumn')}
                    disabled={isDisabled}
                    placeholder="Select a column"
                    onChange={this._handleChangeWhereColumn}
                    options={this._getColumnsOptions()}
                  />
                </div>
                <div className="col-xs-2">
                  <Select
                    clearable={false}
                    disabled={isDisabled}
                    value={this.props.value.get('whereOperator', '')}
                    onChange={this._handleChangeWhereOperator}
                    options={[
                      {
                        label: whereOperatorConstants.EQ_LABEL,
                        value: whereOperatorConstants.EQ_VALUE,
                      },
                      {
                        label: whereOperatorConstants.NOT_EQ_LABEL,
                        value: whereOperatorConstants.NOT_EQ_VALUE,
                      },
                    ]}
                  />
                </div>
                <div className="col-xs-5 pl-0">
                  <Select
                    value={this.props.value.get('whereValues')}
                    multi
                    disabled={isDisabled}
                    allowCreate
                    placeholder="Add a value"
                    emptyStrings
                    onChange={this._handleChangeWhereValues}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <div className="col-xs-2">
                  <ControlLabel>Data types</ControlLabel>
                </div>
                <div className="col-xs-10">
                  <Select
                    clearable={false}
                    deleteRemoves={false}
                    backspaceRemoves={false}
                    options={[
                      { value: 'none', label: 'None' },
                      { value: 'manual', label: 'User defined' },
                    ]}
                    value={hasColumnTypes ? 'manual' : 'none'}
                    onChange={this.toggleDatatypes}
                    disabled={isDisabled}
                  />
                </div>
              </FormGroup>
              {hasColumnTypes && !filteredColumns.isEmpty() && (
                <DatatypeForm
                  datatypes={this.getDatatypes()}
                  columns={filteredColumns}
                  datatypesMap={getMappingFromDatatypes(
                    getComponentDataTypes(KEBOOLA_WR_DB_SNOWFLAKE),
                  )}
                  sourceTable={this.props.value.get('source', '')}
                  sourceTableData={this.props.tables.get(this.props.value.get('source', ''), Map())}
                  disabled={isDisabled}
                  onChange={this._handleChangeDataTypes}
                />
              )}
            </div>
          </PanelWithDetails>
        )}
      </form>
    );
  },

  renderChangeInLastInput(isDisabled) {
    return (
      <FormGroup>
        <div className="col-xs-2">
          <ControlLabel>Changed in last</ControlLabel>
        </div>
        <div className="col-xs-10">
          <ChangedSinceInput
            value={this.getChangedSinceValue()}
            disabled={isDisabled}
            onChange={this._handleChangeChangedSince}
          />
        </div>
      </FormGroup>
    );
  },
});

export default InputMappingRowSnowflakeEditor;
