import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { URLS } from '@keboola/constants';
import { ButtonLink, Link } from '@keboola/design';

const DocumentationButton = () => {
  return (
    <ButtonLink variant="outline">
      <Link href={`${URLS.USER_DOCUMENTATION}/templates/`}>
        <FontAwesomeIcon icon="book-blank" />
        Documentation
      </Link>
    </ButtonLink>
  );
};

export default DocumentationButton;
