import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { HelpBlock, PanelWithDetails } from '@keboola/design';

import { nameWarning } from '@/modules/storage/constants';
import ChangedSinceInput from '@/react/common/ChangedSinceInput';
import string from '@/utils/string';

const SourceTable = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      source: PropTypes.string.isRequired,
      changedSince: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    const panelExpanded = this.props.value.changedSince !== '';

    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Source</h2>
        </div>
        <div className="box-content with-panel">
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Table name</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.value.source}
                onChange={(e) => {
                  this.props.onChange({ source: string.sanitizeKbcTableIdString(e.target.value) });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock className="tw-mt-1">
                Table name in the source project. If the table ID is <code>in.main.mytable</code>,
                use only the table name <code>mytable</code>. {nameWarning}
              </HelpBlock>
            </div>
          </FormGroup>
          <div className="-tw-mx-5">
            <PanelWithDetails
              defaultExpanded={panelExpanded}
              placement="bottom"
              labelCollapse="Hide Advanced Options"
              labelOpen="Show Advanced Options"
            >
              <FormGroup>
                <div className="col-xs-4">
                  <ControlLabel>Changed in last</ControlLabel>
                </div>
                <div className="col-xs-8">
                  <ChangedSinceInput
                    value={this.props.value.changedSince}
                    onChange={(value) => {
                      this.props.onChange({ changedSince: value });
                    }}
                    disabled={this.props.disabled}
                  />
                </div>
              </FormGroup>
            </PanelWithDetails>
          </div>
        </div>
      </div>
    );
  },
});

export default SourceTable;
