import { Promise } from 'bluebird';

import { getDevCredentials } from '@/dev/helpers';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';
import SettingsStore from './SettingsStore';

const getAdminToken = () => {
  return getDevCredentials().pop()?.manageApiToken;
};

const createRequest = (method, path) => {
  return request(method, `${ApplicationStore.getSapiUrl()}/manage/${path}`).set(
    'X-KBC-ManageApiToken',
    SettingsStore.getTokenString(),
  );
};

const createProjectRequest = (method, path) => {
  return createRequest(
    method,
    `projects/${ApplicationStore.getCurrentProjectId()}${path ? `/${path}` : ''}`,
  );
};

const createUserRequest = (method, path) => {
  return createRequest(method, `users/${ApplicationStore.getCurrentAdmin().get('email')}/${path}`);
};

const catchAlreadyAddedFeature = (error) => {
  if (error?.message === 'Feature already added') {
    return Promise.resolve();
  }

  throw error;
};

export default {
  getCurrentProject() {
    return createProjectRequest('GET', '')
      .promise()
      .then((response) => response.body);
  },

  getMaintainers() {
    return createRequest('GET', 'maintainers')
      .promise()
      .then((response) => response.body);
  },

  getMaintainerOrganizations(maintainerId) {
    return createRequest('GET', `maintainers/${maintainerId}/organizations`)
      .promise()
      .then((response) => response.body);
  },

  getOrganizations() {
    return createRequest('GET', 'organizations')
      .promise()
      .then((response) => response.body);
  },

  getOrganization(organizationId) {
    return createRequest('GET', `organizations/${organizationId}`)
      .promise()
      .then((response) => response.body);
  },

  createSessionToken() {
    return request('POST', `${ApplicationStore.getSapiUrl()}/manage/current-user/session-token`)
      .set('X-KBC-ManageApiToken', getAdminToken())
      .promise()
      .then((response) => response.body);
  },

  getUsers() {
    return createProjectRequest('GET', 'users')
      .promise()
      .then((response) => response.body);
  },

  getInvitations() {
    return createProjectRequest('GET', 'invitations')
      .promise()
      .then((response) => response.body);
  },

  getJoinRequests() {
    return createProjectRequest('GET', 'join-requests')
      .promise()
      .then((response) => response.body);
  },

  inviteUser(params) {
    return createProjectRequest('POST', 'invitations')
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  cancelInvite(id) {
    return createProjectRequest('DELETE', `invitations/${id}`)
      .promise()
      .then((response) => response.body);
  },

  approveUser(id) {
    return createProjectRequest('PUT', `join-requests/${id}`)
      .promise()
      .then((response) => response.body);
  },

  rejectUser(id) {
    return createProjectRequest('DELETE', `join-requests/${id}`)
      .promise()
      .then((response) => response.body);
  },

  removeUser(id) {
    return createProjectRequest('DELETE', `users/${id}`)
      .promise()
      .then((response) => response.body);
  },

  verifyToken() {
    return createRequest('GET', 'tokens/verify')
      .promise()
      .then((response) => response.body);
  },

  updateProject(params) {
    return createProjectRequest('PUT', '')
      .send(params)
      .promise()
      .then((response) => response.body);
  },

  removeProject() {
    return createProjectRequest('DELETE', '')
      .promise()
      .then((response) => response.body);
  },

  moveProject(organizationId) {
    return createProjectRequest('POST', 'organizations')
      .send({ organizationId })
      .promise()
      .then((response) => response.body);
  },

  addProjectFeature(feature) {
    return createProjectRequest('POST', 'features')
      .send({ feature })
      .promise()
      .then((response) => response.body)
      .catch(catchAlreadyAddedFeature);
  },

  removeProjectFeature(feature) {
    return createProjectRequest('DELETE', `features/${feature}`)
      .promise()
      .then((response) => response.body);
  },

  addUserFeature(feature) {
    return createUserRequest('POST', 'features')
      .send({ feature })
      .promise()
      .then((response) => response.body)
      .catch(catchAlreadyAddedFeature);
  },

  removeUserFeature(feature) {
    return createUserRequest('DELETE', `features/${feature}`)
      .promise()
      .then((response) => response.body);
  },

  loadFeatures() {
    return createRequest('GET', 'features')
      .promise()
      .then((response) => response.body);
  },
};
