import React from 'react';
import { useInView } from 'react-intersection-observer';
import type { Map } from 'immutable';

import dayjs from '@/date';
import actions from '@/modules/queue/actions';
import Timer from '@/utils/Timer';
import { getRealComponentId } from './helpers';

type LOAD_JOBS_REF = {
  action: (() => Promise<void>) | null;
  runAt: dayjs.Dayjs | null;
};

const VIEW_THRESHOLD = 0.1;
const SKIP_DELAY = 400;
const LAST_CALL_THRESHOLD = 30;
const POLL_INTERVAL = 60 * 1000; // 1 minute

const useJobsLoader = ({
  configurations,
  component,
  isCollapsed,
  skipDelay,
  disable,
}: {
  configurations: Map<string, any>;
  component?: Map<string, any>;
  isCollapsed: boolean;
  skipDelay?: boolean;
  disable: boolean;
}) => {
  const { ref, inView } = useInView({
    threshold: VIEW_THRESHOLD,
    delay: skipDelay ? 0 : SKIP_DELAY,
    skip: disable,
  });

  const loadJobsRef = React.useRef<LOAD_JOBS_REF>({ action: null, runAt: null });

  const loadJobs = React.useCallback(() => {
    const componentsIds = configurations
      .groupBy((config) => getRealComponentId(config, config.get('component', component)))
      .keySeq()
      .toArray();

    const configurationsIds = configurations.keySeq().toArray();

    return actions.loadLatestJobsForConfigurations(componentsIds, configurationsIds).tap(() => {
      loadJobsRef.current.runAt = dayjs();
    });
  }, [configurations, component]);

  React.useEffect(() => {
    if (!inView || isCollapsed) {
      return;
    }

    loadJobsRef.current.action = loadJobs;

    const tick = () => loadJobsRef.current.action?.();
    const lastCallDiff = dayjs().diff(loadJobsRef.current.runAt, 'seconds', true);

    Timer.poll(tick, {
      interval: POLL_INTERVAL,
      skipFirst: lastCallDiff > 0 && lastCallDiff < LAST_CALL_THRESHOLD,
    });

    return () => {
      Timer.stop(tick);
    };
  }, [inView, isCollapsed, loadJobs]);

  return ref;
};

export { useJobsLoader };
