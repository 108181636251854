import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { Clipboard } from '@keboola/design';

import { features as componentFeatures } from '@/modules/components/Constants';
import CodeEditorModal from '@/react/common/CodeEditorModal';
import SaveButtonWithDescription from '@/react/common/SaveButtonWithDescription';
import ApplicationStore from '@/stores/ApplicationStore';
import { updateConfiguration } from './generic/code-blocks/actions';
import {
  getBlocksAsString,
  getBlocksDefaultValue,
  getTablesHints,
  getVariablesHints,
  hasQueries,
  prepareMultipleBlocks,
  resolveEditorMode,
} from './generic/code-blocks/helpers';
import MLFlowArtifactsModal from './MLFlowArtifactsModal';

const CodeBlocksHeader = (props: {
  readOnly: boolean;
  config: Map<string, any>;
  component: Map<string, any>;
  blocks: List<any>;
  sharedCodes: List<any>;
  models: Map<string, any>;
  tables: Map<string, any>;
  variables: Map<string, any>;
}) => {
  const savedCode = React.useMemo(() => {
    return getBlocksAsString(props.component.get('id'), props.blocks, props.sharedCodes);
  }, [props.component, props.blocks, props.sharedCodes]);

  const [showMLFlowArtifactsModal, setShowMLFlowArtifactsModal] = React.useState(false);
  const [showAllCodeBlocksModal, setShowAllCodeBlocksModal] = React.useState(false);
  const [changeDescription, setChangeDescription] = React.useState('');
  const [editedValue, setEditedValue] = React.useState('');
  const [isSaving, setIsSaving] = React.useState(false);

  const onOpenEditor = () => {
    setShowAllCodeBlocksModal(true);
    setEditedValue(savedCode || getBlocksDefaultValue(props.component.get('id')));
  };

  const label = hasQueries(props.component.get('id')) ? 'Queries' : 'Scripts';
  const showMLFlowArtifactsButton =
    ApplicationStore.hasModelsAvailable() &&
    props.component.get('features', List()).includes(componentFeatures.MLFLOW_ARTIFACTS_ACCESS) &&
    !props.models.isEmpty();

  const isChanged = editedValue !== savedCode;
  const onReset = () => setEditedValue(savedCode);
  const onSave = () => {
    setIsSaving(true);

    return prepareMultipleBlocks(props.component.get('id'), props.sharedCodes, editedValue)
      .then((blocks: List<any>) => {
        return updateConfiguration(
          props.component.get('id'),
          props.config.get('id'),
          props.config.get('configuration').setIn(['parameters', 'blocks'], blocks),
          changeDescription || `Multiple ${label} updated`,
        ).then(() => {
          setChangeDescription('');
          setEditedValue(getBlocksAsString(props.component.get('id'), blocks, props.sharedCodes));
        });
      })
      .finally(() => setIsSaving(false));
  };
  const renderAdditionalButtons = () => {
    if (props.readOnly) {
      return null;
    }

    return (
      <SaveButtonWithDescription
        disabled={isSaving}
        isSaving={isSaving}
        isChanged={isChanged}
        onReset={onReset}
        onSave={onSave}
        changeDescription={changeDescription}
        onDescriptionChange={setChangeDescription}
      />
    );
  };

  if (!showMLFlowArtifactsButton && !savedCode && props.readOnly) {
    return null;
  }

  return (
    <>
      <div className="box transparent-header">
        <div className="box-header big-padding smaller-height pl-0 pr-0">
          <h2 className="box-title">{label}</h2>
          <ButtonToolbar>
            {showMLFlowArtifactsButton && (
              <Button bsStyle="default" onClick={() => setShowMLFlowArtifactsModal(true)}>
                Show all MLFlow models
              </Button>
            )}
            {!!savedCode && (
              <Clipboard
                inline={false}
                tooltipPlacement="top"
                label={`Copy all ${label}`}
                text={savedCode}
              />
            )}
            {(!props.readOnly || !!savedCode) && (
              <Button bsStyle="default" onClick={onOpenEditor}>
                {!savedCode ? (
                  <>
                    <FontAwesomeIcon fixedWidth icon="plus" className="btn-icon" />
                    Create multiple {label}
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon fixedWidth icon="code" className="btn-icon" />
                    {!props.readOnly ? 'Edit' : 'Show'} all {label}
                  </>
                )}
              </Button>
            )}
          </ButtonToolbar>
        </div>
        <MLFlowArtifactsModal
          models={props.models}
          show={showMLFlowArtifactsModal}
          onHide={() => setShowMLFlowArtifactsModal(false)}
        />
        {showAllCodeBlocksModal && (
          <CodeEditorModal
            withAutocomplete
            title={`All ${label}`}
            onClose={() => setShowAllCodeBlocksModal(false)}
            value={editedValue}
            onChange={setEditedValue}
            onSave={onSave}
            onReset={onReset}
            isChanged={isChanged}
            renderAdditionalButtons={renderAdditionalButtons}
            codeMirrorOptions={{
              mode: resolveEditorMode(props.component.get('id')),
              ...(props.readOnly
                ? {
                    lineNumbers: false,
                    cursorHeight: 0,
                    readOnly: true,
                  }
                : {
                    placeholder: '-- Your code goes here',
                    hintOptions: {
                      completeSingle: false,
                      container: document.querySelector('.full-screen-modal.full-screen-editor'),
                      tables: getTablesHints(
                        props.component.get('id'),
                        props.config.get('configuration', Map()),
                        props.tables,
                      ),
                      variables: getVariablesHints(props.variables),
                    },
                  }),
            }}
          />
        )}
      </div>
    </>
  );
};

export default CodeBlocksHeader;
