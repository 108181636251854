import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { Clipboard } from '@keboola/design';

import Loader from '@/react/common/Loader';

const SyncActionSimpleValue = ({ action, valueKey, withClipboard = false }) => {
  if (action.get('status') === 'pending') {
    return <Loader />;
  }

  if (action.get('status') === 'error') {
    return action.get('error');
  }

  if (action.get('status') === 'none') {
    return 'N/A';
  }

  const value = action.getIn(['data'].concat(valueKey), 'Unknown');

  if (withClipboard) {
    return (
      <>
        {value}
        <Clipboard text={value} />
      </>
    );
  }

  return value;
};

SyncActionSimpleValue.propTypes = {
  action: PropTypes.instanceOf(Map).isRequired,
  valueKey: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  withClipboard: PropTypes.bool,
};

export default SyncActionSimpleValue;
