import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import { cn, CollapsiblePanel } from '@keboola/design';

import { prepareVariables } from './helpers';

class VariablesOverridePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      variablesOverride: Map(),
    };
  }

  render() {
    const preparedVariables = prepareVariables(
      this.props.allConfigurations,
      this.props.componentId,
      this.props.configId,
      this.props.variableValuesId,
      this.props.variableValuesData,
    );

    if (preparedVariables.isEmpty()) {
      return null;
    }

    return (
      <CollapsiblePanel
        header={
          <div className="tw-flex tw-items-center">
            <span className="fa-layers f-24 color-purple mrp-3">
              <FontAwesomeIcon icon="circle" fixedWidth />
              <FontAwesomeIcon
                icon="code"
                fixedWidth
                transform="shrink-8"
                style={{ color: '#fff' }}
              />
            </span>
            Variables
          </div>
        }
        className="panel mt-1 tw-col-start-2 tw-col-end-[-1]"
        bodyClassName="tw-bg-white tw-p-0"
        isDisabled={this.props.isDisabled}
      >
        <table
          className="table"
          role="presentation"
          tabIndex={-1}
          onClick={(e) => e.stopPropagation()}
        >
          <tbody>
            {preparedVariables
              .map((variable) => {
                return (
                  <tr key={variable.get('name')} className="variable-override">
                    <td className="variable-name">{variable.get('name')}</td>
                    <td className="variable-value">
                      <FormGroup className="m-0">
                        <FormControl
                          type="text"
                          placeholder={variable.get('value') || 'EMPTY STRING'}
                          onChange={(event) =>
                            this.onChangeHandler(variable.get('name'), event.target.value)
                          }
                          className={cn({
                            'is-dirty': this.state.variablesOverride.has(variable.get('name')),
                          })}
                          value={this.state.variablesOverride.get(variable.get('name'), '')}
                        />
                        {this.state.variablesOverride.has(variable.get('name')) && (
                          <Button
                            type="reset"
                            bsStyle="link"
                            className="btn-link-inline text-muted"
                            onClick={() => {
                              this.onChangeHandler(variable.get('name'), '');
                            }}
                          >
                            <FontAwesomeIcon icon="circle-xmark" fixedWidth />
                          </Button>
                        )}
                      </FormGroup>
                    </td>
                  </tr>
                );
              })
              .toArray()}
          </tbody>
        </table>
      </CollapsiblePanel>
    );
  }

  onChangeHandler(name, value) {
    this.setState(
      {
        variablesOverride: value
          ? this.state.variablesOverride.set(name, value)
          : this.state.variablesOverride.delete(name),
      },
      () => this.props.onChangeFn(this.state.variablesOverride),
    );
  }
}

VariablesOverridePanel.propTypes = {
  componentId: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  allConfigurations: PropTypes.instanceOf(Map).isRequired,
  onChangeFn: PropTypes.func.isRequired,
  variableValuesId: PropTypes.string,
  variableValuesData: PropTypes.instanceOf(Map),
  isDisabled: PropTypes.bool,
};

export default VariablesOverridePanel;
