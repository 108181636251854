import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { canWriteBucket } from '@/modules/admin/privileges';
import { filterProductionBuckets } from '@/modules/dev-branches/helpers';
import { bucketDisplayNameWithStage, validateTableName } from '@/modules/storage/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import { bucketLabel } from '@/react/common/selectLabels';
import PredefinedInput from './PredefinedInput';

const INITIAL_STATE = {
  bucketId: '',
  name: '',
  warning: null,
  isSaving: false,
};

const CreateTableFromSnapshotModal = createReactClass({
  propTypes: {
    sapiToken: PropTypes.object.isRequired,
    show: PropTypes.bool.isRequired,
    snapshot: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    activeBucketId: PropTypes.string.isRequired,
    tableName: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      ...INITIAL_STATE,
    };
  },

  render() {
    const bucketsOptions = filterProductionBuckets(this.props.buckets)
      .filter((bucket) => canWriteBucket(this.props.sapiToken, bucket))
      .sortBy((bucket) => bucketDisplayNameWithStage(bucket))
      .map((bucket) => {
        return {
          value: bucket.get('id'),
          label: bucketLabel(bucket),
          name: bucket.get('displayName'),
        };
      })
      .toArray();

    return (
      <Modal
        show={this.props.show}
        onHide={this.onHide}
        onEnter={() =>
          this.setState({
            bucketId: this.props.activeBucketId,
            name: `${this.props.tableName}_${this.props.snapshot.get('id')}`,
          })
        }
      >
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create Table from Snapshot {this.props.snapshot.get('id')}</Modal.Title>
            <ModalIcon icon="table" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Destination Bucket</ControlLabel>
              <Select
                autoFocus
                clearable={false}
                value={this.state.bucketId}
                onChange={this.handleDestinationBucket}
                options={bucketsOptions}
              />
            </FormGroup>
            <PredefinedInput
              entity="tableName"
              value={this.state.name}
              warning={this.state.warning}
              onChange={this.handleName}
            />
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isDisabled={this.isDisabled()}
              saveLabel="Create table"
              saveButtonType="submit"
              isSaving={this.state.isSaving}
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  },

  handleDestinationBucket(selected) {
    this.setState({ bucketId: selected }, this.validateName);
  },

  handleName(name) {
    this.setState({ name }, this.validateName);
  },

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ isSaving: true });
    return this.props
      .onConfirm(this.props.snapshot.get('id'), this.state.bucketId, this.state.name)
      .then(this.onHide)
      .finally(() => this.setState({ isSaving: false }));
  },

  validateName() {
    this.setState(({ name }) => ({
      warning: validateTableName(
        name,
        this.props.tables.filter((table) => table.getIn(['bucket', 'id']) === this.state.bucketId),
      ),
    }));
  },

  onHide() {
    this.setState(INITIAL_STATE);
    this.props.onHide();
  },

  isDisabled() {
    return !this.state.bucketId || !this.state.name || !!this.state.warning;
  },
});

export default CreateTableFromSnapshotModal;
