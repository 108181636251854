import clsx from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const twMerge = extendTailwindMerge({
  prefix: 'tw-',
});

type Mapping = Record<string, unknown>;
type Argument = string | boolean | undefined | Mapping;

export const cn = (...classes: Argument[]) => twMerge(clsx(...classes));

export const extractExternalClasses = (className?: string) => {
  if (!className) {
    return ['', ''];
  }

  const [extCls, rest] = className.split(' ').reduce(
    (acc, cls) => {
      acc[cls.startsWith('extCls-') ? 0 : 1].push(cls);
      return acc;
    },
    [[], []] as [string[], string[]],
  );

  return [extCls.join(' '), rest.join(' ')];
};
