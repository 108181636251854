import React, { type ComponentProps } from 'react';

import { HelpBlock } from '../HelpBlock';

import { useFormGroupContext } from './context';

type Props = Omit<ComponentProps<typeof HelpBlock>, 'testId' | 'state'>;

export const FormGroupHelp = ({ children, ...rest }: Props) => {
  const formGroupContext = useFormGroupContext();

  return (
    <HelpBlock state={formGroupContext.state} {...rest}>
      {children}
    </HelpBlock>
  );
};
