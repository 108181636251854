const ActionTypes = {
  METADATA_SAVE_SUCCESS: 'METADATA_SAVE_SUCCESS',
  METADATA_DELETE_SUCCESS: 'METADATA_DELETE_SUCCESS',
  BRANCH_METADATA_LOAD_SUCCESS: 'BRANCH_METADATA_LOAD_SUCCESS',
};

const ObjectTypes = {
  BUCKET: 'bucket',
  TABLE: 'table',
  COLUMN: 'column',
  BRANCH: 'branch',
} as const;

const MetadataKeys = {
  // storage keys
  PROVIDER: 'provider',
  BASE_TYPE: 'KBC.datatype.basetype',
  TYPE: 'KBC.datatype.type',
  DEFAULT: 'KBC.datatype.default',
  LENGTH: 'KBC.datatype.length',
  NULLABLE: 'KBC.datatype.nullable',
  CREATED_BY_COMPONENT_ID: 'KBC.createdBy.component.id',
  CREATED_BY_CONFIGURATION_ID: 'KBC.createdBy.configuration.id',
  CREATED_BY_BRANCH_ID: 'KBC.createdBy.branch.id',
  LAST_UPDATED_BY_COMPONENT_ID: 'KBC.lastUpdatedBy.component.id',
  LAST_UPDATED_BY_CONFIGURATION_ID: 'KBC.lastUpdatedBy.configuration.id',
  DESCRIPTION: 'KBC.description',
  SHARED_DESCRIPTION: 'KBC.sharedDescription',
  STREAM_SOURCE_ID: 'KBC.stream.source.id',
  STREAM_SINK_ID: 'KBC.stream.sink.id',

  // configurations keys
  CONFIGURATION_FOLDER: 'KBC.configuration.folderName',
  CONFIGURATION_CREATED_FROM: 'KBC.configuration.createdFrom',
  CREATED_BY_AUTOMATION_ID: 'KBC.configuration.createdByAutomationId',
  TEMPLATES_INSTANCE_ID: 'KBC.KAC.templates.instanceId',

  // branch keys
  PROJECT_DESCRIPTION: 'KBC.projectDescription',

  // used in lineage
  IS_ALIAS: 'KBC.isAlias',
};

const BaseTypes = ['STRING', 'INTEGER', 'DATE', 'TIMESTAMP', 'BOOLEAN', 'FLOAT', 'NUMERIC'];

const USED_METADATA_KEYS = [
  MetadataKeys.CONFIGURATION_FOLDER,
  MetadataKeys.CONFIGURATION_CREATED_FROM,
  MetadataKeys.TEMPLATES_INSTANCE_ID,
  MetadataKeys.CREATED_BY_AUTOMATION_ID,
];

export { ActionTypes, ObjectTypes, MetadataKeys, BaseTypes, USED_METADATA_KEYS };
