import { resolve } from 'react-router-named-routes';

import ApplicationStore from '@/stores/ApplicationStore';
import { getUrlWithBaseName } from '@/utils/router/getUrlWithBaseName';

// TODO REMOVE after removing react-router-named-routes package and using react router useNavigate instead of history manipulation
export const resolveUrlWithBaseName = (
  pathname: string,
  params?: Record<string, string | number | boolean>,
): string => {
  const resolvedPathname = resolve(pathname, params);

  return resolvedPathname.startsWith(ApplicationStore.getProjectBaseUrl())
    ? resolvedPathname
    : getUrlWithBaseName(resolvedPathname);
};
