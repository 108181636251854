import React from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { createRoot } from 'react-dom/client';
import { JSONEditor } from '@json-editor/json-editor';
import _ from 'underscore';

import { TooltipProvider } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import callDockerAction from '@/modules/components/DockerActionsApi';
import Select from '@/react/common/Select';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import {
  getCacheKey,
  getCurrentConfigData,
  isValidNewOption,
  shouldAutoload,
} from '@/utils/json-editor/helpers';
import { setStoreData, store } from '@/utils/json-editor/store';
import nextTick from '@/utils/nextTick';

export const useAsyncSelect = ({
  async,
  options,
  onAsyncOptionsLoaded,
  setLoading,
  isReadOnly,
}) => {
  const wasLoaded = React.useRef(false);

  const performAction = React.useCallback(
    (event) => {
      if (!async?.action || isReadOnly) {
        return;
      }

      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configData = getCurrentConfigData();
      const useCache = async.cache ?? true;
      const autoload = shouldAutoload(async.autoload, getCurrentConfigData());
      const cacheKey = useCache
        ? getCacheKey(componentId, async.action, configData, async.autoload)
        : null;

      if (!event && useCache && store.has(cacheKey)) {
        const storedOptions = store.get(cacheKey);

        if (_.isEqual(storedOptions, options)) {
          return;
        }

        return nextTick(() => onAsyncOptionsLoaded(storedOptions));
      }

      if (!event && !autoload) {
        return;
      }

      setLoading(true);
      return callDockerAction(componentId, async.action, { configData: configData.toJS() })
        .then((response) => {
          if (response?.status === 'error') {
            return ApplicationActionCreators.sendNotification({
              type: 'error',
              message: response.message ?? 'Data cannot be loaded.',
            });
          }

          if (!Array.isArray(response)) {
            return;
          }

          if (useCache) {
            setStoreData(cacheKey, response);
          }

          wasLoaded.current = true;
          onAsyncOptionsLoaded(response);
        })
        .finally(() => setLoading(false));
    },
    [async, onAsyncOptionsLoaded, setLoading, options, isReadOnly],
  );

  React.useEffect(() => {
    if (wasLoaded.current) {
      return;
    }

    performAction();
  }, [performAction]);

  return { performAction };
};

const SelectApp = (props) => {
  const [loading, setLoading] = React.useState(false);

  const { performAction } = useAsyncSelect({
    setLoading,
    async: props.async,
    options: props.options,
    onAsyncOptionsLoaded: props.onAsyncOptionsLoaded,
    isReadOnly: props.isReadOnly,
  });

  const renderSelect = () => {
    return (
      <Select
        allowCreate={props.creatable}
        value={props.value}
        options={props.options}
        isLoading={loading}
        clearable={props.clearable}
        disabled={props.disabled}
        placeholder={props.placeholder}
        onChange={props.onChange}
        {...(props.pattern && {
          isValidNewOption: (input) => isValidNewOption(input, props.pattern),
        })}
      />
    );
  };

  if (!!props.async?.action) {
    return (
      <InputGroup>
        {renderSelect()}
        <InputGroup.Button>
          <Button className="ml-1" disabled={loading || props.isReadOnly} onClick={performAction}>
            {props.async.label ?? 'Load data'}
          </Button>
        </InputGroup.Button>
      </InputGroup>
    );
  }

  return renderSelect();
};

export default class ReactSelectPlugin extends JSONEditor.defaults.editors.select {
  renderReactSelect() {
    if (this.root) {
      this.root.render(
        <TooltipProvider>
          <SelectApp
            disabled={this.disabled}
            clearable={!this.isRequired()}
            creatable={!!this.options.creatable}
            placeholder={this.options.inputAttributes?.placeholder}
            value={this.value}
            options={this.enum_values
              .map((value, index) => ({ value, label: this.enum_display[index] ?? value }))
              .filter((option) => !_.isUndefined(option.value))}
            onChange={(selected) => {
              this.value = selected;
              this.onChange(true);
              this.renderReactSelect();
            }}
            onAsyncOptionsLoaded={(options) => {
              this.enum_values = [];
              this.enum_display = [];

              options.forEach((option, index) => {
                this.enum_values[index] = option.value;
                this.enum_display[index] = option.label;
              });

              this.renderReactSelect();
              this.jsoneditor?.notifyWatchers(this.path);
            }}
            async={this.options.async}
            isReadOnly={ApplicationStore.isReadOnly()}
            pattern={this.schema?.pattern}
          />
        </TooltipProvider>,
      );
    }
  }

  afterInputReady() {
    // create new react-select root
    const selectRoot = window.document.createElement('span');

    // add new react-select root before existing select
    this.selectNode = this.input.parentNode.insertBefore(selectRoot, this.input);

    // hide old select
    this.input.style.display = 'none';

    // create react root
    this.root = createRoot(selectRoot);

    // render react-select
    this.renderReactSelect();

    super.afterInputReady();
  }

  setValue(value, initial) {
    if (value && !!this.schema.enum && !this.schema.enum.length) {
      this.enum_display = [value];
      this.enum_values = [value];
      this.enum_options = [value];
    }

    super.setValue(value, initial);

    if (this.root && !initial) {
      this.renderReactSelect();
    }
  }

  enable() {
    // turn off readonly after save
    if (!this.always_disabled) {
      this.selectNode
        ?.querySelector('.react-select__control')
        ?.classList.remove('react-select__control--is-disabled');
    }

    super.enable();
  }

  disable(alwaysDisabled) {
    // turn on readonly during save
    this.selectNode
      ?.querySelector('.react-select__control')
      ?.classList.add('react-select__control--is-disabled');

    super.disable(alwaysDisabled);
  }

  destroy() {
    setTimeout(() => {
      this.root?.unmount();
      this.root = null;
      this.input.previousSibling?.remove();
    });

    super.destroy();
  }

  showValidationErrors(errors) {
    if (this.options?.async?.action) {
      return null;
    }

    super.showValidationErrors(errors);
  }
}
