import React from 'react';
import type { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import TriggersStore from '@/modules/event-trigger/EventTriggersStore';
import NotificationsStore from '@/modules/notifications/store';
import JobsStore from '@/modules/queue/store';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import Automations from './Automations';
import NewAutomation from './NewAutomation';
import AutomationsStore from './store';

const Index = () => {
  const store = useStores(
    () => {
      return {
        automations: AutomationsStore.getStore().automations.sort(
          (a, b) => new Date(b.createdAt ?? '').getTime() - new Date(a.createdAt ?? '').getTime(),
        ),
        allComponents: ComponentsStore.getAll() as Map<string, any>,
        allConfigurations: InstalledComponentsStore.getAll() as Map<string, any>,
        componentsMetadata: InstalledComponentsStore.getAllMetadata() as Map<string, any>,
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
        latestJobs: JobsStore.getLatestConfigJobs(),
        triggers: TriggersStore.getForComponent(KEBOOLA_ORCHESTRATOR),
        notifications: NotificationsStore.getAll(),
        admins: ApplicationStore.getAdmins(),
        sapiToken: ApplicationStore.getSapiToken(),
        currentAdmin: ApplicationStore.getCurrentAdmin(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        hasFlows: ApplicationStore.hasFlows(),
        readOnly: ApplicationStore.isReadOnly(),
      };
    },
    [],
    [
      AutomationsStore,
      ComponentsStore,
      InstalledComponentsStore,
      ApplicationStore,
      JobsStore,
      TriggersStore,
      NotificationsStore,
    ],
  );

  return (
    <>
      <NewAutomation />
      <Automations
        automations={store.automations}
        admins={store.admins}
        currentAdmin={store.currentAdmin}
        notifications={store.notifications}
        triggers={store.triggers}
        latestJobs={store.latestJobs}
        allConfigurations={store.allConfigurations}
        allComponents={store.allComponents}
        sapiToken={store.sapiToken}
        hasProtectedDefaultBranch={store.hasProtectedDefaultBranch}
        hasFlows={store.hasFlows}
        readOnly={store.readOnly}
        componentsMetadata={store.componentsMetadata}
      />
    </>
  );
};

export default Index;
