import { useEffect, useState } from 'react';
import { md5 as MD5 } from 'js-md5';

// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
const SHA256 = async (text: string) => {
  const msgUint8 = new TextEncoder().encode(text);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

const useSHA256 = (text?: string) => {
  const [hash, setHash] = useState('');

  useEffect(() => {
    if (text) {
      SHA256(text).then(setHash);
    }
  }, [text]);

  return hash;
};

export { MD5, useSHA256 };
