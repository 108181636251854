import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { URLS } from '@keboola/constants';
import { Link, Tooltip } from '@keboola/design';

const DocumentationLink = (props: {
  path: string;
  format?: 'text' | 'icon';
  children?: React.ReactNode;
}) => {
  const href = `${URLS.DEVELOPERS_DOCUMENTATION}/extend/generic-extractor/${props.path}`;

  if (props.children) {
    return <Link href={href}>{props.children}</Link>;
  }

  if (props.format === 'text') {
    return (
      <>
        Read more in the <Link href={href}>documentation</Link>.
      </>
    );
  }

  return (
    <Tooltip placement="top" tooltip="Open documentation">
      <Link href={href}>
        <FontAwesomeIcon icon="book-blank" className="tw-ml-3 tw-text-sm tw-text-neutral-400" />
      </Link>
    </Tooltip>
  );
};

export default DocumentationLink;
