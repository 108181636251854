import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS, Map } from 'immutable';

import { createSimpleRow } from '@/modules/configurations/ConfigurationRowsActionCreators';
import CreateRowModal from '@/react/common/CreateRowModal';
import string from '@/utils/string';

class CreateConfigurationRowButton extends React.Component {
  state = {
    show: false,
  };

  render() {
    return (
      <>
        <Button bsStyle="success" bsSize="sm" onClick={this.handleShowModal}>
          <FontAwesomeIcon icon="plus" className="icon-addon-right" />
          Add {this.props.objectName.toLowerCase()}
        </Button>
        <CreateRowModal
          component={this.props.component}
          configId={this.props.configId}
          entity={this.props.objectName}
          buckets={this.props.buckets}
          tables={this.props.tables}
          show={this.state.show}
          onHide={this.handleCloseModal}
          onCreate={this.handleCreate}
          onRowRedirect={this.props.onRowCreated}
        />
      </>
    );
  }

  handleShowModal = () => {
    this.setState({ show: true });
  };

  handleCloseModal = () => {
    this.setState({ show: false });
  };

  handleCreate = (tableId, name, options) => {
    let configData = this.props.emptyConfig(tableId, string.webalize(tableId));

    if (
      configData.isEmpty() &&
      !this.props.component.get('emptyConfigurationRow', Map()).isEmpty()
    ) {
      configData = this.props.component.get('emptyConfigurationRow');
    }

    if (options.addStorageTable) {
      configData = configData.setIn(
        ['storage', 'input', 'tables'],
        fromJS([{ source: tableId, destination: `${tableId}.csv` }]),
      );
    }

    return createSimpleRow(
      this.props.component.get('id'),
      this.props.configId,
      { name, configuration: JSON.stringify(configData.toJS()) },
      `${this.props.objectName} ${name} added`,
    );
  };
}

CreateConfigurationRowButton.propTypes = {
  component: PropTypes.instanceOf(Map).isRequired,
  configId: PropTypes.string.isRequired,
  onRowCreated: PropTypes.func.isRequired,
  tables: PropTypes.instanceOf(Map),
  buckets: PropTypes.instanceOf(Map),
  emptyConfig: PropTypes.func,
  objectName: PropTypes.string,
};

CreateConfigurationRowButton.defaultProps = {
  objectName: 'Row',
  emptyConfig: () => Map(),
};

export default CreateConfigurationRowButton;
