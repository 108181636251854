import React from 'react';

import { cn } from '@keboola/design';

export const TabNav = (props: { children: React.ReactNode; className?: string }) => {
  return (
    <ul role="navigation" className={cn('nav nav-tabs', props.className)}>
      {props.children}
    </ul>
  );
};
