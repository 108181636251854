import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { FormGroup, HelpBlock, Label, TextInput, Tooltip } from '@keboola/design';

import { hasInitQueries, hasSshTunnel, hasSsl } from '@/modules/ex-db-generic/helpers';
import Checkbox from '@/react/common/Checkbox';
import PasswordControl from '@/react/common/PasswordControl';
import Select from '@/react/common/Select';
import SshForm from '@/react/common/SshForm';
import SSLForm from '@/react/common/SSLForm';
import TestCredentialsButtonGroup from '@/react/common/TestCredentialsButtonGroup';
import string from '@/utils/string';
import InitQueriesForm from './InitQueriesForm';

const FORM_GROUP_CLASS_NAMES = 'tw-grid tw-grid-cols-3 tw-items-baseline tw-gap-4';

const CredentialsForm = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    savedCredentials: PropTypes.object.isRequired,
    credentials: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isValidEditingCredentials: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    credentialsTemplate: PropTypes.object.isRequired,
    actionCreators: PropTypes.object.isRequired,
    isSingleTenant: PropTypes.bool.isRequired,
    hasQueries: PropTypes.bool.isRequired,
  },

  getDefaultProps() {
    return {
      onChange: () => null,
    };
  },

  testCredentials() {
    return this.props.actionCreators.testCredentials(this.props.configId, this.props.credentials);
  },

  handleChange(field, event) {
    const value =
      !!event.target.value.length && field.type === 'number'
        ? parseInt(event.target.value, 10)
        : event.target.value;

    return this.props.onChange(this.props.credentials.set(field.name, value));
  },

  handleInitQueriesChange(queries) {
    const credentials = queries.isEmpty()
      ? this.props.credentials.delete('initQueries')
      : this.props.credentials.set('initQueries', queries);

    this.props.onChange(credentials);
  },

  handleCheckboxChange(propName, checked) {
    return this.props.onChange(this.props.credentials.set(propName, checked));
  },

  renderProtectedLabel(labelValue, alreadyEncrypted) {
    let msg = labelValue + ' will be stored securely encrypted.';

    if (alreadyEncrypted) {
      msg = msg + ' The most recently stored value will be used if left empty.';
    }

    return (
      <>
        {labelValue}
        <Tooltip tooltip={msg} type="static-explanatory">
          <FontAwesomeIcon icon="circle-question" className="icon-addon-left" />
        </Tooltip>
      </>
    );
  },

  createInput(field) {
    if (field.isProtected) {
      const savedValue = this.props.savedCredentials.get(field.name);
      return (
        <FormGroup key={field.name} className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor={string.webalize(field.label)}>
            {this.renderProtectedLabel(field.label, !!savedValue)}
          </Label>
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
            <PasswordControl
              id={string.webalize(field.label)}
              disabled={!this.props.enabled || this.props.readOnly}
              placeholder={savedValue ? 'type a new password to change it' : field.placeholder}
              value={this.props.credentials.get(field.name) || ''}
              onChange={this.handleChange.bind(this, field)}
            />
            <HelpBlock>{field.help}</HelpBlock>
          </div>
        </FormGroup>
      );
    }

    switch (field.type) {
      case 'checkbox':
        return (
          <FormGroup key={field.name} className={FORM_GROUP_CLASS_NAMES}>
            <div className="tw-col-span-2 tw-col-start-2 tw-flex tw-flex-col tw-gap-1">
              <Checkbox
                checked={this.props.credentials.get(field.name) || false}
                onChange={this.handleCheckboxChange.bind(this, field.name)}
                disabled={!this.props.enabled || this.props.readOnly}
              >
                {field.label}
              </Checkbox>
              <HelpBlock>{field.help}</HelpBlock>
            </div>
          </FormGroup>
        );

      case 'select':
        return (
          <FormGroup key={field.name} className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor={string.webalize(field.label)}>{field.label}</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <Select
                id={string.webalize(field.label)}
                clearable={false}
                placeholder={field.placeholder}
                value={this.props.credentials.get(field.name) || ''}
                onChange={(value) => {
                  return this.props.onChange(this.props.credentials.set(field.name, value));
                }}
                options={[
                  !field.required && { value: '', label: 'Default' },
                  ...field.options,
                ].filter(Boolean)}
                disabled={this.props.isSaving || this.props.readOnly}
              />
              <HelpBlock>{field.help}</HelpBlock>
            </div>
          </FormGroup>
        );

      case 'textarea':
        return (
          <FormGroup key={field.name} className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor={string.webalize(field.label)}>{field.label}</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <Textarea
                id={string.webalize(field.label)}
                minRows={4}
                className="form-control"
                placeholder={field.placeholder}
                value={this.props.credentials.get(field.name) || ''}
                onChange={this.handleChange.bind(this, field)}
                disabled={!this.props.enabled || this.props.readOnly}
              />
              <HelpBlock>{field.help}</HelpBlock>
            </div>
          </FormGroup>
        );

      case 'text':
        return (
          <FormGroup key={field.name} className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor={string.webalize(field.label)}>{field.label}</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <TextInput
                id={string.webalize(field.label)}
                variant="secondary"
                placeholder={field.placeholder}
                value={this.props.credentials.get(field.name) || ''}
                onChange={(_, event) => this.handleChange.bind(this, field)(event)}
                disabled={!this.props.enabled || this.props.readOnly}
              />
              <HelpBlock>{field.help}</HelpBlock>
            </div>
          </FormGroup>
        );

      default:
        return (
          <FormGroup key={field.name} className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor={string.webalize(field.label)}>{field.label}</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <FormControl
                id={string.webalize(field.label)}
                type={field.type}
                placeholder={field.placeholder}
                value={this.props.credentials.get(field.name) || ''}
                onChange={this.handleChange.bind(this, field)}
                disabled={!this.props.enabled || this.props.readOnly}
              />
              <HelpBlock>{field.help}</HelpBlock>
            </div>
          </FormGroup>
        );
    }
  },

  renderFields() {
    return this.props.credentialsTemplate
      .getFields(this.props.componentId)
      .filter((field) => {
        if (field.onlySingleTenant) {
          return this.props.isSingleTenant;
        }
        return true;
      })
      .map((field) => this.createInput(field));
  },

  sshRowOnChange(sshObject) {
    return this.props.onChange(this.props.credentials.set('ssh', sshObject));
  },

  sslRowOnChange(sslObject) {
    return this.props.onChange(this.props.credentials.set('ssl', sslObject));
  },

  renderInitQueries() {
    if (!hasInitQueries(this.props.componentId)) {
      return null;
    }

    return (
      <InitQueriesForm
        isEditing={this.props.isEditing}
        componentId={this.props.componentId}
        readOnly={this.props.readOnly || !this.props.enabled}
        queries={this.props.credentials.get('initQueries', List())}
        onChange={this.handleInitQueriesChange}
      />
    );
  },

  renderSshRow() {
    if (!hasSshTunnel(this.props.componentId)) {
      return null;
    }

    return (
      <SshForm
        readOnly={this.props.readOnly}
        isEnabled={this.props.enabled}
        data={this.props.credentials.get('ssh', Map())}
        onChange={this.sshRowOnChange}
      />
    );
  },

  renderSSLForm() {
    if (!hasSsl(this.props.componentId)) {
      return null;
    }

    return (
      <SSLForm
        readOnly={this.props.readOnly}
        componentId={this.props.componentId}
        isEditing={this.props.enabled}
        data={this.props.credentials.get('ssl', Map())}
        onChange={this.sslRowOnChange}
      />
    );
  },

  render() {
    const { componentId, configId, enabled, isValidEditingCredentials, isEditing } = this.props;

    return (
      <div className="tw-flex tw-flex-col tw-gap-4">
        {this.props.hasQueries && (
          <HelpBlock className="tw-mt-1">
            Note that by changing the credentials (e.g., database name), all your configured queries
            may become invalid.
          </HelpBlock>
        )}
        {this.renderFields()}
        {this.renderInitQueries()}
        {this.renderSshRow()}
        {this.renderSSLForm()}
        {!this.props.readOnly && (
          <TestCredentialsButtonGroup
            componentId={componentId}
            configId={configId}
            isEditing={isEditing}
            disabled={enabled ? !isValidEditingCredentials : false}
            testCredentialsFn={this.testCredentials}
          />
        )}
      </div>
    );
  },
});

export default CredentialsForm;
