import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS, List, Map } from 'immutable';

import { Alert, Tooltip } from '@keboola/design';

import {
  KEBOOLA_EX_FACEBOOK,
  KEBOOLA_EX_FACEBOOK_ADS,
  KEBOOLA_EX_GOOGLE_ADS,
  KEBOOLA_EX_GOOGLE_ANALYTICS_V_4,
  KEBOOLA_EX_INSTAGRAM,
} from '@/constants/componentIds';
import callDockerAction from '@/modules/components/DockerActionsApi';
import { OnlyEnabledCustomersCheckbox } from '@/modules/ex-google-ads/react/components/AccountsSettings';
import TwoVersionsApiWarning from '@/modules/ex-google-analytics-v4/components/TwoVersionsApiWarning';
import {
  authorizeConfiguration,
  getProfileName,
  prepareProfilesData,
} from '@/modules/ex-google-analytics-v4/helpers';
import { parseSavedProfiles, prepareProfiles } from '@/modules/google-utils/helpers';
import Loader from '@/react/common/Loader';
import Select from '@/react/common/Select';
import { matchByWords } from '@/utils';
import nextTick from '@/utils/nextTick';

const ACTIONS = {
  [KEBOOLA_EX_GOOGLE_ADS]: {
    actionName: 'listAccounts',
    entityName: 'account',
    processOptions: (accounts) =>
      fromJS(accounts)
        .map((account, id) => account.set('value', id))
        .toMap()
        .mapKeys((key, account) => account.getIn(['info', 'id'])),
    getAccountLabel: (account) => {
      const name = account.getIn(['info', 'descriptiveName']);
      const childrenString = account
        .get('children', List())
        .map((childAccount) =>
          childAccount.getIn(['info', 'descriptiveName'], childAccount.getIn(['info', 'id'])),
        )
        .filter(Boolean)
        .join(', ');

      return `${
        name ? `${name} (${account.getIn(['info', 'id'])})` : account.getIn(['info', 'id'])
      }${childrenString ? ` - ${childrenString}` : ''}`;
    },
  },
  [KEBOOLA_EX_GOOGLE_ANALYTICS_V_4]: {
    actionName: 'getProfilesProperties',
    entityName: 'profile',
    processOptions: (profiles) =>
      prepareProfiles(profiles)
        .toList()
        .flatten(2)
        .toMap()
        .mapKeys((key, account) => account.get('id', account.get('propertyKey'))),
    processInput: (configData) => parseSavedProfiles(configData),
    processOutput: (profiles) => prepareProfilesData(Map(), profiles),
    getAccountLabel: (profile) =>
      profile.get('accountName', profile.get('webPropertyName', profile.get('propertyName'))),
    getSubAccountLabel: getProfileName,
  },
  [KEBOOLA_EX_FACEBOOK_ADS]: {
    actionName: 'adaccounts',
    entityName: 'account',
  },
  [KEBOOLA_EX_FACEBOOK]: {
    actionName: 'accounts',
    entityName: 'account',
  },
  [KEBOOLA_EX_INSTAGRAM]: {
    actionName: 'igaccounts',
    entityName: 'account',
  },
};

class SyncAccountsSelect extends React.Component {
  static propTypes = {
    componentId: PropTypes.string.isRequired,
    saved: PropTypes.instanceOf(Map).isRequired,
    oauthCredentials: PropTypes.instanceOf(Map).isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  state = {
    availableAccounts: Map(),
    options: Map(),
    selected: Map(),
    messages: List(),
    onlyEnabledCustomers: false,
    loadingOptionsError: null,
    isLoadingOptions: false,
  };

  componentDidMount() {
    this.setState({ selected: this.processInput(this.props.saved) });
    nextTick(this.loadOptions);
  }

  render() {
    if (!Object.keys(ACTIONS).includes(this.props.componentId)) return null;

    const entityName = ACTIONS[this.props.componentId].entityName;

    return (
      <>
        {this.renderAccountSelector(entityName)}
        {this.renderSelectedAccounts(entityName)}
        {this.props.componentId === KEBOOLA_EX_GOOGLE_ADS && (
          <OnlyEnabledCustomersCheckbox
            value={this.props.saved.toJS()}
            onChange={(newValue) => this.setState(newValue, this.handleChange)}
            isDisabled={this.props.readOnly || this.state.selected.isEmpty()}
          />
        )}
        {this.props.componentId === KEBOOLA_EX_GOOGLE_ANALYTICS_V_4 && (
          <TwoVersionsApiWarning profiles={this.state.selected} />
        )}
      </>
    );
  }

  renderAccountSelector(entityName) {
    return (
      <>
        <Select
          className="select-with-border mb-1"
          disabled={this.state.isLoadingOptions || this.state.options.isEmpty()}
          placeholder={this.renderPlaceholder(entityName)}
          options={this.state.options
            .map((account) => ({
              ...account,
              ...(account.options && {
                options: account.options.filter((option) => !this.state.selected.has(option.value)),
              }),
            }))
            .filter(
              (account, accountId) =>
                !this.state.selected.has(accountId) || account.options?.length,
            )
            .toArray()}
          onChange={this.handleAccountSelect}
          noResultsText={
            this.state.options.isEmpty()
              ? `All ${entityName}s are already selected`
              : `No ${entityName} found`
          }
          filterOption={this.handleFilterOption}
          clearable={false}
        />
        {!this.state.messages.isEmpty() && (
          <div className="mt-1">
            {this.state.messages.map((message) => (
              <Alert key={message} variant="warning" className="tw-mb-5">
                {message}
              </Alert>
            ))}
          </div>
        )}
        {this.state.loadingOptionsError && (
          <Alert variant="error" className="text-left tw-mb-5 tw-mt-3.5">
            {this.state.loadingOptionsError}
          </Alert>
        )}
      </>
    );
  }

  renderSelectedAccounts(entityName) {
    if (this.state.options.isEmpty() && this.state.selected.isEmpty()) return null;

    if (this.state.selected.isEmpty()) {
      return (
        <div className="well empty-muted mt-0">
          <FontAwesomeIcon icon="users" size="3x" />
          <p>Select {entityName}s from the menu above</p>
        </div>
      );
    }

    return (
      <div className="well with-table">
        <table className="table overflow-break-anywhere">
          <thead>
            <tr>
              <th>Account</th>
              {this.hasSubAccountLabel() && <th>Profile</th>}
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.selected.map((account, id) => this.renderAccount(id, entityName)).toArray()}
          </tbody>
        </table>
      </div>
    );
  }

  renderAccount = (id, entityName) => {
    const account = this.state.availableAccounts?.get(id) || this.state.selected.get(id, Map());

    return (
      <tr key={id}>
        <td>
          <FontAwesomeIcon icon="user" className="icon-addon-right text-muted" />
          {this.getAccountLabel(account)}
        </td>
        {this.hasSubAccountLabel() && <td>{this.getSubAccountLabel(account)}</td>}
        <td className="pl-0 pr-0">
          <Tooltip placement="top" tooltip={`Remove ${entityName}`}>
            <Button
              bsStyle="link"
              className="text-muted"
              onClick={() => this.handleAccountRemove(id)}
            >
              <FontAwesomeIcon icon="trash" />
            </Button>
          </Tooltip>
        </td>
      </tr>
    );
  };

  renderPlaceholder(entityName) {
    if (!this.state.isLoadingOptions) {
      return `Select ${entityName}`;
    }

    return (
      <>
        <Loader className="icon-addon-right" />
        Loading {entityName}s...
      </>
    );
  }

  handleFilterOption = (option, filter) => {
    const account = this.state.options.get(option.value);

    const targetStrings = account
      ? [this.getAccountLabel(account), this.getSubAccountLabel?.(account)]
      : [option.value, option.label];

    return matchByWords(targetStrings, filter);
  };

  handleAccountSelect = (selectedId) => {
    const selectedValue = this.state.availableAccounts.getIn(
      [selectedId, 'value'],
      this.state.availableAccounts.get(selectedId, Map()),
    );

    this.setState(
      {
        selected: this.state.selected.set(selectedId, selectedValue),
      },
      this.handleChange,
    );
  };

  handleAccountRemove = (id) => {
    this.setState({ selected: this.state.selected.delete(id) }, this.handleChange);
  };

  handleChange = () => {
    if (this.props.componentId === KEBOOLA_EX_GOOGLE_ADS) {
      return this.props.onChange(
        fromJS({
          customerId: this.state.selected.flatten().toList(),
          onlyEnabledCustomers: this.state.onlyEnabledCustomers,
        }),
      );
    }

    return this.props.onChange(
      ACTIONS[this.props.componentId].processOutput?.(this.state.selected) ||
        fromJS({ accounts: this.state.selected }),
    );
  };

  /* WARNING: This method is also being called remotely from ./ProfilesManager.jsx#L89.
  Before changing be aware of what this method does in order to prevent any unwanted
  behaviour (e.g. infinite loop, multiple re-renders,...) */
  loadOptions = () => {
    this.setState({ isLoadingOptions: true, loadingOptionsError: null });

    callDockerAction(this.props.componentId, ACTIONS[this.props.componentId]?.actionName, {
      configData: authorizeConfiguration(Map(), this.props.oauthCredentials).toJS(),
    })
      .then((result) => {
        if (result?.status === 'error' || result?.error) {
          return this.setState({ loadingOptionsError: result.message || result.error });
        }

        this.setState({
          ...prepareAccountsOptions(
            result,
            this.processOptions,
            this.getAccountLabel,
            this.hasSubAccountLabel() && this.getSubAccountLabel,
          ),
          ...(result.messages && { messages: List(result.messages) }),
        });
      })
      .finally(() => this.setState({ isLoadingOptions: false }));
  };

  processInput = (accounts) => {
    return ACTIONS[this.props.componentId].processInput?.(accounts) || accounts;
  };

  processOptions = (accounts) => {
    return (
      ACTIONS[this.props.componentId].processOptions?.(accounts) ||
      (accounts.toJS ? accounts : fromJS(accounts))
    );
  };

  getAccountLabel = (account) => {
    return ACTIONS[this.props.componentId].getAccountLabel?.(account) || account.get('name');
  };

  hasSubAccountLabel = () => {
    return !!ACTIONS[this.props.componentId].getSubAccountLabel;
  };

  getSubAccountLabel = (account) => {
    return ACTIONS[this.props.componentId].getSubAccountLabel?.(account);
  };
}

const prepareAccountsOptions = (
  accounts,
  customPreHandler,
  getAccountLabel,
  getSubAccountLabel,
) => {
  let availableAccounts = customPreHandler?.(accounts) || accounts;
  let options;

  if (!availableAccounts.toJS) {
    availableAccounts = fromJS(availableAccounts);
  }

  if (!Map.isMap(availableAccounts)) {
    availableAccounts = availableAccounts.toMap().mapKeys((key, account) => account.get('id'));
  }

  if (getSubAccountLabel) {
    options = availableAccounts.groupBy(getAccountLabel).map((subAccounts, account) => {
      return {
        label: account,
        options: subAccounts
          .map((subAccount, subAccountId) => ({
            label: getSubAccountLabel(subAccount),
            value: subAccountId,
          }))
          .sortBy((subAccount) => subAccount.label.toLowerCase())
          .toArray(),
      };
    });
  } else {
    options = availableAccounts.map((account, accountId) => {
      return {
        label: getAccountLabel(account),
        value: accountId,
      };
    });
  }

  options = options.sortBy(
    (subAccounts, account = subAccounts) => account.toLowerCase?.() || account.label?.toLowerCase(),
  );

  return { availableAccounts, options };
};

export default SyncAccountsSelect;
