import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { Alert } from '@keboola/design';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

const AliasFilterModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    table: PropTypes.object.isRequired,
  },

  getInitialState() {
    return this.defaultValues();
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Update Filter</Modal.Title>
            <ModalIcon.Edit />
          </Modal.Header>
          <Modal.Body>
            {this.renderError()}
            <p>
              You can specify a column to filter by, and comma-separated values you&apos;re looking
              for. The alias table will contain only the matching rows.
            </p>
            <br />
            <FormGroup>
              <ControlLabel>Column</ControlLabel>
              <Select
                clearable={false}
                value={this.state.column}
                onChange={this.handleColumn}
                options={this.tableColumns()}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Operator</ControlLabel>
              <Select
                searchable={false}
                clearable={false}
                value={this.state.operator}
                onChange={this.handleOperator}
                options={[
                  { label: '= (IN)', value: 'eq' },
                  { label: '!= (NOT IN)', value: 'ne' },
                ]}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Values</ControlLabel>
              <Select
                multi
                allowCreate
                emptyStrings
                placeholder="Add a value"
                value={this.state.values}
                onChange={this.handleValues}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isSaving={this.props.isSaving}
              isDisabled={this.isDisabled()}
              saveLabel="Update"
              saveButtonType="submit"
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  },

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return (
      <Alert variant="error" className="tw-mb-5">
        {this.state.error}
      </Alert>
    );
  },

  defaultValues() {
    return {
      operator: this.props.table.getIn(['aliasFilter', 'operator'], 'eq'),
      column: this.props.table.getIn(['aliasFilter', 'column'], ''),
      values: this.props.table.getIn(['aliasFilter', 'values'], List()),
      error: null,
    };
  },

  handleColumn(option) {
    this.setState({
      column: option,
    });
  },

  handleOperator(selected) {
    this.setState({
      operator: selected,
    });
  },

  handleValues(values) {
    this.setState({ values });
  },

  onSubmit(event) {
    event.preventDefault();

    if (this.state.error) {
      this.setState({ error: null });
    }

    const params = {
      operator: this.state.operator,
      column: this.state.column,
      values: this.state.values.toArray(),
    };

    this.props.onSubmit(params).then(this.onHide, (message) => {
      this.setState({
        error: message,
      });
    });
  },

  onHide() {
    this.setState(this.defaultValues());
    this.props.onHide();
  },

  tableColumns() {
    return this.props.table
      .get('columns', List())
      .map((column) => ({
        label: column,
        value: column,
      }))
      .toArray();
  },

  isDisabled() {
    return !this.state.operator || !this.state.column || !this.state.values;
  },
});

export default AliasFilterModal;
