import { fromJS, type Map } from 'immutable';

export function createConfiguration(localState: Map<string, any>) {
  const config = fromJS({
    parameters: {
      baseUrl: localState.get('baseUrl', ''),
    },
  });
  const maxRedirects = localState.get('maxRedirects', '');

  if (maxRedirects !== '') {
    return config.setIn(['parameters', 'maxRedirects'], maxRedirects);
  }

  return config;
}

export function parseConfiguration(configuration: Map<string, any>) {
  return fromJS({
    baseUrl: configuration.getIn(['parameters', 'baseUrl'], ''),
    maxRedirects: configuration.getIn(['parameters', 'maxRedirects'], ''),
  });
}

export function isComplete(configuration: Map<string, any>) {
  return configuration.getIn(['parameters', 'baseUrl'], '') !== '';
}
