import React from 'react';
import type { Map } from 'immutable';

import { cn } from '@keboola/design';

import Gravatar from './Gravatar';
import { Truncated } from './Truncated';

type Props = {
  user: Map<string, any>;
  className?: string;
  avatar?: boolean;
  avatarRight?: boolean;
  size?: number;
};

export const User = ({ user, className, avatar = true, avatarRight = false, size = 28 }: Props) => {
  return (
    <div
      className={cn(
        'flex-container inline-flex flex-start',
        { 'flex-reverse': avatarRight },
        className,
      )}
    >
      {avatar && (
        <span className={cn('line-height-1', avatarRight ? 'icon-addon-left' : 'icon-addon-right')}>
          <Gravatar user={user} size={size} />
        </span>
      )}
      <Truncated text={user.get('name') || user.get('email')} tooltip={user.get('email')} />
    </div>
  );
};
