import './sentry';
import './utils/promise';
import './utils/font-awesome/setup';
import './utils/ReactBootstrapOverrides';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { KeboolaClientProvider } from '@keboola/api-client';

import '../styles/app.less';

import { handleError } from '@/utils/promise';
import { Constants as OAuthConstants } from './modules/oauth-v2/Constants';
import { RedirectionPlaceholder } from './modules/oauth-v2/react/RedirectionPlaceholder';
import string from './utils/string';
import * as helpers from './helpers';
import { keboolaClient } from './keboolaClient';
import Root from './Root';

const startApp = (appOptions) => {
  if (window.matchMedia('screen and (max-width: 991px)').matches) {
    document.querySelector('meta[name=viewport]').setAttribute('content', 'width=992');
  }

  // When on OAuth redirection callback route, load placeholder instead of whole app
  if (
    string.rtrim(window.location.pathname, '/') ===
    `${appOptions?.data?.kbc?.projectBaseUrl}/${OAuthConstants.ROUTE_NAME}`
  ) {
    createRoot(appOptions.rootNode).render(<RedirectionPlaceholder />);
    return;
  }

  createRoot(appOptions.rootNode).render(
    <KeboolaClientProvider
      client={keboolaClient}
      baseUrl={appOptions.data.sapi.url}
      token={{
        storageApi: appOptions.data.sapi.token.token,
        managementApi: appOptions.data.manageApiToken?.token,
      }}
      onError={handleError}
    >
      <Root
        data={appOptions.data}
        isPreview={appOptions.isPreview}
        isDemoPreview={appOptions.isDemoPreview}
      />
    </KeboolaClientProvider>,
  );
};

/** @public */
export default {
  start: startApp,
  helpers,
};
