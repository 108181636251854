import type { GenericFetchClient } from '../managementClient';

import type { Feature, GetAllFeaturesQuery } from './types';

export class Features {
  constructor(private readonly client: GenericFetchClient) {}

  async getAllFeatures(query: GetAllFeaturesQuery, signal?: AbortSignal) {
    const { data } = await this.client.get<Feature[], void, GetAllFeaturesQuery>(
      '/features',
      { query },
      { signal },
    );
    return data;
  }
}
