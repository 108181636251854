import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import ConfigurationCopiedNotification from '@/modules/components/react/components/ConfigurationCopiedNotification';

const configurationRestored = (componentId, configurationId, configurationName, hasFlows) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired,
    },

    render() {
      return (
        <ConfigurationCopiedNotification
          action="restored"
          name={configurationName}
          componentId={componentId}
          configId={configurationId}
          onClick={this.props.onClick}
          hasFlows={hasFlows}
        />
      );
    },
  });
};

export default configurationRestored;
