import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, Radio } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { Alert, HelpBlock, Link } from '@keboola/design';

import AutomaticLoadTypeLastUpdated from '@/react/common/AutomaticLoadTypeLastUpdated';
import changedSinceConstants from '@/react/common/changedSinceConstants';
import ChangedSinceInput from '@/react/common/ChangedSinceInput';
import Select from '@/react/common/Select';

const LoadTypeSection = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      changedSince: PropTypes.string.isRequired,
      grainColumns: PropTypes.array.isRequired,
      hasConnectionPoint: PropTypes.bool.isRequired,
      tableId: PropTypes.string.isRequired,
      grain: PropTypes.array,
    }),
    table: PropTypes.instanceOf(Map),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    const { value, onChange, disabled } = this.props;
    const isIncremental = !!value.changedSince;
    const isGrainInvalid = value.grain && value.grain.length === 1;
    const isExternal = this.props.table.getIn(['bucket', 'hasExternalSchema'], false);
    return (
      <form className="form-horizontal">
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Load type</ControlLabel>
          </div>
          <div className="col-xs-8">
            <Radio
              type="radio"
              title="Full load"
              disabled={disabled}
              onChange={() => onChange({ changedSince: '' })}
              checked={!isIncremental}
            >
              Full load
            </Radio>
            <HelpBlock className="tw-mt-1">
              All data in the GoodData dataset will be replaced by the current Storage table data.
            </HelpBlock>
            {!isExternal && (
              <>
                <Radio
                  type="radio"
                  title="Automatic incremental load"
                  disabled={disabled}
                  onChange={() => onChange({ changedSince: changedSinceConstants.ADAPTIVE_VALUE })}
                  checked={
                    isIncremental &&
                    this.props.value.changedSince === changedSinceConstants.ADAPTIVE_VALUE
                  }
                >
                  Automatic incremental load
                </Radio>
                <HelpBlock className="tw-mt-1">
                  Only data changed since the last successful run will be appended to the dataset.
                </HelpBlock>
                {isIncremental &&
                  this.props.value.changedSince === changedSinceConstants.ADAPTIVE_VALUE && (
                    <AutomaticLoadTypeLastUpdated tableId={this.props.value.tableId} />
                  )}
              </>
            )}
            <Radio
              type="radio"
              title="Manual incremental load"
              disabled={disabled}
              onChange={() => onChange({ changedSince: '-1 days' })}
              checked={
                isIncremental &&
                this.props.value.changedSince !== changedSinceConstants.ADAPTIVE_VALUE
              }
            >
              Manual incremental load
            </Radio>
            <HelpBlock className="tw-mt-1">
              Selected data will be appended to the dataset.
            </HelpBlock>
          </div>
        </FormGroup>
        {isIncremental &&
          this.props.value.changedSince !== changedSinceConstants.ADAPTIVE_VALUE && (
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Changed in last</ControlLabel>
              </div>
              <div className="col-xs-8">
                <ChangedSinceInput
                  value={value.changedSince}
                  onChange={(newValue) => this.props.onChange({ changedSince: newValue })}
                  disabled={disabled}
                  tableId={value.tableId}
                />
              </div>
            </FormGroup>
          )}
        {isIncremental && (
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Fact grain</ControlLabel>
            </div>
            <div className="col-xs-8">
              <Select
                placeholder="Select at least 2 columns"
                multi
                disabled={disabled || value.hasConnectionPoint}
                options={value.grainColumns.map((column) => ({ value: column, label: column }))}
                value={value.grain}
                onChange={(newColumns) => onChange({ grain: newColumns })}
              />
              <HelpBlock className="tw-mt-1">
                {isGrainInvalid && (
                  <Alert variant="error" className="tw-mb-5">
                    Please specify a second attribute, reference, or data type columns.
                  </Alert>
                )}
                <Link href="https://developer.gooddata.com/article/set-fact-table-grain">
                  Fact grain
                </Link>{' '}
                columns help to avoid duplicate records in the GoodData dataset without a connection
                point. <strong> Specify at least 2 </strong> attributes, reference, or data type
                columns.
              </HelpBlock>
            </div>
          </FormGroup>
        )}
      </form>
    );
  },
});

export default LoadTypeSection;
