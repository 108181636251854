import { List } from 'immutable';

import cleanDataIcon from '../../../images/no-code-operations/cleanData.svg';
import countAndGroupIcon from '../../../images/no-code-operations/countAndGroup.svg';
import filterIcon from '../../../images/no-code-operations/filter.svg';
import formatDateTimeIcon from '../../../images/no-code-operations/formatDateTime.svg';
import joinTablesIcon from '../../../images/no-code-operations/joinTables.svg';
import minMaxByColumnIcon from '../../../images/no-code-operations/minMaxByColumn.svg';
import minMaxPerRowIcon from '../../../images/no-code-operations/minMaxPerRow.svg';
import newColumnIcon from '../../../images/no-code-operations/newColumn.svg';
import removeColumnsIcon from '../../../images/no-code-operations/removeColumns.svg';
import removeDuplicateRowsIcon from '../../../images/no-code-operations/removeDuplicateRows.svg';
import renameColumnIcon from '../../../images/no-code-operations/renameColumn.svg';
import replaceIcon from '../../../images/no-code-operations/replace.svg';
import sumAndGroupIcon from '../../../images/no-code-operations/sumAndGroup.svg';

import string from '@/utils/string';
import type { DataSample } from './components/SimplifiedDataSample';

const CONDITION_OPERATORS = {
  IS_EQUAL: '=',
  IS_NOT_EQUAL: '!=',
  IS_BLANK: 'IS NULL',
  IS_NOT_BLANK: 'IS NOT NULL',
  CONTAINS: 'CONTAINS',
};
const JOIN_TYPES = {
  'all-all': 'FULL JOIN',
  'matching-all': 'RIGHT JOIN',
  'all-matching': 'LEFT JOIN',
  'matching-matching': 'JOIN',
};
const CLEANING_REGEX_TYPES = {
  space: '[[:space:]]',
  number: '[[:digit:]]',
  'non-number': '[^[:digit:]]',
  punctuation: '[^a-zA-Z0-9]',
};
const CLEANING_OPTIONS = {
  space: 'Remove All Spaces',
  number: 'Remove All Numbers',
  'non-number': 'Remove All Non-Numbers',
  punctuation: 'Remove All Punctuations',
  customCharacter: 'Remove Unwanted Character',
};
const DATETIME_FORMAT_OPTIONS = {
  'YYYY/MM/DD': 'YYYY/MM/DD',
  'MM/DD/YYYY': 'MM/DD/YYYY',
  'DD/MM/YYYY': 'DD/MM/YYYY',
  'YYYY/MM/DD HH:mm:ss': 'YYYY/MM/DD HH:mm:ss',
};
export const OPERATIONS_CATEGORIES = {
  CLEAN: 'Clean & Format',
  FILTER: 'Filter Data',
  CREATE: 'Create & Update Data',
  MATH: 'Do Math',
  COMBINE: 'Combine',
};
export const OPERATIONS = {
  replace: {
    templateId: 'replace',
    name: 'Replace Data',
    icon: replaceIcon,
    category: OPERATIONS_CATEGORIES.CLEAN,
    generateCode: (
      {
        columnName,
        searchValue,
        newValue,
      }: { columnName: string; searchValue: string; newValue: string },
      previousOperationColumns: string[],
    ) => {
      return (
        `SELECT ` +
        previousOperationColumns
          .map((column) => {
            if (columnName === column) {
              return `REPLACE("${columnName}", '${searchValue}', '${newValue}') as "${columnName}"`;
            }

            return `"${column}"`;
          })
          .join(', ') +
        ` FROM {{ ref('%model%') }}`
      );
    },
    inputs: {
      columnName: {
        id: 'columnName',
        name: 'Within',
        type: 'select',
        optionsType: 'column',
      },
      searchValue: {
        id: 'searchValue',
        name: 'Value to Replace',
        type: 'string',
      },
      newValue: {
        id: 'newValue',
        name: 'Replace With',
        type: 'string',
      },
    },
  },
  renameColumn: {
    templateId: 'renameColumn',
    name: 'Rename Column',
    icon: renameColumnIcon,
    category: OPERATIONS_CATEGORIES.CLEAN,
    generateCode: (
      { sourceColumnName, newColumnName }: { sourceColumnName: string; newColumnName: string },
      previousOperationColumns: string[],
    ) => {
      return (
        `SELECT ` +
        previousOperationColumns
          .map((column) => {
            if (sourceColumnName.includes(column)) {
              return `"${column}" AS "${newColumnName}"`;
            }

            return `"${column}"`;
          })
          .join(', ') +
        ` FROM {{ ref('%model%') }}`
      );
    },
    inputs: {
      sourceColumnName: {
        id: 'sourceColumnName',
        name: 'Select Column',
        type: 'select',
        optionsType: 'column',
      },
      newColumnName: {
        id: 'newColumnName',
        name: 'New Column Name',
        type: 'string',
        predefinedValidation: 'columnName',
      },
    },
  },
  removeDuplicateRows: {
    templateId: 'removeDuplicateRows',
    name: 'Remove Duplicate Rows',
    icon: removeDuplicateRowsIcon,
    category: OPERATIONS_CATEGORIES.CLEAN,
    generateCode: ({ targetColumnNames }: { targetColumnNames: string[] }) => {
      const targetColumnNamesQueryPart = targetColumnNames
        .map((column) => `"${column}"`)
        .join(', ');

      return `SELECT * FROM {{ ref('%model%') }} QUALIFY ROW_NUMBER() OVER (PARTITION BY ${targetColumnNamesQueryPart} ORDER BY ${targetColumnNamesQueryPart}) = 1`;
    },
    inputs: {
      targetColumnNames: {
        id: 'targetColumnNames',
        name: 'Remove Duplicates in',
        type: 'multiselect',
        optionsType: 'column',
      },
    },
  },
  removeColumns: {
    templateId: 'removeColumns',
    name: 'Remove Column(s)',
    icon: removeColumnsIcon,
    category: OPERATIONS_CATEGORIES.CLEAN,
    generateCode: (
      { removedColumnNames }: { removedColumnNames: string[] },
      previousOperationColumns: string[],
    ) => {
      return (
        `SELECT ` +
        previousOperationColumns
          .filter((column) => !removedColumnNames.includes(column))
          .map((column) => `"${column}"`)
          .join(', ') +
        ` FROM {{ ref('%model%') }}`
      );
    },
    inputs: {
      removedColumnNames: {
        id: 'removedColumnNames',
        name: 'Select Column',
        type: 'multiselect',
        optionsType: 'column',
        customValidation: (value: string | List<string>, previousOperation: DataSample) => {
          if (
            !value ||
            List(value).isEmpty() ||
            !previousOperation.dataSample.columns ||
            previousOperation.dataSample.columns.length > List(value).count()
          ) {
            return null;
          }

          return 'You have to keep at least one column in the table.';
        },
      },
    },
  },
  cleanData: {
    templateId: 'cleanData',
    name: 'Clean Data',
    icon: cleanDataIcon,
    category: OPERATIONS_CATEGORIES.CLEAN,
    generateCode: (
      {
        targetColumnNames,
        cleaningType,
        matchValue,
      }: {
        targetColumnNames: string[];
        cleaningType: keyof typeof CLEANING_REGEX_TYPES;
        matchValue: string;
      },
      previousOperationColumns: string[],
    ) => {
      return (
        `SELECT ` +
        previousOperationColumns
          .map((column) => {
            if (targetColumnNames.includes(column)) {
              return `regexp_replace("${column}", '${
                CLEANING_REGEX_TYPES[cleaningType] || matchValue
              }') AS "${column}"`;
            }

            return `"${column}"`;
          })
          .join(', ') +
        ` FROM {{ ref('%model%') }}`
      );
    },
    inputs: {
      targetColumnNames: {
        id: 'targetColumnNames',
        name: 'Clean Data in',
        type: 'multiselect',
        optionsType: 'column',
      },
      cleaningType: {
        id: 'cleaningType',
        name: 'Options',
        type: 'select',
        options: Object.entries(CLEANING_OPTIONS).map(([value, label]) => ({ label, value })),
      },
      matchValue: {
        id: 'matchValue',
        name: 'Unwanted Character Value',
        type: 'string',
        shouldHide: ({ cleaningType }: { cleaningType: string }) =>
          cleaningType !== 'customCharacter',
      },
    },
  },
  formatDateTime: {
    templateId: 'formatDateTime',
    name: 'Format Date & Time',
    icon: formatDateTimeIcon,
    category: OPERATIONS_CATEGORIES.CLEAN,
    generateCode: (
      {
        targetColumnNames,
        currentFormat,
        newFormat,
      }: { targetColumnNames: string[]; currentFormat: string; newFormat: string },
      previousOperationColumns: string[],
    ) => {
      return (
        `SELECT ` +
        previousOperationColumns
          .map((column) => {
            if (targetColumnNames.includes(column)) {
              return `TO_VARCHAR(TO_DATE("${column}", '${currentFormat}'), '${newFormat}') AS "${column}"`;
            }

            return `"${column}"`;
          })
          .join(', ') +
        ` FROM {{ ref('%model%') }}`
      );
    },
    inputs: {
      targetColumnNames: {
        id: 'targetColumnNames',
        name: 'Select Column',
        type: 'multiselect',
        optionsType: 'column',
      },
      currentFormat: {
        id: 'currentFormat',
        name: 'Current Format',
        type: 'select',
        options: Object.entries({ AUTO: 'Auto Detect', ...DATETIME_FORMAT_OPTIONS }).map(
          ([value, label]) => ({ value, label }),
        ),
        allowCustomValues: true,
        helpText: 'Select format or define your custom',
      },
      newFormat: {
        id: 'newFormat',
        name: 'New Format',
        type: 'select',
        options: Object.entries(DATETIME_FORMAT_OPTIONS).map(([value, label]) => ({
          value,
          label,
        })),
        allowCustomValues: true,
        helpText: 'Select format or define your custom',
      },
    },
  },
  newColumn: {
    templateId: 'newColumn',
    name: 'If/Else New Column',
    icon: newColumnIcon,
    category: OPERATIONS_CATEGORIES.CREATE,
    generateCode: ({
      newColumnName,
      columnName,
      operator,
      matchValue,
      newValue,
      fallbackValue,
    }: {
      newColumnName: string;
      columnName: string;
      operator: string;
      matchValue: string;
      newValue: string;
      fallbackValue: string;
    }) => {
      let conditionQueryPart =
        operator === CONDITION_OPERATORS.CONTAINS
          ? `CONTAINS("${columnName}",'${matchValue}')`
          : `"${columnName}" ${operator}`;

      if (
        ![
          CONDITION_OPERATORS.IS_BLANK,
          CONDITION_OPERATORS.IS_NOT_BLANK,
          CONDITION_OPERATORS.CONTAINS,
        ].includes(operator)
      ) {
        conditionQueryPart += ` '${matchValue}'`;
      }

      return `SELECT *, CASE WHEN ${conditionQueryPart} THEN '${newValue}' ELSE '${fallbackValue}' END AS "${newColumnName}" FROM {{ ref('%model%') }}`;
    },
    inputs: {
      newColumnName: {
        id: 'newColumnName',
        name: 'Create New Column',
        type: 'string',
        predefinedValidation: 'columnName',
      },
      columnName: {
        id: 'columnName',
        name: 'IF',
        type: 'select',
        optionsType: 'column',
      },
      operator: {
        id: 'operator',
        name: 'Condition',
        type: 'select',
        options: Object.keys(CONDITION_OPERATORS).map((operatorKey) => ({
          label: operatorKey.replaceAll('_', ' '),
          value: CONDITION_OPERATORS[operatorKey as keyof typeof CONDITION_OPERATORS],
        })),
      },
      matchValue: {
        id: 'matchValue',
        name: 'Value',
        type: 'string',
        shouldHide: (variables: { operator: keyof typeof CONDITION_OPERATORS }) => {
          if (!variables) return true;

          return [CONDITION_OPERATORS.IS_BLANK, CONDITION_OPERATORS.IS_NOT_BLANK].includes(
            variables.operator,
          );
        },
      },
      newValue: {
        id: 'newValue',
        name: 'New Value',
        type: 'string',
      },
      fallbackValue: {
        id: 'fallbackValue',
        name: 'ELSE if the rule fails set the value to:',
        type: 'string',
        defaultValue: '',
        placeholder: 'Enter value or keep empty',
      },
    },
  },
  filter: {
    templateId: 'filter',
    name: 'Filter Rows',
    icon: filterIcon,
    category: OPERATIONS_CATEGORIES.FILTER,
    generateCode: ({
      columnName,
      operator,
      matchValue,
    }: {
      columnName: string;
      operator: string;
      matchValue: string;
    }) => {
      let conditionQueryPart =
        operator === CONDITION_OPERATORS.CONTAINS
          ? `CONTAINS("${columnName}",'${matchValue}')`
          : `"${columnName}" ${operator}`;

      if ([CONDITION_OPERATORS.IS_EQUAL, CONDITION_OPERATORS.IS_NOT_EQUAL].includes(operator)) {
        conditionQueryPart += ` '${matchValue}'`;
      }

      return `SELECT * FROM {{ ref('%model%') }} WHERE ${conditionQueryPart}`;
    },
    inputs: {
      columnName: {
        id: 'columnName',
        name: 'Column',
        type: 'select',
        optionsType: 'column',
      },
      operator: {
        id: 'operator',
        name: 'Filter',
        type: 'select',
        options: Object.keys(CONDITION_OPERATORS).map((operatorKey) => ({
          label: operatorKey.replaceAll('_', ' '),
          value: CONDITION_OPERATORS[operatorKey as keyof typeof CONDITION_OPERATORS],
        })),
      },
      matchValue: {
        id: 'matchValue',
        name: 'Value',
        type: 'string',
        shouldHide: (variables: { operator: keyof typeof CONDITION_OPERATORS }) => {
          if (!variables) return true;

          return [CONDITION_OPERATORS.IS_BLANK, CONDITION_OPERATORS.IS_NOT_BLANK].includes(
            variables.operator,
          );
        },
      },
    },
  },
  countAndGroup: {
    templateId: 'countAndGroup',
    name: 'Count Rows',
    icon: countAndGroupIcon,
    category: OPERATIONS_CATEGORIES.MATH,
    generateCode: ({
      sourceColumnName,
      countColumnName,
    }: {
      sourceColumnName: string;
      countColumnName: string;
    }) => {
      return `SELECT "${sourceColumnName}", COUNT("${sourceColumnName}") AS "${countColumnName}" FROM {{ ref('%model%') }} GROUP BY "${sourceColumnName}"`;
    },
    inputs: {
      sourceColumnName: {
        id: 'sourceColumnName',
        name: 'Count Unique Values in This Column',
        type: 'select',
        optionsType: 'column',
      },
      countColumnName: {
        id: 'countColumnName',
        name: 'New Column Name',
        type: 'string',
        predefinedValidation: 'columnName',
      },
    },
  },
  sumAndGroup: {
    templateId: 'sumAndGroup',
    name: 'Sum',
    icon: sumAndGroupIcon,
    category: OPERATIONS_CATEGORIES.MATH,
    generateCode: ({
      sumColumnNames,
      groupColumnName,
    }: {
      sumColumnNames: string[];
      groupColumnName: string;
    }) => {
      return (
        `SELECT "${groupColumnName}", ` +
        sumColumnNames.map((column) => `SUM("${column}") AS "${column}"`).join(', ') +
        ` FROM {{ ref('%model%') }} GROUP BY "${groupColumnName}"`
      );
    },
    inputs: {
      sumColumnNames: {
        id: 'sumColumnNames',
        name: 'Select Column',
        type: 'multiselect',
        optionsType: 'column',
      },
      groupColumnName: {
        id: 'groupColumnName',
        name: 'Group By',
        type: 'select',
        optionsType: 'column',
      },
    },
  },
  minMaxPerRow: {
    templateId: 'minMaxPerRow',
    name: 'Min/Max per Row',
    icon: minMaxPerRowIcon,
    category: OPERATIONS_CATEGORIES.MATH,
    generateCode: ({
      functionName,
      sourceColumnName,
      newColumnName,
    }: {
      functionName: string;
      sourceColumnName: string[];
      newColumnName: string;
    }) => {
      return (
        `SELECT *, ${functionName}(` +
        sourceColumnName.map((column) => `"${column}"::number`).join(', ') +
        `) AS "${newColumnName}" FROM {{ ref('%model%') }}`
      );
    },
    inputs: {
      functionName: {
        id: 'functionName',
        name: 'Operation',
        type: 'select',
        options: [
          { label: 'Min', value: 'LEAST' },
          { label: 'Max', value: 'GREATEST' },
        ],
      },
      sourceColumnName: {
        id: 'sourceColumnName',
        name: 'In Column(s)',
        type: 'multiselect',
        optionsType: 'column',
      },
      newColumnName: {
        id: 'newColumnName',
        name: 'New Column Name',
        type: 'string',
        predefinedValidation: 'columnName',
      },
    },
  },
  minMaxByColumn: {
    templateId: 'minMaxByColumn',
    name: 'Min/Max by Column',
    icon: minMaxByColumnIcon,
    category: OPERATIONS_CATEGORIES.MATH,
    generateCode: ({
      functionName,
      sourceColumnName,
    }: {
      functionName: string;
      sourceColumnName: string[];
    }) => {
      return (
        `SELECT ` +
        sourceColumnName
          .map(
            (column) => `${functionName}("${column}") AS "${column}_${functionName.toLowerCase()}"`,
          )
          .join(', ') +
        ` FROM {{ ref('%model%') }}`
      );
    },
    inputs: {
      functionName: {
        id: 'functionName',
        name: 'Operation',
        type: 'select',
        options: [
          { label: 'Min', value: 'MIN' },
          { label: 'Max', value: 'MAX' },
        ],
      },
      sourceColumnName: {
        id: 'sourceColumnName',
        name: 'In Column(s)',
        type: 'multiselect',
        optionsType: 'column',
      },
    },
  },
  joinTables: {
    templateId: 'joinTables',
    name: 'Combine Tables',
    icon: joinTablesIcon,
    category: OPERATIONS_CATEGORIES.COMBINE,
    generateCode: (
      {
        conditionA = 'matching',
        commonColumnA,
        combineTable = '',
        conditionB = 'matching',
        commonColumnB,
      }: {
        conditionA?: 'matching' | 'all';
        commonColumnA: string;
        combineTable?: string;
        conditionB?: 'matching' | 'all';
        commonColumnB: string;
      },
      previousOperationColumns?: string[],
      sourceTablesDataSamples?: Record<string, DataSample>,
    ) => {
      const joinType = JOIN_TYPES[`${conditionA}-${conditionB}`];
      const combineTablePath = `'${string.strLeftBack(combineTable, '.')}', '${string.strRightBack(
        combineTable,
        '.',
      )}'`;
      let newColumns = sourceTablesDataSamples?.[combineTable]?.dataSample?.columns;

      if (joinType === JOIN_TYPES['matching-matching']) {
        newColumns = newColumns?.filter((column) => column !== commonColumnB);
      }

      const newColumnsQueryPart = newColumns
        ?.map((column) => `"tableB"."${column}" AS "${column}_2"`)
        .join(', ');

      return (
        `SELECT "tableA".*, ` +
        `${newColumnsQueryPart} ` +
        `FROM {{ ref('%model%') }} AS "tableA" ${joinType} ` +
        `{{ source(${combineTablePath}) }} AS "tableB"` +
        `ON "tableA"."${commonColumnA}"="tableB"."${commonColumnB}"`
      );
    },
    inputs: {
      'First Table': {
        sourceTable: {
          id: 'sourceTable',
          name: 'Select Table',
          type: 'sourceTable',
        },
        conditionA: {
          id: 'conditionA',
          name: 'Condition',
          type: 'select',
          options: [
            { label: 'Keep all rows', value: 'all' },
            { label: 'Only matching rows', value: 'matching' },
          ],
        },
        commonColumnA: {
          id: 'commonColumnA',
          name: 'Where Columns Match',
          type: 'select',
          optionsType: 'column',
        },
      },
      'Second Table': {
        combineTable: {
          id: 'combineTable',
          name: 'Select Table',
          type: 'select',
          optionsType: 'table',
        },
        conditionB: {
          id: 'conditionB',
          name: 'Condition',
          type: 'select',
          options: [
            { label: 'Keep all rows', value: 'all' },
            { label: 'Only matching rows', value: 'matching' },
          ],
        },
        commonColumnB: {
          id: 'commonColumnB',
          name: 'Where Columns Match',
          type: 'select',
          optionsType: 'column',
          optionsSourceTableInput: 'combineTable',
        },
      },
    },
  },
};
