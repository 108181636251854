import React from 'react';
import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import { cn } from '@keboola/design';

import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import TransformationsActionCreators from '@/modules/transformations/ActionCreators';
import { routeNames } from '@/modules/transformations/Constants';
import TransformationStore from '@/modules/transformations/stores/TransformationsStore';
import * as sandboxUtils from '@/modules/transformations/utils/sandboxUtils';
import { isKnownTransformationType } from '@/modules/transformations/utils/transformationTypes';
import {
  RouterLink as Link,
  RowActionDropdown,
  RowActionMenuItem,
  Truncated,
} from '@/react/common';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import InlineDescriptionEditInput from '@/react/common/InlineDescriptionEditInput';
import CopyTransformationButton from './CopyTransformationButton';
import CreateSandboxButton from './CreateSandboxButton';
import TransformationType from './TransformationType';

const TransformationRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    transformation: PropTypes.object,
    latestVersionId: PropTypes.number,
    bucket: PropTypes.object,
    transformationBuckets: PropTypes.object,
    pendingActions: PropTypes.object,
    readOnly: PropTypes.bool.isRequired,
    isDevModeActive: PropTypes.bool.isRequired,
  },

  render() {
    // TODO - no detail for unsupported transformations! (remote, db/snapshot, ...)
    if (isKnownTransformationType(this.props.transformation)) {
      return (
        <Link
          className={cn('tr hoverable-actions', {
            'row-disabled': this.props.transformation.get('disabled'),
          })}
          to={routeNames.DETAIL}
          params={{ row: this.props.transformation.get('id'), config: this.props.bucket.get('id') }}
        >
          {this.renderRow()}
        </Link>
      );
    }

    return <div className="tr hoverable-actions">{this.renderRow()}</div>;
  },

  renderRow() {
    return (
      <>
        <span className="td">
          <div className="flex-container flex-start">
            {this.renderIcons()}
            <div>
              <Truncated
                className="component-name"
                text={TransformationStore.getTransformationName(
                  this.props.bucket.get('id'),
                  this.props.transformation.get('id'),
                )}
              />
              <span className="f-13 text-muted">
                <InlineDescriptionEditInput
                  entity="Transformation"
                  description={
                    TransformationStore.getTransformationDescription(
                      this.props.bucket.get('id'),
                      this.props.transformation.get('id'),
                    ) || ''
                  }
                  onSave={this.handleDescriptionChange}
                  readOnly={this.props.readOnly}
                />
              </span>
            </div>
          </div>
        </span>
        <span className="td pr-1 w-400">
          {!this.props.readOnly && (
            <RowActionDropdown>
              {isKnownTransformationType(this.props.transformation) && (
                <>
                  <RunComponentButton
                    mode="menuitem"
                    componentId="transformation"
                    title="Run Transformation"
                    disabled={this.props.transformation.get('disabled')}
                    runParams={() => ({
                      configBucketId: this.props.bucket.get('id'),
                      transformations: [this.props.transformation.get('id')],
                    })}
                  >
                    You are about to run the transformation{' '}
                    {this.props.transformation.get('name', this.props.transformation.get('id'))}.
                  </RunComponentButton>
                  <RowActionMenuItem divider />
                </>
              )}
              <ActivateDeactivateSwitch
                mode="menuitem"
                isActive={!this.props.transformation.get('disabled')}
                isPending={this.props.pendingActions.has('save-disabled')}
                onChange={this._handleActiveChange}
              />
              {!this.props.readOnly &&
                !this.props.isDevModeActive &&
                sandboxUtils.hasSandbox(
                  this.props.transformation.get('backend'),
                  this.props.transformation.get('type'),
                ) && (
                  <CreateSandboxButton
                    mode="menuitem"
                    transformationType={this.props.transformation.get('type')}
                    backend={this.props.transformation.get('backend')}
                    runParams={sandboxUtils.generateRunParameters(
                      this.props.transformation,
                      this.props.bucket.get('id'),
                      this.props.latestVersionId,
                    )}
                  />
                )}
              <CopyTransformationButton
                mode="menuitem"
                transformation={this.props.transformation}
                transformationBuckets={this.props.transformationBuckets}
                bucketId={this.props.bucket.get('id')}
              />
              <RowActionMenuItem divider />
              <RowActionMenuItem
                onSelect={this._deleteTransformation}
                disabled={this.props.pendingActions.get('delete')}
              >
                <FontAwesomeIcon icon="trash" fixedWidth />
                Delete Transformation
              </RowActionMenuItem>
            </RowActionDropdown>
          )}
        </span>
      </>
    );
  },

  renderIcons() {
    return (
      <div className="transformation-backend-icon">
        <TransformationType
          transformation={this.props.transformation}
          imageSize="40"
          showLabel={false}
        />
        <div className="phase-icon">{this.props.transformation.get('phase') || 1}</div>
      </div>
    );
  },

  _deleteTransformation() {
    // if transformation is deleted immediately view is rendered with missing bucket because of store changed
    const transformationId = this.props.transformation.get('id');
    const bucketId = this.props.bucket.get('id');
    return setTimeout(() =>
      TransformationsActionCreators.deleteTransformation(bucketId, transformationId),
    );
  },

  _handleActiveChange(newValue) {
    const changeDescription = `Transformation ${this.props.transformation.get(
      'name',
      this.props.transformation.get('id'),
    )} ${newValue ? 'enabled' : 'disabled'}`;

    return TransformationsActionCreators.changeTransformationProperty(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      'disabled',
      !newValue,
      changeDescription,
    );
  },

  handleDescriptionChange(newDescription) {
    return ConfigurationRowsActionCreators.updateSimple(
      'transformation',
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      { description: newDescription },
      'Update description',
    );
  },
});

export default TransformationRow;
