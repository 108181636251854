import React from 'react';
import type { Map } from 'immutable';

import ScheduledJob from '@/modules/queue/components/ScheduledJob';
import TriggeredJob from '@/modules/queue/components/TriggeredJob';
import { isScheduledJob, isTriggeredJob } from '@/modules/queue/helpers';
import { CreatedDate } from '@/react/common';
import JobStatusIcon from '@/react/common/JobStatusIcon';
import TimeAndUser from '@/react/common/TimeAndUser';

const LastUseCell = (props: { admins: Map<string, any>; job?: Map<string, any> }) => {
  if (!props.job || props.job.isEmpty()) {
    return <span className="text-muted">No run yet</span>;
  }

  if (isScheduledJob(props.job)) {
    return (
      <ScheduledJob
        noIcon
        time={props.job.get('createdTime')}
        timeSuffix={
          <JobStatusIcon status={props.job.get('status')} className="f-16 icon-addon-left" />
        }
      />
    );
  }

  if (isTriggeredJob(props.job)) {
    return (
      <div className="tw-inline-flex tw-items-center">
        <div>
          <CreatedDate createdTime={props.job.get('createdTime')} />
          <br />
          <TriggeredJob />
        </div>
      </div>
    );
  }

  return (
    <TimeAndUser
      showAvatar={false}
      admin={props.admins.get(props.job.getIn(['token', 'description']))}
      time={props.job.get('createdTime')}
      timeSuffix={
        <JobStatusIcon status={props.job.get('status')} className="f-16 icon-addon-left" />
      }
      fallbackName={props.job.getIn(['token', 'description'])}
    />
  );
};

export default LastUseCell;
