/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_EX_SAMPLE_DATA, TRANSFORMATION } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import EmptyState from '@/modules/components/react/components/ComponentEmptyState';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import AboutPhases from '@/modules/orchestrations/react/components/AboutPhases';
import AddTaskModal from '@/modules/orchestrations/react/modals/add-task/AddTaskModal';
import MergePhasesModal from '@/modules/orchestrations/react/modals/MergePhasesModal';
import MoveTasksModal from '@/modules/orchestrations/react/modals/MoveTasksModal';
import PhaseModal from '@/modules/orchestrations/react/modals/Phase';
import Sortable from '@/react/common/Sortable';
import PhaseEditRow from './PhaseEditRow';
import TasksEditTableRow from './TasksEditTableRow';

const TasksEditTable = createReactClass({
  propTypes: {
    tasks: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    onTaskDelete: PropTypes.func.isRequired,
    onTaskUpdate: PropTypes.func.isRequired,
    updateLocalState: PropTypes.func.isRequired,
    localState: PropTypes.object.isRequired,
    handlePhaseMove: PropTypes.func.isRequired,
    handlePhaseUpdate: PropTypes.func.isRequired,
    handlePhasesSet: PropTypes.func.isRequired,
    handleAddTask: PropTypes.func.isRequired,
  },

  render() {
    return (
      <span>
        {this.renderPhaseModal()}
        {this.renderMergePhaseModal()}
        {this.renderMoveTasksModal()}
        {this.renderAddTaskModal()}
        {this.renderTable()}
      </span>
    );
  },

  renderTable() {
    if (!this.props.tasks.count()) {
      return (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th />
                <th className="w-250">Component</th>
                <th className="w-400">Configuration</th>
                <th>Action</th>
                <th>Active</th>
                <th>Continue on Failure</th>
                <th className="w-150" />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-muted" colSpan="7">
                  There are no tasks assigned yet. Please start by adding the first task.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    return (
      <div className="table-responsive">
        <Sortable
          tag="table"
          className="table"
          options={{ filter: 'thead' }}
          onChange={(order, event) => {
            const oldIndex = Math.max(1, event.oldIndex) - 1;
            const newIndex = Math.max(1, event.newIndex) - 1;
            this.props.handlePhaseMove(oldIndex, newIndex);
          }}
        >
          <thead data-id="static-head">
            <tr>
              <th>{this.renderHeaderActionButtons()}</th>
              <th className="w-250">Component</th>
              <th className="w-400">Configuration</th>
              <th>Action</th>
              <th>Active</th>
              <th>Continue on Failure</th>
              <th className="w-150" />
            </tr>
          </thead>
          {this.props.tasks.map(this.renderPhaseRow).toArray()}
        </Sortable>
      </div>
    );
  },

  renderHeaderActionButtons() {
    return (
      <ul className="nav nav-stacked">
        <DropdownButton bsStyle="link" title="Actions" id="orchestration-tasks-edit-table-dropdown">
          <li className={this.canMergePhases() ? '' : 'disabled'}>
            <a onClick={this.canMergePhases() ? this.toggleMergePhases : () => null}>
              Merge selected phases
            </a>
          </li>
          <li className={this.canMoveTasks() ? '' : 'disabled'}>
            <a onClick={this.canMoveTasks() ? this.onToggleMoveTasks : () => null}>
              Move selected tasks between phases
            </a>
          </li>
          <li>
            <a onClick={this.onToggleCollapsePhases}>Collapse/Expand phases</a>
          </li>
          <li>
            <a onClick={this.onGroupTasksETL}>Group tasks into phases by component type</a>
          </li>
        </DropdownButton>
      </ul>
    );
  },

  onGroupTasksETL() {
    const tasksTypes = [
      componentTypes.APPLICATION,
      componentTypes.EXTRACTOR,
      componentTypes.TRANSFORMATION,
      componentTypes.WRITER,
      'other',
    ];
    const allTypes = ComponentsStore.getComponentsTypes();
    for (let type of allTypes) {
      if (!tasksTypes.includes(type)) {
        tasksTypes.push(type);
      }
    }
    let groupedPhases = fromJS(tasksTypes).map((type) => {
      return Map({ id: `${getNewComponentTypeLabel(type)} phase`, tasks: List(), type });
    });
    this.props.tasks.map((p) =>
      p.get('tasks').map((task) => {
        const component = ComponentsStore.getComponent(task.get('component'));
        let taskType;
        if (component && component.get('id') === TRANSFORMATION) {
          taskType = componentTypes.TRANSFORMATION;
        } else {
          taskType = component && component.get('type') ? component.get('type') : 'other';
        }
        groupedPhases = groupedPhases.map((gp) => {
          if (gp.get('type') === taskType) {
            return gp.set('tasks', gp.get('tasks').push(task));
          } else {
            return gp;
          }
        });
        return task;
      }),
    );
    groupedPhases = groupedPhases.map((gp) => gp.delete('type'));
    groupedPhases = groupedPhases.filter((gp) => gp.get('tasks').count() > 0);
    return this.props.handlePhasesSet(groupedPhases);
  },

  onToggleCollapsePhases() {
    let phases = this.props.localState.get('phases', Map());
    let allHidden = phases.reduce((item, p) => item && p.get('isHidden', false), true);
    phases = this.props.tasks
      .map((phase) => {
        return Map({ phaseId: phase.get('id'), isHidden: !allHidden });
      })
      .toMap()
      .mapKeys((key, phase) => phase.get('phaseId'));

    return this.props.updateLocalState('phases', phases);
  },

  renderEmptyTasksRow(phaseId) {
    return (
      <tr key={`empty-phase-row-${phaseId}`}>
        <td colSpan={7}>
          <EmptyState>
            <p>
              {`No tasks assigned to ${phaseId} yet. Empty phases will not be saved. `}
              <AboutPhases />
            </p>
            <div>
              <Button
                bsStyle="success"
                bsSize="sm"
                onClick={() => this.props.updateLocalState(['newTask', 'phaseId'], phaseId)}
              >
                <FontAwesomeIcon icon="plus" className="icon-addon-right" />
                New task
              </Button>
            </div>
          </EmptyState>
        </td>
      </tr>
    );
  },

  renderAddTaskModal() {
    return (
      <AddTaskModal
        onConfigurationSelect={this.props.handleAddTask}
        phaseId={this.props.localState.getIn(['newTask', 'phaseId'])}
        show={!!this.props.localState.getIn(['newTask', 'phaseId'])}
        onHide={() => {
          return this.props.updateLocalState(['newTask'], Map());
        }}
        onChangeSearchQuery={(query) => {
          return this.props.updateLocalState(['newTask', 'searchQuery'], query);
        }}
        searchQuery={this.props.localState.getIn(['newTask', 'searchQuery'], '')}
      />
    );
  },

  renderMoveTasksModal() {
    return (
      <MoveTasksModal
        show={this.props.localState.getIn(['moveTasks', 'show'], false)}
        phases={this.props.tasks.map((phase) => phase.get('id'))}
        onHide={() => {
          return this.props.updateLocalState(['moveTasks', 'show'], false);
        }}
        onMoveTasks={(phaseId) => {
          const markedTasks = this.props.localState.getIn(['moveTasks', 'marked']);
          this._moveTasks(phaseId, markedTasks);
          return this.props.updateLocalState('moveTasks', Map());
        }}
      />
    );
  },

  onToggleMoveTasks() {
    return this.props.updateLocalState(['moveTasks', 'show'], true);
  },

  _moveTasks(phaseId, markedTasks) {
    let found = true;
    let phase = this.props.tasks.find((p) => p.get('id') === phaseId);
    if (!phase) {
      phase = Map({ id: phaseId });
      found = false;
    }
    const destinationTasks = phase.get('tasks', List());
    const tasksToMerge = markedTasks
      .filter((mt) => !destinationTasks.find((dt) => dt.get('id') === mt.get('id')))
      .toList();
    const resultTasks = destinationTasks.concat(tasksToMerge);
    const newPhase = phase.set('tasks', resultTasks);
    let newPhases = this.props.tasks.map((p) => {
      if (p.get('id') === phaseId) {
        return newPhase;
      }
      const tmpTasks = p.get('tasks').filter((t) => !markedTasks.has(t.get('id')));
      return p.set('tasks', tmpTasks);
    });

    if (!found) {
      newPhases = newPhases.push(newPhase);
    }
    return this.props.handlePhasesSet(newPhases);
  },

  _toggleMarkTask(task) {
    let markTask = task;
    const path = ['moveTasks', 'marked', task.get('id')];
    // invert true/false task/null
    if (this.props.localState.getIn(path, null) !== null) {
      markTask = null;
    }
    return this.props.updateLocalState(path, markTask);
  },

  renderPhaseRow(phase) {
    const phaseId = phase.get('id');
    const isHidden = this.isPhaseHidden(phase);

    return (
      <tbody key={phaseId} data-id={phaseId}>
        <PhaseEditRow
          isPhaseHidden={isHidden}
          phase={phase}
          onMarkPhase={this.toggleMarkPhase}
          isMarked={this.props.localState.getIn(['markedPhases', phaseId], false)}
          toggleHide={() => this.props.updateLocalState(['phases', phaseId, 'isHidden'], !isHidden)}
          togglePhaseIdChange={this.togglePhaseIdEdit}
          toggleAddNewTask={() =>
            this.props.updateLocalState(['newTask', 'phaseId'], phase.get('id'))
          }
        />
        {this.renderTasksRows(phase, isHidden)}
      </tbody>
    );
  },

  renderTasksRows(phase, isHidden) {
    if (isHidden) {
      return null;
    }

    const tasksRows = phase.get('tasks').map((task, index) => {
      const taskId = task.get('id');

      return (
        <TasksEditTableRow
          key={`${index}-${taskId}`}
          task={task}
          component={this.props.components.get(
            task.get('component') === KEBOOLA_EX_SAMPLE_DATA
              ? task.getIn(['config', 'configuration', 'parameters', 'componentId'])
              : task.get('component'),
          )}
          disabled={this.props.disabled}
          onTaskDelete={this.props.onTaskDelete}
          onTaskUpdate={this.props.onTaskUpdate}
          isMarked={this.props.localState.hasIn(['moveTasks', 'marked', taskId])}
          toggleMarkTask={() => this._toggleMarkTask(task)}
          onMoveSingleTask={() => this._toggleMoveSingleTask(task, phase.get('id'))}
        />
      );
    });

    if (!tasksRows.count()) {
      return this.renderEmptyTasksRow(phase.get('id'));
    }

    return tasksRows;
  },

  renderPhaseModal() {
    let phaseId = this.props.localState.get('editingPhaseId');
    const existingIds = this.props.tasks
      .map((phase) => phase.get('id'))
      .filter((pId) => pId !== phaseId);
    return (
      <PhaseModal
        phaseId={phaseId}
        show={this.isEditingPhaseId()}
        onPhaseUpdate={(newId) => {
          phaseId = this.props.localState.get('editingPhaseId');
          const phase = this.props.tasks.find((p) => p.get('id') === phaseId);
          this.props.handlePhaseUpdate(phaseId, phase.set('id', newId));
          return this.hidePhaseIdEdit();
        }}
        onHide={this.hidePhaseIdEdit}
        existingIds={existingIds}
      />
    );
  },

  renderMergePhaseModal() {
    return (
      <MergePhasesModal
        show={this.props.localState.get('mergePhases', false)}
        onHide={() => {
          return this.props.updateLocalState('mergePhases', false);
        }}
        tasks={this.props.tasks}
        phases={this.props.tasks.map((phase) => phase.get('id'))}
        onMergePhases={this._mergePhases}
      />
    );
  },

  canMergePhases() {
    return this.props.localState.get('markedPhases', Map()).find((isMarked) => isMarked === true);
  },

  toggleMergePhases() {
    return this.props.updateLocalState('mergePhases', true);
  },

  _mergePhases(destinationPhaseId) {
    const markedPhases = this.props.localState.get('markedPhases');
    let mergedTasks = List();
    // find the best suitable position for new phase
    const markedPhasesIndexes = markedPhases
      .filter((isMarked) => isMarked === true)
      .map((_, phaseId) => {
        return this.props.tasks.findIndex((phase) => phase.get('id') === phaseId);
      });
    const newPhasePosition = markedPhasesIndexes.min();

    // filter only those not selected and not choosed to merge to and
    // concat their tasks
    let newPhases = this.props.tasks.filter((phase) => {
      const pid = phase.get('id');
      const isMarked = markedPhases.get(pid);
      if (isMarked || destinationPhaseId === pid) {
        mergedTasks = mergedTasks.concat(phase.get('tasks'));
      }
      return !isMarked || destinationPhaseId === pid;
    });
    let found = false;
    // if merging into existing phase then replace its tasks
    newPhases = newPhases.map((ph) => {
      if (ph.get('id') === destinationPhaseId) {
        found = true;
        return ph.set('tasks', mergedTasks);
      } else {
        return ph;
      }
    }, found);
    // if not merging into existing phase then push new phase to the end
    if (!found) {
      const newPhase = Map({ id: destinationPhaseId }).set('tasks', mergedTasks);
      newPhases = newPhases.splice(newPhasePosition, 0, newPhase);
    }
    // save to the store
    this.props.handlePhasesSet(newPhases);
    // reset marked phases state
    this.props.updateLocalState([], Map());
    return true;
  },

  canMoveTasks() {
    return this.props.localState
      .getIn(['moveTasks', 'marked'], Map())
      .find((task) => task !== null);
  },

  toggleMarkPhase(phaseId) {
    const marked = this.props.localState.getIn(['markedPhases', phaseId], false);
    return this.props.updateLocalState(['markedPhases', phaseId], !marked);
  },

  hidePhaseIdEdit() {
    return this.props.updateLocalState(['editingPhaseId'], null);
  },

  togglePhaseIdEdit(phaseId) {
    return this.props.updateLocalState(['editingPhaseId'], phaseId);
  },

  isEditingPhaseId() {
    return !!this.props.localState.get('editingPhaseId');
  },

  isPhaseHidden(phase) {
    return this.props.localState.getIn(['phases', phase.get('id'), 'isHidden'], false);
  },
});

export default TasksEditTable;
