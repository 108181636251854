import { cn } from '../../utils';

export const cardClassnames = cn(
  'tw-bg-white',
  'tw-py-4',
  'tw-px-6',
  'tw-flex',
  'tw-flex-col',
  'tw-rounded-lg',
  'tw-gap-4',
  'tw-shadow-[0_2px_6px_0_rgba(100,135,153,0.13)]',
);

export const cardTitleClassnames = cn(
  'tw-m-0',
  'tw-leading-6',
  'tw-font-medium',
  'tw-text-base',
  'tw-neutral-800',
);
