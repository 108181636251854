import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import { Promise } from 'bluebird';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';
import _ from 'underscore';

import { Alert, HelpBlock } from '@keboola/design';

import { uploadFile } from '@/modules/storage/actions';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import FilesDropZone from '@/react/common/FilesDropZone';
import ModalIcon from '@/react/common/ModalIcon';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import UploadProgress from '@/react/common/UploadProgress';

const INITIAL_STATE = {
  files: [],
  uploadedFiles: [],
  tags: List(),
  permanent: false,
  uploading: null,
  error: null,
};

const UploadModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    uploadingProgress: PropTypes.instanceOf(Map).isRequired,
    onUpload: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
  },

  getInitialState() {
    return INITIAL_STATE;
  },

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => this.setState(INITIAL_STATE)}
      >
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Upload New Files</Modal.Title>
            <ModalIcon.Upload />
          </Modal.Header>
          <Modal.Body>{this.renderBody()}</Modal.Body>
          {this.renderFooter()}
        </form>
      </Modal>
    );
  },

  renderBody() {
    if (this.isUploading()) {
      return (
        <UploadProgress
          files={this.state.files.length}
          uploadedFiles={this.state.uploadedFiles.length}
          progress={this.props.uploadingProgress.get(this.state.uploading.path, 0)}
        />
      );
    }

    return (
      <>
        {this.state.error && (
          <Alert variant="error" className="tw-mb-5">
            <p>
              Upload of the file <b>{this.state.uploading.path}</b> failed.
            </p>
            <p>{this.state.error}</p>
            {this.state.uploadedFiles.length > 0 && (
              <>
                <hr />
                <p>
                  Some files were successfully uploaded and removed from the list of selected files.
                  You can try to upload the remaining files again.
                </p>
              </>
            )}
          </Alert>
        )}
        <FormGroup>
          <FilesDropZone
            multiple
            allowAllFiles
            files={this.state.files}
            onDrop={(files, options) =>
              this.setState({
                files: options?.force
                  ? files
                  : _.uniq([...this.state.files, ...files], (file) => file.name),
              })
            }
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>
            Tags <OptionalFormLabel />
          </ControlLabel>
          <Select
            multi
            allowCreate
            placeholder="Enter tags"
            promptTextCreator={() => 'Add tag'}
            value={this.state.tags}
            onChange={(tags) => this.setState({ tags })}
          />
        </FormGroup>
        <FormGroup>
          <Checkbox
            checked={this.state.permanent}
            onChange={(permanent) => this.setState({ permanent })}
          >
            Store permanently
          </Checkbox>
          <HelpBlock className="tw-mt-1">
            Otherwise, the file will be deleted after <strong>15 days</strong>.
          </HelpBlock>
        </FormGroup>
      </>
    );
  },

  renderFooter() {
    if (this.isUploading()) {
      return null;
    }

    return (
      <Modal.Footer>
        <ConfirmButtons
          block
          saveButtonType="submit"
          saveLabel={this.state.error ? 'Try again' : 'Start upload'}
          isDisabled={!this.state.files.length}
        />
      </Modal.Footer>
    );
  },

  isUploading() {
    return this.state.uploading && !this.state.error;
  },

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ uploadedFiles: [], uploading: null, error: null });
    return Promise.each(this.state.files, (file) => {
      this.setState({ uploading: file });
      return uploadFile(file.path, file, {
        isPermanent: this.state.permanent,
        tags: this.state.tags.toArray(),
      }).then(() => this.setState({ uploadedFiles: [...this.state.uploadedFiles, file] }));
    })
      .then(() => {
        this.props.onHide();
        this.props.onUpload();
        this.setState({ uploading: null });
      })
      .catch((error) => {
        this.setState({ error, files: _.difference(this.state.files, this.state.uploadedFiles) });
      });
  },
});

export default UploadModal;
