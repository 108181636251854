import React from 'react';
import type { MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { VariantProps } from 'class-variance-authority';

import { cn } from '../../utils';

import { clearButtonVariants } from './styles';

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  testId?: string;
} & VariantProps<typeof clearButtonVariants>;

const InputClearButton = ({ onClick, testId, state }: Props) => {
  // Prevent default, as we do not want to lose input focus.
  const handleMouseDown: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
  };

  return (
    <button
      type="button"
      onClick={onClick}
      tabIndex={-1} // clear button should not be focusable
      onMouseDown={handleMouseDown}
      className={cn(clearButtonVariants({ state }))}
      data-testid={testId}
    >
      <FontAwesomeIcon icon="xmark" />
    </button>
  );
};

export default InputClearButton;
