import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, Radio } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { HelpBlock } from '@keboola/design';

const ActionTab = createReactClass({
  propTypes: {
    onChangeAction: PropTypes.func.isRequired,
    valueAction: PropTypes.string.isRequired,
  },

  render() {
    return <div className="form-horizontal">{this.renderActionRadio()}</div>;
  },

  renderActionRadio() {
    return (
      <FormGroup>
        <div className="col-xs-2">
          <ControlLabel>On each run:</ControlLabel>
        </div>
        <div className="col-xs-10">
          <FormGroup>
            <Radio
              value="update"
              checked={this.props.valueAction === 'update'}
              onChange={(event) => this.props.onChangeAction(event.target.value)}
            >
              Update file
            </Radio>
            <HelpBlock className="tw-mt-1">Always rewrite the same file</HelpBlock>
          </FormGroup>
          <FormGroup>
            <Radio
              value="create"
              checked={this.props.valueAction === 'create'}
              onChange={(event) => this.props.onChangeAction(event.target.value)}
            >
              Create new file
            </Radio>
            <HelpBlock className="tw-mt-1">Every time create a unique file</HelpBlock>
          </FormGroup>
        </div>
      </FormGroup>
    );
  },
});

export default ActionTab;
