import React from 'react';

import { Alert } from '@keboola/design';

import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import RoutesStore from '@/stores/RoutesStore';

const LegacyIndex = () => {
  return (
    <GenericConfigBody
      componentId={RoutesStore.getCurrentRouteComponentId()}
      configId={RoutesStore.getCurrentRouteParam('config')}
      sidebarProps={{ hideCopy: true, hideSchedule: true }}
    >
      <Alert
        className="tw-mb-5 tw-mt-6"
        variant="warning"
        title="The configuration has legacy format"
      >
        It cannot be further edited. To automatically migrate to the new format, please run your
        configuration.
      </Alert>
    </GenericConfigBody>
  );
};

export default LegacyIndex;
