import React from 'react';
import { cva } from 'class-variance-authority';

import { cn } from '../../utils';

type Variant = 'link' | 'dark' | 'custom';

const buttonInlineVariants = cva(
  [
    'tw-cursor-pointer tw-border-0 tw-bg-transparent tw-p-0 tw-text-sm',
    'tw-flex tw-items-center',
    'tw-underline tw-underline-offset-2',
    'hover:tw-no-underline',
    '[&_svg]:tw-mr-2 [&_svg]:tw-text-base',
  ],
  {
    defaultVariants: {
      variant: 'link',
    },
    variants: {
      variant: {
        link: ['tw-text-secondary-500 hover:tw-text-secondary-600 [&_svg]:tw-text-secondary-500'],
        dark: ['tw-text-neutral-800 [&_svg]:tw-text-neutral-400'],
        custom: [],
      },
    },
  },
);

export const ButtonInline = ({
  children,
  onClick,
  disabled,
  className,
  variant = 'link',
  type = 'button',
  ...buttonProps
}: { variant?: Variant } & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  const styles = cn({ 'tw-opacity-40 !tw-cursor-not-allowed': disabled }, className);
  return (
    <button
      {...buttonProps}
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={cn(buttonInlineVariants({ variant }), styles)}
    >
      {children}
    </button>
  );
};
