import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import { Link } from '@keboola/design';

const UsedByProjectsList = ({ urlTemplates, links }) => {
  return (
    <ul className="mb-0 list-unstyled">
      {links
        .map((link) => {
          return (
            <li key={link.getIn(['project', 'id'])}>
              <Link
                href={_.template(urlTemplates.get('project'))({
                  projectId: link.getIn(['project', 'id']),
                })}
              >
                {link.getIn(['project', 'name'])}
              </Link>
            </li>
          );
        })
        .toArray()}
    </ul>
  );
};

UsedByProjectsList.propTypes = {
  urlTemplates: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
};

export default UsedByProjectsList;
