import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import store from '@/modules/components/stores/InstalledComponentsStore';
import { matchByWords } from '@/utils';
import generateId from '@/utils/generateId';
import { defaultPorts, protocols } from './constants';
import { isValidQuery } from './helpers';

function fetch(componentId, configId) {
  const config = store.getConfig(componentId, configId);
  const configData = store.getConfigData(componentId, configId);

  return {
    config,
    configData,
    parameters: configData.get('parameters', Map()),
    localState: store.getLocalState(componentId, configId) || Map(),
    state: store.getConfig(componentId, configId).get('state', Map()),
  };
}

export function getLocalState(componentId, configId) {
  return fetch(componentId, configId).localState;
}

export function createStore(componentId, configId) {
  const data = fetch(componentId, configId);

  return {
    hasValidCredentials(credentials) {
      const configCredentials = this.getCredentials();

      if (!credentials) {
        return false;
      }

      if (credentials.get('protocol') === protocols.CUSTOM_URI) {
        return (
          !_.isEmpty(credentials.get('uri', '')) && !_.isEmpty(credentials.get('#password', ''))
        );
      }

      const validCredentials = ['host', 'port', 'user', '#password', 'database'].every((field) => {
        const value = credentials.get(field, '').toString();
        return (
          !_.isEmpty(value) ||
          (field === 'port' && credentials.get('protocol') === protocols.MONGO_DB_SRV) ||
          (field === '#password' && !_.isEmpty(configCredentials.get('#password')))
        );
      });

      const ssh = credentials.get('ssh', Map());
      if (ssh.get('enabled')) {
        return (
          validCredentials &&
          ssh.getIn(['keys', 'public']) &&
          ssh.getIn(['keys', '#private']) &&
          ['sshHost', 'user', 'sshPort'].every((field) => !_.isEmpty(ssh.get(field, '').toString()))
        );
      }

      return validCredentials;
    },

    // -------- LOCAL STATE manipulation -----------------
    getQueriesPendingActions() {
      return data.localState.getIn(['pending'], Map());
    },

    getQueriesFilter() {
      return data.localState.get('queriesFilter', '');
    },

    isEditingCredentials() {
      return !!data.localState.get('editingCredentials');
    },

    isSavingCredentials() {
      return data.localState.get('isSavingCredentials', false);
    },

    isChangedCredentials() {
      return data.localState.get('isChangedCredentials', false);
    },

    getEditingCredentials() {
      return data.localState.get('editingCredentials');
    },

    getNewCredentials() {
      var defaultNewCredentials = data.parameters.get('db', Map());
      if (!defaultNewCredentials.get('port')) {
        defaultNewCredentials = defaultNewCredentials.set('port', defaultPorts.DATABASE_PORT);
      }
      if (!defaultNewCredentials.getIn(['ssh', 'sshPort'])) {
        defaultNewCredentials = defaultNewCredentials.setIn(
          ['ssh', 'sshPort'],
          defaultPorts.SSH_PORT,
        );
      }

      const result = data.localState.get('newCredentials', defaultNewCredentials);
      if (result) {
        return result;
      } else {
        return defaultNewCredentials;
      }
    },

    getNewQuery(queryId = null) {
      const ids = this.getQueries()
        .map((q) => q.get('id'))
        .toJS();
      const defaultNewQuery = fromJS({
        enabled: true,
        incremental: false,
        mode: 'raw',
        collection: '',
        id: queryId !== null ? queryId : generateId(ids).toString(),
      });
      return data.localState.getIn(['newQueries', 'query'], defaultNewQuery);
    },

    getNewQueries() {
      return data.localState.getIn(['newQueries']);
    },

    getNewQueriesIdsList() {
      return data.localState.getIn(['newQueriesIdsList'], List([]));
    },

    isEditingQuery(queryId) {
      return !!data.localState.getIn(['editingQueries', queryId]);
    },

    getEditingQuery(queryId) {
      return data.localState.getIn(['editingQueries', queryId]);
    },

    getEditingQueries() {
      return data.localState.getIn(['editingQueries']);
    },

    isSavingQuery(queryId) {
      return !!data.localState.getIn(['isSaving', queryId]);
    },

    isChangedQuery(queryId) {
      return !!data.localState.getIn(['isChanged', queryId]);
    },

    isNewQuery(queryID) {
      return !!data.localState.getIn(['newQueries', queryID]);
    },

    outTableExist(query) {
      if (!query) {
        return false;
      }
      return !!this.getQueries().find((q) => {
        return q.get('name') === query.get('name') && q.get('id') !== query.get('id');
      });
    },

    isEditingQueryValid(queryId) {
      const query = this.getEditingQuery(queryId);
      if (!query) {
        return false;
      }

      return !this.outTableExist(query) && isValidQuery(query);
    },
    // -------- CONFIGDATA manipulation -----------------
    config: data.config,
    configData: data.configData,
    configState: data.state,

    getQueries() {
      return data.parameters
        .get('exports', List())
        .map((table) => table.set('id', table.get('id').toString()));
    },

    getQueriesFiltered() {
      const q = this.getQueriesFilter();
      const queries = this.getQueries();
      const filteredQueries = this.filterQueries(q, queries);
      return filteredQueries;
    },

    filterQueries(filterString, queries) {
      return queries.filter((query) => {
        return matchByWords(query.get('name'), filterString);
      });
    },

    getCredentials() {
      return data.parameters.get('db', Map());
    },

    getConfigQuery(qid) {
      if (this.isEditingQuery(qid)) {
        return this.getEditingQuery(qid);
      } else if (this.isNewQuery(qid)) {
        return this.getNewQuery(qid);
      }

      return this.getQueries().find((q) => q.get('id') === qid);
    },

    getLocalState() {
      return fetch(componentId, configId).localState;
    },
  };
}
