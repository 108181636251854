import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqualImmutable } from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_SNOWFLAKE_TRANSFORMATION } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { hasDynamicBackendSizeEnabled, isContainerBased } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import BackendSizeLabel from '@/modules/components/react/components/BackendSizeLabel';
import AddSandboxModal from '@/modules/sandboxes/components/AddSandboxModal/AddSandboxModal';
import { FORM_STEPS } from '@/modules/sandboxes/Constants';
import {
  createNewWorkspaceFromTransformation,
  resolveSandboxTypeFromComponentId,
  updateExistingWorkspace,
} from '@/modules/sandboxes/helpers';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import { SNOWFLAKE_BACKEND_TRANSFORMATIONS } from '@/modules/transformations-v2/constants';
import { RowActionMenuItem } from '@/react/common';
import BackendSizeButtons from '@/react/common/BackendSizeButtons';
import ConfigurationsTable from '@/react/common/ConfigurationsTable/Table';

const CUSTOM_CLASSES = fromJS({
  backend_size: { th: 'w-125 text-right', td: 'w-125' },
});
const HIDE_COLUMN_FOR_SMALLER_SCREEN = ['backend_size'];
const INITIAL_WORKSPACE_MODAL_STATE = Map({ show: false, step: null, config: Map() });

const WorkspaceMenuitems = (props) => {
  return (
    <>
      <RowActionMenuItem onSelect={props.onCreateSelect} onKeyDown={props.onKeyDown}>
        <FontAwesomeIcon icon="box" fixedWidth />
        Create new workspace
      </RowActionMenuItem>
      <RowActionMenuItem onSelect={props.onUpdateSelect} onKeyDown={props.onKeyDown}>
        <FontAwesomeIcon icon="box" fixedWidth />
        Copy to existing workspace
      </RowActionMenuItem>
    </>
  );
};

const TransformationConfigs = (props) => {
  const [workspaceModal, setWorkspaceModal] = React.useState(INITIAL_WORKSPACE_MODAL_STATE);
  const showBackendSize = props.hasSnowflakeDynamicBackendSize || props.hasJobsDynamicBackendSize;

  const additionalColumns = React.useMemo(() => {
    return [
      showBackendSize && {
        enableSorting: false,
        accessorKey: 'backend_size',
        header: 'Backend Size',
        cell: ({ row }) => {
          const { config, component } = row.original.data;
          if (
            !component ||
            !hasDynamicBackendSizeEnabled(
              component,
              props.hasSnowflakeDynamicBackendSize,
              props.hasJobsDynamicBackendSize,
            )
          ) {
            return null;
          }

          return (
            <BackendSizeLabel
              pullRight
              className="text-muted"
              componentId={component.get('id')}
              size={config.getIn(['configuration', 'runtime', 'backend', 'type'])}
            />
          );
        },
      },
    ];
  }, [showBackendSize, props.hasSnowflakeDynamicBackendSize, props.hasJobsDynamicBackendSize]);

  const renderAdditionalActions = React.useCallback(
    (config) => {
      if (props.readOnly || !props.allowedCreateWorkspace.has(config.getIn(['component', 'id']))) {
        return null;
      }

      return (
        <WorkspaceMenuitems
          onCreateSelect={() => {
            setWorkspaceModal(Map({ show: true, step: FORM_STEPS.SANDBOX_CREATE, config }));
          }}
          onUpdateSelect={() => {
            setWorkspaceModal(Map({ show: true, step: FORM_STEPS.SANDBOX_UPDATE, config }));
          }}
        />
      );
    },
    [props.readOnly, props.allowedCreateWorkspace],
  );

  const renderAdditionalMultiActions = React.useCallback(
    (selected) => {
      let hasSelectedSnowflake = false;
      let hasSelectedNonSql = false;

      const selectedSupportingBackendSize = selected.filter((item) => {
        if (SNOWFLAKE_BACKEND_TRANSFORMATIONS.includes(item.component.get('id'))) {
          hasSelectedSnowflake = true;
        } else if (isContainerBased(item.component)) {
          hasSelectedNonSql = true;
        }

        return hasDynamicBackendSizeEnabled(
          item.component,
          props.hasSnowflakeDynamicBackendSize,
          props.hasJobsDynamicBackendSize,
        );
      });

      if (!showBackendSize || selected.length !== selectedSupportingBackendSize.length) {
        return null;
      }

      return (
        <div className="ml-1">
          <BackendSizeButtons
            componentId={
              hasSelectedSnowflake && !hasSelectedNonSql
                ? KEBOOLA_SNOWFLAKE_TRANSFORMATION
                : 'common'
            }
            onSelect={(backendSize) => {
              return Promise.each(selected, (row) => {
                return InstalledComponentsActionCreators.updateComponentConfiguration(
                  row.component.get('id'),
                  row.config.get('id'),
                  {
                    configuration: JSON.stringify(
                      row.config
                        .get('configuration')
                        .setIn(['runtime', 'backend', 'type'], backendSize)
                        .toJS(),
                    ),
                  },
                  'Change transformation backend size',
                );
              });
            }}
            showNonSqlOptionDisabled={hasSelectedNonSql && hasSelectedSnowflake}
          />
        </div>
      );
    },
    [props.hasJobsDynamicBackendSize, props.hasSnowflakeDynamicBackendSize, showBackendSize],
  );

  return (
    <>
      <ConfigurationsTable
        showData
        showUsedIn
        showComponentIcon
        supportFolders
        currentAdmin={props.currentAdmin}
        notifications={props.notifications}
        customClasses={CUSTOM_CLASSES}
        entity={componentTypes.TRANSFORMATION}
        admins={props.admins}
        componentsMetadata={props.componentsMetadata}
        tablesMetadataMap={prepareTablesMetadataMap(props.allTables)}
        configurations={props.configurations}
        allConfigurations={props.allConfigurations}
        availableConfigurations={props.availableConfigurations}
        hasFlows={props.hasFlows}
        readOnly={props.readOnly}
        isDevModeActive={props.isDevModeActive}
        hasNewQueue={props.hasNewQueue}
        latestJobs={props.latestJobs}
        forceShowAll={props.isSearching}
        renderAdditionalActions={renderAdditionalActions}
        additionalColumns={additionalColumns}
        expandedFolders={props.expandedFolders}
        renderAdditionalMultiActions={renderAdditionalMultiActions}
        columnsHiddenForSmallerScreens={HIDE_COLUMN_FOR_SMALLER_SCREEN}
      />
      <AddSandboxModal
        forceType={resolveSandboxTypeFromComponentId(
          workspaceModal.getIn(['config', 'component', 'id']),
        )}
        forceStep={workspaceModal.get('step', null)}
        hasTableInputMapping={
          !workspaceModal
            .getIn(['config', 'configuration', 'storage', 'input', 'tables'], List())
            .isEmpty()
        }
        show={workspaceModal.get('show', false)}
        allowedComponents={props.allowedCreateWorkspace}
        workspaces={props.sandboxes}
        hasPayAsYouGo={props.hasPayAsYouGo}
        onHide={() => setWorkspaceModal(INITIAL_WORKSPACE_MODAL_STATE)}
        onSubmit={(name, type, options, params, description) => {
          return createNewWorkspaceFromTransformation(
            workspaceModal.getIn(['config', 'component', 'id']),
            workspaceModal.get('config'),
            name,
            type,
            options,
            params,
            description,
          );
        }}
        onUpdate={(workspace, preserve) => {
          return updateExistingWorkspace(
            workspaceModal.get('config'),
            workspace,
            preserve,
            `Use mapping from "${workspaceModal.getIn(['config', 'name'])}" transformation`,
          );
        }}
        sourceTransformation={workspaceModal.get('config')}
      />
    </>
  );
};

TransformationConfigs.propTypes = {
  admins: PropTypes.instanceOf(Map).isRequired,
  configurations: PropTypes.instanceOf(Map).isRequired,
  allConfigurations: PropTypes.instanceOf(Map).isRequired,
  availableConfigurations: PropTypes.instanceOf(Map).isRequired,
  allTables: PropTypes.instanceOf(Map).isRequired,
  readOnly: PropTypes.bool.isRequired,
  isSearching: PropTypes.bool.isRequired,
  hasPayAsYouGo: PropTypes.bool.isRequired,
  hasNewQueue: PropTypes.bool.isRequired,
  isDevModeActive: PropTypes.bool.isRequired,
  hasSnowflakeDynamicBackendSize: PropTypes.bool.isRequired,
  hasJobsDynamicBackendSize: PropTypes.bool.isRequired,
  latestJobs: PropTypes.instanceOf(Map).isRequired,
  allowedCreateWorkspace: PropTypes.instanceOf(Map).isRequired,
  sandboxes: PropTypes.instanceOf(Map).isRequired,
  componentsMetadata: PropTypes.instanceOf(Map).isRequired,
  expandedFolders: PropTypes.instanceOf(Map).isRequired,
  currentAdmin: PropTypes.instanceOf(Map).isRequired,
  notifications: PropTypes.instanceOf(List).isRequired,
  hasFlows: PropTypes.bool.isRequired,
};

const MemoizedTransformationConfigs = React.memo(TransformationConfigs, shallowEqualImmutable);

export default MemoizedTransformationConfigs;
