import React from 'react';
import { Button } from 'react-bootstrap';

import { SortIcon } from '@/react/common';

export type SortState = 'desc' | 'asc' | null;

const SortByName = (props: {
  sortBy: SortState;
  onClick: (sortBy: SortState) => void;
  allowReset?: boolean;
}) => {
  return (
    <>
      <span
        className="clickable"
        title="Sort by name"
        onClick={() => props.onClick(props.sortBy === 'desc' ? 'asc' : 'desc')}
      >
        Name
        <SortIcon
          className="icon-addon-left"
          isSorted={!!props.sortBy}
          isSortedDesc={props.sortBy === 'desc'}
        />
      </span>
      {props.allowReset && !!props.sortBy && (
        <Button bsStyle="link" className="btn-link-inline f-12" onClick={() => props.onClick(null)}>
          Reset sort
        </Button>
      )}
    </>
  );
};

export default SortByName;
