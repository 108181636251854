import React from 'react';
import PropTypes from 'prop-types';

import CsvSettings from '@/react/common/CsvSettings';
import Destination from './Configuration/Destination';
import ProcessingSettings from './Configuration/ProcessingSettings';
import Source from './Configuration/Source';

class Configuration extends React.Component {
  render() {
    return (
      <>
        <Source
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          value={this.props.value}
        />
        <CsvSettings
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          value={this.props.value}
        />
        <Destination
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          value={this.props.value}
        />
        <ProcessingSettings
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          value={this.props.value}
        />
      </>
    );
  }
}

Configuration.propTypes = {
  value: PropTypes.shape({
    bucket: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    wildcard: PropTypes.bool.isRequired,
    subfolders: PropTypes.bool.isRequired,
    newFilesOnly: PropTypes.bool.isRequired,
    decompress: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    incremental: PropTypes.bool.isRequired,
    delimiter: PropTypes.string.isRequired,
    enclosure: PropTypes.string.isRequired,
    columnsFrom: PropTypes.oneOf(['manual', 'header', 'auto']),
    columns: PropTypes.array.isRequired,
    primaryKey: PropTypes.array.isRequired,
    addRowNumberColumn: PropTypes.bool.isRequired,
    addFilenameColumn: PropTypes.bool.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Configuration;
