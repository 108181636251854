import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { Button } from '@keboola/design';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import { prepareFoldersFromMetadata } from '@/modules/components/helpers';
import NewTransformationModal from '@/modules/components/react/components/NewTransformationModal';
import { routeNames } from '@/modules/transformations-v2/constants';
import RoutesStore from '@/stores/RoutesStore';

type Props = {
  hasPayAsYouGo: boolean;
  patternComponents: Map<string, any>;
  allowedComponents: Map<string, any>;
  componentsMetadata: Map<string, any>;
  isDevModeActive: boolean;
};

const NewTransformationButton = ({
  hasPayAsYouGo,
  patternComponents,
  allowedComponents,
  componentsMetadata,
  isDevModeActive,
}: Props) => {
  const [openModal, setOpenModal] = React.useState(false);

  const handleCreate = (componentId: string, configId: string) => {
    RoutesStore.getRouter().transitionTo(routeNames.GENERIC_TRANSFORMATION_CONFIG, {
      component: componentId,
      config: configId,
    });
  };

  return (
    <>
      <Button className={NEW_ENTITY_BUTTON} onClick={() => setOpenModal(true)}>
        <FontAwesomeIcon icon="plus" />
        Create Transformation
      </Button>
      <NewTransformationModal
        show={openModal}
        onHide={() => setOpenModal(false)}
        onCreate={handleCreate}
        patternComponents={patternComponents}
        allowedComponents={allowedComponents}
        folders={prepareFoldersFromMetadata(componentsMetadata)}
        hasPayAsYouGo={hasPayAsYouGo}
        isDevModeActive={isDevModeActive}
      />
    </>
  );
};

export default NewTransformationButton;
