import React, { useEffect, useRef, useState } from 'react';
import type { Map } from 'immutable';

import { Tooltip } from '@keboola/design';

import CodeEditor from '@/react/common/CodeEditor';

const CodeFade = () => (
  <div className="tw-absolute tw-bottom-0 tw-z-10 tw-h-8 tw-w-full tw-bg-gradient-to-t tw-from-neutral-50" />
);

type Props = {
  code: Map<string, any>;
  mode: string;
  readOnly: boolean;
  onClick: () => void;
};

const CodeEditorPreview = ({ readOnly, code, mode, onClick }: Props) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const innerRef = useRef<HTMLDivElement>(null);
  const outerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const innerHeight = innerRef?.current?.offsetHeight || 0;
    const outerHeight = outerRef?.current?.offsetHeight || 0;

    const shouldOverflow = innerHeight > outerHeight;
    if (shouldOverflow !== isOverflowing) {
      setIsOverflowing(shouldOverflow);
    }
  }, [isOverflowing, code]);

  return (
    <Tooltip
      placement="top"
      tooltip={`Click to ${readOnly ? 'show' : 'edit'} code`}
      triggerClassName="tw-block"
    >
      <div
        className="code-editor-preview tw-relative tw-max-h-40 tw-cursor-pointer tw-overflow-hidden tw-rounded"
        ref={outerRef}
        onClick={onClick}
      >
        <div className="tw-pointer-events-none" ref={innerRef}>
          <CodeEditor
            value={code}
            options={{
              mode,
              lineNumbers: false,
              cursorHeight: 0,
              readOnly: true,
              scrollbarStyle: 'null',
            }}
          />
          {isOverflowing && <CodeFade />}
        </div>
      </div>
    </Tooltip>
  );
};

export default CodeEditorPreview;
