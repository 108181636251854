import React, { useEffect, useState } from 'react';

import { Button, cn, Slot, Tooltip } from '@keboola/design';

import type { REQUESTABLE_FEATURE } from '@/constants/features';
import { buildFeatureRequestTitle } from '@/constants/features';
import { isFeatureRequested, requestFeature } from '@/modules/sapi-events/actions';
import contactSupport from '@/utils/contactSupport';

const FeatureRequestButton = ({
  feature,
  children,
  asChild,
}: {
  feature: REQUESTABLE_FEATURE;
  children: React.ReactNode;
  asChild?: boolean;
}) => {
  const [isAlreadyRequested, setIsAlreadyRequested] = useState(false);

  useEffect(() => {
    isFeatureRequested(feature.key).then(setIsAlreadyRequested);
  }, [feature.key]);

  const Wrapper = asChild ? Slot : Button;

  const button = (
    <Wrapper
      disabled={isAlreadyRequested}
      className={cn({ 'tw-pointer-events-none tw-opacity-40': isAlreadyRequested })}
      onClick={() => {
        if (isAlreadyRequested) {
          return;
        }
        contactSupport({
          ticketContext: {
            requesting_new: true,
            summary: buildFeatureRequestTitle(feature.label),
            onSubmit: (description: string) => {
              requestFeature(feature.key, description).then(() => setIsAlreadyRequested(true));
            },
          },
        });
      }}
    >
      {isAlreadyRequested && !asChild ? 'Already requested' : children}
    </Wrapper>
  );

  return isAlreadyRequested ? (
    <Tooltip
      type="explanatory"
      tooltip={
        <>
          You already requested this feature.
          <br />
          We will let you know as soon as possible.
        </>
      }
      placement="top"
    >
      {button}
    </Tooltip>
  ) : (
    button
  );
};

export default FeatureRequestButton;
