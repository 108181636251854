import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import { cn } from '@keboola/design';

import { routeNames } from '@/modules/storage/constants';
import { RouterLink } from '@/react/common';
import ModalIcon from '@/react/common/ModalIcon';
import Tree from '@/react/common/Tree';

class ParametersResultsModal extends React.Component {
  render() {
    const result = this.props.job.get('result', Map());

    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Parameters &amp; Results</Modal.Title>
          <ModalIcon icon="brackets-curly" color="blue" />
        </Modal.Header>
        <Modal.Body>
          {!result.isEmpty() && (
            <>
              <h3 className="mt-0">Results</h3>
              <pre className="params-list">
                <Tree data={result.sort()} valueRenderer={this.valueRenderer} />
              </pre>
            </>
          )}
          <h3 className={cn({ 'mt-0': result.isEmpty() })}>Parameters</h3>
          <pre className="params-list">
            <Tree data={this.props.params} />
          </pre>
        </Modal.Body>
      </Modal>
    );
  }

  valueRenderer = {
    storageFileId: (value) => {
      return (
        <RouterLink to={routeNames.FILES} query={{ q: `id:${value}` }}>
          {value}
        </RouterLink>
      );
    },
  };
}

ParametersResultsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default ParametersResultsModal;
