import React from 'react';

import { Tooltip } from '@keboola/design';

import { prettyCron } from '@/modules/scheduler/helpers';

type Props = {
  crontabRecord: string;
  crontabTimezone: string;
  disabled?: boolean;
};

const CronRecord = (props: Props) => {
  if (!props.crontabRecord) {
    return <span className="text-muted">No Schedule</span>;
  }

  const timezone = props.crontabTimezone || 'UTC';

  if (props.disabled) {
    return (
      <Tooltip
        placement="top"
        tooltip="Scheduler is not enabled."
        type="explanatory"
        triggerClassName="text-muted"
      >
        {prettyCron(props.crontabRecord, timezone)}
      </Tooltip>
    );
  }

  return <span className="text-muted">{prettyCron(props.crontabRecord, timezone)}</span>;
};

export default CronRecord;
