import { Header } from '../../constants';
import { createCallbackMiddleware, createGenericFetchClient } from '../../fetchClient';
import type { ClientInitOptions } from '../types';

import { Branches } from './branches';
import { Files } from './files';
import type { GetStackInfoQuery, StackInfo } from './types';

export type GenericFetchClient = ReturnType<typeof createGenericFetchClient>;

export class StorageClient {
  private readonly client: GenericFetchClient;
  files: Files;
  branches: Branches;

  constructor({ baseUrl, token, callbacks }: ClientInitOptions) {
    this.client = createGenericFetchClient({
      baseUrl: `${baseUrl}/v2/storage`,
      middlewares: [createCallbackMiddleware(callbacks)],
      headers: new Headers({ [Header.STORAGE_API_TOKEN]: token }),
    });

    this.files = new Files(this.client);
    this.branches = new Branches(this.client);
  }

  async getStackInfo(query?: GetStackInfoQuery, signal?: AbortSignal) {
    const { data } = await this.client.get<StackInfo, never, GetStackInfoQuery>(
      '',
      { query },
      { signal },
    );
    return data;
  }
}
