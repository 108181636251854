import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_VARIABLES } from '@/constants/componentIds';
import componentsApi from '@/modules/components/InstalledComponentsApi';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';

const INITIAL_STATE = {
  name: '',
  code: '',
  variables: List(),
  isLoading: false,
  isLoadingVariables: false,
};

class CreateSharedCodeFromSourceModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.onEnter = this.onEnter.bind(this);
    this.onHide = this.onHide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <Modal show={this.props.show} onEnter={this.onEnter} onHide={this.onHide}>
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.copy ? 'Copy ' : 'Shared Code from '}
              {this.props.sourceCode.get('name')}
            </Modal.Title>
            <ModalIcon icon={this.props.copy ? 'clone' : 'code'} color="green" bold />
          </Modal.Header>
          <Modal.Body>{this.renderBody()}</Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={
                this.state.isLoading
                  ? `${this.props.copy ? 'Copying' : 'Creating'} shared code...`
                  : `${this.props.copy ? 'Copy' : 'Create'} shared code`
              }
              saveStyle="success"
              saveButtonType="submit"
              isSaving={this.state.isLoading}
              isDisabled={this.isDisabled()}
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  renderBody() {
    if (this.state.isLoadingVariables) {
      return (
        <>
          <Loader />
          Loading data...
        </>
      );
    }

    return (
      <>
        <FormGroup>
          <ControlLabel>Name</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </FormGroup>
      </>
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true });

    return this.props
      .onSubmit(
        this.props.sourceCode.get('type'),
        this.state.name,
        this.state.code,
        this.state.variables,
      )
      .then(this.onHide);
  }

  isDisabled() {
    return this.state.isLoading || this.state.isLoadingVariables || !this.state.name.trim();
  }

  onEnter() {
    this.setState({
      name: `${this.props.sourceCode.get('name')}${this.props.copy ? ' (Copy)' : ''}`,
      code: this.props.sourceCode.get('code'),
      variables: List(),
    });

    if (this.props.sourceCode.get('variablesId')) {
      this.setState({ isLoadingVariables: true });
      componentsApi
        .getComponentConfiguration(KEBOOLA_VARIABLES, this.props.sourceCode.get('variablesId'))
        .then((config) => {
          this.setState({
            variables: fromJS(config)
              .getIn(['configuration', 'variables'], List())
              .map((variable) => variable.get('name')),
          });
        })
        .finally(() => this.setState({ isLoadingVariables: false }));
    }
  }

  onHide() {
    this.props.onHide();
    this.setState(INITIAL_STATE);
  }
}

CreateSharedCodeFromSourceModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  sourceCode: PropTypes.instanceOf(Map).isRequired,
  copy: PropTypes.bool,
};

export default CreateSharedCodeFromSourceModal;
