import React, { useState } from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { cn } from '@keboola/design';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { routeNames, storageInitialLimit } from '@/modules/storage/constants';
import BlockButton from '@/react/common/BlockButton';
import { onTableRowKeyDown } from '@/react/common/ConfigurationsTable/helpers';
import RoutesStore from '@/stores/RoutesStore';
import type { Data } from '@/types/types';
import onClickSelectionCell from '@/utils/onClickSelectionCell';

type Props = {
  columns: any;
  data: Data[];
};

export const ResultsFlatTable = ({ columns, data }: Props) => {
  const [showAll, setShowAll] = useState(false);

  const tableInstance = useReactTable<Data>({
    columns,
    data,
    getRowId: (row) => row.item.get('id'),
    getCoreRowModel: getCoreRowModel(),
  });

  let rows = tableInstance.getRowModel().rows;

  if (!showAll) {
    rows = rows.slice(0, storageInitialLimit);
  }

  return (
    <>
      <div className="table table-hover react-table">
        <div className="thead">
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="tr with-action-buttons is-sticky bg-color-white">
              {headerGroup.headers.map((header) => {
                return (
                  <div
                    key={header.column.id}
                    className={cn('th', {
                      'w-52': header.column.id === 'selection',
                      'w-200 text-right': header.column.id === 'last_modified',
                    })}
                    {...(header.column.id === 'selection' && {
                      onClick: onClickSelectionCell,
                    })}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows.map((row) => {
            const item = row.original.item;
            const rowAction = () =>
              item.has('bucketTables')
                ? RoutesStore.getRouter().transitionTo(routeNames.BUCKET, {
                    bucketId: item.get('id'),
                  })
                : RoutesStore.getRouter().transitionTo(routeNames.TABLE, {
                    bucketId: item.getIn(['bucket', 'id']),
                    tableName: item.get('name'),
                  });

            return (
              <div
                key={row.id}
                tabIndex={0}
                role="button"
                className="tr clickable hoverable-actions-with-replacement"
                onClick={rowAction}
                onKeyDown={onTableRowKeyDown(rowAction)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <div
                      key={cell.id}
                      className={cn('td', {
                        'bg-selected': row.getIsSelected(),
                        'text-right': cell.column.id === 'last_modified',
                        'dev-bucket': isCreatedInDevBranch(
                          item.has('stage') ? item : item.get('bucket'),
                        ),
                      })}
                      {...(cell.column.id === 'selection' && {
                        onClick: onClickSelectionCell,
                      })}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {tableInstance.getRowModel().rows.length > storageInitialLimit && !showAll && (
        <BlockButton label="Show All" onClick={() => setShowAll(true)} />
      )}
    </>
  );
};
