import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';
import { fromJS, List, Map } from 'immutable';

import { cn, Tooltip } from '@keboola/design';

import MetadataActionCreators from '@/modules/components/MetadataActionCreators';
import { MetadataKeys, ObjectTypes } from '@/modules/components/MetadataConstants';
import {
  getTableColumnMetadata,
  getUserColumnMetadata,
} from '@/modules/components/utils/tableMetadataHelper';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import {
  addTableColumn,
  dataPreview,
  deleteColumnMetadata,
  deleteMultipleColumns,
  saveColumnMetadata,
} from '@/modules/storage/actions';
import {
  getDescriptionValue,
  isColumnUsedInAliasColumns,
  isColumnUsedInAliasFilter,
} from '@/modules/storage/helpers';
import BlockButton from '@/react/common/BlockButton';
import Checkbox from '@/react/common/Checkbox';
import Confirm from '@/react/common/Confirm';
import MarkedText from '@/react/common/MarkedText';
import { matchByWords } from '@/utils';
import descriptionExcerpt from '@/utils/descriptionExcerpt';
import string from '@/utils/string';
import ColumnDatatypes from './ColumnDatatypes';
import ColumnDescriptionModal from './ColumnDescriptionModal';
import CreateColumnModal from './CreateColumnModal';
import DeleteColumnsModal from './DeleteColumnsModal';
import DevBranchStorageWarning from './DevBranchStorageWarning';

const ACTIONS = {
  ADD: 'add',
  DELETE: 'delete',
  UPDATE_TYPES: 'types',
  UPDATE_DESCIPTION: 'description',
};

class TableSchema extends React.Component {
  state = {
    selectedColumns: Map(),
    openCreateModal: false,
    openDescriptionModal: false,
    openDeleteColumnsModal: false,
    columnName: '',
    sampleData: null,
  };

  render() {
    const canDeleteColumns =
      this.canEditColumns(ACTIONS.DELETE) && this.props.table.get('columns').count() > 1;
    const selectedColumnsCount = this.state.selectedColumns.count(
      (isColumnSelected) => isColumnSelected,
    );

    return (
      <div className="box">
        <table className="table">
          <thead>
            <tr className="with-action-buttons">
              {canDeleteColumns ? (
                <>
                  <th className="w-52 pr-0" />
                  <th className="w-300">
                    <div className="flex-container flex-start">
                      {selectedColumnsCount === 0 ? (
                        <span className="text-muted">No columns selected</span>
                      ) : (
                        <strong>
                          {selectedColumnsCount} {string.pluralize(selectedColumnsCount, 'column')}{' '}
                          selected
                        </strong>
                      )}
                      {selectedColumnsCount > 0 && (
                        <div className="table-action-buttons">
                          <Tooltip placement="top" tooltip="Delete Selected">
                            <Button
                              bsStyle="link"
                              className="btn-link-inline btn-link-muted"
                              onClick={() => this.setState({ openDeleteColumnsModal: true })}
                            >
                              <FontAwesomeIcon icon="trash" fixedWidth />
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </th>
                </>
              ) : (
                <th className="w-300">Column name</th>
              )}
              <th className="w-300">Data type</th>
              <th className="text-left">Description</th>
              {canDeleteColumns && <th />}
            </tr>
          </thead>
        </table>
        {this.renderAddColumnButton()}
        <table className="table table-hover">
          <tbody>
            {this.props.table
              .get('columns')
              .filter(
                (columnName) =>
                  !this.props.searchQuery || matchByWords(columnName, this.props.searchQuery),
              )
              .map((columnName) => {
                return (
                  <tr key={columnName} className={cn({ 'hoverable-actions': canDeleteColumns })}>
                    {canDeleteColumns &&
                      this.renderColumnCheckbox(columnName, selectedColumnsCount)}
                    <td className="w-300">{this.renderName(columnName)}</td>
                    <td className="w-300">
                      <ColumnDatatypes
                        columnName={columnName}
                        table={this.props.table}
                        sampleData={this.state.sampleData}
                        loadSampleData={this.handleLoadSampleData}
                        saveUserType={this.handleSaveUserType}
                        deleteUserType={this.handleDeleteUserType}
                        canEdit={this.canEditColumns(ACTIONS.UPDATE_TYPES)}
                        components={this.props.components}
                        disabled={this.props.deletingColumn.get(columnName, false)}
                      />
                    </td>
                    <td className="text-left">{this.renderDescription(columnName)}</td>
                    {canDeleteColumns && (
                      <td className="pl-0 pr-1 no-wrap">
                        <Confirm
                          closeAfterResolve
                          icon="trash"
                          title="Delete Column"
                          text={
                            <>
                              <p>
                                Are you sure you want to delete the column{' '}
                                <strong>{columnName}</strong>?
                              </p>
                              <DevBranchStorageWarning
                                message="The column will also be deleted from the table in production."
                                hasProductionEntity={
                                  !isCreatedInDevBranch(this.props.table.get('bucket'))
                                }
                              />
                            </>
                          }
                          buttonLabel="Delete"
                          isLoading={this.props.deletingColumn.has(columnName)}
                          onConfirm={() => {
                            return deleteMultipleColumns(this.props.table.get('id'), [columnName], {
                              force: true,
                            });
                          }}
                        >
                          <Tooltip placement="top" tooltip="Delete Column">
                            <Button bsStyle="link" className="text-muted">
                              <FontAwesomeIcon icon="trash" fixedWidth />
                            </Button>
                          </Tooltip>
                        </Confirm>
                      </td>
                    )}
                  </tr>
                );
              })
              .toArray()}
          </tbody>
        </table>
        <CreateColumnModal
          show={this.state.openCreateModal}
          table={this.props.table}
          tables={this.props.tables}
          addingColumn={this.props.addingColumn.get(this.props.table.get('id'), false)}
          onSubmit={this.handleCreate}
          onHide={() => this.setState({ openCreateModal: false })}
        />
        <DeleteColumnsModal
          show={this.state.openDeleteColumnsModal}
          table={this.props.table}
          tableAliases={this.props.tableAliases}
          tableLinks={this.props.tableLinks}
          sapiToken={this.props.sapiToken}
          columns={this.state.selectedColumns.filter(Boolean).keySeq().toArray()}
          urlTemplates={this.props.urlTemplates}
          onHide={() => this.setState({ openDeleteColumnsModal: false })}
          onConfirm={this.handleMultiDelete}
          isDeleting={!this.props.deletingColumn.isEmpty()}
        />
        <ColumnDescriptionModal
          show={this.state.openDescriptionModal}
          table={this.props.table}
          columnName={this.state.columnName}
          description={this.getColumnDescription(this.state.columnName)}
          onSubmit={this.handleUpdateDescription}
          onHide={() => this.setState({ openDescriptionModal: false, columnName: '' })}
        />
      </div>
    );
  }

  renderAddColumnButton() {
    if (this.props.table.isEmpty() || !this.canEditColumns(ACTIONS.ADD)) {
      return null;
    }

    return (
      <BlockButton
        style="primary"
        className="add-button"
        label={
          <>
            <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            Add New Column
          </>
        }
        onClick={() => this.setState({ openCreateModal: true })}
      />
    );
  }

  renderColumnCheckbox(columnName, selectedColumnsCount) {
    if (
      !this.isColumnForceDeletable(columnName) ||
      (selectedColumnsCount + 1 === this.props.table.get('columns').count() &&
        !this.state.selectedColumns.get(columnName, false))
    ) {
      return (
        <td className="w-52 pr-0">
          <Checkbox
            disabled
            tooltip={
              !this.isColumnForceDeletable(columnName)
                ? 'Column is used in an alias filter or in alias without synchronized columns'
                : "You can't delete all columns"
            }
          />
        </td>
      );
    }

    return (
      <td className="w-52 pr-0">
        <Checkbox
          tooltip="Toggle Column"
          checked={!!this.state.selectedColumns.get(columnName)}
          disabled={this.props.deletingColumn.get(columnName, false)}
          onChange={(checked) => {
            this.setState(({ selectedColumns }) => ({
              selectedColumns: selectedColumns.set(columnName, checked),
            }));
          }}
        />
      </td>
    );
  }

  renderName(columnName) {
    return (
      <>
        {this.props.table.get('primaryKey', List()).includes(columnName) && (
          <Tooltip tooltip="Primary Key" placement="top">
            <FontAwesomeIcon icon="key" className="f-16 color-primary icon-addon-right" />
          </Tooltip>
        )}
        <MarkedText source={columnName} mark={this.props.searchQuery} />
      </>
    );
  }

  renderDescription(columnName) {
    const description = this.getColumnDescription(columnName);

    return (
      <>
        {!!description ? (
          <span title={description.length > 75 ? description : ''}>
            {descriptionExcerpt(description)}
          </span>
        ) : (
          <span className="text-muted">No description</span>
        )}
        {this.canEditColumns(ACTIONS.UPDATE_DESCIPTION) && (
          <span className="f-13 text-muted">
            <Tooltip tooltip={`${!!description ? 'Update' : 'Add'} description`} placement="top">
              <Button
                bsStyle="link"
                className="btn-link-inline pl-1 pr-1"
                onClick={() => this.setState({ openDescriptionModal: true, columnName })}
                disabled={this.props.deletingColumn.get(columnName, false)}
              >
                <FontAwesomeIcon icon="pen" className="font-size-inherit" />
              </Button>
            </Tooltip>
          </span>
        )}
      </>
    );
  }

  canEditColumns = (action) => {
    if (
      !this.props.canWriteBucket ||
      (this.props.table.get('isTyped', false) && action === ACTIONS.UPDATE_TYPES)
    ) {
      return false;
    }

    if (this.props.table.get('isAlias') && this.props.table.get('aliasColumnsAutoSync')) {
      return false;
    }

    return true;
  };

  isColumnForceDeletable = (columnName) => {
    const { tables, table, sapiToken } = this.props;

    return (
      !isColumnUsedInAliasFilter(columnName, tables, table.get('id'), sapiToken) &&
      !isColumnUsedInAliasColumns(columnName, tables, table.get('id'), sapiToken)
    );
  };

  getColumnDescription = (columnName) => {
    return getDescriptionValue(getTableColumnMetadata(this.props.table).get(columnName));
  };

  handleCreate = (data) => {
    return addTableColumn(this.props.table.get('id'), data);
  };

  handleMultiDelete = (force) => {
    return deleteMultipleColumns(
      this.props.table.get('id'),
      this.state.selectedColumns.filter(Boolean).keySeq(),
      { force },
    ).then(() => this.setState({ selectedColumns: Map() }));
  };

  handleUpdateDescription = (description) => {
    return MetadataActionCreators.saveMetadata(
      ObjectTypes.COLUMN,
      `${this.props.table.get('id')}.${this.state.columnName}`,
      MetadataKeys.DESCRIPTION,
      description,
    );
  };

  handleSaveUserType = (columnName, userDataType) => {
    const metadataToDelete = [];
    const userColumnMetadata = getUserColumnMetadata(this.props.table);
    if (userColumnMetadata.has(columnName) && !userDataType.has(MetadataKeys.LENGTH)) {
      userColumnMetadata.get(columnName).forEach((metadata) => {
        if (metadata.get('key') === MetadataKeys.LENGTH) {
          metadataToDelete.push(metadata);
        }
      });
    }

    const columnId = `${this.props.table.get('id')}.${columnName}`;
    return Promise.each(metadataToDelete, (metadata) => {
      return deleteColumnMetadata(columnId, metadata.get('id'));
    }).then(() => saveColumnMetadata(columnId, userDataType));
  };

  handleDeleteUserType = (columnName) => {
    const promises = [];
    const userColumnMetadata = getUserColumnMetadata(this.props.table);
    userColumnMetadata.get(columnName).forEach((metadata) => {
      if (
        [
          MetadataKeys.PROVIDER,
          MetadataKeys.BASE_TYPE,
          MetadataKeys.LENGTH,
          MetadataKeys.NULLABLE,
        ].includes(metadata.get('key'))
      ) {
        promises.push(
          deleteColumnMetadata(`${this.props.table.get('id')}.${columnName}`, metadata.get('id')),
        );
      }
    });
    return Promise.all(promises);
  };

  handleLoadSampleData = () => {
    dataPreview(this.props.table.get('id'), { limit: 50 }).then((data) => {
      this.setState({ sampleData: fromJS(data) });
    });
  };
}

TableSchema.propTypes = {
  components: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
  table: PropTypes.instanceOf(Map).isRequired,
  addingColumn: PropTypes.instanceOf(Map).isRequired,
  deletingColumn: PropTypes.instanceOf(Map).isRequired,
  urlTemplates: PropTypes.instanceOf(Map).isRequired,
  tableAliases: PropTypes.array.isRequired,
  tableLinks: PropTypes.array.isRequired,
  canWriteBucket: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string,
};

export default TableSchema;
