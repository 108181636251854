import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { List } from 'immutable';

import { HelpBlock } from '@keboola/design';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

class SelectTargetPhaseModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPhaseId: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onHide = this.onHide.bind(this);
  }
  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
          <ModalIcon icon="code-merge" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <Select
            clearable={false}
            value={this.state.selectedPhaseId}
            placeholder="Select phase..."
            onChange={(input) => this.setState({ selectedPhaseId: input ? input : null })}
            options={this.props.phases
              .map((phase) => {
                return {
                  label: phase.get('name'),
                  value: phase.get('id'),
                };
              })
              .toArray()}
          />
          <HelpBlock className="tw-mt-1">
            Select an existing phase name or type a new phase name.
          </HelpBlock>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel={this.props.buttonLabel}
            onSave={this.handleSubmit}
            isDisabled={!this.state.selectedPhaseId}
          />
        </Modal.Footer>
      </Modal>
    );
  }

  onHide() {
    this.setState({
      selectedPhaseId: null,
    });
    this.props.onHide();
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.onSelectPhase(this.state.selectedPhaseId);
    this.onHide();
  }
}

SelectTargetPhaseModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  phases: PropTypes.instanceOf(List).isRequired,
  onSelectPhase: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default SelectTargetPhaseModal;
