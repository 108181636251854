import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { CircleIcon } from '@/react/common';
import BlockButton from '@/react/common/BlockButton';
import Loader from '@/react/common/Loader';
import MappingModal from './MappingModal';
import TablesList from './TablesList';

class JobMappingPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMappingModalShown: false,
    };
  }

  render() {
    return (
      <div className="job-mapping-panel box box-panel">
        <div className="box-header">
          <h3 className="box-title">Mapping</h3>
          <CircleIcon icon="code-merge" color="violet" />
        </div>
        {this.props.stats.isEmpty() ? this.renderLoading() : this.renderContent()}
      </div>
    );
  }

  renderContent() {
    const inputTables = this.props.stats.getIn(['tables', 'export']);
    const outputTables = this.props.stats.getIn(['tables', 'import']);

    return (
      <>
        <div className="box-panel-content">
          <h4 className="mt-0 font-mono font-semibold">Input</h4>
          <TablesList tables={inputTables} listItemsLimit={3} />
          <h4 className="font-mono font-semibold">Output</h4>
          <TablesList tables={outputTables} listItemsLimit={3} />
        </div>
        {(inputTables.get('tables').count() > 0 || outputTables.get('tables').count() > 0) && (
          <>
            <BlockButton
              label="Show All"
              onClick={() => this.setState({ isMappingModalShown: true })}
            />
            <MappingModal
              show={this.state.isMappingModalShown}
              onHide={() => this.setState({ isMappingModalShown: false })}
              stats={this.props.stats}
            />
          </>
        )}
      </>
    );
  }

  renderLoading() {
    return (
      <div className="box-panel-content">
        <p className="text-center pt-1">
          <Loader />
          <br />
          Loading...
        </p>
      </div>
    );
  }
}

JobMappingPanel.propTypes = {
  stats: PropTypes.instanceOf(Map).isRequired,
};

export default JobMappingPanel;
