import React from 'react';
import type { List } from 'immutable';

import { Alert } from '@keboola/design';

import CopyPrimaryKeyButton from '@/react/common/CopyPrimaryKeyButton';

const PrimaryKeyWarning = (props: {
  primaryKey: List<string> | undefined;
  onChange: (primaryKey: List<string>) => void;
}) => {
  return (
    <Alert variant="warning" className="tw-mt-1">
      The primary key does not match the primary key in the existing table. The specified value (or
      empty) overwrites the existing one.{' '}
      <CopyPrimaryKeyButton primaryKey={props.primaryKey} onChange={props.onChange} />
    </Alert>
  );
};

export default PrimaryKeyWarning;
