import type { Map } from 'immutable';
import { fromJS } from 'immutable';

const createConfiguration = (localState: Map<string, any>) => {
  return fromJS({
    parameters: {
      host: localState.get('host', ''),
      port: parseInt(localState.get('port', 21), 10),
      connectionType: localState.get('connectionType', ''),
      username: localState.get('username', ''),
      '#password': localState.get('password', ''),
      '#privateKey': localState.get('privateKey', ''),
      ignorePassiveAddress: localState.get('ignorePassiveAddress', false),
      ssh: localState.get('ssh', { enabled: false }),
    },
  });
};

const parseConfiguration = (configuration: Map<string, any>) => {
  return fromJS({
    host: configuration.getIn(['parameters', 'host'], ''),
    port: configuration.getIn(['parameters', 'port'], 21),
    connectionType: configuration.getIn(['parameters', 'connectionType'], ''),
    username: configuration.getIn(['parameters', 'username'], ''),
    password: configuration.getIn(['parameters', '#password'], ''),
    privateKey: configuration.getIn(['parameters', '#privateKey'], ''),
    ignorePassiveAddress: configuration.getIn(['parameters', 'ignorePassiveAddress'], false),
    ssh: configuration.getIn(['parameters', 'ssh'], { enabled: false }),
  });
};

const isComplete = (configuration: Map<string, any>) => {
  return (
    configuration.getIn(['parameters', 'host'], '') !== '' &&
    configuration.getIn(['parameters', 'port'], '') !== '' &&
    configuration.getIn(['parameters', 'connectionType'], '') !== '' &&
    configuration.getIn(['parameters', 'username'], '') !== '' &&
    configuration.getIn(['parameters', '#password'], '') !== '' &&
    configuration.hasIn(['parameters', 'ignorePassiveAddress'])
  );
};

const createEmptyConfiguration = () => createConfiguration(fromJS({ port: 21 }));

export default {
  createConfiguration,
  parseConfiguration,
  isComplete,
  createEmptyConfiguration,
};
