import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Promise from 'bluebird';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { Alert } from '@keboola/design';

import jobsApi from '@/modules/jobs/JobsApi';
import queueApi from '@/modules/queue/api';
import { JOBS_STATUS, routeNames } from '@/modules/queue/constants';
import TokenString from '@/modules/tokens/react/components/TokenString';
import { RouterLink as Link } from '@/react/common';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';

const RefreshTokenModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    onHideFn: PropTypes.func.isRequired,
    onRefreshFn: PropTypes.func.isRequired,
    isRefreshing: PropTypes.bool.isRequired,
    token: PropTypes.object.isRequired,
    sapiToken: PropTypes.instanceOf(Map).isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      newToken: null,
      isValidating: false,
      hasJobs: false,
    };
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.handleClose} onEnter={this.checkRunningJobs}>
        <Modal.Header closeButton>
          <Modal.Title>Refresh Token {this.props.token.get('description')}</Modal.Title>
          <ModalIcon icon="arrows-rotate" color="green" bold />
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={this.props.isRefreshing}
            onSave={this.handleRefresh}
            saveLabel="Refresh"
            showSave={!this.state.newToken}
          />
        </Modal.Footer>
      </Modal>
    );
  },

  renderBody() {
    if (this.state.isValidating) {
      return (
        <p>
          <Loader /> Loading data...
        </p>
      );
    }

    if (this.state.newToken) {
      const canBeSeen =
        this.props.sapiToken.get('id') === this.state.newToken.get('id') ||
        !this.state.newToken.get('isMasterToken');

      return (
        <>
          <Alert variant="success" className="tw-mb-5">
            Token has been refreshed.
            {canBeSeen && <> Make sure to copy it. You won&apos;t be able to see it again.</>}
          </Alert>
          {canBeSeen && <TokenString token={this.state.newToken} />}
        </>
      );
    }

    return (
      <>
        <p>
          You are about to refresh the token {this.props.token.get('description')} (
          {this.props.token.get('id')}). A new token will be generated and the old token becomes
          immediately invalid.
        </p>
        {this.state.hasJobs && (
          <Alert variant="error" className="tw-mb-5">
            The token is associated with some{' '}
            <Link
              to={this.props.hasNewQueue ? routeNames.JOBS : 'jobs'}
              query={this.props.hasNewQueue ? this.getQueryParams() : { q: this.getJobsQuery() }}
            >
              unfinished jobs
            </Link>
            . Refreshing the token may cause these jobs to fail.
          </Alert>
        )}
      </>
    );
  },

  handleRefresh() {
    this.setState({ newToken: null });
    this.props.onRefreshFn().then((newToken) => this.setState({ newToken: newToken }));
  },

  handleClose() {
    this.props.onHideFn();
    this.setState({ newToken: null });
  },

  checkRunningJobs() {
    this.setState({ isValidating: true });

    Promise.resolve()
      .then(() => {
        if (this.props.hasNewQueue) {
          return queueApi.getJobs(this.getQueryParams());
        }

        return jobsApi.getJobsByQuery(this.getJobsQuery());
      })
      .then((jobs) => {
        this.setState({ isValidating: false, hasJobs: jobs.length > 0 });
      });
  },

  getQueryParams() {
    return {
      tokenId: this.props.token.get('id'),
      status: [JOBS_STATUS.CREATED, JOBS_STATUS.WAITING, JOBS_STATUS.PROCESSING],
    };
  },

  getJobsQuery() {
    return `token.id:${this.props.token.get(
      'id',
    )} AND (status:created OR status:waiting OR status:processing)`;
  },
});

export default RefreshTokenModal;
