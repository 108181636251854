import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { cn, Link, Tooltip } from '@keboola/design';

import { resolveRouterLinkParams } from '@/modules/components/helpers';
import { removeBranchFromUrl } from '@/modules/dev-branches/helpers';
import { Truncated } from '@/react/common';
import Loader from '@/react/common/Loader';
import TimeAndUser from '@/react/common/TimeAndUser';
import RoutesStore from '@/stores/RoutesStore';
import descriptionExcerpt from '@/utils/descriptionExcerpt';
import { CONFIGURATION_STATUS } from './constants';

export type ConfigurationStatus = (typeof CONFIGURATION_STATUS)[keyof typeof CONFIGURATION_STATUS];

type Props = {
  config: Map<string, any>;
  component: Map<string, any>;
  admins: Map<string, any>;
  isLoadingDiff: boolean;
  readOnly: boolean;
  status: ConfigurationStatus;
  onCompare: () => void;
  onReset: () => void;
};

const PullFromProductionConfigurationRow = ({
  readOnly,
  config,
  component,
  admins,
  status,
  isLoadingDiff,
  onCompare,
  onReset,
}: Props) => {
  const showCompare = status !== CONFIGURATION_STATUS.noChange;

  const renderActions = () => {
    if (!showCompare && readOnly) {
      return null;
    }

    return (
      <>
        {showCompare && (
          <Tooltip placement="top" tooltip="Compare">
            <Button bsStyle="link" className="text-muted" onClick={onCompare}>
              {isLoadingDiff ? <Loader /> : <FontAwesomeIcon icon="right-left" fixedWidth />}
            </Button>
          </Tooltip>
        )}
        {!readOnly && (
          <Tooltip placement="top" tooltip="Pull from Production">
            <Button bsStyle="link" className="text-muted" onClick={onReset}>
              <FontAwesomeIcon icon="code-pull-request" fixedWidth />
            </Button>
          </Tooltip>
        )}
      </>
    );
  };

  const renderStatus = () => {
    switch (status) {
      case CONFIGURATION_STATUS.updatedInBranch:
        return <span className="color-danger">Updated in Branch</span>;
      case CONFIGURATION_STATUS.updatedInProduction:
        return <span className="color-primary">Updated in Production</span>;
      case CONFIGURATION_STATUS.conflict:
        return <span className="color-danger">Conflicting Configuration</span>;
      case CONFIGURATION_STATUS.missing:
        return <span className="color-primary">Missing in Branch</span>;
      case CONFIGURATION_STATUS.noChange:
        return <span>No Changes in Production</span>;
      default:
        return '';
    }
  };

  const prepareConfigHref = () => {
    const linkParams = resolveRouterLinkParams(component.get('id'), config.get('id'), null);

    if (!linkParams) {
      return;
    }

    const branchUrl = RoutesStore.getRouter().createHref(linkParams.to, linkParams.params);

    return removeBranchFromUrl(branchUrl);
  };

  return (
    <span
      className={cn('tr', {
        'hoverable-actions-with-replacement': showCompare || !readOnly,
      })}
    >
      <span className="td">
        <Link href={prepareConfigHref()}>
          <Truncated text={config.get('name', '---')} />
        </Link>
        {config.get('description') && (
          <div>
            <span className="f-13 text-muted">{descriptionExcerpt(config.get('description'))}</span>
          </div>
        )}
      </span>
      <span className="td w-300 text-right">
        <TimeAndUser
          admin={admins.get(config.getIn(['currentVersion', 'creatorToken', 'description']))}
          time={config.getIn(['currentVersion', 'created'])}
          fallbackName={config.getIn(['currentVersion', 'creatorToken', 'description'])}
        />
      </span>
      <span className="td w-300 text-muted text-right">
        <div className="actions-container">
          <div className="not-actions">{renderStatus()}</div>
          <div className="actions">
            <span className="no-wrap pull">{renderActions()}</span>
          </div>
        </div>
      </span>
    </span>
  );
};

export default PullFromProductionConfigurationRow;
