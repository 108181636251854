import React from 'react';
import type { Map } from 'immutable';

import { Badge, Tooltip } from '@keboola/design';

const MigratedLabel = ({ bucket }: { bucket: Map<string, any> }) => {
  const isMigraded = bucket.getIn(
    ['configuration', 'migrated'],
    bucket.getIn(['configuration', 'migratedToNewLegacy'], false),
  );

  if (!isMigraded) {
    return null;
  }

  return (
    <Tooltip
      placement="top"
      type="explanatory"
      tooltip="This bucket has been migrated to new transformations"
    >
      <Badge text="Migrated" variant="green-inverse" placement="right" />
    </Tooltip>
  );
};

export default MigratedLabel;
