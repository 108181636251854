import React from 'react';

import { Separator } from '../Separator';

import { useRadioGroupContext } from './context';

export const RadioGroupSeparator = () => {
  const { orientation } = useRadioGroupContext();
  const invertedOrientation = orientation === 'horizontal' ? 'vertical' : 'horizontal';
  const size = orientation === 'horizontal' ? '3/4' : 'full';

  return <Separator orientation={invertedOrientation} size={size} className="tw-m-1" />;
};
