import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { ButtonGroup, FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

import FilePicker from '@/modules/ex-onedrive/react/FilePicker';
import Checkbox from '@/react/common/Checkbox';
import CreateWorkbook from './CreateWorkbook';
import CreateWorksheet from './CreateWorksheet';
import LoadWorksheets from './LoadWorksheets';

const FORM_GROUP_CLASS_NAME = 'tw-grid tw-grid-cols-4 tw-gap-4 tw-items-center';

class Destination extends React.Component {
  render() {
    const { value, onChange, disabled, context } = this.props;

    return (
      <>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Workbook Configuration</h2>
            <ButtonGroup>
              <FilePicker
                disabled={disabled}
                onSelect={({ name, driveId, fileId }) =>
                  onChange({ workbookName: name, driveId, fileId })
                }
              />
              <CreateWorkbook
                disabled={disabled}
                configId={context.get('configurationId')}
                onCreate={({ name, driveId, fileId }) =>
                  onChange({ workbookName: name, driveId, fileId })
                }
              />
            </ButtonGroup>
          </div>
          <div className="box-content tw-flex tw-flex-col tw-gap-4">
            <FormGroup className={FORM_GROUP_CLASS_NAME}>
              <Label htmlFor="filename">File Name</Label>
              <TextInput
                id="filename"
                className="tw-col-span-3"
                variant="secondary"
                value={value.workbookName || ''}
                disabled={disabled}
                onChange={(workbookName) => onChange({ workbookName })}
              />
            </FormGroup>

            <FormGroup className={FORM_GROUP_CLASS_NAME}>
              <Label htmlFor="drive-id">Drive ID</Label>
              <TextInput
                id="drive-id"
                className="tw-col-span-3"
                variant="secondary"
                value={value.driveId || ''}
                disabled={disabled}
                onChange={(driveId) => onChange({ driveId })}
              />
            </FormGroup>

            <FormGroup className={FORM_GROUP_CLASS_NAME}>
              <Label htmlFor="file-id">File ID</Label>
              <TextInput
                id="file-id"
                className="tw-col-span-3"
                variant="secondary"
                disabled={disabled}
                value={value.fileId || ''}
                onChange={(fileId) => onChange({ fileId })}
              />
            </FormGroup>
          </div>
        </div>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Worksheet Configuration</h2>
            <ButtonGroup>
              <LoadWorksheets
                configId={context.get('configurationId')}
                fileId={value.fileId}
                driveId={value.driveId}
                onSelect={({ name, id }) => onChange({ worksheetName: name, id })}
                disabled={disabled}
              />
              <CreateWorksheet
                configId={context.get('configurationId')}
                fileId={value.fileId}
                driveId={value.driveId}
                onCreate={({ name, id }) => onChange({ worksheetName: name, id })}
                disabled={disabled}
              />
            </ButtonGroup>
          </div>
          <div className="box-content tw-flex tw-flex-col tw-gap-4">
            <FormGroup className={FORM_GROUP_CLASS_NAME}>
              <Label htmlFor="worksheet-name">Worksheet Name</Label>
              <TextInput
                id="worksheet-name"
                variant="secondary"
                className="tw-col-span-3"
                value={value.worksheetName || ''}
                disabled={disabled}
                onChange={(worksheetName) => onChange({ worksheetName })}
              />
            </FormGroup>

            <FormGroup className={FORM_GROUP_CLASS_NAME}>
              <Label htmlFor="worksheet-id">Worksheet ID</Label>
              <TextInput
                id="worksheet-id"
                className="tw-col-span-3"
                variant="secondary"
                value={value.id || ''}
                disabled={disabled}
                onChange={(id) => onChange({ id })}
              />
            </FormGroup>

            <FormGroup className={FORM_GROUP_CLASS_NAME}>
              <div className="tw-col-span-3 tw-col-start-2 tw-flex tw-flex-col tw-gap-1">
                <Checkbox
                  disabled={disabled}
                  checked={value.append}
                  onChange={(checked) => onChange({ append: checked })}
                >
                  Append
                </Checkbox>
                <HelpBlock>The new rows are inserted after the existing ones.</HelpBlock>
              </div>
            </FormGroup>
          </div>
        </div>
      </>
    );
  }
}

Destination.propTypes = {
  context: PropTypes.instanceOf(Map).isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Destination;
