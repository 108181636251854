import React from 'react';
import { List, Map } from 'immutable';

import { CollapsiblePanel } from '@keboola/design';

import { componentTypes } from '@/constants/componentTypes';
import keyCodes from '@/constants/keyCodes';
import { resolveRouterLinkParams } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { usedAsOutputTable, usedInOutputMapping } from '@/modules/storage/helpers';
import { CreatedDate, Truncated } from '@/react/common';
import ComponentIcon from '@/react/common/ComponentIcon';
import Loader from '@/react/common/Loader';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';
import hasSelections from '@/utils/hasSelections';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';

const ConfigurationUsage = ({ tableId }: { tableId: string }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const { configurations, transformations } = useStores(
    () => {
      const components = InstalledComponentsStore.getAll() as Map<string, any>;

      const prepareComponent = (types: string[]) => {
        return components
          .filter((component: Map<string, any>) => types.includes(component.get('type')))
          .map((component) => {
            return component.update('configurations', Map(), (configs: Map<string, any>) => {
              return configs
                .map((config) => {
                  return config.update('rows', List(), (rows: Map<string, any>) => {
                    return rows.filter((row: Map<string, any>) => {
                      return usedAsOutputTable(row, tableId) || usedInOutputMapping(row, tableId);
                    });
                  });
                })
                .filter((config) => {
                  return (
                    usedAsOutputTable(config, tableId) ||
                    usedInOutputMapping(config, tableId) ||
                    !config.get('rows', List()).isEmpty()
                  );
                });
            });
          })
          .filter((component: Map<string, any>) => {
            return !component.get('configurations', Map()).isEmpty();
          }) as Map<string, any>;
      };

      return {
        configurations: prepareComponent([
          componentTypes.EXTRACTOR,
          componentTypes.APPLICATION,
          componentTypes.WRITER,
        ]),
        transformations: prepareComponent([componentTypes.TRANSFORMATION]),
      };
    },
    [tableId],
    [InstalledComponentsStore],
  );

  React.useEffect(() => {
    setIsLoading(true);
    InstalledComponentsActionCreators.loadInstalledComponentsForce({
      include: 'configuration,rows',
    }).finally(() => setIsLoading(false));
  }, [tableId]);

  if (isLoading) {
    return (
      <div className="tw-mt-4">
        <Loader /> checking existing configurations...
      </div>
    );
  }

  if (configurations.isEmpty() && transformations.isEmpty()) {
    return null;
  }

  const renderBox = (label: string, components: Map<string, any>) => {
    if (components.isEmpty()) {
      return null;
    }

    return (
      <CollapsiblePanel defaultExpanded header={label} className="tw-mt-3" bodyClassName="p-0">
        <table className="table table-hover">
          <tbody>
            {components
              .map((component: Map<string, any>) => {
                return component
                  .get('configurations')
                  .map((config: Map<string, any>) => {
                    const onClick = (e: React.MouseEvent | React.KeyboardEvent) => {
                      const linkParams = resolveRouterLinkParams(
                        component.get('id'),
                        config.get('id'),
                      );

                      if (hasSelections() || !linkParams) {
                        return;
                      }

                      if (shouldUseNewWindow(e)) {
                        return windowOpen(
                          RoutesStore.getRouter().createHref(linkParams.to, linkParams.params),
                        );
                      }

                      return RoutesStore.getRouter().transitionTo(linkParams.to, linkParams.params);
                    };

                    return (
                      <tr
                        key={`${component.get('id')}-${config.get('id')}`}
                        tabIndex={0}
                        role="button"
                        onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
                        onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
                        onClick={onClick}
                        onKeyDown={(e) => {
                          if (e.key === keyCodes.ENTER) {
                            onClick(e);
                          }
                        }}
                      >
                        <td className="pl-1">
                          <ComponentConfigurationLink
                            componentId={component.get('id')}
                            configId={config.get('id')}
                            className="flex-container flex-start"
                          >
                            <ComponentIcon
                              size="20"
                              className="icon-addon-right"
                              component={component}
                            />
                            <Truncated text={config.get('name')} />
                          </ComponentConfigurationLink>
                        </td>
                        <td className="text-muted pr-1">
                          Last changed{' '}
                          <CreatedDate createdTime={config.getIn(['currentVersion', 'created'])} />
                        </td>
                      </tr>
                    );
                  })
                  .toArray();
              })
              .toArray()}
          </tbody>
        </table>
      </CollapsiblePanel>
    );
  };

  return (
    <div className="tw-mt-4">
      <p className="tw-mb-2 tw-mt-4 tw-text-sm">These are the ones we managed to find:</p>
      {renderBox('Component Configurations', configurations)}
      {renderBox('Transformations', transformations)}
    </div>
  );
};

export default ConfigurationUsage;
