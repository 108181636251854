import React from 'react';

import { JOBS_STATUS } from '@/modules/queue/constants';
import { formatAbsolute } from '@/react/common/helpers';
import { durationInWords } from '@/utils/duration';
import { JOBS_GRAPH_TOOLTIP_COLORS } from './constants';
import GraphTooltip from './GraphTooltip';
import type { GraphJob } from './types';

const JobsGraphTooltip = ({ job }: { job: GraphJob }) => {
  return (
    <GraphTooltip>
      <div className="tw-capitalize" style={{ color: JOBS_GRAPH_TOOLTIP_COLORS[job.status] }}>
        {job.status}
        {job.status === JOBS_STATUS.SUCCESS ? '!' : ''}
      </div>
      <div>Duration: {durationInWords(job.duration, job.unit)}</div>
      <div>{formatAbsolute(job.date)}</div>
    </GraphTooltip>
  );
};

export default JobsGraphTooltip;
