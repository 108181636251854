import later from '@breejs/later';
import cronstrue from 'cronstrue';
import { Map } from 'immutable';

import dayjs, { shouldPreferHour12 } from '@/date';
import { SCHEDULE_STATE } from './constants';
import { currentDateInTimezone, displayTimezone } from './timezones';

export const activeSchedules = (triggers: Map<string, any>, schedulers: Map<string, any>) => {
  return triggers.count() + schedulers.count(isActiveScheduler);
};

export const isActiveScheduler = (scheduler: Map<string, any>) => {
  return scheduler.getIn(['configuration', 'schedule', 'state']) === SCHEDULE_STATE.ENABLED;
};

export const prettyCron = (cronTab: string, timezone: string) => {
  return `${stringifyCron(cronTab)} (${displayTimezone(timezone)})`;
};

const stringifyCron = (cron: string) => {
  return cronstrue.toString(cron, { verbose: true, use24HourTimeFormat: !shouldPreferHour12() });
};

export const getScheduleFromCrontab = (crontabRecord: string) => {
  return later.parse.cron(crontabRecord).schedules[0];
};

const getPeriodForSchedule = (schedule: later.Recurrence) => {
  if (schedule.M) {
    return later.year.name;
  }

  if (schedule.D) {
    return later.month.name;
  }

  if (schedule.d) {
    return later.dayOfWeek.name;
  }

  if (schedule.h) {
    return later.day.name;
  }

  return later.hour.name;
};

export const getPeriodForCrontab = (crontabRecord: string) => {
  return getPeriodForSchedule(getScheduleFromCrontab(crontabRecord));
};

export const getNextSchedule = (crontabRecord: string, crontabTimezone = 'UTC') => {
  const startDate = currentDateInTimezone(crontabTimezone);

  if (!dayjs.isDayjs(startDate)) {
    return [];
  }

  const tzOffset = dayjs().tz(crontabTimezone).utcOffset();
  const next = later
    .schedule(later.parse.cron(crontabRecord))
    .next(5, startDate.utc(true).toDate()) as Date[];

  return next.map((date) => dayjs.utc(date).utcOffset(tzOffset, true));
};

// helper for old modules which count with maximum of single scheduler
export const getSingleScheduler = (schedulers: Map<string, any>) => {
  return schedulers.first() || Map();
};
