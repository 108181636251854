import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { Button } from '@keboola/design';

import { routeNames } from '@/modules/storage/constants';
import type { StoreSource } from '@/modules/stream/store';
import RoutesStore from '@/stores/RoutesStore';
import NewStreamModal from './NewStreamModal';

const AddNewStream = ({
  tables,
  sources,
}: {
  tables: Map<string, any>;
  sources: StoreSource[];
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        <FontAwesomeIcon icon="plus" />
        Create Data Stream
      </Button>
      <NewStreamModal
        show={showModal}
        tables={tables}
        sources={sources}
        onHide={() => setShowModal(false)}
        onCreated={(sourceName: string, sourceId: string) => {
          RoutesStore.getRouter().transitionTo(routeNames.STREAM_DETAIL, { sourceId });
        }}
      />
    </>
  );
};

export default AddNewStream;
