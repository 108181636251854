import React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '@keboola/design';

import Loader from '@/react/common/Loader';

type Props = {
  inModal?: boolean;
  isSaving: boolean;
  isGenerating: boolean;
  onCancel: () => void;
  editorValue: string | null;
  onSave: () => void;
  description: string;
};

const SaveButtons = ({
  inModal,
  description,
  isSaving,
  isGenerating,
  onCancel,
  editorValue,
  onSave,
}: Props) => {
  const areValuesEqual = description === editorValue;
  const areValuesEmpty = !description && !editorValue;

  return (
    <ButtonToolbar className={cn({ 'block same-width': inModal })}>
      {(!inModal || !!description) && (
        <Button
          disabled={isSaving || isGenerating}
          onClick={onCancel}
          {...(!inModal && { bsSize: 'small' })}
        >
          Cancel
        </Button>
      )}
      <Button
        bsStyle="success"
        disabled={isSaving || isGenerating || areValuesEmpty || areValuesEqual}
        onClick={onSave}
        {...(!inModal && { bsSize: 'small' })}
      >
        {isSaving ? (
          <Loader className="icon-addon-right" />
        ) : (
          <FontAwesomeIcon icon="check-circle" className="icon-addon-right" fixedWidth />
        )}
        Save
      </Button>
    </ButtonToolbar>
  );
};

export default SaveButtons;
