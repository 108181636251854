import React from 'react';

import { cn } from '@keboola/design';

const ConnectorIcon = ({
  width = 10,
  height = 12,
  className,
  style,
}: {
  width?: number;
  height?: number;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <svg
      fill="none"
      style={style}
      width={width}
      height={height}
      viewBox="0 0 10 12"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('connector-icon', className)}
    >
      <path fill="#C8CAD9" fillRule="evenodd" clipRule="evenodd" d="M2 0H0V10V12H2H10V10H2V0Z" />
    </svg>
  );
};

export default ConnectorIcon;
