import React from 'react';

import { DISABLED_WORKSPACE_ACTIONS_MESSAGE } from '@/modules/sandboxes/Constants';
import { tableModalTabs } from '@/modules/storage/constants';
import {
  CopyToWorkspace,
  CreateSnapshot,
  CreateWorkspace,
  ExportSelected,
  TableIconAction,
} from '@/react/common/TableIconActions';

type Props = {
  onSelectModal: (modal: (typeof tableModalTabs)[keyof typeof tableModalTabs]) => void;
  onSelectSingleActionModal: (modal: string) => void;
  hasSnowflakePartnerConnectLimited: boolean;
  workspaceActionsDisabled: boolean;
  isDeleteSelectedDisabled: boolean;
  isTruncateSelectedDisabled: boolean;
  isExportSelectedDisabled: boolean;
  isCreateSnapshotsDisabled: boolean;
  anySelectedIsAlias: boolean;
  selectedTablesSize: number;
};

const MultiActionsButtons = ({
  onSelectModal,
  onSelectSingleActionModal,
  hasSnowflakePartnerConnectLimited,
  workspaceActionsDisabled,
  isDeleteSelectedDisabled,
  isTruncateSelectedDisabled,
  isExportSelectedDisabled,
  isCreateSnapshotsDisabled,
  anySelectedIsAlias,
  selectedTablesSize,
}: Props) => {
  return (
    <div className="table-action-buttons no-wrap">
      <ExportSelected
        disabled={isExportSelectedDisabled}
        onClick={() => onSelectModal(tableModalTabs.EXPORT)}
      />
      <CreateSnapshot
        multiple={selectedTablesSize > 1}
        disabled={isCreateSnapshotsDisabled}
        onClick={() => onSelectModal(tableModalTabs.SNAPSHOT)}
      />
      {!hasSnowflakePartnerConnectLimited && (
        <>
          <CreateWorkspace
            tooltip={workspaceActionsDisabled ? DISABLED_WORKSPACE_ACTIONS_MESSAGE : null}
            onClick={() => onSelectSingleActionModal(tableModalTabs.CREATE_WORKSPACE)}
            disabled={workspaceActionsDisabled}
          />
          <CopyToWorkspace
            tooltip={workspaceActionsDisabled ? DISABLED_WORKSPACE_ACTIONS_MESSAGE : null}
            onClick={() => onSelectSingleActionModal(tableModalTabs.USE_WORKSPACE)}
            disabled={workspaceActionsDisabled}
          />
        </>
      )}

      <span className="group-separator compact" />

      {!anySelectedIsAlias && (
        <TableIconAction
          tooltip="Truncate Selected"
          onClick={() => onSelectModal(tableModalTabs.TRUNCATE)}
          libraryIcon="xmark"
          disabled={isTruncateSelectedDisabled}
        />
      )}

      <TableIconAction
        tooltip="Delete Selected"
        onClick={() => onSelectModal(tableModalTabs.DELETE_MULTIPLE)}
        libraryIcon="trash"
        disabled={isDeleteSelectedDisabled}
      />
    </div>
  );
};

export default MultiActionsButtons;
