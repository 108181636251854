import React from 'react';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { KEBOOLA_VARIABLES } from '@/constants/componentIds';
import { hasVariables, isComponentDeprecated } from '@/modules/components/helpers';
import { prepareVariables } from '@/modules/components/react/components/generic/variables/helpers';
import Variables from '@/modules/components/react/components/generic/variables/Variables';
import MigrationRow from '@/modules/components/react/components/MigrationRow';
import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import configurationRowsActions from '@/modules/configurations/ConfigurationRowsActionCreators';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import configurationsActions from '@/modules/configurations/ConfigurationsActionCreators';
import ConfigurationsStore from '@/modules/configurations/ConfigurationsStore';
import ConfigurationRows from '@/modules/configurations/react/components/ConfigurationRows';
import CreateConfigurationRowButton from '@/modules/configurations/react/components/CreateConfigurationRowButton';
import IndexSections from '@/modules/configurations/react/components/IndexSections';
import sections from '@/modules/configurations/utils/sections';
import OAuthStore from '@/modules/oauth-v2/Store';
import { shouldShowSimplifiedUi } from '@/modules/simplified-ui/helpers';
import SimplifiedUi from '@/modules/simplified-ui/Index';
import CatchUnsavedRunWarning from '@/react/common/CatchUnsavedRunWarning';
import createStoreMixin from '@/react/mixins/createStoreMixin';
// stores
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageBucketsStore,
      StorageTablesStore,
      ConfigurationsStore,
      ConfigurationRowsStore,
      VersionsStore,
      OAuthStore,
    ),
  ],

  getStateFromStores() {
    const configurationId = RoutesStore.getCurrentRouteParam('config');
    const settings = RoutesStore.getRouteSettings();
    const componentId = settings.get('componentId');
    const component = ComponentsStore.getComponent(componentId);
    const configuration = ConfigurationsStore.get(componentId, configurationId);
    const configData = ConfigurationsStore.getConfiguration(componentId, configurationId);
    const allConfigurations = InstalledComponentsStore.getAll();
    const conformFn = settings.getIn(['index', 'onConform'], (config) => config);
    const parseFn = sections.makeParseFn(
      settings.getIn(['index', 'sections'], List()),
      conformFn,
      InstalledComponentsStore.getConfigurationContext(componentId, configurationId),
    );
    const configurationBySections = ConfigurationsStore.getEditingConfiguration(
      componentId,
      configurationId,
      parseFn,
    );
    const variables = InstalledComponentsStore.getComponentConfigurations(KEBOOLA_VARIABLES);
    const variablesConfigData = variables.get(configData.get('variables_id'), Map());

    return {
      settings,
      component,
      componentId,
      configuration,
      configurationId,
      configData,
      variablesConfiguration: variablesConfigData.get('configuration', Map()),
      variablesConfigurationRow: variablesConfigData
        .get('rows', List())
        .find((row) => row.get('id') === configData.get('variables_values_id', null), null, Map()),
      preparedVariables: prepareVariables(allConfigurations, componentId, configurationId),
      readOnly: ApplicationStore.isReadOnly(),
      rows: ConfigurationRowsStore.getRows(componentId, configurationId),
      isChanged: !configurationBySections.equals(parseFn(configData)),
      isJsonChanged: ConfigurationsStore.isEditingJsonConfiguration(componentId, configurationId),
      isDeprecated: isComponentDeprecated(component),
      deprecationReplacementComponentId: component.getIn(['uiOptions', 'replacementApp']),
      allBuckets: StorageBucketsStore.getAll(),
      allTables: StorageTablesStore.getAll(),
      hasNewQueue: ApplicationStore.hasNewQueue(),
      hasFlows: ApplicationStore.hasFlows(),
    };
  },

  onRowCreated(rowId) {
    const transitionParams = {
      config: this.state.configurationId,
      row: rowId,
    };
    RoutesStore.getRouter().transitionTo(this.state.componentId + '-row', transitionParams);
  },

  renderNewConfigRowButton() {
    return (
      <CreateConfigurationRowButton
        component={this.state.component}
        configId={this.state.configurationId}
        emptyConfig={sections.makeCreateEmptyFn(
          this.state.settings.getIn(['row', 'sections'], List()),
          this.state.settings.getIn(['row', 'onSave']),
        )}
        onRowCreated={this.onRowCreated}
        buckets={this.state.allBuckets}
        tables={this.state.allTables}
      />
    );
  },

  renderRowsTable() {
    if (this.state.rows.count() === 0) {
      return (
        <div className="box-separator">
          <h2 className="tw-m-0 tw-mb-4 tw-text-base">Rows</h2>
          <div className="box">
            <div className="box-content text-center">
              <p>No rows created yet.</p>
              {!this.state.readOnly && this.renderNewConfigRowButton()}
            </div>
          </div>
        </div>
      );
    }

    return (
      <ConfigurationRows
        readOnly={this.state.readOnly}
        rows={this.state.rows.toList()}
        config={this.state.configuration}
        tables={this.state.allTables}
        buckets={this.state.allBuckets}
        component={this.state.component}
        rowDelete={(rowId) => {
          return configurationRowsActions.delete(
            this.state.componentId,
            this.state.configurationId,
            rowId,
            false,
          );
        }}
        rowDeletePending={(rowId) => {
          return ConfigurationRowsStore.getPendingActions(
            this.state.componentId,
            this.state.configurationId,
            rowId,
          ).has('delete');
        }}
        rowEnableDisable={(rowId) => {
          const isDisabled = this.state.rows.getIn([rowId, 'isDisabled'], false);
          return configurationRowsActions[isDisabled ? 'enable' : 'disable'](
            this.state.componentId,
            this.state.configurationId,
            rowId,
          );
        }}
        rowEnableDisablePending={(rowId) => {
          const rowPendingActions = ConfigurationRowsStore.getPendingActions(
            this.state.componentId,
            this.state.configurationId,
            rowId,
          );

          return rowPendingActions.has('disable') || rowPendingActions.has('enable');
        }}
        rowLinkTo={this.state.componentId + '-row'}
        onOrder={(rowIds, movedRowId) => {
          return configurationsActions.orderRows(
            this.state.componentId,
            this.state.configurationId,
            rowIds,
            movedRowId,
          );
        }}
        orderPending={ConfigurationsStore.getPendingActions(
          this.state.componentId,
          this.state.configurationId,
        ).get('order-rows', Map())}
        columns={this.state.settings.getIn(['row', 'columns'])}
        filter={this.state.settings.getIn(['row', 'searchFilter'])}
        onRowCreated={this.onRowCreated}
        rowCreateEmptyConfig={sections.makeCreateEmptyFn(
          this.state.settings.getIn(['row', 'sections'], List()),
          this.state.settings.getIn(['row', 'onSave']),
        )}
      />
    );
  },

  renderRunModalContent() {
    return (
      <>
        {(this.state.isChanged || this.state.isJsonChanged) && <CatchUnsavedRunWarning />}
        <p>You are about to run {this.state.configuration.get('name', 'Untitled')}.</p>
      </>
    );
  },

  renderVariables() {
    if (!hasVariables()) {
      return null;
    }

    return (
      <Variables
        readOnly={this.state.readOnly}
        mainConfiguration={this.state.configData}
        mainComponentId={this.state.componentId}
        mainConfigurationId={this.state.configurationId}
        configuration={this.state.variablesConfiguration}
        row={this.state.variablesConfigurationRow}
        variables={this.state.preparedVariables}
        configVariablesId={this.state.configData.get('variables_id', null)}
        configVariablesValuesId={this.state.configData.get('variables_values_id', null)}
      />
    );
  },

  render() {
    if (
      shouldShowSimplifiedUi(
        this.state.component,
        this.state.configuration.get('configuration', Map()),
      )
    ) {
      return <SimplifiedUi />;
    }

    return (
      <GenericConfigBody
        key={`${this.state.componentId}-${this.state.configurationId}`}
        componentId={this.state.componentId}
        configId={this.state.configurationId}
        sidebarProps={{
          run: {
            disabled: this.state.rows.isEmpty() ? 'There are no rows configured.' : '',
            forceModal: this.state.isChanged || this.state.isJsonChanged,
            text: this.renderRunModalContent(),
          },
        }}
      >
        {this.state.isDeprecated && !this.state.readOnly && (
          <MigrationRow
            componentId={this.state.componentId}
            replacementAppId={this.state.deprecationReplacementComponentId}
            hasNewQueue={this.state.hasNewQueue}
            hasFlows={this.state.hasFlows}
          />
        )}
        {this.renderVariables()}
        <IndexSections />
        {this.renderRowsTable()}
      </GenericConfigBody>
    );
  },
});

export default Index;
