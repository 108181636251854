import React, { useEffect, useState } from 'react';

import { deleteAutomation } from '@/modules/automations/actions';
import { loadAllAndInitialize } from '@/modules/dev-branches/actions';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { prepareProductionHref } from '@/modules/dev-branches/helpers';
import SimpleMergeHeaderButton from '@/modules/dev-branches/SimpleMergeHeaderButton';
import { routeNames as flowsRouteNames } from '@/modules/flows/constants';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const Merge = (props: { automationId?: string; flowConfigId?: string }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    loadAllAndInitialize().then(() => setIsLoaded(true));
  }, []);

  if (!props.flowConfigId || !props.automationId) return null;

  return (
    <SimpleMergeHeaderButton
      skipConfirmation
      keepBranch
      isDisabled={!isLoaded}
      onMerge={() => {
        deleteAutomation(props.automationId!);

        window.location.href = prepareProductionHref(
          ApplicationStore.getProjectBaseUrl(),
          RoutesStore.getRouter().createHref(flowsRouteNames.DETAIL, {
            config: props.flowConfigId,
          }),
          DevBranchesStore.getCurrentId(),
        );
      }}
      className="tw-ml-7"
    />
  );
};

export default Merge;
