import React from 'react';

import { cn } from '@keboola/design';

import { FILTERS } from '@/constants';
import type { FilterValues } from '@/modules/trash/types';

const AdditionalActions = ({
  filter,
  onSetFilter,
}: {
  filter: FilterValues;
  onSetFilter: (value: FilterValues) => void;
}) => {
  return (
    <div className="predefined-search-list">
      <button
        type="button"
        className={cn('btn predefined-search-link', {
          active: filter === FILTERS.ALL,
        })}
        onClick={() => onSetFilter(FILTERS.ALL)}
      >
        All
      </button>
      <button
        type="button"
        className={cn('btn predefined-search-link', {
          active: filter === FILTERS.COMPONENTS,
        })}
        onClick={() => onSetFilter(FILTERS.COMPONENTS)}
      >
        My Components
      </button>
    </div>
  );
};

export default AdditionalActions;
