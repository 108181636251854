import React from 'react';
import type { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn, Tooltip } from '@keboola/design';

type Props = {
  onClick: () => void;
  title: ReactNode;
  description: string;
  icon: ReactNode;
  isDisabled?: boolean;
  disabledReason?: string;
};

const ModalActionButton = ({
  icon,
  title,
  description,
  onClick,
  isDisabled = false,
  disabledReason,
}: Props) => {
  const content = (
    <div className="modal-action-button">
      <Button
        onClick={() => !isDisabled && onClick()}
        className={cn({ disabled: isDisabled })}
        block
      >
        <div className="modal-action-button-icon">{icon}</div>
        <div className="modal-action-button-text">
          <strong>{title}</strong>
          {description && <small>{description}</small>}
        </div>
        <div className="modal-action-button-arrow">
          <FontAwesomeIcon icon="angle-right" />
        </div>
      </Button>
    </div>
  );

  if (isDisabled) {
    return (
      <Tooltip
        tooltip={disabledReason}
        placement="top"
        type="explanatory"
        triggerClassName="opacity-half"
      >
        {content}
      </Tooltip>
    );
  }

  return content;
};

export default ModalActionButton;
