import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useColumnsStore } from '@/modules/lineage/contexts/columnsStore';

type UseOpenNodeProps = {
  show: boolean;
  nodeId: string;
  onToggleNodeShow: (show: boolean) => void;
  onUpdateVisibleColumns: () => void;
};

export const useOpenNode = ({
  show,
  nodeId,
  onToggleNodeShow,
  onUpdateVisibleColumns,
}: UseOpenNodeProps) => {
  const { chosenColumn, highlightedColumns, shouldResetColumnsHighlight, nodeIdManipulating } =
    useColumnsStore(
      useShallow((state) => ({
        chosenColumn: state.chosenColumn,
        highlightedColumns: state.highlightedColumns,
        shouldResetColumnsHighlight: state.shouldResetColumnsHighlight,
        nodeIdManipulating: state.nodeIdManipulating,
      })),
    );

  const nodeIncludesHighlightedColumns = highlightedColumns.some(
    (column) => column.nodeId === nodeId,
  );
  const nodeIsNotPartOfHighlightedTree =
    chosenColumn && !nodeIncludesHighlightedColumns && nodeIdManipulating !== nodeId;

  useEffect(() => {
    const close = show && (shouldResetColumnsHighlight || nodeIsNotPartOfHighlightedTree);
    const open = nodeIncludesHighlightedColumns && !show;
    if (!close && !open) {
      return;
    }

    // if we reset highlighted columns, we close all nodes
    // we close all irrelevant nodes that were opened before selecting a column
    // we don't want to close the node, where column was clicked
    if (close && nodeId === nodeIdManipulating) {
      return;
    }

    onToggleNodeShow(!close);
    onUpdateVisibleColumns();
  }, [
    chosenColumn,
    nodeId,
    nodeIdManipulating,
    nodeIncludesHighlightedColumns,
    nodeIsNotPartOfHighlightedTree,
    onToggleNodeShow,
    onUpdateVisibleColumns,
    shouldResetColumnsHighlight,
    show,
  ]);
};
