import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ButtonGroup, ButtonLink } from '@keboola/design';

import { routeNames } from '@/modules/templates/constants';
import DocumentationButton from '@/modules/templates/DocumentationButton';
import { RouterLink } from '@/react/common';

const AddNewTemplateHeader = () => {
  return (
    <ButtonGroup>
      <DocumentationButton />
      <ButtonLink>
        <RouterLink to={routeNames.TEMPLATES}>
          <FontAwesomeIcon icon="plus" /> Add Template
        </RouterLink>
      </ButtonLink>
    </ButtonGroup>
  );
};

export default AddNewTemplateHeader;
