import { List, Map } from 'immutable';

import { backendImages } from '@/constants/backendImages';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import { backends } from '@/modules/storage/constants';
import { matchByWords } from '@/utils';
import descriptionExcerpt from '@/utils/descriptionExcerpt';

const getDescriptionFromMetadata = (metadata, key = MetadataKeys.DESCRIPTION) => {
  return metadata.find((item) => item.get('key') === key, null, Map()).get('value', null);
};

const getSharedBucketDescription = (bucket) => {
  const metadata = bucket.get('metadata', List());
  const description = getDescriptionFromMetadata(metadata, MetadataKeys.SHARED_DESCRIPTION);

  if (!description) {
    return getDescriptionFromMetadata(metadata);
  }

  return description;
};

const getDescriptionWithDefaultValue = (bucket, defaultValue = 'No description available') => {
  const description = getSharedBucketDescription(bucket);

  if (!description) {
    return defaultValue;
  }

  return descriptionExcerpt(description, 130);
};

const getDuplicateTableIds = (tables) => {
  return tables
    .groupBy((tableName) => tableName)
    .filter((group) => group.count() > 1)
    .flatMap((table) => table)
    .sortBy((table) => table)
    .keySeq();
};

const getLinkedBucket = (sharedBuckets, bucket) => {
  return sharedBuckets.find(
    (sharedBucket) =>
      sharedBucket.getIn(['sourceBucket', 'id']) === bucket.get('id') &&
      sharedBucket.getIn(['sourceBucket', 'project', 'id']) === bucket.getIn(['project', 'id']),
  );
};

const prepareSharedBuckets = (sharedBuckets, linkedBuckets, projectId, query) => {
  let shared = sharedBuckets
    .filter((bucket) => bucket.getIn(['project', 'id']) !== projectId)
    .toMap()
    .mapKeys((index, bucket) => {
      return `${bucket.getIn(['project', 'id'])}-${bucket.get('id')}`;
    });

  linkedBuckets
    .filter(
      (bucket) =>
        !shared.has(
          `${bucket.getIn(['sourceBucket', 'project', 'id'])}-${bucket.getIn([
            'sourceBucket',
            'id',
          ])}`,
        ),
    )
    .map((bucket) => {
      shared = shared.set(
        `${bucket.getIn(['sourceBucket', 'project', 'id'])}-${bucket.getIn([
          'sourceBucket',
          'id',
        ])}`,
        bucket.get('sourceBucket').set('linkedBucket', bucket),
      );
    });

  if (query) {
    shared = shared.filter((bucket) => {
      const name = bucket.get('displayName');
      const description = getDescriptionWithDefaultValue(bucket, '');
      return matchByWords([name, description], query);
    });
  }

  shared = shared.map((bucket) => {
    const linkedBucket = getLinkedBucket(linkedBuckets, bucket);
    if (linkedBucket) {
      return bucket.set('linkedBucket', linkedBucket);
    }
    return bucket;
  });

  return shared;
};

const couldBeProjectRemovedFromBucketSharingSettings = (bucket, projectId) => {
  if (bucket.get('linkedBy', List()).count() === 0) {
    return true;
  }
  return (
    bucket
      .get('linkedBy', List())
      .count((bucket) => bucket.getIn(['project', 'id']) === projectId.toString()) === 0
  );
};

const getBackendImage = (backend) => {
  switch (backend) {
    case backends.SNOWFLAKE:
      return backendImages.snowflake;

    case backends.REDSHIFT:
      return backendImages.redshift;

    case backends.SYNAPSE:
      return backendImages.synapse;

    case backends.EXASOL:
      return backendImages.exasol;

    case backends.TERADATA:
      return backendImages.teradata;

    case backends.BIGQUERY:
      return backendImages.bigquery;
  }

  return null;
};

export {
  prepareSharedBuckets,
  getSharedBucketDescription,
  getDescriptionFromMetadata,
  getDescriptionWithDefaultValue,
  getDuplicateTableIds,
  getLinkedBucket,
  couldBeProjectRemovedFromBucketSharingSettings,
  getBackendImage,
};
