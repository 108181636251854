import React from 'react';
import PropTypes from 'prop-types';

import { cn } from '@keboola/design';

import { defaultTransformationBackendSize } from '@/modules/components/Constants';
import { getTransformationBackendSizes } from '@/modules/components/helpers';
import string from '@/utils/string';
import BackendSizeIcons from './BackendSizeIcons';

const BackendSizeLabel = ({
  componentId,
  className,
  showText = false,
  size = defaultTransformationBackendSize,
  emphasized = false,
  pullRight = false,
}) => {
  return (
    <div
      className={cn(className, 'backend-size-label', {
        'pull-right': pullRight,
        emphasized,
      })}
      title={string.capitalize(size)}
    >
      <BackendSizeIcons allSizes={getTransformationBackendSizes(componentId)} size={size} />
      {showText && <span className="tw-capitalize">{size}</span>}
    </div>
  );
};

BackendSizeLabel.propTypes = {
  componentId: PropTypes.string.isRequired,
  className: PropTypes.string,
  showText: PropTypes.bool,
  size: PropTypes.string,
  emphasized: PropTypes.bool,
  pullRight: PropTypes.bool,
};

const MemoizedBackendSizeLabel = React.memo(BackendSizeLabel);

export default MemoizedBackendSizeLabel;
