import React from 'react';
import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import InfiniteScroll from 'react-infinite-scroller';
import createReactClass from 'create-react-class';
import { Map, Seq } from 'immutable';

import { IconButton, Tooltip } from '@keboola/design';

import { factory as defaultEventsFactory } from '@/modules/sapi-events/EventsService';
import EventsTable from '@/modules/sapi-events/react/EventsTable';
import BlockButton from '@/react/common/BlockButton';
import SearchPanel from '@/react/common/SearchPanel';

const predefinedSearches = [
  {
    name: 'Omit table fetches',
    query: 'NOT event:storage.tableDataPreview OR NOT event:storage.tableDetail',
  },
  {
    name: 'Omit table exports',
    query: 'NOT event:storage.tableExported',
  },
  {
    name: 'Imports/exports only',
    query: ['ImportStarted', 'ImportDone', 'ImportError', 'Exported']
      .map((type) => `event:storage.table${type}`)
      .join(' OR '),
  },
];

/** @type {any} */
const Events = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    admins: PropTypes.instanceOf(Map).isRequired,
    eventsFactory: PropTypes.object,
  },

  getDefaultProps() {
    return {
      eventsFactory: defaultEventsFactory(),
    };
  },

  getInitialState() {
    return {
      events: Seq(),
      isLoadingMore: false,
      isRefreshing: false,
      isLoading: false,
      hasMore: true,
      searchQuery: '',
    };
  },

  componentDidMount() {
    this._events = this.props.eventsFactory;
    this._events.addChangeListener(this.handleChange);
    this._events.load();
  },

  componentWillUnmount() {
    this._events.removeChangeListener(this.handleChange);
    this._events.reset();
  },

  render() {
    return (
      <>
        <SearchPanel
          searchFieldPlaceholder="Search events"
          query={this.state.searchQuery}
          onSearch={this.handleQueryChange}
          onSubmit={this.handleSearchSubmit}
          predefinedSearches={predefinedSearches}
          additionalActions={this.renderRefreshButton()}
        />
        <div className="box">
          <InfiniteScroll
            initialLoad={false}
            hasMore={this.state.hasMore}
            loadMore={this.handleLoadMore}
          >
            <EventsTable
              events={this.state.events}
              admins={this.props.admins}
              isLoading={this.isSearching()}
              loadEvent={this.handleLoadEvent}
              searchQuery={this.state.searchQuery}
            />
          </InfiniteScroll>
          {this.renderMoreButton()}
        </div>
      </>
    );
  },

  renderRefreshButton() {
    return (
      <Tooltip tooltip="Refresh events" placement="top">
        <IconButton
          variant="outline"
          size="small"
          onClick={this.handleRefresh}
          isLoading={this.state.isRefreshing}
          icon="arrows-rotate"
        />
      </Tooltip>
    );
  },

  renderMoreButton() {
    if (this.state.events.isEmpty() || !this.state.hasMore || this.isSearching()) {
      return null;
    }

    return (
      <BlockButton
        onClick={this.handleLoadMore}
        isLoading={this.state.isLoadingMore}
        disabled={this.state.isLoading || this.state.isLoadingMore}
      />
    );
  },

  handleChange() {
    const isLoading = this._events.getIsLoading();

    this.setState({
      searchQuery: this._events.getQuery(),
      events: this._events.getEvents(),
      isRefreshing: isLoading && this.state.isRefreshing,
      isLoading,
      isLoadingMore: this._events.getIsLoadingOlder(),
      hasMore: this._events.getHasMore(),
    });
  },

  handleRefresh() {
    this.setState({ isRefreshing: true }, () => this._events.load());
  },

  handleLoadEvent(eventId) {
    return this._events.loadEvent(eventId);
  },

  handleLoadMore() {
    if (this.state.isLoading || this.state.isLoadingMore) {
      return;
    }

    this._events.loadMoreMax();
  },

  handleQueryChange(query) {
    this.setState({ searchQuery: query });
  },

  handleSearchSubmit(searchQuery) {
    this._events.setQuery(searchQuery);
    this._events.load();
  },

  isSearching() {
    return this.state.isLoading && !this.state.isRefreshing;
  },
});

export default Events;
