import React from 'react';

import { URLS } from '@keboola/constants';
import { Link } from '@keboola/design';

import KeyValueBox from '@/react/common/KeyValueBox/KeyValueBox';

type Props = {
  isLoading?: boolean;
  isReadOnly: boolean;
  isDisabled: boolean;
  onAddNew: () => void;
  children: React.ReactNode;
};

const VariablesBox = (props: Props) => {
  return (
    <KeyValueBox
      entity="Variable"
      helperText={
        <>
          Variables allow you to parameterize transformations, enabling you to reuse similar
          transformations with different values. For detailed information, please refer to the{' '}
          <Link href={`${URLS.USER_DOCUMENTATION}/transformations/variables`}>documentation</Link>.
        </>
      }
      {...props}
    />
  );
};

export default VariablesBox;
