import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import CopyTransformationModal from '@/modules/transformations/react/modals/CopyTransformationModal';
import { RowActionMenuItem } from '@/react/common';

const CopyTransformationButton = createReactClass({
  propTypes: {
    transformation: PropTypes.object.isRequired,
    transformationBuckets: PropTypes.object.isRequired,
    bucketId: PropTypes.string.isRequired,
    mode: PropTypes.oneOf(['link', 'menuitem']),
    onKeyDown: PropTypes.func,
  },

  getDefaultProps() {
    return {
      mode: 'link',
    };
  },

  getInitialState() {
    return {
      showModal: false,
    };
  },

  render() {
    if (this.props.mode === 'menuitem') {
      return (
        <>
          <RowActionMenuItem onSelect={this.openModal} onKeyDown={this.props.onKeyDown}>
            <FontAwesomeIcon icon="clone" fixedWidth />
            Copy transformation
          </RowActionMenuItem>
          {this.renderModal()}
        </>
      );
    }

    return (
      <>
        <Button bsStyle="link" className="btn-block" onClick={this.openModal}>
          <FontAwesomeIcon icon="clone" fixedWidth />
          Copy transformation
        </Button>
        {this.renderModal()}
      </>
    );
  },

  renderModal() {
    return (
      <CopyTransformationModal
        transformation={this.props.transformation}
        transformationBuckets={this.props.transformationBuckets}
        bucketId={this.props.bucketId}
        show={this.state.showModal}
        onHide={this.closeModal}
      />
    );
  },

  openModal(e) {
    e.preventDefault();
    this.setState({ showModal: true });
  },

  closeModal() {
    this.setState({ showModal: false });
  },
});

export default CopyTransformationButton;
