import { RADEKTOMASEK_EX_DROPBOX_V_2 as COMPONENT_ID } from '@/constants/componentIds';
import { configPoll } from '@/utils/genericPolls';
import { configRequiredData } from '@/utils/genericRequiredData';
import Index from './Index';

export default {
  name: COMPONENT_ID,
  path: `${COMPONENT_ID}/:config`,
  requireData: [(params: { config: string }) => configRequiredData(COMPONENT_ID, params.config)],
  poll: configPoll(COMPONENT_ID),
  defaultRouteHandler: Index,
};
