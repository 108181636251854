import { Map } from 'immutable';

const conform = (configuration: Map<string, any>) => {
  if (
    configuration.has('parameters') &&
    !configuration.hasIn(['parameters', 'ignorePassiveAddress'])
  ) {
    configuration = configuration.setIn(['parameters', 'ignorePassiveAddress'], false);
  }

  if (configuration.has('parameters') && !configuration.hasIn(['parameters', 'ssh'])) {
    configuration = configuration.setIn(['parameters', 'ssh'], Map({ enabled: false }));
  }

  return configuration;
};

export default conform;
