import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import CreateSandboxModal from '@/modules/transformations/react/modals/ConfigureSandbox';
import { RowActionMenuItem } from '@/react/common';

const CreateSandboxButton = createReactClass({
  propTypes: {
    backend: PropTypes.string.isRequired,
    transformationType: PropTypes.string.isRequired,
    runParams: PropTypes.object.isRequired,
    mode: PropTypes.oneOf(['link', 'menuitem']),
    onKeyDown: PropTypes.func,
  },

  getDefaultProps() {
    return {
      mode: 'link',
    };
  },

  getInitialState() {
    return {
      isModalOpen: false,
    };
  },

  openModal(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      isModalOpen: true,
    });
  },

  render() {
    if (this.props.mode === 'menuitem') {
      return (
        <>
          <RowActionMenuItem onSelect={this.openModal} onKeyDown={this.props.onKeyDown}>
            <FontAwesomeIcon icon="wrench" fixedWidth />
            Sandbox
          </RowActionMenuItem>
          {this.renderModal()}
        </>
      );
    }

    return (
      <>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a onClick={this.openModal}>
          <FontAwesomeIcon icon="wrench" fixedWidth />
          Sandbox
        </a>
        {this.renderModal()}
      </>
    );
  },

  renderModal() {
    return (
      <CreateSandboxModal
        defaultMode="prepare"
        show={this.state.isModalOpen}
        onHide={() => this.setState({ isModalOpen: false })}
        backend={this.props.backend}
        transformationType={this.props.transformationType}
        runParams={this.props.runParams}
      />
    );
  },
});

export default CreateSandboxButton;
