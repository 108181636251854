import colors from '@keboola/tailwind-config/colors';

import type { CustomEdge } from '@/modules/lineage/rfTypes';

export const highlightedEdgeStyles = {
  strokeWidth: 2,
  stroke: colors.secondary['500'],
};

type Direction = 'source' | 'target';

type EdgeInfo = { edge: CustomEdge; direction: 'prev' | 'next' };

const buildChosenColumnName = (
  nodeId: string | null,
  column: string | null,
  direction: Direction,
) => {
  return `${nodeId}/${column}-${direction}`;
};

const parseHandleName = (handle: string) => {
  return handle.includes('-source') ? handle.split('-source')[0] : handle.split('-target')[0];
};

const isDuplicate = (arr: { id: string }[], id: string) => {
  return arr.some((item) => item.id === id);
};

// for next edges
// if edge is from table to config, we want the next edge to have target handle as current original target handle and its original source handle as current source handle
export const getNextEdges = (edges: CustomEdge[], currentEdge: CustomEdge) => {
  return edges.filter(
    (edge) =>
      edge.source === currentEdge.target &&
      (typeof currentEdge.data?.originalTargetHandle !== 'undefined'
        ? edge.targetHandle === currentEdge.data.originalTargetHandle &&
          edge.data?.originalSourceHandle === currentEdge.sourceHandle
        : !!currentEdge.targetHandle &&
          edge.sourceHandle === `${parseHandleName(currentEdge.targetHandle)}-source`),
  );
};

const getPrevEdges = (edges: CustomEdge[], currentEdge: CustomEdge) => {
  return edges.filter(
    (edge) =>
      edge.target === currentEdge.source &&
      (typeof currentEdge.data?.originalSourceHandle !== 'undefined'
        ? edge.sourceHandle === currentEdge.data.originalSourceHandle &&
          edge.data?.originalTargetHandle === currentEdge.targetHandle
        : !!currentEdge.sourceHandle &&
          edge.targetHandle === `${parseHandleName(currentEdge.sourceHandle)}-target`),
  );
};

const goThroughGraph = (buffer: EdgeInfo[], initEdges: CustomEdge[]): CustomEdge[] => {
  const result: CustomEdge[] = [];
  const visited: CustomEdge[] = [];

  while (buffer.length !== 0) {
    const current = buffer.shift();
    if (!current || !current.edge) {
      return [];
    }

    visited.push(current.edge);

    const nextEdges = current.direction === 'next' ? getNextEdges(initEdges, current.edge) : [];
    const prevEdges = current.direction === 'prev' ? getPrevEdges(initEdges, current.edge) : [];

    nextEdges.forEach((edge) => {
      if (!isDuplicate(visited, edge.id)) {
        buffer.push({ edge, direction: 'next' });
      }
    });

    prevEdges.forEach((edge) => {
      if (!isDuplicate(visited, edge.id)) {
        buffer.push({ edge, direction: 'prev' });
      }
    });

    if (!isDuplicate(result, current.edge.id)) {
      result.push(current.edge);
    }
  }

  return result;
};

export const highlightedEdges = (edges: CustomEdge[], nodeId: string, column: string) => {
  // first get edges from column
  const edgesFromColumn = edges
    .filter((edge) => edge.id.includes(buildChosenColumnName(nodeId, column, 'source')))
    .map((edge) => ({ edge, direction: 'next' as const }));

  const edgesToColumn = edges
    .filter((edge) => edge.id.includes(buildChosenColumnName(nodeId, column, 'target')))
    .map((edge) => ({ edge, direction: 'prev' as const }));

  // if column does not have an edge from it, we will not highlight anything
  if (edgesFromColumn.length === 0 && edgesToColumn.length === 0) {
    return [];
  }

  return goThroughGraph([...edgesFromColumn, ...edgesToColumn], edges);
};

const isDuplicateColumn = (
  arr: { column: string; nodeId: string }[],
  column: string,
  nodeId: string,
) => {
  return arr.some((item) => item.column === column && item.nodeId === nodeId);
};

export const calculateHighlightedColumns = (
  selectedEdges: CustomEdge[],
  nodeIdOfSelectedColumn: string,
) => {
  return selectedEdges.reduce<{ column: string; nodeId: string }[]>((acc, edge) => {
    const sourceColumn = parseHandleName(edge?.sourceHandle ?? '');
    const targetColumn = parseHandleName(edge?.targetHandle ?? '');

    if (
      !isDuplicateColumn(acc, sourceColumn, edge.source) &&
      edge.source !== nodeIdOfSelectedColumn // prevents adding initially selected column to result and moving it to first page
    ) {
      acc.push({
        column: sourceColumn,
        nodeId: edge.source,
      });
    }

    if (
      !isDuplicateColumn(acc, targetColumn, edge.target) &&
      edge.target !== nodeIdOfSelectedColumn // prevents adding initially selected column to result and moving it to first page
    ) {
      acc.push({
        column: targetColumn,
        nodeId: edge.target,
      });
    }

    return acc;
  }, []);
};
