import React, { useEffect } from 'react';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import contactSupport from '@/utils/contactSupport';

export const ErrorBoundaryFallback = () => {
  useEffect(() => {
    ApplicationActionCreators.sendNotification({
      type: 'error',
      id: 'error-boundary-fallback',
      message: () => (
        <>
          Unable to load a section of the application. Refresh the page to resolve the issue. If the
          problem persists, please contact our support.
        </>
      ),
      button: {
        label: 'Contact support',
        action: () => contactSupport(),
      },
    });
  }, []);

  return null;
};
