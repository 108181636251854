import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import { matchByWords } from '@/utils';

const ConfigurationSelect = createReactClass({
  propTypes: {
    folders: PropTypes.instanceOf(Map).isRequired,
    component: PropTypes.object.isRequired,
    onReset: PropTypes.func.isRequired,
    onConfigurationSelect: PropTypes.func.isRequired,
    query: PropTypes.string.isRequired,
    orchestrationId: PropTypes.string,
  },

  render() {
    return (
      <>
        <div className="flex-container">
          <h2 className="mt-1 mb-1">
            <ComponentIcon
              component={this.props.component}
              className="icon-addon-right"
              size="32"
            />
            <ComponentName component={this.props.component} showType />
          </h2>
          <Button bsStyle="link" className="btn-link-inline" onClick={this.props.onReset}>
            <FontAwesomeIcon icon="chevron-left" className="icon-addon-right" />
            Back
          </Button>
        </div>
        {this.renderConfigurations()}
      </>
    );
  },

  renderConfigurations() {
    const filteredConfigurations = this._getFilteredConfigurations();
    const folders = this.props.folders.get(this.props.component.get('id'), Map());

    if (filteredConfigurations.isEmpty()) {
      return <p>No configuration found.</p>;
    }

    return (
      <div className="list-group">
        {filteredConfigurations
          .sort((a, b) => {
            const folderA = folders.get(a.get('id'));
            const folderB = folders.get(b.get('id'));

            if (folderA && !folderB) return -1;
            if (!folderA && folderB) return 1;

            return `${folderA} ${a.get('name')}`.localeCompare(`${folderB} ${b.get('name')}`);
          })
          .map((config) => {
            return (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key={config.get('id')}
                className="list-group-item flex-container"
                onClick={() => this.props.onConfigurationSelect(config)}
              >
                <span>
                  <span className="font-medium">{config.get('name')}</span>
                  {folders.has(config.get('id')) && (
                    <div className="f-11 text-muted">
                      <FontAwesomeIcon icon="folder" fixedWidth /> {folders.get(config.get('id'))}
                    </div>
                  )}
                </span>
                <FontAwesomeIcon icon="circle-plus" />
              </a>
            );
          })
          .toArray()}
      </div>
    );
  },

  _getFilteredConfigurations() {
    const configs = this.props.component
      .get('configurations', Map())
      .delete(this.props.orchestrationId);

    if (!this.props.query) {
      return configs;
    }

    return configs.filter((config) => {
      const folder = this.props.folders.getIn(
        [this.props.component.get('id'), config.get('id')],
        '',
      );

      return matchByWords([folder, config.get('name'), config.get('id')], this.props.query);
    });
  },
});

export default ConfigurationSelect;
