import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { List, Map, Set } from 'immutable';

import { Tooltip } from '@keboola/design';

import { sortRowsByName } from '@/modules/configurations/utils/helpers';
import Checkbox from '@/react/common/Checkbox';
import LazyList from '@/react/common/LazyList';
import Loader from '@/react/common/Loader';
import Sortable from '@/react/common/Sortable';
import SortByName from '@/react/common/SortByName';
import string from '@/utils/string';
import ConfigurationRowsTableRow from './ConfigurationRowsTableRow';
import { RemoveSelectedRowsModal } from './RemoveSelectedRowsModal';
import { RunSelectedRowsModal } from './RunSelectedRowsModal';

const ConfigurationRowsTable = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    rows: PropTypes.instanceOf(List).isRequired,
    config: PropTypes.instanceOf(Map).isRequired,
    component: PropTypes.instanceOf(Map).isRequired,
    columns: PropTypes.object.isRequired,
    rowDelete: PropTypes.func.isRequired,
    rowEnableDisable: PropTypes.func.isRequired,
    rowDeletePending: PropTypes.func.isRequired,
    rowEnableDisablePending: PropTypes.func.isRequired,
    rowLinkTo: PropTypes.string.isRequired,
    onOrder: PropTypes.func.isRequired,
    orderPending: PropTypes.object.isRequired,
    disabledMove: PropTypes.bool.isRequired,
    isRowConfiguration: PropTypes.bool.isRequired,
    getRowRunParams: PropTypes.func,
  },

  getInitialState() {
    return {
      selected: Set(),
      showRunModal: false,
      showDeleteModal: false,
      sort: null,
    };
  },

  render() {
    return (
      <>
        <LazyList
          rootElement="div"
          className="table table-hover"
          items={this.props.rows}
          render={this.renderTable}
        />
        <RunSelectedRowsModal
          show={this.state.showRunModal}
          onHide={() => this.setState({ showRunModal: false })}
          componentId={this.props.component.get('id')}
          configId={this.props.config.get('id')}
          selected={this.state.selected.toArray()}
        />
        <RemoveSelectedRowsModal
          show={this.state.showDeleteModal}
          onHide={() => this.setState({ showDeleteModal: false })}
          onDone={() => this.setState({ selected: Set() })}
          componentId={this.props.component.get('id')}
          configId={this.props.config.get('id')}
          selected={this.state.selected.toArray()}
          rows={this.props.rows}
        />
      </>
    );
  },

  renderTable(rows) {
    const selectedCount = this.state.selected.count();
    const isAllSelected = selectedCount === this.props.rows.count();
    const isSomeSelected = selectedCount > 0 && selectedCount !== this.props.rows.count();

    return (
      <div className="table table-hover">
        <div className="thead">
          <div className="tr">
            {!this.props.readOnly && this.props.isRowConfiguration ? (
              <>
                <span className="th with-row-sort-handle w-52 pr-0">
                  <Tooltip
                    placement="top"
                    tooltip={`${isAllSelected || isSomeSelected ? 'Deselect' : 'Select'} all rows`}
                  >
                    <Checkbox
                      checked={isAllSelected}
                      onChange={(checked) =>
                        this.setState(() => ({
                          selected: !checked
                            ? Set()
                            : this.props.rows.map((row) => row.get('id')).toSet(),
                        }))
                      }
                      indeterminate={isSomeSelected && !isAllSelected}
                    />
                  </Tooltip>
                </span>
                <span className="th pl-0 w-250">
                  <div className="flex-container flex-start">
                    {!selectedCount ? (
                      <SortByName
                        allowReset
                        sortBy={this.state.sort}
                        onClick={(sort) => this.setState({ sort })}
                      />
                    ) : (
                      <strong>
                        {selectedCount} {string.pluralize(selectedCount, 'row')} selected
                      </strong>
                    )}
                    {selectedCount > 0 && (
                      <div className="table-action-buttons">
                        <Tooltip placement="top" tooltip="Run Selected">
                          <Button
                            bsStyle="link"
                            className="text-muted"
                            onClick={() => this.setState({ showRunModal: true })}
                          >
                            <FontAwesomeIcon icon="play-circle" />
                          </Button>
                        </Tooltip>
                        <Tooltip placement="top" tooltip="Delete Selected">
                          <Button
                            bsStyle="link"
                            className="text-muted"
                            onClick={() => this.setState({ showDeleteModal: true })}
                            disabled={this.state.isDeleting}
                          >
                            {this.state.isDeleting ? (
                              <Loader />
                            ) : (
                              <FontAwesomeIcon icon="trash" fixedWidth />
                            )}
                          </Button>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </span>
              </>
            ) : (
              <span className="th w-250">Name</span>
            )}
            {this.props.columns.map((columnDefinition, index) => (
              <span className="th" key={index}>
                {columnDefinition.get('name')}
              </span>
            ))}
            <span className="th" />
          </div>
        </div>
        <Sortable
          className="tbody"
          options={{ disabled: this.props.readOnly || !this.props.isRowConfiguration }}
          onChange={(order, event) => {
            const allRowsOrder = [
              ...order,
              ...this.props.rows
                .map((row) => row.get('id'))
                .toArray()
                .slice(order.length),
            ];

            return this.props.onOrder(allRowsOrder, order[event.newIndex]);
          }}
        >
          {this.renderTableRows(rows)}
        </Sortable>
      </div>
    );
  },

  renderTableRows(rows) {
    return rows
      .sort(sortRowsByName(this.state.sort))
      .map((row) => {
        const thisRowOrderPending = this.props.orderPending.get(row.get('id'), false);
        const rowsOrderPending = this.props.orderPending.count() > 0;
        const isSelected = this.state.selected.has(row.get('id'));

        return (
          <ConfigurationRowsTableRow
            key={row.get('id')}
            row={row}
            columns={this.props.columns}
            config={this.props.config}
            component={this.props.component}
            linkTo={this.props.rowLinkTo}
            isSelected={isSelected}
            toggleSelected={() => {
              this.setState({
                selected: isSelected
                  ? this.state.selected.delete(row.get('id'))
                  : this.state.selected.add(row.get('id')),
              });
            }}
            isDeletePending={this.props.rowDeletePending(row.get('id'))}
            onDelete={() => this.props.rowDelete(row.get('id'))}
            isEnableDisablePending={this.props.rowEnableDisablePending(row.get('id'))}
            onEnableDisable={() => this.props.rowEnableDisable(row.get('id'))}
            disabledMove={this.props.disabledMove || rowsOrderPending || !!this.state.sort}
            orderPending={thisRowOrderPending}
            readOnly={this.props.readOnly}
            isRowConfiguration={this.props.isRowConfiguration}
            {...(this.props.getRowRunParams && { runParams: this.props.getRowRunParams(row) })}
          />
        );
      })
      .toArray();
  },
});

export default ConfigurationRowsTable;
