import {
  KDS_TEAM_WR_EXASOL,
  KDS_TEAM_WR_HIVE_CSAS,
  KEBOOLA_WR_LOOKER_V2,
} from '@/constants/componentIds';
import string from '@/utils/string';
import { getRequiredFields } from './credentialsFields';

export default (componentId, credentials) => {
  if (!credentials || !credentials.count()) {
    return false;
  }

  if (componentId === KEBOOLA_WR_LOOKER_V2 && credentials.get('driver') === 'bigquery') {
    return !!credentials.get('dataset') && !!credentials.get('service_account');
  }

  let fields = getRequiredFields(componentId);

  if (componentId === KDS_TEAM_WR_EXASOL) {
    fields = credentials.has('#refresh_token')
      ? fields.filter((field) => field.name !== '#password')
      : fields.filter((field) => field.name !== '#refresh_token');
  }

  if (componentId === KDS_TEAM_WR_HIVE_CSAS) {
    if (credentials.get('authType') === 'kerberos') {
      fields = fields.filter((field) => !['user', '#password'].includes(field.name));
      credentials = credentials.flatten();
    } else {
      fields = fields.filter(
        (field) =>
          !['kinitPrincipal', 'servicePrincipal', 'config', '#keytab'].includes(field.name),
      );
    }
  }

  return fields.every((field) => {
    const hasValue = !!credentials.get(field.name, '')?.toString() ?? false;

    if (field.name.startsWith('#')) {
      const hasFallbackValue = !!credentials.get(string.ltrim(field.name, '#'), '').toString();
      return hasValue || hasFallbackValue;
    }

    return hasValue;
  });
};
