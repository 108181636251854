import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS, List, Map, OrderedSet } from 'immutable';

import { Alert, Badge, cn, TextInput } from '@keboola/design';

import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import RoutesStore from '@/stores/RoutesStore';
import PromptInput from './AI/PromptInput';
import { DirectoryItemSkeleton } from './Skeleton/DirectoryItemSkeleton';
import Checkbox from './Checkbox';
import { SORT } from './constants';
import LazyList from './LazyList';
import NoResultsFound from './NoResultsFound';
import SortSelect from './SortSelect';

const NEW_CATEGORIES = ['Data Apps'];

class Directory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filters: fromJS({
        sortBy: props.supportedSortOptions.includes(SORT.POPULAR) ? SORT.POPULAR : SORT.A_Z,
      }),
    };
  }

  componentDidMount() {
    const filters = RoutesStore.getRouterState().getIn(['location', 'query'], Map());

    if (!filters.isEmpty()) {
      this.setState({
        filters: Map({
          q: filters.get('q', ''),
          types: List().concat(filters.get('types', List())),
          categories: List().concat(filters.get('categories', List())),
          ...(filters.get('sortBy') && { sortBy: filters.get('sortBy') }),
        }),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.filters.equals(this.state.filters)) {
      RoutesStore.getRouter().updateQuery({
        ...this.state.filters.toJS(),
      });
    }
  }

  render() {
    const { items, categories, types, hasAiSuggestedComponents } = this.props.getItems(
      this.state.filters,
    );

    return (
      <div className="tw-grid tw-grid-cols-4 tw-items-start tw-gap-8">
        {this.props.withAiSearch && (
          <div className="tw-col-span-full">
            <PromptInput
              autoFocus
              placeholder={`Search specific ${this.props.entity} or write your usecase`}
              prompt={this.state.filters.get('q', '')}
              onChange={(query) => {
                this.setState({ filters: this.state.filters.set('q', query) });
                this.props.onChange?.(query);
              }}
              className="tw-mb-1"
            />
            <span className="tw-text-xs tw-text-neutral-400">
              e.g. I need to download data from marketing campaigns
            </span>
          </div>
        )}
        <div className="directory-filter is-sticky">
          {!this.props.withAiSearch && (
            <TextInput
              autoFocus
              value={this.state.filters.get('q', '')}
              placeholder={`Search ${this.props.entity}`}
              allowClear
              onChange={(query) => {
                this.setState({ filters: this.state.filters.set('q', query) });
              }}
              prefix={<FontAwesomeIcon className="tw-text-neutral-400" icon="magnifying-glass" />}
            />
          )}
          {this.props.types?.count() && (
            <>
              <h3 className="f-14 first:tw-mt-0">Types</h3>
              {this.props.types.map((type) => {
                const checked = this.state.filters.get('types', List()).includes(type);

                return (
                  <Checkbox
                    className="block"
                    key={type}
                    checked={checked}
                    onChange={() => {
                      this.setState({
                        filters: checked
                          ? this.state.filters.update('types', (types) => {
                              return types.filter((selected) => selected !== type);
                            })
                          : this.state.filters.update('types', List(), (types) => types.push(type)),
                      });
                    }}
                  >
                    <div className="flex-container text-muted font-medium">
                      <span
                        className={cn({
                          'color-primary': this.state.filters.get('types', List()).includes(type),
                        })}
                      >
                        {getNewComponentTypeLabel(type)}
                      </span>
                      <span>{types.count((item) => item.get('type') === type)}</span>
                    </div>
                  </Checkbox>
                );
              })}
            </>
          )}
          {this.props.categories?.count() && (
            <>
              <h3 className="f-14">Categories</h3>
              {this.props.categories.sort().map((category) => {
                const checked = this.state.filters.get('categories', List()).includes(category);

                return (
                  <Checkbox
                    className="block"
                    key={category}
                    checked={checked}
                    onChange={() => {
                      this.setState({
                        filters: checked
                          ? this.state.filters.update('categories', (categories) => {
                              return categories.filter((selected) => selected !== category);
                            })
                          : this.state.filters.update('categories', List(), (categories) =>
                              categories.push(category),
                            ),
                      });
                    }}
                  >
                    <div className="flex-container text-muted font-medium">
                      <span
                        className={cn({
                          'color-primary': this.state.filters
                            .get('categories', List())
                            .includes(category),
                        })}
                      >
                        {category}
                        {NEW_CATEGORIES.includes(category) && (
                          <Badge text="NEW" variant="blue" placement="right" />
                        )}
                      </span>
                      <span>
                        {categories.count((item) => {
                          return item.get('categories', List()).includes(category);
                        })}
                      </span>
                    </div>
                  </Checkbox>
                );
              })}
            </>
          )}
        </div>
        <div className="tw-col-span-3">
          <div className="flex-container mb-1">
            <h2 className="f-24 m-0">
              {!this.state.filters.get('q') &&
              this.state.filters.get('types', List()).isEmpty() &&
              this.state.filters.get('categories', List()).isEmpty()
                ? `All ${this.props.entity}s`
                : `${this.props.entity}s`}
              {(!items.isEmpty() || (this.props.withAiSearch && !this.props.aiSearch?.isLoading)) &&
                ` (${items.count()})`}
            </h2>
            {!!this.props.supportedSortOptions?.length && (
              <SortSelect
                value={this.state.filters.get('sortBy')}
                onChange={(sortBy) =>
                  this.setState({
                    filters: this.state.filters.set('sortBy', sortBy),
                  })
                }
                supportedOptions={this.props.supportedSortOptions}
              />
            )}
          </div>
          {this.props.withAiSearch &&
            items.count() === 1 &&
            this.props.aiSearch?.isFallbackComponentUsed && (
              <Alert className="tw-mb-4">
                There&apos;s no available component for the mentioned use case. We recommend using
                generic component.
              </Alert>
            )}
          <LazyList items={items} render={this.renderItems} />
          {this.props.withAiSearch && (
            <>
              {this.props.aiSearch?.isLoading ? (
                <div className={cn('tw-text-center', items.isEmpty() ? 'tw-mt-0' : 'tw-mt-6')}>
                  <span className="tw-text-xs tw-font-medium tw-text-neutral-400">
                    Keep tight, AI is thinking...
                  </span>
                  <div className="box-container two-columns !tw-mt-4">
                    <DirectoryItemSkeleton />
                    <DirectoryItemSkeleton />
                  </div>
                </div>
              ) : (
                !items.isEmpty() &&
                !hasAiSuggestedComponents && (
                  <div className="tw-mt-6 tw-text-center">
                    <span className="tw-text-xs tw-font-medium tw-text-neutral-400">
                      The AI didn&apos;t find any additional components to use.
                    </span>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  renderItems = (items) => {
    if (items.isEmpty() && this.props.withAiSearch && this.props.aiSearch?.isLoading) return;

    if (items.isEmpty()) {
      return <NoResultsFound entityName={this.props.entity.toLowerCase()} />;
    }

    return (
      <div className="box-container two-columns">
        {items
          .map((item) => this.props.renderItem(item, this.state.filters.get('q', '')))
          .toArray()}
      </div>
    );
  };
}

Directory.propTypes = {
  entity: PropTypes.string.isRequired,
  getItems: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  categories: PropTypes.instanceOf(OrderedSet),
  types: PropTypes.instanceOf(List),
  supportedSortOptions: PropTypes.array,
  aiSearch: PropTypes.shape({
    components: PropTypes.instanceOf(List),
    isFallbackComponentUsed: PropTypes.bool,
    isLoading: PropTypes.bool,
  }),
  withAiSearch: PropTypes.bool,
  handleAiSearch: PropTypes.func,
  onChange: PropTypes.func,
};

export default Directory;
