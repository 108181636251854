import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { autoUpdate, offset, useFloating } from '@floating-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '@keboola/design';

import { HIDE_OTHER_ACTION_DROPDOWNS } from '@/constants/customEvents';
import ActionLoader from './ActionLoader';
import FastFade from './FastFade';

const MENU_TRANSITION_STYLES = { transition: 'transform 120ms ease-out' };

export const RowActionDropdown = (props: {
  children: React.ReactNode;
  toggleClassName?: string;
  showLoading?: boolean;
  loadingActionName?: string | null;
  inHeader?: boolean;
  inModal?: boolean;
}) => {
  const dropdownId = React.useId();
  const [wantsToOpen, setWantsToOpen] = React.useState(false);
  const [dropup, setDropup] = React.useState(false);

  const { refs, elements, update, floatingStyles } = useFloating({
    strategy: 'fixed',
    placement: dropup ? 'top-end' : 'bottom-end',
    middleware: [offset({ crossAxis: 8 })],
  });

  React.useEffect(() => {
    if (!wantsToOpen) {
      return;
    }

    const hide = () => setWantsToOpen(false);

    window.addEventListener(HIDE_OTHER_ACTION_DROPDOWNS, hide, { passive: true });
    return () => {
      window.removeEventListener(HIDE_OTHER_ACTION_DROPDOWNS, hide);
    };
  }, [wantsToOpen]);

  React.useEffect(() => {
    if (!wantsToOpen || !elements.reference || !elements.floating) {
      return;
    }

    const cleanup = autoUpdate(elements.reference, elements.floating, update, {
      animationFrame: true,
    });

    return () => {
      cleanup();
    };
  }, [wantsToOpen, elements, update]);

  const handleToggle = (isOpen: boolean, event: any, data: { source: string }) => {
    if (
      data.source === 'rootClose' &&
      event.composedPath?.().some((el: any) => el.id === dropdownId)
    ) {
      return;
    }

    // first hide all other dropdowns
    if (isOpen) {
      window.dispatchEvent(new Event(HIDE_OTHER_ACTION_DROPDOWNS));
    }

    // then decide about this one
    setWantsToOpen(isOpen);

    if (!isOpen) {
      return dropup && setDropup(false);
    }

    const rect = event?.target?.getBoundingClientRect();

    if (rect && rect.bottom > 400 && window.innerHeight * 0.65 < rect.bottom) {
      return setDropup(true);
    }
  };

  if (!props.children) {
    return null;
  }

  return (
    <span
      ref={refs.setReference}
      onClick={(event) => {
        // We have to catch this event and decide what to do with it, because
        // sometimes RowActionDropdown component is used within Anchor (e.g. for
        // configurations in generic Docker components).
        //
        // Note that all events will be caught, also clicks on menu items in
        // Dropdown.Menu.
        //
        // To make Anchors work, we have to check if event.target has href attr and
        // decide whether to "prevent default" or not, so Anchors will work.
        if (props.inModal || 'href' in event.target) {
          return;
        }

        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <Dropdown
        pullRight
        id={dropdownId}
        className="row-dropdown"
        dropup={dropup}
        open={wantsToOpen}
        onToggle={handleToggle}
        rootCloseEvent="mousedown"
      >
        <Dropdown.Toggle
          noCaret
          bsStyle={props.inHeader ? 'default' : 'link'}
          className={cn(props.toggleClassName, { '!tw-ml-3 tw-max-h-10': props.inHeader })}
        >
          {props.showLoading ? (
            <ActionLoader loadingActionName={props.loadingActionName} />
          ) : (
            <FontAwesomeIcon
              className="f-20"
              icon={props.inHeader ? ['fas', 'ellipsis-vertical'] : ['far', 'ellipsis']}
            />
          )}
        </Dropdown.Toggle>
        <LazyDropdownMenu
          bsRole="menu"
          inHeader={props.inHeader}
          floating={refs.floating}
          setFloating={refs.setFloating}
          floatingStyles={floatingStyles}
        >
          {props.children}
        </LazyDropdownMenu>
      </Dropdown>
    </span>
  );
};

const LazyDropdownMenu = React.forwardRef(
  ({ children, inHeader, floating, setFloating, floatingStyles, ...restProps }: any, ref) => {
    const [entered, setEntered] = React.useState(false);
    const [hasOverflow, setHasOverflow] = React.useState(false);

    const checkOverflow = React.useCallback(() => {
      let parent = floating.current?.parentElement;

      while (parent) {
        const overflowY = window.getComputedStyle(parent).getPropertyValue('overflow-y');

        if (['auto', 'scroll'].includes(overflowY)) {
          setHasOverflow(true);
          return;
        }

        parent = parent?.parentElement;

        if (parent?.tagName.toLowerCase() === 'body') {
          break;
        }
      }

      setHasOverflow(false);
    }, [floating]);

    return (
      <FastFade
        appear
        mountOnEnter
        in={!!restProps.open}
        onEnter={checkOverflow}
        onEntered={() => setEntered(true)}
        onExit={() => setEntered(false)}
      >
        <span
          ref={setFloating}
          {...(hasOverflow && {
            style: {
              zIndex: 11,
              ...floatingStyles,
              ...(entered && MENU_TRANSITION_STYLES),
            },
          })}
        >
          <Dropdown.Menu {...restProps} className={cn({ 'tw-mt-2': inHeader })} ref={ref}>
            {children}
          </Dropdown.Menu>
        </span>
      </FastFade>
    );
  },
);
