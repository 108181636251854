import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { cn } from '@keboola/design';

import { RouterLink } from '@/react/common';

const Notification = ({
  notification,
  onCancel,
  onMouseEnter,
  onMouseLeave,
}: {
  notification: Map<string, any>;
  onCancel: (id: string) => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) => {
  const handleCancel = React.useCallback(
    () => onCancel(notification.get('id')),
    [notification, onCancel],
  );

  React.useEffect(() => {
    if (!notification.get('timeout') || !!notification.get('paused')) {
      return;
    }

    const timeout = setTimeout(handleCancel, notification.get('timeout'));

    return () => {
      clearTimeout(timeout);
    };
  }, [notification, handleCancel]);

  const getIcon = () => {
    switch (notification.get('type')) {
      case 'success':
        return 'check-circle';

      case 'error':
        return 'exclamation-triangle';

      case 'warning':
        return 'exclamation-circle';

      default:
        return 'info-circle';
    }
  };

  const renderAction = () => {
    if (!notification.get('link') && !notification.get('button')) {
      return null;
    }

    if (notification.get('link')) {
      return notification.getIn(['link', 'download']) ? (
        <a
          download
          className="btn btn-sm btn-link notification-action"
          onClick={handleCancel}
          href={notification.getIn(['link', 'href'])}
        >
          {notification.getIn(['link', 'label'])}
        </a>
      ) : (
        <RouterLink
          className="btn btn-sm btn-link notification-action"
          onClick={handleCancel}
          to={notification.getIn(['link', 'to'])}
          params={notification.getIn(['link', 'params'])}
        >
          {notification.getIn(['link', 'label'])}
        </RouterLink>
      );
    }

    return (
      <Button
        bsSize="sm"
        bsStyle="link"
        className="notification-action"
        onClick={() => {
          notification.getIn(['button', 'action'])();
          handleCancel();
        }}
      >
        {notification.getIn(['button', 'label'])}
      </Button>
    );
  };
  const getProgressStyle = () => {
    const remain = Math.round(
      (notification.get('timeout') / notification.get('initialTimeout')) * 100,
    );

    if (notification.get('paused')) {
      return { width: `${remain}%` };
    }

    return {
      width: `${remain}%`,
      animationName: 'notification-progress',
      animationDuration: `${notification.get('timeout')}ms`,
      animationTimingFunction: 'linear',
      animationFillMode: 'forwards',
    };
  };

  return (
    <div
      className={cn(
        'notification tw-relative tw-flex tw-items-start tw-overflow-hidden tw-rounded-lg tw-py-3 tw-pl-4 tw-pr-1 tw-text-white',
        {
          'tw-bg-secondary-600': notification.get('type') === 'info',
          'tw-bg-primary-700': notification.get('type') === 'success',
          'tw-bg-error-550': notification.get('type') === 'error',
          'tw-bg-warning-550': notification.get('type') === 'warning',
        },
      )}
      {...(notification.get('timeout') && { onMouseEnter, onMouseLeave })}
    >
      <FontAwesomeIcon
        icon={getIcon()}
        className="tw-mb-0.5 tw-ml-0.5 tw-mr-3.5 tw-mt-2 tw-text-base"
      />
      <div className="tw-flex tw-max-h-[40vh] tw-flex-1 tw-flex-wrap tw-items-start tw-justify-between tw-overflow-auto">
        <span className="overflow-break-anywhere tw-my-1.5 tw-mr-3">
          {typeof notification.get('message') === 'string'
            ? notification.get('message')
            : React.createElement(notification.get('message'), { onClick: handleCancel })}
        </span>
        {renderAction()}
      </div>
      <Button
        bsSize="sm"
        bsStyle="link"
        className="notification-close tw-inline-flex tw-items-start tw-place-self-stretch tw-rounded-none !tw-py-1.5 !tw-pl-4 !tw-pr-3"
        onClick={handleCancel}
      >
        <FontAwesomeIcon icon="times" className="!tw-bottom-0 tw-inline-flex tw-h-5 tw-w-5" />
      </Button>
      <span
        className={cn('notification-progress tw-bac tw-absolute tw-bottom-0 tw-left-0 tw-h-0.5')}
        style={getProgressStyle()}
      />
    </div>
  );
};

export default Notification;
