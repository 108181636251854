import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { Tabs, TabsContent, type Trigger } from '@keboola/design';

import type { AutomationDetail } from '@/api/routes/aiService';
import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '@/constants/componentIds';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import FlowConfigs from '@/modules/flows/components/FlowConfigs';
import { prepareOrchestrations } from '@/modules/orchestrations-v2/helpers';
import { AiIcon } from '@/react/common/AI/svgGradient';
import LazyList from '@/react/common/LazyList';
import TimeAndUser from '@/react/common/TimeAndUser';
import { redirectToAutomation } from './actions';

const Automations = (props: {
  automations: AutomationDetail[];
  admins: Map<string, any>;
  currentAdmin: Map<string, any>;
  notifications: List<any>;
  triggers: Map<string, any>;
  latestJobs: Map<string, any>;
  allConfigurations: Map<string, any>;
  allComponents: Map<string, any>;
  componentsMetadata: Map<string, any>;
  sapiToken: Map<string, any>;
  hasProtectedDefaultBranch: boolean;
  hasFlows: boolean;
  readOnly: boolean;
}) => {
  const flowComponent = props.allConfigurations.get(KEBOOLA_ORCHESTRATOR, Map());
  const flowConfigurations = prepareOrchestrations(
    flowComponent.get('configurations', Map()),
    props.allConfigurations.get(KEBOOLA_SCHEDULER)?.get('configurations') || Map(),
  ).filter((configuration: Map<string, any>) => {
    const configurationMetadata = props.componentsMetadata.getIn(
      [KEBOOLA_ORCHESTRATOR, configuration.get('id')],
      List(),
    );

    return configurationMetadata.some(
      (row: Map<string, any>) => row.get('key') === MetadataKeys.CREATED_BY_AUTOMATION_ID,
    );
  });
  const renderAutomations = useCallback(
    (automations: AutomationDetail[]) =>
      automations.map((automation) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a key={automation.id} className="tr" onClick={() => redirectToAutomation(automation)}>
          <div className="td">{automation.name}</div>
          <div className="td text-right tw-w-72">
            <TimeAndUser
              showAvatar={false}
              admin={props.admins.get(automation.createdByToken?.id || '')}
              time={automation.createdAt || ''}
              fallbackName={automation.createdByToken?.name || ''}
            />
          </div>
        </a>
      )),
    [props.admins],
  );
  const triggers: Trigger<string>[] = [];

  if (props.automations.length) {
    triggers.push({
      title: (
        <div className="tw-flex tw-items-center tw-gap-2">
          <AiIcon className="group-[[data-state=inactive]:not(:hover)]/tab-trigger:[&_path]:tw-fill-neutral-400" />
          Automations
        </div>
      ),
      value: 'automations',
    });
  }
  if (!flowConfigurations.isEmpty()) {
    triggers.push({
      title: (
        <div className="tw-flex tw-items-center tw-gap-2">
          <FontAwesomeIcon icon="bars-staggered" />
          Generated Flows
        </div>
      ),
      value: 'flows',
    });
  }

  if (!triggers.length) return null;

  return (
    <Tabs triggers={triggers}>
      <TabsContent value="automations">
        <div className="box">
          <div className="table table-hover">
            <div className="thead">
              <div className="tr">
                <div className="th">Name</div>
                <div className="th text-right tw-w-72">Created</div>
              </div>
            </div>
            <LazyList items={props.automations} render={renderAutomations} className="tbody" />
          </div>
        </div>
      </TabsContent>
      <TabsContent value="flows">
        <div className="box">
          <FlowConfigs
            readOnly={props.readOnly}
            hasFlows={props.hasFlows}
            configurations={flowConfigurations}
            allConfigurations={props.allConfigurations}
            admins={props.admins}
            currentAdmin={props.currentAdmin}
            triggers={props.triggers}
            component={flowComponent}
            latestJobs={props.latestJobs}
            isSearching={false}
            allComponents={props.allComponents}
            notifications={props.notifications}
            sapiToken={props.sapiToken}
            hasProtectedDefaultBranch={props.hasProtectedDefaultBranch}
          />
        </div>
      </TabsContent>
    </Tabs>
  );
};

export default Automations;
