import React from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import { Alert, Link } from '@keboola/design';

import Api from '@/api';
import { type ExistingApp } from '@/api/routes/dataScienceService';
import { resetPassword } from '@/modules/data-apps/actions';
import { hasBasicAuthEnabled } from '@/modules/sandboxes/helpers';
import Confirm from '@/react/common/Confirm';
import CredentialsBox from '@/react/common/CredentialsBox';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';

type Props = {
  show: boolean;
  onHide: () => void;
  app: ExistingApp;
  config: Map<string, any>;
};

const CredentialsModal = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isResetting, setIsResetting] = React.useState(false);
  const [password, setPassword] = React.useState<string | null>(null);

  const hasBasicAuth = hasBasicAuthEnabled(props.config);

  const loadPassword = React.useCallback(() => {
    setIsLoading(true);
    return Api.dataScienceService
      .getAppPassword({ appId: props.app.id })
      .then((data) => setPassword(data.password))
      .finally(() => setIsLoading(false));
  }, [props.app.id]);

  const handleReset = React.useCallback(() => {
    setIsResetting(true);
    return resetPassword(props.app.id)
      .then(() => loadPassword())
      .finally(() => setIsResetting(false));
  }, [props.app.id, loadPassword]);

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onEnter={() => {
        if (!hasBasicAuth || password) {
          return;
        }

        loadPassword();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Data App Credentials</Modal.Title>
        <ModalIcon icon="user" color="green" bold />
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <>
            <Loader /> Loading data...
          </>
        ) : (
          <>
            <CredentialsBox
              noBorder
              rows={Map({
                Host: props.app.url,
                ...(hasBasicAuth && { Password: { protected: true, text: password } }),
              })}
            />
            {hasBasicAuth && (
              <Alert className="tw-mt-4">
                To reset password{' '}
                <Confirm
                  icon="key"
                  buttonType="primary"
                  title="Reset Data App Password"
                  text="Are you sure you want to reset the data app password?"
                  buttonLabel={isResetting ? 'Resetting...' : 'Reset'}
                  onConfirm={handleReset}
                  isLoading={isResetting}
                  closeAfterResolve
                >
                  <button className="btn btn-link btn-link-inline">click here</button>
                </Confirm>
                .
              </Alert>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Link href={props.app.url} className="btn btn-block btn-success">
          <FontAwesomeIcon icon="circle-play" className="icon-addon-right" fixedWidth />
          Open Data App
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default CredentialsModal;
