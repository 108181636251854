import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { fromJS, Map } from 'immutable';

import { Alert } from '@keboola/design';

import { isVatIdValid, parseBillingInformation } from '@/modules/billing/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import { HTTP_STATUS_CODE_BAD_REQUEST } from '@/utils/errors/helpers';
import HttpError from '@/utils/errors/HttpError';
import isEmailValid from '@/utils/isEmailValid';
import BillingEditFields from './BillingEditFields';

const INITIAL_DATA = {
  isProcessing: false,
  tempData: Map(),
  error: null,
};

class BillingEditModal extends React.Component {
  state = INITIAL_DATA;

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => this.setState(this.getInitData())}
      >
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              Edit Billing Details
              <ModalIcon icon="file-invoice" color="blue" bold />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.error && (
              <Alert variant="error" className="tw-mb-5">
                {this.state.error}
              </Alert>
            )}
            <BillingEditFields
              state={this.state.tempData}
              setState={(tempData, error) =>
                this.setState({ tempData, ...(typeof error !== 'undefined' && { error }) })
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={this.state.isProcessing ? 'Saving changes...' : 'Save Changes'}
              isSaving={this.state.isProcessing}
              isDisabled={this.isDisabled()}
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  isDisabled = () => {
    if (!isEmailValid(this.state.tempData.get('email'))) {
      return true;
    }

    if (
      !isVatIdValid(this.state.tempData.get('vatId'), this.state.tempData.get('addressCountry'))
    ) {
      return true;
    }

    return (
      !this.state.tempData.get('addressCountry') ||
      !this.state.tempData.get('addressLine1') ||
      !this.state.tempData.get('addressCity') ||
      !this.state.tempData.get('addressPostalCode') ||
      this.state.tempData.equals(this.getInitData().tempData)
    );
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ isProcessing: true });
    this.props
      .onSubmit(this.state.tempData.toJS())
      .then(() => {
        this.props.onHide();
        this.setState(INITIAL_DATA);
      })
      .catch(HttpError, (error) => {
        if (error.response?.status === HTTP_STATUS_CODE_BAD_REQUEST) {
          return this.setState({ error: error.message });
        }

        throw error;
      })
      .finally(() => this.setState({ isProcessing: false }));
  };

  getInitData = () => {
    return { tempData: fromJS(parseBillingInformation(this.props.billing)) };
  };
}

BillingEditModal.propTypes = {
  billing: PropTypes.instanceOf(Map).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default BillingEditModal;
