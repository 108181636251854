import React, { useState } from 'react';

import type { AlertProps } from '@keboola/design';
import { Alert } from '@keboola/design';

import { COLLAPSED_ALERTS } from '@/constants/localStorageKeys';
import { getItem, setItem } from '@/utils/localStorage';

const CollapsibleAlert = ({ id, ...props }: { id: string } & AlertProps) => {
  const [isOpen, setIsOpen] = useState(!getItem(`${COLLAPSED_ALERTS}-${id}`, false));

  const handleToggle = (open: boolean) => {
    setIsOpen(open);
    setItem(`${COLLAPSED_ALERTS}-${id}`, !open);
  };

  return (
    <Alert collapsible onToggle={handleToggle} isOpen={isOpen} {...props}>
      {props.children}
    </Alert>
  );
};

export default CollapsibleAlert;
