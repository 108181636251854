import React from 'react';
import type { HTMLAttributes } from 'react';

import { cn } from '../../utils';

import { CardContent } from './CardContent';
import { CardHeader } from './CardHeader';
import { CardTitle } from './CardTitle';
import { cardClassnames } from './styles';

type Props = HTMLAttributes<HTMLDivElement>;

export const Card = ({ children, className, ...rest }: Props) => (
  <div className={cn(cardClassnames, className)} {...rest}>
    {children}
  </div>
);

Card.Header = CardHeader;
Card.Content = CardContent;
Card.Title = CardTitle;
