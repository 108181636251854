import { fromJS, List, Map, Range } from 'immutable';

import dayjs from '@/date';
import { resolveRouterLinkParams } from '@/modules/components/helpers';
import { routeNames as componentsRouteNames } from '@/modules/components-directory/constants';
import { routeNames as flowsRouteNames } from '@/modules/flows/constants';
import { routeNames as templatesRouteNames } from '@/modules/templates/constants';
import string from '@/utils/string';
import { ACTIVITY_TYPES, DEV_BRANCH_EVENTS, STORAGE_EVENTS } from './constants';

const createQueryToGetActivities = () => {
  const branchEvents = Object.values(DEV_BRANCH_EVENTS)
    .map((event) => `event:${event}`)
    .join(' OR ');

  const events = Object.values(STORAGE_EVENTS)
    .map((event) => `event:${event}`)
    .join(' OR ');

  return `${branchEvents} OR (NOT _exists_:runId AND (${events})) AND -objectName:\\[_internal\\]`;
};

const splitIntoChunks = (list, size = 6) => {
  return Range(0, list.count(), size).map((start) => list.slice(start, start + size));
};

const injectLastSeenRow = (activity, lastSeen) => {
  const activityCount = activity.count();
  const lastSeenIndex = activity.findIndex((row, index, collection) => {
    if (
      index !== activityCount - 1 &&
      dayjs(row.get('date')).isAfter(lastSeen) &&
      dayjs(collection.getIn([index + 1, 'date'])).isBefore(lastSeen)
    ) {
      return true;
    }

    return false;
  });

  if (lastSeenIndex !== -1) {
    return activity.insert(lastSeenIndex + 1, fromJS({ type: 'last-seen' }));
  }

  return activity;
};

const getCreatedTime = (item) => {
  return item.get('created', item.get('createdTime'));
};

const getActiveUsers = (admins, activities) => {
  return activities
    .filter(
      (event) => event.get('type') !== ACTIVITY_TYPES.JOB || event.getIn(['data', 'createdTime']),
    )
    .groupBy((event) => {
      if (event.get('type') === ACTIVITY_TYPES.CONFIGURATION) {
        return event.getIn(['data', 'token', 'name']);
      }
      return event.getIn(['data', 'token', 'description']);
    })
    .filter((activities, creator) => admins.has(creator))
    .map((activities) =>
      activities
        .sortBy((event) => -new Date(getCreatedTime(event.get('data'))).valueOf())
        .map((event) => event.get('data')),
    )
    .sortBy((event) => {
      return -new Date(getCreatedTime(event.first()).valueOf());
    });
};

const moveUserToFirstPlaceByModifyingActivity = (activities, email) => {
  return activities
    .set(
      email,
      fromJS([
        {
          createdTime: new Date().toISOString(),
        },
      ]),
    )
    .sortBy((activities, creator) => (email === creator ? -1 : 0));
};

const groupSameActivity = (activity) => {
  return activity.reduce((accum, row, index) => {
    if (
      index === 0 ||
      row.get('type') === ACTIVITY_TYPES.JOB ||
      row.getIn(['data', 'event']) !== accum.last().getIn(['data', 'event'])
    ) {
      return accum.push(row);
    }

    const lastParams = accum.last().getIn(['data', 'params']);

    if (
      row.getIn(['data', 'event']) === STORAGE_EVENTS.EDITED &&
      row.getIn(['data', 'params', 'configurationId']) === lastParams.get('configurationId')
    ) {
      return accum.updateIn([accum.count() - 1, 'count'], (count) => count + 1);
    }

    if (
      row.getIn(['data', 'event']) === STORAGE_EVENTS.ROW_EDITED &&
      row.getIn(['data', 'params', 'configuration', 'configurationId']) ===
        lastParams.getIn(['configuration', 'configurationId']) &&
      row.getIn(['data', 'params', 'rowId']) === lastParams.get('rowId')
    ) {
      return accum.updateIn([accum.count() - 1, 'count'], (count) => count + 1);
    }

    return accum.push(row);
  }, List());
};

const groupSameActivityForLatestEdits = (activity) => {
  return activity
    .sortBy((event) => -1 * new Date(event.getIn(['date'])))
    .reduce((accum, row) => {
      const currentEvent = row.getIn(['data', 'event']);
      const currentParams = row.getIn(['data', 'params']);

      if (
        [
          STORAGE_EVENTS.PURGED,
          STORAGE_EVENTS.DELETED,
          STORAGE_EVENTS.COPIED,
          STORAGE_EVENTS.CREATED,
          STORAGE_EVENTS.EDITED,
          STORAGE_EVENTS.RESTORED,
          STORAGE_EVENTS.ROLLBACK,
        ].includes(currentEvent) &&
        !accum.has(currentParams.get('configurationId'))
      ) {
        return accum.set(currentParams.get('configurationId'), row);
      }

      if (
        [
          STORAGE_EVENTS.ROW_COPIED,
          STORAGE_EVENTS.ROW_CREATED,
          STORAGE_EVENTS.ROW_DELETED,
          STORAGE_EVENTS.ROW_EDITED,
          STORAGE_EVENTS.ROW_ROLLBACK,
        ].includes(currentEvent) &&
        !accum.has(currentParams.getIn(['configuration', 'configurationId']))
      ) {
        return accum.set(currentParams.getIn(['configuration', 'configurationId']), row);
      }

      return accum;
    }, Map());
};

const isValidTarget = (target) => {
  return !!mapTargetToRoute(target);
};

const isValidCampaignTarget = (target) => {
  return isValidTarget(target) && target.startsWith('template');
};

const getCampaignTarget = (target) => {
  return target.includes(':') ? string.strRight(target, ':') : target;
};

const mapTargetToRoute = (target) => {
  if (target?.startsWith('template:')) {
    return {
      name: templatesRouteNames.TEMPLATE_DETAIL,
      params: { templateId: string.strRight(target, ':') },
    };
  }

  if (target?.startsWith('component:')) {
    const linkParams = resolveRouterLinkParams(string.strRight(target, ':'));

    if (!linkParams) {
      return null;
    }

    return { name: linkParams.to, params: linkParams.params };
  }

  if (target === 'flows') {
    return {
      name: flowsRouteNames.ROOT,
    };
  }

  if (target === 'templates') {
    return {
      name: templatesRouteNames.TEMPLATES,
    };
  }

  if (target === 'components') {
    return {
      name: componentsRouteNames.ROOT,
    };
  }

  return null;
};

export {
  createQueryToGetActivities,
  getActiveUsers,
  getCampaignTarget,
  getCreatedTime,
  groupSameActivity,
  groupSameActivityForLatestEdits,
  injectLastSeenRow,
  isValidCampaignTarget,
  isValidTarget,
  mapTargetToRoute,
  moveUserToFirstPlaceByModifyingActivity,
  splitIntoChunks,
};
