import React from 'react';
import type { Map } from 'immutable';
import _ from 'underscore';

import { cn } from '@keboola/design';

import { canManageDevBranch } from '@/modules/admin/privileges';
import { isMergeRequestApproved, isMergeRequestInReview } from '@/modules/dev-branches/helpers';

const DevModeBar = (props: {
  sapiToken: Map<string, any>;
  currentDevBranch: Map<string, any>;
  mergeRequest: Map<string, any>;
  hasProtectedDefaultBranch: boolean;
}) => {
  const [showLabel, setShowLabel] = React.useState(false);

  React.useEffect(() => {
    const handleScroll = _.throttle(() => setShowLabel(window.scrollY > 0), 100);

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [props.hasProtectedDefaultBranch]);

  if (props.currentDevBranch.isEmpty() && !props.hasProtectedDefaultBranch) {
    return null;
  }

  let label = 'Dev Branch';
  let subLabel = '';

  if (isMergeRequestInReview(props.mergeRequest) || isMergeRequestApproved(props.mergeRequest)) {
    subLabel = isMergeRequestApproved(props.mergeRequest) ? 'Ready to merge' : 'In review';
  }

  if (props.currentDevBranch.isEmpty()) {
    label = 'Production';

    if (canManageDevBranch(props.sapiToken)) {
      subLabel = 'If you want to make changes, You have to create a separate branch.';
    }
  }

  return (
    <div
      id="dev-mode-bar"
      className={cn({
        'in-branch': !props.currentDevBranch.isEmpty(),
        'in-review': isMergeRequestInReview(props.mergeRequest),
        'is-approved': isMergeRequestApproved(props.mergeRequest),
        static: props.hasProtectedDefaultBranch && !showLabel,
      })}
    >
      <span
        className={cn('dev-mode-label', {
          active: props.hasProtectedDefaultBranch || showLabel,
        })}
      >
        <span className="font-medium">{label}</span>
        {subLabel && <span> - {subLabel}</span>}
      </span>
    </div>
  );
};

export default DevModeBar;
