import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

const ComponentEmptyState = createReactClass({
  propTypes: {
    children: PropTypes.node,
  },
  render() {
    return <div className="tw-justify-items-center tw-text-center">{this.props.children}</div>;
  },
});

export default ComponentEmptyState;
