import React from 'react';
import { Modal } from 'react-bootstrap';

import { cn } from '@keboola/design';

import BlockButton from '@/react/common/BlockButton';

export const ConfigRowsInfo = (props: { children: React.ReactNode }) => {
  const [showAll, setShowAll] = React.useState(false);

  const rows = React.Children.toArray(props.children);
  const hasMoreRows = rows.length > 2;

  return (
    <>
      <div className="tw-relative tw-mb-2">
        <div
          className={cn('tw-flex tw-max-h-20 tw-flex-col tw-gap-1', {
            "tw-overflow-y-hidden after:tw-pointer-events-none after:tw-absolute after:tw-bottom-0 after:tw-h-5 after:tw-w-full after:tw-bg-gradient-to-b after:tw-from-transparent after:tw-to-white after:tw-content-['']":
              hasMoreRows,
          })}
        >
          {rows.slice(0, 3)}
        </div>
      </div>
      {hasMoreRows && (
        <>
          <BlockButton
            label="Show All"
            className="-tw-mb-3.5 -tw-ml-3.5 -tw-mr-3.5 tw-p-0"
            onClick={() => setShowAll(true)}
          />
          <Modal show={showAll} onHide={() => setShowAll(false)} bsSize="large">
            <Modal.Header closeButton>
              <Modal.Title>All Configurations</Modal.Title>
            </Modal.Header>
            <Modal.Body>{rows}</Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};
