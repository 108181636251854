import React from 'react';
import type { ReactNode } from 'react';

import { cn } from '../utils';

export type CollapseProps = {
  open: boolean;
  header: ReactNode;
  className?: string;
  bodyClassName?: string;
  children: ReactNode;
};

export const Collapse = ({ open, header, className, bodyClassName, children }: CollapseProps) => {
  return (
    <div
      data-state={open ? 'open' : 'closed'}
      className={cn('tw-group tw-flex tw-flex-col', className)}
    >
      {header}
      <div
        className={cn(
          'tw-grid tw-duration-300',
          open ? 'tw-grid-rows-[1fr]' : 'tw-grid-rows-[0fr]',
        )}
      >
        <div className={cn('tw-overflow-hidden', open && 'tw-animate-collapseDown', bodyClassName)}>
          {children}
        </div>
      </div>
    </div>
  );
};
