import React from 'react';
import type { Map } from 'immutable';
import { fromJS } from 'immutable';

import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import { getAppState } from '@/modules/data-apps/helpers';
import { RowActionMenuItem } from '@/react/common';
import type { ConfigRow } from '@/react/common/ConfigurationsTable/constants';
import CopyButton from '@/react/common/ConfigurationsTable/CopyButton';
import MoveActionButton from '@/react/common/ConfigurationsTable/MoveActionButton';
import ConfigurationsTable from '@/react/common/ConfigurationsTable/Table';
import DeleteDataApp from './DeleteDataApp';
import DeployDataApp from './DeployDataApp';
import OpenDataApp from './OpenDataApp';
import StatusLabel from './StatusLabel';
import TerminateDataApp from './TerminateDataApp';

const CUSTOM_CLASSES = fromJS({
  link: { th: 'w-250 text-right', td: 'w-250 text-right' },
  status: { th: 'w-175', td: 'w-175 no-wrap' },
});

type Props = {
  component: Map<string, any>;
  dataApps: Map<string, any>;
  allConfigurations: Map<string, any>;
  componentsMetadata: Map<string, any>;
  filterQuery: string;
  readOnly: boolean;
  hasNewQueue: boolean;
  hasFlows: boolean;
  hasPayAsYouGo: boolean;
  admins: Map<string, any>;
  currentAdmin: Map<string, any>;
};

class DataApps extends React.Component<Props> {
  render() {
    return (
      <ConfigurationsTable
        supportFolders
        hideCommonActions
        entity="data app"
        forceShowAll={!!this.props.filterQuery}
        customClasses={CUSTOM_CLASSES}
        component={this.props.component}
        configurations={this.props.dataApps}
        allConfigurations={this.props.allConfigurations}
        availableConfigurations={this.props.allConfigurations.get(KEBOOLA_DATA_APPS)}
        componentsMetadata={this.props.componentsMetadata}
        readOnly={this.props.readOnly}
        hasNewQueue={this.props.hasNewQueue}
        hasFlows={this.props.hasFlows}
        admins={this.props.admins}
        currentAdmin={this.props.currentAdmin}
        additionalColumns={this.additionalColumns}
        renderAdditionalActions={this.renderAdditionalActions}
      />
    );
  }

  additionalColumns = [
    {
      enableSorting: false,
      accessorKey: 'link',
      header: 'Application Link',
      cell: ({ row }: { row: ConfigRow }) => {
        if (!row.original.data.config) return null;

        return (
          <OpenDataApp
            mode="list"
            config={row.original.data.config}
            app={row.original.data.config.get('app')}
          />
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'Status',
      cell: ({ row }: { row: ConfigRow }) => {
        if (!row.original.data.config) return null;

        return <StatusLabel app={row.original.data.config.get('app')} />;
      },
      sortingFn: (rowA: ConfigRow, rowB: ConfigRow) => {
        if (rowA.depth === 0 && rowB.depth === 0) {
          return 0;
        }

        const aState = getAppState(rowA.original.data.config.get('app'));
        const bState = getAppState(rowB.original.data.config.get('app'));

        if (aState === 'running' && bState !== 'running') {
          return -1;
        }

        if (aState !== 'running' && bState === 'running') {
          return 1;
        }

        return aState.localeCompare(bState);
      },
      isLastColumn: true,
    },
  ];

  renderAdditionalActions = (
    config: Map<string, any> | undefined,
    commonProps: Record<string, any>,
  ) => {
    if (!config) return null;

    return (
      <>
        <DeployDataApp
          mode="menuitem"
          config={config}
          app={config.get('app')}
          readOnly={this.props.readOnly}
          hasPayAsYouGo={this.props.hasPayAsYouGo}
        />
        <RowActionMenuItem divider />
        <TerminateDataApp
          mode="menuitem"
          config={config}
          app={config.get('app')}
          readOnly={this.props.readOnly}
        />
        <CopyButton
          configuration={config}
          component={this.props.component}
          hasFlows={this.props.hasFlows}
        />
        <MoveActionButton
          mode="menuitem"
          onClick={commonProps.onMove}
          component={this.props.component}
          hasFlows={this.props.hasFlows}
        />
        <RowActionMenuItem divider />
        <DeleteDataApp mode="menuitem" config={config} readOnly={this.props.readOnly} />
      </>
    );
  };
}

export default DataApps;
