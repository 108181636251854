import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';

class DeleteDevBranchModal extends React.Component {
  state = { isDeleting: false };

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onHide}>
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Development Branch</Modal.Title>
            <ModalIcon icon={['far', 'code-branch']} color="orange" bold />
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to delete the development branch <b>{this.props.name}</b>?
            </p>
            <p>The changes you made will be deleted permanently.</p>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveStyle="danger"
              saveLabel={
                this.state.isDeleting
                  ? 'Deleting development branch...'
                  : 'YES, delete development branch'
              }
              isSaving={this.state.isDeleting}
            />
            <ConfirmButtons
              block
              saveLabel="No, I want to keep this development branch"
              onSave={this.props.onHide}
              isDisabled={this.state.isDeleting}
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ isDeleting: true });
    return this.props
      .onSubmit()
      .then(this.props.onHide)
      .finally(() => this.setState({ isDeleting: false }));
  };
}

DeleteDevBranchModal.propTypes = {
  name: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default DeleteDevBranchModal;
