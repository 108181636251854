import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { List, Map } from 'immutable';

import { HelpBlock } from '@keboola/design';

import dayjs from '@/date';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

const DATA_FORMAT = 'YYYY-MM-DD';

const prepareExpired = (expires) => {
  return expires ? dayjs(expires).format(DATA_FORMAT) : null;
};

const prepareExpirationDays = (expiryDate) => {
  return Math.ceil(dayjs(expiryDate).diff(dayjs(), 'days', true));
};

class UpdateProjectModal extends React.Component {
  state = {
    tempData: Map(),
    isSaving: false,
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => {
          const expires = this.props.project.get('expires');

          this.setState({
            tempData: Map({
              expires: expires !== null,
              expiryDate: prepareExpired(expires),
              name: this.props.project.get('name'),
              type: this.props.project.get('type'),
              billedMonthlyPrice: this.props.project.get('billedMonthlyPrice'),
            }),
          });
        }}
      >
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Update Project Settings</Modal.Title>
            <ModalIcon icon="pen" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Project name</ControlLabel>
              <FormControl
                type="text"
                value={this.state.tempData.get('name') || ''}
                onChange={(e) =>
                  this.setState({ tempData: this.state.tempData.set('name', e.target.value) })
                }
              />
            </FormGroup>
            {(this.props.isSuperAdmin || this.props.canUpdateTypeAndExpiration) && (
              <>
                <FormGroup>
                  <ControlLabel>Type</ControlLabel>
                  <Select
                    clearable={false}
                    value={this.state.tempData.get('type') || ''}
                    onChange={(selected) =>
                      this.setState({ tempData: this.state.tempData.set('type', selected) })
                    }
                    options={this.props.projectTemplates
                      .map((template) => ({
                        value: template.get('id'),
                        label: template.get('description'),
                      }))
                      .toArray()}
                  />
                  <HelpBlock className="tw-mt-1">
                    The change does not affect limits settings. Just the project type is changed.
                  </HelpBlock>
                </FormGroup>
                <FormGroup>
                  <ActivateDeactivateSwitch
                    withLabel
                    customLabel="Expiration"
                    className="tw-pl-0 tw-pt-0"
                    activateTooltip="Set expiration"
                    deactivateTooltip="No expiration"
                    isActive={this.state.tempData.get('expires')}
                    onChange={(expires) => {
                      const expiryDate = expires ? dayjs().add(1, 'day').format(DATA_FORMAT) : null;

                      this.setState({
                        tempData: this.state.tempData
                          .set('expires', expires)
                          .set('expiryDate', expiryDate),
                      });
                    }}
                  />
                  {this.state.tempData.get('expires') && (
                    <FormControl
                      type="date"
                      min={dayjs().add(1, 'day').format(DATA_FORMAT)}
                      value={this.state.tempData.get('expiryDate')}
                      onChange={(e) => {
                        const expiryDate = e.target.value || null;

                        this.setState({
                          tempData: this.state.tempData.set('expiryDate', expiryDate),
                        });
                      }}
                    />
                  )}
                </FormGroup>
                {this.props.isSuperAdmin && (
                  <FormGroup>
                    <ControlLabel>Monthly Fee $</ControlLabel>
                    <FormControl
                      type="text"
                      value={this.state.tempData.get('billedMonthlyPrice') || ''}
                      onChange={(e) =>
                        this.setState({
                          tempData: this.state.tempData.set('billedMonthlyPrice', e.target.value),
                        })
                      }
                    />
                  </FormGroup>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={this.state.isSaving ? 'Saving changes...' : 'Save changes'}
              isSaving={this.state.isSaving}
              isDisabled={this.isDisabled()}
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  isDisabled() {
    if (!this.state.tempData.get('name')) {
      return true;
    }

    return (
      this.state.tempData.get('name') === this.props.project.get('name') &&
      this.state.tempData.get('type') === this.props.project.get('type') &&
      this.state.tempData.get('expiryDate') === prepareExpired(this.props.project.get('expires')) &&
      this.state.tempData.get('billedMonthlyPrice') === this.props.project.get('billedMonthlyPrice')
    );
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isSaving: true });
    this.props
      .onSubmit({
        name: this.state.tempData.get('name'),
        ...((this.props.isSuperAdmin || this.props.canUpdateTypeAndExpiration) && {
          type: this.state.tempData.get('type'),
          expirationDays: this.state.tempData.get('expires')
            ? prepareExpirationDays(this.state.tempData.get('expiryDate'))
            : 0,
        }),
        ...(this.props.isSuperAdmin && {
          billedMonthlyPrice: parseFloat(this.state.tempData.get('billedMonthlyPrice')) || null,
        }),
      })
      .then(this.props.onHide)
      .finally(() => this.setState({ isSaving: false }));
  };
}

UpdateProjectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  project: PropTypes.instanceOf(Map).isRequired,
  projectTemplates: PropTypes.instanceOf(List).isRequired,
  canUpdateTypeAndExpiration: PropTypes.bool.isRequired,
  isSuperAdmin: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UpdateProjectModal;
