import React from 'react';
import PropTypes from 'prop-types';
import ImmutableRendererMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { routeNames as legacyOrchestrationsRouteNames } from '@/modules/orchestrations/Constants';
import ScheduledJob from '@/modules/queue/components/ScheduledJob';
import TriggeredJob from '@/modules/queue/components/TriggeredJob';
import { routeNames } from '@/modules/queue/constants';
import { isScheduledJob, isTriggeredJob } from '@/modules/queue/helpers';
import { CreatedDate, User } from '@/react/common';
import JobDuration from '@/react/common/JobDuration';
import JobStatusLabel from '@/react/common/JobStatusLabel';
import RoutesStore from '@/stores/RoutesStore';
import hasSelections from '@/utils/hasSelections';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';

const JobsTableRow = createReactClass({
  mixins: [ImmutableRendererMixin],

  propTypes: {
    job: PropTypes.instanceOf(Map).isRequired,
    admin: PropTypes.instanceOf(Map),
    onJobCancel: PropTypes.func,
    hasNewQueue: PropTypes.bool,
  },

  jobDetail(event) {
    event.stopPropagation();

    if (hasSelections()) {
      return;
    }

    if (this.props.hasNewQueue) {
      const params = { jobId: this.props.job.get('id') };

      if (shouldUseNewWindow(event)) {
        return windowOpen(RoutesStore.getRouter().createHref(routeNames.JOB_DETAIL, params));
      }

      return RoutesStore.getRouter().transitionTo(routeNames.JOB_DETAIL, params);
    }

    const params = {
      orchestrationId: RoutesStore.getRouterState().getIn(['params', 'orchestrationId']),
      jobId: this.props.job.get('id'),
    };

    if (shouldUseNewWindow(event)) {
      return windowOpen(
        RoutesStore.getRouter().createHref(legacyOrchestrationsRouteNames.JOB, params),
      );
    }

    return RoutesStore.getRouter().transitionTo(legacyOrchestrationsRouteNames.JOB, params);
  },

  cancelJob(event) {
    event.stopPropagation();
    return this.props.onJobCancel(this.props.job);
  },

  render() {
    return (
      <tr
        className="clickable"
        onClick={this.jobDetail}
        onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
        onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
      >
        <td>
          {this.props.admin ? (
            <User user={this.props.admin} />
          ) : isScheduledJob(this.props.job) ? (
            <ScheduledJob iconLeft />
          ) : isTriggeredJob(this.props.job) ? (
            <TriggeredJob />
          ) : (
            this.props.job.getIn(['token', 'description']) ||
            this.props.job.getIn(['initiatorToken', 'description'])
          )}
        </td>
        <td className="text-right">
          <CreatedDate createdTime={this.props.job.get('createdTime')} absolute />
        </td>
        <td className="text-right">
          <JobDuration
            status={this.props.job.get('status')}
            startTime={this.props.job.get('startTime')}
            endTime={this.props.job.get('endTime')}
          />
        </td>
        <td>
          <JobStatusLabel status={this.props.job.get('status')} />
        </td>
      </tr>
    );
  },
});

export default JobsTableRow;
