import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { routeNames as flowsRouteNames } from '@/modules/flows/constants';
import { routeNames as legacyOrchestrationsRouteNames } from '@/modules/orchestrations/Constants';
import { routeNames as orchestrationsRouteNames } from '@/modules/orchestrations-v2/constants';
import { RouterLink as Link } from '@/react/common';

const configurationScheduled = (
  configuration,
  orchestrationId,
  hasFlows,
  options = { newOrchestration: false, linkToIndex: false },
) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired,
    },

    render() {
      return (
        <>
          Configuration{' '}
          <Link to={this.getLink()} params={this.getParams()} onClick={this.props.onClick}>
            {configuration.get('name')}
          </Link>{' '}
          has been automated.
        </>
      );
    },

    getLink() {
      if (options.linkToIndex) {
        return options.newOrchestration
          ? hasFlows
            ? flowsRouteNames.ROOT
            : orchestrationsRouteNames.ROOT
          : legacyOrchestrationsRouteNames.ROOT;
      }

      return options.newOrchestration
        ? hasFlows
          ? flowsRouteNames.DETAIL
          : orchestrationsRouteNames.DETAIL
        : legacyOrchestrationsRouteNames.ORCHESTRATION;
    },

    getParams() {
      if (options.linkToIndex) {
        return null;
      }

      if (options.newOrchestration) {
        return { config: orchestrationId };
      }

      return { orchestrationId };
    },
  });
};

export default configurationScheduled;
