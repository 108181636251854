import React from 'react';

import { Alert } from '@keboola/design';

const Warning = () => {
  return (
    <Alert variant="warning" className="tw-mb-5">
      You&apos;re not using the latest version of the transformation backend. Please set the backend
      version to <code>Latest</code> to keep the environment up to date.
    </Alert>
  );
};

export default Warning;
