import React from 'react';
import type { ReactNode } from 'react';

import { cn } from '../../utils';

import { FormGroupProvider } from './context';
import { FormGroupHelp } from './FormGroupHelp';
import { FormGroupTextInput } from './FormGroupTextInput';
import type { FormGroupContext } from './types';

export type FormGroupProps = {
  children: ReactNode;
  className?: string;
  testId?: string;
} & FormGroupContext;

export const FormGroup = ({ className, children, state, testId }: FormGroupProps) => {
  return (
    <FormGroupProvider value={{ state }}>
      <div data-testid={testId} className={cn('tw-flex tw-flex-col tw-gap-1', className)}>
        {children}
      </div>
    </FormGroupProvider>
  );
};

FormGroup.Help = FormGroupHelp;
FormGroup.TextInput = FormGroupTextInput;
