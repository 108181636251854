import React from 'react';

import { routeNames } from '@/modules/billing/constants';
import { RouterLink as Link } from '@/react/common';
import ApplicationStore from '@/stores/ApplicationStore';

const BackLink = () => {
  if (!ApplicationStore.hasPayAsYouGo()) return null;

  return (
    <Link className="dark muted" to={routeNames.BILLING_OVERVIEW}>
      Billing
    </Link>
  );
};

export default BackLink;
