import React from 'react';

import { URLS } from '@keboola/constants';

import ApplicationStore from '@/stores/ApplicationStore';

const CreateProjectBar = () => {
  if (!ApplicationStore.isDemoPreview()) {
    return null;
  }

  return (
    <div className="top-sticky-bar">
      Ready to take control? Start creating your own project!
      <a href={URLS.REGISTRATION_WIZARD} className="btn btn-success">
        Create My Project
      </a>
    </div>
  );
};

export default CreateProjectBar;
