/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import _ from 'underscore';

import { cn } from '@keboola/design';

import SearchBar from './SearchBar';

export const FilterPanel = (props: {
  onChange: (query: string) => void;
  query?: string;
  asBox?: boolean;
  className?: string;
  searchBarClassName?: string;
  additionalActions?: React.ReactNode;
  placeholder?: string | (() => string);
  autoFocus?: boolean;
}) => {
  const [query, setQuery] = React.useState(props.query);

  React.useEffect(() => {
    if ('query' in props && query !== props.query) {
      setQuery(props.query);
    }
  }, [props.query]);

  const debouncedOnChange = React.useMemo(() => {
    return _.debounce((newQuery: string) => {
      if (
        newQuery.length > 1 ||
        !newQuery ||
        (props.query && newQuery.length < props.query.length)
      ) {
        props.onChange(newQuery);
      }
    }, 300);
  }, [props.onChange]);

  return (
    <div className={cn({ 'search-panel box': props.asBox !== false }, props.className)}>
      <SearchBar
        autoFocus={props.autoFocus}
        placeholder={props.placeholder}
        query={query}
        onChange={(newQuery) => {
          setQuery(newQuery);

          query && query.length > 1 && !newQuery
            ? props.onChange(newQuery)
            : debouncedOnChange(newQuery);
        }}
        additionalActions={props.additionalActions}
        className={props.searchBarClassName}
      />
    </div>
  );
};
