import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ButtonInline } from '@keboola/design';

import { getConfigDescription, sendFeedback } from '@/modules/ai/api';
import { FEEDBACK_STATUS, type FeedbackStatusType } from '@/modules/ai/constants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import ApplicationStore from '@/stores/ApplicationStore';
import EditingArea from './EditingArea';
import GenerateButton from './GenerateButton';
import SaveButtons from './SaveButtons';
import StaticArea from './StaticArea';

type DescriptionProps = {
  description: string;
  onSave: () => Promise<void>;
  readOnly: boolean;
  placeholder?: string;
  inModal?: boolean;
  warning?: React.ReactNode;
  componentId?: string;
  configId?: string;
  rowId?: string;
  hasAllowedAi?: boolean;
  editorValue: string | null;
  onSetEditorValue: (value: string | null) => void;
};

const DescriptionBox = ({
  description,
  onSave,
  readOnly,
  placeholder,
  inModal,
  warning,
  componentId,
  configId,
  rowId,
  hasAllowedAi,
  editorValue,
  onSetEditorValue,
}: DescriptionProps) => {
  const [isGenerating, setGenerating] = React.useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isGeneratedWithAI, setIsGeneratedWithAI] = React.useState(false);
  const [correlationId, setCorrelationId] = React.useState<string | null>(null);
  const [feedbackStatus, setFeedbackStatus] = React.useState<FeedbackStatusType | null>(null);

  const isEditing = editorValue !== null || isGenerating;

  const handleGenerate = () => {
    if (!componentId || !configId) {
      return;
    }

    setFeedbackStatus(null);
    setGenerating(true);

    getConfigDescription(componentId, configId, rowId)
      .then((data) => {
        onSetEditorValue(data.description.trim());
        setCorrelationId(data.correlationId);
      })
      .finally(() => {
        setGenerating(false);
        setIsGeneratedWithAI(true);
      });
  };

  const handleSendFeedback = (status: FeedbackStatusType, comment?: string) => {
    if (!componentId || !configId) {
      return;
    }

    const branchId = ApplicationStore.hasProtectedDefaultBranch()
      ? DevBranchesStore.getCurrentId()?.toString()
      : DevBranchesStore.getDefaultBranchId()?.toString();

    setFeedbackStatus(status);
    sendFeedback({
      value: status,
      subject: {
        type: 'configuration',
        configId,
        componentId,
        rowId,
        branchId: branchId ?? '',
      },
      ...(comment && { comment }),
      ...(correlationId && { correlationId }),
    });
  };

  const handleCancel = () => {
    onSetEditorValue(null);
    setIsGeneratedWithAI(false);
  };

  const handleSave = () => {
    setIsSaving(true);

    return onSave().finally(() => {
      setIsSaving(false);
      handleCancel();
    });
  };

  const renderSaveButtons = () => (
    <SaveButtons
      isSaving={isSaving}
      isGenerating={isGenerating}
      onCancel={handleCancel}
      editorValue={editorValue}
      onSave={handleSave}
      description={description}
      inModal={inModal}
    />
  );

  const renderBody = () => {
    if (isEditing && !readOnly) {
      return (
        <EditingArea
          value={editorValue || ''}
          inModal={inModal}
          isSaving={isSaving}
          isGenerating={isGenerating}
          onChange={onSetEditorValue}
          hasAllowedAi={hasAllowedAi}
          onSave={handleSave}
          onCancel={handleCancel}
          onGenerate={handleGenerate}
          warning={warning}
          isGeneratedWithAI={isGeneratedWithAI}
          onBad={(comment?: string) => handleSendFeedback(FEEDBACK_STATUS.BAD, comment)}
          onGood={() => handleSendFeedback(FEEDBACK_STATUS.GOOD)}
          feedbackStatus={feedbackStatus}
        />
      );
    }

    if (description) {
      return (
        <StaticArea
          value={description}
          readOnly={readOnly}
          onClick={() => onSetEditorValue(description)}
        />
      );
    }

    return (
      <div className="tw-mt-4 tw-flex tw-justify-center tw-gap-6">
        <ButtonInline variant="dark" onClick={() => onSetEditorValue('')}>
          <FontAwesomeIcon icon="plus" className="color-success" />
          Add description
        </ButtonInline>
        {hasAllowedAi && (
          <>
            <span className="text-muted f-12 font-medium">or</span>
            <GenerateButton
              variant="dark"
              isGenerating={isGenerating}
              isSaving={isSaving}
              handleGenerate={handleGenerate}
            />
          </>
        )}
      </div>
    );
  };

  if (readOnly && !description) {
    return null;
  }

  if (inModal) {
    return (
      <>
        <Modal.Body className="description-box">{renderBody()}</Modal.Body>
        {isEditing && <Modal.Footer>{renderSaveButtons()}</Modal.Footer>}
      </>
    );
  }

  return (
    <div className="box description-box">
      <div className="flex-container">
        {placeholder && <h3 className="f-16 line-height-24 m-0">{placeholder}</h3>}
        {isEditing && renderSaveButtons()}
      </div>
      {renderBody()}
    </div>
  );
};

export default DescriptionBox;
