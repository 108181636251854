import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { Clipboard } from '@keboola/design';

const RunIdTooltip = ({ job }: { job: Map<string, any> }) => {
  return (
    <Clipboard
      text={job.get('runId')}
      label={
        <span className="tw-ml-2 tw-text-xs tw-font-normal tw-text-neutral-400">
          Run ID
          <FontAwesomeIcon icon="copy" className="tw-ml-2 tw-text-xs" />
        </span>
      }
      tooltipText={
        <>
          <p className="tooltip-title">Run ID</p>
          <p className="overflow-break-anywhere">{job.get('runId')}</p>
          <small>Click to copy</small>
        </>
      }
      tooltipPlacement="top"
      tooltipType="explanatory"
      showIcon={false}
    />
  );
};

export default RunIdTooltip;
