import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { List, Map } from 'immutable';

import { HelpBlock } from '@keboola/design';

import LastFetchedValue from '@/modules/ex-db-generic/react/components/LastFetchedValue';
import { IncrementalFetchingAllowedTypes } from '@/modules/ex-google-bigquery-v2/constants';
import Select from '@/react/common/Select';

class IncrementalFetching extends React.Component {
  render() {
    if (this.props.allValues.advancedMode) {
      return null;
    }

    const incrementalFetchingOptions = this.getIncrementalFetchingOptions();

    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Incremental Fetching</h2>
        </div>
        <div className="box-content">
          {incrementalFetchingOptions.length > 0 || !!this.props.value.incrementalFetchingColumn ? (
            <>
              <FormGroup>
                <div className="col-xs-4">
                  <ControlLabel>Column</ControlLabel>
                </div>
                <div className="col-xs-8">
                  <Select
                    placeholder="Fetch by column"
                    value={this.props.value.incrementalFetchingColumn || ''}
                    onChange={(selected) =>
                      this.props.onChange({ incrementalFetchingColumn: selected })
                    }
                    options={incrementalFetchingOptions}
                    disabled={this.props.disabled || !this.props.allValues.tableId}
                  />
                  <HelpBlock className="tw-mt-1">
                    If enabled, only newly created or updated records since the last run will be
                    fetched.
                  </HelpBlock>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="col-xs-4">
                  <ControlLabel>Limit</ControlLabel>
                </div>
                <div className="col-xs-8">
                  <FormControl
                    min="0"
                    type="number"
                    value={this.props.value.limit || 0}
                    onChange={({ target }) =>
                      this.props.onChange({ limit: target.value ? parseInt(target.value, 10) : 0 })
                    }
                    disabled={this.props.disabled || !this.props.value.incrementalFetchingColumn}
                  />
                  <HelpBlock className="tw-mt-1">
                    The number of records to fetch from the source per run. Subsequent runs will
                    start from the last record fetched. Note: 0 means unlimited.
                  </HelpBlock>
                </div>
              </FormGroup>
              {this.props.value.incrementalFetchingColumn && (
                <FormGroup>
                  <div className="col-xs-4">
                    <ControlLabel>Last Fetched Value</ControlLabel>
                  </div>
                  <div className="col-xs-8">
                    <LastFetchedValue
                      value={this.props.state.getIn(['component', 'lastFetchedValue'])}
                      incrementalFetchingColumn={this.props.value.incrementalFetchingColumn}
                      onReset={this.props.onResetState}
                    />
                  </div>
                </FormGroup>
              )}
            </>
          ) : (
            <FormGroup>
              <div className="col-xs-8 col-xs-offset-4">
                <HelpBlock className="tw-mt-1">
                  Incremental fetching is available only for tables containing numeric or
                  timestamp/datetime columns.
                </HelpBlock>
              </div>
            </FormGroup>
          )}
        </div>
      </div>
    );
  }

  getIncrementalFetchingOptions() {
    let columns = this.props.actions
      .getIn(['getTables', 'data'], List())
      .find(
        (table) =>
          table.get('datasetId') === this.props.allValues.datasetId &&
          table.get('tableId') === this.props.allValues.tableId,
        null,
        Map(),
      )
      .get('columns', this.props.actions.getIn(['getColumns', 'data'], List()))
      .filter((column) =>
        IncrementalFetchingAllowedTypes.includes(column.get('type').toUpperCase()),
      )
      .map((column) => ({ label: column.get('name'), value: column.get('name') }));

    if (
      this.props.value.incrementalFetchingColumn &&
      !columns.some((option) => option.value === this.props.value.incrementalFetchingColumn)
    ) {
      columns = columns.push({
        label: this.props.value.incrementalFetchingColumn,
        value: this.props.value.incrementalFetchingColumn,
      });
    }

    return columns.toArray();
  }
}

IncrementalFetching.propTypes = {
  state: PropTypes.instanceOf(Map).isRequired,
  actions: PropTypes.instanceOf(Map).isRequired,
  onResetState: PropTypes.func.isRequired,
  allValues: PropTypes.object.isRequired,
  value: PropTypes.shape({
    incrementalFetchingColumn: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default IncrementalFetching;
