import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { Alert, Button, FormGroup, Label, Tooltip } from '@keboola/design';

import { canLinkBucket } from '@/modules/admin/privileges';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { nameWarning } from '@/modules/storage/constants';
import { bucketDisplayNameWithStage, validateBucketName } from '@/modules/storage/helpers';
import { isValidName } from '@/react/common/helpers';
import InputValidation from '@/react/common/InputValidation';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';

class LinkButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayName: '',
      showModal: false,
      creatingBucket: false,
      error: null,
      warning: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleDisplayName = this.handleDisplayName.bind(this);
  }

  render() {
    if (!canLinkBucket(this.props.token)) {
      return (
        <Tooltip
          tooltip="You don't have permission to link this bucket."
          placement="top"
          type="explanatory"
          triggerClassName="tw-flex tw-shrink-0"
        >
          <Button size={this.props.size} disabled variant="secondary">
            {this.props.withIcon && <FontAwesomeIcon icon="plus" />}
            Use this
          </Button>
        </Tooltip>
      );
    }

    return (
      <>
        {this.renderButton()}
        {this.renderModal()}
      </>
    );
  }

  renderButton() {
    return (
      <Button
        size={this.props.size}
        variant="secondary"
        onClick={this.openModal}
        className="tw-flex tw-shrink-0"
      >
        {this.props.withIcon && <FontAwesomeIcon icon="plus" />}
        Use this
      </Button>
    );
  }

  renderModal() {
    return (
      <Modal show={this.state.showModal} onHide={this.closeModal}>
        <form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <ModalIcon
              icon="folder"
              generatedBackground={bucketDisplayNameWithStage(this.props.bucket)}
            />
            <Modal.Title>
              {this.props.bucket.get('displayName')}{' '}
              <span className="text-muted font-normal">
                by {this.props.bucket.getIn(['project', 'name'])}
              </span>
            </Modal.Title>
            {this.props.bucket.get('description') && (
              <div className="text-muted mt-1">{this.props.bucket.get('description')}</div>
            )}
          </Modal.Header>
          <Modal.Body>
            {this.state.error && (
              <Alert variant="error" className="tw-mb-5">
                {this.state.error}
              </Alert>
            )}
            <InputValidation predefined="bucketName" value={this.state.displayName}>
              {(inputState) => {
                const state = !!this.state.warning || inputState === 'error' ? 'error' : 'default';
                const messages = {
                  default: `The bucket will be linked to an IN stage. ${nameWarning}`,
                  error: this.state.warning,
                };

                return (
                  <FormGroup state={state}>
                    <Label htmlFor="name">
                      Select a name how the bucket will appear in your project
                    </Label>

                    <div className="tw-flex tw-gap-1">
                      <FormGroup.TextInput
                        id="name"
                        className="tw-grow"
                        autoFocus
                        variant="secondary"
                        value={this.state.displayName}
                        onChange={this.handleDisplayName}
                      />
                      <Button
                        className="tw-min-w-fit"
                        type="submit"
                        onClick={this.onSubmit}
                        disabled={this.isDisabled()}
                      >
                        {this.state.creatingBucket ? (
                          <Loader />
                        ) : (
                          <FontAwesomeIcon icon="circle-check" />
                        )}
                        Link
                      </Button>
                    </div>

                    <FormGroup.Help>{messages[state]}</FormGroup.Help>
                  </FormGroup>
                );
              }}
            </InputValidation>
            {this.props.bucket.get('tables', List()).count() > 0 && (
              <div className="well">
                <div className="well-title">Tables</div>
                {this.props.bucket.get('tables').map((table, index) => (
                  <div className="flex-container flex-start" key={index}>
                    <FontAwesomeIcon icon="table" className="icon-addon-right text-muted" />
                    {table.get('displayName')}
                  </div>
                ))}
              </div>
            )}
          </Modal.Body>
        </form>
      </Modal>
    );
  }

  openModal() {
    this.setState({ showModal: true, displayName: this.props.bucket.get('displayName') });
  }

  closeModal() {
    this.setState({
      showModal: false,
      error: null,
      displayName: '',
    });
  }

  handleDisplayName(displayName) {
    this.setState({
      displayName,
      warning: validateBucketName(
        displayName,
        'in',
        this.props.buckets,
        DevBranchesStore.getCurrentId(),
      ),
    });
  }

  isDisabled() {
    if (
      this.state.creatingBucket ||
      !this.state.displayName ||
      !isValidName(this.state.displayName) ||
      this.state.warning
    ) {
      return true;
    }

    return false;
  }

  onSubmit(e) {
    e.preventDefault();

    const newBucket = {
      stage: 'in',
      displayName: this.state.displayName,
      name: this.state.displayName,
      sourceProjectId: this.props.bucket.getIn(['project', 'id']),
      sourceBucketId: this.props.bucket.get('id'),
    };

    this.setState({ creatingBucket: true });
    StorageActionCreators.createBucket(newBucket, { async: true })
      .then(this.closeModal)
      .catch((error) => {
        this.setState({ error, creatingBucket: false });
      });
  }
}

LinkButton.propTypes = {
  buckets: PropTypes.instanceOf(Map).isRequired,
  bucket: PropTypes.instanceOf(Map).isRequired,
  token: PropTypes.instanceOf(Map).isRequired,
  size: PropTypes.string,
  withIcon: PropTypes.bool,
};

LinkButton.defaultProps = {
  withIcon: false,
};

export default LinkButton;
