export const ServiceId = {
  VAULT: 'vault',
  DATA_SCIENCD: 'data-science',
} as const;

export const Header = {
  STORAGE_API_TOKEN: 'X-StorageApi-Token',
  MANAGEMENT_API_TOKEN: 'X-KBC-ManageApiToken',
  CONTENT_TYPE: 'content-type',
};

export const ErrorMessage = {
  UNINITIALIZED_CLIENT:
    'Uninitialized client: To initialize the Keboola client, use either the .init() method or the <KeboolaProvider/> component.',
  REACT_MISSING_CONTEXT_PROVIDER: 'useKeboolaClient must be used within <KeboolaProvider />',
};

export type ServiceIdType = (typeof ServiceId)[keyof typeof ServiceId];
