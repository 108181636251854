import Promise from 'bluebird';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import {
  KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2,
  KEBOOLA_EXASOL_TRANSFORMATION,
  KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION,
  KEBOOLA_JULIA_TRANSFORMATION,
  KEBOOLA_ORACLE_TRANSFORMATION,
  KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION,
  KEBOOLA_PYTHON_TRANSFORMATION_V_2,
  KEBOOLA_R_TRANSFORMATION_V_2,
  KEBOOLA_REDSHIFT_TRANSFORMATION,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION,
  KEBOOLA_SYNAPSE_TRANSFORMATION,
  KEBOOLA_TERADATA_TRANSFORMATION,
} from '@/constants/componentIds';
import parseQueries from '@/modules/legacy-transformation/utils/parseQueries';
import { prepareCodeString } from '@/modules/shared-codes/helpers';
import normalizeNewlines from '@/modules/transformations/utils/normalizeNewlines';
import escapeStringRegexp from '@/utils/escapeStringRegexp';
import string from '@/utils/string';

const prepareBlocks = (blocks = List()) => {
  if (blocks.isEmpty()) {
    return blocks.push(getNewBlock('Block 1'));
  }

  return blocks;
};

const prepareScriptsBeforeSave = (componentId, scripts) => {
  if (!hasQueries(componentId) || componentId === KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION) {
    return Promise.resolve(List([scripts]));
  }

  return parseQueries(scripts)
    .then((queries) => fromJS(queries))
    .catch(() => List([scripts]))
    .then((queries) => queries.map(normalizeNewlines));
};

const reorderBlocks = (blocks, { newIndex, oldIndex }) => {
  const temp = blocks.get(oldIndex);
  return blocks.splice(oldIndex, 1).splice(newIndex, 0, temp);
};

const reorderCodes = (blocks, order, { newIndex, oldIndex }) => {
  // prevent a bug when dragging single and only one code-block in blocks when it's put back to original spot
  if (!order[newIndex]) {
    return blocks;
  }

  const oldBlockIndex = parseInt(order[newIndex].split('-')[0], 10);
  const otherBlocks = order
    .map((item) => parseInt(item.split('-')[0], 10))
    .filter((item) => item !== oldBlockIndex);
  const newBlockIndex = otherBlocks.length > 0 ? otherBlocks.shift() : oldBlockIndex;
  const temp = blocks.getIn([oldBlockIndex, 'codes', oldIndex]);

  return blocks
    .updateIn([oldBlockIndex, 'codes'], (codes) => codes.splice(oldIndex, 1))
    .updateIn([newBlockIndex, 'codes'], (codes) => codes.splice(newIndex, 0, temp));
};

const cleanupSharedCodes = (configData, sharedCodes) => {
  const sharedCodesIds = configData
    .getIn(['parameters', 'blocks'], List())
    .map((block) => {
      return block
        .get('codes', List())
        .filter((code) => isSharedCode(code, sharedCodes))
        .map((code) => string.trim(code.get('script').join('\n\n'), '{}'));
    })
    .flatten(1)
    .toSet()
    .toList();

  if (sharedCodesIds.count() > 0) {
    return configData.set('shared_code_row_ids', sharedCodesIds);
  }

  return configData.delete('shared_code_id').delete('shared_code_row_ids');
};

const getNewBlock = (name = 'New Code Block') => {
  return fromJS({ name, codes: [] });
};

const getNewCode = (name = 'New Code') => {
  return fromJS({ name, script: [] });
};

const hasQueries = (componentId) => {
  return ['text/x-sql', 'text/x-sfsql', 'text/x-plsql'].includes(resolveEditorMode(componentId));
};

const getDelimiterAndCommentStrings = (componentId) => {
  switch (componentId) {
    case KEBOOLA_SNOWFLAKE_TRANSFORMATION:
    case KEBOOLA_ORACLE_TRANSFORMATION:
    case KEBOOLA_REDSHIFT_TRANSFORMATION:
    case KEBOOLA_SYNAPSE_TRANSFORMATION:
    case KEBOOLA_EXASOL_TRANSFORMATION:
    case KEBOOLA_TERADATA_TRANSFORMATION:
    case KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION:
      return {
        delimiter: ';',
        commentStart: '/* ',
        commentEnd: ' */',
      };
    case KEBOOLA_JULIA_TRANSFORMATION:
    case KEBOOLA_PYTHON_TRANSFORMATION_V_2:
    case KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION:
    case KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2:
    case KEBOOLA_R_TRANSFORMATION_V_2:
      return {
        delimiter: '',
        commentStart: '# ',
        commentEnd: '',
      };
    default:
      return {
        delimiter: '',
        commentStart: '/* ',
        commentEnd: ' */',
      };
  }
};

const resolveEditorMode = (componentId) => {
  switch (componentId) {
    case KEBOOLA_SNOWFLAKE_TRANSFORMATION:
      return 'text/x-sfsql';

    case KEBOOLA_REDSHIFT_TRANSFORMATION:
    case KEBOOLA_SYNAPSE_TRANSFORMATION:
      return 'text/x-sql';

    case KEBOOLA_ORACLE_TRANSFORMATION:
    case KEBOOLA_EXASOL_TRANSFORMATION:
    case KEBOOLA_TERADATA_TRANSFORMATION:
    case KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION:
      return 'text/x-plsql';

    case KEBOOLA_PYTHON_TRANSFORMATION_V_2:
    case KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2:
    case KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION:
      return 'text/x-python';

    case KEBOOLA_JULIA_TRANSFORMATION:
      return 'text/x-julia';

    case KEBOOLA_R_TRANSFORMATION_V_2:
      return 'text/x-rsrc';

    default:
      return 'application/json';
  }
};

const isSharedCode = (code, sharedCodes) => !!getSharedCode(code, sharedCodes);
const getSharedCode = (code, sharedCodes) => {
  const scripts = code.get('script', List())?.join('\n\n');
  return sharedCodes.find((sharedCode) => scripts === `{{${sharedCode.get('id')}}}`);
};

const getBlocksDefaultValue = (componentId) => {
  const { commentStart, commentEnd } = getDelimiterAndCommentStrings(componentId);

  return `${commentStart}===== BLOCK: Block 1 =====${commentEnd}

${commentStart}===== CODE: Code =====${commentEnd}

-- Your code goes here`;
};

const getBlocksAsString = (componentId, blocks, sharedCodes) => {
  const { delimiter, commentStart, commentEnd } = getDelimiterAndCommentStrings(componentId);
  const prepareScript = (script) => {
    if (commentEnd && script.trim().endsWith(commentEnd.trim())) {
      return `${script}\n\n`;
    }

    return `${string.rtrim(script, delimiter)}${delimiter}\n\n`;
  };

  let result = '';
  blocks
    .map((block) =>
      block.update('codes', List(), (codes) =>
        codes.filter((code) => code.get('script', List())?.some((script) => !!script)),
      ),
    )
    .filter((block) => !block.get('codes', List()).isEmpty())
    .forEach((block) => {
      result += `${commentStart}===== BLOCK: ${block.get('name', '')} =====${commentEnd}\n\n`;
      block.get('codes', List()).forEach((code) => {
        const name = code.get('name', '');

        if (isSharedCode(code, sharedCodes)) {
          const sharedCode = getSharedCode(code, sharedCodes);

          result += `${commentStart}===== SHARED CODE: ${name} =====${commentEnd}\n\n`;
          result += List()
            .concat(sharedCode.getIn(['configuration', 'code_content'], ''))
            .map(prepareScript)
            .join('');
        } else {
          result += `${commentStart}===== CODE: ${name} =====${commentEnd}\n\n`;
          result += code.get('script', List())?.map(prepareScript)?.join('');
        }
      });
    });
  return result;
};

const prepareMultipleBlocks = (componentId, sharedCodes, value) => {
  const parts = getDelimiterAndCommentStrings(componentId);
  const start = escapeStringRegexp(parts.commentStart);
  const end = escapeStringRegexp(parts.commentEnd);

  let blocks = List();

  return Promise.resolve()
    .then(() => {
      const blocksParts = value
        .trim()
        .split(new RegExp(`${start}\\s?=+\\s?block:\\s?([^=]+)=+\\s?${end}`, 'i'));

      if (blocksParts.length < 2) {
        return [['New Code Block', value]];
      }

      return _.chunk(blocksParts.slice(1), 2);
    })
    .each(([blockName, code = '']) => {
      let codes = List();

      return Promise.resolve()
        .then(() => {
          const codesParts = code
            .trim()
            .split(new RegExp(`${start}\\s?=+\\s?(code|shared code):\\s?([^=]+)=+\\s?${end}`, 'i'));

          if (codesParts.length < 2) {
            return [['code', 'New Code', code]];
          }

          return _.chunk(codesParts.slice(1), 3);
        })
        .each(([codeType, codeName = '', codeContent = '']) => {
          return prepareScriptsBeforeSave(componentId, codeContent.trim()).then((scripts) => {
            let code = getNewCode(codeName.trim()).set('script', scripts);

            if (codeType.toLowerCase() === 'shared code') {
              const sharedCode = sharedCodes.find((sharedCode) => {
                const codeString = sharedCode.getIn(['configuration', 'code_content']);

                return (
                  string.rtrim(prepareCodeString(scripts), parts.delimiter) ===
                  string.rtrim(prepareCodeString(codeString), parts.delimiter)
                );
              });

              if (sharedCode) {
                code = code.set('script', List([`{{${sharedCode.get('id')}}}`]));
              }
            }

            codes = codes.push(code);
          });
        })
        .then(() => {
          blocks = blocks.push(getNewBlock(blockName.trim()).set('codes', codes));
        });
    })
    .then(() => blocks);
};

const getColumnsHints = (tables, input) => {
  let columns = input.get('columns', List());

  if (!columns.count()) {
    columns = tables.getIn([input.get('source'), 'columns'], List());
  }

  return columns.map((column) => `"${column}"`);
};

const getTablesHints = (componentId, configData, storageTables) => {
  let tables = Map();
  const isSql = hasQueries(componentId);

  configData.getIn(['storage', 'input', 'tables'], List()).forEach((input) => {
    if (isSql) {
      tables = tables.set(input.get('destination'), getColumnsHints(storageTables, input));
    } else {
      tables = tables.set(`in/tables/${input.get('destination')}`, List());
    }
  });

  configData.getIn(['storage', 'output', 'tables'], List()).forEach((output) => {
    if (isSql) {
      tables = tables.set(output.get('source'), List());
    } else {
      tables = tables.set(`out/tables/${output.get('source')}`, List());
    }
  });

  return tables.toJS();
};

const getVariablesHints = (variables) => {
  return variables
    .getIn(['configuration', 'values'], List())
    .map((variable) => ({ name: variable.get('name'), value: variable.get('value') }))
    .toArray();
};

export {
  prepareBlocks,
  prepareMultipleBlocks,
  prepareScriptsBeforeSave,
  reorderBlocks,
  reorderCodes,
  cleanupSharedCodes,
  getNewBlock,
  getNewCode,
  hasQueries,
  resolveEditorMode,
  isSharedCode,
  getSharedCode,
  getBlocksDefaultValue,
  getBlocksAsString,
  getTablesHints,
  getVariablesHints,
};
