import { Header } from '../../constants';
import {
  createCallbackMiddleware,
  createOpenapiFetchClient,
  type OpenapiQueryType,
} from '../../fetchClient';
import type { ClientInitOptions } from '../types';

import type { paths } from './__generated__/schema';

type GetVariablesQuery = OpenapiQueryType<paths['/variables']['get']>;

export class VaultClient {
  private readonly client: ReturnType<typeof createOpenapiFetchClient<paths>>;

  constructor({ baseUrl, token, callbacks }: ClientInitOptions) {
    this.client = createOpenapiFetchClient({
      baseUrl: baseUrl,
      headers: new Headers({ [Header.STORAGE_API_TOKEN]: token }),
      middlewares: [createCallbackMiddleware(callbacks)],
    });
  }

  async getVariables(query: GetVariablesQuery, signal?: AbortSignal) {
    const { data } = await this.client.get(
      '/variables',
      { query },
      {
        signal,
      },
    );

    return data;
  }
}
