import React from 'react';
import PropTypes from 'prop-types';
import { resolve } from 'react-router-named-routes';
import * as Sentry from '@sentry/react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { cn, Link } from '@keboola/design';

import RouterActionCreators from '@/actions/RouterActionCreators';
import { IS_DEVELOPMENT } from '@/constants/environment';
import { routesNames } from '@/constants/external';
import {
  FEATURE_IS_SINGLE_TENANT,
  FEATURE_POWER_USER,
  FEATURE_SNOWFLAKE_PARTNER_CONNECT,
  FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
} from '@/constants/features';
import BackToProductionWarning from '@/modules/dev-branches/components/BackToProductionWarning';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import WelcomeModalGcp from '@/modules/gcp/components/WelcomeModal';
import { getCampaignTarget, isValidCampaignTarget } from '@/modules/home/helpers';
import Expiration from '@/modules/home/react/Expiration';
import WelcomeModal from '@/modules/snowflake-partner-connect/components/WelcomeModal';
import { routeNames as snowflakePartnerConnectRouteNames } from '@/modules/snowflake-partner-connect/constants';
import UpgradeSPCBar from '@/modules/snowflake-partner-connect/UpgradeBar';
import StackFeaturesStore from '@/modules/stack-features/Store';
import TopBarNav from '@/react/admin/project/TopBarNav';
import Confetti from '@/react/common/Confetti';
import { ErrorBoundaryFallback } from '@/react/common/ErrorBoundaryFallback';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ErrorPage from '@/react/pages/ErrorPage';
import LoadingPage from '@/react/pages/LoadingPage';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { handleError } from '@/utils/promise';
import { getUrlWithBaseName } from '@/utils/router/getUrlWithBaseName';
import CreateProjectBar from './CreateProjectBar';
import DevModeBar from './DevModeBar';
import Favicon from './Favicon';
import FloatingNotifications from './FloatingNotifications';
import Hotkeys from './Hotkeys';
import PageTitle from './PageTitle';
import ProductFruits from './ProductFruits';
import ReleaseManager from './ReleaseManager';
import ScrollAndFocusManager from './ScrollAndFocusManager';
import TopUpBar from './TopUpBar';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: handleError,
  }),
  mutationCache: new MutationCache({
    onError: handleError,
  }),
});

const App = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, DevBranchesStore, StackFeaturesStore)],

  getStateFromStores() {
    const currentProject = ApplicationStore.getCurrentProject();

    return {
      currentProject,
      currentOrganization: ApplicationStore.getCurrentOrganization(),
      organizations: ApplicationStore.getOrganizations(),
      currentDevBranch: DevBranchesStore.getCurrentDevBranch(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      isInitialLoading: ApplicationStore.getInitialLoading(),
      projectFeatures: ApplicationStore.getCurrentProjectFeatures(),
      projectExpiration: currentProject.get('expires'),
      isDemoPreview: ApplicationStore.isDemoPreview(),
      hasSnowflakePartnerConnect: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_PARTNER_CONNECT,
      ),
      hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
      ),
      hasInvalidCustomBackend: ApplicationStore.hasInvalidCustomBackend(),
      currentDevBranchMergeRequest: DevBranchesStore.getCurrentDevBranchMergeRequest(),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      sapiToken: ApplicationStore.getSapiToken(),
      readOnly: ApplicationStore.isReadOnly(),
      isPowerUser: ApplicationStore.hasCurrentAdminFeature(FEATURE_POWER_USER),
    };
  },

  getInitialState() {
    RouterActionCreators.routerCreated({
      isActive: (name, params, index) =>
        this.props.router.isActive({ pathname: resolve(name, params) }, index),
      isActiveByPathName: (pathName, indexOnly = false) =>
        this.props.router.isActive({ pathname: getUrlWithBaseName(pathName) }, indexOnly),
    });

    return {
      backFlow: Map(),
      isSingleTenant: StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      projectTemplates: ApplicationStore.getProjectTemplates(),
      canManageApps: ApplicationStore.getKbcVars().get('canManageApps'),
    };
  },

  componentDidMount() {
    this.validateAccess();
  },

  componentDidUpdate() {
    this.identifyUser();

    if (!this.state.backFlow.has('pathname') && !!this.props.location?.state?.flowId) {
      this.setState({
        backFlow: Map({
          id: this.props.location.state.flowId,
          scroll: this.props.location.state.scrollY,
          pathname: this.props.location.pathname,
        }),
      });
    } else if (
      !this.state.backFlow.isEmpty() &&
      (!this.props.location.pathname.startsWith(this.state.backFlow.get('pathname')) ||
        this.props.location.state?.resetBackFlow)
    ) {
      this.setState({ backFlow: Map() });
    }

    this.validateAccess();
  },

  validateAccess() {
    if (this.state.hasInvalidCustomBackend) {
      if (!this.props.location.pathname.includes('dashboard')) {
        RoutesStore.getRouter().transitionTo('app');
      }
    }
  },

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <div
          className={cn(routesNames(this.props.router.routes), {
            'demo-preview': this.state.isDemoPreview,
          })}
        >
          <Favicon />
          <CreateProjectBar />
          {this.renderUpgradeSPCBar()}
          {this.renderProjectExpirationBar()}
          <DevModeBar
            sapiToken={this.state.sapiToken}
            currentDevBranch={this.state.currentDevBranch}
            hasProtectedDefaultBranch={this.state.hasProtectedDefaultBranch}
            mergeRequest={this.state.currentDevBranchMergeRequest}
          />
          <TopUpBar />
          {this.state.backFlow.isEmpty() ? (
            this.renderBody()
          ) : (
            <div className="flow-panel grid-background">
              <div className="container flow-panel-container">{this.renderBody()}</div>
            </div>
          )}
        </div>
      </QueryClientProvider>
    );
  },

  renderBody() {
    return (
      <>
        <PageTitle />
        <Hotkeys />
        <ReleaseManager location={this.props.location} />
        <ScrollAndFocusManager location={this.props.location} />
        {!this.state.hasProtectedDefaultBranch && !this.state.isPowerUser && (
          <BackToProductionWarning />
        )}
        <TopBarNav
          user={this.state.currentAdmin}
          urlTemplates={this.state.urlTemplates}
          currentProject={this.state.currentProject}
          currentOrganization={this.state.currentOrganization}
          projectTemplates={this.state.projectTemplates}
          canManageApps={this.state.canManageApps}
          organizations={this.state.organizations}
          backFlow={this.state.backFlow}
        />
        <FloatingNotifications />
        <div className="container main-container">{this.renderMain()}</div>
        {this.renderProductFruits()}
        {this.renderConfetti()}
        {this.renderWelcome()}
        {this.renderSPCWelcomeModal()}
        {this.renderDevButtons()}
      </>
    );
  },

  renderMain() {
    return (
      <Sentry.ErrorBoundary fallback={() => <ErrorBoundaryFallback />}>
        {RoutesStore.isError() ? (
          <ErrorPage />
        ) : this.state.isInitialLoading ? (
          <LoadingPage />
        ) : (
          this.props.children
        )}
      </Sentry.ErrorBoundary>
    );
  },

  renderProductFruits() {
    return (
      <ProductFruits
        user={this.state.currentAdmin}
        sapiToken={this.state.sapiToken}
        location={this.props.location}
        isDemoPreview={this.state.isDemoPreview}
      />
    );
  },

  renderConfetti() {
    if (!this.props.location.query || typeof this.props.location.query.celebrate === 'undefined') {
      return null;
    }

    return <Confetti router={this.props.router} location={this.props.location} />;
  },

  renderWelcome() {
    if (!('welcome' in this.props.location.query)) {
      return null;
    }

    return <WelcomeModalGcp router={this.props.router} location={this.props.location} />;
  },

  renderProjectExpirationBar() {
    if (!this.state.hasSnowflakePartnerConnect || this.state.hasSnowflakePartnerConnectLimited) {
      return null;
    }

    return <Expiration expires={this.state.projectExpiration} showInStickyPanel />;
  },

  renderUpgradeSPCBar() {
    if (
      !this.state.hasSnowflakePartnerConnectLimited ||
      this.props.router.isActive(snowflakePartnerConnectRouteNames.UPGRADE_PAGE) ||
      this.state.readOnly
    ) {
      return null;
    }

    return <UpgradeSPCBar />;
  },

  renderSPCWelcomeModal() {
    if (!this.state.hasSnowflakePartnerConnect) {
      return null;
    }

    return (
      <React.Suspense fallback={null}>
        <WelcomeModal isUpgraded={!this.state.hasSnowflakePartnerConnectLimited} />
      </React.Suspense>
    );
  },

  renderDevButtons() {
    if (!IS_DEVELOPMENT) {
      return null;
    }

    return (
      <div
        style={{
          position: 'fixed',
          display: 'inline-block',
          left: '1em',
          bottom: '1em',
          backgroundColor: 'white',
          borderRadius: '.33em',
          border: 'thin solid #e3e9f3',
          fontFamily: 'monospace',
          lineHeight: '1.8em',
          padding: '0 .6em',
          zIndex: 3000,
        }}
      >
        <a href="/dev.html" title="Open local development settings">
          DEV
        </a>
        {' | '}
        <Link
          href={ApplicationStore.getSapiUrl() + window.location.pathname}
          title="Open in production"
        >
          PROD
        </Link>
      </div>
    );
  },

  identifyUser() {
    if (
      this.state.isSingleTenant ||
      !window.heap ||
      // we support both heap sdks - new one has getIdentity function, old one has identity property
      (window.heap.getIdentity?.() ?? window.heap.identity)
    ) {
      return;
    }

    window.heap.identify(this.state.currentAdmin.get('email'));

    const userProperties = { organization: this.state.currentOrganization.get('name') };

    if (isValidCampaignTarget(this.props.location.query.target)) {
      userProperties.landing_page = getCampaignTarget(this.props.location.query.target);
      userProperties.campaign = this.props.location.query.campaign;
    }

    window.heap.addUserProperties(userProperties);
  },
});

App.propTypes = {
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default App;
