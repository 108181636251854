import { FILTERS } from '@/constants';
import {
  KEBOOLA_DATA_APPS,
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_SANDBOXES,
  TRANSFORMATION,
} from '@/constants/componentIds';
import dayjs from '@/date';
import {
  hasGenericDockerUI,
  hasGenericTemplatesUI,
  hasGenericUI,
} from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import ApplicationStore from '@/stores/ApplicationStore';
import { matchByWords } from '@/utils';
import type { Configuration, DeletedComponent, FilterValues } from './types';

const getDisabledRestoreReason = (componentId: string) => {
  if ([KEBOOLA_SANDBOXES, KEBOOLA_DATA_APPS].includes(componentId)) {
    return `${
      componentId === KEBOOLA_SANDBOXES ? 'Workspaces' : 'Data Apps'
    } are shortlived interactive development environments. Once deleted they cannot be restored.`;
  }

  if (isObsoleteComponent(componentId)) {
    return 'The configuration of an obsolete component cannot be restored.';
  }

  return '';
};

const hasDisabledRestore = (componentId: string) => !!getDisabledRestoreReason(componentId);

const isObsoleteComponent = (componentId: string) => {
  const component = ComponentsStore.getComponent(componentId);

  if (!component || [TRANSFORMATION, KEBOOLA_SANDBOXES].includes(componentId)) {
    return false;
  }

  return (
    component.get('uri') &&
    !hasGenericUI(component) &&
    !hasGenericDockerUI(component) &&
    !hasGenericTemplatesUI(component)
  );
};

const getFilteredConfigurations = (
  component: {
    configurations?: Configuration[];
  },
  searchQuery: string,
) => {
  let configurations = component.configurations || [];

  if (searchQuery) {
    configurations = configurations.filter((configuration) => {
      return matchByWords(
        [configuration.id, configuration.name, configuration.description ?? ''],
        searchQuery,
      );
    });
  }

  if (configurations.length === 0) {
    configurations = component.configurations || [];
  }

  return configurations.sort((a, b) => {
    const createdA = dayjs(a.currentVersion.created).unix();
    const createdB = dayjs(b.currentVersion.created).unix();
    return createdB - createdA;
  });
};

const getFilteredComponents = (
  filter: FilterValues,
  searchQuery: string,
  deletedComponents: DeletedComponent[],
  adminEmail: string,
) => {
  let components = deletedComponents;

  if (filter === FILTERS.COMPONENTS) {
    components = components
      .map((component) => ({
        ...component,
        configurations: component.configurations.filter(
          (config) => config.creatorToken.description === adminEmail,
        ),
      }))
      .filter((component) => component.configurations.length !== 0);
  }

  if (searchQuery) {
    components = components.filter((component) => {
      if (matchByWords([component?.id, component?.name], searchQuery)) {
        return true;
      }

      return component.configurations.some((configuration) => {
        return matchByWords(
          [configuration.id, configuration.name, configuration.description ?? ''],
          searchQuery,
        );
      });
    });
  }

  return components.sort((component1, component2) =>
    component1.name.toLowerCase().localeCompare(component2.name.toLowerCase()),
  );
};

const mapOrchestratorToFlow = (component: DeletedComponent) => ({ ...component, name: 'Flow' });

const containsOrchestratorWithFlows = (data: DeletedComponent[]) =>
  ApplicationStore.hasFlows() && data.some((component) => component.id === KEBOOLA_ORCHESTRATOR);

export {
  getDisabledRestoreReason,
  hasDisabledRestore,
  isObsoleteComponent,
  getFilteredConfigurations,
  getFilteredComponents,
  mapOrchestratorToFlow,
  containsOrchestratorWithFlows,
};
