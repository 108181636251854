const SERVICE_BILLING = 'billing';
const SERVICE_TELEMETRY = 'telemetry';
const SERVICE_DATA_SCIENCE = 'data-science';
const SERVICE_DOCKER_RUNNER = 'docker-runner';
const SERVICE_ENCRYPTION = 'encryption';
const SERVICE_IMPORT = 'import';
const SERVICE_OAUTH = 'oauth';
const SERVICE_QUEUE = 'queue';
const SERVICE_AI = 'ai';
const SERVICE_NOTIFICATION = 'notification';
const SERVICE_SANDBOXES = 'sandboxes';
const SERVICE_SCHEDULER = 'scheduler';
const SERVICE_SYNC_ACTIONS = 'sync-actions';
const SERVICE_SYRUP = 'syrup';
const SERVICE_SYRUP_PROVISIONING = 'provisioning';
const SERVICE_SYRUP_TRANSFORMATION = 'transformation';
const SERVICE_SYRUP_QUEUE = 'queue';
const SERVICE_SYRUP_ORCHESTRATOR = 'orchestrator';
const SERVICE_SYRUP_DOCKER = 'docker';
const SERVICE_TEMPLATES = 'templates';
const SERVICE_STREAM = 'stream';
const SERVICE_VAULT = 'vault';

export {
  SERVICE_BILLING,
  SERVICE_TELEMETRY,
  SERVICE_DATA_SCIENCE,
  SERVICE_DOCKER_RUNNER,
  SERVICE_ENCRYPTION,
  SERVICE_IMPORT,
  SERVICE_OAUTH,
  SERVICE_QUEUE,
  SERVICE_AI,
  SERVICE_NOTIFICATION,
  SERVICE_SANDBOXES,
  SERVICE_SCHEDULER,
  SERVICE_SYNC_ACTIONS,
  SERVICE_SYRUP,
  SERVICE_SYRUP_PROVISIONING,
  SERVICE_SYRUP_TRANSFORMATION,
  SERVICE_SYRUP_QUEUE,
  SERVICE_SYRUP_ORCHESTRATOR,
  SERVICE_SYRUP_DOCKER,
  SERVICE_TEMPLATES,
  SERVICE_STREAM,
  SERVICE_VAULT,
};
