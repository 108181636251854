import React from 'react';
import createReactClass from 'create-react-class';

import { canLoadSharedBuckets } from '@/modules/admin/privileges';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { SHARED_FROM_PROJECT_ROUTES, SHARED_WITH_YOU_ROUTES } from '@/modules/data-catalog/routes';
import { FilterPanel, TabLink, TabNav } from '@/react/common';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import MySharedBuckets from './MySharedBuckets';

const SharedFromThisProject = createReactClass({
  mixins: [createStoreMixin(StorageBucketsStore)],

  getStateFromStores() {
    return {
      sapiToken: ApplicationStore.getSapiToken(),
      mySharedBuckets: StorageBucketsStore.getAll().filter((bucket) => bucket.get('sharing')),
      availableUsersOptions: ApplicationStore.getSharingToAdminsData(),
      availableProjectsOptions: ApplicationStore.getSharingToProjectsData(),
      urlTemplates: ApplicationStore.getUrlTemplates(),
    };
  },

  getInitialState() {
    return {
      query: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
    };
  },

  render() {
    if (!canLoadSharedBuckets(this.state.sapiToken)) {
      return this.renderSharedBuckets();
    }

    return (
      <>
        <div className="tabs-with-border-wrapper">
          <TabNav>
            <TabLink to={SHARED_WITH_YOU_ROUTES.shared_with_you} useReactRouterLink>
              Shared with you
            </TabLink>
            <TabLink to={SHARED_FROM_PROJECT_ROUTES.shared_from_project} active useReactRouterLink>
              Shared from this project
            </TabLink>
          </TabNav>
        </div>
        {this.renderSharedBuckets()}
      </>
    );
  },

  renderSharedBuckets() {
    return (
      <>
        <FilterPanel
          placeholder={this.getPlaceholder}
          query={this.state.query}
          onChange={(query) => {
            this.setState({ query });
            RoutesStore.getRouter().updateQuery({ q: query });
          }}
        />
        <MySharedBuckets
          sharedBuckets={this.state.mySharedBuckets}
          sapiToken={this.state.sapiToken}
          availableUsersOptions={this.state.availableUsersOptions}
          availableProjectsOptions={this.state.availableProjectsOptions}
          query={this.state.query}
          urlTemplates={this.state.urlTemplates}
        />
      </>
    );
  },

  getPlaceholder() {
    return `Search data catalog (${this.state.mySharedBuckets.count()})`;
  },
});

export default SharedFromThisProject;
