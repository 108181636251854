import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';

import { Alert, HelpBlock, ProgressBar } from '@keboola/design';

import type { INITIAL_CSV_FORM_STATE } from '@/modules/storage/constants';
import { validateTableName } from '@/modules/storage/helpers';
import FilesDropZone from '@/react/common/FilesDropZone';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import PredefinedInput from './PredefinedInput';

type Props = {
  tables: Map<string, any>;
  values: typeof INITIAL_CSV_FORM_STATE;
  setValue: (key: string, value: string | File | null) => void;
  progress: number;
};

const CsvUploadForm = ({ tables, values, setValue, progress }: Props) => {
  if (progress && !values.error) {
    return <ProgressBar type="success" progress={progress} />;
  }

  return (
    <div>
      {values.error && (
        <Alert variant="error" className="tw-mb-5">
          {values.error}
        </Alert>
      )}
      <PredefinedInput
        autoFocus
        entity="tableName"
        value={values.name}
        warning={values.warning}
        onChange={(name) => {
          setValue('name', name);
          setValue('warning', validateTableName(name, tables));
        }}
      />
      <FormGroup>
        <FilesDropZone
          label="CSV file"
          onDrop={(files) => setValue('file', files[0])}
          files={values.file}
        />
        <HelpBlock className="tw-mt-1">Table structure will be set up from the CSV file.</HelpBlock>
      </FormGroup>
      <FormGroup>
        <ControlLabel>Delimiter</ControlLabel>
        <FormControl
          type="text"
          value={values.delimiter}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setValue('delimiter', event.target.value)
          }
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>Enclosure</ControlLabel>
        <FormControl
          type="text"
          value={values.enclosure}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setValue('enclosure', event.target.value)
          }
        />
      </FormGroup>
      <FormGroup>
        <ControlLabel>
          Primary key <OptionalFormLabel />
        </ControlLabel>
        <FormControl
          type="text"
          placeholder="Primary key column name"
          value={values.primaryKey}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setValue('primaryKey', event.target.value)
          }
        />
        <HelpBlock className="tw-mt-1">
          Primary key is useful for incremental imports - rows that already exist in the table are
          updated.
        </HelpBlock>
      </FormGroup>
    </div>
  );
};

export default CsvUploadForm;
