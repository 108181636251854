import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { FormGroup, HelpBlock, Label, PanelWithDetails, TextInput } from '@keboola/design';

import Checkbox from '@/react/common/Checkbox';

const NewDimensionForm = createReactClass({
  propTypes: {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
  },

  handleChange(newDiff) {
    this.props.onChange({ ...this.props.value, ...newDiff });
  },

  render() {
    const { disabled, value } = this.props;

    return (
      <form className="tw-flex tw-flex-col tw-gap-4">
        <FormGroup className="tw-grid tw-grid-cols-4 tw-items-start tw-gap-4">
          <Label htmlFor="name">Name</Label>
          <TextInput
            id="name"
            className="tw-col-span-3"
            variant="secondary"
            disabled={disabled}
            onChange={(name) => this.handleChange({ name })}
            value={value.name}
          />
        </FormGroup>

        <FormGroup className="tw-grid tw-grid-cols-4 tw-gap-4">
          <div className="tw-col-span-3 tw-col-start-2">
            <Checkbox
              checked={!!value.includeTime}
              onChange={() => this.handleChange({ includeTime: !value.includeTime })}
            >
              Include Time
            </Checkbox>
          </div>
        </FormGroup>

        <FormGroup className="tw-mt-6 tw-grid tw-grid-cols-4 tw-items-start">
          <Label htmlFor="template">Template</Label>
          <div className="tw-col-span-3 tw-flex tw-flex-col tw-gap-6">
            {this.renderRadio(
              'GoodData',
              'gooddata',
              'Default date dimension provided by GoodData',
            )}
            {this.renderRadio(
              'Keboola',
              'keboola',
              'Default date dimension provided by Keboola. Added all week setups: Mon-Sun, Tue-Mon, Wed-Tue, Thu-Wed, Fri-Thu, Sat-Fri, Sun-Sat + Boolean value whether its weekend or working day',
            )}
            {this.renderRadio(
              'Custom',
              'custom',
              'Provide your own template. You can generate the csv file containing all necessary details and provide it to GoodData.',
            )}
          </div>
        </FormGroup>

        {value.template !== 'keboola' && value.template !== 'gooddata' && (
          <FormGroup className="tw-grid tw-grid-cols-4 tw-items-start tw-gap-4">
            <Label htmlFor="template-id">Template ID</Label>
            <TextInput
              id="template-id"
              className="tw-col-span-3"
              variant="secondary"
              disabled={disabled}
              onChange={(templateId) => this.handleChange({ templateId })}
              value={value.templateId}
            />
          </FormGroup>
        )}

        <PanelWithDetails labelCollapse="Hide Advanced Options" labelOpen="Show Advanced Options">
          <FormGroup className="tw-grid tw-grid-cols-4 tw-items-start tw-gap-4">
            <Label htmlFor="identifier">Identifier</Label>
            <TextInput
              id="identifier"
              className="tw-col-span-3"
              variant="secondary"
              placeholder="optional"
              disabled={disabled}
              onChange={(identifier) => this.handleChange({ identifier })}
              value={value.identifier}
            />
          </FormGroup>
        </PanelWithDetails>
      </form>
    );
  },

  renderRadio(label, value, helpText) {
    return (
      <div className="tw-flex tw-flex-col tw-gap-1">
        <Radio
          className="tw-m-0"
          disabled={this.props.disabled}
          checked={this.props.value.template === value}
          onChange={() => this.handleChange({ template: value })}
          value={value}
          name="template"
        >
          {label}
        </Radio>
        <HelpBlock>{helpText}</HelpBlock>
      </div>
    );
  },
});

export default NewDimensionForm;
