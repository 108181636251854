import React from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';

import { Label, TextInput } from '@keboola/design';

import { PARAMETER_TYPE } from '@/modules/ex-generic/constants';
import PasswordControl from '@/react/common/PasswordControl';
import Select from '@/react/common/Select';
import FunctionControl from './FunctionControl';

const BOOLEAN_OPTIONS = [
  { label: 'True', value: true },
  { label: 'False', value: false },
];

const ParameterTypeInput = (props: {
  parameters: Map<string, any>;
  type: string;
  value: string | boolean | number;
  onChange: (value: string | boolean | number) => void;
  autoFocus: boolean;
}) => {
  const renderControl = () => {
    if (props.type === PARAMETER_TYPE.BOOLEAN) {
      return (
        <Select
          autoFocus={props.autoFocus}
          value={props.value}
          onChange={props.onChange}
          options={BOOLEAN_OPTIONS}
        />
      );
    }

    if (props.type === PARAMETER_TYPE.FUNCTION) {
      return (
        <FunctionControl
          autoFocus={props.autoFocus}
          parameters={props.parameters}
          value={props.value as string}
          onChange={props.onChange}
        />
      );
    }

    if (props.type === PARAMETER_TYPE.ENCRYPTED) {
      return (
        <>
          <Label htmlFor="encrypted-value">Encrypted Value</Label>
          <PasswordControl
            id="encrypted-value"
            autoFocus={props.autoFocus}
            value={props.value as string}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
          />
        </>
      );
    }

    if (props.type === PARAMETER_TYPE.STRING) {
      return (
        <>
          <Label htmlFor="value">Value</Label>
          <TextInput
            id="value"
            variant="secondary"
            autoFocus={props.autoFocus}
            value={`${props.value}`}
            onChange={(value) => props.onChange(value)}
          />
        </>
      );
    }

    return (
      <>
        <Label htmlFor="value">Value</Label>
        <FormControl
          id="value"
          autoFocus={props.autoFocus}
          type="number"
          value={props.value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
        />
      </>
    );
  };

  return <FormGroup>{renderControl()}</FormGroup>;
};

export default ParameterTypeInput;
