import React, { useState } from 'react';
import { OrderedMap } from 'immutable';

import { componentTypes } from '@/constants/componentTypes';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import NotificationsStore from '@/modules/notifications/store';
import JobsStore from '@/modules/queue/store';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import { FilterPanel } from '@/react/common';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationsTable from '@/react/common/ConfigurationsTable/Table';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { matchByWords } from '@/utils';
import { mergeSampleDataToConfigurations } from './helpers';

const Configurations = () => {
  const [filterQuery, setFilterQuery] = useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );

  const {
    componentId,
    allConfigurations,
    allComponents,
    componentsMetadata,
    allTables,
    component,
    latestJobs,
    readOnly,
    admins,
    currentAdmin,
    notifications,
    hasNewQueue,
    hasFlows,
  } = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const allComponents = ComponentsStore.getAll();

      return {
        componentId,
        allComponents,
        allTables: StorageTablesStore.getAll(),
        allConfigurations: mergeSampleDataToConfigurations(
          InstalledComponentsStore.getAll(),
          allComponents,
        ),
        componentsMetadata: InstalledComponentsStore.getAllMetadata(),
        component: ComponentsStore.getComponent(componentId),
        latestJobs: JobsStore.getLatestConfigJobs(),
        readOnly: ApplicationStore.isReadOnly(),
        admins: ApplicationStore.getAdmins(),
        currentAdmin: ApplicationStore.getCurrentAdmin(),
        notifications: NotificationsStore.getAll(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        hasFlows: ApplicationStore.hasFlows(),
      };
    },
    [],
    [
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageTablesStore,
      JobsStore,
      NotificationsStore,
      RoutesStore,
    ],
  );

  const configurations = React.useMemo(() => {
    return allConfigurations
      .getIn([componentId, 'configurations'], OrderedMap())
      .filter((configuration) => {
        return (
          configuration.get('id') === filterQuery ||
          matchByWords([configuration.get('name'), configuration.get('description')], filterQuery)
        );
      });
  }, [allConfigurations, componentId, filterQuery]);

  const getPlaceholder = () => {
    return `Search configurations (${configurations.count()})`;
  };

  return (
    <>
      <ConfigurationInfoPanel component={component} />
      {(filterQuery || !configurations.isEmpty()) && (
        <FilterPanel
          placeholder={getPlaceholder}
          query={filterQuery}
          onChange={(query) => {
            setFilterQuery(query);
            RoutesStore.getRouter().updateQuery({ q: query });
          }}
        />
      )}
      <ConfigurationsTable
        forceShowAll
        showMigrations
        showUsedIn
        admins={admins}
        currentAdmin={currentAdmin}
        notifications={notifications}
        showData={component.get('type') !== componentTypes.WRITER}
        latestJobs={latestJobs}
        allComponents={allComponents}
        allConfigurations={allConfigurations}
        readOnly={readOnly}
        hasNewQueue={hasNewQueue}
        hasFlows={hasFlows}
        configurations={configurations}
        component={component}
        tablesMetadataMap={prepareTablesMetadataMap(allTables)}
        componentsMetadata={componentsMetadata}
      />
    </>
  );
};

export default Configurations;
