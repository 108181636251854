import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'underscore';

import { IconButton } from '@keboola/design';

import type { AutomationMessage, UserMessage } from '@/api/routes/aiService';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { prepareProductionHref } from '@/modules/dev-branches/helpers';
import Builder from '@/modules/flows/components/Builder';
import useFlow from '@/modules/flows/hooks/useFlow';
import { Truncated } from '@/react/common';
import PromptInput from '@/react/common/AI/PromptInput';
import { AiIcon } from '@/react/common/AI/svgGradient';
import FullScreenModal, { FullScreenModalHeader } from '@/react/common/FullScreenModal';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { injectButtonStyles } from '@/utils/external/productFruits';
import Message from './interactive-elements/Message';
import { getAutomationMessages, sendMessage } from './actions';
import { routeNames } from './constants';
import { constructMessage, isInteractiveMessage } from './helpers';
import AutomationsStore from './store';

const Automation = () => {
  const store = useStores(
    () => {
      const automationId = RoutesStore.getCurrentRouteParam('id');
      const automation = AutomationsStore.getStore().automations.find(
        (automation) => automation.id === automationId,
      );

      return {
        automation,
        admin: ApplicationStore.getCurrentAdmin(),
      };
    },
    [],
    [ApplicationStore, AutomationsStore, RoutesStore],
  );
  const flowStore = useFlow(store.automation?.configurationId);
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState<AutomationMessage[]>([]);
  const flowConfiguration = flowStore.config?.get('configuration')?.toJS();

  const onClose = () => {
    window.location.href = prepareProductionHref(
      ApplicationStore.getProjectBaseUrl(),
      RoutesStore.getRouter().createHref(routeNames.ROOT),
      DevBranchesStore.getCurrentId(),
    );
  };

  const submitMessage = useCallback(
    (messageObject: Partial<UserMessage>) => {
      const message = constructMessage(messageObject, { flowConfiguration });

      if ((!message.text?.trim() && _.isEmpty(message.data)) || isLoading || !store.automation?.id)
        return Promise.resolve();

      setHistory((history) => [...history, message]);
      setPrompt((currentPrompt) => (currentPrompt === message.text ? '' : currentPrompt));
      setIsLoading(true);

      return sendMessage(store.automation.id, message)
        .then((response) => setHistory((history) => [...history, response]))
        .finally(() => setIsLoading(false));
    },
    [flowConfiguration, isLoading, store.automation],
  );

  useEffect(() => {
    if (!store.automation?.id) return;

    setIsLoading(true);
    getAutomationMessages(store.automation.id)
      .then((messages) =>
        setHistory((history) => {
          if (messages && history.length < messages.length) return messages;
          return history;
        }),
      )
      .finally(() => setIsLoading(false));
  }, [store.automation?.id]);

  useEffect(() => {
    if (window.productFruitsIsReady) {
      injectButtonStyles({ left: '15px' });
    } else {
      window.productFruitsReady = () => injectButtonStyles({ left: '15px' });
    }

    setTimeout(() => {
      const jiraWidgetElement = window.document.querySelector<HTMLElement>('#jsd-widget');

      if (!jiraWidgetElement) return;

      jiraWidgetElement.style.left = '26px';
    }, 1000);

    return () => injectButtonStyles({ left: 'unset' });
  }, []);

  if (!store.automation?.id) return null;

  return (
    <FullScreenModal className="[&_.modal-content]:tw-h-full [&_.modal-content]:tw-p-0 [&_.modal-content_>_div]:tw-flex [&_.modal-content_>_div]:tw-h-full [&_.modal-content_>_div]:tw-flex-col">
      <FullScreenModalHeader
        title={<Truncated text={store.automation?.name} />}
        onClose={onClose}
        className="tw-bg-white tw-px-5 tw-pt-5"
      >
        <IconButton
          icon="xmark"
          onClick={onClose}
          variant="outline"
          className="tw-ml-10 tw-shrink-0"
        />
      </FullScreenModalHeader>
      <Modal.Body className="tw-relative tw-flex tw-h-full tw-overflow-hidden tw-p-0">
        <div className="tw-flex tw-h-full tw-w-full tw-content-end tw-overflow-hidden">
          <div className="flow-container grid-background tw-relative tw-mr-[420px] tw-grow tw-overflow-auto">
            {!_.isEmpty(flowConfiguration) ? (
              <Builder
                forceAutosave
                config={flowStore.config}
                configId={flowStore.configId}
                readOnly={flowStore.readOnly}
                hasPayAsYouGo={flowStore.hasPayAsYouGo}
                showBackendSize={flowStore.showBackendSize}
                patternComponents={flowStore.allowedPatternComponents}
                hasJobsDynamicBackendSize={flowStore.hasJobsDynamicBackendSize}
                hasSnowflakeDynamicBackendSize={flowStore.hasSnowflakeDynamicBackendSize}
                hasDataApps={flowStore.hasDataApps}
                flowStatus={flowStore.flowStatus}
                components={flowStore.components}
                configurations={flowStore.configurations}
                visualizationPhases={flowStore.visualizationPhases}
                tablesMetadataMap={flowStore.tablesMetadataMap}
                tasks={flowStore.tasks}
                phases={flowStore.phases}
                folders={flowStore.folders}
                isChanged={flowStore.isChanged}
                hasSnowflakePartnerConnectLimited={flowStore.hasSnowflakePartnerConnectLimited}
                hasTemplates={flowStore.hasTemplates}
                isDevModeActive={flowStore.isDevModeActive}
              />
            ) : (
              <div className="tw-mx-auto tw-mt-14 tw-flex tw-w-[360px] tw-flex-col tw-gap-5 tw-text-center">
                <span className="tw-text-2xl">
                  Generate your first flow with the help of our AI Copilot.
                </span>
                <AiIcon className="tw-h-8" />
              </div>
            )}
          </div>
          <div className="tw-absolute tw-inset-y-0 tw-right-0 tw-ml-auto tw-flex tw-w-[420px] tw-flex-col tw-gap-3 tw-overflow-hidden tw-bg-white tw-px-5 tw-py-4">
            <span className="tw-text-xs tw-font-semibold tw-uppercase">Keboola AI Agent</span>
            <div className="-tw-mx-5 tw-flex tw-grow tw-flex-col tw-gap-2 tw-overflow-x-hidden tw-overflow-y-scroll tw-px-5 tw-break-anywhere">
              {history.map((messageObject: (typeof history)[number], index) => {
                if (
                  !messageObject.text &&
                  !(
                    messageObject.data &&
                    ['componentSelectionResponse', 'sourceTableSelectionResponse'].includes(
                      messageObject.type,
                    )
                  )
                ) {
                  return null;
                }

                return (
                  <Message
                    key={index}
                    automationId={store.automation?.id}
                    admin={store.admin}
                    messageObject={messageObject}
                    submitMessage={submitMessage}
                    flowConfigId={flowStore.configId}
                    isLastMessage={index === history.length - 1}
                  />
                );
              })}
              {isLoading && <Message />}
            </div>
            <PromptInput
              autoFocus
              multiLine
              placeholder="Write Message"
              prompt={prompt}
              onChange={setPrompt}
              onSubmit={() => submitMessage({ text: prompt })}
              isDisabled={!prompt.trim() || isLoading}
              isDisabledInput={isInteractiveMessage(history.at(-1))}
              className="tw-mx-auto tw-w-full tw-max-w-4xl"
              variant="narrow"
            />
          </div>
        </div>
      </Modal.Body>
    </FullScreenModal>
  );
};

export default Automation;
