import React from 'react';
import { FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cardAmericanExpress from '../../../../images/credit-card-american-express.png';
import cardDiscover from '../../../../images/credit-card-discover.png';
import cardMasterCard from '../../../../images/credit-card-mastercard.png';
import cardVisa from '../../../../images/credit-card-visa.png';

import { removeCard, updateTopUp } from '@/modules/billing/actions';
import Confirm from '@/react/common/Confirm';
import type { TopUpSetting } from './TopUpForm';

type Card = {
  id: string;
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
  funding: 'credit' | 'debit' | 'prepaid' | 'unknown';
};

const SavedCardInfo = (props: { card: Card; setting?: { enabled: boolean } & TopUpSetting }) => {
  let icon;

  switch (props.card.brand) {
    case 'visa':
      icon = cardVisa;
      break;
    case 'amex':
      icon = cardAmericanExpress;
      break;
    case 'discover':
      icon = cardDiscover;
      break;
    case 'mastercard':
      icon = cardMasterCard;
  }

  return (
    <div className="saved-card">
      <FormControl.Static className="flex-container form-control mb-1">
        {icon ? (
          <img src={icon} loading="lazy" alt={icon} />
        ) : (
          <FontAwesomeIcon icon="credit-card-blank" className="f-24" />
        )}
        XXXX - XXXX - XXXX - {props.card.last4}
        <span className="text-muted ml-auto">
          Valid thru {props.card.exp_month}/{props.card.exp_year}
        </span>
      </FormControl.Static>
      <Confirm
        closeAfterResolve
        onConfirm={() => {
          return removeCard(props.card.id).then(() => {
            if (props.setting?.enabled) {
              return updateTopUp({ ...props.setting, enabled: false });
            }
          });
        }}
        icon="credit-card-blank"
        title="Remove Credit Card"
        text="Are you sure you want to remove credit card details?"
        buttonLabel="Remove Credit Card"
        buttonType="danger"
        childrenRootElement="button"
        childrenRootElementClass="btn btn-default btn-block"
        modalSize="sm"
      >
        Remove Credit Card
      </Confirm>
    </div>
  );
};

export default SavedCardInfo;
