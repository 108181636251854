import React from 'react';

import { cn } from '@keboola/design';

const TableDivideRow = (props: { children: React.ReactNode; className?: string }) => {
  return (
    <div className="tr divide-row">
      <span className={cn('divide-row-label no-wrap', props.className)}>{props.children}</span>
    </div>
  );
};

export default TableDivideRow;
