let _baseUrl: string = '';
let _authHeaders: Record<string, string> = {};
let _fetch: typeof window.fetch = window.fetch;
export function init(baseUrl: string, ApiKeyAuth: string, fetch: typeof window.fetch) {
  _baseUrl = baseUrl;
  _authHeaders = { 'X-StorageApi-Token': ApiKeyAuth };
  _fetch = fetch;
}
export type ErrorExplanationRequest = { error?: string | undefined; jobId: string };
export type ConfigurationDescriptionRequest = {
  branchId: string;
  componentId: string;
  configId: string;
  rowId?: string | undefined;
};
export type ErrorExplanation = { answer: string; correlationId: string };
export type ObjectDescription = { description: string; correlationId: string };
export type ComponentSuggestionRequest = { prompt: string };
export type SuggestedComponent = { componentId: string; score: number };
export type ComponentSuggestion = {
  components: { componentId: string; score: number }[];
  correlationId: string;
};
export type Feedback = {
  value: 'good' | 'bad' | 'cancel';
  correlationId?: string | undefined;
  comment?: string | undefined;
  subject?:
    | DescribeConfigurationFeedbackSubject
    | ExplainFeedbackSubject
    | ConversationFeedbackSubject
    | ComponentSuggestionsFeedbackSubject
    | undefined;
};
export type ExplainFeedbackSubject = { type?: 'failed-job' | undefined; jobId: string };
export type DescribeConfigurationFeedbackSubject = {
  type?: 'configuration' | undefined;
  branchId: string;
  componentId: string;
  configId: string;
  rowId?: string | undefined;
};
export type ConversationFeedbackSubject = {
  type?: 'conversation' | undefined;
  conversationId: string;
};
export type ComponentSuggestionsFeedbackSubject = {
  type?: 'component-suggestions' | undefined;
  searchTerm: string;
};
export type ConversationCreateRequest = { branchId: string; componentId: string; configId: string };
export type ConversationGenerateQueryRequest = {
  prompt: string;
  existingQuery?: string | undefined;
};
export type ConversationGenerateQueryResponse = {
  query: string;
  queryUnderstanding: string;
  queryReformulation: string;
};
export type ConversationExecuteQueryRequest = { query: string };
export type ConversationExecuteQueryResponse = {
  columns: string[];
  rows: { columnName: string; value: string; isTruncated: boolean }[][];
};
export type ConversationExecuteTransformationRequest = {
  transformation: {
    parameters: { blocks: { name: string; codes: { name: string; script: string[] }[] }[] };
    storage: { input: { tables: any[] } };
  };
  output_table?: string | undefined;
};
export type GetOutputTablesRequest = { queries: string[] };
export type Conversation = {
  id: string;
  createdAt: string;
  updatedAt: string;
  status: 'provisioning' | 'loading' | 'ready' | 'executing' | 'failed';
  sandboxCreateJobId: string;
  sandboxWorkspaceLoadJobId?: number | undefined;
  sandboxDeleteJobId?: string | undefined;
  sandboxId: number;
};
export type AutomationDetail = {
  id?: string | undefined;
  name?: string | undefined;
  branchId?: string | undefined;
  configurationId?: string | undefined;
  status?: ('provisioningSandbox' | 'creatingBranch' | 'ready' | 'failed' | 'deleted') | undefined;
  createdAt?: string | undefined;
  createdByToken?: { id: string; name: string } | undefined;
  updatedAt?: string | undefined;
  conversations?: Conversation[] | undefined;
};
export type MessageTypesAgent =
  | 'plainText'
  | 'conversationStarted'
  | 'componentSelectionRequest'
  | 'sourceTableSelectionRequest'
  | 'intentConfirmationRequest'
  | 'flowGenerated'
  | 'transformationGenerated';
export type Context = {
  options?: string[] | undefined;
  selectedValue?: string | undefined;
  selectedValues?: string[] | undefined;
  taskId?: string | undefined;
  componentId?: string | undefined;
  configurationId?: string | undefined;
  inputs?: any[] | undefined;
  configuration?: any | undefined;
  intentSummary?: string | undefined;
};
export type AgentMessage = {
  text: string | null;
  role: 'agent';
  type: MessageTypesAgent;
  data: Context;
};
export type MessageTypesUser =
  | 'plainText'
  | 'componentSelectionResponse'
  | 'sourceTableSelectionResponse'
  | 'intentConfirmationResponse'
  | 'flowConfigurationInProgress'
  | 'configurationEditStarted'
  | 'configurationEditInProgress'
  | 'configurationEditFinished';
export type UserMessage = {
  text: string | null;
  role: 'user';
  type: MessageTypesUser;
  data: Context;
};
export type AutomationMessage = AgentMessage | UserMessage;
export type AutomationMessagesListResponse = { messages?: AutomationMessage[] | undefined };
export type Error = {
  error: string;
  code: number;
  status: 'error';
  exceptionId?: string | undefined;
};
/**
 * Returns basic information about the service.
 */
export function index(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Checks the service is up & running.
 */
export function healthCheck(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/health-check';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Receives an error message and returns a generated explanation.
 */
export function explainError(body: ErrorExplanationRequest): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/explain';
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Generates a description for a configuration.
 */
export function describeConfiguration(body: ConfigurationDescriptionRequest): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/describe/configuration';
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Generates a list of suggested components for a given natural query.
 */
export function suggestComponent(body: ComponentSuggestionRequest): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/suggest/component';
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Records the provided feedback.
 */
export function feedback(body: Feedback): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/feedback';
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Initiate a conversation backend.
 */
export function createConversation(body: ConversationCreateRequest): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/conversations';
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Get conversation detail.
 */
export function getConversation(params: { conversationId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/conversations/{conversationId}'.replace(
    '{conversationId}',
    params['conversationId'],
  );
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Delete conversation.
 */
export function deleteConversation(params: { conversationId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/conversations/{conversationId}'.replace(
    '{conversationId}',
    params['conversationId'],
  );
  return _fetch(url.toString(), { method: 'delete', headers: { ..._authHeaders } });
}
/**
 * Generate a new SQL query given a textual prompt.
 */
export function generateQuery(
  params: { conversationId: string },
  body: ConversationGenerateQueryRequest,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/conversations/{conversationId}/generate-query'.replace(
    '{conversationId}',
    params['conversationId'],
  );
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Execute SQL query and return the result.
 */
export function executeQuery(
  params: { conversationId: string },
  body: ConversationExecuteQueryRequest,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/conversations/{conversationId}/execute-query'.replace(
    '{conversationId}',
    params['conversationId'],
  );
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Execute transformation and store the result to be retrieved later by getLastResult.
 */
export function executeTransformation(
  params: { conversationId: string },
  body: ConversationExecuteTransformationRequest,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/conversations/{conversationId}/execute-transformation'.replace(
    '{conversationId}',
    params['conversationId'],
  );
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Get result of a transformation executed by previous executeTransformation call.
 */
export function getLastResult(params: { conversationId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/conversations/{conversationId}/get-last-result'.replace(
    '{conversationId}',
    params['conversationId'],
  );
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Analyzes a given SQL query and returns the names of all tables created by that query.
 */
export function getOutputTables(
  params: { id: string },
  body: GetOutputTablesRequest,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/conversations/{id}/get-output-tables'.replace('{id}', params['id']);
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Retrieve a list of all automations available in the project.
 */
export function getAutomations(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/automations';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Create new automation
 */
export function createAutomation(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/automations';
  return _fetch(url.toString(), { method: 'post', headers: { ..._authHeaders } });
}
/**
 * Get automation detail
 */
export function getAutomation(params: { automationId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/automations/{automationId}'.replace('{automationId}', params['automationId']);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Deletes an automation identified by its ID
 */
export async function deleteAutomation(params: { automationId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/automations/{automationId}'.replace('{automationId}', params['automationId']);
  const response = await _fetch(url.toString(), { method: 'delete', headers: { ..._authHeaders } });
  return response;
}
/**
 * It automatically selects appropriate conversation to pull the messages from
 */
export function getAutomationMessages(params: { automationId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/automations/{automationId}/messages'.replace(
    '{automationId}',
    params['automationId'],
  );
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Posts a message to a specific automation identified by `automationId`.
 */
export function postMessageToAutomation(
  params: { automationId: string },
  body: UserMessage,
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/automations/{automationId}/messages'.replace(
    '{automationId}',
    params['automationId'],
  );
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
