import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import { routeNames as transformationRouteNames } from '@/modules/transformations-v2/constants';
import { RouterLink } from '@/react/common';
import Select from '@/react/common/Select';

class LinkedModelsBox extends React.Component {
  render() {
    const linkedModels = this.props.models.filter((model) => {
      return model.getIn(['deployment', 'linkedConfigurations'], List()).some((link) => {
        return (
          link.get('componentId') === this.props.componentId &&
          link.get('configurationId') === this.props.configId
        );
      });
    });

    if (linkedModels.isEmpty()) {
      return null;
    }

    return (
      <div className="box">
        <div className="box-header big-padding smaller-height">
          <h2 className="box-title">Linked ML/AI Models</h2>
        </div>
        <div className="box-content pt-0">
          <Select
            multi
            disabled
            value={linkedModels.map((model) => model.get('name')).toArray()}
            multiValueRenderer={({ data }) => {
              return (
                <RouterLink to={transformationRouteNames.MODELS} className="bigger-label clickable">
                  {data.value}
                </RouterLink>
              );
            }}
            placeholder="No linked models"
          />
        </div>
      </div>
    );
  }
}

LinkedModelsBox.propTypes = {
  models: PropTypes.instanceOf(Map).isRequired,
  componentId: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
};

export default LinkedModelsBox;
