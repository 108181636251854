import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import { List, Map } from 'immutable';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

class ChangeOrganizationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      organization: '',
      isSaving: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() =>
          this.setState({ organization: this.props.project.getIn(['organization', 'id']) })
        }
      >
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Change Project Organization</Modal.Title>
            <ModalIcon icon="pen" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Organization</ControlLabel>
              <Select
                clearable={false}
                value={this.state.organization}
                onChange={(value) => this.setState({ organization: value })}
                options={this.props.organizations
                  .groupBy((organization) => organization.getIn(['maintainer', 'id']))
                  .sortBy((organizations) => organizations.getIn([0, 'maintainer', 'name']))
                  .map((organizations) => {
                    const maintainerName = organizations.getIn([0, 'maintainer', 'name']);

                    return {
                      label: maintainerName,
                      options: organizations
                        .sortBy((organization) => organization.get('name'))
                        .map((organization) => {
                          return {
                            value: organization.get('id'),
                            label: organization.get('name'),
                          };
                        })
                        .toArray(),
                    };
                  })
                  .toArray()}
                disabled={this.state.isSaving}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={this.state.isSaving ? 'Changing organization...' : 'Changes organization'}
              isSaving={this.state.isSaving}
              isDisabled={
                !this.state.organization ||
                this.state.organization ===
                  this.props.project.getIn(['organization', 'id'], '').toString()
              }
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isSaving: true });
    this.props
      .onSubmit(this.state.organization, this.props.project.getIn(['organization', 'id']))
      .then(this.props.onHide)
      .finally(() => this.setState({ isSaving: false }));
  }
}

ChangeOrganizationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  project: PropTypes.instanceOf(Map).isRequired,
  organizations: PropTypes.instanceOf(List).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChangeOrganizationModal;
