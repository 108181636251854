import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { cn, HelpBlock } from '@keboola/design';

import { sheetFullName } from '@/modules/ex-google-drive/common';
import ProcessorControls from '@/modules/ex-google-drive/react/components/ProcessorControls';
import { nameWarning } from '@/modules/storage/constants';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import string from '@/utils/string';

const OutputTableModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    onHideFn: PropTypes.func.isRequired,
    localState: PropTypes.object.isRequired,
    savedSheets: PropTypes.object.isRequired,
    updateLocalState: PropTypes.func.isRequired,
    onSaveSheetFn: PropTypes.func.isRequired,
    enableDeprecatedGoogleDriveTranspose: PropTypes.bool.isRequired,
  },

  render() {
    const sheet = this.props.localState.get('sheet', Map());
    const documentTitle = sheetFullName(sheet, ' / ');
    const headerRowValue = this.headerRowValue();

    return (
      <Modal bsSize="large" show={this.props.show} onHide={this.props.onHideFn}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Extraction of {documentTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.renderEdit()}</Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={this.props.isSaving}
            onSave={this.handleSave}
            saveLabel="Save"
            isDisabled={
              Boolean(this.invalidReason()) || isNaN(headerRowValue) || headerRowValue < 1
            }
          />
        </Modal.Footer>
      </Modal>
    );
  },

  renderEdit() {
    const value = this.outputTableValue();
    const sanitized = string.sanitizeKbcTableIdString(value);
    const err = this.invalidReason();

    return (
      <div className="form-horizontal">
        <div className={cn('form-group', { 'has-error': !!err })}>
          <label htmlFor="outputTable" className="control-label col-sm-4">
            Output Table
          </label>
          <div className="col-sm-8">
            <input
              id="outputTable"
              type="text"
              className="form-control"
              value={value}
              onChange={this.onChange}
            />
            {err && <span className="help-block">{err}</span>}
            {sanitized !== value && (
              <span className="help-block">Table name will be sanitized to {sanitized}</span>
            )}
            <HelpBlock className="tw-mt-1">{nameWarning}</HelpBlock>
          </div>
        </div>

        {(this.props.localState.hasIn(['processor', 'definition']) ||
          this.props.enableDeprecatedGoogleDriveTranspose) && (
          <div className="form-group">
            <div className="col-sm-8 col-sm-offset-4">
              <Checkbox
                checked={this.transposeEnabledValue()}
                onChange={this.toggleTransposeEnabled}
              >
                Enable Output Processor
              </Checkbox>
            </div>
          </div>
        )}
        {this.transposeEnabledValue() ? this.renderProcessorControls() : ''}
      </div>
    );
  },

  renderProcessorControls() {
    return (
      <ProcessorControls
        headerRowValue={this.headerRowValue()}
        onChangeHeaderRow={this.onChangeHeaderRow}
        headerColumnNamesValue={this.headerColumnNamesValue()}
        onChangeHeaderColumnNames={this.onChangeHeaderColumnNames}
        transposeHeaderRowValue={this.transposeHeaderRowValue()}
        onChangeTransposeHeaderRow={this.onChangeTransposeHeaderRow}
        transposedHeaderColumnNameValue={this.transposedHeaderColumnNameValue()}
        onChangeTransposedHeaderColumnName={this.onChangeTransposedHeaderColumnName}
        transposeFromValue={this.transposeFromValue()}
        onChangeTransposeFrom={this.onChangeTransposeFrom}
      />
    );
  },

  handleSave() {
    const sanitized = string.sanitizeKbcTableIdString(this.outputTableValue());
    const sheet = this.props.localState.get('sheet');
    this.props.updateLocalState('dontValidate', true);
    const newSheet = sheet
      .set('outputTable', sanitized)
      .setIn(['header', 'rows'], this.headerRowValue());
    const processor = this.props.localState.get('processor');
    const newProcessor = this.transposeEnabledValue()
      ? processor
          .setIn(['transpose'], this.transposeEnabledValue())
          .setIn(['header_rows_count'], this.headerRowValue())
          .setIn(['header_column_names'], this.headerColumnNamesValue())
          .setIn(['header_transpose_row'], this.transposeHeaderRowValue())
          .setIn(['header_transpose_column_name'], this.transposedHeaderColumnNameValue())
          .setIn(['transpose_from_column'], this.transposeFromValue())
      : Map();
    return this.props.onSaveSheetFn(newSheet, newProcessor).then(this.props.onHideFn);
  },

  outputTableValue() {
    const defaultValue = this.props.localState.getIn(['sheet', 'outputTable'], '');
    return this.props.localState.get('value', defaultValue).trim();
  },

  headerRowValue() {
    const defaultValue = this.props.localState.getIn(['sheet', 'header', 'rows'], 1);
    return this.props.localState.get('headerRow', defaultValue);
  },

  onChangeHeaderRow(e) {
    const newVal = parseInt(e.target.value, 10);
    this.props.updateLocalState('headerRow', newVal);
  },

  transposeEnabledValue() {
    const defaultValue = this.props.localState.getIn(
      ['processor', 'parameters', 'transpose'],
      false,
    );
    return this.props.localState.get('transposeEnabled', defaultValue);
  },

  toggleTransposeEnabled() {
    const defaultValue = !!this.props.localState.get('transposeEnabled', false);
    this.props.updateLocalState('transposeEnabled', !defaultValue);
  },

  headerColumnNamesValue() {
    const defaultValue = this.props.localState.getIn([
      'processor',
      'parameters',
      'header_column_names',
    ]);

    return this.props.localState.get('headerColumnNames', defaultValue || List()).toJS();
  },

  onChangeHeaderColumnNames(columnsArray) {
    this.props.updateLocalState('headerColumnNames', columnsArray);
  },

  transposeHeaderRowValue() {
    const defaultValue = this.props.localState.getIn(
      ['processor', 'parameters', 'header_transpose_row'],
      0,
    );
    return this.props.localState.get('transposeHeaderRow', defaultValue);
  },

  onChangeTransposeHeaderRow(e) {
    const maxVal = this.props.localState.get('headerRow', 1);
    const newVal = e.target.value > maxVal ? maxVal : e.target.value;
    this.props.updateLocalState('transposeHeaderRow', newVal);
  },

  transposedHeaderColumnNameValue() {
    const defaultValue = this.props.localState.getIn(
      ['processor', 'parameters', 'header_transpose_column_name'],
      '',
    );
    return this.props.localState.get('transposedHeaderColumnName', defaultValue).trim();
  },

  onChangeTransposedHeaderColumnName(e) {
    const newVal = e.target.value;
    this.props.updateLocalState('transposedHeaderColumnName', newVal);
  },

  transposeFromValue() {
    const defaultValue = this.props.localState.getIn(
      ['processor', 'parameters', 'transpose_from_column'],
      0,
    );
    return this.props.localState.get('transposeFrom', defaultValue);
  },

  onChangeTransposeFrom(e) {
    const newVal = e.target.value;
    this.props.updateLocalState('transposeFrom', newVal);
  },

  invalidReason() {
    if (this.props.localState.get('dontValidate')) return null;
    const value = this.outputTableValue();
    if (!value || value.length === 0) return 'Can not be empty.';
    const sanitized = string.sanitizeKbcTableIdString(value);
    const savedValue = this.props.localState.getIn(['sheet', 'outputTable']);
    if (
      savedValue !== sanitized &&
      this.props.savedSheets.find((s) => s.get('outputTable') === sanitized)
    )
      return `Table name ${sanitized} already exists in the configuration.`;
    return null;
  },

  onChange(e) {
    const newVal = e.target.value;
    this.props.updateLocalState('value', newVal);
  },
});

export default OutputTableModal;
