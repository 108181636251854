import { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import componentsActions from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';

const getLocalState = (configId) => {
  return InstalledComponentsStore.getLocalState(KEBOOLA_ORCHESTRATOR, configId);
};

const getLocalStateValue = (configId, path, defaultValue) => {
  return getLocalState(configId).getIn([].concat(path), defaultValue);
};

const updateLocalStateValue = (configId, path, data) => {
  componentsActions.updateLocalState(
    KEBOOLA_ORCHESTRATOR,
    configId,
    getLocalState(configId).setIn([].concat(path), data),
  );
};

const clearLocalState = (configId) => {
  componentsActions.updateLocalState(KEBOOLA_ORCHESTRATOR, configId, Map());
};

export { getLocalStateValue, updateLocalStateValue, clearLocalState };
