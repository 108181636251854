import { MAPPINGS_COLLAPSIONS } from '@/constants/localStorageKeys';
import ApplicationStore from '@/stores/ApplicationStore';
import { getItem, setItem } from '@/utils/localStorage';

const getMappingKey = (
  componentId: string,
  configId: string,
  rowId: string,
  mappingKey: string,
) => {
  const currentProjectId = ApplicationStore.getCurrentProjectId();

  return `${currentProjectId}-${componentId}-${configId}-${rowId}-${mappingKey}`;
};

export const setMappingCollapsion = (
  componentId: string,
  configId: string,
  rowId: string,
  mappingKey: string,
  value?: boolean,
) => {
  const collapsions = getItem(MAPPINGS_COLLAPSIONS, {});
  const key = getMappingKey(componentId, configId, rowId, mappingKey);
  setItem(MAPPINGS_COLLAPSIONS, { ...collapsions, [key]: value ?? false });
};

export const getMappingCollapsion = (
  componentId: string,
  configId: string,
  rowId: string,
  mappingKey: string,
) => {
  const collapsions = getItem(MAPPINGS_COLLAPSIONS, {});
  const key = getMappingKey(componentId, configId, rowId, mappingKey);
  return collapsions[key] ?? false;
};
