import React from 'react';
import type { Map } from 'immutable';

import { Alert } from '@keboola/design';

import { usingTwoVersionOfApi } from '@/modules/google-utils/helpers';

const TwoVersionsApiWarning = ({ profiles }: { profiles: Map<string, any> }) => {
  if (!usingTwoVersionOfApi(profiles)) {
    return null;
  }

  return (
    <Alert variant="warning" className="tw-mb-5">
      Using two versions of API is not supported. Please choose only profiles with or without{' '}
      <strong>GA4 - Data API</strong> or switch to an advanced mode, where you will have more
      options for how to set up your connection.
    </Alert>
  );
};

export default TwoVersionsApiWarning;
