import React from 'react';
import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import { Badge } from '@keboola/design';

import configurationsActions from '@/modules/configurations/ConfigurationsActionCreators';
import ConfigurationRowsTable from '@/modules/configurations/react/components/ConfigurationRowsTable';
import columnTypes from '@/modules/configurations/utils/columnTypeConstants';
import * as actionsProvisioning from '@/modules/ex-db-generic/actionsProvisioning';

const QueryTable = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    queries: PropTypes.object.isRequired,
    filter: PropTypes.string.isRequired,
    configuration: PropTypes.instanceOf(Map).isRequired,
    component: PropTypes.instanceOf(Map).isRequired,
    pendingActions: PropTypes.object.isRequired,
    isRowConfiguration: PropTypes.bool,
  },

  getInitialState() {
    return {
      orderPending: Map(),
    };
  },

  render() {
    return (
      <div className="box">
        <ConfigurationRowsTable
          readOnly={this.props.readOnly}
          columns={fromJS([
            {
              name: 'Source',
              type: columnTypes.VALUE,
              value: function (row) {
                if (row.get('query')) {
                  return <Badge text="SQL" />;
                }

                if (row.getIn(['table', 'schema']) && row.getIn(['table', 'tableName'])) {
                  return `${row.getIn(['table', 'schema'])}.${row.getIn(['table', 'tableName'])}`;
                }

                return '';
              },
            },
            {
              name: 'Destination',
              type: columnTypes.TABLE_LINK,
              value: function (row) {
                return row.get('outputTable');
              },
            },
            {
              name: 'Load Options',
              type: columnTypes.VALUE,
              value: function (row) {
                return (
                  <>
                    {row.get('primaryKey', List()).count() > 0 && (
                      <>
                        <small>Primary Key:</small> {row.get('primaryKey', []).join(', ')}
                        <br />
                      </>
                    )}
                    {row.get('incremental') && <Badge text="Incremental" />}
                  </>
                );
              },
            },
          ])}
          component={this.props.component}
          config={this.props.configuration}
          rows={this.props.queries}
          rowLinkTo={'ex-db-generic-' + this.props.component.get('id') + '-query'}
          rowDeletePending={(rowId) => !!this.props.pendingActions.getIn([rowId, 'deleteQuery'])}
          rowDelete={(rowId) => this.handleDelete(rowId)}
          rowEnableDisablePending={(rowId) => !!this.props.pendingActions.getIn([rowId, 'enabled'])}
          rowEnableDisable={(rowId) => this.handleActiveChange(rowId)}
          getRowRunParams={(row) => this.prepareRunParams(row)}
          disabledMove={this.props.filter !== ''}
          onOrder={(rowIds, movedRowId) => this.handleOrder(rowIds, movedRowId)}
          orderPending={this.state.orderPending}
          isRowConfiguration={this.props.isRowConfiguration}
        />
      </div>
    );
  },

  handleOrder(rowIds, movedRowId) {
    this.setState({ orderPending: this.state.orderPending.set(movedRowId, true) });

    return configurationsActions
      .orderRows(
        this.props.component.get('id'),
        this.props.configuration.get('id'),
        rowIds,
        movedRowId,
      )
      .finally(() => {
        this.setState({ orderPending: this.state.orderPending.delete(movedRowId) });
      });
  },

  handleActiveChange(rowId) {
    const actionCreators = actionsProvisioning.createActions(this.props.component.get('id'));

    return actionCreators.changeQueryEnabledState(
      this.props.configuration.get('id'),
      rowId,
      !this.props.queries.find((query) => query.get('id') === rowId)?.get('enabled'),
    );
  },

  handleDelete(rowId) {
    const actionCreators = actionsProvisioning.createActions(this.props.component.get('id'));

    return actionCreators.deleteQuery(this.props.configuration.get('id'), rowId);
  },

  prepareRunParams(row) {
    const actionCreators = actionsProvisioning.createActions(this.props.component.get('id'));

    return actionCreators.prepareSingleQueryRunData(
      this.props.configuration.get('id'),
      row,
      'index',
    );
  },
});

export default QueryTable;
