import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Promise } from 'bluebird';
import { fromJS, List, Map } from 'immutable';

import { IconButton } from '@keboola/design';

import JobsApi from '@/modules/queue/api';
import { JOBS_LIMIT_FOR_GRAPH } from '@/modules/queue/constants';
import { prepareGraphData } from '@/modules/queue/helpers';
import EmptyGraph from '@/react/common/EmptyGraph';
import type { GraphJob } from '@/react/common/Graph/types';
import type { State } from './JobsGraphMemo';
import JobsGraphMemo from './JobsGraphMemo';

const JobsGraphWithPaging = ({ jobsFilters = {}, ...props }) => {
  const [state, setStateRaw] = useState<State>({
    graphData: null,
    allJobRunsPaged: Map(),
    currentJobRunsPage: -1,
    loadingJobs: true,
    activeIndex: null,
  });

  useEffect(() => {
    if (!state.graphData && props.job) {
      loadInitialJobsAndInitGraph();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.graphData, props.job]);

  const setState = useCallback((state: Partial<State>) => {
    setStateRaw((s) => ({
      ...s,
      ...state,
    }));
  }, []);

  const prepareData = (pageNumber: number, allJobRunsPaged = state.allJobRunsPaged) => {
    return prepareGraphData(
      allJobRunsPaged.get(`${pageNumber}`, List()),
      { onlyJobs: true },
      props.job.get('id'),
    );
  };

  const loadInitialJobsAndInitGraph = () => {
    if (!props.job) {
      return;
    }

    return Promise.props({
      previousJobs: loadJobs({ limit: JOBS_LIMIT_FOR_GRAPH * 3, previous: true }),
      nextJobs: loadJobs({ limit: JOBS_LIMIT_FOR_GRAPH * 2 }),
    })
      .then(({ previousJobs: prev, nextJobs: next }) => {
        let previousJobs = prev;
        const nextJobs = next;

        if (previousJobs.length <= JOBS_LIMIT_FOR_GRAPH) {
          const availableSpaceInPreviousJobs = JOBS_LIMIT_FOR_GRAPH - previousJobs.length;
          const chunkToFitInPreviousJobs = nextJobs.splice(0, availableSpaceInPreviousJobs);

          previousJobs = [...previousJobs, ...chunkToFitInPreviousJobs];
        }

        return new Promise((resolve) => {
          const allJobRunsPaged = fromJS({
            ...(previousJobs?.length > 0 && {
              [-3]: previousJobs.slice(JOBS_LIMIT_FOR_GRAPH * 2, -1),
              [-2]: previousJobs.slice(JOBS_LIMIT_FOR_GRAPH, JOBS_LIMIT_FOR_GRAPH * 2),
              [-1]: previousJobs.slice(0, JOBS_LIMIT_FOR_GRAPH),
            }),
            ...(nextJobs?.length > 0 && {
              [0]: nextJobs.slice(0, JOBS_LIMIT_FOR_GRAPH),
              [1]: nextJobs.slice(JOBS_LIMIT_FOR_GRAPH, -1),
            }),
          });
          const currentJobRunsPage = previousJobs?.length > 0 ? -1 : 0;

          const graphData = prepareData(currentJobRunsPage, allJobRunsPaged);

          props.onInitialGraphDataLoad?.(graphData ?? Map());
          setState({ allJobRunsPaged, graphData, currentJobRunsPage });

          resolve();
        });
      })
      .finally(() => setState({ loadingJobs: false }));
  };

  const hasMorePreviousJobsAvailable = !state.allJobRunsPaged
    .get(`${state.currentJobRunsPage - 1}`, List())
    .isEmpty();

  const hasMoreNextJobsAvailable = !state.allJobRunsPaged
    .get(`${state.currentJobRunsPage + 1}`, List())
    .isEmpty();

  const getJobs = useCallback(() => {
    if (!state.graphData) {
      return [];
    }

    let jobs = state.graphData.get('jobs');
    const jobsCount = jobs.count();

    if (JOBS_LIMIT_FOR_GRAPH && jobsCount < JOBS_LIMIT_FOR_GRAPH) {
      const emptyEntries = fromJS(
        new Array(JOBS_LIMIT_FOR_GRAPH - jobsCount).fill({ duration: 0 }),
      );

      jobs =
        state.currentJobRunsPage > -2 && hasMorePreviousJobsAvailable
          ? jobs.concat(emptyEntries)
          : emptyEntries.concat(jobs);
      jobs = jobs.map((job: Map<string, any>, index: number) => job.set('index', index));
    }

    return jobs.toJS() as GraphJob[];
  }, [state.graphData, state.currentJobRunsPage, hasMorePreviousJobsAvailable]);

  const renderPageButton = (type: 'previous' | 'next') => {
    return (
      <IconButton
        variant="invisible"
        icon={type === 'previous' ? 'chevron-left' : 'chevron-right'}
        className="circle-button larger with-shadow"
        onClick={() => {
          const currentJobRunsPage = state.currentJobRunsPage + (type === 'previous' ? -1 : 1);

          setState({
            currentJobRunsPage,
            graphData: prepareData(currentJobRunsPage),
          });

          loadMoreJobs();
        }}
        disabled={state.loadingJobs}
      />
    );
  };

  const loadJobs = (options: Partial<{ offset: number; limit: number; previous: boolean }>) => {
    return JobsApi.getSiblingJobs(props.job, { ...jobsFilters, ...options });
  };

  const loadMoreJobs = () => {
    const shouldLoadPreviousJobs = state.currentJobRunsPage < 0;
    const newPageNumber = state.currentJobRunsPage + (shouldLoadPreviousJobs ? -2 : 2);
    const offset =
      (shouldLoadPreviousJobs ? Math.abs(newPageNumber) - 1 : newPageNumber) * JOBS_LIMIT_FOR_GRAPH;

    if (
      state.allJobRunsPaged.get(`${newPageNumber}`) ||
      state.allJobRunsPaged
        .get(`${newPageNumber + (shouldLoadPreviousJobs ? 1 : -1)}`, List())
        .isEmpty()
    )
      return;

    setState({ loadingJobs: true });

    return loadJobs({ offset, previous: shouldLoadPreviousJobs })
      .then((jobs) =>
        setState({
          allJobRunsPaged: state.allJobRunsPaged.set(`${newPageNumber}`, fromJS(jobs)),
        }),
      )
      .finally(() => setState({ loadingJobs: false }));
  };

  const jobsData = useMemo(() => getJobs(), [getJobs]);

  if (!props.job) {
    return null;
  }

  if (!state.graphData || state.graphData.get('jobs', List()).isEmpty()) {
    const noReference =
      props.job && !props.job.get('config')
        ? 'The graph cannot be displayed as the job has no configuration reference.'
        : null;

    const text = noReference || 'No jobs to display.';

    return (
      <div className="tw-flex tw-h-64 tw-items-center tw-justify-center">
        <EmptyGraph text={text} isLoading={state.loadingJobs} />
      </div>
    );
  }

  return (
    <div className="jobs-graph tw-flex tw-items-center">
      {hasMorePreviousJobsAvailable && renderPageButton('previous')}
      <div className="chart-box fill-space tw-h-64">
        <JobsGraphMemo
          jobsData={jobsData}
          activeIndex={state.activeIndex}
          jobId={props.job.get('id')}
          setState={setState}
        />
      </div>
      {hasMoreNextJobsAvailable && renderPageButton('next')}
    </div>
  );
};

export default JobsGraphWithPaging;
