import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import dayjs, { TIME_FORMAT } from '@/date';
import StorageApi from '@/modules/components/StorageApi';
import { createTrigger, updateTrigger } from '@/modules/event-trigger/actions';
import EventTrigger from '@/modules/event-trigger/components/EventTrigger';
import { crontabDefault, defaultTrigger } from '@/modules/event-trigger/constants';
import { createAndActivateScheduler, updateScheduler } from '@/modules/scheduler/actions';
import CronScheduler from '@/modules/scheduler/components/CronScheduler';
import CronSchedulerPreview from '@/modules/scheduler/components/CronSchedulerPreview';
import SchedulePredefinedButtons from '@/modules/scheduler/components/SchedulePredefinedButtons';
import ScheduleTimezone from '@/modules/scheduler/components/ScheduleTimezone';
import { scheduleInvokeType } from '@/modules/scheduler/constants';
import { getPeriodForCrontab } from '@/modules/scheduler/helpers';
import type { StoreSource } from '@/modules/stream/store';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

type Props = {
  show: boolean;
  onHide: () => void;
  configId: string;
  tables: Map<string, any>;
  buckets: Map<string, any>;
  hasDataStreams: boolean;
  dataStreamSources: StoreSource[];
  canManageSchedule: boolean;
  canManageTriggers: boolean;
  hasProtectedDefaultBranch: boolean;
  trigger?: Map<string, any>;
  scheduler?: Map<string, any>;
};

type State = {
  isSaving: boolean;
  trigger: Map<string, any>;
  showTriggerMode: boolean;
  cronTab: string;
  cronTabPeriod: string;
  timezone: string;
};

class ScheduleModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { isSaving: false, ...this.getTriggerData(), ...this.getCronTabData() };
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => this.setState({ ...this.getTriggerData(), ...this.getCronTabData() })}
        className="scheduler-modal"
      >
        <Modal.Header className="no-border" closeButton>
          <Modal.Title>
            {this.props.trigger
              ? 'Update Trigger'
              : this.props.scheduler
                ? 'Update Schedule'
                : 'Create Scheduler'}
          </Modal.Title>
          <ModalIcon icon="clock" color="green" bold />
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="schedule-form">
            {this.renderInvokeSelect()}
            {this.renderForm()}
          </div>
        </Modal.Body>
        <Modal.Footer>{this.renderButtons()}</Modal.Footer>
      </Modal>
    );
  }

  renderForm() {
    if (this.state.showTriggerMode) {
      return (
        <EventTrigger
          tables={this.props.tables}
          buckets={this.props.buckets}
          selected={this.state.trigger
            .get('tables', List())
            .map((table: Map<string, any>) => table.get('tableId'))
            .toArray()}
          period={this.state.trigger.get('coolDownPeriodMinutes').toString()}
          onAddTable={this.handleTriggerTableAdd}
          onRemoveTable={this.handleTriggerTableRemove}
          onChangePeriod={this.handleTriggerPeriodChange}
          hasDataStreams={this.props.hasDataStreams}
          dataStreamSources={this.props.dataStreamSources}
        />
      );
    }

    return (
      <>
        <SchedulePredefinedButtons
          onSelect={(cronTab: string) => {
            this.setState({ cronTab, cronTabPeriod: getPeriodForCrontab(cronTab) });
          }}
        />
        <CronScheduler
          crontabRecord={this.state.cronTab}
          crontabTimezone={this.state.timezone}
          period={this.state.cronTabPeriod}
          onChange={(cronTab: string, cronTabPeriod?: string) => {
            this.setState({ cronTab });

            if (cronTabPeriod) {
              this.setState({ cronTabPeriod });
            }
          }}
        />
        <div className="flex-container text-muted">
          <span>Current time is {dayjs.utc().format(TIME_FORMAT)} UTC.</span>
          <ScheduleTimezone
            onChange={(timezone: string) => this.setState({ timezone })}
            crontabTimezone={this.state.timezone}
          />
        </div>
        <CronSchedulerPreview
          crontabRecord={this.state.cronTab}
          timezone={this.state.timezone || 'UTC'}
        />
      </>
    );
  }

  renderButtons() {
    const label = this.state.showTriggerMode ? 'Trigger' : 'Schedule';

    return (
      <Button
        block
        type="submit"
        bsStyle="success"
        onClick={this.handleSave}
        disabled={this.isSaveButtonDisabled()}
      >
        {this.state.isSaving ? (
          <Loader className="icon-addon-right" />
        ) : (
          <FontAwesomeIcon icon="arrow-right" className="icon-addon-right" />
        )}
        {this.props.trigger || this.props.scheduler ? 'Update' : 'Set Up'} {label}
      </Button>
    );
  }

  renderInvokeSelect = () => {
    if (this.props.trigger || this.props.scheduler) {
      return null;
    }

    return (
      <div className="form-group mb-2">
        <Select
          searchable={false}
          clearable={false}
          options={[
            {
              label: 'Date & Time',
              value: scheduleInvokeType.TIME,
              isDisabled: !this.props.canManageSchedule,
              disabledReason: !this.props.canManageSchedule
                ? 'Time schedule can be set up only in the development branch by a developer.'
                : '',
            },
            {
              label: 'Triggered',
              value: scheduleInvokeType.EVENT,
              isDisabled: !this.props.canManageTriggers,
              disabledReason: !this.props.canManageTriggers
                ? 'Triggers can be set up in the production by a production manager.'
                : '',
            },
          ]}
          value={this.state.showTriggerMode ? scheduleInvokeType.EVENT : scheduleInvokeType.TIME}
          onChange={(invokeType: string) =>
            this.setState({ showTriggerMode: scheduleInvokeType.EVENT === invokeType })
          }
          disabled={this.state.isSaving}
        />
      </div>
    );
  };

  handleTriggerTableAdd = (tableId: string) => {
    this.setState((state) => ({
      trigger: state.trigger.set(
        'tables',
        state.trigger.get('tables', List()).push(Map({ tableId })),
      ),
    }));
  };

  handleTriggerTableRemove = (tableId: string) => {
    this.setState((state) => ({
      trigger: state.trigger.update('tables', List(), (tables) =>
        tables.filter((table: Map<string, any>) => table.get('tableId') !== tableId),
      ),
    }));
  };

  handleTriggerPeriodChange = ({ target }: { target: { value: string } }) => {
    this.setState((state) => ({
      trigger: state.trigger.set('coolDownPeriodMinutes', parseInt(target.value, 10)),
    }));
  };

  isSaveButtonDisabled() {
    if (this.state.isSaving) {
      return true;
    }

    if (this.state.showTriggerMode) {
      return (
        this.state.trigger.get('tables', List()).isEmpty() ||
        isNaN(this.state.trigger.get('coolDownPeriodMinutes')) ||
        this.state.trigger.get('coolDownPeriodMinutes') < 1
      );
    }

    return false;
  }

  handleSave = () => {
    this.setState({ isSaving: true });
    const promise = this.state.showTriggerMode ? this.saveTrigger() : this.saveScheduler();
    return promise.then(this.props.onHide).finally(() => this.setState({ isSaving: false }));
  };

  saveScheduler() {
    const schedule = {
      cronTab: this.state.cronTab,
      timezone: this.state.timezone || 'UTC',
    };

    if (!this.props.scheduler) {
      return createAndActivateScheduler(this.props.configId, schedule);
    }

    return updateScheduler(
      this.props.scheduler.get('id'),
      this.props.scheduler.mergeIn(['configuration', 'schedule'], schedule),
    );
  }

  saveTrigger() {
    const options = {
      tableIds: this.state.trigger
        .get('tables', List())
        .map((table: Map<string, any>) => table.get('tableId'))
        .toArray(),
      coolDownPeriodMinutes: this.state.trigger.get('coolDownPeriodMinutes'),
    };

    if (!this.props.trigger) {
      return StorageApi.createTriggerToken(this.props.configId).then((token) =>
        createTrigger({
          component: KEBOOLA_ORCHESTRATOR,
          configurationId: this.props.configId,
          runWithTokenId: token.id,
          ...options,
        }),
      );
    }

    return updateTrigger(this.props.trigger.get('id'), options);
  }

  getCronTabData = () => {
    if (!this.props.scheduler) {
      return {
        cronTab: crontabDefault(),
        cronTabPeriod: getPeriodForCrontab(crontabDefault()),
        timezone: 'UTC',
      };
    }

    const scheduleConfig = this.props.scheduler.getIn(['configuration', 'schedule'], Map());
    const cronTab = scheduleConfig.get('cronTab') || crontabDefault();

    return {
      cronTab,
      cronTabPeriod: getPeriodForCrontab(cronTab),
      timezone: scheduleConfig.get('timezone') || 'UTC',
    };
  };

  getTriggerData = () => {
    if (!this.props.trigger) {
      return {
        trigger: fromJS(defaultTrigger),
        showTriggerMode: !this.props.canManageSchedule && this.props.canManageTriggers,
      };
    }

    return {
      trigger: fromJS(defaultTrigger).merge(this.props.trigger),
      showTriggerMode: true,
    };
  };
}

export default ScheduleModal;
