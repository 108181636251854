import React from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { buttonStyles } from './styles';
import type { CommonButtonProps } from './types';

type IconButtonProps = {
  onClick?: (e: React.MouseEvent) => void;
  icon: IconProp;
  isFilledIcon?: boolean;
  isLoading?: boolean;
};

export const IconButton = ({
  onClick,
  size = 'medium',
  variant = 'primary',
  disabled = false,
  icon,
  isFilledIcon = false,
  isLoading = false,
  className,
}: CommonButtonProps & IconButtonProps) => {
  const styles = buttonStyles({ variant, size, icon, disabled, isFilledIcon, className });
  return (
    <button onClick={onClick} className={styles} disabled={disabled} data-disabled={disabled}>
      <FontAwesomeIcon
        spin={isLoading}
        icon={isLoading ? 'spinner' : icon}
        className="tw-inline-block tw-h-4 tw-w-4 tw-align-middle"
      />
    </button>
  );
};
