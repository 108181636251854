import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { Alert, Button, ButtonInline } from '@keboola/design';

import EmptyState from '@/modules/components/react/components/ComponentEmptyState';
import Loader from '@/react/common/Loader';

const QuerySample = createReactClass({
  propTypes: {
    sampleDataInfo: PropTypes.object.isRequired,
    onRunQuery: PropTypes.func.isRequired,
    isQueryValid: PropTypes.bool,
  },

  getInitialState() {
    return {
      showIds: false,
    };
  },

  render() {
    return (
      <div>
        {this.renderRunButton()}
        {this.renderSamplesTable()}
      </div>
    );
  },

  renderSamplesTable() {
    const sampleData = this.getSampleDataInfo('data', null);

    if (!sampleData) {
      return null;
    }

    if (sampleData.count() === 0) {
      return <EmptyState>Query returned empty result</EmptyState>;
    }

    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>{this.renderHeader(sampleData.first())}</tr>
          </thead>
          <tbody>{this.renderRows(sampleData)}</tbody>
        </table>
      </div>
    );
  },

  renderHeader(firstRow) {
    return firstRow
      .map((value, columnName) => (
        <th key={columnName}>
          {columnName}
          {columnName === 'id' && (
            <ButtonInline onClick={() => this.setState({ showIds: !this.state.showIds })}>
              {this.state.showIds ? 'Hide' : 'Show'}
            </ButtonInline>
          )}
        </th>
      ))
      .toArray();
  },

  renderRows(rows) {
    return rows
      .map((row, rowIndex) => (
        <tr key={rowIndex}>
          {row
            .map((value, columnName) => (
              <td key={columnName}>{columnName === 'id' && !this.state.showIds ? '...' : value}</td>
            ))
            .toArray()}
        </tr>
      ))
      .toArray();
  },

  renderRunButton() {
    const isLoading = this.getSampleDataInfo('isLoading', false);
    const isError = this.getSampleDataInfo('isError', false);

    return (
      <div>
        <div className="text-center tw-justify-items-center">
          <Button
            variant="secondary"
            disabled={isLoading || !this.props.isQueryValid}
            onClick={this.props.onRunQuery}
          >
            Test Query {isLoading && <Loader />}
          </Button>
        </div>
        <br />
        {isError && this.renderError()}
      </div>
    );
  },

  renderError() {
    let message = this.getSampleDataInfo(
      ['error', 'message'],
      'An error occurred while testing query',
    );
    const code = this.getSampleDataInfo(['error', 'code']);

    if (code < 500) {
      try {
        message = JSON.parse(message).message || message;
      } catch {
        // use default message defined above
      }
    }

    return (
      <Alert variant="error" className="tw-mb-5">
        <p>{message}</p>
        {code >= 500 && <div>{this.getSampleDataInfo(['error', 'exceptionId'])}</div>}
      </Alert>
    );
  },

  getSampleDataInfo(key, defaultValue) {
    return this.props.sampleDataInfo.getIn([].concat(key), defaultValue);
  },
});

export default QuerySample;
