import React from 'react';

import { FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

import { nameWarning } from '@/modules/storage/constants';
import Checkbox from '@/react/common/Checkbox';
import Select from '@/react/common/Select';
import string from '@/utils/string';

const FORM_GROUP_CLASS_NAMES = 'tw-grid tw-grid-cols-3 tw-items-baseline tw-mb-4 tw-gap-4';

type Value = {
  storage: string;
  incremental: boolean;
  primary_key: Immutable.List<any>;
};

type Props = {
  value: Value;
  onChange: (value: Partial<Value>) => void;
  disabled: boolean;
};

const Destination = ({ value, onChange, disabled = false }: Props) => {
  return (
    <div className="box">
      <div className="box-header big-padding with-border">
        <h3 className="box-title">Destination</h3>
      </div>
      <div className="box-content">
        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="storage-table-name">Storage table name</Label>
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
            <TextInput
              id="storage-table-name"
              variant="secondary"
              value={value.storage}
              onChange={(value) => {
                onChange({ storage: string.sanitizeKbcTableIdString(value) });
              }}
              placeholder="mytable"
              disabled={disabled}
            />
            <HelpBlock>Name of the table stored in Storage {nameWarning}</HelpBlock>
          </div>
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <div className="tw-col-span-2 tw-col-start-2 tw-flex tw-flex-col tw-gap-1">
            <Checkbox
              checked={value.incremental}
              onChange={(checked) => onChange({ incremental: checked })}
              disabled={disabled}
            >
              Incremental load
            </Checkbox>
            <HelpBlock>
              If incremental load is turned on, the table will be updated instead of rewritten.{' '}
              Tables with a primary key will have rows updated, tables without a primary key will
              have rows appended.
            </HelpBlock>
          </div>
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="primary-key">Primary Key</Label>
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
            <Select
              id="primary-key"
              value={value.primary_key}
              multi
              allowCreate
              placeholder="Add a column to the primary key"
              onChange={(value) => {
                onChange({ primary_key: value });
              }}
              disabled={disabled}
            />
            <HelpBlock>
              If a primary key is set, updates can be done on the table by selecting{' '}
              <strong>incremental load</strong>. The primary key can consist of multiple columns.
              The primary key of an existing table cannot be changed.
            </HelpBlock>
          </div>
        </FormGroup>
      </div>
    </div>
  );
};

export default Destination;
