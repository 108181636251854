import React from 'react';

import { cn } from '@keboola/design';

import { type FilterType, VARIABLE_TYPE } from '@/modules/vault/constants';
import { FilterPanel } from '@/react/common';

type Props = {
  query: string;
  setSearchQuery: (query: string) => void;
  variablesType: FilterType;
  setVariablesType: (type: FilterType) => void;
  placeholder: string;
  isDevModeActive: boolean;
};

const Search = ({
  query,
  setSearchQuery,
  variablesType,
  setVariablesType,
  placeholder,
  isDevModeActive,
}: Props) => {
  const renderFilterButton = (label: string, type: FilterType) => {
    return (
      <button
        onClick={() => setVariablesType(type)}
        className={cn('btn predefined-search-link', { active: variablesType === type })}
      >
        {label}
      </button>
    );
  };

  return (
    <FilterPanel
      query={query}
      onChange={setSearchQuery}
      placeholder={placeholder}
      additionalActions={
        <div className="predefined-search-list">
          {renderFilterButton('All', VARIABLE_TYPE.ALL)}
          <span className="group-separator" />
          {renderFilterButton('Project Wide', VARIABLE_TYPE.WIDE)}
          {isDevModeActive ? (
            <>
              {renderFilterButton('Branch Specific', VARIABLE_TYPE.SCOPED)}
              {renderFilterButton('Production Only', VARIABLE_TYPE.PRODUCTION)}
            </>
          ) : (
            renderFilterButton('Production Only', VARIABLE_TYPE.SCOPED)
          )}
        </div>
      }
    />
  );
};

export default Search;
