import React from 'react';

export const ShareBucketIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="svg-custom biggest"
  >
    <path
      d="M6 2C4.34375 2 3 3.34375 3 5V15C3 16.6562 4.34375 18 6 18H15H16C16.5531 18 17 17.5531 17 17C17 16.4469 16.5531 16 16 16V14C16.5531 14 17 13.5531 17 13V3C17 2.44687 16.5531 2 16 2H15H6ZM6 14H14V16H6C5.44688 16 5 15.5531 5 15C5 14.4469 5.44688 14 6 14Z"
      fill="currentColor"
    />
    <circle cx="17" cy="17" r="7" fill="white" />
    <path
      d="M17 23C18.5913 23 20.1174 22.3679 21.2426 21.2426C22.3679 20.1174 23 18.5913 23 17C23 15.4087 22.3679 13.8826 21.2426 12.7574C20.1174 11.6321 18.5913 11 17 11C15.4087 11 13.8826 11.6321 12.7574 12.7574C11.6321 13.8826 11 15.4087 11 17C11 18.5913 11.6321 20.1174 12.7574 21.2426C13.8826 22.3679 15.4087 23 17 23ZM17.3984 13.4141L20.0234 16.0391L20.4219 16.4375L19.625 17.232L19.2266 16.8336L17.5625 15.1695V20.1875V20.75H16.4375V20.1875V15.1695L14.7734 16.8336L14.375 17.232L13.5805 16.4375L13.9789 16.0391L16.6016 13.4141L17 13.0156L17.3984 13.4141Z"
      fill="currentColor"
    />
  </svg>
);
