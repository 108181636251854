import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import BackendSizeLabel from '@/modules/components/react/components/BackendSizeLabel';
import {
  getCurrentBackendSize,
  isInternalDataAppJob,
  isScheduledJob,
  isTriggeredJob,
} from '@/modules/queue/helpers';
import { CreatedDate, User } from '@/react/common';
import InternalDataAppJob from './InternalDataAppJob';
import RunIdTooltip from './RunIdTooltip';
import ScheduledJob from './ScheduledJob';
import TriggeredJob from './TriggeredJob';

const RunInfoPanel = ({ job, jobConfigurationLink, admin, showBackendSize }) => {
  return (
    <div className="box info-row">
      <div className="info-row-section">
        <h4 className="first-line flex-container">
          Configuration
          <RunIdTooltip job={job} />
        </h4>
        <div className="last-line">{jobConfigurationLink}</div>
      </div>
      <div className="info-row-section horizontal">
        <h4 className="first-line flex-container">
          <span>Start</span>
          <CreatedDate
            absolute
            withSeconds
            className="font-normal text-muted"
            createdTime={job.get('startTime')}
          />
        </h4>
        <h4 className="first-line flex-container">
          <span>End</span>
          <CreatedDate
            absolute
            withSeconds
            className="font-normal text-muted"
            createdTime={job.get('endTime')}
          />
        </h4>
      </div>
      <div className="info-row-section">
        <h4 className="first-line">Created</h4>
        <div className="last-line">
          <CreatedDate
            absolute
            withSeconds
            className="font-normal text-muted"
            createdTime={job.get('createdTime')}
          />
          {' by '}
          {admin ? (
            <User user={admin} avatar={false} />
          ) : isScheduledJob(job) ? (
            <ScheduledJob noIcon />
          ) : isTriggeredJob(job) ? (
            <TriggeredJob />
          ) : isInternalDataAppJob(job) ? (
            <InternalDataAppJob />
          ) : (
            job.getIn(['token', 'description'])
          )}
        </div>
      </div>
      {showBackendSize && (
        <div className="info-row-section">
          <h4 className="first-line">Backend size</h4>
          <div className="last-line">
            <BackendSizeLabel
              componentId={job.get('component')}
              size={getCurrentBackendSize(job)}
              showText
            />
          </div>
        </div>
      )}
    </div>
  );
};

RunInfoPanel.propTypes = {
  job: PropTypes.instanceOf(Map).isRequired,
  jobConfigurationLink: PropTypes.node.isRequired,
  admin: PropTypes.instanceOf(Map),
  showBackendSize: PropTypes.bool,
};

export default RunInfoPanel;
