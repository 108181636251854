import React from 'react';
import type { LabelProps as RLabelProps } from '@radix-ui/react-label';
import * as RLabel from '@radix-ui/react-label';

import { cn } from '../../utils';

export type LabelProps = RLabelProps;

const Optional = ({ className }: { className?: string }) => (
  <span className={cn('tw-text-neutral-400', className)}>(optional)</span>
);

export const Label = ({ className, children, ...rest }: LabelProps) => {
  return (
    <RLabel.Root {...rest} className={cn('tw-m-0 tw-text-neutral-800 tw-font-normal', className)}>
      {children}
    </RLabel.Root>
  );
};

Label.Optional = Optional;
