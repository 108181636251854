import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { HelpBlock } from '@keboola/design';

import Checkbox from '@/react/common/Checkbox';
import Select from '@/react/common/Select';

const SaveSettings = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      incremental: PropTypes.bool.isRequired,
      primaryKey: PropTypes.array.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Save Settings</h2>
        </div>
        <div className="box-content">
          <FormGroup>
            <div className="col-xs-8 col-xs-offset-4">
              <Checkbox
                checked={this.props.value.incremental}
                disabled={this.props.disabled}
                onChange={(checked) => this.props.onChange({ incremental: checked })}
              >
                Incremental
              </Checkbox>
              <HelpBlock className="tw-mt-1">
                If incremental load is turned on, the table will be updated instead of rewritten.
                Tables with a primary key will have rows updated, tables without a primary key will
                have rows appended.
              </HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Primary Key</ControlLabel>
            </div>
            <div className="col-xs-8">
              <Select
                name="primaryKey"
                value={this.props.value.primaryKey}
                multi
                allowCreate
                placeholder="Add a column to the primary key"
                onChange={(value) => {
                  this.props.onChange({ primaryKey: value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock className="tw-mt-1">
                If a primary key is set, updates can be done on the table by selecting{' '}
                <strong>incremental loads</strong>. The primary key can consist of multiple columns.
                The primary key of an existing table cannot be changed.
              </HelpBlock>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  },
});

export default SaveSettings;
