import type { Service } from '../clients/storage/types';
import type { ServiceIdType } from '../constants';

export const getServiceMap = (services: Service[]) =>
  services.reduce(
    (acc, service) => {
      acc[service.id as ServiceIdType] = service.url;
      return acc;
    },
    {} as Record<ServiceIdType, string>,
  );
