import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

import { KEBOOLA_WR_AWS_S_3 } from '@/constants/componentIds';
import SyncActionSimpleValue from '@/modules/configurations/react/components/SyncActionSimpleValue';
import { LOGIN_TYPES } from '@/modules/wr-aws-s3/constants';
import PasswordControl from '@/react/common/PasswordControl';
import Select from '@/react/common/Select';
import string from '@/utils/string';
import DocumentationLink from './DocumentationLink';

const FORM_GROUP_CLASS_NAMES = 'tw-grid tw-grid-cols-3 tw-items-start tw-mb-4 tw-gap-4';

const Credentials = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      loginType: PropTypes.oneOf(Object.values(LOGIN_TYPES)).isRequired,
      bucket: PropTypes.string,
      awsAccessKeyId: PropTypes.string,
      awsSecretAccessKey: PropTypes.string,
      accountId: PropTypes.string,
      roleName: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    const isWriter = this.props.context.get('componentId') === KEBOOLA_WR_AWS_S_3;

    return (
      <div>
        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="login-type">Login Type</Label>
          <Select
            id="login-type"
            className="tw-col-span-2"
            clearable={false}
            searchable={false}
            disabled={this.props.disabled}
            value={this.props.value.loginType}
            onChange={(selected) => this.props.onChange({ loginType: selected })}
            options={Object.values(LOGIN_TYPES).map((value) => ({
              value,
              label: string.capitalize(value),
            }))}
          />
        </FormGroup>
        {this.props.value.loginType === LOGIN_TYPES.ROLE ? (
          <>
            <FormGroup className={FORM_GROUP_CLASS_NAMES}>
              <Label htmlFor="account-id">Account ID</Label>
              <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
                <TextInput
                  id="account-id"
                  variant="secondary"
                  value={this.props.value.accountId}
                  onChange={(accountId) => {
                    this.props.onChange({ accountId });
                  }}
                  disabled={this.props.disabled}
                  placeholder="123456789012"
                />
                <HelpBlock>ID of your AWS account</HelpBlock>
              </div>
            </FormGroup>
            <FormGroup className={FORM_GROUP_CLASS_NAMES}>
              <Label htmlFor="role-name">Role Name</Label>
              <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
                <TextInput
                  id="role-name"
                  variant="secondary"
                  value={this.props.value.roleName}
                  onChange={(roleName) => {
                    this.props.onChange({ roleName });
                  }}
                  disabled={this.props.disabled}
                />
                <HelpBlock>
                  Fill in name of a role you created in your AWS account. More information is
                  available in the{' '}
                  <DocumentationLink isWriter={isWriter} section="#authentication-with-aws-role" />.
                </HelpBlock>
              </div>
            </FormGroup>

            <FormGroup className={FORM_GROUP_CLASS_NAMES}>
              <Label htmlFor="external-id">External Id</Label>
              <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
                <FormControl.Static>
                  <SyncActionSimpleValue
                    action={this.props.actions.get('getExternalId')}
                    valueKey={['external-id']}
                    withClipboard
                  />
                </FormControl.Static>
                <HelpBlock>
                  Use this identifier as the External ID when creating the new role.
                </HelpBlock>
              </div>
            </FormGroup>
          </>
        ) : (
          <>
            <FormGroup className={FORM_GROUP_CLASS_NAMES}>
              <Label htmlFor="access-key-id">Access Key Id</Label>
              <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
                <TextInput
                  id="access-key-id"
                  variant="secondary"
                  value={this.props.value.awsAccessKeyId}
                  onChange={(awsAccessKeyId) => {
                    this.props.onChange({ awsAccessKeyId });
                  }}
                  placeholder="MYAWSACCESSKEYID123"
                  disabled={this.props.disabled}
                />
                <HelpBlock>
                  <span>
                    Make sure that this AWS Access Key ID has correct permissions. Required
                    permissions are
                    {isWriter ? (
                      <ul>
                        <li>
                          <code>s3:PutObject</code> to upload files
                        </li>
                        <li>
                          <code>s3:GetBucketLocation</code> to determine the bucket region
                        </li>
                      </ul>
                    ) : (
                      <ul>
                        <li>
                          <code>s3:GetObject</code> for the given key/wildcard
                        </li>
                        <li>
                          <code>s3:ListBucket</code> to access all wildcard files
                        </li>
                        <li>
                          <code>s3:GetBucketLocation</code> to determine the bucket region
                        </li>
                      </ul>
                    )}
                    More information is available in the <DocumentationLink isWriter={isWriter} />.
                  </span>
                </HelpBlock>
              </div>
            </FormGroup>
            <FormGroup className={FORM_GROUP_CLASS_NAMES}>
              <Label htmlFor="secret-access-key">Secret Access Key</Label>
              <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
                <PasswordControl
                  id="secret-access-key"
                  value={this.props.value.awsSecretAccessKey}
                  onChange={(e) => this.props.onChange({ awsSecretAccessKey: e.target.value })}
                  disabled={this.props.disabled}
                />
                <HelpBlock>The AWS Secret Access Key will be encrypted.</HelpBlock>
              </div>
            </FormGroup>
          </>
        )}
        {isWriter && (
          <FormGroup className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor="s3-bucket">S3 Bucket</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <TextInput
                id="s3-bucket"
                variant="secondary"
                placeholder="mybucket"
                value={this.props.value.bucket}
                onChange={(bucket) => {
                  this.props.onChange({ bucket });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>Name of the target AWS S3 bucket.</HelpBlock>
            </div>
          </FormGroup>
        )}
      </div>
    );
  },
});

export default Credentials;
