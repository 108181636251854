import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from '@keboola/design';

import Loader from '@/react/common/Loader';

const CreditsLoadingTooltip = ({ show }) => {
  if (!show) {
    return null;
  }

  return (
    <Tooltip
      placement="top"
      tooltip="Loading credits balance..."
      triggerClassName="tw-align-middle tw-text-[0.5em]"
    >
      <Loader className="icon-addon-left text-muted" />
    </Tooltip>
  );
};

CreditsLoadingTooltip.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default CreditsLoadingTooltip;
