import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import { HelpBlock } from '@keboola/design';

import Checkbox from '@/react/common/Checkbox';
import Select from '@/react/common/Select';

class User extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">User</h3>
        </div>
        <div className="box-content">
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Email</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.value.email}
                onChange={(e) => this.props.onChange({ email: e.target.value })}
                disabled={this.props.disabled}
              />
              <HelpBlock className="tw-mt-1">
                Username will be generated from the email address.
              </HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Read schemas</ControlLabel>
            </div>
            <div className="col-xs-8">
              <Select
                multi
                value={this.props.value.schemas_read}
                options={this.getNotUsedSchemas()}
                onChange={(newValue) => this.props.onChange({ schemas_read: newValue })}
                disabled={this.props.disabled}
              />
              <HelpBlock className="tw-mt-1">
                List of schemas the user will have read-only access to. There is no validation yet,
                so make sure that there are no typos and schemas exist before creating the user.
              </HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Write schemas</ControlLabel>
            </div>
            <div className="col-xs-8">
              <Select
                multi
                value={this.props.value.schemas_write}
                options={this.getNotUsedSchemas()}
                onChange={(newValue) => this.props.onChange({ schemas_write: newValue })}
                disabled={this.props.disabled}
              />
              <HelpBlock className="tw-mt-1">
                List of schemas the user will have read and write access to. There is no validation
                yet, so make sure that there are no typos and schemas exist before creating the
                user.
              </HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-8 col-xs-offset-4">
              <Checkbox
                checked={this.props.value.disabled}
                onChange={(checked) => this.props.onChange({ disabled: checked })}
                disabled={this.props.disabled}
              >
                Disabled
              </Checkbox>
              <HelpBlock className="tw-mt-1">Disabled users cannot log in.</HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-8 col-xs-offset-4">
              <Checkbox
                checked={this.props.value.reset_password}
                onChange={(checked) => this.props.onChange({ reset_password: checked })}
                disabled={this.props.disabled}
              >
                Reset password
              </Checkbox>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  }

  getNotUsedSchemas() {
    return this.filterUsedSchemasFromExisting(
      this.props.value.existingSchemas,
      this.props.value.schemas_read,
      this.props.value.schemas_write,
    );
  }

  filterUsedSchemasFromExisting(existing, schemasRead, schemasWrite) {
    return existing.filter((item) => {
      return !(schemasRead.includes(item.value) || schemasWrite.includes(item.value));
    });
  }
}

User.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default User;
