import React, { type ChangeEvent, type MouseEvent, useMemo, useRef, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';
import _ from 'underscore';

import { Button, ButtonInline, cn, Tooltip } from '@keboola/design';
import type { Variant } from '@keboola/design/src/components/Button/types';

import { EXCLUDE_FROM_NEW_LIST } from '@/constants/componentFlags';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import ComponentBoxModal from '@/modules/components-directory/components/ComponentBoxModal';
// import Checkbox from '@/react/common/Checkbox';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import LazyList from '@/react/common/LazyList';
import MarkedText from '@/react/common/MarkedText';
import useStores from '@/react/hooks/useStores';
import { matchByWords } from '@/utils';

const ComponentSelection = ({
  componentsIds,
  onSubmit,
}: {
  componentsIds?: string[];
  onSubmit: (componentIds: string[]) => void;
}) => {
  const { allComponents, publicComponents } = useStores(
    () => {
      const allComponents = ComponentsStore.getAll() as Map<string, any>;

      return {
        allComponents,
        publicComponents: allComponents.filter(
          (component) => !component.get('flags').includes(EXCLUDE_FROM_NEW_LIST),
        ) as Map<string, any>,
      };
    },
    [],
    [ComponentsStore],
  );
  const [selectedComponents /* , setSelectedComponents */] = useState<string[]>([]);
  const [selectedSearchResultsComponents /* , setSelectedSearchResultsComponents */] = useState<
    string[]
  >([]);
  const [detailModalComponentId, setDetailModalComponentId] = useState('');
  const componentsListRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useMemo(() => _.debounce(setSearchQuery, 200), [setSearchQuery]);
  const searchResults: string[] = useMemo(() => {
    if (searchQuery.length < 2) return [];

    return publicComponents
      .filter((component: Map<string, any>) => {
        if (
          selectedComponents.includes(component.get('id')) ||
          componentsIds?.includes(component.get('id'))
        )
          return false;

        return (
          matchByWords(component.get('name'), searchQuery) || component.get('id') === searchQuery
        );
      })
      .map((component: Map<string, any>) => component.get('id'))
      .toArray();
  }, [publicComponents, selectedComponents, searchQuery, componentsIds]);
  const isSearchActive = searchQuery.length > 1;

  if (!componentsIds?.length) return null;

  return (
    <div className="tw-flex tw-flex-wrap tw-gap-2">
      {componentsIds.map((id) => {
        const component = allComponents.get(id);

        if (!component) return null;

        return (
          <ComponentSelectionItem
            key={id}
            component={component}
            isSelected={selectedComponents.includes(id)}
            onSelect={() => onSubmit([id])}
            onShowDetail={() => setDetailModalComponentId(id)}
            className={cn('hover:!tw-border-secondary-500 tw-w-auto', {
              'tw-border-secondary-500': selectedComponents.includes(id),
            })}
            buttonVariant="outline"
          />
        );
      })}
      <div className="tw-relative tw-w-full">
        <FormControl
          type="text"
          placeholder="Search components..."
          onChange={(event: ChangeEvent<HTMLInputElement>) => debouncedSearch(event.target.value)}
          className="tw-border tw-border-solid tw-border-neutral-200 tw-bg-white tw-py-3.5 tw-pl-11"
        />
        <FontAwesomeIcon
          icon="search"
          className="tw-absolute tw-inset-y-0 tw-left-4 tw-my-auto tw-text-base tw-text-neutral-400"
        />
      </div>
      {(!!selectedSearchResultsComponents.length || !!searchResults.length || isSearchActive) && (
        <div className="!tw-m-0 tw-flex tw-max-h-72 tw-w-full tw-flex-col tw-overflow-y-auto tw-rounded-lg tw-border tw-border-solid tw-border-neutral-150 tw-p-1">
          {!!selectedSearchResultsComponents.length && (
            <div
              className={cn('tw-flex-auto tw-flex-shrink-0 tw-overflow-y-auto', {
                'tw-max-h-[50%]': isSearchActive,
              })}
            >
              {selectedSearchResultsComponents.map((componentId) => (
                <ComponentSelectionItem
                  isSelected
                  key={componentId}
                  component={allComponents.get(componentId)}
                  onSelect={() => onSubmit([componentId])}
                  onShowDetail={() => setDetailModalComponentId(componentId)}
                />
              ))}
            </div>
          )}
          {!!selectedSearchResultsComponents.length &&
            (!!searchResults.length || isSearchActive) && (
              <hr className="tw-my-1 tw-w-[calc(100%-1rem)]" />
            )}
          {!!searchResults.length && (
            <div ref={componentsListRef} className="tw-overflow-y-auto">
              <LazyList
                useWindow={false}
                getScrollParent={() => componentsListRef.current}
                items={searchResults}
                render={(items) =>
                  items.map((componentId) => (
                    <ComponentSelectionItem
                      key={componentId}
                      component={allComponents.get(componentId)}
                      isSelected={selectedComponents.includes(componentId)}
                      onSelect={() => onSubmit([componentId])}
                      searchQuery={searchQuery}
                      onShowDetail={() => setDetailModalComponentId(componentId)}
                    />
                  ))
                }
              />
            </div>
          )}
          {isSearchActive && !searchResults.length && (
            <span className="tw-px-3 tw-py-2">No results found</span>
          )}
        </div>
      )}
      <div className="tw-flex tw-gap-2">
        {/* <Button
          onClick={() => onSubmit(selectedComponents)}
          disabled={selectedComponents.length === 0}
        >
          Submit Selection
        </Button> */}
        <ButtonInline onClick={() => onSubmit([])}>Not Relevant</ButtonInline>
      </div>
      <ComponentBoxModal
        readOnly
        hasConfigurations={false}
        show={!!detailModalComponentId}
        allComponents={allComponents}
        component={allComponents.get(detailModalComponentId, Map())}
        onHide={() => setDetailModalComponentId('')}
      />
    </div>
  );
};

const ComponentSelectionItem = ({
  component,
  isSelected,
  onSelect,
  searchQuery,
  onShowDetail,
  className,
  buttonVariant = 'invisible',
}: {
  component: Map<string, any>;
  isSelected: boolean;
  onSelect: (setSelectedComponents: (selectedComponents: string[]) => string[]) => void;
  searchQuery?: string;
  onShowDetail: () => void;
  className?: string;
  buttonVariant?: Variant;
}) => {
  return (
    <Button
      variant={buttonVariant}
      className={cn(
        'tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-gap-2 tw-rounded tw-p-2 hover:tw-bg-neutral-100 tw-max-h-none tw-text-left',
        className,
      )}
      onClick={() =>
        onSelect((selectedComponents) =>
          isSelected
            ? selectedComponents.filter((id) => id !== component.get('id'))
            : [...selectedComponents, component.get('id')],
        )
      }
    >
      {/* <Checkbox checked={isSelected} className="tw-pointer-events-none" /> */}
      <ComponentIcon component={component} size="24" />
      <ComponentName component={component}>
        {(name) => <MarkedText source={name} mark={searchQuery} className="tw-uppercase" />}
      </ComponentName>
      <div className="tw-ml-auto tw-shrink-0">
        <Tooltip placement="top" tooltip="Show component details">
          <Button
            variant="invisible"
            className="!tw-p-0 !tw-text-neutral-400"
            onClick={(event: MouseEvent<Element>) => {
              event.stopPropagation();
              onShowDetail();
            }}
          >
            <FontAwesomeIcon icon="circle-info" className="tw-text-base tw-text-neutral-400" />
          </Button>
        </Tooltip>
      </div>
    </Button>
  );
};

export default ComponentSelection;
