import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { TRANSFORMATION } from '@/constants/componentIds';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import TransformationActionCreators from '@/modules/transformations/ActionCreators';
import { routeNames } from '@/modules/transformations/Constants';
import EmptyStateBucket from '@/modules/transformations/react/components/EmptyStateBucket';
import TransformationRow from '@/modules/transformations/react/components/TransformationRow';
import TransformationBucketsStore from '@/modules/transformations/stores/TransformationBucketsStore';
import TransformationsStore from '@/modules/transformations/stores/TransformationsStore';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const TransformationBucket = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      DevBranchesStore,
      TransformationsStore,
      TransformationBucketsStore,
      VersionsStore,
    ),
  ],

  getStateFromStores() {
    const bucketId = RoutesStore.getCurrentRouteParam('config');
    const latestVersions = VersionsStore.getVersions(TRANSFORMATION, bucketId);
    const latestVersionId = latestVersions.map((v) => v.get('version')).max();

    return {
      bucketId,
      transformations: TransformationsStore.getTransformations(bucketId),
      bucket: TransformationBucketsStore.get(bucketId),
      transformationBuckets: TransformationBucketsStore.getAll(),
      pendingActions: TransformationsStore.getPendingActions(bucketId),
      latestVersions,
      latestVersionId,
      readOnly: ApplicationStore.isReadOnly(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
    };
  },

  UNSAFE_componentWillReceiveProps() {
    return this.setState(this.getStateFromStores());
  },

  render() {
    return (
      <div className="row">
        <div className="col-sm-9">
          <ComponentDescription
            componentId="transformation"
            configId={this.state.bucket.get('id')}
            placeholderEntity="Transformation Bucket"
          />
          <div className="box">
            {this.state.transformations.count() ? (
              this._renderTable()
            ) : (
              <EmptyStateBucket bucket={this.state.bucket} readOnly={this.state.readOnly} />
            )}
          </div>
        </div>
        <div className="col-sm-3">
          <Sidebar
            hideCopy
            hideSchedule
            componentId="transformation"
            configId={this.state.bucketId}
            run={{
              params: () => ({ configBucketId: this.state.bucketId }),
              title: 'Run transformations',
              text: `You are about to run all transformations in the bucket ${this.state.bucket.get(
                'name',
              )}.`,
            }}
            delete={
              <Button
                block
                bsStyle="link"
                className="btn-link-inline"
                onClick={this._deleteTransformationBucket}
              >
                <FontAwesomeIcon icon="trash" fixedWidth />
                Delete Transformation
              </Button>
            }
            versionsLinkTo={routeNames.BUCKET_VERSIONS}
          />
        </div>
      </div>
    );
  },

  _renderTable() {
    return (
      <>
        <div className="table table-hover">
          <div className="thead">
            <div className="tr">
              <div className="th contain-header">
                <h3 className="m-0">Transformations</h3>
              </div>
              <div className="th w-400" />
            </div>
          </div>
          <span className="tbody">
            {this._getSortedTransformations()
              .map((transformation) => {
                return (
                  <TransformationRow
                    latestVersionId={this.state.latestVersionId}
                    transformation={transformation}
                    bucket={this.state.bucket}
                    transformationBuckets={this.state.transformationBuckets}
                    pendingActions={this.state.pendingActions.get(transformation.get('id'), Map())}
                    key={transformation.get('id')}
                    readOnly={this.state.readOnly}
                    isDevModeActive={this.state.isDevModeActive}
                  />
                );
              })
              .toArray()}
          </span>
        </div>
      </>
    );
  },

  _getSortedTransformations() {
    return this.state.transformations.sortBy((transformation) => {
      const phase = `000000000${transformation.get('phase')}`.slice(-10);
      const name = transformation.get('name', '').toLowerCase();
      return phase + name;
    });
  },

  _deleteTransformationBucket() {
    const bucketId = this.state.bucket.get('id');
    TransformationActionCreators.deleteTransformationBucket(bucketId);
    RoutesStore.getRouter().transitionTo(routeNames.ROOT);
  },
});

export default TransformationBucket;
