import { Map } from 'immutable';

import {
  KEBOOLA_DATA_APPS,
  KEBOOLA_EX_SAMPLE_DATA,
  KEBOOLA_SANDBOXES,
  KEBOOLA_SHARED_CODE,
} from '@/constants/componentIds';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { routeNames as dataAppsRouteNames } from '@/modules/data-apps/constants';
import { updatedInProduction } from '@/modules/dev-branches/helpers';
import { routeNames as jobsRouteNames } from '@/modules/jobs/Constants';
import * as jobsResolver from '@/modules/jobs/JobComponentResolver';
import JobsStore from '@/modules/jobs/stores/JobsStore';
import { routeNames as fakeLegacyTransformationRouteNames } from '@/modules/legacy-transformation/Constants';
import TransformationsStore2 from '@/modules/legacy-transformation/stores/TransformationsStore';
import * as queueResolver from '@/modules/queue/jobResolver';
import QueueStore from '@/modules/queue/store';
import { resolveComponentIdFromSandboxType } from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import { routeNames as legacyTransformationRouteNames } from '@/modules/transformations/Constants';
import TransformationsStore from '@/modules/transformations/stores/TransformationsStore';
import { routeNames as transformationRouteNames } from '@/modules/transformations-v2/constants';
import RoutesStore from '@/stores/RoutesStore';
import { CONFIGURATION_STATUS } from './constants';
import type { ConfigurationStatus } from './PullFromProductionConfigurationRow';

const resolveComponent = () => {
  const componentId = RoutesStore.getCurrentRouteComponentId();
  const routeConfig = RoutesStore.getCurrentRouteConfig();

  if (componentId === KEBOOLA_EX_SAMPLE_DATA) {
    const configData = InstalledComponentsStore.getConfigData(
      KEBOOLA_EX_SAMPLE_DATA,
      RoutesStore.getCurrentRouteParam('config'),
    );
    return ComponentsStore.getComponent(
      configData.getIn(['parameters', 'componentId'], componentId),
    );
  }

  if (ComponentsStore.hasComponent(componentId)) {
    return ComponentsStore.getComponent(componentId);
  }

  if (['workspace', 'workspace-versions'].includes(routeConfig.get('name'))) {
    const configData = InstalledComponentsStore.getConfigData(
      KEBOOLA_SANDBOXES,
      RoutesStore.getCurrentRouteParam('config'),
    );
    const componentIdfromSandboxType = resolveComponentIdFromSandboxType(
      SandboxesStore.getSandboxes()
        .get(configData.getIn(['parameters', 'id']), Map())
        .get('type'),
    );
    return ComponentsStore.getComponent(componentIdfromSandboxType) || Map();
  }

  if (
    [transformationRouteNames.SHARED_CODE, transformationRouteNames.SHARED_CODE_VERSIONS].includes(
      routeConfig.get('name'),
    )
  ) {
    const configData = InstalledComponentsStore.getConfigData(
      KEBOOLA_SHARED_CODE,
      RoutesStore.getCurrentRouteParam('config'),
    );
    return ComponentsStore.getComponent(configData.getIn(['componentId']));
  }

  if (routeConfig.get('name') === jobsRouteNames.DETAIL) {
    return jobsResolver.getComponentByJob(
      JobsStore.get(RoutesStore.getCurrentRouteIntParam('jobId')) || Map(),
    );
  }

  if (routeConfig.get('name') === 'queue-job') {
    return queueResolver.getComponentByJob(
      QueueStore.get(RoutesStore.getCurrentRouteParam('jobId')),
    );
  }

  if (routeConfig.get('name') === legacyTransformationRouteNames.DETAIL) {
    return TransformationsStore.getTransformation(
      RoutesStore.getCurrentRouteParam('config'),
      RoutesStore.getCurrentRouteParam('row'),
    );
  }

  if (routeConfig.get('name') === fakeLegacyTransformationRouteNames.DETAIL) {
    return TransformationsStore2.getTransformation(
      RoutesStore.getCurrentRouteParam('config'),
      RoutesStore.getCurrentRouteParam('row'),
    );
  }

  if (routeConfig.get('name') === dataAppsRouteNames.DATA_APP_DETAIL) {
    return ComponentsStore.getComponent(KEBOOLA_DATA_APPS);
  }

  return Map();
};

const isConfigurationMissingInBranch = (
  componentId: string,
  configurationId: string,
  devComponents: Map<string, any>,
) => {
  return !devComponents.hasIn([componentId, 'configurations', configurationId]);
};

const getConfigurationStatus = (
  componentId: string,
  configurationId: string,
  productionComponents: Map<string, any>,
  devComponents: Map<string, any>,
): ConfigurationStatus => {
  const prodConfig = productionComponents.getIn([componentId, 'configurations', configurationId]);
  const devConfig = devComponents.getIn([componentId, 'configurations', configurationId], Map());
  const isMissing = isConfigurationMissingInBranch(componentId, configurationId, devComponents);

  const updatedInBranch = devConfig.get('version') > 1;

  if (isMissing) {
    return CONFIGURATION_STATUS.missing;
  }

  if (updatedInProduction(devConfig, prodConfig) && updatedInBranch) {
    return CONFIGURATION_STATUS.conflict;
  }

  if (updatedInProduction(devConfig, prodConfig)) {
    return CONFIGURATION_STATUS.updatedInProduction;
  }

  if (updatedInBranch) {
    return CONFIGURATION_STATUS.updatedInBranch;
  }

  return CONFIGURATION_STATUS.noChange;
};

const getComponentTypeLabel = (component: Map<string, any>) => {
  const componentType = component.get('type');

  if (componentType === 'transformation') {
    return 'transformation';
  }

  if (componentType === 'other' && component.get('id') === KEBOOLA_DATA_APPS) {
    return 'data app';
  }

  return 'component';
};

const getComponentTypeIcon = (component: Map<string, any>) => {
  switch (getComponentTypeLabel(component)) {
    case 'transformation':
      return 'window-restore';
    case 'data app':
      return 'browser';
    default:
      return 'layer-group';
  }
};

export { getConfigurationStatus, getComponentTypeLabel, getComponentTypeIcon, resolveComponent };
