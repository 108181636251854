import React from 'react';

import { cn } from '../../utils';

import { Skeleton } from './Skeleton';

const Item = ({ className }: { className?: string }) => (
  <div className="tw-flex tw-items-center tw-gap-3">
    <Skeleton height="md" className="tw-w-6 tw-rounded-full" />
    <Skeleton height="sm" className={cn('tw-w-40', className)} />
  </div>
);

export const SkeletonList = ({ hasHeading = false }: { hasHeading?: boolean }) => (
  <div className="tw-flex tw-flex-col tw-gap-4 tw-p-6">
    {hasHeading ? <Skeleton height="xxs" className="tw-w-28" /> : null}

    <div className="tw-flex tw-flex-col tw-gap-4">
      <Item className="tw-w-96" />
      <Item className="tw-w-60" />
      <Item className="tw-w-72" />
      <Item className="tw-w-52" />
      <Item className="tw-w-96" />
      <Item className="tw-w-60" />
      <Item className="tw-w-72" />
      <Item className="tw-w-52" />
      <Item className="tw-w-96" />
      <Item className="tw-w-60" />
      <Item className="tw-w-72" />
      <Item className="tw-w-52" />
    </div>
  </div>
);
