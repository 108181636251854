import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import type Promise from 'bluebird';
import type { Map } from 'immutable';

import { Link } from '@keboola/design';

import CodeEditor from '@/react/common/CodeEditor';
import ConfirmModal from '@/react/common/ConfirmModal';

const ExecutionStepModal = (props: {
  show: boolean;
  onHide: () => void;
  onSubmit: (command: string) => Promise<any>;
  step?: Map<string, any>;
}) => {
  const [value, setValue] = React.useState('');
  const isNew = props.step?.has('new');

  return (
    <ConfirmModal
      buttonType="success"
      icon={isNew ? 'plus' : 'pen'}
      title={isNew ? 'Add New Step' : 'Update Step'}
      buttonLabel={isNew ? 'Add Execution Step' : 'Update Execution Step'}
      onEnter={() => setValue(props.step?.valueSeq().first() || '')}
      isDisabled={!value.match(/^dbt .+/)}
      text={
        <>
          <FormGroup>
            <ControlLabel>Execution Step:</ControlLabel>
            <CodeEditor
              autoFocus
              withAutocomplete
              value={value}
              onChange={setValue}
              options={{
                mode: 'dbt',
                placeholder: 'dbt command',
                lineNumbers: false,
                extraKeys: { 'Ctrl-Space': 'autocomplete', 'Alt-Space': 'autocomplete' },
              }}
              help={
                <>
                  Standard dbt{' '}
                  <Link href="https://docs.getdbt.com/reference/dbt-commands">core syntax</Link>.
                </>
              }
            />
          </FormGroup>
        </>
      }
      show={props.show}
      onHide={props.onHide}
      onConfirm={() => props.onSubmit(value.trim())}
    />
  );
};

export default ExecutionStepModal;
