import React from 'react';

import { Tooltip } from '@keboola/design';

import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';

const TriggerSwitch = () => {
  return (
    <Tooltip
      placement="top"
      type="explanatory"
      tooltip="An event trigger cannot be disabled, only deleted."
    >
      <ActivateDeactivateSwitch isActive noTooltips buttonDisabled className="tw-p-0" />
    </Tooltip>
  );
};

export default TriggerSwitch;
