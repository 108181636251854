import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { HelpBlock } from '@keboola/design';

import StorageApiLink from '@/modules/components/react/components/StorageApiTableLinkEx';

const TitleSection = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      title: PropTypes.string,
      identifier: PropTypes.string,
    }),
    tableId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    const { value, onChange, disabled } = this.props;

    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Dataset</h2>
        </div>
        <div className="box-content">
          <div className="form-horizontal">
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Storage table</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl.Static>
                  <StorageApiLink tableId={this.props.tableId} />
                </FormControl.Static>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Title</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  disabled={disabled}
                  onChange={(e) => onChange({ title: e.target.value })}
                  value={value.title}
                />
                <HelpBlock className="tw-mt-1">Name of the dataset in GoodData</HelpBlock>
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Identifier (optional)</ControlLabel>
              </div>
              <div className="col-xs-8">
                <FormControl
                  type="text"
                  disabled={disabled}
                  onChange={(e) => onChange({ identifier: e.target.value })}
                  value={value.identifier || ''}
                />
                <HelpBlock className="tw-mt-1">
                  Custom identifier of the dataset in GoodData
                </HelpBlock>
              </div>
            </FormGroup>
          </div>
        </div>
      </div>
    );
  },
});

export default TitleSection;
