import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '@keboola/design';

const Loader = ({
  className = '',
  fixedWidth = true,
}: {
  className?: string | Record<string, boolean>;
  fixedWidth?: boolean;
}) => {
  return <FontAwesomeIcon spin icon="spinner" fixedWidth={fixedWidth} className={cn(className)} />;
};

export default Loader;
