import React from 'react';

import { URLS } from '@keboola/constants';
import { Alert, Link } from '@keboola/design';

import { componentTypes } from '@/constants/componentTypes';
import { HIDE_WORKSPACES_BILLING_ALERT } from '@/constants/localStorageKeys';
import { getAllowedTransformations } from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import { BILLABLE } from '@/modules/sandboxes/Constants';
import { resolveSandboxTypeFromComponentId } from '@/modules/sandboxes/helpers';
import StackFeaturesStore from '@/modules/stack-features/Store';
import useLocalStorage from '@/react/hooks/useLocalStorage';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

const WorkspacesBillingAlert = () => {
  const [isHide, hideAlert] = useLocalStorage(HIDE_WORKSPACES_BILLING_ALERT, false);
  const { allowedWorkspaces } = useStores(
    () => {
      const allowedWorkspaces = getAllowedTransformations(
        ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        ApplicationStore.getSapiToken(),
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll(),
      )
        .filter((component: Map<string, any>, componentId: string) =>
          Object.values(BILLABLE).some(
            (el) => el === resolveSandboxTypeFromComponentId(componentId),
          ),
        )
        .map((component: Map<string, any>) => component.get('name'));

      return { allowedWorkspaces };
    },
    [],
    [StackFeaturesStore, ApplicationStore, ComponentsStore],
  );

  if (isHide) {
    return null;
  }

  return (
    <div className="container tw-mt-5">
      <Alert onClose={() => hideAlert(true)}>
        Active{' '}
        <span className="font-medium">
          {allowedWorkspaces
            ? allowedWorkspaces.join(allowedWorkspaces.count() === 2 ? ' and ' : ', ') + ' '
            : ''}
        </span>
        workspaces consume credits/minutes from your balance.{' '}
        <Link
          href={`${URLS.USER_DOCUMENTATION}/management/project/limits/#project-power--time-credits`}
        >
          Learn more about billing
        </Link>
      </Alert>
    </div>
  );
};

export default WorkspacesBillingAlert;
