import { List, Map, OrderedMap } from 'immutable';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '@/constants/features';
import {
  canManageNotifications,
  canManageSchedule,
  canManageTriggers,
} from '@/modules/admin/privileges';
import { routeNames as automationsRouteNames } from '@/modules/automations/constants';
import {
  getAllowedPatternComponents,
  getAllowedTransformations,
  prepareFoldersFromMetadata,
} from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import { mergeSampleDataToConfigurations } from '@/modules/components-directory/helpers';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import EventTriggersStore from '@/modules/event-trigger/EventTriggersStore';
import {
  getRunningFlowStatus,
  jobVersionMatch,
  prepareVisualizationPhases,
  sortPhases,
  withLastFakePhase,
} from '@/modules/flows/helpers';
import NotificationsStore from '@/modules/notifications/store';
import { prepareOrchestration } from '@/modules/orchestrations-v2/helpers';
import { getLocalStateValue } from '@/modules/orchestrations-v2/localState';
import JobsStore from '@/modules/queue/store';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import StreamStore from '@/modules/stream/store';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const useFlow = (configId?: string) => {
  const flowsData = useStores(
    () => {
      if (!configId) return {};

      const config = InstalledComponentsStore.getConfig(KEBOOLA_ORCHESTRATOR, configId);
      const flow = prepareOrchestration(
        config,
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SCHEDULER),
      );

      const sapiToken = ApplicationStore.getSapiToken();
      const tasksFromConfig = config.getIn(['configuration', 'tasks'], List());
      const phasesFromConfig = sortPhases(config.getIn(['configuration', 'phases'], List()));
      const tasks = getLocalStateValue(configId, ['tasks'], tasksFromConfig);
      const phases = getLocalStateValue(configId, ['phases'], phasesFromConfig);
      const phasesWithSomeTasks = phases.filter((phase: Map<string, any>) => {
        return tasks.some((task: Map<string, any>) => task.get('phase') === phase.get('id'));
      });
      const isChanged =
        !tasks.equals(tasksFromConfig) || !phasesWithSomeTasks.equals(phasesFromConfig);

      const allComponents = ComponentsStore.getAll() as Map<string, any>;
      const allowedTransformationComponents = getAllowedTransformations(
        ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        sapiToken,
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll(),
      );
      const allInstalledComponents = InstalledComponentsStore.getAll() as Map<string, any>;
      const allAllowedComponents = allComponents.filter((component, componentId) => {
        return (
          component.get('type') !== componentTypes.TRANSFORMATION ||
          allowedTransformationComponents.has(componentId) ||
          !allInstalledComponents.getIn([componentId, 'configurations'], Map()).isEmpty()
        );
      });

      const allJobs = JobsStore.getAll();
      const latestJobData = JobsStore.getLatestJobs(KEBOOLA_ORCHESTRATOR, configId);
      const latestJobs = latestJobData.get('jobs') ?? OrderedMap();
      const latestJob = latestJobs.first();
      const runningJob = !isChanged
        ? getLocalStateValue(
            configId,
            ['runningJob'],
            latestJob && jobVersionMatch(config, latestJob) ? latestJob : null,
          )
        : null;
      const flowStatus = runningJob ? getRunningFlowStatus(allJobs, runningJob) : null;
      const hasSnowflakeDynamicBackendSize = ApplicationStore.hasSnowflakeDynamicBackendSize();
      const hasJobsDynamicBackendSize = ApplicationStore.hasJobsDynamicBackendSize();
      const componentsMetadata = InstalledComponentsStore.getAllMetadata();
      const configurations = mergeSampleDataToConfigurations(
        allInstalledComponents,
        allAllowedComponents,
      ).deleteIn([KEBOOLA_ORCHESTRATOR, 'configurations', configId]);
      const readOnly =
        RoutesStore.getCurrentRouteConfig().get('name') === automationsRouteNames.DETAIL ||
        ApplicationStore.isReadOnly();

      return {
        readOnly,
        configId,
        config,
        phases,
        tasks,
        configurations,
        componentsMetadata,
        flow,
        isChanged,
        allJobs,
        sapiToken,
        flowStatus,
        latestJobData,
        hasSnowflakeDynamicBackendSize,
        hasJobsDynamicBackendSize,
        activeTab: RoutesStore.getCurrentRouteParam('tab'),
        canManageSchedule: canManageSchedule(sapiToken),
        canManageTriggers: canManageTriggers(sapiToken),
        canSetupNotifications: canManageNotifications(sapiToken),
        projectId: ApplicationStore.getCurrentProjectId(),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        component: ComponentsStore.getComponent(KEBOOLA_ORCHESTRATOR),
        components: allAllowedComponents,
        allowedPatternComponents: getAllowedPatternComponents(
          ComponentsStore.getAllForType(componentTypes.CODE_PATTERN),
          DevBranchesStore.isDevModeActive(),
        ),
        folders: prepareFoldersFromMetadata(componentsMetadata),
        tablesMetadataMap: prepareTablesMetadataMap(TablesStore.getAll()),
        otherFlows: configurations.getIn([KEBOOLA_ORCHESTRATOR, 'configurations'], Map()),
        visualizationPhases: prepareVisualizationPhases(
          readOnly ? phases : withLastFakePhase(phases),
          tasks,
          allComponents,
          allInstalledComponents,
          InstalledComponentsStore.getAllDeleted() as Map<string, any>,
        ),
        tables: TablesStore.getAll() as Map<string, any>,
        buckets: BucketsStore.getAll() as Map<string, any>,
        allNotifications: NotificationsStore.getAll(),
        notifications: NotificationsStore.getNotifications(
          KEBOOLA_ORCHESTRATOR,
          configId,
        ) as List<any>,
        triggers: EventTriggersStore.get(KEBOOLA_ORCHESTRATOR, configId) as Map<string, any>,
        jobs: latestJobs,
        admins: ApplicationStore.getAdmins(),
        hasFlows: ApplicationStore.hasFlows(),
        jobsPendingActions: JobsStore.getPendingActions() as Map<string, any>,
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
        showBackendSize: hasSnowflakeDynamicBackendSize || hasJobsDynamicBackendSize,
        hasDataApps: ApplicationStore.hasDataApps(),
        hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
          FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
        ),
        hasTemplates: ApplicationStore.hasTemplates(),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        hasDataStreams: ApplicationStore.hasDataStreams(),
        dataStreamSources: StreamStore.getStore().sources,
      };
    },
    [configId],
    [
      RoutesStore,
      TablesStore,
      BucketsStore,
      EventTriggersStore,
      ApplicationStore,
      ComponentsStore,
      NotificationsStore,
      InstalledComponentsStore,
      StackFeaturesStore,
      JobsStore,
      StreamStore,
    ],
  );

  return flowsData;
};

export default useFlow;
