import React from 'react';
import { Button } from 'react-bootstrap';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Tooltip } from '@keboola/design';

type Props = {
  tooltip: string;
  onClick: () => void;
  disabled?: boolean;
} & IconProps;

type IconProps =
  | { libraryIcon: IconProp; icon?: never }
  | { icon: React.ReactNode; libraryIcon?: never };

export const TableIconAction = ({ tooltip, libraryIcon, icon, ...buttonProps }: Props) => (
  <Tooltip placement="top" tooltip={tooltip} triggerClassName="tw-align-middle">
    <Button {...buttonProps} bsStyle="link" className={'btn-link-inline btn-link-muted'}>
      {libraryIcon ? <FontAwesomeIcon icon={libraryIcon} fixedWidth /> : icon}
    </Button>
  </Tooltip>
);
