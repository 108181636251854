import { fromJS } from 'immutable';

import { QUERY_TYPE } from '@/modules/ex-facebook/constants';
const exfbtemplates = [
  {
    id: 'allposts',
    name: 'All posts',
    template: {
      name: 'feed',
      query: {
        path: 'feed',
        fields: 'attachments{caption, type, description},message,created_time,shares',
      },
    },
  },

  {
    id: 'allcomments',
    name: 'All comments',
    template: {
      name: 'comments',
      query: {
        path: 'feed',
        fields: 'comments{message,created_time,from,comments{message,created_time,from}}',
      },
    },
  },

  {
    id: 'reviews',
    name: 'Page Reviews',
    template: {
      name: 'ratings',
      query: {
        path: 'ratings',
        fields: 'created_time,reviewer,rating,review_text,has_review,has_rating',
      },
    },
  },

  {
    id: 'onlycommnetsposts',
    name: 'Only comments of posts',
    template: {
      name: 'comments',
      query: {
        path: 'feed',
        fields: 'comments{message,created_time,from}',
      },
    },
  },

  {
    id: 'pageinsights',
    name: 'Selected page insights for over last 90 days',
    template: {
      name: 'page_insights',
      query: {
        path: '',
        fields:
          'insights.since(90 days ago).metric(page_views_total, page_fan_removes, page_fan_adds, page_fans, page_fans, page_impressions)',
      },
    },
  },

  {
    id: 'postsinsights',
    name: 'Selected posts insights',
    template: {
      name: 'posts_insights',
      query: {
        path: 'feed',
        fields:
          'insights.since(now).metric(post_consumptions, post_impressions_fan, post_impressions_paid, post_impressions, page_posts_impressions_organic, page_posts_impressions_paid, page_posts_impressions)',
      },
    },
  },

  {
    id: 'allpostslikessummary',
    name: 'All posts LIKE reactions summary',
    template: {
      name: 'feed_likes',
      query: {
        path: 'feed',
        fields: 'reactions.type(LIKE).summary(total_count).limit(0)',
      },
    },
  },

  {
    id: 'allpostswowssummary',
    name: 'All posts WOW reactions summary',
    template: {
      name: 'feed_wow',
      query: {
        path: 'feed',
        fields: 'reactions.type(WOW).summary(total_count).limit(0)',
      },
    },
  },

  {
    id: 'allpostshahasummary',
    name: 'All posts HAHA reactions summary',
    template: {
      name: 'feed_haha',
      query: {
        path: 'feed',
        fields: 'reactions.type(HAHA).summary(total_count).limit(0)',
      },
    },
  },

  {
    id: 'allpostssadsummary',
    name: 'All posts SAD reactions summary',
    template: {
      name: 'feed_sad',
      query: {
        path: 'feed',
        fields: 'reactions.type(SAD).summary(total_count).limit(0)',
      },
    },
  },

  {
    id: 'allpostsANGRYsummary',
    name: 'All posts ANGRY reactions summary',
    template: {
      name: 'feed_angry',
      query: {
        path: 'feed',
        fields: 'reactions.type(ANGRY).summary(total_count).limit(0)',
      },
    },
  },

  {
    id: 'allpostslovesummary',
    name: 'All posts LOVE reactions summary',
    template: {
      name: 'feed_love',
      query: {
        path: 'feed',
        fields: 'reactions.type(LOVE).summary(total_count).limit(0)',
      },
    },
  },
];

const exfbAdsTemplates = {
  [QUERY_TYPE.NESTED]: [
    {
      id: 'ads',
      name: 'All Ads',
      template: {
        query: {
          path: 'ads',
          fields: 'id,name,adset_id',
        },
      },
    },
    {
      id: 'campaigns',
      name: 'All Campaigns',
      template: {
        query: {
          path: 'campaigns',
          fields: 'id,name,account_id',
        },
      },
    },
    {
      id: 'adsets',
      name: 'All Adsets',
      template: {
        query: {
          path: 'adsets',
          fields: 'id,name,campaign_id',
        },
      },
    },
  ],
  [QUERY_TYPE.ASYNC_INSIGHTS]: [
    {
      id: 'asyncadsinsights',
      name: 'Async Ads Insights For Last Month',
      template: {
        query: {
          parameters:
            'fields=ad_id,ad_name,impressions,reach,clicks,spend&level=ad&action_breakdowns=action_type&date_preset=last_month&time_increment=1',
        },
      },
    },
    {
      id: 'asyncadsinsightsactions',
      name: 'Async Ads Insights Actions Stats For Last Month',
      template: {
        query: {
          parameters:
            'fields=ad_id,actions&level=ad&action_breakdowns=action_type&date_preset=last_month&time_increment=1',
        },
      },
    },
    {
      id: 'asynccampaigninsights',
      name: 'Async Campaigns Detail - Q1 - Insights',
      template: {
        query: {
          parameters:
            'level=campaign&fields=account_id,account_name,campaign_id,campaign_name,impressions,clicks,spend,reach&date_preset=last_28d&time_increment=1',
        },
      },
    },
    {
      id: 'async_campaigns_insights_type',
      name: 'Async Campaigns Detail - Q1 - Action Types',
      template: {
        query: {
          parameters:
            'level=campaign&fields=account_id,account_name,campaign_id,campaign_name,actions&action_breakdowns=action_type&date_preset=last_28d&time_increment=1',
        },
      },
    },
    {
      id: 'async_campaigns_insights_reaction',
      name: 'Async Campaigns Detail - Q1 - Action Reactions',
      template: {
        query: {
          parameters:
            'level=campaign&fields=account_id,account_name,campaign_id,campaign_name,actions&action_breakdowns=action_reaction&date_preset=last_28d&time_increment=1',
        },
      },
    },
  ],
};

const exInstagramTemplates = [
  {
    id: 'pageinfo',
    name: 'Page Info',
    template: {
      name: 'page',
      query: {
        path: '',
        fields: 'id,biography,followers_count,media_count,username,website',
      },
    },
  },

  {
    id: 'pageaudienceinsights',
    name: 'Page Audience Insights',
    template: {
      name: 'page_audience',
      query: {
        path: '',
        fields:
          'insights.period(lifetime).metric(audience_gender_age, audience_locale, audience_country, audience_city, online_followers)',
      },
    },
  },

  {
    id: 'pagedailyinsights',
    name: 'Page Daily Insights',
    template: {
      name: 'page_daily_insights',
      query: {
        path: '',
        fields: 'insights.since(30 days ago).period(day).metric(reach,impressions,follower_count)',
      },
    },
  },

  {
    id: 'medialifetimeinsights',
    name: 'Media Lifetime Insights',
    template: {
      name: 'media_lifetime_insights',
      query: {
        path: 'media',
        fields: 'insights.metric(impressions,reach,saved)',
      },
    },
  },

  {
    id: 'media',
    name: 'Media',
    template: {
      name: 'media',
      query: {
        path: 'media',
        fields:
          'id,caption,media_type,like_count,ig_id,comments_count,is_comment_enabled,media_url,owner,permalink,shortcode,timestamp,thumbnail_url,comments,from_id,from_full_name',
      },
    },
  },
];

export default fromJS({
  'keboola.ex-facebook': exfbtemplates,
  'keboola.ex-instagram': exInstagramTemplates,
  'keboola.ex-facebook-ads': exfbAdsTemplates,
});
