import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { Button } from '@keboola/design';

import ProfilesManagerModal from './ProfilesManagerModal';
import ProfilesTable from './ProfilesTable';

class Profiles extends React.Component {
  state = {
    showModal: false,
    isSaving: false,
  };

  render() {
    if (!this.props.hasCredentialsId && this.props.profiles.isEmpty()) {
      return null;
    }

    return (
      <div className="box-separator">
        <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
          <h2 className="tw-m-0 tw-text-base">Profiles</h2>
          {!this.props.profiles.isEmpty() && this.renderSetupButton()}
        </div>
        {this.props.profiles.isEmpty() ? (
          <div className="box">
            <div className="box-content tw-justify-items-center">
              <p>No profiles selected yet.</p>
              {this.renderSetupButton()}
            </div>
          </div>
        ) : (
          <ProfilesTable
            profiles={this.props.profiles}
            onSave={this.handleSave}
            readOnly={this.props.readOnly}
          />
        )}
        {this.renderModal()}
      </div>
    );
  }

  renderSetupButton() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <Button size="small" onClick={() => this.setState({ showModal: true })}>
        <FontAwesomeIcon icon="plus" />
        Add Profiles
      </Button>
    );
  }

  renderModal() {
    return (
      <ProfilesManagerModal
        componentId={this.props.componentId}
        show={this.state.showModal}
        onHideFn={() => this.setState({ showModal: false })}
        profiles={this.props.profiles}
        isSaving={this.state.isSaving}
        onSave={this.handleSave}
        oauthCredentials={this.props.oauthCredentials}
      />
    );
  }

  handleSave = (profiles) => {
    this.setState({ isSaving: true });
    return this.props.handleSave(profiles).finally(() => this.setState({ isSaving: false }));
  };
}

Profiles.propTypes = {
  componentId: PropTypes.string.isRequired,
  profiles: PropTypes.instanceOf(List).isRequired,
  oauthCredentials: PropTypes.instanceOf(Map).isRequired,
  hasCredentialsId: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  handleSave: PropTypes.func.isRequired,
};

export default Profiles;
