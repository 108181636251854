import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { cn, IconButton, Tooltip } from '@keboola/design';

const PhaseInput = (props: {
  phase: Map<string, any>;
  previousPhase: Map<string, any>;
  newTaskPosition: null | string;
  handleShowAddTaskModal: (position: string | false) => void;
  readOnly: boolean;
  isLast: boolean;
}) => {
  if (props.readOnly) {
    return null;
  }

  const inlineButtonId = `${props.previousPhase.get('id')}:${props.phase.get('id')}`;

  return (
    <>
      {!props.isLast && (
        <div className="between-phases-action">
          <Tooltip
            placement="top"
            tooltip={props.newTaskPosition === inlineButtonId ? 'Close' : 'Add Task'}
            triggerClassName="add-task-inline-container"
          >
            <IconButton
              variant="inline"
              className={cn('add-task-inline circle-button [&_svg]:!tw-text-white', {
                active: props.newTaskPosition === inlineButtonId,
              })}
              onClick={() => props.handleShowAddTaskModal(inlineButtonId)}
              icon={props.newTaskPosition === inlineButtonId ? 'xmark' : 'plus'}
            />
          </Tooltip>
        </div>
      )}
      <FontAwesomeIcon icon="caret-down" className="f-16" />
    </>
  );
};

export default PhaseInput;
