import React, { useState } from 'react';
import type { Map } from 'immutable';
import Switch from 'rc-switch';

import { Tooltip } from '@keboola/design';

import { DATA_TYPE_SUPPORT } from '@/modules/components/Constants';
import {
  hasAuthoritativeDataTypes,
  hasTableWithoutTypes,
  supportAuthoritativeDataTypes,
} from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import SwitchStateLink from '@/react/common/ActionControls/SwitchStateLink';
import InfoTooltip from '@/react/common/InfoTooltip';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

type Props = {
  isReadOnly: boolean;
  rowId?: string;
  configId: string;
  componentId: string;
  configData: Map<string, any>;
  configuration: Map<string, any>;
  component: Map<string, any>;
};

const AutoTypesSwitch = ({
  isReadOnly,
  rowId,
  configId,
  componentId,
  configData,
  configuration,
  component,
}: Props) => {
  const { allTables } = useStores(
    () => {
      return {
        allTables: StorageTablesStore.getAll(),
      };
    },
    [],
    [StorageTablesStore],
  );

  const [isSaving, setIsSaving] = useState(false);

  const componentSupportsAutoTypes =
    ApplicationStore.hasNewNativeTypes() && supportAuthoritativeDataTypes(component);

  if (rowId || !componentSupportsAutoTypes) {
    return null;
  }

  const anyNotTypedTable = hasTableWithoutTypes(componentId, configuration, allTables);

  const isEnabled =
    hasAuthoritativeDataTypes(configData) ||
    (!configData.getIn(['storage', 'output', 'data_type_support']) && !anyNotTypedTable);

  if (!isEnabled && anyNotTypedTable) {
    return (
      <li>
        <Tooltip
          placement="top"
          type="explanatory"
          tooltip="The configuration has existing table without data types defined. Automatic data type retrieval cannot be enabled."
          triggerClassName="btn-link not-allowed"
        >
          <Switch disabled checked={false} prefixCls="switch" />
          Automatic data types
        </Tooltip>
      </li>
    );
  }

  const toggleAutoTypes = () => {
    setIsSaving(true);
    InstalledComponentsActionCreators.updateComponentConfiguration(
      componentId,
      configId,
      {
        configuration: JSON.stringify(
          configData.setIn(
            ['storage', 'output', 'data_type_support'],
            isEnabled ? DATA_TYPE_SUPPORT.HINTS : DATA_TYPE_SUPPORT.AUTHORITATIVE,
          ),
        ),
      },
      `"${isEnabled ? 'Disable' : 'Enable'}" automatic data types`,
    ).finally(() => setIsSaving(false));
  };

  return (
    <li>
      <SwitchStateLink
        isActive={isEnabled}
        readOnly={isReadOnly}
        isPending={isSaving}
        onChange={toggleAutoTypes}
        label={
          <div className="tw-inline-flex tw-items-center tw-justify-start">
            Automatic data types
            <InfoTooltip
              tooltip={
                <>
                  <p className="tooltip-title">
                    Automatic data type retrieval is now {isEnabled ? 'enabled' : 'disabled'}.
                  </p>
                  <p>
                    {isEnabled ? (
                      <>
                        This means that newly created tables will have their columns automatically
                        assigned appropriate data types based on the data they store. Existing
                        tables will not be affected by this change. If you wish to have data types
                        defined for existing tables, you&apos;ll need to drop them and run the
                        component to recreate them.
                      </>
                    ) : (
                      <>
                        This means that all columns in new tables will be created as type VARCHAR by
                        default. Existing tables will remain unaffected by this change. If you no
                        longer want the component to generate data types for existing tables,
                        you&apos;ll need to drop them and run the component to recreate them.
                      </>
                    )}
                  </p>
                </>
              }
            />
          </div>
        }
      />
    </li>
  );
};

export default AutoTypesSwitch;
