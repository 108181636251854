import React, { useState } from 'react';
import type { Map } from 'immutable';

import { cn, FormGroup, Label, TextInput } from '@keboola/design';

import { AVAILABLE_COUNTRIES, INVALID_VAT_ERROR } from '@/modules/billing/constants';
import { isVatIdValid } from '@/modules/billing/helpers';
import Select from '@/react/common/Select';
import { matchByWords } from '@/utils';
import isEmailValid from '@/utils/isEmailValid';

type Fields =
  | 'name'
  | 'email'
  | 'addressCountry'
  | 'addressLine1'
  | 'addressLine2'
  | 'addressCity'
  | 'addressPostalCode'
  | 'vatId';
type FieldsState = Map<Fields, string>;
type Props = {
  state: FieldsState;
  setState: (state: FieldsState, error?: string | null) => void;
  vertical?: boolean;
};

const BillingEditFields = (props: Props) => {
  const [countrySelectQuery, setCountrySelectQuery] = useState('');
  const formGroupClassName = cn('tw-mb-4', {
    ['tw-grid tw-grid-cols-[1fr_4fr] tw-items-center tw-gap-5']: props.vertical,
  });
  const labelClassName = props.vertical ? 'tw-text-right' : '';

  return (
    <div className={props.vertical ? 'tw-ml-5' : ''}>
      <FormGroup className={formGroupClassName}>
        <Label htmlFor="email" className={labelClassName}>
          Billing Email
        </Label>
        <TextInput
          id="email"
          type="email"
          variant="secondary"
          placeholder="Billing Email Adress"
          value={props.state.get('email', '')}
          onChange={(value) => {
            props.setState(
              props.state.set('email', value),
              isEmailValid(value) ? null : 'Provided email address is not valid.',
            );
          }}
        />
      </FormGroup>

      <FormGroup className={formGroupClassName}>
        <Label htmlFor="company-name" className={labelClassName}>
          Company Name <Label.Optional className="tw-block" />
        </Label>
        <TextInput
          id="company-name"
          variant="secondary"
          placeholder="Company Name"
          value={props.state.get('name', '')}
          onChange={(value) => props.setState(props.state.set('name', value))}
        />
      </FormGroup>

      <FormGroup className={formGroupClassName}>
        <Label htmlFor="country" className={labelClassName}>
          Country
        </Label>
        <Select
          id="country"
          clearable={false}
          placeholder="Select Country"
          inputValue={countrySelectQuery}
          onInputChange={setCountrySelectQuery}
          options={Object.entries(AVAILABLE_COUNTRIES)
            .map(([value, label]) => ({
              label,
              value,
            }))
            .sort((option) =>
              countrySelectQuery &&
              option.label.toLowerCase().startsWith(countrySelectQuery.toLowerCase())
                ? -1
                : 1,
            )}
          value={props.state.get('addressCountry', '')}
          onChange={(selected: string) =>
            props.setState(
              props.state.set('addressCountry', selected),
              isVatIdValid(props.state.get('vatId', ''), selected) ? null : INVALID_VAT_ERROR,
            )
          }
          filterOption={(data, query) => matchByWords(data.label, query)}
        />
      </FormGroup>

      <FormGroup className={formGroupClassName}>
        <Label htmlFor="address1" className={labelClassName}>
          Address Line 1
        </Label>

        <TextInput
          id="address1"
          variant="secondary"
          placeholder="Address Line 1"
          value={props.state.get('addressLine1', '')}
          onChange={(value) => props.setState(props.state.set('addressLine1', value))}
        />
      </FormGroup>

      <FormGroup className={formGroupClassName}>
        <Label htmlFor="address2" className={labelClassName}>
          Address Line 2 <Label.Optional />
        </Label>
        <TextInput
          id="address2"
          variant="secondary"
          placeholder="Address Line 2"
          value={props.state.get('addressLine2', '')}
          onChange={(value) => props.setState(props.state.set('addressLine2', value))}
        />
      </FormGroup>

      <FormGroup className={formGroupClassName}>
        <Label htmlFor="city" className={labelClassName}>
          City
        </Label>

        <div className="tw-grid tw-grid-cols-2 tw-gap-5">
          <TextInput
            id="city"
            variant="secondary"
            placeholder="City"
            value={props.state.get('addressCity', '')}
            onChange={(value) => props.setState(props.state.set('addressCity', value))}
            className="tw-w-full"
          />

          <div className={cn('tw-relative', { 'tw-flex tw-gap-5': props.vertical })}>
            <Label
              htmlFor="postal-code"
              className={cn('tw-whitespace-nowrap tw-items-center tw-flex', {
                'tw-absolute -tw-top-6': !props.vertical,
              })}
            >
              Postal Code
            </Label>
            <TextInput
              id="postal-code"
              variant="secondary"
              placeholder="000 00"
              value={props.state.get('addressPostalCode', '')}
              onChange={(value) => props.setState(props.state.set('addressPostalCode', value))}
              className="tw-w-full"
            />
          </div>
        </div>
      </FormGroup>

      <FormGroup className={formGroupClassName}>
        <Label htmlFor="vat-id" className={labelClassName}>
          Vat ID <Label.Optional className="tw-block" />
        </Label>
        <TextInput
          id="vat-id"
          variant="secondary"
          placeholder="Vat ID"
          value={props.state.get('vatId', '')}
          onChange={(value) =>
            props.setState(
              props.state.set('vatId', value),
              isVatIdValid(value, props.state.get('addressCountry', '')) ? null : INVALID_VAT_ERROR,
            )
          }
        />
      </FormGroup>
    </div>
  );
};

export default BillingEditFields;
