import React from 'react';

import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { prepareSharedBuckets } from '@/modules/data-catalog/helpers';
import { SHARED_FROM_PROJECT_ROUTES, SHARED_WITH_YOU_ROUTES } from '@/modules/data-catalog/routes';
import { FilterPanel, TabLink, TabNav } from '@/react/common';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import SharedBuckets from './SharedBuckets';

const SharedWithYou = () => {
  const store = useStores(
    () => {
      return {
        allBuckets: StorageBucketsStore.getAll(),
        sapiToken: ApplicationStore.getSapiToken(),
        currentProjectId: ApplicationStore.getCurrentProjectId(),
        sharedBuckets: StorageBucketsStore.getSharedBuckets(),
        linkedBuckets: StorageBucketsStore.getLinkedBuckets(),
      };
    },
    [],
    [StorageBucketsStore, ApplicationStore],
  );

  const [query, setQuery] = React.useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );

  const sharedAndLinkedBuckets = React.useMemo(() => {
    return prepareSharedBuckets(
      store.sharedBuckets,
      store.linkedBuckets,
      store.currentProjectId,
      query,
    );
  }, [store.sharedBuckets, store.linkedBuckets, store.currentProjectId, query]);

  return (
    <>
      <div className="tabs-with-border-wrapper">
        <TabNav>
          <TabLink to={SHARED_WITH_YOU_ROUTES.shared_with_you} active useReactRouterLink>
            Shared with you
          </TabLink>
          <TabLink to={SHARED_FROM_PROJECT_ROUTES.shared_from_project} useReactRouterLink>
            Shared from this project
          </TabLink>
        </TabNav>
      </div>
      <FilterPanel
        query={query}
        onChange={(query) => {
          setQuery(query);
          RoutesStore.getRouter().updateQuery({ q: query });
        }}
        placeholder={() => {
          return !query ? `Search data catalog (${sharedAndLinkedBuckets.count()})` : '';
        }}
      />
      <SharedBuckets
        token={store.sapiToken}
        allBuckets={store.allBuckets}
        sharedBuckets={store.sharedBuckets}
        sharedAndLinkedBuckets={sharedAndLinkedBuckets}
        query={query}
      />
    </>
  );
};

export default SharedWithYou;
