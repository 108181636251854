import Promise from 'bluebird';
import { List, Map } from 'immutable';

const DEFAULT_INTERVAL = 30 * 1000; // 30 seconds

class Timer {
  timers = List();
  pendingPromises = Map();

  poll(callback, options = {}) {
    if (!options.skipFirst) {
      this.runAction(callback);
    }

    this.timers = this.timers.push({
      callback,
      intervalId: setInterval(() => this.runAction(callback), options.interval || DEFAULT_INTERVAL),
    });
  }

  stop(callback) {
    this.timers = this.timers.filter((timer) => {
      if (timer.callback !== callback) {
        return true;
      }
      this.stopAction(timer);
      return false;
    });
  }

  isPolling(callback) {
    return this.timers.some((timer) => timer.callback === callback);
  }

  runAction(callback) {
    const timer = this.timers.find((timer) => timer.callback === callback);

    if (
      timer &&
      this.pendingPromises.has(timer.intervalId) &&
      this.pendingPromises.get(timer.intervalId).isPending()
    ) {
      return;
    }

    if (timer) {
      this.pendingPromises = this.pendingPromises.set(
        timer.intervalId,
        Promise.resolve(callback()),
      );
      return;
    }

    callback();
  }

  stopAction(timer) {
    clearInterval(timer.intervalId);
    this.pendingPromises = this.pendingPromises
      .remove(timer.intervalId)
      .filter((promise) => promise?.isPending());
  }
}

export default new Timer();
