const routeNames = {
  ROOT: 'storage',
  JOBS: 'storage-jobs',
  EVENTS: 'storage-events',
  FILES: 'storage-files',
  STREAMS: 'storage-data-streams',
  STREAM_DETAIL: 'storage-data-stream-detail',
  BUCKET: 'storage-bucket',
  TABLE: 'storage-table',
};

const actionTypes = {
  TOGGLE_CONTEXT_FILTER: 'STORAGE_TOGGLE_CONTEXT_FILTER',
  TOGGLE_SHOW_ALL_BUCKETS: 'TOGGLE_SHOW_ALL_BUCKETS',
  TOGGLE_EXPAND_BUCKET: 'TOGGLE_EXPAND_BUCKET',
  RESET_EXPANDED_BUCKETS: 'RESET_EXPANDED_BUCKETS',
  UPDATE_SEARCH_QUERY: 'STORAGE_UPDATE_SEARCH_QUERY',
  UPDATE_SEARCH_FILTERS: 'STORAGE_UPDATE_SEARCH_FILTERS',
  UPDATE_FILES_SEARCH_QUERY: 'STORAGE_UPDATE_FILES_SEARCH_QUERY',
  UPDATE_BUCKETS_SORT: 'UPDATE_BUCKETS_SORT',
};

const backends = {
  SNOWFLAKE: 'snowflake',
  REDSHIFT: 'redshift',
  SYNAPSE: 'synapse',
  EXASOL: 'exasol',
  TERADATA: 'teradata',
  BIGQUERY: 'bigquery',
};

const dataPreviewDataType = {
  [backends.SNOWFLAKE]: 'DOUBLE',
  [backends.SYNAPSE]: 'REAL',
  [backends.EXASOL]: 'DECIMAL',
  [backends.TERADATA]: 'DECIMAL',
  [backends.BIGQUERY]: 'DECIMAL',
};

const jobsLimit = 20;
const filesLimit = 50;
const storageInitialLimit = 50;
const nameMaxLength = 96;
const ALIAS_SUFFIX = '_alias';

const eventsPredefinedSearches = [
  {
    name: 'Omit table fetches',
    query: 'NOT event:storage.tableDataPreview OR NOT event:storage.tableDetail',
  },
  {
    name: 'Omit table exports',
    query: 'NOT event:storage.tableExported',
  },
  {
    name: 'Imports/exports only',
    query:
      'event:storage.tableImportStarted OR event:storage.tableImportDone OR event:storage.tableImportError OR event:storage.tableExported',
  },
];

const bucketTabs = {
  OVERVIEW: 'overview',
  EVENTS: 'events',
};

const tableTabs = {
  OVERVIEW: 'overview',
  EVENTS: 'events',
  SCHEMA: 'schema',
  DATA_SAMPLE: 'data-sample',
  SNAPSHOT_AND_RESTORE: 'snapshot-and-restore',
  GRAPH: 'graph',
  USAGE: 'usage',
  LINEAGE: 'lineage',
} as const;

const tableModalTabs = {
  SHARE: 'share',
  EXPORT: 'export',
  LOAD: 'load',
  RESTORE: 'restore',
  SNAPSHOT: 'snapshot',
  ALIAS: 'alias',
  TRUNCATE: 'truncate',
  DELETE: 'delete',
  DELETE_MULTIPLE: 'deleteMultiple',
  CREATE_WORKSPACE: 'createWorkspace',
  USE_WORKSPACE: 'useWorkspace',
};

const EXPORT_TYPES = {
  GZIP: 'GZIP',
  CSV: 'CSV',
  XLSX: 'XLSX',
};

const INITIAL_CSV_FORM_STATE = {
  name: '',
  file: null,
  delimiter: ',',
  enclosure: '"',
  primaryKey: '',
  error: null,
  warning: null,
};

const nameWarning = 'Only alphanumeric characters, dashes, and underscores are allowed.';
const tableIdWarning = 'Only alphanumeric characters, dots, dashes, and underscores are allowed.';

const externalBigQueryTableType = 'bigquery-external-table';
const externalSnowflakeTableType = 'snowflake-external-table';

const EXTERNAL_DATASET_DISABLE_TOOLTIP = {
  DATA_CATALOG_BUCKET:
    'External bucket cannot be shared with other projects. You need to go to the other project and register it there.',
  DATA_CATALOG_TABLE:
    'External tables cannot be shared with other projects. You need to go to the other project and register it there.',
  TRIGGER: 'External table cannot be used as a trigger.',
  INPUT_MAPPING:
    'External table cannot be directly used in IM mapping; however, you can directly reference them in the code.',
  OUTPUT_MAPPING: 'External table cannot be used in OM mapping',
};

const SNOWFLAKE_DOCS = 'https://docs.snowflake.com/en/user-guide/data-sharing-intro';

export {
  routeNames,
  actionTypes,
  backends,
  dataPreviewDataType,
  jobsLimit,
  filesLimit,
  storageInitialLimit,
  nameMaxLength,
  eventsPredefinedSearches,
  ALIAS_SUFFIX,
  bucketTabs,
  tableTabs,
  tableModalTabs,
  EXPORT_TYPES,
  nameWarning,
  tableIdWarning,
  INITIAL_CSV_FORM_STATE,
  EXTERNAL_DATASET_DISABLE_TOOLTIP,
  externalBigQueryTableType,
  externalSnowflakeTableType,
  SNOWFLAKE_DOCS,
};
