import { createContext, useContext } from 'react';

import type { FormGroupContext } from './types';

const formGroupContext = createContext<FormGroupContext>({
  state: null,
});

export const FormGroupProvider = formGroupContext.Provider;

export const useFormGroupContext = () => useContext(formGroupContext);
