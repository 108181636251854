import React from 'react';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';
import _ from 'underscore';

import { Link } from '@keboola/design';

import StorageApiBucketLinkEx from '@/modules/components/react/components/StorageApiBucketLinkEx';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { SHARING_TYPE_LABELS } from '@/modules/data-catalog/constants';
import { getDescriptionWithDefaultValue } from '@/modules/data-catalog/helpers';
import NotAvailable from '@/modules/storage/components/NotAvailable';
import { CreatedDate, FileSize } from '@/react/common';
import RowsCount from '@/react/common/RowsCount';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ErrorContent from '@/react/pages/ErrorContent';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import BackendRow from './BackendRow';
import OwnerRow from './OwnerRow';
import SharedBucketTables from './SharedBucketTables';

const SharedBucketDetail = createReactClass({
  mixins: [createStoreMixin(StorageBucketsStore)],

  getStateFromStores() {
    const sharedBucketId = RoutesStore.getCurrentRouteParam('bucketId');
    const currentProjectId = ApplicationStore.getCurrentProjectId();
    const sharedProjectId = parseInt(RoutesStore.getCurrentRouteParam('projectId'), 10);

    return {
      admins: ApplicationStore.getAdmins(),
      sharedBucket: StorageBucketsStore.getSharedBucket(
        sharedBucketId,
        currentProjectId,
        sharedProjectId,
      ),
      urlTemplates: ApplicationStore.getUrlTemplates(),
    };
  },

  render() {
    if (!this.state.sharedBucket) {
      return <ErrorContent headerText="Bucket not found" />;
    }

    return (
      <>
        {this.renderOverview()}
        {this.renderDescription()}
        {this.renderTables()}
      </>
    );
  },

  renderOverview() {
    const linkedBucket = this.state.sharedBucket.get('linkedBucket');
    const isExternal = this.state.sharedBucket.get('hasExternalSchema');

    return (
      <div className="box info-row">
        <div className="info-row-section horizontal">
          <BackendRow backend={this.state.sharedBucket.get('backend')} />
          <OwnerRow
            owner={this.state.sharedBucket.get('sharedBy', Map())}
            admins={this.state.admins}
          />
          <p className="first-line font-medium flex-container">
            <span>Project</span>
            <span className="font-normal">
              <Link
                href={_.template(this.state.urlTemplates.get('project'))({
                  projectId: this.state.sharedBucket.getIn(['project', 'id']),
                })}
              >
                {this.state.sharedBucket.getIn(['project', 'name'])}
              </Link>
            </span>
          </p>
          <p className="first-line font-medium flex-container">
            <span>Shared to</span>
            <span className="font-normal text-muted">
              {SHARING_TYPE_LABELS[this.state.sharedBucket.get('sharing')]}
            </span>
          </p>
        </div>
        <div className="info-row-section horizontal">
          {linkedBucket && (
            <p className="first-line f-14 font-medium flex-container">
              <span>Linked to</span>
              <span className="font-normal">
                <StorageApiBucketLinkEx bucketId={linkedBucket.get('id')} />
              </span>
            </p>
          )}
          <p className="first-line f-14 font-medium flex-container">
            <span>Stage</span>
            <span className="font-normal text-muted">
              {this.state.sharedBucket.get('stage').toUpperCase()}
            </span>
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Created at</span>
            <CreatedDate
              className="font-normal text-muted"
              createdTime={this.state.sharedBucket.get('created')}
            />
          </p>
          {!isExternal && (
            <p className="first-line f-14 font-medium flex-container">
              <span>Last change</span>
              <CreatedDate
                className="font-normal text-muted"
                createdTime={this.state.sharedBucket.get('lastChangeDate')}
              />
            </p>
          )}
          <p className="first-line f-14 font-medium flex-container">
            <span>Row count</span>
            <span className="font-normal text-muted">
              {isExternal ? (
                <NotAvailable entity="external buckets" />
              ) : (
                <RowsCount count={this.state.sharedBucket.get('rowsCount')} />
              )}
            </span>
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Data size</span>
            <span className="font-normal text-muted">
              {isExternal ? (
                <NotAvailable entity="external buckets" />
              ) : (
                <FileSize size={this.state.sharedBucket.get('dataSizeBytes')} />
              )}
            </span>
          </p>
        </div>
      </div>
    );
  },

  renderDescription() {
    return (
      <div className="box">
        <div className="box-content">
          <h3 className="mt-0">Description</h3>
          {getDescriptionWithDefaultValue(this.state.sharedBucket) || 'No description available'}
        </div>
      </div>
    );
  },

  renderTables() {
    return <SharedBucketTables tables={this.state.sharedBucket.get('tables')} />;
  },
});

export default SharedBucketDetail;
