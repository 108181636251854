import { cva } from 'class-variance-authority';

import { cn } from '../../utils';

export const inputClassnames = cn(
  // reset
  'tw-border-none',
  'tw-bg-transparent',
  'tw-outline-none',
  'tw-w-full',
  'tw-leading-5',
  'tw-p-0',

  // base
  'tw-py-2.5',
  'tw-text-sm',

  // placeholder
  'placeholder:tw-text-neutral-400',
  'placeholder:tw-font-normal',

  // disabled
  'disabled:tw-cursor-not-allowed',
);

export const inputWrapperVariants = cva(
  [
    // positioning
    'tw-flex',
    'tw-items-center',
    'tw-gap-2',

    // base
    'tw-rounded',
    'tw-px-4',
    'tw-outline',
    'tw-outline-neutral-200',
    'tw-text-neutral-800',
    'tw-transition-all',
    'tw-font-sans',

    // focus
    'has-[input:focus]:tw-outline-1',
    'has-[input:focus]:tw-ring-4',

    //readonly
    'has-[input[readonly]]:tw-bg-neutral-150',
    'has-[input[readonly]]:focus-within:tw-ring-0',
    'has-[input[readonly]]:tw-outline-0',

    // disabled
    'disabled:tw-opacity-40',
  ],
  {
    defaultVariants: {
      variant: 'primary',
      state: 'default',
    },
    compoundVariants: [
      {
        variant: 'secondary',
        state: 'error',
        className: ['tw-bg-error-100'],
      },
      {
        variant: 'secondary',
        state: 'warning',
        className: ['tw-bg-warning-100'],
      },
    ],
    variants: {
      variant: {
        primary: ['tw-bg-white', 'tw-outline-1'],
        secondary: ['tw-bg-neutral-100', 'tw-outline-0'],
      },
      state: {
        default: [
          //focus
          'has-[input:focus]:tw-outline-secondary-500',
          'has-[input:focus]:tw-ring-secondary-200',

          ///readonly
          'has-[input[readonly]]:focus-within:tw-outline-0',
          'has-[input[readonly]]:active:tw-outline-0',
        ],
        error: [
          // base
          'tw-outline-error-500',

          // focus
          'has-[input:focus]:tw-outline-error-500',
          'has-[input:focus]:tw-ring-error-200',
        ],
        warning: [
          // base
          'tw-outline-warning-500',

          // focus
          'has-[input:focus]:tw-outline-warning-500',
          'has-[input:focus]:tw-ring-warning-200',
        ],
      },
    },
  },
);

export const clearButtonVariants = cva(
  [
    // reset
    'tw-border-none',
    'tw-bg-transparent',
    // positioning
    'tw-flex',
    'tw-items-center',

    'tw-cursor-pointer',
    'hover:tw-text-neutral-800',
  ],
  {
    defaultVariants: {
      state: 'default',
    },
    variants: {
      state: {
        default: ['tw-text-neutral-400', 'hover:tw-text-neutral-800'],
        error: ['tw-text-error-600', 'hover:tw-text-error-800'],
        warning: ['tw-text-warning-600', 'hover:tw-text-warning-800'],
      },
    },
  },
);
