import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';

import { IconButton, Tooltip } from '@keboola/design';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { createTablePrimaryKey } from '@/modules/storage/actions';
import ConfirmModal from '@/react/common/ConfirmModal';
import Select from '@/react/common/Select';
import ChangePrimaryKeyWarning from './ChangePrimaryKeyWarning';
import ConfigurationUsage from './ConfigurationUsage';
import DevBranchStorageWarning from './DevBranchStorageWarning';

const CreatePrimaryKey = (props: { table: Map<string, any> }) => {
  const [showModal, setShowModal] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);
  const [primaryKey, setPrimaryKey] = React.useState<string[]>([]);

  const handleSubmit = () => {
    setIsCreating(true);
    return createTablePrimaryKey(props.table.get('id'), { columns: primaryKey }).finally(() =>
      setIsCreating(false),
    );
  };

  return (
    <>
      <Tooltip tooltip="Create primary key" placement="top">
        <IconButton
          className="tw-inline-flex tw-h-max"
          variant="inline"
          onClick={() => setShowModal(true)}
          disabled={isCreating}
          icon="pen"
          isLoading={isCreating}
        />
      </Tooltip>
      <ConfirmModal
        show={showModal}
        icon="plus"
        title="Create Primary Key"
        buttonLabel="Create primary key"
        onEnter={() => setPrimaryKey([])}
        text={
          <>
            <FormGroup>
              <ControlLabel>Columns</ControlLabel>
              <Select
                multi
                autoFocus
                clearable={false}
                placeholder="Check one or more columns"
                value={primaryKey}
                onChange={(selectedColumns) => setPrimaryKey(selectedColumns.toJS())}
                options={props.table
                  .get('columns')
                  .map((column: any) => ({ label: column, value: column }))
                  .toArray()}
              />
            </FormGroup>
            <ChangePrimaryKeyWarning action="update" />
            <DevBranchStorageWarning
              message="The primary key will also be added to the table in production."
              hasProductionEntity={!isCreatedInDevBranch(props.table.get('bucket'))}
            />
            <ConfigurationUsage tableId={props.table.get('id')} />
          </>
        }
        onConfirm={handleSubmit}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};

export default CreatePrimaryKey;
