import React from 'react';
import Promise from 'bluebird';

import StorageActionCreators from '@/modules/components/StorageActionCreators';
import StorageJobsStore from '@/modules/components/stores/StorageJobsStore';
import StreamActionCreators from '@/modules/stream/actions';
import DetailHeaderButtons from '@/modules/stream/components/DetailHeaderButtons';
import StreamIndexHeaderButton from '@/modules/stream/components/IndexHeaderButton';
import NameEdit from '@/modules/stream/components/NameEdit';
import Detail from '@/modules/stream/Detail';
import { findSource } from '@/modules/stream/helpers';
import StreamIndex from '@/modules/stream/Index';
import StreamStore from '@/modules/stream/store';
import DummyBreadcrumb from '@/react/common/DummyBreadcrumb';
import ApplicationStore from '@/stores/ApplicationStore';
import nextTick from '@/utils/nextTick';
import BucketLabelsFromStore from './components/BucketLabelsFromStore';
import BucketNameEdit from './components/BucketNameEdit';
import BucketTitle from './components/BucketTitle';
import CreateTableButton from './components/CreateTableButton';
import HeaderButtons from './components/HeaderButtons';
import TableNameEdit from './components/TableNameEdit';
import TableRouteLabels from './components/TableRouteLabels';
import TableTitle from './components/TableTitle';
import UploadFileButton from './components/UploadFileButton';
import {
  loadBucketDetail,
  loadFiles,
  loadJob,
  loadJobs,
  loadTableDetail,
  resetStorageStore,
  tokenVerify,
  updateFilesSearchQuery,
} from './actions';
import Bucket from './Bucket';
import { routeNames } from './constants';
import Events from './Events';
import Files from './Files';
import Index from './Index';
import Jobs from './Jobs';
import Table from './Table';

const STORAGE_TITLE = 'Storage';
const SOURCE_POLL_INTERVAL = 15 * 1000; // 15 seconds

const routes = {
  name: routeNames.ROOT,
  title: STORAGE_TITLE,
  headerButtonsHandler: HeaderButtons,
  defaultRouteHandler: Index,
  onLeave: () => nextTick(resetStorageStore),
  requireData: [() => tokenVerify(), () => StorageActionCreators.loadBucketsAndTables()],
  childRoutes: [
    {
      name: routeNames.JOBS,
      path: 'jobs(/:jobId)',
      title: STORAGE_TITLE,
      breadcrumbHandler: DummyBreadcrumb,
      defaultRouteHandler: Jobs,
      poll: {
        skipFirst: true,
        action: () => loadJobs(),
      },
      requireData: [
        ({ jobId }) => {
          if (jobId) {
            if (StorageJobsStore.getAll().isEmpty()) {
              loadJobs();
            }

            if (!StorageJobsStore.getJob(jobId).isEmpty()) {
              return Promise.resolve();
            }

            return loadJob(jobId);
          }

          // init loading without waiting, we will show loading on the index page
          loadJobs();
        },
      ],
    },
    {
      name: routeNames.EVENTS,
      path: 'events',
      title: STORAGE_TITLE,
      breadcrumbHandler: DummyBreadcrumb,
      defaultRouteHandler: Events,
    },
    {
      name: routeNames.STREAMS,
      path: 'data-streams',
      title: 'Data Streams',
      breadcrumbHandler: DummyBreadcrumb,
      defaultRouteHandler: StreamIndex,
      headerButtonsHandler: StreamIndexHeaderButton,
      requireData: [
        (params) => {
          if (!ApplicationStore.hasDataStreams() || 'sourceId' in params) return Promise.resolve();

          return StreamActionCreators.loadSources();
        },
      ],
      childRoutes: [
        {
          name: routeNames.STREAM_DETAIL,
          path: ':sourceId',
          title: (routerState) =>
            findSource(StreamStore.getStore().sources, routerState.getIn(['params', 'sourceId']))
              ?.name,
          nameEdit: () => <NameEdit />,
          defaultRouteHandler: Detail,
          headerButtonsHandler: DetailHeaderButtons,
          requireData: (params) => StreamActionCreators.loadSource(params.sourceId),
          poll: {
            skipFirst: true,
            interval: SOURCE_POLL_INTERVAL,
            action: (params) => StreamActionCreators.loadSource(params.sourceId),
          },
        },
      ],
    },
    {
      name: routeNames.FILES,
      path: 'files',
      title: STORAGE_TITLE,
      breadcrumbHandler: DummyBreadcrumb,
      defaultRouteHandler: Files,
      headerButtonsHandler: UploadFileButton,
      poll: {
        skipFirst: true,
        action: (params, query) => loadFiles({ ...(query.q && { q: query.q }) }),
      },
      requireData: [
        (params, query) => {
          if (query.q || query.q === '') {
            updateFilesSearchQuery(query.q);

            return loadFiles({ q: query.q });
          }

          return loadFiles();
        },
      ],
    },
    {
      name: routeNames.BUCKET,
      path: ':bucketId(/tab/:bucketTab)',
      titleHandler: BucketTitle,
      nameEdit(params) {
        return <BucketNameEdit key={params.bucketId} bucketId={params.bucketId} />;
      },
      requireData: (params) => loadBucketDetail(params.bucketId),
      defaultRouteHandler: Bucket,
      headerButtonsHandler: CreateTableButton,
      labelHandler: BucketLabelsFromStore,
      childRoutes: [
        {
          name: routeNames.TABLE,
          path: ':tableName(/tab/:tableTab)',
          titleHandler: TableTitle,
          labelHandler: TableRouteLabels,
          nameEdit(params) {
            return (
              <TableNameEdit
                key={`${params.bucketId}.${params.tableName}`}
                bucketId={params.bucketId}
                tableName={params.tableName}
              />
            );
          },
          requireData: (params) => loadTableDetail(`${params.bucketId}.${params.tableName}`),
          defaultRouteHandler: Table,
        },
      ],
    },
  ],
};

export default routes;
