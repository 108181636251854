import React from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Badge, Tooltip } from '@keboola/design';

import { routeNames, tabs } from '@/modules/flows/constants';
import { TabLink, TabNav } from '@/react/common';
import string from '@/utils/string';

const NAV_TABS: {
  key: string;
  icon: IconProp;
  title: string;
}[] = [
  { key: tabs.BUILDER, icon: 'box', title: 'Builder' },
  { key: tabs.ALL_RUNS, icon: 'rotate', title: 'All Runs' },
  { key: tabs.SCHEDULES, icon: 'clock', title: 'Schedules' },
  { key: tabs.NOTIFICATIONS, icon: 'bell-on', title: 'Notifications' },
  { key: tabs.VERSIONS, icon: 'clone', title: 'Versions' },
];

const Tabs = (props: { configId: string; activeSchedules: number; activeTab?: string }) => {
  const activeTab = props.activeTab || tabs.BUILDER;

  return (
    <TabNav className="no-shrink pr-2">
      {NAV_TABS.map((tab) => (
        <TabLink
          key={tab.key}
          to={routeNames.DETAIL}
          active={activeTab === tab.key}
          params={{
            config: props.configId,
            tab: tab.key !== tabs.BUILDER ? tab.key : '',
          }}
        >
          <span className="tw-inline-flex tw-items-center">
            <FontAwesomeIcon icon={tab.icon} className="icon-addon-right" />
            {tab.title}
            {tab.key === tabs.SCHEDULES && props.activeSchedules > 0 && (
              <Tooltip
                placement="top"
                type="explanatory"
                tooltip={`${props.activeSchedules} Active ${string.pluralize(props.activeSchedules, 'Schedule')}`}
              >
                <Badge
                  asTag
                  variant="green-darker"
                  placement="right"
                  className="tw-min-w-5 tw-rounded-full tw-tracking-normal"
                >
                  {props.activeSchedules}
                </Badge>
              </Tooltip>
            )}
          </span>
        </TabLink>
      ))}
    </TabNav>
  );
};

export default Tabs;
