import { JOBS_LIMIT_FOR_GRAPH } from '@/modules/queue/constants';

const routeNames = {
  ROOT: 'orchestrations',
  ORCHESTRATION: 'orchestration',
  JOB: 'orchestrationJob',
  TASKS: 'orchestrationTasks',
  NOTIFICATIONS: 'orchestrationNotifications',
  VERSIONS: 'orchestrator-versions',
};

const ActionTypes = {
  ORCHESTRATIONS_LOAD: 'ORCHESTRATIONS_LOAD',
  ORCHESTRATIONS_LOAD_SUCCESS: 'ORCHESTRATIONS_LOAD_SUCCESS',
  ORCHESTRATIONS_LOAD_ERROR: 'ORCHESTRATIONS_LOAD_ERROR',

  ORCHESTRATIONS_LIST_SORT_BY_NAME: 'ORCHESTRATIONS_LIST_SORT_BY_NAME',

  ORCHESTRATION_JOBS_LOAD: 'ORCHESTRATION_JOBS_LOAD',
  ORCHESTRATION_JOBS_LOAD_SUCCESS: 'ORCHESTRATION_JOBS_LOAD_SUCCESS',
  ORCHESTRATION_JOBS_LOAD_ERROR: 'ORCHESTRATION_JOBS_LOAD_ERROR',

  ORCHESTRATION_LOAD: 'ORCHESTRATION_LOAD',
  ORCHESTRATION_CREATE_SUCCESS: 'ORCHESTRATION_CREATE_SUCCESS',

  ORCHESTRATION_DELETE_START: 'ORCHESTRATION_DELETE_START',
  ORCHESTRATION_DELETE_SUCCESS: 'ORCHESTRATION_DELETE_SUCCESS',
  ORCHESTRATION_DELETE_ERROR: 'ORCHESTRATION_DELETE_ERROR',

  ORCHESTRATION_LOAD_SUCCESS: 'ORCHESTRATION_LOAD_SUCCESS',
  ORCHESTRATION_LOAD_ERROR: 'ORCHESTRATION_LOAD_ERROR',

  ORCHESTRATION_ACTIVE_CHANGE_START: 'ORCHESTRATION_ACTIVE_CHANGE_START',
  ORCHESTRATION_ACTIVE_CHANGE_SUCCESS: 'ORCHESTRATION_ACTIVE_CHANGE_SUCCESS',
  ORCHESTRATION_ACTIVE_CHANGE_ERROR: 'ORCHESTRATION_ACTIVE_CHANGE_ERROR',

  ORCHESTRATION_TASKS_EDIT_START: 'ORCHESTRATION_TASKS_EDIT_START',
  ORCHESTRATION_TASKS_EDIT_CANCEL: 'ORCHESTRATION_TASKS_EDIT_CANCEL',
  ORCHESTRATION_TASKS_EDIT_UPDATE: 'ORCHESTRATION_TASKS_EDIT_UPDATE',

  ORCHESTRATION_TASKS_SAVE_START: 'ORCHESTRATION_TASKS_SAVE_START',
  ORCHESTRATION_TASKS_SAVE_SUCCESS: 'ORCHESTRATION_TASKS_SAVE_SUCCESS',
  ORCHESTRATION_TASKS_SAVE_ERROR: 'ORCHESTRATION_TASKS_SAVE_ERROR',

  ORCHESTRATION_FIELD_EDIT_START: 'ORCHESTRATION_FIELD_EDIT_START',
  ORCHESTRATION_FIELD_EDIT_CANCEL: 'ORCHESTRATION_FIELD_EDIT_CANCEL',
  ORCHESTRATION_FIELD_EDIT_UPDATE: 'ORCHESTRATION_FIELD_EDIT_UPDATE',

  ORCHESTRATION_FIELD_SAVE_START: 'ORCHESTRATION_FIELD_SAVE_START',
  ORCHESTRATION_FIELD_SAVE_SUCCESS: 'ORCHESTRATION_FIELD_SAVE_SUCCESS',
  ORCHESTRATION_FIELD_SAVE_ERROR: 'ORCHESTRATION_FIELD_SAVE_ERROR',

  ORCHESTRATION_JOB_LOAD: 'ORCHESTRATION_JOB_LOAD',
  ORCHESTRATION_JOB_LOAD_SUCCESS: 'ORCHESTRATION_JOB_LOAD_SUCCESS',
  ORCHESTRATION_JOB_LOAD_ERROR: 'ORCHESTRATION_JOB_LOAD_ERROR',

  ORCHESTRATION_JOB_TERMINATE_START: 'ORCHESTRATION_JOB_TERMINATE_START',
  ORCHESTRATION_JOB_TERMINATE_ERROR: 'ORCHESTRATION_JOB_TERMINATE_ERROR',
  ORCHESTRATION_JOB_TERMINATE_SUCCESS: 'ORCHESTRATION_JOB_TERMINATE_SUCCESS',

  ORCHESTRATION_JOB_RETRY_START: 'ORCHESTRATION_JOB_RETRY_START',
  ORCHESTRATION_JOB_RETRY_SUCCESS: 'ORCHESTRATION_JOB_RETRY_SUCCESS',
  ORCHESTRATION_JOB_RETRY_ERROR: 'ORCHESTRATION_JOB_RETRY_ERROR',

  ORCHESTRATION_JOB_RETRY_EDIT_START: 'ORCHESTRATION_JOB_RETRY_EDIT_START',
  ORCHESTRATION_JOB_RETRY_EDIT_UPDATE: 'ORCHESTRATION_JOB_RETRY_EDIT_UPDATE',
  ORCHESTRATION_JOB_RETRY_EDIT_SUCCESS: 'ORCHESTRATION_JOB_RETRY_EDIT_SUCCESS',
  ORCHESTRATION_JOB_RETRY_EDIT_CANCEL: 'ORCHESTRATION_JOB_RETRY_EDIT_CANCEL',
  ORCHESTRATION_JOB_RETRY_EDIT_ERROR: 'ORCHESTRATION_JOB_RETRY_EDIT_ERROR',

  ORCHESTRATION_RUN_TASK_EDIT_START: 'ORCHESTRATION_RUN_TASK_EDIT_START',
  ORCHESTRATION_RUN_TASK_EDIT_UPDATE: 'ORCHESTRATION_RUN_TASK_EDIT_UPDATE',
  ORCHESTRATION_RUN_TASK_EDIT_SUCCESS: 'ORCHESTRATION_RUN_TASK_EDIT_SUCCESS',
  ORCHESTRATION_RUN_TASK_EDIT_CANCEL: 'ORCHESTRATION_RUN_TASK_EDIT_CANCEL',
  ORCHESTRATION_RUN_TASK_EDIT_ERROR: 'ORCHESTRATION_RUN_TASK_EDIT_ERROR',
};

const ORCHESTRATIONS_LIST_SORT_BY_NAME_OPTIONS = {
  ASC: 'asc',
  DESC: 'desc',
};

const ORCHESTRATION_INVOKE_TYPE = {
  TIME: 'time',
  EVENT: 'event',
};

const LIMIT_OF_ORCHESTRATION_JOBS_TO_FETCH = {
  initial: JOBS_LIMIT_FOR_GRAPH, // initial load, load more, etc.
  reload: 10, // polling, reload, etc.
};

export {
  routeNames,
  ActionTypes,
  ORCHESTRATIONS_LIST_SORT_BY_NAME_OPTIONS,
  ORCHESTRATION_INVOKE_TYPE,
  LIMIT_OF_ORCHESTRATION_JOBS_TO_FETCH,
};
