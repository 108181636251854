import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '@keboola/design';

type Props = {
  isSorted: boolean;
  isSortedDesc: boolean;
  className?: string;
};

export const SortIcon = (props: Props) => {
  return (
    <FontAwesomeIcon
      className={cn('sort-icon icon-addon-right', props.className)}
      icon={['fad', props.isSorted ? (props.isSortedDesc ? 'sort-down' : 'sort-up') : 'sort']}
    />
  );
};
