import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import DropboxChooser from 'react-dropbox-chooser';
import type BluebirdPromise from 'bluebird';
import { first, isEmpty } from 'underscore';

import { Button, FormGroup, HelpBlock, Label, Tabs, TabsContent, TextInput } from '@keboola/design';

import { getDestinationName } from '@/modules/ex-dropbox-v2/actions/ApplicationActions';
import type { DropboxFile } from '@/modules/ex-dropbox-v2/Index';
import ConfirmButtons from '@/react/common/ConfirmButtons';

type Props = {
  onHide: () => void;
  show: boolean;
  canSaveConfig: boolean;
  saveConfig: (forceData?: DropboxFile[]) => BluebirdPromise<void>;
  cancelConfig: () => void;
  isSaving?: boolean;
  handleCsvSelectChange: (values: any) => void;
  selectedDropboxFiles: any[];
};

const DropboxFileSelectorModal = (props: Props) => {
  const [activeTab, setActiveTab] = useState('instant');
  const [name, setName] = useState('');
  const [link, setLink] = useState('');

  const handleSaveFunction = () => {
    if (activeTab === 'external') {
      const forceData = [
        {
          link,
          name,
          manualInsert: true,
        },
      ];
      return props.saveConfig(forceData).then(() => props.onHide());
    }
    return props.saveConfig().then(() => props.onHide());
  };

  const canSave = () => {
    if (activeTab === 'external') {
      return isEmpty(name) || isEmpty(link);
    }
    return props.canSaveConfig;
  };

  const onInsertLink = (value: string) => {
    setLink(value);
    setName((prevState) =>
      isEmpty(prevState) ? getDestinationName(value.split('?')[0]) : prevState,
    );
  };

  const renderManualInsert = () => {
    return (
      <div className="tw-flex tw-flex-col tw-gap-4">
        <FormGroup className="tw-grid tw-grid-cols-4 tw-gap-4">
          <Label htmlFor="link">Link</Label>
          <div className="tw-col-span-3 tw-flex tw-flex-col tw-gap-1">
            <TextInput
              variant="secondary"
              name="link"
              value={link}
              onChange={onInsertLink}
              autoFocus
            />
            <HelpBlock>Link to a CSV file shared by a Dropbox account</HelpBlock>
          </div>
        </FormGroup>

        <FormGroup className="tw-grid tw-grid-cols-4 tw-gap-4">
          <Label htmlFor="name">Name</Label>
          <div className="tw-col-span-3 tw-flex tw-flex-col tw-gap-1">
            <TextInput
              variant="secondary"
              name="name"
              value={name}
              onChange={(value) => setName(value)}
              autoFocus
            />
            <HelpBlock>Name of the CSV file and the output table</HelpBlock>
          </div>
        </FormGroup>
      </div>
    );
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton className="tw-border-0 !tw-pb-0">
        <Modal.Title>Dropbox File Selector</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          inModal
          value={activeTab}
          onValueChange={(tab: string) => setActiveTab(tab)}
          triggers={[
            {
              value: 'instant',
              title: 'Choose From Dropbox',
            },
            {
              value: 'external',
              title: 'Insert Link Manually',
            },
          ]}
        >
          <TabsContent value="instant">
            <p>
              Please choose a CSV file you want to extract via Dropbox Chooser. It uses a pop-up
              window; hence, disable pop-up blocking for this site in the browser settings.
            </p>
            <DropboxChooser
              appKey={'2is8jmvnwbchcyr'}
              cancel={() => {}}
              success={(files: unknown[] = []) => props.handleCsvSelectChange(files)}
              multiselect={false}
              extensions={['.csv']}
            >
              <Button variant="secondary" className="tw-w-full">
                Choose from Dropbox
              </Button>
            </DropboxChooser>
            {Array.isArray(props.selectedDropboxFiles) && props.selectedDropboxFiles.length > 0 && (
              <div>
                <br />
                <div>
                  <h4>Selected: {first(props.selectedDropboxFiles).name}</h4>
                </div>
              </div>
            )}
          </TabsContent>
          <TabsContent value="external">{renderManualInsert()}</TabsContent>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <ConfirmButtons
          block
          saveLabel="Create file"
          isSaving={props.isSaving}
          onSave={handleSaveFunction}
          isDisabled={canSave()}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default DropboxFileSelectorModal;
