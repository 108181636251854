import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import imgTransformationBucketEmptyImage from '../../../../../images/transformation-bucket-empty.png';

import NewTransformation from '@/modules/transformations/react/modals/NewTransformation';
import { fileUrl } from '@/utils/fileHelpers';

const EmptyStateBucket = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    bucket: PropTypes.object.isRequired,
  },

  render() {
    return (
      <div className="box-content">
        <div className="row">
          <span className="col-md-4 text-center">
            <img
              src={fileUrl(imgTransformationBucketEmptyImage)}
              alt="Empty transformation bucket"
            />
          </span>
          <span className="col-md-8">
            <h2>This transformation bucket is empty.</h2>
            {!this.props.readOnly && (
              <p className="add-button">
                <NewTransformation bucket={this.props.bucket} />
              </p>
            )}
            <p className="help">
              <strong>What is a transformation?</strong>
              <br />
              Transformations pick data from Storage, manipulate it and then store it back. They can
              be written in SQL (Snowflake), Python, R, Julia and OpenRefine.
            </p>
          </span>
        </div>
      </div>
    );
  },
});

export default EmptyStateBucket;
