import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';

import { SHARES_TYPES } from '@/constants';
import { isSharingAvailable } from '@/modules/admin/privileges';
import { ObjectTypes } from '@/modules/components/MetadataConstants';
import DeleteUnlinkBucketMenuItem from '@/modules/storage/components/DeleteUnlinkBucketMenuItem';
import DescriptionButton from '@/modules/storage/components/DescriptionButton';
import RefreshExternalBucketMenuItem from '@/modules/storage/components/RefreshExternalBucketMenuItem';
import {
  getBucketShareTooltipText,
  getLoadingActionName,
  isBucketShareDisabled,
} from '@/modules/storage/helpers';
import { RowActionDropdown, RowActionMenuItem } from '@/react/common';
import ActionLoader from '@/react/common/ActionLoader';
import { ShareBucketIcon } from '@/react/common/icons';
import type { OpenShareModal } from '@/types/types';

type Props = {
  bucket: Map<string, any>;
  sapiToken: Map<string, any>;
  isDeleting: boolean;
  isExporting: boolean;
  isCreatingSnapshot: boolean;
  openModal: (bucket: Map<string, any>) => void;
  openExportTablesModal: (tables: Map<string, any>[]) => void;
  openSnapshotModal: (tables: Map<string, any>[]) => void;
  openShareModal: OpenShareModal;
  canExportTable: boolean;
  canWriteBucket: boolean;
  canManageBucket: boolean;
};

export const BucketActions = ({
  bucket,
  sapiToken,
  isDeleting,
  isExporting,
  isCreatingSnapshot,
  openModal,
  openExportTablesModal,
  openSnapshotModal,
  openShareModal,
  canExportTable,
  canWriteBucket,
  canManageBucket,
}: Props) => {
  const bucketTables = bucket.get('bucketTables', List());
  const areBucketTablesEmpty = bucketTables.isEmpty();
  const hasAliasTables = bucketTables.some((table: Map<string, any>) => table.get('isAlias'));

  const isLoading = isDeleting || isExporting || isCreatingSnapshot;

  return isLoading ? (
    <ActionLoader
      loadingActionName={getLoadingActionName({
        isCreatingSnapshot,
        isExporting,
        isDeleting,
        tableOrBucket: 'bucket',
      })}
    />
  ) : (
    <RowActionDropdown>
      {canExportTable && (
        <RowActionMenuItem
          disabled={areBucketTablesEmpty}
          disabledReason="No tables in the bucket."
          onSelect={() => openExportTablesModal(bucketTables.toArray())}
        >
          <FontAwesomeIcon fixedWidth icon="down-to-line" />
          Export bucket
        </RowActionMenuItem>
      )}

      {canWriteBucket && (
        <RowActionMenuItem
          disabled={areBucketTablesEmpty || hasAliasTables}
          disabledReason={
            hasAliasTables
              ? 'Snapshot is not available for alias tables.'
              : 'No tables in the bucket.'
          }
          onSelect={() => openSnapshotModal(bucketTables.toArray())}
        >
          <FontAwesomeIcon fixedWidth icon="camera-viewfinder" />
          Create snapshot
        </RowActionMenuItem>
      )}

      {isSharingAvailable(sapiToken) && (
        <RowActionMenuItem
          disabled={isBucketShareDisabled(bucket)}
          disabledReason={getBucketShareTooltipText(bucket)}
          onSelect={() =>
            openShareModal({
              selectedBucket: bucket,
              shareModalStep: SHARES_TYPES.EXISTING_BUCKET,
            })
          }
        >
          <ShareBucketIcon />
          Share Bucket
        </RowActionMenuItem>
      )}
      <DescriptionButton data={bucket} entity={ObjectTypes.BUCKET} readOnly={!canManageBucket} />
      {canManageBucket && (
        <>
          <RefreshExternalBucketMenuItem bucket={bucket} />
          <RowActionMenuItem divider />
          <DeleteUnlinkBucketMenuItem
            bucket={bucket}
            token={sapiToken}
            onSelect={() => openModal(bucket)}
            linkedBucketsCount={bucket.get('linkedBy', Map()).count()}
            isLinked={bucket.has('sourceBucket')}
          />
        </>
      )}
    </RowActionDropdown>
  );
};
