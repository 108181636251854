import removeMarkdown from 'remove-markdown';

import string from './string';

export default (description, pruneLength = 75) => {
  if (!description) {
    return '';
  }

  return string
    .prune(
      removeMarkdown(string.truncate(description, 1000).replace(/\s\s+/g, ' ', '')),
      pruneLength,
    )
    .trim();
};
