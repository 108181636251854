import { Header } from '../../constants';
import {
  ApiError,
  type ApiResponse,
  createGenericFetchClient,
  defaultValidateStatus,
  type MiddlewareFn,
} from '../../fetchClient';

type TokenCreator = {
  id: number;
  name: string;
};

type User = {
  id: number;
  name: string;
  email: string;
  mfaEnabled: boolean;
  features: string[];
  canAccessLogs: boolean;
  isSuperAdmin: boolean;
};

type ManagementToken = {
  id: number;
  description: string;
  created: string;
  lastUsed: string;
  expires: string;
  isSessionToken: boolean;
  isExpired: boolean;
  isDisabled: boolean;
  scopes: string[];
  type: string;
  creator: TokenCreator;
  user: User;
};

type ManagementTokenWithStringToken = { token: string } & ManagementToken;

export class ManagementClientAuthError extends ApiError {
  title: string;
  message: string;
  constructor(title: string, message: string, res: ApiResponse) {
    super(res);
    this.title = title;
    this.message = message;
  }
}

export const isManagementClientAuthError = (error: unknown): error is ManagementClientAuthError =>
  error instanceof ManagementClientAuthError;

const createAuth = (baseUrl: string) => {
  const fetchClient = createGenericFetchClient({ baseUrl });

  const createCurrentUserSessionToken = async (accessToken: string) => {
    const headers = new Headers({ [Header.MANAGEMENT_API_TOKEN]: accessToken });
    const { data } = await fetchClient.post<ManagementTokenWithStringToken>(
      '/manage/current-user/session-token',
      {},
      { headers },
    );
    return data.token;
  };

  const createAdminAccountSessionToken = async () => {
    const { data } = await fetchClient.post<string>(
      '/admin/account/session-token',
      {},
      {
        // transform valid response, to invalid and throw custom error
        validateStatus: (apiResponse) => {
          const { response } = apiResponse;

          // If the response is "302 Found", then response type is "text/html", because backend detected
          // that session expired and redirected user to login screen
          const isTextHtmlType =
            response.headers.has(Header.CONTENT_TYPE) &&
            response.headers.get(Header.CONTENT_TYPE)!.includes('text/html');

          if (isTextHtmlType)
            return new ManagementClientAuthError(
              'Your session expired',
              'You will be logged out automatically.',
              apiResponse,
            );

          return defaultValidateStatus(apiResponse);
        },
      },
    );
    return data;
  };

  const isTokenValid = async (accessToken: string) => {
    try {
      const headers = new Headers({ [Header.MANAGEMENT_API_TOKEN]: accessToken });
      await fetchClient.get<ManagementToken>('/manage/tokens/verify', {}, { headers });
      return true;
    } catch {
      return false;
    }
  };

  return {
    isTokenValid,
    createCurrentUserSessionToken,
    createAdminAccountSessionToken,
  };
};

export const createAuthMiddleware = ({
  accessToken,
  baseUrl,
}: {
  baseUrl: string;
  accessToken?: string;
}): MiddlewareFn => {
  const auth = createAuth(baseUrl);
  let token: string | undefined = accessToken;
  // eslint-disable-next-line turbo/no-undeclared-env-vars
  const isDevelopment = process.env.NODE_ENV === 'development';

  return async (request, next) => {
    if (!!token) {
      const isValid = await auth.isTokenValid(token);
      if (!isValid) {
        // in dev env we use manage token with type "admin" to create new manage token with type "session"
        // in production we create manage token with type "session" directly, since we are authorized
        // using PHP session
        token = isDevelopment
          ? await auth.createCurrentUserSessionToken(token)
          : await auth.createAdminAccountSessionToken();
      }
    } else {
      token = await auth.createAdminAccountSessionToken();
    }

    request.request.headers.set(Header.MANAGEMENT_API_TOKEN, token);
    return next(request);
  };
};
