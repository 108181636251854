import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '@keboola/design';

import { canPurgeTrash } from '@/modules/admin/privileges';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { useDeleteAllComponent } from '@/modules/trash/rqHooks/useDeleteAllComponents';
import { useDeletedComponents } from '@/modules/trash/rqHooks/useDeletedComponents';
import Confirm from '@/react/common/Confirm';
import Loader from '@/react/common/Loader';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

const TrashHeaderButtons = () => {
  const deletedComponents = useDeletedComponents();

  const { isDevModeActive, sapiToken } = useStores(
    () => ({
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      sapiToken: ApplicationStore.getSapiToken(),
    }),
    [],
    [DevBranchesStore, ApplicationStore],
  );

  const deleteAllComponents = useDeleteAllComponent();

  const hasDeletedComponents = deletedComponents.data && deletedComponents.data.length > 0;

  if (!canPurgeTrash(sapiToken) || !hasDeletedComponents || isDevModeActive) {
    return null;
  }

  return (
    <Confirm
      icon="trash"
      title="Empty Trash"
      text="Are you sure you want to permanently delete all configurations in Trash?"
      buttonLabel="Empty Trash"
      onConfirm={() => {
        return deleteAllComponents.mutateAsync({
          components: deletedComponents.data,
        });
      }}
    >
      <Button variant="danger" disabled={deleteAllComponents.isPending}>
        {deleteAllComponents.isPending ? (
          <>
            <Loader /> Deleting configurations...
          </>
        ) : (
          <>
            <FontAwesomeIcon icon="trash" />
            Empty Trash
          </>
        )}
      </Button>
    </Confirm>
  );
};

export default TrashHeaderButtons;
