import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn, Tooltip } from '@keboola/design';

import FullScreenModal, { FullScreenModalHeader } from './FullScreenModal';

class FullScreenEditor extends React.Component {
  componentDidMount() {
    this.props.onEnter?.();
  }

  render() {
    return (
      <FullScreenModal
        className={cn('full-screen-editor', this.props.className)}
        onHide={() => {
          if (!this.props.disableEscClose && !this.props.closeDisabledReason) {
            this.props.onClose();
          }
        }}
      >
        <FullScreenModalHeader
          title={this.props.renderTitle()}
          isTitleWithIcon
          onClose={this.props.onClose}
        >
          <ButtonToolbar>
            {this.props.renderButtons && this.props.renderButtons()}
            {this.props.renderCloseButton
              ? this.props.renderCloseButton()
              : this.renderCloseButton()}
          </ButtonToolbar>
        </FullScreenModalHeader>

        <Modal.Body>{this.props.renderEditor()}</Modal.Body>
      </FullScreenModal>
    );
  }

  renderCloseButton() {
    if (this.props.closeDisabledReason) {
      return (
        <Tooltip placement="bottom" tooltip={this.props.closeDisabledReason} type="explanatory">
          <Button className="disabled">
            <FontAwesomeIcon icon="xmark" />
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button onClick={this.props.onClose}>
        <FontAwesomeIcon icon="xmark" />
      </Button>
    );
  }
}

FullScreenEditor.propTypes = {
  renderTitle: PropTypes.func.isRequired,
  renderEditor: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  renderButtons: PropTypes.func,
  renderCloseButton: PropTypes.func,
  closeDisabledReason: PropTypes.string,
  className: PropTypes.string,
  disableEscClose: PropTypes.bool,
};

FullScreenEditor.defaultProps = {
  disableEscClose: false,
};

export default FullScreenEditor;
