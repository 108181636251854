import React, { Component } from 'react';
import type { Options, SortableEvent } from 'sortablejs';
import SortableJS from 'sortablejs';

const store: {
  nextSibling: Element | null;
  activeComponent: Sortable | null;
} = {
  nextSibling: null,
  activeComponent: null,
};

type OnHandlers = keyof Pick<Options, 'onChoose' | 'onAdd' | 'onUpdate' | 'onStart' | 'onEnd'>;

const onHandlers: OnHandlers[] = ['onChoose', 'onStart', 'onEnd', 'onAdd', 'onUpdate'];

type Props = {
  children: React.ReactNode;
  onChange: (items: string[], evt: SortableEvent) => void;
  tag?: React.ElementType;
  options?: Options;
  className?: string;
  style?: any;
};

class Sortable extends Component<Props> {
  sortable: SortableJS | null = null;
  sortableRef = React.createRef<HTMLElement>();

  componentDidMount() {
    const options: Options = {
      handle: '.drag-handle',
      forceFallback: true,
      animation: 200,
      ...this.props.options,
    };

    onHandlers.forEach((name) => {
      const eventHandler = options[name];

      options[name] = (...params: [SortableEvent]) => {
        const [evt] = params;

        if (name === 'onChoose') {
          store.nextSibling = evt.item.nextElementSibling;
          store.activeComponent = this;
        } else if (name === 'onAdd' || name === 'onUpdate') {
          const remote = store.activeComponent;
          const items = this.sortable?.toArray() ?? [];
          const remoteItems = remote?.sortable?.toArray() ?? [];

          const referenceNode =
            store.nextSibling && store.nextSibling.parentNode !== null ? store.nextSibling : null;
          evt.from.insertBefore(evt.item, referenceNode);
          if (remote !== this) {
            const remoteOptions = remote?.props?.options ?? {};

            if (typeof remoteOptions.group === 'object' && remoteOptions.group.pull === 'clone') {
              evt.item.parentNode?.removeChild(evt.item);
            }

            if (remote?.props.onChange) {
              remote.props.onChange(remoteItems, evt);
            }
          }

          this.props.onChange(items, evt);
        }

        if (eventHandler) {
          setTimeout(() => eventHandler(evt), 0);
        }
      };
    });

    if (this.sortableRef.current) {
      this.sortable = SortableJS.create(this.sortableRef.current, options);
    }
  }

  componentWillUnmount() {
    if (this.sortable) {
      this.sortable.destroy();
      this.sortable = null;
    }
  }

  render() {
    const Component = this.props.tag || 'div';

    return (
      <Component style={this.props.style} className={this.props.className} ref={this.sortableRef}>
        {this.props.children}
      </Component>
    );
  }
}

export default Sortable;
