import React from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '@keboola/design';

import { useSHA256 } from '@/utils/crypto';

const backgroundColors: {
  [key: string]: string;
} = {
  0: '#38CE8F',
  1: '#FBBC05',
  2: '#925cff',
  3: '#FF6A3C',
  4: '#4CCBFF',
  5: '#07BE07',
  6: '#FF5B50',
  7: '#3B5998',
  8: '#38CE8F',
  9: '#FBBC05',
  a: '#925cff',
  b: '#FF6A3C',
  c: '#4CCBFF',
  d: '#07BE07',
  e: '#FF5B50',
  f: '#3B5998',
};

export type IconColor =
  | 'blue'
  | 'green'
  | 'light-green'
  | 'red'
  | 'violet'
  | 'yellow'
  | 'gray'
  | 'orange';

type Props = {
  icon?: IconProp;
  iconUrl?: string;
  color?: IconColor;
  generatedBackground?: string;
  className?: string;
  bold?: boolean;
  bigger?: boolean;
  smaller?: boolean;
};

export const CircleIcon = ({
  icon,
  iconUrl,
  className,
  color = 'blue',
  generatedBackground,
  bold = false,
  bigger = false,
  smaller = false,
}: Props) => {
  let styles = {};
  const hash = useSHA256(generatedBackground);

  if (generatedBackground) {
    const firstCharacter = hash.substring(0, 1).toLowerCase();

    styles = {
      color: '#fff',
      background: firstCharacter in backgroundColors && backgroundColors[firstCharacter],
    };
  }

  return (
    <span
      className={cn(
        'circle-icon',
        className,
        { [color]: !iconUrl },
        { bold },
        { 'bigger-icon': bigger },
        { 'smaller-icon': smaller },
      )}
      style={styles}
    >
      {iconUrl ? (
        <img src={iconUrl} width="100%" height="100%" loading="lazy" alt="Circle icon" />
      ) : (
        icon && <FontAwesomeIcon icon={icon} />
      )}
    </span>
  );
};
