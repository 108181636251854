import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { Alert } from '@keboola/design';

import { STAGE } from '@/constants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { backends } from '@/modules/storage/constants';
import { prepareBackendLabel, validateBucketName } from '@/modules/storage/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import { isValidName } from '@/react/common/helpers';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import PredefinedInput from './PredefinedInput';

const INITIAL_STATE = {
  displayName: '',
  stage: 'in',
  backend: '',
  error: null,
  warning: null,
};

const CreateBucketModal = createReactClass({
  propTypes: {
    sapiToken: PropTypes.object.isRequired,
    openModal: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    buckets: PropTypes.object.isRequired,
  },

  getInitialState() {
    return INITIAL_STATE;
  },

  render() {
    const backendOptions = this.getBackendOptions();

    return (
      <Modal show={this.props.openModal} onHide={this.onHide}>
        <form onSubmit={this.onSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create Bucket</Modal.Title>
            <ModalIcon icon="folder" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            {this.renderError()}
            <PredefinedInput
              autoFocus
              entity="bucketName"
              value={this.state.displayName}
              warning={this.state.warning}
              onChange={this.handleDisplayName}
            />
            <FormGroup>
              <ControlLabel>Stage</ControlLabel>
              <Select
                clearable={false}
                placeholder="Select stage..."
                onChange={this.handleStage}
                options={Object.values(STAGE).map((stage) => ({
                  value: stage,
                  label: stage.toUpperCase(),
                }))}
                value={this.state.stage}
              />
            </FormGroup>
            {backendOptions.length > 1 && (
              <FormGroup>
                <ControlLabel>Backend</ControlLabel>
                <Select
                  clearable={false}
                  placeholder="Select backend..."
                  onChange={this.handleBackend}
                  value={this.state.backend || this.getDefaultBackend()}
                  options={backendOptions.map((backend) => ({
                    value: backend,
                    label: prepareBackendLabel(backend),
                  }))}
                />
              </FormGroup>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isSaving={this.props.isSaving}
              isDisabled={this.isDisabled()}
              saveLabel={this.props.isSaving ? 'Creating bucket...' : 'Create bucket'}
              saveButtonType="submit"
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  },

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return (
      <Alert variant="error" className="tw-mb-5">
        {this.state.error}
      </Alert>
    );
  },

  getBackendOptions() {
    const options = [];

    if (this.props.sapiToken.getIn(['owner', 'hasRedshift'], false)) {
      options.push(backends.REDSHIFT);
    }

    if (this.props.sapiToken.getIn(['owner', 'hasSnowflake'], false)) {
      options.push(backends.SNOWFLAKE);
    }

    if (this.props.sapiToken.getIn(['owner', 'hasSynapse'], false)) {
      options.push(backends.SYNAPSE);
    }

    if (this.props.sapiToken.getIn(['owner', 'hasExasol'], false)) {
      options.push(backends.EXASOL);
    }

    if (this.props.sapiToken.getIn(['owner', 'hasTeradata'], false)) {
      options.push(backends.TERADATA);
    }

    if (this.props.sapiToken.getIn(['owner', 'hasBigquery'], false)) {
      options.push(backends.BIGQUERY);
    }

    return options;
  },

  getDefaultBackend() {
    return this.props.sapiToken.getIn(['owner', 'defaultBackend']);
  },

  handleDisplayName(displayName) {
    this.setState({ displayName }, this.validateDisplayName);
  },

  handleStage(value) {
    this.setState({ stage: value }, this.validateDisplayName);
  },

  handleBackend(backend) {
    this.setState({ backend });
  },

  onHide() {
    this.props.onHide();
    this.resetState();
  },

  onSubmit(event) {
    event.preventDefault();
    const newBucket = {
      name: this.state.displayName,
      displayName: this.state.displayName,
      stage: this.state.stage,
      backend: this.state.backend || this.getDefaultBackend(),
    };

    this.props.onSubmit(newBucket).then(this.onHide, (message) => {
      this.setState({
        error: message,
      });
    });
  },

  resetState() {
    this.setState(INITIAL_STATE);
  },

  validateDisplayName() {
    this.setState({
      warning: validateBucketName(
        this.state.displayName,
        this.state.stage,
        this.props.buckets,
        DevBranchesStore.getCurrentId(),
      ),
    });
  },

  isDisabled() {
    const backend = this.state.backend || this.getDefaultBackend();

    if (
      !this.state.displayName ||
      !isValidName(this.state.displayName) ||
      !this.state.stage ||
      !backend ||
      this.state.warning
    ) {
      return true;
    }

    return this.props.isSaving;
  },
});

export default CreateBucketModal;
