import React from 'react';
import { Map } from 'immutable';

import { URLS } from '@keboola/constants';
import { Link } from '@keboola/design';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { getClipboardSecret } from '@/modules/data-apps/helpers';
import EncryptedValue from '@/react/common/EncryptedValue';
import Filled from '@/react/common/KeyValueBox/Filled';
import KeyValueBox from '@/react/common/KeyValueBox/KeyValueBox';
import string from '@/utils/string';
import SecretForm from './SecretForm';

type Props = {
  configId: string;
  componentId: string;
  configData: Map<string, any>;
  secrets: Map<string, string>;
  readOnly: boolean;
};

class SecretsBox extends React.Component<Props> {
  state = {
    isSavingNew: false,
    isAddingNew: false,
    saving: Map(),
    deleting: Map(),
    editing: Map(),
  };

  render() {
    return (
      <KeyValueBox
        entity="Secret"
        isReadOnly={this.props.readOnly}
        isDisabled={this.state.isAddingNew}
        onAddNew={() => this.setState({ isAddingNew: true })}
        helperText={
          <>
            Manage sensitive secrets, like database credentials, in your Streamlit app with optional
            encryption for added security. For detailed information, please refer to the{' '}
            <Link href={`${URLS.USER_DOCUMENTATION}/components/data-apps/#secrets`}>
              documentation
            </Link>
            .
          </>
        }
      >
        {this.renderSecrets()}
      </KeyValueBox>
    );
  }

  renderSecrets() {
    if (this.props.secrets.count() === 0 && !this.state.isAddingNew) {
      return null;
    }

    return (
      <>
        {this.props.secrets
          .map((value, name) => {
            if (!name) {
              return null;
            }

            if (this.state.editing.has(name)) {
              return (
                <SecretForm
                  key={`edit-${name}`}
                  onSave={(newName: string, value: string) => {
                    return this.handleUpdate(name, newName, value);
                  }}
                  onReset={() => {
                    this.setState({ editing: this.state.editing.delete(name) });
                  }}
                  name={name}
                  value={value}
                  isSaving={this.state.saving.has(name)}
                  secrets={this.props.secrets}
                  readOnly={this.props.readOnly}
                />
              );
            }

            const trimmedName = string.ltrim(name, '#');
            return (
              <Filled
                key={`view-${name}`}
                readOnly={this.props.readOnly}
                name={trimmedName}
                value={name.startsWith('#') ? <EncryptedValue /> : (value ?? '')}
                deleteVariableFn={() => this.handleDelete(name)}
                startEditingFn={() => {
                  this.setState({ editing: this.state.editing.set(name, true) });
                }}
                isDeleting={this.state.deleting.has(name)}
                clipboardText={getClipboardSecret(trimmedName)}
                entity="secret"
              />
            );
          })
          .toArray()}
        {this.state.isAddingNew && (
          <SecretForm
            onSave={this.handleAdd}
            onReset={() => this.setState({ isAddingNew: false })}
            isSaving={this.state.isSavingNew}
            secrets={this.props.secrets}
            readOnly={this.props.readOnly}
          />
        )}
      </>
    );
  }

  handleUpdate = (oldName: string, name: string, value: string) => {
    this.setState({ saving: this.state.saving.set(oldName, true) });
    return this.saveConfigData(
      this.props.configData
        .deleteIn(['parameters', 'dataApp', 'secrets', oldName])
        .setIn(['parameters', 'dataApp', 'secrets', name], value),
      'Update secret',
    ).finally(() => this.setState({ saving: this.state.saving.delete(oldName) }));
  };

  handleAdd = (name: string, value: string) => {
    this.setState({ isSavingNew: true });
    return this.saveConfigData(
      this.props.configData.updateIn(['parameters', 'dataApp', 'secrets'], Map(), (secrets) => {
        return secrets.set(name, value);
      }),
      'Add new secret',
    ).finally(() => this.setState({ isSavingNew: false }));
  };

  handleDelete = (name: string) => {
    this.setState({ deleting: this.state.deleting.set(name, true) });
    return this.saveConfigData(
      this.props.configData
        .deleteIn(['parameters', 'dataApp', 'secrets', name])
        .updateIn(['parameters', 'dataApp'], (dataApp) => {
          if (dataApp.get('secrets', Map()).isEmpty()) {
            return dataApp.delete('secrets');
          }

          return dataApp;
        }),
      'Delete secret',
    ).finally(() => this.setState({ deleting: this.state.deleting.delete(name) }));
  };

  saveConfigData = (configData: Map<string, any>, changeDescription: string) => {
    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.props.componentId,
      this.props.configId,
      configData,
      changeDescription,
    );
  };
}

export default SecretsBox;
