import React from 'react';

import { byteConverter } from './helpers';

export const FileSize = ({ size, precision }: { size?: number | null; precision?: number }) => {
  if (!size && size !== 0) {
    return <span>N/A</span>;
  }

  return <span className="no-wrap">{byteConverter(size, precision)}</span>;
};
