import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import type Promise from 'bluebird';
import { List, Map } from 'immutable';

import { cn, HelpBlock } from '@keboola/design';

import type { ACTIVE_MENU } from '@/modules/ex-generic/constants';
import { AUTH_METHODS, RESERVED_CUSTOM_NAMES } from '@/modules/ex-generic/constants';
import { prepareUserParameters, transformMapToKeyValueList } from '@/modules/ex-generic/helpers';
import CodeEditor from '@/react/common/CodeEditor';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import EncryptedPropertiesHelpBlock from '@/react/common/EncryptedPropertiesHelpBlock';
import PasswordControl from '@/react/common/PasswordControl';
import Select from '@/react/common/Select';
import DocumentationLink from './DocumentationLink';
import GenericPairs from './GenericPairs';

const CUSTOM_AUTH_TEMPLATE = `{
  "type": "",
  "format": "",
  "expires": "",
  "query": {},
  "headers": {},
  "loginRequest": {},
  "apiRequest": {}
}`;

const AUTH_METHODS_OPTIONS = [
  { label: 'None', value: AUTH_METHODS.NONE },
  { label: 'Basic Authentication', value: AUTH_METHODS.BASIC },
  { label: 'API Key Auth', value: AUTH_METHODS.API_KEY },
  { label: 'Bearer Token', value: AUTH_METHODS.BEARER },
  { label: 'OAuth2.0 Client Credentials', value: AUTH_METHODS.OAUTH2 },
  { label: 'Query Authentication', value: AUTH_METHODS.QUERY },
  { label: 'Custom', value: AUTH_METHODS.CUSTOM },
];

const ADD_TO_OPTIONS = [
  { label: 'Header', value: 'header' },
  { label: 'Query params', value: 'query' },
];

const LOGIN_REQUEST_TYPES = [
  { label: 'Basic Auth', value: 'basic' },
  { label: 'Post Form', value: 'post' },
  { label: 'Post JSON', value: 'json' },
];

const Authentication = (props: {
  readOnly: boolean;
  editing: Map<string, any>;
  parameters: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
  onSave: (
    parameters: Map<string, any>,
    changeDescription: string,
    newMenu?: ACTIVE_MENU,
  ) => Promise<any>;
}) => {
  const method = props.editing.get('method');

  const renderControl = (label: string, property: string, type?: 'password') => {
    const commonProps = {
      disabled: props.readOnly,
      value: props.editing.get(property, ''),
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        props.setEditing(props.editing.set(property, e.target.value));
      },
    };

    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        {type === 'password' ? (
          <PasswordControl {...commonProps} />
        ) : (
          <FormControl type="text" {...commonProps} />
        )}
      </FormGroup>
    );
  };

  const renderAdditionalInputs = () => {
    if (method === AUTH_METHODS.BASIC) {
      return (
        <>
          {renderControl('Username', 'username')}
          {renderControl('Password', 'password', 'password')}
        </>
      );
    }

    if (method === AUTH_METHODS.API_KEY) {
      return (
        <>
          {renderControl('Key', 'key')}
          {renderControl('Token', 'token', 'password')}
          <FormGroup>
            <ControlLabel>Add to</ControlLabel>
            <Select
              clearable={false}
              disabled={props.readOnly}
              value={props.editing.get('add_to', 'header')}
              onChange={(value: string) => {
                props.setEditing(props.editing.set('add_to', value));
              }}
              options={ADD_TO_OPTIONS}
            />
          </FormGroup>
        </>
      );
    }

    if (method === AUTH_METHODS.BEARER) {
      return renderControl('Token', 'token', 'password');
    }

    if (method === AUTH_METHODS.OAUTH2) {
      return (
        <>
          <FormGroup>
            <ControlLabel>Login Request Type</ControlLabel>
            <Select
              clearable={false}
              disabled={props.readOnly}
              value={props.editing.get('login_type', 'basic')}
              onChange={(value: string) => {
                props.setEditing(props.editing.set('login_type', value));
              }}
              options={LOGIN_REQUEST_TYPES}
            />
            <HelpBlock className="tw-mt-1">
              Send the OAuth credentials as Basic Auth or POST with the xxx-form-url-encoded or JSON
              body.
            </HelpBlock>
          </FormGroup>
          {renderControl('Client ID', 'client_id')}
          {renderControl('Client Secret', 'client_secret', 'password')}
          {renderControl('Access Token URL', 'endpoint')}
          <FormGroup>
            <ControlLabel>Scopes</ControlLabel>
            <Select
              multi
              allowCreate
              disabled={props.readOnly}
              placeholder="Enter scopes"
              value={props.editing.get('scopes')}
              onChange={(value: List<string>) => {
                props.setEditing(props.editing.set('scopes', value));
              }}
              promptTextCreator={(label) => label}
              newOptionCreator={(option) => {
                return { label: `Set scope "${option}"`, value: option };
              }}
              inputValueSanitizer={(input: string) => input.trim().replace(/\s+/g, '-')}
            />
          </FormGroup>
        </>
      );
    }

    if (method === AUTH_METHODS.QUERY) {
      const query = props.editing.get('query', Map());
      const rows = List.isList(query) ? query : transformMapToKeyValueList(query);

      return (
        <GenericPairs
          entity="Query"
          rows={rows}
          onSave={props.onSave}
          readOnly={props.readOnly}
          parameters={props.parameters}
          onChange={(query) => props.setEditing(props.editing.set('query', query))}
          userParameters={prepareUserParameters(props.parameters)}
        />
      );
    }

    if (method === AUTH_METHODS.CUSTOM) {
      return (
        <FormGroup>
          <ControlLabel>Authentication</ControlLabel>
          <CodeEditor
            value={props.editing.get('authentication', '')}
            onChange={(value: string) => {
              props.setEditing(props.editing.set('authentication', value));
            }}
            options={{ readOnly: props.readOnly }}
          />
          <EncryptedPropertiesHelpBlock />
        </FormGroup>
      );
    }

    return null;
  };

  return (
    <CollapsibleBox
      title="Authentication"
      defaultOpen={!props.parameters.hasIn(['config', RESERVED_CUSTOM_NAMES.AUTH_METHOD])}
      titleSuffix={<DocumentationLink path="configuration/api/authentication/" />}
      collapsePrefix={
        <span
          className={cn(
            'tw-mr-2 tw-text-xs tw-font-medium',
            method !== AUTH_METHODS.NONE ? 'tw-text-primary-600' : 'tw-text-warning-600',
          )}
        >
          {AUTH_METHODS_OPTIONS.find((option) => option.value === method)?.label || 'None'}
        </span>
      }
    >
      <FormGroup>
        <ControlLabel>Method</ControlLabel>
        <Select
          clearable={false}
          disabled={props.readOnly}
          placeholder="Select method"
          value={method}
          onChange={(value: string) => {
            const newEditing = props.editing.withMutations((editing) => {
              editing.set('method', value);

              if (value === AUTH_METHODS.CUSTOM) {
                editing.set('authentication', CUSTOM_AUTH_TEMPLATE);
              }
            });

            props.setEditing(newEditing);
          }}
          options={AUTH_METHODS_OPTIONS}
        />
        <HelpBlock className="tw-mt-1">
          Select one of the predefined methods or use custom JSON to define your{' '}
          <code>authentication</code> section.{' '}
          <DocumentationLink format="text" path="configuration/api/authentication/" />
        </HelpBlock>
      </FormGroup>
      {renderAdditionalInputs()}
    </CollapsibleBox>
  );
};

export default Authentication;
