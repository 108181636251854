import React from 'react';
import type { ReactNode } from 'react';

import { cn, Collapse } from '@keboola/design';

import CollapseButton from './CollapseButton';
import InfoTooltip from './InfoTooltip';

export type Props = {
  title: ReactNode;
  isDisabled?: boolean;
  entity?: string;
  defaultOpen?: boolean;
  titleSuffix?: React.ReactNode;
  collapsePrefix?: React.ReactNode;
  additionalActions?: (isOpen?: boolean, toggleOpen?: () => void) => React.ReactNode;
  headerClassName?: string;
  children?: React.ReactNode;
  hint?: React.ReactNode;
  hideSuffixIfCollapsed?: boolean;
  childrenClassName?: string;
  showAdditionalActionsWhenDisabled?: boolean;
  isOpen?: boolean;
  onToggle?: () => void;
};

const CollapsibleBox = ({
  title,
  isDisabled = false,
  entity,
  defaultOpen = false,
  titleSuffix,
  collapsePrefix,
  additionalActions,
  headerClassName,
  children,
  hint,
  hideSuffixIfCollapsed = false,
  childrenClassName,
  showAdditionalActionsWhenDisabled = false,
  isOpen,
  onToggle,
}: Props) => {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(defaultOpen);

  const handleCollapse = () => {
    if (!isDisabled) {
      setUncontrolledOpen(!open);
    }
  };

  const open = isOpen ?? uncontrolledOpen;

  return (
    <Collapse
      open={open}
      className={cn('box box-collapsible', {
        disabled: isDisabled,
      })}
      header={
        <div
          className={cn('box-header big-padding btn-collapse-area', headerClassName)}
          onClick={onToggle ?? handleCollapse}
        >
          <h2 className="box-title flex-container flex-start">
            <span className="color-main">{title}</span>
            {hint && <InfoTooltip tooltip={hint} />}
            {(!hideSuffixIfCollapsed || open) && titleSuffix}
          </h2>
          <div className="flex-container">
            {!open && collapsePrefix}
            {(showAdditionalActionsWhenDisabled || !isDisabled) && !!additionalActions && (
              <span className="addons" onClick={(event) => event.stopPropagation()}>
                {additionalActions(open, handleCollapse)}
              </span>
            )}
            <CollapseButton
              entity={entity || 'setting'}
              isCollapsed={isDisabled || !open}
              onToggle={handleCollapse}
              isDisabled={isDisabled}
            />
          </div>
        </div>
      }
    >
      <div className={cn('box-content pt-0', childrenClassName)}>{children}</div>
    </Collapse>
  );
};

export default CollapsibleBox;
