import React, { useId } from 'react';
import type { ReactNode } from 'react';

import { GraphContext } from './GraphContext';

export const GraphContextProvider = (props: {
  children: ReactNode;
  mainNodeId?: string | null;
  zoomOnHover?: boolean;
}) => {
  const graphId = useId();
  const contextValue = React.useMemo(() => {
    return {
      mainNodeId: props.mainNodeId ?? null,
      graphId,
      zoomOnHover: props.zoomOnHover ?? false,
    };
  }, [graphId, props.mainNodeId, props.zoomOnHover]);

  return <GraphContext.Provider value={contextValue}>{props.children}</GraphContext.Provider>;
};
