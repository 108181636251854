import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { KEBOOLA_EX_SAMPLE_DATA } from '@/constants/componentIds';
import PhaseRow from './PhaseRow';
import TasksTableRow from './TasksTableRow';

const TasksTable = createReactClass({
  propTypes: {
    tasks: PropTypes.object.isRequired,
    orchestration: PropTypes.object.isRequired,
    components: PropTypes.object.isRequired,
    onRun: PropTypes.func.isRequired,
    updateLocalState: PropTypes.func.isRequired,
    localState: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,
  },

  _handleTaskRun(task) {
    return this.props.onRun(task);
  },

  render() {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Component</th>
            <th>Configuration</th>
            <th>Action</th>
            <th>Active</th>
            <th>Continue on Failure</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {this.props.tasks.count() ? (
            this.renderPhasedTasksRows()
          ) : (
            <tr>
              <td colSpan="6" className="text-muted">
                There are no tasks assigned yet.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  },

  renderPhasedTasksRows() {
    let result = List();

    this.props.tasks.map((phase) => {
      const tasksRows = phase.get('tasks').map((task) => {
        return (
          <TasksTableRow
            key={task.get('id')}
            task={task}
            orchestration={this.props.orchestration}
            component={this.props.components.get(
              task.get('component') === KEBOOLA_EX_SAMPLE_DATA
                ? task.getIn(['config', 'configuration', 'parameters', 'componentId'])
                : task.get('component'),
            )}
            onRun={this._handleTaskRun}
            readOnly={this.props.readOnly}
          />
        );
      });

      const isPhaseHidden = this.isPhaseHidden(phase);
      const phaseRow = this.renderPhaseRow(phase, isPhaseHidden);
      result = result.push(phaseRow);

      if (!isPhaseHidden) {
        result = result.concat(tasksRows);
      }
    });

    return result.toArray();
  },

  renderPhaseRow(phase, isPhaseHidden) {
    const phaseId = phase.get('id');

    return (
      <PhaseRow
        isPhaseHidden={isPhaseHidden}
        key={phaseId}
        phase={phase}
        toggleHide={() => {
          return this.props.updateLocalState(['phases', phaseId, 'isHidden'], !isPhaseHidden);
        }}
      />
    );
  },

  isPhaseHidden(phase) {
    return this.props.localState.getIn(['phases', phase.get('id'), 'isHidden'], false);
  },
});

export default TasksTable;
