import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { Map } from 'immutable';

import { URLS } from '@keboola/constants';

import { KEBOOLA_EX_HTTP } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ConfigurationRowsActionCreators, {
  createSimpleRow,
} from '@/modules/configurations/ConfigurationRowsActionCreators';
import { createConfiguration } from '@/modules/ex-http/adapters/row';
import { runComponent } from '@/modules/simplified-ui/actions';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import nextTick from '@/utils/nextTick';
import string from '@/utils/string';
import Help from './Help';

class HttpExSetup extends React.Component {
  static propTypes = {
    config: PropTypes.instanceOf(Map).isRequired,
    readOnly: PropTypes.bool.isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
  };

  state = {
    url: this.getSavedUrl(),
    isSaving: false,
  };

  render() {
    return (
      <div className="flex-container align-top">
        <div className="fill-space pr-2">
          <form className="form-horizontal" onSubmit={this.handleSubmit}>
            <FormGroup>
              <div className="col-xs-3">
                <ControlLabel>File URL</ControlLabel>
              </div>
              <div className="col-xs-9">
                <FormControl
                  value={this.state.url}
                  onChange={({ target }) =>
                    this.setState({
                      url: target.value,
                    })
                  }
                  disabled={this.props.readOnly}
                />
              </div>
            </FormGroup>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel="Save and Run Configuration"
              isSaving={this.state.isSaving}
              isDisabled={this.isSaveDisabled()}
            />
          </form>
        </div>
        {this.renderHelp()}
      </div>
    );
  }
  renderHelp() {
    if (!this.state.isSaving && this.isSaveDisabled()) {
      return (
        <Help
          title="Enter your CSV file URL"
          text="If you need more setup options (like CSV settings, primary key, or incremental load) or want to load more files at once, switch to an advanced mode, where you will have more options for how to set up your connection."
          documentation={`${URLS.USER_DOCUMENTATION}/components/extractors/storage/http/`}
        />
      );
    }

    return (
      <Help
        down
        title="What will happen now?"
        text="We will start loading your data. It may take a few minutes for large amounts of data. In the meantime, you can load data from other sources."
      />
    );
  }

  getSavedUrl() {
    const baseUrl = this.props.config.getIn(['configuration', 'parameters', 'baseUrl'], '');
    const path = this.props.config.getIn(['rows', 0, 'configuration', 'parameters', 'path'], '');

    if (!baseUrl.trim() || !path.trim()) {
      return '';
    }

    return `${baseUrl}/${string.ltrim(path, '/')}`;
  }

  getNewUrlParts() {
    try {
      const { protocol, hostname, pathname, search = '' } = new window.URL(this.state.url);

      return { baseUrl: `${protocol}//${hostname}`.trim(), path: `${pathname}${search}`.trim() };
    } catch {
      return { baseUrl: this.state.url, path: '' };
    }
  }

  isUrlChangeValid() {
    const { baseUrl, path } = this.getNewUrlParts();

    if (!baseUrl || !string.ltrim(path, '/')) return false;

    return (
      baseUrl !== this.props.config.getIn(['configuration', 'parameters', 'baseUrl'], '') ||
      path !== this.props.config.getIn(['rows', 0, 'configuration', 'parameters', 'path'], '')
    );
  }

  isSaveDisabled() {
    return this.props.readOnly || this.state.isSaving || !this.isUrlChangeValid();
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ isSaving: true });
    const { baseUrl, path } = this.getNewUrlParts();

    return InstalledComponentsActionCreators.saveComponentConfigData(
      KEBOOLA_EX_HTTP,
      this.props.config.get('id'),
      this.props.config
        .get('configuration', Map())
        .setIn(['parameters', 'baseUrl'], baseUrl.trim()),
      'Update HTTP configuration',
    )
      .then(() => {
        const configRow = this.props.config
          .getIn(['rows', 0], Map())
          .set('configuration', createConfiguration(Map({ path: path.trim(), name: 'data' })));

        if (configRow.get('id')) {
          return ConfigurationRowsActionCreators.updateSimple(
            KEBOOLA_EX_HTTP,
            this.props.config.get('id'),
            configRow.get('id'),
            { configuration: JSON.stringify(configRow.get('configuration', Map()).toJS()) },
            'Update row',
          );
        }

        return createSimpleRow(
          KEBOOLA_EX_HTTP,
          this.props.config.get('id'),
          {
            name: string.strRightBack(path, '/'),
            configuration: JSON.stringify(configRow.get('configuration', Map()).toJS()),
          },
          'Create new row',
        );
      })
      .then(() => {
        return runComponent(KEBOOLA_EX_HTTP, this.props.config.get('id'), this.props.hasNewQueue);
      })
      .finally(() => nextTick(() => this.setState({ url: this.getSavedUrl(), isSaving: false })));
  };
}
export default HttpExSetup;
