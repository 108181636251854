export function matchByWords(
  texts: string | number | (string | number)[],
  query: string | undefined,
) {
  if (!query?.trim()) {
    return true;
  }

  const inputs = ([] as (string | number)[])
    .concat(texts)
    .map((input) => input?.toString()?.toLowerCase())
    .filter(Boolean);

  const queries = query.trim().toLowerCase().split(' ');

  return queries.every((word) => inputs.some((input) => input.includes(word)));
}
