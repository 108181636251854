import React from 'react';

import { HelpBlock } from '@keboola/design';

import type { PathValueProps } from '@/modules/ex-ftp/SourcePath';
import Select from '@/react/common/Select';

type Props = {
  columnsFrom: 'manual' | 'header' | 'auto';
  disabled: boolean;
  onChange: (data: Partial<PathValueProps>) => void;
  columns: string[];
  primaryKey: string[];
};

const columnsFromOptions = [
  {
    label: 'Set the header manually',
    value: 'manual',
  },
  {
    label: 'Read the header from the file(s) header',
    value: 'header',
  },
  {
    label: 'Create the header automatically',
    value: 'auto',
  },
];

class HeaderPrimaryKey extends React.Component<Props> {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Header &amp; Primary Key</h3>
        </div>
        <div className="box-content">
          <div className="form-group">
            <label htmlFor="columnsFrom" className="col-xs-4 control-label">
              Read Header
            </label>
            <div className="col-xs-8">
              <Select
                name="columnsFrom"
                id="columnsFrom"
                value={this.props.columnsFrom}
                searchable={false}
                clearable={false}
                options={columnsFromOptions}
                disabled={this.props.disabled}
                onChange={(value: PathValueProps['columnsFrom']) => {
                  this.props.onChange({
                    columnsFrom: value,
                    ...(value !== 'manual' && { columns: [] }),
                  });
                }}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="columns" className="col-xs-4 control-label">
              Set Header
            </label>
            <div className="col-xs-8">
              <Select
                name="columns"
                id="columns"
                value={this.props.columns}
                multi
                allowCreate
                placeholder="Add a column"
                onChange={(value) => {
                  this.props.onChange({ columns: value.toArray() });
                }}
                disabled={this.props.columnsFrom !== 'manual' || this.props.disabled}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="primaryKey" className="col-xs-4 control-label">
              Primary Key
            </label>
            <div className="col-xs-8">
              <Select
                name="primaryKey"
                id="primaryKey"
                value={this.props.primaryKey}
                multi
                allowCreate
                placeholder="Add a column to the primary key"
                onChange={(value) => {
                  this.props.onChange({ primaryKey: value.toArray() });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock className="tw-mt-1">
                If a primary key is set, updates can be done on the table by selecting{' '}
                <strong>incremental loads</strong>. The primary key can consist of multiple columns.
                The primary key of an existing table cannot be changed.
              </HelpBlock>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderPrimaryKey;
