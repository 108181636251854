import React from 'react';
import createReactClass from 'create-react-class';
import { fromJS, List } from 'immutable';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import GenericRows from '@/modules/components/react/pages/GenericRows';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import columnTypes from '@/modules/configurations/utils/columnTypeConstants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import OauthStore from '@/modules/oauth-v2/Store';
import { shouldShowSimplifiedUi } from '@/modules/simplified-ui/helpers';
import SimplifiedUi from '@/modules/simplified-ui/Index';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import Profiles from './components/Profiles';
import { prepareProfilesData } from './helpers';
import LegacyIndex from './LegacyIndex';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      DevBranchesStore,
      OauthStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageBucketsStore,
      StorageTablesStore,
      ConfigurationRowsStore,
    ),
  ],

  getStateFromStores() {
    const componentId = RoutesStore.getCurrentRouteComponentId();
    const configId = RoutesStore.getCurrentRouteParam('config');
    const config = InstalledComponentsStore.getConfig(componentId, configId);
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);

    return {
      componentId,
      configId,
      config,
      configData,
      allTables: StorageTablesStore.getAll(),
      allBuckets: StorageBucketsStore.getAll(),
      component: ComponentsStore.getComponent(componentId),
      rows: ConfigurationRowsStore.getRows(componentId, configId),
      oauthCredentials: OauthStore.getCredentials(componentId, configData),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
    };
  },

  render() {
    if (
      this.state.rows.isEmpty() &&
      !this.state.configData.getIn(['parameters', 'queries'], List()).isEmpty()
    ) {
      return <LegacyIndex />;
    }

    if (shouldShowSimplifiedUi(this.state.component, this.state.configData)) {
      return <SimplifiedUi />;
    }

    return (
      <GenericConfigBody
        key={`${this.state.componentId}-${this.state.configId}`}
        componentId={this.state.componentId}
        configId={this.state.configId}
      >
        <Profiles
          componentId={this.state.componentId}
          profiles={this.state.configData
            .getIn(['parameters', 'profiles'], List())
            .concat(this.state.configData.getIn(['parameters', 'properties'], List()))}
          hasCredentialsId={this.state.configData.hasIn(['authorization', 'oauth_api', 'id'])}
          oauthCredentials={this.state.oauthCredentials}
          handleSave={this.handleSaveProfiles}
          readOnly={this.state.readOnly}
        />
        <GenericRows
          rows={this.state.rows}
          buckets={this.state.allBuckets}
          tables={this.state.allTables}
          config={this.state.config}
          component={this.state.component}
          readOnly={this.state.readOnly}
          rowLinkTo={`${this.state.componentId}-row`}
          rowsColumns={fromJS([
            {
              name: 'Output Table',
              type: columnTypes.TABLE_LINK,
              value: (row) => {
                return `${this.state.configData.getIn(
                  ['parameters', 'outputBucket'],
                  '',
                )}.${row.getIn(['parameters', 'outputTable'], '')}`;
              },
            },
          ])}
        />
      </GenericConfigBody>
    );
  },

  handleSaveProfiles(profiles) {
    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.state.componentId,
      this.state.configId,
      prepareProfilesData(this.state.configData, profiles),
      'Update profiles',
    );
  },
});

export default Index;
