import { createContext, useContext } from 'react';

import type { KeboolaClient } from '../client';
import { ErrorMessage } from '../constants';

const context = createContext<KeboolaClient | null>(null);

export const Provider = context.Provider;

export const useKeboolaClient = () => {
  const client = useContext(context);
  if (client === null) throw new Error(ErrorMessage.REACT_MISSING_CONTEXT_PROVIDER);
  return client;
};
