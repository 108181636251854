import React from 'react';

import { TableIconAction } from './TableIconAction';

type Props = {
  onClick: () => void;
  multiple?: boolean;
  disabled?: boolean;
};

export const CreateSnapshot = ({ onClick, multiple = false, disabled = false }: Props) => (
  <TableIconAction
    tooltip={`Create snapshot${multiple ? 's' : ''}`}
    onClick={onClick}
    disabled={disabled}
    libraryIcon="camera-viewfinder"
  />
);
