import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import OptionalFormLabel from '@/react/common/OptionalFormLabel';
const DirectTokenInsertFields = createReactClass({
  propTypes: {
    token: PropTypes.string.isRequired,
    authorizedFor: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired,
    onChangeProperty: PropTypes.func.isRequired,
  },

  getDefaultProps() {
    return {
      token: '',
      authorizedFor: '',
    };
  },

  makeSetStatePropertyFn(prop) {
    return (e) => {
      const val = e.target.value;
      this.props.onChangeProperty(prop, val);
    };
  },

  render() {
    return (
      <div>
        <div className="form-group">
          <label htmlFor="directToken" className="control-label col-xs-3">
            Token
          </label>
          <div className="col-xs-9">
            <FormControl
              id="directToken"
              name="directtoken"
              value={this.props.token}
              onChange={this.makeSetStatePropertyFn('token')}
              autoFocus
            />
            <span className="help-block">Manually generated access token.</span>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="authorizedFor" className="control-label col-xs-3">
            Name <OptionalFormLabel />
          </label>
          <div className="col-xs-9">
            <FormControl
              id="authorizedFor"
              value={this.props.authorizedFor}
              onChange={this.makeSetStatePropertyFn('authorizedFor')}
              autoFocus={false}
            />
            <span className="help-block">
              Describe this authorization, e.g., by the account name.
            </span>
          </div>
        </div>
      </div>
    );
  },
});

export default DirectTokenInsertFields;
