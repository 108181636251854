import React from 'react';
import { Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import { HelpBlock, Link } from '@keboola/design';

import CodeEditor from '@/react/common/CodeEditor';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import { isValidJsonConfig } from '@/utils/validation';

type Props = {
  show: boolean;
  readOnly: boolean;
  params: Map<string, any>;
  onHide: () => void;
  onSave: (params: Record<string, any>) => void;
};

const TaskParamsModal = ({ params, show, readOnly, onHide, onSave }: Props) => {
  const [paramsString, setParamsString] = React.useState('');

  return (
    <Modal
      show={show}
      onHide={onHide}
      className="only-with-editor"
      onEnter={() => setParamsString(JSON.stringify(params.toJS(), null, 2))}
    >
      <Modal.Header closeButton>
        <Modal.Title>Task Parameters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CodeEditor
          autoFocus
          value={paramsString}
          onChange={setParamsString}
          options={{ readOnly }}
        />
        <HelpBlock className="tw-mt-1">
          This is a low-level feature that modifies the parameters sent to the underlying{' '}
          <Link href="https://app.swaggerhub.com/apis-docs/keboola/job-queue-api/1.3.3#/Jobs/createJob">
            API call
          </Link>
          .
        </HelpBlock>
      </Modal.Body>
      <Modal.Footer>
        <ConfirmButtons
          block
          saveLabel="Set"
          saveStyle="primary"
          isDisabled={readOnly || !isValidJsonConfig(paramsString)}
          onSave={() => {
            onSave(JSON.parse(paramsString));
            onHide();
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default TaskParamsModal;
