import React from 'react';

const EmptyBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="box">
      <div className="box-content">{children}</div>
    </div>
  );
};

export default EmptyBox;
