import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { cn } from '@keboola/design';

import { sortEntities } from '@/constants';
import { useSorter } from '@/hooks';
import { canManageBucket, canWriteBucket, isSharingAvailable } from '@/modules/admin/privileges';
import { updateBucketsSort } from '@/modules/storage/actions';
import { routeNames } from '@/modules/storage/constants';
import {
  getFilteredData,
  isBucket,
  sortByDisplayName,
  sortByExactMatch,
} from '@/modules/storage/helpers';
import { BucketLabels, CreatedDate, RouterLink as Link, TableLabels } from '@/react/common';
import BucketStageLabel from '@/react/common/BucketStageLabel';
import MarkedText from '@/react/common/MarkedText';
import type { OpenShareModal, RowData, TableData } from '@/types/types';
import {
  BucketActions,
  CellSelection,
  HeaderActions,
  HeaderSelection,
  LastChangeColumn,
  ResultsFlatTable,
  TableActions,
} from './components';

type Props = {
  readOnly: boolean;
  sapiToken: Map<string, any>;
  buckets: Map<string, any>;
  query: string;
  searchFilters: Map<string, any>;
  bucketsSort: Map<string, any>;
  openDeleteModal: () => void;
  openDeleteBucketModal: (bucket: Map<string, any>) => void;
  openDeleteTableModal: (table: Map<string, any>) => void;
  openExportTablesModal: (tables: Map<string, any>[]) => void;
  openCreateWorkpaceModal: () => void;
  openSnapshotModal: (tables: Map<string, any>[]) => void;
  openShareModal: OpenShareModal;
  deletingTables: Map<string, boolean>;
  exportingTables: Map<string, boolean>;
  createSnapshotsTables: Map<string, boolean>;
  deletingBuckets: Map<string, boolean>;
  canExportTable: boolean;
  hasSnowflakePartnerConnectLimited: boolean;
};

export const IndexTableResults = ({
  readOnly,
  sapiToken,
  buckets,
  query,
  searchFilters,
  bucketsSort,
  openDeleteModal,
  openDeleteBucketModal,
  openDeleteTableModal,
  openExportTablesModal,
  openCreateWorkpaceModal,
  openSnapshotModal,
  openShareModal,
  deletingTables,
  exportingTables,
  createSnapshotsTables,
  deletingBuckets,
  canExportTable,
  hasSnowflakePartnerConnectLimited,
}: Props) => {
  const { sort, setSort, sorter } = useSorter(bucketsSort, updateBucketsSort);

  const columns = React.useMemo(
    () =>
      [
        {
          id: 'selection',
          header: ({ table }: { table: TableData }) => (
            <span className="tw-relative tw-top-0.5">
              <HeaderSelection table={table} />
            </span>
          ),
          cell: ({ row }: { row: RowData }) => (
            <span className="tw-relative tw-top-0.5">
              <CellSelection row={row} />
            </span>
          ),
        },
        {
          header: ({ table }: { table: TableData }) => {
            return (
              <HeaderActions
                allBuckets={buckets}
                sapiToken={sapiToken}
                openDeleteModal={openDeleteModal}
                openCreateWorkpaceModal={openCreateWorkpaceModal}
                canExportTable={canExportTable}
                canShareTable={isSharingAvailable(sapiToken)}
                openExportTablesModal={openExportTablesModal}
                openSnapshotModal={openSnapshotModal}
                table={table}
                sort={sort}
                setSort={setSort}
                hasSnowflakePartnerConnectLimited={hasSnowflakePartnerConnectLimited}
                isCreatingSnapshot={!createSnapshotsTables.isEmpty()}
                isExportingTables={!exportingTables.isEmpty()}
                openShareModal={openShareModal}
              />
            );
          },
          cell: ({ row }: { row: RowData }) => {
            const item = row.original.item;

            if (isBucket(item)) {
              const bucket = item;
              const bucketToExport = buckets.get(bucket.get('id'));

              return (
                <span className="flex-container flex-start overflow-break-anywhere">
                  <FontAwesomeIcon
                    fixedWidth
                    icon={
                      bucketToExport.get('bucketTables', List()).isEmpty()
                        ? ['far', 'folder']
                        : 'folder'
                    }
                    className="text-muted"
                  />
                  <Link
                    to={routeNames.BUCKET}
                    params={{ bucketId: bucket.get('id') }}
                    className="link-inherit"
                  >
                    <MarkedText mark={query} source={bucket.get('displayName')} />
                  </Link>
                  <BucketLabels bucket={bucket} />
                </span>
              );
            }

            const table = item;

            return (
              <span className="flex-container flex-start overflow-break-anywhere">
                <FontAwesomeIcon fixedWidth icon="table" className="text-muted" />
                <Link
                  to={routeNames.TABLE}
                  params={{
                    bucketId: table.getIn(['bucket', 'id']),
                    tableName: table.get('name'),
                  }}
                  className={cn('link-inherit', {
                    'dotted-underline': table.get('isAlias', false),
                  })}
                >
                  {table.getIn(['bucket', 'displayName'])}
                  <BucketStageLabel
                    round
                    placement="right"
                    stage={table.getIn(['bucket', 'stage'])}
                  />
                  <FontAwesomeIcon
                    icon={['far', 'angle-right']}
                    className="text-muted icon-addon-left icon-addon-right"
                  />
                  <MarkedText mark={query} source={table.get('displayName')} />
                </Link>
                <TableLabels table={table} />
              </span>
            );
          },
          accessorKey: 'item',
        },
        {
          id: 'last_modified',
          header: () => <LastChangeColumn sort={sort} setSort={setSort} />,
          cell: ({ row }: { row: RowData }) => {
            if (row.original.item.has('stage')) {
              const bucket = row.original.item;
              const isDeleting = deletingBuckets.get(bucket.get('id'), false);
              const currentBucket = buckets.get(bucket.get('id'));
              const isExportingBucket =
                !currentBucket.get('bucketTables').isEmpty() &&
                currentBucket
                  .get('bucketTables')
                  .every((table: Map<string, any>) => exportingTables.get(table.get('id'), false));
              const isCreatingSnapshotBucket =
                !currentBucket.get('bucketTables').isEmpty() &&
                currentBucket
                  .get('bucketTables')
                  .every((table: Map<string, any>) =>
                    createSnapshotsTables.get(table.get('id'), false),
                  );

              return (
                <div
                  className={cn('actions-container', {
                    'force-actions': isDeleting || isExportingBucket || isCreatingSnapshotBucket,
                  })}
                >
                  <div className="not-actions">
                    <CreatedDate
                      createdTime={bucket.get('lastChangeDate') || bucket.get('created')}
                    />
                  </div>
                  <div className="actions">
                    <BucketActions
                      bucket={currentBucket}
                      sapiToken={sapiToken}
                      isDeleting={isDeleting}
                      isExporting={isExportingBucket}
                      isCreatingSnapshot={isCreatingSnapshotBucket}
                      openModal={openDeleteBucketModal}
                      canExportTable={canExportTable}
                      openExportTablesModal={openExportTablesModal}
                      openSnapshotModal={openSnapshotModal}
                      openShareModal={openShareModal}
                      canManageBucket={canManageBucket(sapiToken, bucket)}
                      canWriteBucket={canWriteBucket(sapiToken, bucket)}
                    />
                  </div>
                </div>
              );
            }

            const table = row.original.item;
            const isDeleting = deletingTables.get(table.get('id'), false);
            const isExporting = exportingTables.get(table.get('id'), false);
            const isCreatingSnapshot = createSnapshotsTables.get(table.get('id'), false);

            return (
              <div
                className={cn('actions-container', {
                  'force-actions': isDeleting || isExporting || isCreatingSnapshot,
                })}
              >
                <div className="not-actions">
                  <CreatedDate createdTime={table.get('lastChangeDate') || table.get('created')} />
                </div>
                <div className="actions">
                  <TableActions
                    table={table}
                    isDeleting={isDeleting}
                    isExporting={isExporting}
                    isCreatingSnapshot={isCreatingSnapshot}
                    openDeleteTableModal={openDeleteTableModal}
                    openExportTablesModal={openExportTablesModal}
                    openSnapshotModal={openSnapshotModal}
                    canExportTable={canExportTable}
                    canWriteBucket={canWriteBucket(sapiToken, table.get('bucket'))}
                    canShareTable={isSharingAvailable(sapiToken)}
                    openShareModal={openShareModal}
                  />
                </div>
              </div>
            );
          },
        },
      ].filter((column) => !readOnly || column.id !== 'selection'),
    [
      readOnly,
      sapiToken,
      sort,
      setSort,
      openDeleteModal,
      openCreateWorkpaceModal,
      canExportTable,
      openExportTablesModal,
      openSnapshotModal,
      openShareModal,
      hasSnowflakePartnerConnectLimited,
      createSnapshotsTables,
      exportingTables,
      query,
      buckets,
      deletingBuckets,
      deletingTables,
      openDeleteBucketModal,
      openDeleteTableModal,
    ],
  );

  const memoizedRows = React.useMemo(() => {
    let data = getFilteredData(buckets, query, searchFilters.toJS());

    if (sort.get('entity') === sortEntities.NAME && !!query) {
      data = data.sortBy(sortByDisplayName).sortBy(sortByExactMatch);
    } else {
      data = data.sort(sorter);
    }

    return data
      .map((bucket: Map<string, any>) => {
        let tables = bucket.get('bucketTables');

        if (sort.get('entity') === sortEntities.NAME && !!query) {
          tables = tables.sortBy(sortByDisplayName).sortBy(sortByExactMatch);
        } else {
          tables = tables.sort(sorter);
        }

        return [
          bucket.get('matches') && { item: bucket },
          ...tables.map((table: Map<string, any>) => ({ item: table })).toArray(),
        ].filter(Boolean);
      })
      .toArray()
      .flat();
  }, [buckets, query, searchFilters, sort, sorter]);

  if (memoizedRows.length === 0) {
    return (
      <div className="box searchbar-results-box">
        <div className="box-content">No buckets or tables are matching your criteria</div>
      </div>
    );
  }

  return (
    <div className="box">
      <ResultsFlatTable columns={columns} data={memoizedRows} />
    </div>
  );
};
