import React from 'react';

import { ButtonLink, Link } from '@keboola/design';

import { routeNames as dataTakeoutRouteNames } from '@/modules/data-takeout/constants';
import { RouterLink } from '@/react/common';

class DataTakeout extends React.Component {
  render() {
    return (
      <div className="well pp-6">
        <h3 className="tw-mb-6 tw-mt-0">Data Takeout</h3>
        <div className="value-list m-0">
          <p className="tw-mb-6">
            Export this project to <Link href="https://aws.amazon.com/s3/">Amazon S3</Link> or{' '}
            <Link href="https://azure.microsoft.com/services/storage/blobs/">
              Azure Blob Storage
            </Link>
            .
          </p>
          <ButtonLink variant="secondary" className="tw-w-max" size="small">
            <RouterLink to={dataTakeoutRouteNames.DATA_TAKEOUT}>Data Takeout</RouterLink>
          </ButtonLink>
        </div>
      </div>
    );
  }
}

export default DataTakeout;
