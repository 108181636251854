import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn } from '@keboola/design';

const DropdownToggleIcon = ({
  isOpen,
  className,
  withBackground = false,
}: {
  isOpen: boolean;
  className?: string;
  withBackground?: boolean;
}) => {
  return (
    <FontAwesomeIcon
      icon={isOpen ? 'angle-up' : 'angle-down'}
      className={cn('dropdown-toggle-icon', { 'with-background': withBackground }, className)}
      fixedWidth
    />
  );
};

export default DropdownToggleIcon;
