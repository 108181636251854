import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';

import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import { matchByWords } from '@/utils';
import descriptionExcerpt from '@/utils/descriptionExcerpt';

const OrchestrationSelect = createReactClass({
  propTypes: {
    component: PropTypes.object.isRequired,
    orchestrations: PropTypes.object.isRequired,
    orchestratorConfigurations: PropTypes.object.isRequired,
    onReset: PropTypes.func.isRequired,
    onConfigurationSelect: PropTypes.func.isRequired,
    query: PropTypes.string.isRequired,
    hasFlows: PropTypes.bool.isRequired,
  },

  render() {
    const filteredOrchestrations = this._getFilteredOrchestrations();
    return (
      <>
        <div className="flex-container">
          <h2 className="mt-1 mb-1">
            <ComponentIcon
              component={this.props.component}
              className="icon-addon-right"
              size="32"
            />
            <ComponentName component={this.props.component} showType />
          </h2>
          <Button bsStyle="link" className="btn-link-inline" onClick={this.props.onReset}>
            <FontAwesomeIcon icon="chevron-left" className="icon-addon-right" />
            Back
          </Button>
        </div>
        {filteredOrchestrations.count() > 0 ? (
          <div className="list-group">
            {filteredOrchestrations
              .map((configuration) => {
                return (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    className="list-group-item"
                    key={configuration.get('id')}
                    onClick={this._handleSelect.bind(this, configuration)}
                  >
                    <div className="flex-container">
                      <span>
                        <strong>{configuration.get('name')}</strong>
                        <small>
                          {descriptionExcerpt(
                            this.props.orchestratorConfigurations.getIn([
                              configuration.get('id').toString(),
                              'description',
                            ]),
                          )}
                        </small>
                      </span>
                      <FontAwesomeIcon icon="circle-plus" pull="right" />
                    </div>
                  </a>
                );
              })
              .toArray()}
          </div>
        ) : (
          <p>No {this.props.hasFlows ? 'flow' : 'orchestration'} found.</p>
        )}
      </>
    );
  },

  _handleSelect(configuration) {
    return this.props.onConfigurationSelect(configuration);
  },

  _getFilteredOrchestrations() {
    const orchestrations = this.props.orchestrations.sortBy((orchestration) =>
      orchestration.get('name', '').toLowerCase(),
    );

    if (!this.props.query) {
      return orchestrations;
    }

    return orchestrations.filter((orchestration) =>
      matchByWords(orchestration.get('name', ''), this.props.query),
    );
  },
});

export default OrchestrationSelect;
