import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { Alert } from '@keboola/design';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';

const SendTokenModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    onHideFn: PropTypes.func.isRequired,
    onSendFn: PropTypes.func.isRequired,
    isSending: PropTypes.bool.isRequired,
    token: PropTypes.object.isRequired,
  },

  getInitialState() {
    return {
      email: '',
      message: '',
    };
  },

  render() {
    const { token } = this.props;
    return (
      <Modal show={this.props.show} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Send Token {token.get('description')}</Modal.Title>
          <ModalIcon icon="share" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          {this.renderInfo()}
          <div className="form form-horizontal">
            <div className="form-group">
              <label htmlFor="recipient" className="control-label col-sm-3">
                Recipient
              </label>
              <div className="col-sm-9">
                <input
                  id="recipient"
                  placeholder="mail@example.com"
                  disabled={this.props.isSending}
                  className="form-control"
                  type="email"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="message" className="control-label col-sm-3">
                Message
              </label>
              <div className="col-sm-9">
                <textarea
                  id="message"
                  placeholder="Here's the token to upload your data"
                  disabled={this.props.isSending}
                  className="form-control"
                  type="textarea"
                  value={this.state.message}
                  onChange={(e) => this.setState({ message: e.target.value })}
                  rows="3"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={this.props.isSending}
            isDisabled={!this.isValid()}
            onSave={this.handleSend}
            saveLabel="Send"
          />
        </Modal.Footer>
      </Modal>
    );
  },

  isValid() {
    return !!this.state.email && !!this.state.message;
  },

  handleSend() {
    this.props.onSendFn(this.state).then(this.handleClose);
  },

  renderInfo() {
    return (
      <Alert className="tw-mb-5">
        The recipient will receive an email with a link to retrieve the token. The link will expire
        in 24 hours.
      </Alert>
    );
  },

  handleClose() {
    this.props.onHideFn();
    this.resetState();
  },

  resetState() {
    this.setState({ email: '', message: '' });
  },
});

export default SendTokenModal;
