import React from 'react';
import { ControlLabel, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import type { Map } from 'immutable';

import { Clipboard, HelpBlock } from '@keboola/design';

import type { ExistingApp } from '@/api/routes/dataScienceService';
import { getCurrentHostname } from '@/constants/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { prepareSlug } from '@/modules/data-apps/helpers';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import SaveButtons from '@/react/common/SaveButtons';

const URL_PREFIX_PATH = ['parameters', 'dataApp', 'slug'];

const AdditionalSettings = (props: {
  componentId: string;
  configId: string;
  configData: Map<string, any>;
  app?: ExistingApp;
  readOnly: boolean;
}) => {
  const savedUrlPrefix = props.configData.getIn(URL_PREFIX_PATH, '');
  const [urlPrefix, setUrlPrefix] = React.useState(savedUrlPrefix);
  const [saving, setSaving] = React.useState(false);
  const hostname = getCurrentHostname().replace('connection.', 'hub.');
  const urlSuffix = props.app ? `-${props.app.id}.${hostname}` : '';

  const input = (
    <FormControl
      type="text"
      value={urlPrefix}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setUrlPrefix(prepareSlug(e.target.value));
      }}
    />
  );

  return (
    <CollapsibleBox
      title="Additional Settings"
      additionalActions={() => {
        return (
          <SaveButtons
            isSaving={saving}
            disabled={props.readOnly || saving || !urlPrefix}
            isChanged={urlPrefix !== savedUrlPrefix}
            onSave={() => {
              setSaving(true);
              return InstalledComponentsActionCreators.saveComponentConfigData(
                props.componentId,
                props.configId,
                props.configData.setIn(URL_PREFIX_PATH, urlPrefix),
                'Change url prefix',
              ).finally(() => setSaving(false));
            }}
            onReset={() => setUrlPrefix(savedUrlPrefix)}
          />
        );
      }}
    >
      <FormGroup>
        <div className="flex-container">
          <ControlLabel>Url Prefix</ControlLabel>
          {props.app && (
            <Clipboard
              aloneLink
              tooltipPlacement="top"
              label="Copy to Clipboard"
              tooltipText="Click to copy"
              text={`${urlPrefix}${urlSuffix}`}
            />
          )}
        </div>
        {props.app ? (
          <InputGroup>
            {input}
            <InputGroup.Addon>{urlSuffix}</InputGroup.Addon>
          </InputGroup>
        ) : (
          input
        )}
        <HelpBlock className="tw-mt-1">Url prefix will help identify the app by the URL.</HelpBlock>
      </FormGroup>
    </CollapsibleBox>
  );
};

export default AdditionalSettings;
