import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { List } from 'immutable';
import { fromJS, Map } from 'immutable';

import { Tooltip } from '@keboola/design';

import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '@/constants/features';
import SandboxesActions from '@/modules/sandboxes/Actions';
import AddSandboxModal from '@/modules/sandboxes/components/AddSandboxModal/AddSandboxModal';
import { FORM_STEPS } from '@/modules/sandboxes/Constants';
import { updateExistingWorkspace } from '@/modules/sandboxes/helpers';
import { DBT_COMPONENTS } from '@/modules/transformations-v2/constants';
import { SortIcon } from '@/react/common';
import ApplicationStore from '@/stores/ApplicationStore';
import string from '@/utils/string';
import ConfirmModal from './ConfirmModal';
import InfoTooltip from './InfoTooltip';
import Loader from './Loader';
import MultiActionsHeader from './MultiActionsHeader';
import { CopyToWorkspace, CreateWorkspace } from './TableIconActions';

const MODALS = {
  DELETE: 'DELETE',
  CREATE_WORKSPACE: 'CREATE_WORKSPACE',
  USE_WORKSPACE: 'USE_WORKSPACE',
};

export const MappingsHeader = ({
  type,
  componentId,
  storage,
}: {
  type: 'input' | 'output';
  componentId: string;
  storage: string;
}) => {
  return (
    <h2 className="box-title tw-leading-10">
      {DBT_COMPONENTS.includes(componentId) && storage === 'tables' && type === 'input' ? (
        <>
          Storage Sources
          <InfoTooltip tooltip="Optionally, define sources from Storage to help declutter lineage and documentation. Note that no data will be physically loaded during execution as dbt has read access to Storage. The platform will generate only selected tables sources.yml during execution." />
        </>
      ) : (
        `${string.capitalize(string.strLeft(storage, 's'))} ${string.capitalize(type)} Mapping`
      )}
    </h2>
  );
};

type Props = {
  hide: boolean;
  disabled: boolean;
  componentId: string;
  configurationId: string;
  type: 'input' | 'output';
  storage: 'tables' | 'files';
  allMappings: List<any>;
  selectedMappings: Map<number, boolean>;
  updateMappingsSelection: (mappings: Map<unknown, unknown>) => void;
  deleteMappings: (
    componentId: string,
    configurationId: string,
    rowId: string | null,
    type: string,
    storage: string,
    selectedMappings: Map<number, boolean>,
  ) => Promise<void>;
  allowedComponents?: Map<string, any>;
  sandboxes?: Map<string, any>;
  hasPayAsYouGo?: boolean;
  rowId?: string;
  isSorted: boolean;
  isSortedDesc: boolean;
  onClick: () => void;
};

const MappingsMultiActionsHeader = ({
  hide,
  disabled,
  componentId,
  configurationId,
  type,
  storage,
  allMappings,
  selectedMappings,
  updateMappingsSelection,
  deleteMappings,
  allowedComponents,
  sandboxes,
  hasPayAsYouGo,
  rowId,
  isSorted,
  isSortedDesc,
  onClick,
}: Props) => {
  const [activeModal, setActiveModal] = useState('');
  const [isDeletingMappings, setIsDeletingMappings] = useState(false);
  const selectedCount = selectedMappings.count((isTableSelected) => !!isTableSelected);
  const selectedMappingsStorage = Map().setIn(
    [type, storage],
    allMappings.filter((mapping, index) => selectedMappings.get(index!, false)),
  );
  const allowWorkspaceActions =
    allowedComponents &&
    !ApplicationStore.hasCurrentProjectFeature(FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED);
  const isRowSelected = !selectedMappings.isEmpty();

  return (
    <div className="tw-w-[51%]">
      <div
        className="tw-inline-flex tw-cursor-pointer tw-items-center"
        onClick={() => !isRowSelected && onClick()}
      >
        <MultiActionsHeader
          hide={hide}
          disabled={disabled}
          totalCount={allMappings.count()}
          selectedCount={selectedCount}
          onToggleAll={(checked) =>
            updateMappingsSelection(
              !checked
                ? Map()
                : allMappings
                    .map((mapping, index) => [index, true])
                    .toList()
                    .fromEntrySeq()
                    .toMap(),
            )
          }
          placeholder={
            <div className="tw-mt-px tw-h-5 tw-font-medium tw-leading-5">
              {type === 'input' ? 'Source' : 'Output'}{' '}
              {string.capitalize(string.strLeft(storage, 's'))}
            </div>
          }
          entity="mapping"
        >
          <div className="table-action-buttons tw-mt-px tw-h-5">
            <Tooltip placement="top" tooltip="Delete Selected">
              <Button
                bsStyle="link"
                className="text-muted"
                onClick={() => setActiveModal(MODALS.DELETE)}
                disabled={isDeletingMappings}
              >
                {isDeletingMappings ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
              </Button>
            </Tooltip>
            {allowWorkspaceActions && (
              <>
                <CreateWorkspace onClick={() => setActiveModal(MODALS.CREATE_WORKSPACE)} />
                <CopyToWorkspace onClick={() => setActiveModal(MODALS.USE_WORKSPACE)} />
              </>
            )}
            <ConfirmModal
              show={activeModal === MODALS.DELETE}
              icon="trash"
              title="Delete Selected"
              text="Are you sure you want to delete the selected mappings?"
              buttonLabel="Delete"
              buttonType="danger"
              onConfirm={() => {
                setIsDeletingMappings(true);

                return deleteMappings(
                  componentId,
                  configurationId,
                  rowId ?? null,
                  type,
                  storage,
                  selectedMappings,
                )
                  .then(() => updateMappingsSelection(Map()))
                  .finally(() => setIsDeletingMappings(false));
              }}
              onHide={() => setActiveModal('')}
            />
            {allowWorkspaceActions && (
              <AddSandboxModal
                hasTableInputMapping
                show={['CREATE_WORKSPACE', 'USE_WORKSPACE'].includes(activeModal)}
                forceStep={activeModal === 'USE_WORKSPACE' ? FORM_STEPS.SANDBOX_UPDATE : null}
                onHide={() => setActiveModal('')}
                workspaces={
                  sandboxes?.filter(
                    (sandbox) => sandbox.get('configurationId') !== configurationId,
                  ) as Map<string, any>
                }
                allowedComponents={allowedComponents}
                hasPayAsYouGo={hasPayAsYouGo ?? false}
                onUpdate={(workspace: Map<string, any>, preserve: boolean) => {
                  const preparedConfig = fromJS({
                    configuration: {
                      parameters: { id: workspace.get('id') },
                      storage: selectedMappingsStorage,
                    },
                  });

                  return updateExistingWorkspace(
                    preparedConfig,
                    workspace,
                    preserve,
                    'Update mapping',
                  );
                }}
                onSubmit={(
                  name: string,
                  type: string,
                  options: Map<string, any>,
                  params: Map<string, any>,
                  description: string,
                ) => {
                  return SandboxesActions.createSandbox(
                    {
                      name,
                      description,
                      configuration: JSON.stringify({ storage: selectedMappingsStorage }),
                    },
                    type,
                    options,
                    params.set('storage', selectedMappingsStorage),
                  );
                }}
              />
            )}
          </div>
        </MultiActionsHeader>
        {!isRowSelected && (
          <SortIcon className="tw-pl-2" isSorted={isSorted} isSortedDesc={isSortedDesc} />
        )}
      </div>
    </div>
  );
};

export default MappingsMultiActionsHeader;
