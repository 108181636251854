import Promise from 'bluebird';
import { fromJS, List } from 'immutable';
import _ from 'underscore';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { KEBOOLA_LEGACY_TRANSFORMATION } from '@/constants/componentIds';
import dispatcher from '@/Dispatcher';
import * as installedComponentsConstants from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsApi from '@/modules/components/InstalledComponentsApi';
import VersionActionCreators from '@/modules/components/VersionsActionCreators';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import RowVersionActionCreators from '@/modules/configurations/RowVersionsActionCreators';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import copyTransformationNotification from './react/components/notifications/copyTransformationNotification';
import deleteTransformationBucketNotification from './react/components/notifications/deleteTransformationBucketNotification';
import restoreTransformationBucketNotification from './react/components/notifications/restoreTransformationBucketNotification';
import TransformationBucketsStore from './stores/TransformationBucketsStore';
import TransformationsStore from './stores/TransformationsStore';
import parseQueries from './utils/parseQueries';
import { routeNames } from './Constants';
import * as constants from './Constants';
import transformationsApi from './TransformationsApiAdapter';

const updateTransformationEditingFieldQueriesStringDebouncer = _.debounce(function (
  bucketId,
  transformationId,
  queriesString,
) {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES_START,
    bucketId: bucketId,
    transformationId: transformationId,
  });
  return parseQueries(queriesString)
    .then(function (splitQueries) {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES_SUCCESS,
        bucketId: bucketId,
        transformationId: transformationId,
        splitQueries: splitQueries,
      });
    })
    .catch(function () {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES_ERROR,
        bucketId: bucketId,
        transformationId: transformationId,
      });
    });
}, 1000);

const reloadVersions = function (configId, rowId) {
  let promises = [VersionActionCreators.loadVersionsForce(KEBOOLA_LEGACY_TRANSFORMATION, configId)];
  if (rowId) {
    promises.push(
      RowVersionActionCreators.loadVersionsForce(KEBOOLA_LEGACY_TRANSFORMATION, configId, rowId),
    );
  }
  return Promise.all(promises);
};

export default {
  createTransformationBucket: function (data) {
    var changeDescription, newBucket;
    newBucket = {};
    changeDescription = 'Create transformation bucket ' + data.name;
    return transformationsApi
      .createTransformationBucket(data, changeDescription)
      .then(function (bucket) {
        newBucket = bucket;
        return InstalledComponentsActionCreators.loadInstalledComponentsForce();
      })
      .then(function () {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_BUCKET_CREATE_SUCCESS,
          bucket: newBucket,
        });
        VersionActionCreators.loadVersionsForce(KEBOOLA_LEGACY_TRANSFORMATION, newBucket.id);
        return RoutesStore.getRouter().transitionTo(routeNames.BUCKET, {
          config: newBucket.id,
        });
      });
  },
  createTransformation: function (bucketId, data) {
    var changeDescription;
    changeDescription = 'Create transformation ' + data.get('name');
    return transformationsApi
      .createTransformation(bucketId, data.toJS(), changeDescription)
      .then(function (transformation) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_CREATE_SUCCESS,
          bucketId: bucketId,
          transformation: transformation,
        });
        reloadVersions(bucketId, transformation.id).then(() => {
          RoutesStore.getRouter().transitionTo(routeNames.DETAIL, {
            row: transformation.id,
            config: bucketId,
          });
        });
        return null;
      });
  },
  createTransformationWithoutRedirect: function (bucketId, data) {
    const changeDescription = 'Create transformation ' + data.get('name');
    return transformationsApi
      .createTransformation(bucketId, data.toJS(), changeDescription)
      .then((transformation) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_CREATE_SUCCESS,
          bucketId: bucketId,
          transformation: transformation,
        });
        InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_LEGACY_TRANSFORMATION);
        ApplicationActionCreators.sendNotification({
          type: 'success',
          message: copyTransformationNotification(bucketId, fromJS(transformation)),
        });
        return reloadVersions(bucketId);
      });
  },
  deleteTransformationBucket: function (bucketId) {
    if (DevBranchesStore.isDevModeActive()) {
      return InstalledComponentsActionCreators.deleteConfiguration(
        KEBOOLA_LEGACY_TRANSFORMATION,
        bucketId,
      ).then(() => {
        return InstalledComponentsActionCreators.loadComponentConfigsData(
          KEBOOLA_LEGACY_TRANSFORMATION,
        );
      });
    }

    const bucket = TransformationBucketsStore.get(bucketId);
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_BUCKET_DELETE,
      bucketId: bucketId,
    });
    return InstalledComponentsApi.deleteConfiguration(KEBOOLA_LEGACY_TRANSFORMATION, bucketId)
      .then(() => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_BUCKET_DELETE_SUCCESS,
          bucketId: bucketId,
        });
        dispatcher.handleViewAction({
          type: installedComponentsConstants.ActionTypes
            .INSTALLED_COMPONENTS_DELETE_CONFIGURATION_SUCCESS,
          componentId: KEBOOLA_LEGACY_TRANSFORMATION,
          configurationId: bucketId,
          transition: false,
        });
        InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_LEGACY_TRANSFORMATION);
        return ApplicationActionCreators.sendNotification({
          type: 'info',
          message: deleteTransformationBucketNotification(bucket),
          button: {
            label: 'Restore',
            action: () => this.restoreTransformationBucket(bucket),
          },
        });
      })
      .catch((e) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_BUCKET_DELETE_ERROR,
          bucketId: bucketId,
        });
        throw e;
      });
  },
  restoreTransformationBucket: function (bucket) {
    const bucketId = bucket.get('id');
    dispatcher.handleViewAction({
      type: constants.ActionTypes.DELETED_TRANSFORMATION_BUCKET_RESTORE,
      bucketId: bucketId,
    });
    return transformationsApi
      .restoreTransformationBucket(bucketId)
      .then(function () {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.DELETED_TRANSFORMATION_BUCKET_RESTORE_SUCCESS,
          bucketId: bucketId,
        });
        return InstalledComponentsActionCreators.loadComponentConfigsData(
          KEBOOLA_LEGACY_TRANSFORMATION,
        ).then(function () {
          return ApplicationActionCreators.sendNotification({
            type: 'success',
            message: restoreTransformationBucketNotification(bucketId, bucket.get('name')),
          });
        });
      })
      .catch(function (e) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.DELETED_TRANSFORMATION_BUCKET_RESTORE_ERROR,
          bucketId: bucketId,
        });
        throw e;
      });
  },
  deleteTransformation: function (bucketId, transformationId) {
    if (DevBranchesStore.isDevModeActive()) {
      return ConfigurationRowsActionCreators.delete(
        KEBOOLA_LEGACY_TRANSFORMATION,
        bucketId,
        transformationId,
      ).then(() => {
        return InstalledComponentsActionCreators.loadComponentConfigsData(
          KEBOOLA_LEGACY_TRANSFORMATION,
        );
      });
    }

    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_DELETE,
      bucketId: bucketId,
      transformationId: transformationId,
    });
    const transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    return InstalledComponentsApi.deleteConfigurationRow(
      KEBOOLA_LEGACY_TRANSFORMATION,
      bucketId,
      transformationId,
      'Delete transformation ' + transformation.get('name'),
    )
      .then(function () {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_DELETE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
        });
        InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_LEGACY_TRANSFORMATION);
        return reloadVersions(bucketId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_DELETE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
        });
        throw error;
      });
  },

  changeTransformationProperty: function (
    bucketId,
    transformationId,
    propertyName,
    newValue,
    changeDescription,
  ) {
    var pendingAction, transformation, finalChangeDescription;
    finalChangeDescription = changeDescription;
    pendingAction = 'save-' + propertyName;
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId: transformationId,
      bucketId: bucketId,
      pendingAction: pendingAction,
    });
    transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    transformation = transformation.set(propertyName, newValue);
    if (!changeDescription) {
      finalChangeDescription =
        'Change ' + string.capitalize(propertyName) + ' in ' + transformation.get('name');
    }
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), finalChangeDescription)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: propertyName,
          pendingAction: pendingAction,
          data: response,
        });
        return reloadVersions(bucketId, transformationId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: propertyName,
          pendingAction: pendingAction,
          error: error,
        });
        throw error;
      });
  },
  deleteTransformationProperty: function (
    bucketId,
    transformationId,
    propertyName,
    changeDescription,
  ) {
    let finalChangeDescription = changeDescription;
    const pendingAction = 'save-' + propertyName;
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId: transformationId,
      bucketId: bucketId,
      pendingAction: pendingAction,
    });
    let transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    transformation = transformation.delete(propertyName);
    if (!changeDescription) {
      finalChangeDescription =
        'Delete ' + string.capitalize(propertyName) + ' in ' + transformation.get('name');
    }
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), finalChangeDescription)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: propertyName,
          pendingAction: pendingAction,
          data: response,
        });
        return reloadVersions(bucketId, transformationId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: propertyName,
          pendingAction: pendingAction,
          error: error,
        });
        throw error;
      });
  },
  updateTransformationEditingField: function (bucketId, transformationId, fieldId, newValue) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_UPDATE_EDITING_FIELD,
      bucketId: bucketId,
      transformationId: transformationId,
      fieldId: fieldId,
      newValue: newValue,
    });
  },
  updateTransformationEditingFieldQueriesString: function (
    bucketId,
    transformationId,
    queriesString,
  ) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES,
      bucketId: bucketId,
      transformationId: transformationId,
      queriesString: queriesString,
    });
    return updateTransformationEditingFieldQueriesStringDebouncer(
      bucketId,
      transformationId,
      queriesString,
    );
  },
  cancelTransformationEditingField: function (bucketId, transformationId, fieldId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_CANCEL_EDITING_FIELD,
      bucketId: bucketId,
      transformationId: transformationId,
      fieldId: fieldId,
    });
  },

  saveTransformationQueries: function (bucketId, transformationId) {
    var transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    const transformationEditingFields = TransformationsStore.getTransformationEditingFields(
      bucketId,
      transformationId,
    );
    const pendingAction = 'save-queries';
    const changeDescription = transformationEditingFields.get(
      'description',
      'Change Queries in ' + transformation.get('name'),
    );
    transformation = transformation.set('queries', transformationEditingFields.get('splitQueries'));
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId: transformationId,
      bucketId: bucketId,
      pendingAction: pendingAction,
    });
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), changeDescription)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: 'queries',
          pendingAction: pendingAction,
          data: response,
        });
        return reloadVersions(bucketId, transformationId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: 'queries',
          pendingAction: pendingAction,
          error: error,
        });
        throw error;
      });
  },

  saveTransformationScript: function (bucketId, transformationId) {
    var transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    const transformationEditingFields = TransformationsStore.getTransformationEditingFields(
      bucketId,
      transformationId,
    );
    const pendingAction = 'save-queries';
    const changeDescription = transformationEditingFields.get(
      'description',
      'Change Scripts in ' + transformation.get('name'),
    );
    transformation = transformation.set(
      'queries',
      List([transformationEditingFields.get('queriesString')]),
    );
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId: transformationId,
      bucketId: bucketId,
      pendingAction: pendingAction,
    });
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), changeDescription)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: 'queries',
          pendingAction: pendingAction,
          data: response,
        });
        return reloadVersions(bucketId, transformationId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: 'queries',
          pendingAction: pendingAction,
          error: error,
        });
        throw error;
      });
  },

  saveTransformationEditingField: function (
    bucketId,
    transformationId,
    fieldId,
    changeDescription,
  ) {
    var pendingAction, transformation, value, finalChangeDescription;
    finalChangeDescription = changeDescription;
    value = TransformationsStore.getTransformationEditingFields(bucketId, transformationId).get(
      fieldId,
    );
    transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    transformation = transformation.set(fieldId, value);
    if (!finalChangeDescription) {
      finalChangeDescription =
        'Change ' + string.capitalize(fieldId) + ' in ' + transformation.get('name');
    }
    pendingAction = 'save-' + fieldId;
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId: transformationId,
      bucketId: bucketId,
      pendingAction: pendingAction,
    });
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), finalChangeDescription)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: fieldId,
          pendingAction: pendingAction,
          data: response,
        });
        return reloadVersions(bucketId, transformationId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
          editingId: fieldId,
          pendingAction: pendingAction,
          error: error,
        });
        throw error;
      });
  },
};
