import React from 'react';
import PropTypes from 'prop-types';

import { FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

import CsvDelimiterInput from '@/react/common/CsvDelimiterInput';
import Select from '@/react/common/Select';

const columnsFromOptions = [
  {
    label: 'CSV file(s) contain(s) a header row',
    value: 'header',
  },
  {
    label: 'Set column names manually',
    value: 'manual',
  },
  {
    label: 'Generate column names as col_1, col_2, ...',
    value: 'auto',
  },
];

const FORM_GROUP_CLASS_NAMES = 'tw-grid tw-grid-cols-3 tw-items-baseline tw-mb-4 tw-gap-4';

class CsvSettings extends React.Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">CSV Settings</h3>
        </div>
        <div className="box-content">
          <CsvDelimiterInput
            value={this.props.value.delimiter}
            onChange={(value) => {
              this.props.onChange({ delimiter: value });
            }}
            disabled={this.props.disabled}
            help={
              <span>
                Field delimiter used in the CSV file. Use <code>\t</code> for tabulator.
              </span>
            }
          />

          <FormGroup className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor="enclosure">Enclosure</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <TextInput
                id="enclosure"
                variant="secondary"
                value={this.props.value.enclosure}
                onChange={(value) => {
                  this.props.onChange({ enclosure: value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>Field enclosure used in the CSV file.</HelpBlock>
            </div>
          </FormGroup>

          <FormGroup className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor="columns-from">Header</Label>
            <Select
              id="columns-from"
              className="tw-col-span-2"
              name="columnsFrom"
              value={this.props.value.columnsFrom}
              searchable={false}
              clearable={false}
              options={columnsFromOptions}
              disabled={this.props.disabled}
              onChange={(value) => {
                this.props.onChange({ columnsFrom: value });
              }}
            />
          </FormGroup>

          {this.props.value.columnsFrom === 'manual' && (
            <FormGroup className={FORM_GROUP_CLASS_NAMES}>
              <Label htmlFor="columns">Column Names</Label>
              <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
                <Select
                  id="columns"
                  name="columns"
                  value={this.props.value.columns}
                  multi
                  allowCreate
                  placeholder="Add a column"
                  onChange={(value) => {
                    this.props.onChange({ columns: value });
                  }}
                  disabled={this.props.disabled || this.props.value.columnsFrom === 'header'}
                />
                <HelpBlock>Specify the columns of the headerless files.</HelpBlock>
              </div>
            </FormGroup>
          )}
        </div>
      </div>
    );
  }
}

CsvSettings.propTypes = {
  value: PropTypes.shape({
    delimiter: PropTypes.string.isRequired,
    enclosure: PropTypes.string.isRequired,
    columnsFrom: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default CsvSettings;
