import type { BodyParam, CreateFetchRequestFn, PathParam, ValidateStatusFn } from './types';

export const defaultValidateStatus: ValidateStatusFn = ({ response }) =>
  response.status >= 200 && response.status <= 299;

function removeUndefined(obj: Record<string, string>) {
  const objCopy = { ...obj };
  for (const [key, value] of Object.entries(objCopy)) {
    if (value == null) delete objCopy[key];
  }
  return objCopy;
}

export const parseData = async (response: Response) => {
  // HTTP 204 - no content
  if (response.status === 204) return null;

  // headers indicates that the content is JSON, so parse it.
  const contentType = response.headers.get('content-type');
  if (contentType && contentType == 'application/json') {
    return await response.json();
  }

  // try JSON parsing on the text
  // If it fails, return the string. The headers does not have a content type.
  const text = await response.text();
  try {
    return JSON.parse(text);
  } catch {
    return text;
  }
};

// Header instance serialize undefined value as `undefined`, we do not want that
const cleanHeadersInit = (headersInit: HeadersInit | undefined) => {
  if (Array.isArray(headersInit)) return headersInit;
  if (headersInit instanceof Headers) return headersInit;
  if (headersInit == null) return headersInit;

  return removeUndefined(headersInit);
};

const createHeaders = (
  headersInitA: HeadersInit | undefined,
  headersInitB: HeadersInit | undefined,
) => {
  const headersA = new Headers(cleanHeadersInit(headersInitA));
  const headersB = new Headers(cleanHeadersInit(headersInitB));

  headersB.forEach((value, key) => {
    headersA.set(key, value);
  });

  return headersA;
};

export const createPath = (path: string, pathParam: PathParam = {}) =>
  path.replace(/\{([^}]+)}/g, (_, key) => {
    if (!(key in pathParam))
      throw new Error(`Path parameter "${key}" is missing in the path "${path}"`);

    return encodeURIComponent(pathParam[key]);
  });

export const createSearch = (query: Record<string, unknown>) => {
  if (query == null) return '';

  const searchParams = new URLSearchParams();
  for (const [key, value] of Object.entries(query)) {
    if (value == null) continue;
    if (typeof value === 'number' || typeof value === 'boolean') searchParams.set(key, `${value}`);
    if (typeof value === 'string') searchParams.set(key, value);
    if (Array.isArray(value)) searchParams.set(key, value.join(','));
  }

  return searchParams.toString();
};

export const createBody = (body: BodyParam) => {
  // We want undefined explicitly, as null is a value.
  // eslint-disable-next-line no-undefined
  if (body == null) return undefined;
  if (body instanceof FormData) return body;

  const bodyString = JSON.stringify(body);
  // We want undefined explicitly, as null is a value.
  // eslint-disable-next-line no-undefined
  return bodyString === '{}' ? undefined : bodyString;
};

export const createFetchRequest: CreateFetchRequestFn = ({
  url,
  method,
  params,
  options = {},
  defaultOptions,
}) => {
  const {
    baseUrl,
    validateStatus: defValidateStatus = defaultValidateStatus,
    headers: defaultHeaders,
    ...restDefaultOptions
  } = defaultOptions;
  const { validateStatus, headers: endpointHeaders, ...restOptions } = options;

  const headers = createHeaders(defaultHeaders, endpointHeaders);
  const path = createPath(url, params.path);
  const search = createSearch(params.query ?? {});
  const body = createBody(params.body);

  const urlInstance = new URL(baseUrl + path);
  urlInstance.search = search;

  const request = new Request(urlInstance, {
    ...restDefaultOptions,
    ...restOptions,
    headers,
    method,
    body,
  });

  return { request, validateStatus: validateStatus ?? defValidateStatus };
};
