import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import Select from '@/react/common/Select';

const HeaderColumnsMultiSelect = createReactClass({
  propTypes: {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
  },

  render() {
    return (
      <Select
        id={this.props.id}
        allowCreate
        multi
        value={this.props.value}
        onChange={this.props.onChange}
        placeholder="Type new values"
      />
    );
  },
});

export default HeaderColumnsMultiSelect;
