import React from 'react';

import { CircleIcon } from '@/react/common';

const AccessPanel = ({
  totalTokenCount,
  adminTokenCount,
}: {
  totalTokenCount: number;
  adminTokenCount: number;
}) => (
  <div className="box box-panel box-panel-small">
    <div className="box-header">
      <h2 className="box-title">Access</h2>
      <CircleIcon icon="key" color="yellow" />
    </div>
    <div className="box-panel-content mt-auto">
      <p className="summary-title">{adminTokenCount} Users</p>
      <p className="summary-text">{totalTokenCount - adminTokenCount} API Tokens</p>
    </div>
  </div>
);

export default AccessPanel;
