import React from 'react';

import { Skeleton } from '@keboola/design';

export const DirectoryItemSkeleton = () => (
  <div className="box box-panel">
    <div className="box-header tw-flex tw-flex-row tw-justify-start tw-gap-2">
      <Skeleton height="2xl" className="tw-w-12 tw-rounded-full" />

      <div className="tw-flex tw-flex-col tw-gap-2">
        <Skeleton height="xs" className="tw-w-40" />
        <Skeleton height="xxs" className="tw-w-20" />
      </div>
    </div>

    <div className="box-panel-content tw-flex tw-flex-col tw-gap-2">
      <Skeleton height="xxs" className="tw-w-full" />
      <Skeleton height="xxs" className="tw-w-28" />
      <Skeleton height="md" className="tw-mt-4 tw-w-52" />
    </div>
  </div>
);
