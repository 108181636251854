import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import { Clipboard } from '@keboola/design';

import {
  canLoadSharedBuckets,
  canManageSharedBucket,
  isSharingAvailable,
} from '@/modules/admin/privileges';
import { MetadataKeys, ObjectTypes } from '@/modules/components/MetadataConstants';
import BackendRow from '@/modules/data-catalog/react/BackendRow';
import { SHARED_FROM_PROJECT_ROUTES, SHARED_WITH_YOU_ROUTES } from '@/modules/data-catalog/routes';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { BackendEntityInfoTooltip } from '@/modules/storage/components/BackendEntityInfoTooltip';
import { backends } from '@/modules/storage/constants';
import { CreatedDate, FileSize, RouterLink } from '@/react/common';
import RowsCount from '@/react/common/RowsCount';
import ApplicationStore from '@/stores/ApplicationStore.js';
import TablesList from './TablesList/TablesList';
import NotAvailable from './NotAvailable';
import ShareBucketSimple from './ShareBucketSimple';
import StorageDescription from './StorageDescription';

class BucketOverview extends React.Component {
  render() {
    return (
      <>
        {this.renderOverview()}
        {this.renderDescription()}
        <TablesList
          sapiToken={this.props.sapiToken}
          sandboxes={this.props.sandboxes}
          allowedTransformationComponents={this.props.allowedTransformationComponents}
          bucket={this.props.bucket}
          tables={this.props.tables}
          allTables={this.props.allTables}
          components={this.props.components}
          configurations={this.props.configurations}
          urlTemplates={this.props.urlTemplates}
          canWriteBucket={this.props.canWriteBucket}
          deletingTables={this.props.deletingTables}
          truncatingTables={this.props.truncatingTables}
          exportingTables={this.props.exportingTables}
          createSnapshotsTables={this.props.createSnapshotsTables}
          canExportTable={this.props.canExportTable}
          hasPayAsYouGo={this.props.hasPayAsYouGo}
          hasFlows={this.props.hasFlows}
          hasSnowflakePartnerConnectLimited={this.props.hasSnowflakePartnerConnectLimited}
          isBucketBrowser={this.props.isBucketBrowser}
        />
      </>
    );
  }

  renderSchema() {
    const backend = this.props.bucket.get('backend');
    const path = this.props.bucket.get('path');
    const isSnowflake = backend === backends.SNOWFLAKE;
    const isBigQuery = backend === backends.BIGQUERY;

    if (!ApplicationStore.hasReadOnlyStorage() || (!isSnowflake && !isBigQuery) || !path) {
      return null;
    }

    return (
      <div className="tw-flex tw-justify-between tw-text-base tw-font-medium">
        <span>{isSnowflake ? 'Schema' : 'Dataset Name'}</span>
        <span className="tw-flex tw-flex-row tw-items-center tw-gap-4">
          <BackendEntityInfoTooltip isSnowflakeBackend={isSnowflake} />
          <Clipboard
            tooltipPlacement="top"
            className="font-normal overflow-break-anywhere"
            label={path}
            text={path}
            showIcon={false}
          />
        </span>
      </div>
    );
  }

  renderOverview() {
    const isExternal = this.props.bucket.get('hasExternalSchema');
    const isLinked = this.props.bucket.has('sourceBucket');

    return (
      <div className="box info-row">
        <div className="info-row-section horizontal more-space">
          <div className="first-line font-medium flex-container">
            <span>ID</span>
            <Clipboard
              tooltipPlacement="top"
              className="overflow-break-anywhere"
              label={this.props.bucket.get('id')}
              text={this.props.bucket.get('id')}
              showIcon={false}
            />
          </div>
          <div className="first-line font-medium flex-container">
            <span>Name</span>
            <Clipboard
              tooltipPlacement="top"
              className="font-normal overflow-break-anywhere"
              label={this.props.bucket.get('displayName')}
              text={this.props.bucket.get('displayName')}
              showIcon={false}
            />
          </div>
          <BackendRow backend={this.props.bucket.get('backend')} />
          {this.renderSourceBucket()}
          {this.renderSchema()}
        </div>
        <div className="info-row-section horizontal">
          <p className="first-line f-14 font-medium flex-container">
            <span>Stage</span>
            <span className="font-normal text-muted">
              {this.props.bucket.get('stage').toUpperCase()}
            </span>
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Created</span>
            <CreatedDate
              className="font-normal text-muted"
              createdTime={this.props.bucket.get('created')}
            />
          </p>
          {!isExternal && (
            <p className="first-line f-14 font-medium flex-container">
              <span>Last change</span>
              <CreatedDate
                className="font-normal text-muted"
                createdTime={
                  isLinked
                    ? this.props.bucket.getIn(['sourceBucket', 'lastChangeDate'])
                    : this.props.bucket.get('lastChangeDate')
                }
              />
            </p>
          )}
          <p className="first-line f-14 font-medium flex-container">
            <span>Row count</span>
            <span className="font-normal text-muted">
              {isExternal ? (
                <NotAvailable entity="external buckets" />
              ) : (
                <RowsCount
                  count={
                    isLinked
                      ? this.props.bucket.getIn(['sourceBucket', 'rowsCount'])
                      : this.props.bucket.get('rowsCount')
                  }
                />
              )}
            </span>
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Data size</span>
            <span className="font-normal text-muted">
              {isExternal ? (
                <NotAvailable entity="external buckets" />
              ) : (
                <FileSize
                  size={
                    isLinked
                      ? this.props.bucket.getIn(['sourceBucket', 'dataSizeBytes'])
                      : this.props.bucket.get('dataSizeBytes')
                  }
                />
              )}
            </span>
          </p>
        </div>
      </div>
    );
  }

  renderSourceBucket() {
    const source = this.props.bucket.get('sourceBucket');

    if (source) {
      return (
        <p className="first-line flex-container">
          <span className="font-medium">Source bucket</span>
          <span className="font-normal text-muted">
            {canLoadSharedBuckets(this.props.sapiToken) ? (
              <RouterLink
                forceProduction
                to={SHARED_WITH_YOU_ROUTES.shared_with_you_detail(
                  source.getIn(['project', 'id']),
                  source.get('id'),
                )}
                useReactRouterLink
              >
                {source.get('displayName')}
              </RouterLink>
            ) : (
              source.get('displayName')
            )}
          </span>
        </p>
      );
    }

    if (!this.props.bucket.get('sharing') && !isSharingAvailable(this.props.sapiToken)) {
      return null;
    }

    if (
      this.props.bucket.get('hasExternalSchema') === true &&
      this.props.bucket.get('backend') !== backends.SNOWFLAKE
    ) {
      return null;
    }

    return (
      <p className="first-line flex-container">
        <span className="font-medium">Sharing</span>
        <span>{this.renderSharingInfo()}</span>
      </p>
    );
  }

  renderSharingInfo() {
    if (this.props.bucket.get('sharing')) {
      return (
        <RouterLink
          forceProduction
          to={SHARED_FROM_PROJECT_ROUTES.shared_from_project_detail(this.props.bucket.get('id'))}
          className="btn btn-link btn-link-inline partly-underlined"
          useReactRouterLink
        >
          <span className="underline">Enabled</span>{' '}
          <span className="text-muted">(show detail)</span>
        </RouterLink>
      );
    }

    return (
      <ShareBucketSimple
        bucket={this.props.bucket}
        canShare={canManageSharedBucket(this.props.sapiToken, this.props.hasPayAsYouGo)}
        availableUsersOptions={this.props.availableUsersOptions}
        availableProjectsOptions={this.props.availableProjectsOptions}
      />
    );
  }

  renderDescription() {
    return (
      <StorageDescription
        objectType={ObjectTypes.BUCKET}
        objectId={this.props.bucket.get('id')}
        metadata={this.props.bucket.get('metadata', List())}
        metadataKey={MetadataKeys.DESCRIPTION}
        readOnly={!this.props.canManageBuckets}
        isDevBucket={isCreatedInDevBranch(this.props.bucket)}
      />
    );
  }
}

BucketOverview.propTypes = {
  components: PropTypes.instanceOf(Map).isRequired,
  configurations: PropTypes.instanceOf(Map).isRequired,
  allowedTransformationComponents: PropTypes.instanceOf(Map).isRequired,
  sandboxes: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  bucket: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
  allTables: PropTypes.instanceOf(Map).isRequired,
  pendingActions: PropTypes.instanceOf(Map).isRequired,
  deletingTables: PropTypes.instanceOf(Map).isRequired,
  truncatingTables: PropTypes.instanceOf(Map).isRequired,
  exportingTables: PropTypes.instanceOf(Map).isRequired,
  createSnapshotsTables: PropTypes.instanceOf(Map).isRequired,
  isDevModeActive: PropTypes.bool.isRequired,
  hasPayAsYouGo: PropTypes.bool.isRequired,
  canExportTable: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
  canWriteBucket: PropTypes.bool.isRequired,
  urlTemplates: PropTypes.instanceOf(Map).isRequired,
  availableUsersOptions: PropTypes.array.isRequired,
  availableProjectsOptions: PropTypes.array.isRequired,
  hasSnowflakePartnerConnectLimited: PropTypes.bool.isRequired,
  isBucketBrowser: PropTypes.bool,
};

export default BucketOverview;
