import React from 'react';
import { FormGroup } from 'react-bootstrap';

import { Alert } from '@keboola/design';

import Checkbox from '@/react/common/Checkbox';
import ReadOnlyDocumentationLink from './ReadOnlyDocumentationLink';

const ReadOnlyStorageCheckbox = (props: {
  value: boolean;
  onChange: (checked: boolean) => void;
  isDisabled: boolean;
  alertClassName?: string;
}) => {
  return (
    <>
      <FormGroup>
        <Checkbox checked={props.value} onChange={props.onChange} disabled={props.isDisabled}>
          Grant read-only access to all Project data
        </Checkbox>
      </FormGroup>
      <Alert className={props.alertClassName} title="Read-Only Access">
        Enabling this option grants read-only access to all project data without a need to define
        tables in input mapping. <ReadOnlyDocumentationLink />
      </Alert>
    </>
  );
};

export default ReadOnlyStorageCheckbox;
