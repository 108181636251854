import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { IconButton, Tooltip } from '@keboola/design';

import StorageActionCreators from '@/modules/components/StorageActionCreators';
import NewLineToBr from '@/react/common/NewLineToBr';
import UpdateDevBranchDescriptionModal from './UpdateDevBranchDescriptionModal';

class DevBranchDescription extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.startEdit = this.startEdit.bind(this);
  }

  render() {
    return (
      <>
        <div className="box box-panel box-branch-description">
          <div className="box-header with-border">
            <h2 className="box-title">Development Branch Notes</h2>
            {!this.props.readOnly && (
              <Tooltip placement="top" tooltip="Edit notes">
                <IconButton variant="outline" onClick={this.startEdit} icon="pen" />
              </Tooltip>
            )}
          </div>
          {!!this.props.branch.get('description') ? (
            <div className="box-panel-content text-muted font-mono">
              <NewLineToBr text={this.props.branch.get('description')} />
            </div>
          ) : (
            <div className="box-panel-content text-center p-2 mt-2">
              <div className="mt-2">
                <h3 className="mt-2">Let&apos;s put some notes in!</h3>
                <p className="text-muted mb-1">
                  This place is for you and your teammates to stay informed about the changes you
                  did in this branch.
                </p>
              </div>
            </div>
          )}
        </div>
        <UpdateDevBranchDescriptionModal
          branch={this.props.branch}
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          onSubmit={StorageActionCreators.updateDevBranch}
        />
      </>
    );
  }

  startEdit() {
    this.setState({
      showModal: true,
      description: this.props.branch.get('description'),
    });
  }
}

DevBranchDescription.propTypes = {
  branch: PropTypes.instanceOf(Map).isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default DevBranchDescription;
