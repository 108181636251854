import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from '@/react/common/Loader';
import string from '@/utils/string';

type Props = {
  entity: string;
  isLoading?: boolean;
  isReadOnly: boolean;
  isDisabled: boolean;
  onAddNew: () => void;
  helperText: React.ReactNode;
  children: React.ReactNode;
};

const KeyValueBox = ({
  entity,
  isLoading = false,
  isReadOnly,
  isDisabled,
  onAddNew,
  helperText,
  children,
}: Props) => {
  return (
    <div className="box box-genericVariablesUI pbp-2">
      <div className="box-header smaller ptp-2 prp-1 plp-6">
        <h2 className="box-title line-height-24 tw-capitalize">
          {string.pluralize(2, entity)} {isLoading && <Loader className="icon-addon-left" />}
        </h2>
        {!isReadOnly && (
          <Button
            bsStyle="link"
            className="header-inline-button color-success"
            onClick={onAddNew}
            disabled={isDisabled}
          >
            <FontAwesomeIcon icon="plus" className="icon-addon-right" />
            New {entity}
          </Button>
        )}
      </div>
      <div className="box-content ptp-2 prp-6 pbp-2 plp-6">
        <p className="tw-text-sm tw-text-neutral-500">{helperText}</p>
        {children}
      </div>
    </div>
  );
};
export default KeyValueBox;
