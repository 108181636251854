import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import { cn } from '@keboola/design';

import CollapsibleBox from '@/react/common/CollapsibleBox';

class Step extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    isConfigured: PropTypes.bool,
    isProcessing: PropTypes.bool,
    isDisabled: PropTypes.bool,
    status: PropTypes.node,
    headerButton: PropTypes.node,
    headerClassName: PropTypes.string,
    paddingless: PropTypes.bool,
    keepOpenAfterConfigured: PropTypes.bool,
    onStartProcessing: PropTypes.func,
  };

  static defaultProps = {
    isConfigured: false,
    isProcessing: false,
    isDisabled: false,
    paddingless: false,
    keepOpenAfterConfigured: false,
    onStartProcessing: _.noop,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isProcessing && this.props.isProcessing) {
      this.props.onStartProcessing();
    }
  }

  render() {
    return (
      <CollapsibleBox
        title={this.props.title}
        entity="step"
        isDisabled={this.props.isDisabled}
        defaultOpen={!this.props.isConfigured || this.props.keepOpenAfterConfigured}
        showAdditionalActionsWhenDisabled
        additionalActions={() => (
          <div className="flex-container">
            {this.props.status}
            {this.props.headerButton && (
              <div className="ml-auto">
                {React.cloneElement(this.props.headerButton, {
                  isConfigured: this.props.isConfigured,
                })}
              </div>
            )}
          </div>
        )}
        childrenClassName={cn({
          'p-0': this.props.paddingless,
        })}
      >
        {!this.props.isDisabled &&
          React.cloneElement(this.props.children, {
            isConfigured: this.props.isConfigured,
          })}
      </CollapsibleBox>
    );
  }
}

export default Step;
