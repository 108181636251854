import React from 'react';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { KEBOOLA_EX_MONGODB as componentId } from '@/constants/componentIds';
import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import DeleteConfigurationRowButton from '@/modules/configurations/react/components/DeleteConfigurationRowButton';
import QueryEditor from '@/modules/ex-mongodb/react/components/QueryEditor';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const Detail = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      RoutesStore,
      ConfigurationRowsStore,
      InstalledComponentsStore,
    ),
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const rowId = RoutesStore.getCurrentRouteParam('row');
    const editing =
      ConfigurationRowsStore.getEditingConfiguration(componentId, configId, rowId) || Map();

    return {
      rowId,
      configId,
      editing,
      query: editing.get('parameters', Map()),
      row: ConfigurationRowsStore.get(componentId, configId, rowId),
      pendingActions: ConfigurationRowsStore.getPendingActions(componentId, configId, rowId),
      configuration: InstalledComponentsStore.getConfig(componentId, configId),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  render() {
    return (
      <GenericConfigBody
        key={`${componentId}-${this.state.configId}-${this.state.rowId}`}
        componentId={componentId}
        configId={this.state.configId}
        rowId={this.state.rowId}
        sidebarProps={{
          delete: (
            <DeleteConfigurationRowButton
              onClick={this.handleDeleteRow}
              isPending={this.state.pendingActions.has('delete')}
              mode="link"
            />
          ),
        }}
      >
        <div className="box-separator">
          <QueryEditor
            isRow
            componentId={componentId}
            configId={this.state.configId}
            query={this.state.query}
            configState={this.state.row.get('state', Map())}
            onChange={this.handleChange}
            onResetState={this.handleResetState}
            readOnly={this.state.readOnly}
          />
        </div>
      </GenericConfigBody>
    );
  },

  handleChange(parameters: Map<string, any>) {
    return ConfigurationRowsActionCreators.updateConfiguration(
      componentId,
      this.state.configId,
      this.state.rowId,
      this.state.editing.set('parameters', parameters),
    );
  },

  handleDeleteRow() {
    ConfigurationRowsActionCreators.delete(
      componentId,
      this.state.configId,
      this.state.rowId,
      true,
      `${this.state.row.get('name')} deleted`,
    );
  },

  handleResetState() {
    return ConfigurationRowsActionCreators.clearComponentState(
      componentId,
      this.state.configId,
      this.state.rowId,
    );
  },
});

export default Detail;
