import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Promise from 'bluebird';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import installedComponentsApi from '@/modules/components/InstalledComponentsApi';
import configurationRestoredNotification from '@/modules/components/react/components/notifications/configurationRestored';
import type { Configuration } from '@/modules/trash/types';
import ApplicationStore from '@/stores/ApplicationStore';
import string from '@/utils/string';
import { RQ_KEYS } from './keys';

const restoreConfigurations = (
  componentId: string,
  configsToRestore: Configuration[],
  selectedSize: number,
) => {
  return Promise.map(
    configsToRestore,
    (config) => installedComponentsApi.restoreConfiguration(componentId, config.id),
    { concurrency: 3 },
  ).then(() => {
    if (selectedSize === 1) {
      return ApplicationActionCreators.sendNotification({
        type: 'success',
        message: configurationRestoredNotification(
          componentId,
          configsToRestore[0].id,
          configsToRestore[0].name,
          ApplicationStore.hasFlows(),
        ),
      });
    }

    return ApplicationActionCreators.sendNotification({
      type: 'success',
      message: () => (
        <>
          <b>{selectedSize}</b> {string.pluralize(selectedSize, 'configuration')} have been
          restored.
        </>
      ),
    });
  });
};

export const useRestoreMultipleConfigurations = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      componentId,
      configsToRestore,
      selectedSize,
    }: {
      componentId: string;
      configsToRestore: Configuration[];
      selectedSize: number;
    }) => restoreConfigurations(componentId, configsToRestore, selectedSize),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: RQ_KEYS.TRASH });
    },
  });
};
