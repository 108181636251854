import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import { Button } from '@keboola/design';

import { canManageSharedBucket } from '@/modules/admin/privileges';
import EditSharingModal from './EditSharingModal';
import ForceUnlinkModal from './ForceUnlinkModal';

class EditSharingButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditModal: false,
      showUnlinkModal: false,
      disableAnimation: false,
    };
  }

  render() {
    if (!canManageSharedBucket(this.props.sapiToken)) {
      return null;
    }

    return (
      <>
        <Button
          variant="outline"
          size="small"
          onClick={() => {
            this.setState({
              showEditModal: true,
            });
          }}
        >
          Edit
        </Button>
        <EditSharingModal
          disableAnimation={this.state.disableAnimation}
          bucket={this.props.bucket}
          sapiToken={this.props.sapiToken}
          availableUsersOptions={this.props.availableUsersOptions}
          availableProjectsOptions={this.props.availableProjectsOptions}
          show={this.state.showEditModal}
          onSaveFn={(showLinkingWarning) => {
            if (showLinkingWarning && this.props.bucket.get('linkedBy', List()).count() > 0) {
              this.setState({ disableAnimation: true, showUnlinkModal: true });

              setTimeout(() => {
                this.setState({ disableAnimation: false });
              }, 400);
            }
          }}
          closeModalFn={() => {
            this.setState({ showEditModal: false });
          }}
        />
        <ForceUnlinkModal
          disableAnimation={this.state.disableAnimation}
          show={this.state.showUnlinkModal}
          closeModalFn={() => {
            this.setState({ showUnlinkModal: false });
          }}
          allowCancel
          showUnsharedInfo
          bucket={this.props.bucket}
          urlTemplates={this.props.urlTemplates}
          sapiToken={this.props.sapiToken}
        />
      </>
    );
  }
}

EditSharingButton.propTypes = {
  bucket: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  availableUsersOptions: PropTypes.array.isRequired,
  availableProjectsOptions: PropTypes.array.isRequired,
};

export default EditSharingButton;
