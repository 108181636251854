import Promise from 'bluebird';

import { uiModes } from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import VersionActionCreators from '@/modules/components/VersionsActionCreators';
import oldJobsActionCreators from '@/modules/jobs/ActionCreators';
import jobsActionCreators from '@/modules/queue/actions';

const enableSimplifiedUi = (componentId, configId, configData) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    componentId,
    configId,
    {
      configuration: JSON.stringify(configData.setIn(['runtime', 'uiMode'], uiModes.SIMPLE).toJS()),
    },
    'Enable simplified mode',
  );
};

const disableSimplifiedUi = (componentId, configId, configData) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    componentId,
    configId,
    {
      configuration: JSON.stringify(
        configData.setIn(['runtime', 'uiMode'], uiModes.DEFAULT).toJS(),
      ),
    },
    'Disable simplified mode',
  );
};

const runComponent = (componentId, configId, hasNewQueue) => {
  return InstalledComponentsActionCreators.runComponent({
    method: 'run',
    component: componentId,
    data: { config: configId },
  })
    .delay(2000)
    .then(() => {
      return Promise.all([
        hasNewQueue
          ? jobsActionCreators.loadComponentConfigurationLatestJobs(componentId, configId)
          : oldJobsActionCreators.loadComponentConfigurationLatestJobs(componentId, configId),
        VersionActionCreators.loadVersionsForce(componentId, configId),
        InstalledComponentsActionCreators.loadComponentConfigDataForce(componentId, configId),
      ]);
    });
};

export { enableSimplifiedUi, disableSimplifiedUi, runComponent };
