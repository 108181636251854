import React from 'react';
import { FormGroup } from 'react-bootstrap';

import { HelpBlock } from '@keboola/design';

import type { PathValueProps } from '@/modules/ex-ftp/SourcePath';
import Checkbox from '@/react/common/Checkbox';

type Props = {
  addFilenameColumn: boolean;
  addRowNumberColumn: boolean;
  disabled: boolean;
  onChange: (data: Partial<PathValueProps>) => void;
};

class AuditBox extends React.Component<Props> {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Audit</h3>
        </div>
        <div className="box-content">
          <FormGroup>
            <div className="col-xs-8 col-xs-offset-4">
              <Checkbox
                checked={this.props.addFilenameColumn}
                onChange={(checked) => this.props.onChange({ addFilenameColumn: checked })}
                disabled={this.props.disabled}
              >
                Filename
              </Checkbox>
              <HelpBlock className="tw-mt-1">
                Add an <code>ftp_filename</code> column that will store the processed file name.
              </HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-8 col-xs-offset-4">
              <Checkbox
                checked={this.props.addRowNumberColumn}
                onChange={(checked) => this.props.onChange({ addRowNumberColumn: checked })}
                disabled={this.props.disabled}
              >
                Row Number
              </Checkbox>
              <HelpBlock className="tw-mt-1">
                Add an <code>ftp_row_number</code> column that will store the row number from the
                processed file.
              </HelpBlock>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  }
}

export default AuditBox;
