import { DataScienceClient } from './clients/dataScience';
import { ManagementClient } from './clients/management';
import { StorageClient } from './clients/storage';
import type { StackInfo } from './clients/storage/types';
import type { ClientInitOptions } from './clients/types';
import { VaultClient } from './clients/vault';
import { ErrorMessage } from './constants';
import { assertIsDefined, getServiceMap } from './utils';

export type KeboolaClientOptions = {
  token: {
    storageApi: string;
    managementApi?: string;
  };
} & Omit<ClientInitOptions, 'token'>;

function assertIsClientDefined<T>(value: T | null | undefined): asserts value is T {
  assertIsDefined(value, ErrorMessage.UNINITIALIZED_CLIENT);
}

export class KeboolaClient {
  private _stackInfo: StackInfo | null = null;
  private _storage: StorageClient | null = null;
  private _vault: VaultClient | null = null;
  private _dataScience: DataScienceClient | null = null;
  private _management: ManagementClient | null = null;

  get storage() {
    assertIsClientDefined(this._storage);
    return this._storage;
  }

  get vault() {
    assertIsClientDefined(this._vault);
    return this._vault;
  }

  get stackInfo() {
    assertIsClientDefined(this._stackInfo);
    return this._stackInfo;
  }

  get dataScience() {
    assertIsClientDefined(this._dataScience);
    return this._dataScience;
  }

  get management() {
    assertIsClientDefined(this._management);
    return this._management;
  }

  /**
   * Initialize the API clients provided by Keboola.
   * @param options
   */
  async init(options: KeboolaClientOptions) {
    const storageClient = new StorageClient({
      baseUrl: options.baseUrl,
      token: options.token.storageApi,
      callbacks: options.callbacks,
    });

    this._management = new ManagementClient({
      baseUrl: options.baseUrl,
      token: options.token.managementApi,
      callbacks: options.callbacks,
    });

    const stackInfo = await storageClient.getStackInfo({ exclude: 'componentDetails' });
    const serviceMap = getServiceMap(stackInfo.services);

    this._vault = new VaultClient({
      baseUrl: serviceMap['vault'],
      token: options.token.storageApi,
      callbacks: options.callbacks,
    });

    this._dataScience = new DataScienceClient({
      baseUrl: serviceMap['data-science'],
      token: options.token.storageApi,
      callbacks: options.callbacks,
    });

    this._stackInfo = stackInfo;
    this._storage = storageClient;
  }
}
