import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { Button, Tooltip } from '@keboola/design';

import { canRunJob } from '@/modules/admin/privileges';
import { JOBS_STATUS } from '@/modules/queue/constants';
import string from '@/utils/string';
import Confirm from './Confirm';
import Loader from './Loader';

type Props = {
  job?: Map<string, any>;
  sapiToken: Map<string, any>;
  isTerminating: boolean;
  onTerminate: () => Promise<void>;
  simple?: boolean;
  isFlow?: boolean;
};

const canBeTerminated = (sapiToken: Map<string, any>, job?: Map<string, any>) => {
  if (!job || !canRunJob(sapiToken)) {
    return false;
  }

  return [JOBS_STATUS.CREATED, JOBS_STATUS.WAITING, JOBS_STATUS.PROCESSING].includes(
    job.get('status'),
  );
};

const JobTerminateButton = ({
  job,
  sapiToken,
  isTerminating,
  onTerminate,
  isFlow,
  simple,
}: Props) => {
  if (!canBeTerminated(sapiToken, job)) {
    return null;
  }

  const name = isFlow ? 'flow' : 'job';

  return (
    <Confirm
      title={`Terminate ${string.capitalize(name)}`}
      text={`Are you sure you want to terminate the ${name} ${job?.get('id')}?`}
      buttonLabel="Terminate"
      onConfirm={onTerminate}
    >
      {simple ? (
        <Tooltip tooltip={`Terminate ${string.capitalize(name)}`} placement="top">
          <Button variant="outline" disabled={isTerminating}>
            {isTerminating ? <Loader /> : <FontAwesomeIcon icon="xmark" fixedWidth />}
          </Button>
        </Tooltip>
      ) : (
        <Button variant="outline" disabled={isTerminating}>
          {isTerminating ? (
            <>
              <Loader />
              Terminating {name}...
            </>
          ) : (
            <>
              <FontAwesomeIcon icon="xmark" fixedWidth />
              Terminate {name}
            </>
          )}
        </Button>
      )}
    </Confirm>
  );
};

export default JobTerminateButton;
