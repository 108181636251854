import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from '@keboola/design';

const LinkWithWindowIcon = (props: {
  href?: string;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
}) => {
  return (
    <Link
      href={props.href}
      onClick={props.onClick}
      className="btn btn-link btn-link-inline text-muted !tw-flex tw-items-center tw-justify-end tw-break-all"
    >
      {props.children}
      <FontAwesomeIcon className="tw-ml-1 tw-py-0.5 tw-pl-1" icon="arrow-up-right-from-square" />
    </Link>
  );
};

export default LinkWithWindowIcon;
