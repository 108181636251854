import type { GenericFetchClient } from '../storageClient';

import type { File, GetFilesQuery } from './types';

export class Files {
  constructor(private client: GenericFetchClient) {}

  async getFiles(query?: GetFilesQuery, signal?: AbortSignal) {
    const { data } = await this.client.get<File[], never, GetFilesQuery>(
      '/files',
      { query },
      { signal },
    );

    return data;
  }
}
