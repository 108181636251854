import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Map } from 'immutable';

import SandboxActions from '@/modules/sandboxes/Actions';
import { CONTAINER_BASED, SUPPORT_SIZES } from '@/modules/sandboxes/Constants';
import Confirm from '@/react/common/Confirm';
import ConfirmMenuItem from '@/react/common/ConfirmMenuItem';
import Loader from '@/react/common/Loader';
import WorkspaceExpirationControl from './WorkspaceExpirationControl';
import WorkspaceSizeControl from './WorkspaceSizeControl';

const RestoreWorkspace = (props: {
  mode: 'sidebar' | 'menuitem';
  config: Map<string, any>;
  configData: Map<string, any>;
  sandbox: Map<string, any>;
  isRestoring: boolean;
  hasPayAsYouGo: boolean;
}) => {
  const [size, setSize] = React.useState(props.sandbox.get('size'));
  const [expiration, setExpiration] = React.useState(props.sandbox.get('expirationAfterHours'));
  const type = props.sandbox.get('type');

  const restoreSandbox = () => {
    let params = Map<string, any>();

    if (SUPPORT_SIZES.includes(type)) {
      params = params.set('size', size).set('type', type);
    }

    if (CONTAINER_BASED.includes(type)) {
      params = params.set('expirationAfterHours', expiration);
    }

    return SandboxActions.restoreSandbox(
      props.sandbox.get('id'),
      props.config.get('id'),
      props.configData.getIn(['storage', 'input'], Map()).toJS(),
      params,
    );
  };

  const renderBody = () => {
    if (!CONTAINER_BASED.includes(type)) {
      return (
        <p>
          Are you sure you want to restore the worspace <strong>{props.config.get('name')}</strong>
          now?
        </p>
      );
    }

    return (
      <>
        <WorkspaceSizeControl
          type={type}
          value={size}
          onChange={setSize}
          isDisabled={props.isRestoring}
          hasPayAsYouGo={props.hasPayAsYouGo}
        />
        <WorkspaceExpirationControl
          type={type}
          value={expiration}
          onChange={setExpiration}
          isDisabled={props.isRestoring}
        />
      </>
    );
  };

  if (props.mode === 'sidebar') {
    return (
      <Confirm
        icon="arrow-rotate-left"
        title="Restore Workspace"
        buttonType="primary"
        buttonLabel="Restore"
        text={renderBody()}
        onConfirm={restoreSandbox}
        isDisabled={props.isRestoring}
        childrenRootElement="a"
      >
        {props.isRestoring ? <Loader /> : <FontAwesomeIcon icon="arrow-rotate-left" fixedWidth />}
        Restore
      </Confirm>
    );
  }

  return (
    <ConfirmMenuItem
      icon="arrow-rotate-left"
      title="Restore Workspace"
      buttonType="primary"
      buttonLabel="Restore"
      text={renderBody()}
      isDisabled={props.isRestoring}
      onConfirm={restoreSandbox}
    >
      <FontAwesomeIcon icon="arrow-rotate-left" fixedWidth />
      Restore workspace
    </ConfirmMenuItem>
  );
};

export default RestoreWorkspace;
