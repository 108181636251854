import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import StorageApi from '@/modules/components/StorageApi';
import { pullTable } from '@/modules/storage/actions';
import { RowActionMenuItem } from '@/react/common';
import ConfirmModal from '@/react/common/ConfirmModal';
import Loader from '@/react/common/Loader';

const CloneToCurrentBranch = (props: {
  pullingTable: boolean;
  table: Map<string, any>;
  currentDevBranch: Map<string, any>;
}) => {
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [canClone, setCanClone] = React.useState(false);

  const renderBody = () => {
    if (loading) {
      return (
        <p>
          <Loader /> Loading production table...
        </p>
      );
    }

    if (!canClone) {
      return <p>Production table does not exist.</p>;
    }

    return (
      <>
        Are you sure you want to clone the table{' '}
        <span className="font-medium">{props.table.get('name')}</span> to branch{' '}
        <span className="font-medium">{props.currentDevBranch.get('name')}</span>? The existing
        branch table will be overwritten.
      </>
    );
  };

  return (
    <>
      <RowActionMenuItem onSelect={() => setShow(true)}>
        {props.pullingTable ? (
          <>
            <Loader />
            Clonning table...
          </>
        ) : (
          <>
            <FontAwesomeIcon fixedWidth icon="clone" />
            Clone to Current Branch
          </>
        )}
      </RowActionMenuItem>
      <ConfirmModal
        show={show}
        onHide={() => setShow(false)}
        icon="clone"
        iconColor="orange"
        title="Clone to Current Branch"
        text={renderBody()}
        buttonLabel="Clone"
        isDisabled={loading || !canClone}
        onConfirm={() => pullTable(props.table.get('id'))}
        onEnter={() => {
          setLoading(true);
          StorageApi.getProductionTable(props.table.get('id'))
            .then(() => setCanClone(true))
            .catch(() => setCanClone(false))
            .finally(() => setLoading(false));
        }}
      />
    </>
  );
};

export default CloneToCurrentBranch;
