import React from 'react';

import { Button } from '@keboola/design';

const IntentConfirmation = ({ onClick }: { onClick: (confirm?: string) => void }) => {
  return (
    <div className="tw-flex tw-flex-wrap tw-gap-2">
      <Button onClick={() => onClick('yes')}>Accept</Button>
      <Button variant="outline" onClick={() => onClick('no')}>
        Continue chat
      </Button>
    </div>
  );
};

export default IntentConfirmation;
