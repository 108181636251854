import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { Tooltip } from '@keboola/design';

import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { RowActionMenuItem } from '@/react/common';

type Props = {
  onClick: () => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
} & ({ mode: 'sidebar' } | { mode: 'menuitem'; component: Map<string, any>; hasFlows: boolean });

const MoveActionButton = (props: Props) => {
  if (props.mode === 'menuitem') {
    return (
      <RowActionMenuItem onSelect={props.onClick} onKeyDown={props.onKeyDown}>
        <FontAwesomeIcon fixedWidth icon="folder-arrow-up" />
        Move{' '}
        {props.component.get('type') === componentTypes.TRANSFORMATION
          ? 'transformation'
          : props.component.get('id') === KEBOOLA_ORCHESTRATOR
            ? props.hasFlows
              ? 'flow'
              : 'orchestration'
            : props.component.get('id') === KEBOOLA_DATA_APPS
              ? 'data app'
              : 'configuration'}
      </RowActionMenuItem>
    );
  }

  return (
    <Tooltip placement="top" tooltip="Move Selected">
      <Button bsStyle="link" className="btn-link-inline btn-link-muted" onClick={props.onClick}>
        <FontAwesomeIcon icon="folder-arrow-up" />
      </Button>
    </Tooltip>
  );
};

export default MoveActionButton;
