import React from 'react';

import { Alert } from '@keboola/design';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ReadOnlyDocumentationLink from '@/modules/sandboxes/components/ReadOnlyDocumentationLink';
import WorkspacesStore from '@/modules/workspaces/WorkspacesStore';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';

const ReadOnlyStorageAlert = () => {
  const state = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getCurrentRouteParam('config');
      const configData = InstalledComponentsStore.getConfigData(componentId, configId);
      const workspaceCredentials = WorkspacesStore.getConfigurationWorkspaceByConnectionUser(
        componentId,
        configId,
        configData.getIn(['parameters', 'db', 'user']),
      );

      return {
        readOnlyStorageAccess: workspaceCredentials.get('readOnlyStorageAccess', false),
      };
    },
    [],
    [RoutesStore, InstalledComponentsStore],
  );

  if (!state.readOnlyStorageAccess) {
    return null;
  }

  return (
    <div className="container tw-mt-5">
      <Alert>
        <span className="font-medium">Read-Only Access</span> to all project data is granted for the
        workspace used in this configuration. Be cautious when sharing it.{' '}
        <ReadOnlyDocumentationLink />
      </Alert>
    </div>
  );
};

export default ReadOnlyStorageAlert;
