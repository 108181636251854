import {
  type ApiResponse,
  createFetchClient,
  type CreateFetchClientOptions,
  type FetchClientOptions,
  type HttpMethod,
} from './createFetchClient';
import type { OpenapiParametersType, OpenapiReturnType } from './types';

export type OpenapiPaths<Paths> = {
  [P in keyof Paths]: {
    [M in HttpMethod]?: unknown;
  };
};

type PathsWithMethod<Paths extends OpenapiPaths<Paths>, PathnameMethod extends HttpMethod> = {
  [Pathname in keyof Paths]: Paths[Pathname] extends {
    [K in PathnameMethod]: any;
  }
    ? Pathname
    : never;
}[keyof Paths];

type FetchMethod<Paths extends OpenapiPaths<Paths>, Method extends HttpMethod> = <
  Path extends PathsWithMethod<Paths, Method>,
>(
  url: Path,
  params: OpenapiParametersType<Paths[Path][Method]>,
  options?: FetchClientOptions,
) => Promise<ApiResponse<OpenapiReturnType<Paths[Path][Method]>>>;

type FetchClient<Paths extends OpenapiPaths<Paths>> = {
  get: FetchMethod<Paths, 'get'>;
  post: FetchMethod<Paths, 'post'>;
  put: FetchMethod<Paths, 'put'>;
  path: FetchMethod<Paths, 'patch'>;
  delete: FetchMethod<Paths, 'delete'>;
};

export const createOpenapiFetchClient = <Paths extends OpenapiPaths<Paths>>(
  defaultOptions: CreateFetchClientOptions,
) => createFetchClient(defaultOptions) as FetchClient<Paths>;
