import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { findTemplateInstanceIdFromMetadata } from '@/modules/components/helpers';
import { routeNames as templatesRouteNames } from '@/modules/templates/constants';
import { RouterLink } from '@/react/common';
import { UsedInFlowsModal } from '@/react/common/FlowsListModal';
import OnClickEventPropagationStopper from '@/react/common/OnClickEventPropagationStopper';

const UsedInCell = (props: {
  config: Map<string, any>;
  component: Map<string, any>;
  componentsMetadata: Map<string, any>;
  flows: Map<string, any>;
}) => {
  const templateInstanceId = findTemplateInstanceIdFromMetadata(
    props.component.get('id'),
    props.config.get('id'),
    props.componentsMetadata,
  );

  return (
    <OnClickEventPropagationStopper>
      <UsedInFlowsModal
        config={props.config}
        component={props.component}
        flows={props.flows}
        hidePlaceholder={!templateInstanceId}
      />
      {templateInstanceId && (
        <div className="text-muted">
          <RouterLink
            to={templatesRouteNames.INSTANCE_DETAIL}
            params={{ instanceId: templateInstanceId }}
          >
            <FontAwesomeIcon icon="book-open" className="f-16 btn-icon text-muted" />
            Template
          </RouterLink>
        </div>
      )}
    </OnClickEventPropagationStopper>
  );
};

const MemoizedUsedInCell = React.memo(UsedInCell);

export default MemoizedUsedInCell;
