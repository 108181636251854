import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type Map } from 'immutable';

import { cn } from '@keboola/design';

import NativeTypesLabel from '@/modules/storage/components/NativeTypesLabel';
import { tableName } from '@/modules/storage/helpers';
import { BucketLabels } from './BucketLabels';
import BucketStageLabel from './BucketStageLabel';
import DevBranchLabel from './DevBranchLabel';
import ExternalTableLabel from './ExternalTableLabel';
import MarkedText from './MarkedText';

const bucketLabel = (
  bucket: Map<string, any>,
  options?: { showBucketIcon?: boolean; hideStage?: boolean },
) => {
  return (inputString?: string) => {
    return (
      <div className="flex-container flex-start">
        {options?.showBucketIcon && (
          <FontAwesomeIcon icon="folder" className="color-base icon-addon-right" />
        )}
        {!options?.hideStage && <BucketStageLabel placement="left" stage={bucket.get('stage')} />}
        <DevBranchLabel bucket={bucket} />
        <MarkedText source={bucket.get('displayName')} mark={inputString} />
        <BucketLabels bucket={bucket} withSharedLabel={false} withStageLabel={false} />
      </div>
    );
  };
};

const tableLabel = (
  table: Map<string, any>,
  options?: {
    showTableIcon?: boolean;
    hideStage?: boolean;
    alreadyAddedSuffix?: boolean;
    className?: string;
  },
) => {
  return (inputString?: string) => {
    return (
      <div className={cn('flex-container flex-start', options?.className)}>
        {options?.showTableIcon && (
          <FontAwesomeIcon icon="table" className="text-muted icon-addon-right" />
        )}
        {!options?.hideStage && (
          <BucketStageLabel placement="left" stage={table.getIn(['bucket', 'stage'])} />
        )}
        <DevBranchLabel bucket={table.get('bucket')} />
        <MarkedText source={tableName(table)} mark={inputString} />
        <NativeTypesLabel isTyped={table.get('isTyped')} />
        <ExternalTableLabel
          tableType={table.get('tableType')}
          hasExternalSchema={table.getIn(['bucket', 'hasExternalSchema'])}
        />
        {options?.alreadyAddedSuffix && (
          <span className="ml-auto f-12 text-muted">Already Added</span>
        )}
      </div>
    );
  };
};

const folderLabel = (name: string, folder?: string, forceInputString?: string) => {
  return (inputString?: string) => {
    return (
      <>
        <MarkedText source={name} mark={forceInputString ?? inputString} />
        {folder && (
          <div className="f-11 text-muted">
            <FontAwesomeIcon icon="folder" fixedWidth />{' '}
            <MarkedText source={folder} mark={forceInputString ?? inputString} />
          </div>
        )}
      </>
    );
  };
};

export { bucketLabel, tableLabel, folderLabel };
