import React from 'react';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';
import type { Map } from 'immutable';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';

type Props = {
  show: boolean;
  phase: Map<string, any>;
  onHide: () => void;
  onSubmit: (id: string, name: string, description: string) => void;
};

const PhaseEditModal = (props: Props) => {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onEnter={() => {
        setName(props.phase.get('name', ''));
        setDescription(props.phase.get('description', ''));
      }}
    >
      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          props.onSubmit(props.phase.get('id'), name, description);
          props.onHide();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Phase</Modal.Title>
          <ModalIcon.Edit />
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              placeholder="Type a name"
              value={name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Note</ControlLabel>
            <Textarea
              minRows={3}
              className="form-control"
              placeholder="Describe the step"
              value={description}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
                setDescription(event.target.value)
              }
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveButtonType="submit"
            saveLabel="Save changes"
            isDisabled={name.trim() === ''}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PhaseEditModal;
