import { Header } from '../../constants';
import { createCallbackMiddleware, createOpenapiFetchClient } from '../../fetchClient';
import type { ClientInitOptions } from '../types';

import type { paths } from './__generated__/schema';

export class DataScienceClient {
  private readonly client: ReturnType<typeof createOpenapiFetchClient<paths>>;

  constructor({ baseUrl, callbacks, token }: ClientInitOptions) {
    this.client = createOpenapiFetchClient<paths>({
      baseUrl: baseUrl,
      middlewares: [createCallbackMiddleware(callbacks)],
      headers: new Headers({ [Header.STORAGE_API_TOKEN]: token }),
    });
  }

  async getAppLogsDownload(id: string, signal?: AbortSignal) {
    const { data } = await this.client.get(
      '/apps/{appId}/logs/download',
      { path: { appId: id } },
      { signal },
    );
    return data;
  }

  async getAppLogsTail(id: string, signal?: AbortSignal) {
    const { data } = await this.client.get(
      '/apps/{appId}/logs/tail',
      {
        query: {
          lines: 200,
        },
        path: {
          appId: id,
        },
      },
      {
        signal,
      },
    );

    return data;
  }
}
