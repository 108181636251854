import React from 'react';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { cn, Link } from '@keboola/design';

import SandboxesActions from '@/modules/sandboxes/Actions';
import { CONTAINER_BASED, NEED_CLIENT_TO_CONNECT } from '@/modules/sandboxes/Constants';
import { prepareCredentialsData, prepareSandboxUrl } from '@/modules/sandboxes/helpers';
import CredentialsBox from '@/react/common/CredentialsBox';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import ClientInfo from './ClientInfo';
import ResetPassword from './ResetPassword';

type Props = {
  sandbox: Map<string, any>;
  show: boolean;
  onHide: () => void;
  config?: Map<string, any>;
};

class CredentialsModal extends React.Component<Props> {
  state = {
    isLoading: false,
  };

  render() {
    const type = this.props.sandbox.get('type');

    return (
      <Modal show={this.props.show} onHide={this.props.onHide} onEnter={this.checkData}>
        <Modal.Header closeButton>
          <Modal.Title>Workspace Credentials</Modal.Title>
          <ModalIcon icon="user" color="green" bold />
        </Modal.Header>
        <Modal.Body>{this.renderBody(type)}</Modal.Body>
        {!NEED_CLIENT_TO_CONNECT.includes(type) && !!this.props.sandbox.get('url') && (
          <Modal.Footer>
            <Link
              href={prepareSandboxUrl(this.props.sandbox)}
              className={cn('btn btn-block btn-success', {
                disabled: this.state.isLoading,
              })}
            >
              <FontAwesomeIcon icon="circle-play" className="icon-addon-right" fixedWidth />
              Connect
            </Link>
          </Modal.Footer>
        )}
      </Modal>
    );
  }

  renderBody(type: string) {
    if (this.state.isLoading) {
      return (
        <>
          <Loader /> Loading data...
        </>
      );
    }

    return (
      <>
        <CredentialsBox noBorder rows={prepareCredentialsData(this.props.sandbox)} />
        {NEED_CLIENT_TO_CONNECT.includes(type) && (
          <>
            <hr />
            <ClientInfo />
          </>
        )}
        {!CONTAINER_BASED.includes(type) && (
          <ResetPassword
            id={this.props.sandbox.get('id')}
            configId={this.props.sandbox.get('configurationId')}
            config={this.props.config}
          />
        )}
      </>
    );
  }

  checkData = () => {
    if (!this.props.sandbox.get('password')) {
      this.setState({ isLoading: true });
      SandboxesActions.loadSandboxForce(this.props.sandbox.get('id')).finally(() =>
        this.setState({ isLoading: false }),
      );
    }
  };
}

export default CredentialsModal;
