import React from 'react';
import type { Map } from 'immutable';

import { cn } from '@keboola/design';

import { useSorter } from '@/hooks';
import { canManageBucket, canWriteBucket, isSharingAvailable } from '@/modules/admin/privileges';
import { updateBucketsSort } from '@/modules/storage/actions';
import { getFilteredData } from '@/modules/storage/helpers';
import { CreatedDate } from '@/react/common';
import TableUpdatedBy from '@/react/common/TableUpdatedBy';
import type { OpenShareModal, RowData, TableData } from '@/types/types';
import onClickSelectionCell from '@/utils/onClickSelectionCell';
import {
  BucketActions,
  BucketCell,
  CellSelection,
  ExpandableTable,
  HeaderActions,
  HeaderSelection,
  LastChangeColumn,
  TableActions,
  TableCell,
} from './components';

type Props = {
  readOnly: boolean;
  sapiToken: Map<string, any>;
  buckets: Map<string, any>;
  expandedBuckets: Map<string, boolean>;
  bucketsSort: Map<string, any>;
  components: Map<string, any>;
  configurations: Map<string, any>;
  deletingTables: Map<string, boolean>;
  deletingBuckets: Map<string, boolean>;
  exportingTables: Map<string, boolean>;
  createSnapshotsTables: Map<string, boolean>;
  openDeleteModal: () => void;
  openDeleteBucketModal: (bucket: Map<string, any>) => void;
  openDeleteTableModal: (table: Map<string, any>) => void;
  openExportTablesModal: (tables: Map<string, any>[]) => void;
  openCreateWorkpaceModal: () => void;
  openSnapshotModal: (tables: Map<string, any>[]) => void;
  openShareModal: OpenShareModal;
  canExportTable: boolean;
  hasFlows: boolean;
  showAllBuckets: boolean;
  hasSnowflakePartnerConnectLimited: boolean;
  searchFilters: Map<string, any>;
};

export const IndexTable = ({
  readOnly,
  buckets,
  expandedBuckets,
  showAllBuckets,
  bucketsSort,
  searchFilters,
  components,
  configurations,
  sapiToken,
  openDeleteModal,
  openDeleteBucketModal,
  openDeleteTableModal,
  openExportTablesModal,
  openSnapshotModal,
  openShareModal,
  openCreateWorkpaceModal,
  deletingTables,
  exportingTables,
  createSnapshotsTables,
  deletingBuckets,
  canExportTable,
  hasFlows,
  hasSnowflakePartnerConnectLimited,
}: Props) => {
  const { sort, sorter, setSort } = useSorter(bucketsSort, updateBucketsSort);

  const columns = React.useMemo(
    () => [
      {
        header: ({ table }: { table: TableData }) => {
          return (
            <div className="tw-inline-flex tw-items-center">
              {!readOnly && (
                <div
                  onClick={onClickSelectionCell}
                  className="-tw-ml-6 tw-inline-flex tw-items-center tw-p-1 tw-pl-6 tw-pr-3"
                >
                  <HeaderSelection table={table} />
                </div>
              )}
              <HeaderActions
                canExportTable={canExportTable}
                canShareTable={isSharingAvailable(sapiToken)}
                table={table}
                sapiToken={sapiToken}
                sort={sort}
                setSort={setSort}
                hasSnowflakePartnerConnectLimited={hasSnowflakePartnerConnectLimited}
                isCreatingSnapshot={!createSnapshotsTables.isEmpty()}
                isExportingTables={!exportingTables.isEmpty()}
                openDeleteModal={openDeleteModal}
                openCreateWorkpaceModal={openCreateWorkpaceModal}
                openExportTablesModal={openExportTablesModal}
                openSnapshotModal={openSnapshotModal}
                openShareModal={openShareModal}
              />
            </div>
          );
        },
        cell: ({ row }: { row: RowData }) => {
          const inFolder = row.depth === 1;

          return (
            <div
              className={cn('tw-inline-flex tw-items-center', {
                'tw-pl-7': inFolder,
              })}
            >
              {!readOnly && (
                <div
                  onClick={onClickSelectionCell}
                  className={cn(
                    'tw-inline-flex tw-items-center tw-p-3',
                    inFolder ? '-tw-ml-12 tw-pl-12' : '-tw-ml-6 tw-pl-6',
                  )}
                >
                  <CellSelection row={row} />
                </div>
              )}
              {row.depth === 0 ? (
                <BucketCell row={row} canWrite={canWriteBucket(sapiToken, row.original.item)} />
              ) : (
                <TableCell
                  row={row}
                  canWrite={canWriteBucket(sapiToken, row.original.item.get('bucket'))}
                />
              )}
            </div>
          );
        },
        accessorKey: 'item',
      },
      {
        id: 'last_updated_by',
        header: 'Recently updated by',
        cell: ({ row }: { row: RowData }) => {
          if (row.original.item.has('stage')) {
            return (
              <span className="text-muted">
                {row.original.item.get('bucketTables').isEmpty() ? (
                  'N/A'
                ) : (
                  <TableUpdatedBy
                    table={row.original.item
                      .get('bucketTables')
                      .sortBy((table: Map<string, any>) => {
                        const lastChange = table.get('lastChangeDate', table.get('created'));
                        return -1 * new Date(lastChange).getTime();
                      })
                      .first()}
                    components={components}
                    configurations={configurations}
                    hasFlows={hasFlows}
                  />
                )}
              </span>
            );
          }

          return (
            <span className="text-muted">
              <TableUpdatedBy
                table={row.original.item}
                components={components}
                configurations={configurations}
                hasFlows={hasFlows}
              />
            </span>
          );
        },
      },
      {
        id: 'last_modified',
        header: () => <LastChangeColumn sort={sort} setSort={setSort} />,
        cell: ({ row }: { row: RowData }) => {
          if (row.original.item.has('stage')) {
            const bucket = row.original.item;
            const isDeleting = deletingBuckets.get(bucket.get('id'), false);
            const isExportingBucket =
              !bucket.get('bucketTables').isEmpty() &&
              bucket.get('bucketTables').every((table: Map<string, any>) => {
                return exportingTables.get(table.get('id'), false);
              });

            const isCreatingSnapshotBucket =
              !bucket.get('bucketTables').isEmpty() &&
              bucket.get('bucketTables').every((table: Map<string, any>) => {
                return createSnapshotsTables.get(table.get('id'), false);
              });

            return (
              <div
                className={cn('actions-container', {
                  'force-actions': isDeleting || isExportingBucket || isCreatingSnapshotBucket,
                })}
              >
                <div className="not-actions">
                  <CreatedDate
                    createdTime={bucket.get('lastChangeDate') || bucket.get('created')}
                  />
                </div>
                <div className="actions">
                  <BucketActions
                    bucket={bucket}
                    sapiToken={sapiToken}
                    isDeleting={isDeleting}
                    isExporting={isExportingBucket}
                    isCreatingSnapshot={isCreatingSnapshotBucket}
                    openModal={openDeleteBucketModal}
                    canExportTable={canExportTable}
                    openExportTablesModal={openExportTablesModal}
                    openSnapshotModal={openSnapshotModal}
                    openShareModal={openShareModal}
                    canManageBucket={canManageBucket(sapiToken, bucket)}
                    canWriteBucket={canWriteBucket(sapiToken, bucket)}
                  />
                </div>
              </div>
            );
          }

          const table = row.original.item;
          const isDeleting = deletingTables.get(table.get('id'), false);
          const isExporting = exportingTables.get(table.get('id'), false);
          const isCreatingSnapshot = createSnapshotsTables.get(table.get('id'), false);

          return (
            <div
              className={cn('actions-container', {
                'force-actions': isDeleting || isExporting || isCreatingSnapshot,
              })}
            >
              <div className="not-actions">
                <CreatedDate createdTime={table.get('lastChangeDate') || table.get('created')} />
              </div>
              <div className="actions">
                <TableActions
                  table={table}
                  isDeleting={isDeleting}
                  isExporting={isExporting}
                  isCreatingSnapshot={isCreatingSnapshot}
                  openDeleteTableModal={openDeleteTableModal}
                  openExportTablesModal={openExportTablesModal}
                  openSnapshotModal={openSnapshotModal}
                  canExportTable={canExportTable}
                  canWriteBucket={canWriteBucket(sapiToken, table.get('bucket'))}
                  canShareTable={isSharingAvailable(sapiToken)}
                  openShareModal={openShareModal}
                />
              </div>
            </div>
          );
        },
      },
    ],
    [
      sort,
      setSort,
      openDeleteModal,
      openCreateWorkpaceModal,
      canExportTable,
      openExportTablesModal,
      openSnapshotModal,
      openShareModal,
      hasSnowflakePartnerConnectLimited,
      createSnapshotsTables,
      exportingTables,
      sapiToken,
      components,
      configurations,
      hasFlows,
      readOnly,
      deletingTables,
      deletingBuckets,
      openDeleteBucketModal,
      openDeleteTableModal,
    ],
  );

  const memoizedRows = React.useMemo(() => {
    return getFilteredData(buckets, '', searchFilters.toJS())
      .sort(sorter)
      .map((bucket: Map<string, any>) => {
        return {
          item: bucket,
          subRows: bucket
            .get('bucketTables')
            .sort(sorter)
            .map((table: Map<string, any>) => ({ item: table }))
            .toArray(),
        };
      })
      .toArray();
  }, [buckets, searchFilters, sorter]);

  if (memoizedRows.length === 0) {
    return (
      <div className="box searchbar-results-box">
        <div className="box-content">No buckets created yet.</div>
      </div>
    );
  }

  return (
    <div className="box">
      <ExpandableTable
        columns={columns}
        data={memoizedRows}
        expandedBuckets={expandedBuckets}
        showAll={showAllBuckets}
      />
    </div>
  );
};
