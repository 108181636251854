import React, { useState } from 'react';
import { Promise } from 'bluebird';
import type { Map } from 'immutable';

import { Button, Tooltip } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import InstalledActionsCreators from '@/modules/components/InstalledComponentsActionCreators';
import ConfirmModal from '@/react/common/ConfirmModal';
import string from '@/utils/string';

type Props = {
  devComponents: Map<string, any>;
  productionComponents: Map<string, any>;
};

const PullAllFromProductionButton = ({ devComponents, productionComponents }: Props) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isPending, setPending] = useState(false);

  const configsToPull: { componentId: string; configId: string }[] = [];

  productionComponents.forEach((component) => {
    component.get('configurations').forEach((config: Map<string, any>) => {
      const componentId = component.get('id');
      const configId = config.get('id');

      if (!devComponents.hasIn([componentId, 'configurations', configId])) {
        configsToPull.push({ componentId, configId });
      }
    });
  });

  const handlePullAllMissingConfigs = () => {
    setPending(true);

    return Promise.map(
      configsToPull,
      (item) =>
        InstalledActionsCreators.resetConfigurationToProductionVersion(
          item.componentId,
          item.configId,
        ),
      { concurrency: 3 },
    )
      .then(() => {
        ApplicationActionCreators.sendNotification({
          type: 'success',
          message: () => (
            <>
              <b>{configsToPull.length}</b> configurations have been pulled from production.
            </>
          ),
        });

        return Promise.all([
          InstalledActionsCreators.loadInstalledComponentsForce(),
          InstalledActionsCreators.loadDeletedComponentsForce(),
        ]);
      })
      .finally(() => setPending(false));
  };

  return (
    <>
      <Tooltip
        tooltip="Branch is up to date"
        forceHide={configsToPull.length !== 0}
        placement="bottom"
      >
        <Button
          variant="outline"
          disabled={configsToPull.length === 0 || isPending}
          onClick={() => setModalVisible(true)}
        >
          Pull all missing configurations
        </Button>
      </Tooltip>

      <ConfirmModal
        show={isModalVisible}
        title="Pull from Production"
        text={
          <>
            Do you want to pull <b>{configsToPull.length}</b>{' '}
            {string.pluralize(configsToPull.length, 'configuration')} to the current branch?
          </>
        }
        buttonLabel="Pull configurations"
        buttonType="success"
        icon="code-pull-request"
        iconColor="blue"
        closeAfterResolve
        isLoading={isPending}
        onConfirm={handlePullAllMissingConfigs}
        onHide={() => setModalVisible(false)}
      />
    </>
  );
};

export default PullAllFromProductionButton;
