import React from 'react';
import { Modal } from 'react-bootstrap';
import Promise from 'bluebird';
import { List, Map } from 'immutable';

import { FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { GENERIC_DOCKER_UI_ROWS } from '@/constants/componentFlags';
import { componentTypes } from '@/constants/componentTypes';
import { hasWriterSimpleTableInput } from '@/modules/components/helpers';
import InstalledComponentsActions from '@/modules/components/InstalledComponentsActionCreators';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import InputMappingSource from '@/modules/transformations/react/components/mapping/InputMappingSource';
import nextTick from '@/utils/nextTick';
import ConfirmButtons from './ConfirmButtons';
import ModalIcon from './ModalIcon';

const isWriterWithoutRows = (component: Map<string, any>) => {
  return (
    component.get('type') === componentTypes.WRITER &&
    !component.get('flags').includes(GENERIC_DOCKER_UI_ROWS)
  );
};

const CreateRowModal = (props: {
  component: Map<string, any>;
  configId: string;
  buckets?: Map<string, any>;
  tables?: Map<string, any>;
  entity?: string;
  show?: boolean;
  onCreate: (tableId: string, name: string, options?: { addStorageTable: boolean }) => Promise<any>;
  onRowRedirect: (rowId: string) => void;
  onHide: () => void;
}) => {
  const [value, setValue] = React.useState('');
  const [isSaving, setIsSaving] = React.useState(false);

  const addStorageTable = hasWriterSimpleTableInput(props.component);
  const showTableSelector = isWriterWithoutRows(props.component) || addStorageTable;

  const entity = props.entity || 'Table';

  const createRows = () => {
    let data = [[value, value]];

    if (showTableSelector && props.buckets && props.tables) {
      const tables = props.buckets.has(value)
        ? props.tables.filter((table) => table.getIn(['bucket', 'id']) === value).toList()
        : List([props.tables.get(value)]);

      data = tables.map((table) => [table.get('id'), table.get('displayName')]).toArray();
    }

    return Promise.mapSeries(data, ([tableId, name]) => {
      if (!showTableSelector) {
        return props.onCreate(tableId, name, { addStorageTable });
      }

      return StorageActionCreators.loadTableDetail(tableId)
        .then(nextTick)
        .then(() => props.onCreate(tableId, name, { addStorageTable }));
    })
      .then((result) => {
        if (result.length === 1) {
          return props.onRowRedirect(result[0]['id']);
        }

        return InstalledComponentsActions.loadComponentConfigDataForce(
          props.component.get('id'),
          props.configId,
        ).then(() => {
          ApplicationActionCreators.sendNotification({
            type: 'success',
            message: `All ${entity.toLowerCase()}s have been created.`,
          });
        });
      })
      .then(props.onHide);
  };

  return (
    <Modal onHide={props.onHide} show={props.show} onEnter={() => setValue('')}>
      <form
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();

          setIsSaving(true);
          return createRows().finally(() => setIsSaving(false));
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add {entity.toLowerCase()}</Modal.Title>
          <ModalIcon.Plus />
        </Modal.Header>
        <Modal.Body>
          <FormGroup className="tw-mb-4">
            {showTableSelector ? (
              <>
                <Label htmlFor="select-table">Select a table or a bucket</Label>
                <InputMappingSource
                  id="select-table"
                  disableMultiSelect // TODO: Add support here?
                  value={Map({ source: value })}
                  tables={props.tables}
                  buckets={props.buckets}
                  onChange={setValue}
                />
                <HelpBlock>
                  When a bucket is selected, a configuration for each table from the bucket will be
                  created.
                </HelpBlock>
              </>
            ) : (
              <>
                <Label htmlFor="name">Name</Label>
                <TextInput
                  id="name"
                  variant="secondary"
                  autoFocus
                  value={value}
                  onChange={(value) => setValue(value)}
                />
              </>
            )}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveButtonType="submit"
            saveLabel="Create"
            isSaving={isSaving}
            isDisabled={!value}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateRowModal;
