import React from 'react';
import { ControlLabel, FormGroup, Modal, Nav, NavItem, Tab } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import Switch from 'rc-switch';

import { ButtonInline } from '@keboola/design';

import { EXCLUDE_FROM_NEW_LIST, EXCLUDE_RUN } from '@/constants/componentFlags';
import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import keyCodes from '@/constants/keyCodes';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import { getComponentsFiltered, sortComponents } from '@/modules/components-directory/helpers';
import { componentsUnavailableInTrial } from '@/modules/snowflake-partner-connect/constants';
import FastFade from '@/react/common/FastFade';
import SearchBar from '@/react/common/SearchBar';
import Select from '@/react/common/Select';
import AddTaskComponentRow from './AddTaskComponentRow';

type Props = {
  show: boolean;
  onHide: () => void;
  onSelect: (component: Map<string, any>) => void;
  components: Map<string, any>;
  configurations: Map<string, any>;
  hasSnowflakePartnerConnectLimited: boolean;
  hasDataApps: boolean;
  position?: string;
};

type TabKey = 'transformations' | 'components' | 'flows' | 'data-apps';

type State = {
  query: string;
  componentType: string;
  showExistingFirst: boolean;
  showAll: boolean;
  selectedTab: TabKey;
  openStyles: React.CSSProperties | null;
};

const INITIAL_STATE: State = {
  query: '',
  componentType: '',
  showExistingFirst: true,
  showAll: false,
  selectedTab: 'components',
  openStyles: null,
};

const MAXIMUM_INITIAL_COMPONENTS = 5;

const VALID_TYPES = Object.values(componentTypes);

const COMPONENT_TYPES_IN_FILTER = [
  componentTypes.EXTRACTOR,
  componentTypes.WRITER,
  componentTypes.APPLICATION,
] as const;

const getComponents = memoizeOne(
  (components, configurations, query, componentType, hasSnowflakePartnerConnectLimited) => {
    const allowedComponents = components.filter((component: Map<string, any>) => {
      const flags = component.get('flags', List());

      if (flags.includes(EXCLUDE_RUN)) {
        return false;
      }

      if (configurations.has(component.get('id')) || component.get('id') === query) {
        return true;
      }

      return !flags.includes(EXCLUDE_FROM_NEW_LIST) && VALID_TYPES.includes(component.get('type'));
    });

    let filteredComponents = getComponentsFiltered({
      components: allowedComponents,
      query,
      types: componentType ? List([componentType]) : List(COMPONENT_TYPES_IN_FILTER),
      fields: ['name'],
    });

    if (hasSnowflakePartnerConnectLimited) {
      filteredComponents = filteredComponents.filter((component: Map<string, any>) => {
        return !componentsUnavailableInTrial.includes(component.get('id'));
      });
    }

    return filteredComponents;
  },
);

class AddTaskModal extends React.Component<Props, State> {
  state = INITIAL_STATE;
  tooltipTimeouts = Map();

  componentDidUpdate(prevProps: Props) {
    if (prevProps.show && !this.props.show) {
      this.setState(INITIAL_STATE);
    }
  }

  checkPressEscape = (e: KeyboardEvent) => {
    if (this.props.show && e.key === keyCodes.ESCAPE) {
      this.props.onHide();
    }
  };

  componentDidMount() {
    window.addEventListener('keyup', this.checkPressEscape);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.checkPressEscape);
  }

  render() {
    if (!this.props.show || !this.props.position) {
      return null;
    }

    const container = this.getRootElement();

    if (!container) {
      return null;
    }

    return ReactDOM.createPortal(this.renderAddNewTaskDialog(), container);
  }

  renderAddNewTaskDialog() {
    return (
      <FastFade in appear onEntering={this.calculateOpenStyles}>
        <Modal.Dialog bsClass="add-new-task" style={this.state.openStyles}>
          <Modal.Header className="no-border">
            <Modal.Title>Select Component</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pt-0">
            {this.renderSearch()}
            <Tab.Container
              id="add-task-modal-tabs"
              activeKey={this.state.selectedTab}
              onSelect={(tab: TabKey) => this.setState({ selectedTab: tab, showAll: false })}
            >
              <>
                <Nav bsStyle="tabs" role="navigation">
                  <NavItem eventKey="components">Components</NavItem>
                  {!this.props.hasSnowflakePartnerConnectLimited && (
                    <NavItem eventKey="transformations">Transformations</NavItem>
                  )}
                  <NavItem eventKey="flows">Flows</NavItem>
                  {this.props.hasDataApps && <NavItem eventKey="data-apps">Data Apps</NavItem>}
                </Nav>
                <Tab.Content animation={false}>
                  <Tab.Pane eventKey="components">
                    {this.renderCategorySelect()}
                    {this.renderFilterSwitch()}
                    {this.renderComponents()}
                  </Tab.Pane>
                  <Tab.Pane eventKey="transformations">{this.renderComponents()}</Tab.Pane>
                  <Tab.Pane eventKey="flows">
                    {this.renderComponents(KEBOOLA_ORCHESTRATOR)}
                  </Tab.Pane>
                  {this.props.hasDataApps && (
                    <Tab.Pane eventKey="data-apps">
                      {this.renderComponents(KEBOOLA_DATA_APPS)}
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </>
            </Tab.Container>
          </Modal.Body>
        </Modal.Dialog>
      </FastFade>
    );
  }

  renderSearch = () => {
    return (
      <FormGroup>
        <SearchBar
          bordered
          placeholder="Search"
          query={this.state.query}
          onChange={(query) => this.setState({ query })}
        />
      </FormGroup>
    );
  };

  renderCategorySelect = () => {
    return (
      <FormGroup>
        <Select
          clearable={false}
          value={this.state.componentType}
          onChange={(type: string) => this.setState({ componentType: type })}
          options={[
            { label: 'All components', value: '' },
            ...COMPONENT_TYPES_IN_FILTER.map((type) => ({
              value: type,
              label: getNewComponentTypeLabel(type),
            })),
          ]}
        />
      </FormGroup>
    );
  };

  renderFilterSwitch = () => {
    return (
      <FormGroup className="flex-container flex-start">
        <Switch
          prefixCls="switch"
          className="icon-addon-right"
          checked={this.state.showExistingFirst}
          onChange={(showExistingFirst) => this.setState({ showExistingFirst })}
        />
        <ControlLabel
          className="clickable text-muted f-13 mb-0"
          onClick={() => this.setState({ showExistingFirst: !this.state.showExistingFirst })}
        >
          Show with existing configurations first
        </ControlLabel>
      </FormGroup>
    );
  };

  renderComponents = (forceComponent?: string) => {
    const componentType =
      this.state.selectedTab === 'transformations'
        ? componentTypes.TRANSFORMATION
        : this.state.componentType;
    const components = forceComponent
      ? getComponentsFiltered({
          components: Map({ [forceComponent]: this.props.components.get(forceComponent) }),
          query: this.state.query,
          fields: ['name'],
        })
      : getComponents(
          this.props.components,
          this.props.configurations,
          this.state.query,
          componentType,
          this.props.hasSnowflakePartnerConnectLimited,
        );
    const showShowAllButton =
      !this.state.showAll && components.count() > MAXIMUM_INITIAL_COMPONENTS;

    return (
      <div className="component-list">
        {this.renderComponentsRows(components)}
        {showShowAllButton && (
          <ButtonInline
            variant="custom"
            className="tw-mt-4 tw-w-full tw-justify-center tw-text-xs tw-font-medium tw-uppercase tw-no-underline hover:!tw-bg-transparent hover:tw-underline"
            onClick={() => this.setState({ showAll: true })}
          >
            Show all components
          </ButtonInline>
        )}
      </div>
    );
  };

  renderComponentsRows = (components: Map<string, any>) => {
    if (components.isEmpty()) {
      return 'No components found';
    }

    let sortedComponents = sortComponents(components);

    if (this.state.showExistingFirst) {
      sortedComponents = sortedComponents.sort(
        (componentA: Map<string, any>, componentB: Map<string, any>) => {
          const hasAconfigs = this.props.configurations.has(componentA.get('id'));
          const hasBconfigs = this.props.configurations.has(componentB.get('id'));

          if (hasAconfigs && !hasBconfigs) {
            return -1;
          }

          if (!hasAconfigs && hasBconfigs) {
            return 1;
          }

          return 0;
        },
      );
    }

    if (!this.state.showAll) {
      sortedComponents = sortedComponents.slice(0, MAXIMUM_INITIAL_COMPONENTS);
    }

    return sortedComponents
      .map((component: Map<string, any>) => {
        const configurations = this.props.configurations
          .getIn([component.get('id'), 'configurations'], Map())
          .count();

        return (
          <AddTaskComponentRow
            key={component.get('id')}
            component={component}
            configurations={configurations}
            onSelect={this.props.onSelect}
            query={this.state.query}
          />
        );
      })
      .toArray();
  };

  calculateOpenStyles = (el: Element) => {
    const builder = document.querySelector('.flow-builder');

    if (!this.props.position || !builder) {
      return;
    }

    let styles: React.CSSProperties = {
      top: '0px',
      left: '110%',
    };

    const modalRect = el.getBoundingClientRect();

    if (modalRect.left + modalRect.width > builder.clientWidth) {
      styles = {
        ...styles,
        transformOrigin: 'top right',
        left: 'initial',
        right: '110%',
      };
    }

    const containerRect = document.querySelector('.flow-graph-container')?.getBoundingClientRect();

    if (!containerRect) {
      return;
    }

    const bottomOffset = modalRect.bottom - containerRect.bottom;
    const topOffset = modalRect.height - (modalRect.top - containerRect.top);

    if (bottomOffset > 0 && bottomOffset > topOffset) {
      styles = {
        ...styles,
        top: 'initial',
        bottom: '-20px',
        transformOrigin: `bottom ${styles?.right ? 'right' : 'left'}`,
      };
    }

    this.setState({ openStyles: styles });
  };

  getRootElement = () => {
    if (this.props.position === '[[end]]') {
      return document.querySelector('.flow-builder--group.is-last .add-task-inline-container');
    }

    if (this.props.position?.includes(':')) {
      const phaseId = this.props.position.split(':')[1];

      return document.querySelector(
        `.flow-builder--group[data-name^="${phaseId}-"] .between-phases-action .add-task-inline`,
      )?.parentElement;
    }

    return document.querySelector(
      `.flow-builder--group[data-name^="${this.props.position}-"] .phase-actions .add-task-inline`,
    )?.parentElement;
  };
}

export default AddTaskModal;
