import type { IconProp } from '@fortawesome/fontawesome-svg-core';

import { cn, extractExternalClasses } from '../../utils';

import type { Size, Variant } from './types';

const SIZE_CLASSES_TEXT = {
  'extra-small': 'tw-max-h-5',
  small: 'tw-px-4 tw-py-1.5 tw-max-h-8',
  medium: 'tw-px-4 tw-py-2.5 tw-max-h-10',
  large: 'tw-px-4 tw-py-3.5 tw-max-h-12',
};

const SIZE_CLASSES_ICON = {
  'extra-small': '!tw-flex tw-w-5 tw-max-h-5',
  small: 'tw-p-0 tw-w-8 tw-h-8',
  medium: 'tw-p-0 tw-w-10 tw-h-10',
  large: 'tw-p-0 tw-w-12 tw-h-12',
};

const VARIANT_CLASSES = {
  secondary:
    'tw-text-white tw-bg-secondary-500 not-disabled:hover:tw-bg-secondary-700 hover:!tw-text-white not-disabled:active:tw-bg-secondary-800',
  primary:
    '!tw-text-white tw-bg-primary-500 not-disabled:hover:tw-bg-primary-600 not-disabled:active:tw-bg-primary-800',
  danger:
    '!tw-text-white tw-bg-error-500 not-disabled:hover:tw-bg-error-600 not-disabled:active:tw-bg-error-700',
  outline:
    '!tw-text-neutral-800 [&_svg]:tw-text-neutral-400 tw-bg-white tw-border tw-border-solid tw-border-neutral-200 not-disabled:hover:tw-border-neutral-300 not-disabled:hover:tw-bg-neutral-100 not-disabled:active:tw-bg-neutral-150 [&_svg]:not-disabled:hover:tw-text-secondary-600',
  invisible:
    '!tw-text-neutral-800 [&_svg]:tw-text-neutral-400 tw-rounded-full tw-bg-transparent not-disabled:hover:tw-bg-neutral-100 not-disabled:focus:tw-bg-neutral-100 not-disabled:active:tw-bg-neutral-150 [&_svg]:not-disabled:hover:tw-text-secondary-600 [&_svg]:not-disabled:focus:tw-text-secondary-600',
  inline:
    'tw-text-neutral-800 [&_svg]:tw-text-neutral-400 tw-rounded-full tw-bg-transparent [&_svg]:not-disabled:hover:tw-text-secondary-600 [&_svg]:not-disabled:focus:tw-text-secondary-600',
};

const BASE_CLASSES = {
  common: 'tw-items-center tw-justify-center tw-uppercase tw-gap-2 tw-rounded tw-shadow-none',
  text: 'tw-text-xs tw-leading-5 tw-font-medium tw-tracking-[1px]',
  border:
    'tw-border-none [&:not(:active)]:not-disabled:focus-visible:tw-outline !tw-outline-offset-0 [&:not(:active)]:not-disabled:focus-visible:tw-outline-secondary-500 [&:not(:active)]:not-disabled:focus-visible:tw-outline-1 [&:not(:active)]:not-disabled:focus-visible:tw-shadow-[0_0_0_4px_rgba(31,143,255,0.2)]',
  svg: '[&_svg]:tw-text-base',
};

export const buttonStyles = ({
  variant,
  size,
  icon,
  disabled,
  isFilledIcon = false,
  className,
}: {
  variant: Variant;
  size: Size;
  icon?: IconProp;
  disabled: boolean;
  isFilledIcon?: boolean;
  className?: string;
}) => {
  const [externalClassNames, restClassNames] = extractExternalClasses(className);

  return cn(
    externalClassNames,
    ...Object.values(BASE_CLASSES),
    VARIANT_CLASSES[variant],
    icon ? SIZE_CLASSES_ICON[size] : SIZE_CLASSES_TEXT[size],
    icon ? 'tw-block' : 'tw-flex tw-flex-row',
    { '[&_svg]:tw-text-secondary-500': isFilledIcon },
    disabled ? '!tw-cursor-not-allowed tw-opacity-40' : 'tw-cursor-pointer',
    restClassNames,
  );
};
