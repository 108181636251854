import React from 'react';

import type { TextInputProps } from '../TextInput';
import { TextInput } from '../TextInput';

import { useFormGroupContext } from './context';

type Props = Omit<TextInputProps, 'state'>;

export const FormGroupTextInput = (props: Props) => {
  const { state } = useFormGroupContext();
  return <TextInput state={state} {...props} />;
};
