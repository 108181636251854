import React from 'react';
import type { Header } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';

import { SortIcon } from '@/react/common';
import MultiSortTooltip from '@/react/common/MultiSortTooltip';

type Props = {
  header: Header<unknown, unknown>;
  canSort: boolean;
};

const ColumnHeader = (props: Props) => {
  const renderHeader = () => {
    return flexRender(props.header.column.columnDef.header, props.header.getContext());
  };

  if (!props.canSort) {
    return renderHeader();
  }

  const renderSortIcon = (classNames: string) => {
    return (
      <SortIcon
        className={classNames}
        isSorted={!!props.header.column.getIsSorted()}
        isSortedDesc={props.header.column.getIsSorted() === 'desc'}
      />
    );
  };

  if (props.header.column.id === 'data') {
    return (
      <span className="tw-inline-flex tw-items-center">
        {renderHeader()}
        <MultiSortTooltip active={!props.header.column.getIsSorted()}>
          {renderSortIcon('icon-addon-left')}
        </MultiSortTooltip>
      </span>
    );
  }

  return (
    <span className="tw-inline-flex tw-items-center">
      <MultiSortTooltip active={!props.header.column.getIsSorted()}>
        {renderSortIcon('icon-addon-right')}
      </MultiSortTooltip>
      {renderHeader()}
    </span>
  );
};

export default ColumnHeader;
