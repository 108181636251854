import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import { cn } from '@keboola/design';

import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import SapiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import ChangeOrderHandle from '@/modules/configurations/react/components/ChangeOrderHandle';
import DeleteConfigurationRowButton from '@/modules/configurations/react/components/DeleteConfigurationRowButton';
import RowVersionsActionCreators from '@/modules/configurations/RowVersionsActionCreators';
import { DISABLED_NULLABLE } from '@/modules/wr-db/constants';
import { deleteRow, toggleRow } from '@/modules/wr-db/rowsActions';
import { RowActionDropdown, RowActionMenuItem, Truncated } from '@/react/common';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import Check from '@/react/common/Check';
import ConfigurationDisabledLabel from '@/react/common/ConfigurationDisabledLabel';
import { getRealComponentId } from '@/react/common/ConfigurationsTable/helpers';
import CopyVersionButton from '@/react/common/CopyVersionButton';
import InlineDescriptionEditInput from '@/react/common/InlineDescriptionEditInput';
import MultiActionsSelectCheckbox from '@/react/common/MultiActionsSelectCheckbox';
import NullablePrimaryKeyWarning from '@/react/common/NullablePrimaryKeyWarning';
import RoutesStore from '@/stores/RoutesStore';

class ConfigRowTableRow extends React.Component {
  state = {
    isDeletePending: false,
    isEnableDisablePending: false,
  };

  render() {
    return (
      <div
        data-id={this.props.row.get('id')}
        className={cn('tr clickable hoverable-actions overflow-break-anywhere', {
          'row-sort': !this.props.readOnly,
        })}
        onClick={() => {
          return RoutesStore.getRouter().transitionTo(this.props.component.get('id') + '-row', {
            config: this.props.config.get('id'),
            row: this.props.row.get('id'),
          });
        }}
      >
        {!this.props.readOnly ? (
          <>
            <div className="td row-sort-handle" onClick={(e) => e.stopPropagation()}>
              <div className="tw-flex tw-flex-nowrap tw-items-center tw-justify-center">
                <ChangeOrderHandle
                  isPending={this.props.isChangingOrder}
                  disabled={
                    this.props.isFiltered || this.props.isSorted || this.props.isChangingOrder
                  }
                />
                <MultiActionsSelectCheckbox
                  isChecked={this.props.isSelected}
                  isDisabled={this.state.isDeletePending}
                  onToggle={this.props.toggleSelected}
                  entity="row"
                />
              </div>
            </div>
            <div className="td pl-0 overflow-break-anywhere">{this.renderName()}</div>
          </>
        ) : (
          <div className="td">{this.renderName()}</div>
        )}
        <div className="td">
          <div className="flex-container flex-start">
            {this.renderSourceTable()}
            {this.renderNullablePrimaryKeyWarning()}
          </div>
        </div>
        <div className="td">{this.props.row.getIn(['configuration', 'parameters', 'dbName'])}</div>
        <div className="td text-center">
          <Check
            isChecked={this.props.row.getIn(['configuration', 'parameters', 'incremental'], false)}
          />
        </div>
        <div className="td pr-1">{this.renderRowActions()}</div>
      </div>
    );
  }

  renderName() {
    const isDisabled = this.props.row.get('isDisabled', false);

    return (
      <>
        <div className="flex-container flex-start">
          <Truncated
            text={this.props.row.get('name')}
            className={cn('config-name', { 'opacity-half': isDisabled })}
          />
          {isDisabled && <ConfigurationDisabledLabel className="no-shrink icon-addon-left" />}
        </div>
        <div className={cn('f-13 text-muted', { 'opacity-half': isDisabled })}>
          <InlineDescriptionEditInput
            entity="Row"
            description={this.props.row.get('description', '')}
            onSave={(newDescription) => {
              return ConfigurationRowsActionCreators.updateSimple(
                getRealComponentId(this.props.config, this.props.component),
                this.props.config.get('id'),
                this.props.row.get('id'),
                { description: newDescription },
                'Change description',
              );
            }}
            readOnly={this.props.readOnly || isDisabled}
            component={this.props.component}
            config={this.props.config}
            row={this.props.row}
          />
        </div>
      </>
    );
  }

  renderNullablePrimaryKeyWarning() {
    if (
      DISABLED_NULLABLE.includes(this.props.component.get('id')) &&
      this.props.row.getIn(['configuration', 'parameters', 'items'], List()).some((item) => {
        return (
          item.get('nullable', false) &&
          this.props.row
            .getIn(['configuration', 'parameters', 'primaryKey'], List())
            .includes(item.get('dbName'))
        );
      })
    ) {
      return <NullablePrimaryKeyWarning />;
    }
    return null;
  }

  renderRowActions() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <RowActionDropdown
        showLoading={this.state.isDeletePending || this.state.isEnableDisablePending}
      >
        <RunComponentButton
          mode="menuitem"
          title="Run Table"
          componentId={this.props.component.get('id')}
          runParams={() => {
            return {
              config: this.props.config.get('id'),
              row: this.props.row.get('id'),
            };
          }}
        >
          You are about to run {this.props.row.get('name')}.
        </RunComponentButton>
        <RowActionMenuItem divider />
        <ActivateDeactivateSwitch
          mode="menuitem"
          isActive={!this.props.row.get('isDisabled', false)}
          isPending={this.state.isEnableDisablePending}
          onChange={() => {
            this.setState({ isEnableDisablePending: true });
            return toggleRow(
              this.props.component.get('id'),
              this.props.config.get('id'),
              this.props.row,
            ).finally(() => this.setState({ isEnableDisablePending: false }));
          }}
        />
        <CopyVersionButton
          isRow
          isLast
          mode="menuitem"
          configId={this.props.config.get('id')}
          componentId={getRealComponentId(this.props.config, this.props.component)}
          componentType={this.props.component.get('type')}
          version={this.props.row}
          onCopy={(name) => {
            return RowVersionsActionCreators.copyVersion(
              getRealComponentId(this.props.config, this.props.component),
              this.props.config.get('id'),
              this.props.row.get('id'),
              this.props.row.get('version'),
              name,
            );
          }}
        />
        <RowActionMenuItem divider />
        <DeleteConfigurationRowButton
          mode="menuitem"
          isPending={this.state.isDeletePending}
          onClick={() => {
            this.setState({ isDeletePending: true });
            return deleteRow(
              this.props.component.get('id'),
              this.props.config.get('id'),
              this.props.row,
            ).finally(() => this.setState({ isDeletePending: false }));
          }}
        />
      </RowActionDropdown>
    );
  }

  renderSourceTable() {
    const sourceTableId = this.props.row.getIn(['configuration', 'parameters', 'tableId']);

    if (this.props.tablesWithSourceSearchInputMapping.has(sourceTableId)) {
      return (
        <>
          Search table by metadata
          <br />
          <code>
            {this.props.tablesWithSourceSearchInputMapping.getIn([
              sourceTableId,
              'source_search',
              'value',
            ])}
          </code>
        </>
      );
    }

    return <SapiTableLinkEx tableId={sourceTableId} showOnlyDisplayName />;
  }
}

ConfigRowTableRow.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  component: PropTypes.instanceOf(Map).isRequired,
  config: PropTypes.instanceOf(Map).isRequired,
  row: PropTypes.instanceOf(Map).isRequired,
  isChangingOrder: PropTypes.bool.isRequired,
  isFiltered: PropTypes.bool.isRequired,
  isSorted: PropTypes.bool.isRequired,
  tablesWithSourceSearchInputMapping: PropTypes.instanceOf(Map).isRequired,
  isSelected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired,
};

export default ConfigRowTableRow;
