import React from 'react';
import type { NodeProps } from '@xyflow/react';
import { fromJS } from 'immutable';

import { cn } from '@keboola/design';

import { componentTypes } from '@/constants/componentTypes';
import type { ConfigurationNode } from '@/modules/lineage/rfTypes';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentType from '@/react/common/ComponentType';
import BaseNode from './BaseNode';

const Configuration = ({
  data,
  onClick,
  onHighlight,
  graphId,
}: NodeProps<ConfigurationNode> & {
  onHighlight: (nodeId: string) => void;
  onClick: (nodeId: string) => void;
  graphId: string;
}) => {
  const isTransformation = data.component?.type === componentTypes.TRANSFORMATION;

  return (
    <BaseNode
      nodeId={data.fqid}
      onClick={onClick}
      onHighlight={onHighlight}
      nodeTitle={data.name}
      nodeType={isTransformation ? 'TRF' : 'COM'}
      customIcon={<ComponentIcon component={fromJS(data.component)} size="20" />}
      backgroundColor={cn(
        isTransformation
          ? 'tw-bg-warning-300'
          : {
              'tw-bg-primary-200': data.component?.type === componentTypes.EXTRACTOR,
              'tw-bg-cyan-200': data.component?.type === componentTypes.WRITER,
              'tw-bg-error-200': data.component?.type === componentTypes.APPLICATION,
            },
      )}
      graphId={graphId}
    >
      {data.component && (
        <p className="tw-m-0">
          <ComponentType type={data.component.type} noIcon labelClassName="tw-text-cyan-600" /> -{' '}
          {data.component.name}
        </p>
      )}
    </BaseNode>
  );
};

export default Configuration;
