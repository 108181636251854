import React from 'react';

import { ButtonGroup, cn, IconButton, Tooltip } from '@keboola/design';

import Confirm from '@/react/common/Confirm';

const ScheduleRow = ({
  type,
  value,
  onEdit,
  onDelete,
  disabled,
  children,
}: {
  type: 'trigger' | 'scheduler';
  value: React.ReactNode;
  onEdit?: () => void;
  onDelete?: () => Promise<unknown>;
  disabled?: boolean;
  children?: React.ReactNode;
}) => {
  const [deleting, setDeleting] = React.useState(false);
  const isTrigger = type === 'trigger';

  const editLabel = `Edit ${isTrigger ? 'Trigger' : 'Schedule'}`;
  const deleteLabel = `Delete ${isTrigger ? 'Trigger' : 'Schedule'}`;

  return (
    <div
      className={cn(
        'tw-group/box tw-grid tw-grid-cols-[44px_auto_100px] tw-rounded tw-border tw-border-solid tw-border-neutral-150 tw-p-4',
        { 'tw-bg-neutral-50': disabled },
      )}
    >
      <div>{children}</div>
      <div>
        <h3 className={cn('tw-mb-1 tw-mt-0 tw-text-sm', { 'tw-opacity-40': disabled })}>
          {isTrigger ? 'Table Trigger' : 'Date & Time'}
        </h3>
        <p className={cn('tw-m-0', { 'tw-opacity-40': disabled })}>{value}</p>
      </div>
      {(onEdit || onDelete) && (
        <div className="tw-flex tw-items-center tw-justify-end tw-opacity-0 tw-transition-opacity group-hover/box:tw-opacity-100">
          <ButtonGroup>
            {onEdit && (
              <Tooltip type="action" placement="top" tooltip={editLabel}>
                <IconButton
                  size="small"
                  variant="invisible"
                  icon="pen"
                  onClick={onEdit}
                  disabled={deleting}
                />
              </Tooltip>
            )}
            {onDelete && (
              <Confirm
                icon="trash"
                title={deleteLabel}
                buttonLabel={deleteLabel}
                text={`Are you sure you want to delete this ${isTrigger ? 'trigger' : 'schedule'}?`}
                onConfirm={() => {
                  setDeleting(true);
                  return onDelete().finally(() => setDeleting(false));
                }}
              >
                <Tooltip type="action" placement="top" tooltip={deleteLabel}>
                  <IconButton
                    size="small"
                    variant="invisible"
                    icon="trash"
                    isLoading={deleting}
                    disabled={deleting}
                  />
                </Tooltip>
              </Confirm>
            )}
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};

export default ScheduleRow;
