import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BluebirdPromise from 'bluebird';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import { Button, IconButton, Tooltip } from '@keboola/design';

import { RADEKTOMASEK_EX_DROPBOX_V_2 as componentId } from '@/constants/componentIds';
import dayjs from '@/date';
import actions from '@/modules/components/InstalledComponentsActionCreators';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import SapiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { FileSize, RowActionDropdown } from '@/react/common';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { MD5 } from '@/utils/crypto';
import getDefaultBucket from '@/utils/getDefaultBucket';
import string from '@/utils/string';
import {
  getDestinationName,
  sortTimestampsInAscendingOrder,
  sortTimestampsInDescendingOrder,
} from './actions/ApplicationActions';
import FileSelectorModal from './components/DropboxFileSelectorModal';

export type DropboxFile = {
  name: string;
  manualInsert: boolean;
  link: string;
  bytes?: number;
};

const Index = () => {
  const state = useStores(
    () => {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const configData = InstalledComponentsStore.getConfigData(componentId, configId);
      const localState = InstalledComponentsStore.getLocalState(componentId, configId);

      return {
        componentId,
        configId,
        configData,
        localState,
        defaultBucket: getDefaultBucket('in', componentId, configId),
        isSaving: InstalledComponentsStore.isSavingConfigData(componentId, configId),
        parameters: configData.get('parameters', Map()),
        readOnly: ApplicationStore.isReadOnly(),
      };
    },
    [],
    [RoutesStore, ApplicationStore, InstalledComponentsStore],
  );

  const [showFileSelectorModal, setShowFileSelectorModal] = React.useState(false);

  const closeFileSelectorModal = () => {
    setShowFileSelectorModal(state.isSaving);
    updateLocalState(['selectedDropboxFiles'], fromJS([]));
  };

  const hasFiles = () => {
    const hasFiles = state.configData.hasIn(['parameters', 'config', 'dropboxFiles']);
    const filesCount = hasFiles
      ? state.configData.getIn(['parameters', 'config', 'dropboxFiles']).count()
      : 0;
    return filesCount > 0;
  };

  const renderFileSelectorModal = () => {
    if (state.readOnly) {
      return null;
    }

    return (
      <>
        <Button size="small" onClick={() => setShowFileSelectorModal(true)}>
          <FontAwesomeIcon icon="plus" />
          New file
        </Button>
        <FileSelectorModal
          saveConfig={saveConfig}
          isSaving={state.isSaving}
          cancelConfig={() => updateLocalState(['selectedDropboxFiles'], fromJS([]))}
          canSaveConfig={canSaveConfig}
          onHide={closeFileSelectorModal}
          show={showFileSelectorModal}
          handleCsvSelectChange={(values) => updateLocalState(['selectedDropboxFiles'], values)}
          selectedDropboxFiles={getSelectedCsvFiles()}
        />
      </>
    );
  };

  const renderConfigSummary = () => {
    const hasSelectedFiles = state.configData.hasIn(['parameters', 'config', 'dropboxFiles']);
    const selectedFiles = hasSelectedFiles
      ? state.configData.getIn(['parameters', 'config', 'dropboxFiles'])
      : List();

    if (!hasSelectedFiles || selectedFiles.isEmpty()) {
      return null;
    }

    return (
      <div className="box-separator">
        <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
          <h2 className="tw-m-0 tw-text-base">Files</h2>
          {renderFileSelectorModal()}
        </div>
        <div className="box">
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Dropbox File</th>
                <th>Size</th>
                <th />
                <th>Output Table</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {selectedFiles
                .map((table: Map<string, any>, index: number) => {
                  return (
                    <tr key={index} className="hoverable-actions">
                      <td>{table.get('name')}</td>
                      <td>
                        <FileSize size={table.get('bytes')} />
                      </td>
                      <td>&gt;</td>
                      <td>
                        <SapiTableLinkEx
                          tableId={state.defaultBucket + '.' + table.get('output')}
                        />
                      </td>
                      <td className="pl-0 pr-1 no-wrap">{renderActionButtons(table, index)}</td>
                    </tr>
                  );
                })
                .toArray()}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderActionButtons = (table: Map<string, any>, index: number) => {
    if (state.readOnly) {
      return null;
    }

    return (
      <>
        <Tooltip tooltip="Delete" placement="top" triggerClassName="tw-align-middle">
          <IconButton
            className="tw-inline-flex"
            variant="inline"
            disabled={state.isSaving}
            onClick={() => handleDeletingSingleElement(index)}
            icon="trash"
          />
        </Tooltip>
        <RowActionDropdown>
          <RunComponentButton
            mode="menuitem"
            title="Run Extraction"
            componentId={componentId}
            disabled={!state.configData.hasIn(['parameters', 'config', 'dropboxFiles', index])}
            disabledReason="No file to extract."
            runParams={() => handleUploadingSingleElement(index)}
          >
            You are about to run an extraction of <strong>{table.get('name')}</strong> from Dropbox.
          </RunComponentButton>
        </RowActionDropdown>
      </>
    );
  };

  const getLocalConfigDataFilesCount = () => {
    if (!state.localState.has('selectedDropboxFiles')) {
      return 0;
    }
    return fromJS(state.localState.get('selectedDropboxFiles')).size;
  };

  // We can save new config whether user changed files selection.
  // On the other hand the bucket may be changed, but we also have to make sure the bucket is set.
  const canSaveConfig = !(getLocalConfigDataFilesCount() > 0);

  const updateAndSaveConfigData = (
    path: string[],
    data: Map<string, any>,
    changeDescription: string,
  ) => {
    const newData = state.configData.setIn(path, data);
    return actions.saveComponentConfigData(componentId, state.configId, newData, changeDescription);
  };

  const saveConfig = (forceData?: DropboxFile[]) => {
    const hasSelectedDropboxFiles = !!forceData || state.localState.has('selectedDropboxFiles');
    let changeDescription = '';
    const data = forceData || state.localState.get('selectedDropboxFiles');
    if (hasSelectedDropboxFiles) {
      const localState = data.map((dropboxFile: DropboxFile) => {
        changeDescription = `Create file ${dropboxFile.name}`;
        const output = dropboxFile.manualInsert
          ? dropboxFile.name
          : string.sanitizeKbcTableIdString(getDestinationName(dropboxFile.name));
        return {
          bytes: dropboxFile.bytes,
          '#link': dropboxFile.link,
          name: dropboxFile.name,
          timestamp: dayjs().unix(),
          hash: MD5(dropboxFile.name).toString(),
          output: output,
        };
      });

      const oldState = state.configData
        .getIn(['parameters', 'config', 'dropboxFiles'], List())
        .toJS();
      const mergedState = [...oldState, ...localState];

      // We need to dedup the state in case there has been selected the same combination of file + bucket.
      // We also need to keep the older files to make sure we keep consistent file names in case of duplicate in names.
      const dedupState = _.values(
        _.indexBy(mergedState.sort(sortTimestampsInDescendingOrder), 'hash'),
      );

      // We need to make sure the final name will be unique.
      const newState = _.flatten(
        _.values(_.groupBy(dedupState, 'output')).map((arrayOfFileNames) => {
          if (arrayOfFileNames.length === 1) {
            return arrayOfFileNames;
          } else {
            return arrayOfFileNames.sort(sortTimestampsInAscendingOrder).map((fileName, index) => {
              if (index > 0) {
                return Object.assign({}, fileName, {
                  output: fileName.output + fileName.hash.slice(0, 4),
                });
              }
              return fileName;
            });
          }
        }),
      ).sort(sortTimestampsInAscendingOrder);

      return updateAndSaveConfigData(
        ['parameters', 'config', 'dropboxFiles'],
        fromJS(newState),
        changeDescription,
      );
    }
    return BluebirdPromise.resolve();
  };

  const canRunUpload =
    state.configData.hasIn(['parameters', 'config', 'dropboxFiles']) &&
    state.configData.getIn(['parameters', 'config', 'dropboxFiles']).count() > 0;

  const getSelectedCsvFiles = () => {
    const selectedDropboxFiles: string[] = [];
    const localConfigDataFiles = state.localState.get('selectedDropboxFiles');
    const hasLocalConfigDataFiles = state.localState.has('selectedDropboxFiles');

    if (hasLocalConfigDataFiles) {
      localConfigDataFiles.map((fileName: string) => {
        selectedDropboxFiles.push(fileName);
      });
    }

    return selectedDropboxFiles;
  };

  const handleDeletingSingleElement = (element: number) => {
    if (state.configData.hasIn(['parameters', 'config', 'dropboxFiles'])) {
      const name = state.configData.getIn([
        'parameters',
        'config',
        'dropboxFiles',
        element,
        'name',
      ]);
      const changeDescription = `Delete file ${name}`;
      const newConfig = state.configData
        .getIn(['parameters', 'config', 'dropboxFiles'])
        .delete(element);

      updateAndSaveConfigData(
        ['parameters', 'config', 'dropboxFiles'],
        newConfig,
        changeDescription,
      );
    }
  };

  const handleUploadingSingleElement = (element: number) => {
    const selectedFile = state.configData
      .getIn(['parameters', 'config', 'dropboxFiles', element], Map())
      .toJS();
    return {
      config: state.configId,
      configData: {
        parameters: {
          config: {
            dropboxFiles: [
              {
                '#link': selectedFile['#link'],
                name: selectedFile.name,
                output: selectedFile.output,
              },
            ],
          },
        },
      },
    };
  };

  const updateLocalState = (path: string[], data: Map<string, any>) => {
    const newLocalState = state.localState.setIn(path, data);
    actions.updateLocalState(componentId, state.configId, newLocalState, path);
  };

  return (
    <GenericConfigBody
      key={`${state.componentId}-${state.configId}`}
      componentId={state.componentId}
      configId={state.configId}
      sidebarProps={{
        run: {
          disabled: !canRunUpload ? 'A Dropbox file must be selected.' : '',
          text: 'You are about to run an extraction of all configured CSV files from Dropbox.',
        },
      }}
    >
      {hasFiles() ? null : (
        <div className="box-separator">
          <h2 className="tw-m-0 tw-mb-4 tw-text-base">Files</h2>
          <div className="box">
            <div className="box-content tw-justify-items-center">
              <p>No files selected yet.</p>
              {renderFileSelectorModal()}
            </div>
          </div>
        </div>
      )}
      {renderConfigSummary()}
    </GenericConfigBody>
  );
};

export default Index;
