import { loadAll } from './actions';
import { routeNames } from './constants';
import DevBranchTitle from './DevBranchTitle';
import HeaderButton from './HeaderButton';
import MergeIndex from './MergeIndex';

export default {
  name: routeNames.DEVELOPMENT_OVERVIEW,
  titleHandler: DevBranchTitle,
  defaultRouteHandler: MergeIndex,
  headerButtonsHandler: HeaderButton,
  poll: { action: loadAll },
};
