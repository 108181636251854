import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { cn } from '@keboola/design';

import PaginatedBoxItem from './PaginatedBoxItem';

class PaginatedBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeBox: 0,
    };
  }

  render() {
    const boxes = React.Children.toArray(this.props.children);

    if (!boxes.length) {
      return null;
    }

    return (
      <div className="box-panel-content paginated">
        <SwitchTransition>
          <CSSTransition key={this.state.activeBox} classNames="fade" timeout={300}>
            {boxes[this.state.activeBox]}
          </CSSTransition>
        </SwitchTransition>
        {this.renderNavigation(boxes)}
      </div>
    );
  }

  renderNavigation(boxes) {
    if (boxes.length < 2) {
      return null;
    }

    return (
      <div className="box-navigation">
        {boxes.map((box, index) => (
          <Button
            key={index}
            bsStyle="link"
            className={cn({ active: this.state.activeBox === index })}
            onClick={() => this.setState({ activeBox: index })}
          />
        ))}
      </div>
    );
  }
}

PaginatedBox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
};

PaginatedBox.Item = PaginatedBoxItem;

export default PaginatedBox;
