import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { cn, Tooltip } from '@keboola/design';

const SwitchButton = (props: {
  label: string;
  onClick?: () => void;
  isDisabled?: boolean;
  tooltip?: string;
}) => {
  const body = (
    <Button
      className={cn({ 'btn-disabled': props.isDisabled })}
      onClick={() => {
        props.onClick?.();
        (document.activeElement as HTMLElement | null)?.blur();
      }}
    >
      <FontAwesomeIcon icon="right-left" className="icon-addon-right" fixedWidth />
      {props.label}
    </Button>
  );

  if (props.tooltip) {
    return (
      <Tooltip type="auto" tooltip={props.tooltip} placement="bottom">
        {body}
      </Tooltip>
    );
  }

  return body;
};

export default SwitchButton;
