import React, { useState } from 'react';
import { FormControl, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';
import type { Map } from 'immutable';
import { List } from 'immutable';

import {
  Button,
  ButtonGroup,
  cn,
  FormGroup,
  IconButton,
  Label,
  Link,
  TextInput,
} from '@keboola/design';

import { EXPERIMENTAL } from '@/constants/componentFlags';
import { KEBOOLA_EX_SAMPLE_DATA, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import * as externalClasses from '@/constants/external';
import {
  ensureComponentWithDetails,
  getNewComponentTypeLabel,
  resolveRouterLinkParams,
  saveFolderToMetadata,
} from '@/modules/components/helpers';
import {
  createConfiguration,
  createSampleDataConfiguration,
} from '@/modules/components-directory/actions';
import { hasSampleDataAvailable } from '@/modules/components-directory/helpers';
import ComponentAuthor from '@/react/common/ComponentAuthor';
import ComponentBadges from '@/react/common/ComponentBadges';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentLicence from '@/react/common/ComponentLicence';
import ComponentType from '@/react/common/ComponentType';
import ExperimentalComponentInfoAlert from '@/react/common/ExperimentalComponentInfoAlert';
import { hasStageFlag } from '@/react/common/helpers';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const PAGES = {
  TYPE: 'type',
  FORM: 'form',
};

const TYPES = {
  BLANK: 'blank',
  SAMPLE_DATA: 'demo',
};

const ComponentBoxModal = ({
  show,
  allComponents,
  component,
  readOnly,
  showButtons = true,
  hasConfigurations = false,
  allowFolder = false,
  folders = List(),
  onCreate,
  onHide,
}: {
  show: boolean;
  readOnly: boolean;
  allComponents?: Map<string, any>;
  component: Map<string, any>;
  onHide: () => void;
  onCreate?: (componentId: string, configId: string) => Promise<any>;
  hasConfigurations?: boolean;
  allowFolder?: boolean;
  showButtons?: boolean;
  folders?: List<string>;
}) => {
  const isFlow = component.get('id') === KEBOOLA_ORCHESTRATOR;
  const hasSampleData = !!allComponents && hasSampleDataAvailable(allComponents, component);
  const showOnlyForm = isFlow || (!readOnly && showButtons && hasConfigurations);
  const flags = component.get('flags', List());

  const [page, setPage] = useState(PAGES.TYPE);
  const [type, setType] = useState(TYPES.BLANK);
  const [name, setName] = useState('');
  const [folder, setFolder] = useState('');
  const [description, setDescription] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  const renderComponentInfo = () => {
    if (!component.has('data')) {
      return (
        <Modal.Body>
          <Loader className="icon-addon-right" />
          Loading component details...
        </Modal.Body>
      );
    }

    return (
      <>
        <Modal.Body>
          {component.get('flags').includes(EXPERIMENTAL) && <ExperimentalComponentInfoAlert />}
          <div className="flex-container flex-start mb-2">
            <div className="tw-mr-10">
              <p className="text-muted">Type</p>
              <div className="f-16">
                <ComponentType
                  labelClassName="font-medium color-main"
                  type={component.get('type')}
                />
              </div>
            </div>
            <div className="tw-mr-10">
              <p className="text-muted">Author</p>
              <div className="f-16">
                <ComponentAuthor
                  is3rdParty={flags.includes('3rdParty')}
                  contact={component.getIn(['data', 'vendor', 'contact'], List())}
                />
              </div>
            </div>
            {hasStageFlag(component.get('flags')) && (
              <div>
                <p className="text-muted">Stage</p>
                <ComponentBadges flags={component.get('flags')} className="tw-mb-0.5" />
              </div>
            )}
          </div>
          <p className="text-muted">{component.get('description')}</p>
          <ComponentLicence href={component.getIn(['data', 'vendor', 'licenseUrl'])} />
          {(component.get('documentationUrl') ||
            component.getIn(['data', 'vendor', 'licenseUrl'])) && (
            <div className="mt-2">
              {component.get('documentationUrl') && (
                <p>
                  <FontAwesomeIcon icon="book-blank" className="text-muted icon-addon-right" />
                  <Link href={component.get('documentationUrl')}>Documentation</Link>
                </p>
              )}
              {component.getIn(['data', 'vendor', 'licenseUrl']) && (
                <p>
                  <FontAwesomeIcon icon="file-lines" className="text-muted icon-addon-right" />
                  <Link href={component.getIn(['data', 'vendor', 'licenseUrl'])}>Licence</Link>
                </p>
              )}
            </div>
          )}
        </Modal.Body>
        {showButtons && !readOnly && (
          <Modal.Footer>
            <ButtonGroup className="tw-w-full">
              <Button
                className="tw-w-full"
                onClick={() => {
                  setType(TYPES.BLANK);
                  setPage(PAGES.FORM);
                }}
              >
                <FontAwesomeIcon icon="plus" fixedWidth />
                Connect to my data
              </Button>
              {hasSampleData && (
                <Button
                  className="tw-w-full"
                  variant="secondary"
                  onClick={() => {
                    setType(TYPES.SAMPLE_DATA);
                    setPage(PAGES.FORM);
                  }}
                >
                  <FontAwesomeIcon icon="plus" fixedWidth />
                  Use with demo data
                </Button>
              )}
            </ButtonGroup>
          </Modal.Footer>
        )}
      </>
    );
  };

  const renderForm = () => {
    return (
      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();

          setIsCreating(true);
          Promise.resolve()
            .then(() => {
              return type === TYPES.SAMPLE_DATA
                ? createSampleDataConfiguration(component.get('id'), name, description)
                : createConfiguration(component.get('id'), name, description);
            })
            .tap((response) => {
              if (!!folder) {
                return saveFolderToMetadata(component.get('id'), response.id, folder);
              }
            })
            .then((response) => {
              const componentId =
                type === TYPES.SAMPLE_DATA ? KEBOOLA_EX_SAMPLE_DATA : component.get('id');

              if (onCreate) {
                return onCreate(componentId, response.id).then(onHide);
              }

              const linkParams = resolveRouterLinkParams(
                componentId,
                response.id,
                null,
                ApplicationStore.hasFlows(),
              );

              RoutesStore.getRouter().transitionTo(linkParams?.to, linkParams?.params);
              return null;
            })
            .catch((error) => {
              setIsCreating(false);
              throw error;
            });
        }}
      >
        <Modal.Body className="tw-flex tw-flex-col tw-gap-4">
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <TextInput
              id="name"
              autoFocus
              value={name}
              onChange={setName}
              placeholder="Name your configuration"
              disabled={isCreating || readOnly}
              variant="secondary"
            />
          </FormGroup>

          <FormGroup>
            <Label htmlFor="description">
              Description <Label.Optional />
            </Label>
            <FormControl
              id="description"
              componentClass="textarea"
              value={description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
              placeholder="Describe your configuration"
              disabled={isCreating || readOnly}
            />
          </FormGroup>
          {allowFolder && (
            <FormGroup>
              <Label htmlFor="folder">
                Folder <Label.Optional />
              </Label>
              <Select
                id="folder"
                allowCreate
                value={folder}
                onChange={setFolder}
                placeholder="Select or create a folder"
                promptTextCreator={(label) => `Create folder "${label}"`}
                options={folders
                  .sortBy((folder) => folder?.toLowerCase())
                  .map((folder) => ({ label: folder, value: folder }))
                  .toArray()}
              />
            </FormGroup>
          )}
        </Modal.Body>
        {showButtons && !readOnly && (
          <Modal.Footer>
            <ButtonGroup className="tw-w-full">
              {!showOnlyForm && (
                <IconButton
                  variant="outline"
                  icon="arrow-left"
                  onClick={() => setPage(PAGES.TYPE)}
                />
              )}
              <Button
                type="submit"
                className={cn(
                  'tw-w-full',
                  type === TYPES.SAMPLE_DATA
                    ? `${externalClasses.useDemoDataComponent(component)}`
                    : `${externalClasses.connectToMyDataComponent(component)}`,
                )}
                disabled={isCreating || name === null}
              >
                {isCreating ? <Loader /> : <FontAwesomeIcon icon="circle-check" fixedWidth />}
                Create {isFlow ? 'Flow' : 'Configuration'}
              </Button>
            </ButtonGroup>
          </Modal.Footer>
        )}
      </form>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      onEnter={() => {
        setPage(showOnlyForm ? PAGES.FORM : PAGES.TYPE);
        setType(TYPES.BLANK);
        setName(
          isFlow
            ? `My flow`
            : `My ${component.get('name')} ${getNewComponentTypeLabel(component.get('type'))}`,
        );
        setDescription('');
        ensureComponentWithDetails(component.get('id'));
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{component.get('name')}</Modal.Title>
        {component.get('id') === KEBOOLA_ORCHESTRATOR ? (
          <ModalIcon icon="bars-staggered" color="green" bold />
        ) : (
          <ComponentIcon className="circle-icon bigger-icon modal-icon" component={component} />
        )}
      </Modal.Header>
      {page === PAGES.TYPE ? renderComponentInfo() : renderForm()}
    </Modal>
  );
};

export default ComponentBoxModal;
