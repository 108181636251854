import type { GenericFetchClient } from '../storageClient';

import { mapBranchMetadata } from './mappers';
import type { BranchMetadataRaw } from './types';

export class Branches {
  constructor(private client: GenericFetchClient) {}

  async getBranchMetadata(id: string, signal?: AbortSignal) {
    const { data } = await this.client.get<BranchMetadataRaw[], { id: string }>(
      '/branch/{id}/metadata',
      { path: { id } },
      { signal },
    );

    return data.map(mapBranchMetadata);
  }
}
