import TrashHeaderButton from './react/components/TrashHeaderButtons';
import Index from './react/pages/TrashIndex';
import { routeNames } from './constants';

export default {
  name: routeNames.SETTINGS_TRASH,
  title: 'Recently Deleted',
  path: 'settings-trash',
  defaultRouteHandler: Index,
  headerButtonsHandler: TrashHeaderButton,
};
