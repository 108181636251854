import React from 'react';
import type { Map } from 'immutable';

import { Alert } from '@keboola/design';

import SandboxActions from '@/modules/sandboxes/Actions';
import Confirm from '@/react/common/Confirm';

const ResetPassword = (props: { id: string; configId: string; config?: Map<string, any> }) => {
  const [isResetting, setIsResetting] = React.useState(false);

  const handleConfirm = React.useCallback(() => {
    const additionalParams = !!props.config?.hasIn(['configuration', 'authorization'])
      ? { authorization: props.config.getIn(['configuration', 'authorization']).toJS() }
      : {};

    setIsResetting(true);
    return SandboxActions.resetPassword(props.id, props.configId, additionalParams).finally(() => {
      return setIsResetting(false);
    });
  }, [props.id, props.configId, props.config]);

  return (
    <Alert className="tw-mt-4">
      To reset password{' '}
      <Confirm
        icon="key"
        buttonType="primary"
        title={`Reset Workspace Password`}
        text={`Are you sure you want to reset the workspace password?`}
        buttonLabel={isResetting ? 'Resetting...' : 'Reset'}
        onConfirm={handleConfirm}
        isLoading={isResetting}
        closeAfterResolve
      >
        <button className="btn btn-link btn-link-inline">click here</button>
      </Confirm>
      .
    </Alert>
  );
};

export default ResetPassword;
