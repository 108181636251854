import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ButtonInline, Tooltip } from '@keboola/design';

import Loader from '@/react/common/Loader';

const GenerateButton = (props: {
  isSaving: boolean;
  handleGenerate?: () => void;
  isGenerating?: boolean;
  variant?: React.ComponentProps<typeof ButtonInline>['variant'];
}) => {
  return (
    <Tooltip
      placement="top"
      type="explanatory"
      tooltip="Automatically generates a description using advanced artificial intelligence algorithms. The generated description may not be perfect, but it can serve as a starting point for further editing."
      forceHide={props.isGenerating}
    >
      <ButtonInline
        variant={props.variant}
        disabled={props.isGenerating || props.isSaving}
        onClick={props.handleGenerate}
      >
        {props.isGenerating ? (
          <Loader />
        ) : (
          <FontAwesomeIcon fixedWidth icon="brain-circuit" className="color-cyan" />
        )}
        Generate description
      </ButtonInline>
    </Tooltip>
  );
};

export default GenerateButton;
