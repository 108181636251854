import React from 'react';
import { Button, ButtonToolbar, ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { Alert, cn, Link } from '@keboola/design';

import LoadOptions from '@/modules/sandboxes/components/LoadOptions';
import type { SANDBOX_TYPE } from '@/modules/sandboxes/Constants';
import { CONTAINER_BASED } from '@/modules/sandboxes/Constants';
import { prepareSandboxUrl } from '@/modules/sandboxes/helpers';
import Checkbox from '@/react/common/Checkbox';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import { matchByWords } from '@/utils';
import WorkspaceCredentials from './WorkspaceCredentials';

type Props = {
  forceType?: (typeof SANDBOX_TYPE)[keyof typeof SANDBOX_TYPE] | null;
  tempData: Map<string, any>;
  onSetTempData: (key: string, value: unknown) => void;
  sourceTransformation: Map<string, any>;
  simple: boolean;
  sandboxType: string;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  workspaces: Map<string, any>;
  onFindWorkspaceDetail: (id: string) => Map<string, any>;

  // state
  isLoadingWorkspaces: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  error: React.ReactNode | null;
  isLoadingWorkspace: boolean;
};

const UpdateModal = ({
  forceType,
  tempData,
  onSetTempData,
  simple,
  isLoadingWorkspaces,
  isLoading,
  isLoaded,
  error,
  sourceTransformation,
  isLoadingWorkspace,
  sandboxType,
  onSubmit,
  workspaces,
  onFindWorkspaceDetail,
}: Props) => {
  const options = workspaces
    .filter((workspace) => {
      return workspace.get('type') === forceType || workspace.get('type') === sandboxType;
    })
    .map((workspace) => ({
      value: workspace.getIn(['configuration', 'id']),
      label:
        CONTAINER_BASED.includes(workspace.get('type')) && !workspace.get('active') ? (
          <>
            <FontAwesomeIcon
              icon="moon"
              color="#fff"
              transform="shrink-5"
              className="bg-color-purple align-bottom f-16 img-rounded tw-w-4"
            />{' '}
            {workspace.getIn(['configuration', 'name'])}
          </>
        ) : (
          workspace.getIn(['configuration', 'name'])
        ),
      name: workspace.getIn(['configuration', 'name']),
    }))
    .toArray();

  const isDisabled = isLoadingWorkspaces || isLoading || !options.length;
  const placeholder = isLoadingWorkspaces
    ? 'Loading existing workspaces...'
    : options.length > 0
      ? 'Select workspace'
      : 'No workspace created yet';

  const workspace = onFindWorkspaceDetail(tempData.get('workspace'));

  return (
    <form onSubmit={onSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>Select Existing Workspace</Modal.Title>
        <ModalIcon icon="box" color="green" bold />
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <ControlLabel>Workspaces</ControlLabel>
          <Select
            options={options}
            value={tempData.get('workspace', '')}
            onChange={(value: string) => onSetTempData('workspace', value)}
            placeholder={placeholder}
            isLoading={isLoadingWorkspaces}
            disabled={isDisabled}
            filterOption={({ name, value }, filter) => matchByWords([name, value], filter)}
          />
        </FormGroup>
        {CONTAINER_BASED.includes(sandboxType) ? (
          <FormGroup>
            <Checkbox
              checked={tempData.get('preserve', false)}
              onChange={(checked) => onSetTempData('preserve', checked)}
              disabled={isDisabled}
            >
              {sourceTransformation.isEmpty()
                ? 'Preserve input and output mappings in the workspace'
                : 'Merge input and output mappings from the transformation to the workspace'}
            </Checkbox>
          </FormGroup>
        ) : (
          <LoadOptions
            preserve={tempData.get('preserve', false)}
            onChange={(preserve) => onSetTempData('preserve', preserve)}
          />
        )}
        <WorkspaceCredentials
          workspace={workspace}
          simple={simple}
          isLoadingWorkspace={isLoadingWorkspace}
        />
        {error && (
          <Alert variant="error" className="tw-mb-5">
            {error}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar className="block">
          <Button
            type="submit"
            bsStyle="success"
            disabled={isLoadingWorkspaces || !options.length || !workspace || isLoaded}
          >
            {isLoading ? (
              <Loader className="icon-addon-right" />
            ) : (
              <FontAwesomeIcon icon="circle-check" className="icon-addon-right" fixedWidth />
            )}
            {isLoaded ? 'Loaded' : isLoading ? 'Loading data...' : 'Load data'}
          </Button>
          <Link
            href={workspace ? prepareSandboxUrl(workspace) : ''}
            className={cn('btn btn-success', {
              'disabled btn-disabled': isDisabled || !workspace || !isLoaded,
            })}
          >
            <FontAwesomeIcon icon="circle-play" className="icon-addon-right" fixedWidth />
            Connect
          </Link>
        </ButtonToolbar>
      </Modal.Footer>
    </form>
  );
};

export default UpdateModal;
