import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';

import ClientInfo from '@/modules/sandboxes/components/ClientInfo';
import { NEED_CLIENT_TO_CONNECT } from '@/modules/sandboxes/Constants';
import { prepareCredentialsData } from '@/modules/sandboxes/helpers';
import CredentialsBox from '@/react/common/CredentialsBox';
import Loader from '@/react/common/Loader';

const WorkspaceCredentials = ({
  workspace,
  simple,
  isLoadingWorkspace,
}: {
  workspace: Map<string, any> | null;
  simple: boolean;
  isLoadingWorkspace: boolean;
}) => {
  if (!workspace || simple) {
    return null;
  }

  return (
    <>
      <FormGroup>
        <ControlLabel className="mb-1">Workspace Credentials</ControlLabel>
        {isLoadingWorkspace ? (
          <div>
            <Loader /> Loading data...
          </div>
        ) : (
          <CredentialsBox
            rows={prepareCredentialsData(workspace)}
            disabled={!workspace.get('active')}
            noBorder
          />
        )}
      </FormGroup>
      {NEED_CLIENT_TO_CONNECT.includes(workspace.get('type')) && <ClientInfo />}
    </>
  );
};

export default WorkspaceCredentials;
