import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { cn, Tooltip } from '@keboola/design';

import { LOCAL_STORAGE_FAV_PROJECT_KEY_PART } from '@/constants/localStorageKeys';
import useLocalStorage from '@/react/hooks/useLocalStorage';
import type { Project } from './types';

const Favourite = ({
  isCurrentProject,
  isDisabled,
  project,
  currentUser,
  children,
}: {
  isCurrentProject: boolean;
  isDisabled: boolean;
  project: Project;
  currentUser: Map<string, any>;
  children: React.ReactNode;
}) => {
  const [favouriteProjects, setFavouriteProjects] = useLocalStorage<Pick<Project, 'id'>[]>(
    `${LOCAL_STORAGE_FAV_PROJECT_KEY_PART}-${currentUser.get('id')}`,
    [],
  );

  const isFavourite = favouriteProjects.some((favProject) => favProject.id === project.id);

  return (
    <span
      className={cn(
        { active: isCurrentProject, disabled: isDisabled },
        'tw-group/project tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-p-2',
      )}
    >
      {children}
      <Tooltip tooltip={isFavourite ? 'Remove from starred' : 'Add to starred'} placement="top">
        <FontAwesomeIcon
          icon="star"
          onClick={(e) => {
            e.stopPropagation();

            setFavouriteProjects(
              isFavourite
                ? favouriteProjects.filter((favProject) => favProject.id !== project.id)
                : [...favouriteProjects, { id: project.id }],
            );
          }}
          className={cn(
            'hover:tw-cursor-pointer hover:tw-text-warning-500',
            isFavourite
              ? 'tw-flex tw-text-warning-500'
              : 'tw-hidden tw-text-neutral-400 group-hover/project:tw-flex',
          )}
        />
      </Tooltip>
    </span>
  );
};

export default Favourite;
