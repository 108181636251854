import Promise from 'bluebird';

import { TRANSFORMATION } from '@/constants/componentIds';
import { APP_ROUTE } from '@/constants/routeNames';
import * as BillingActions from '@/modules/billing/actions';
import componentsActions from '@/modules/components/InstalledComponentsActionCreators';
import metadataActionCreators from '@/modules/components/MetadataActionCreators';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import HomeHeaderButtons from './react/HomeHeaderButtons';
import Home from './react/Index';
import ActionCreators from './ActionCreators';
import { isValidTarget, mapTargetToRoute } from './helpers';

const pollableActions = () => {
  // no need to wait for these actions to finish
  ActionCreators.loadJobs();
  ActionCreators.loadActivities();
  ActionCreators.loadLatestGitEvent();

  return Promise.all([
    ApplicationStore.hasPayAsYouGo() && BillingActions.getCredits(),
    componentsActions.reloadInstalledComponents({ include: 'configuration' }),
  ]);
};

export default {
  path: 'dashboard',
  name: APP_ROUTE,
  title: 'Overview',
  defaultRouteHandler: Home,
  headerButtonsHandler:
    !ApplicationStore.hasInvalidCustomBackend() &&
    !ApplicationStore.isDemoPreview() &&
    HomeHeaderButtons,
  onEnter: ({ location }, replace, callback) => {
    if (isValidTarget(location.query.target)) {
      const { name, params } = mapTargetToRoute(location.query.target);
      RoutesStore.getRouter().replaceTo(name, params, location.query);
    }

    callback();
  },
  requireData: [
    pollableActions,
    () => {
      // metadata is not used in development branch, so we can skip loading them
      if (DevBranchesStore.isDevModeActive()) {
        return Promise.resolve();
      }

      return metadataActionCreators.loadProjectBranchMetadata(
        DevBranchesStore.getDefaultBranchId(),
      );
    },
    () => {
      if (!ApplicationStore.hasNewTransformationsOnly()) {
        return componentsActions.loadComponentConfigsData(TRANSFORMATION);
      }

      return Promise.resolve();
    },
  ],
  poll: {
    skipFirst: true,
    action: pollableActions,
  },
};
