import React from 'react';
import { FormGroup, Modal } from 'react-bootstrap';
import { Promise } from 'bluebird';
import { fromJS, List, Map } from 'immutable';

import { HelpBlock, Label } from '@keboola/design';

import { parseCurl } from '@/modules/ex-generic/actions';
import { type ACTIVE_MENU } from '@/modules/ex-generic/constants';
import {
  extractPlaceholders,
  flattenJobs,
  prepareNewEndpoint,
  prepareRealJobPath,
} from '@/modules/ex-generic/helpers';
import CodeEditor from '@/react/common/CodeEditor';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import fromJSOrdered from '@/utils/fromJSOrdered';
import ParentForm from './ParentForm';
import UrlPathControl from './UrlPathControl';
import UrlPreview from './UrlPreview';

const NewEndpoint = (props: {
  show: boolean;
  parameters: Map<string, any>;
  onSave: (parameters: Map<string, any>, changeDescription: string) => Promise<any>;
  setMenu: (menu: ACTIVE_MENU) => void;
  onHide: () => void;
}) => {
  const [formData, setFormData] = React.useState<Map<string, any>>(Map());
  const [isSaving, setSaving] = React.useState(false);

  const flattenedJobs = flattenJobs(props.parameters);
  const pathAlreadyUsed = flattenedJobs.some(
    (job: Map<string, any>) => job.get('endpoint') === formData.get('endpoint'),
  );

  const isDisabled = () => {
    if (!formData.get('endpoint') && !formData.get('curl')) {
      return true;
    }

    if (formData.get('childJob') && !formData.get('parent')) {
      return true;
    }

    return false;
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSaving(true);
    const parentPath: number[] | null = flattenedJobs
      .find((job: Map<string, any>) => job.get('endpoint') === formData.get('parent'))
      ?.get('endpointPath');

    return Promise.resolve()
      .then(() => {
        return !formData.get('curl')
          ? prepareNewEndpoint(formData.get('endpoint', '') as string, flattenedJobs)
          : parseCurl(props.parameters, formData.get('curl')).then(fromJS);
      })
      .then((job) => {
        const placeholders = extractPlaceholders(job.get('endpoint', ''));

        if (!parentPath || !placeholders.length) {
          return job;
        }

        return job.set(
          'placeholders',
          placeholders.reduce((accumulator: Map<string, any>, placeholder: string) => {
            return accumulator.set(placeholder, '');
          }, Map()),
        );
      })
      .then((job) => {
        const jobPath = parentPath
          ? ['config', 'jobs', ...prepareRealJobPath(parentPath), 'children']
          : ['config', 'jobs'];

        return props
          .onSave(
            props.parameters.updateIn(jobPath, List(), (jobs) => {
              return jobs.push(job);
            }),
            'Add new endpoint',
          )
          .then((configData) => {
            const jobs = fromJSOrdered(configData.parameters).getIn(jobPath, List());
            const newJobIndex = jobs.count() - 1;

            props.setMenu(parentPath ? [...parentPath, newJobIndex] : [newJobIndex]);
          });
      })
      .then(props.onHide)
      .finally(() => setSaving(false));
  };

  return (
    <Modal show={props.show} onHide={props.onHide} onEnter={() => setFormData(Map())}>
      <form onSubmit={handleSave}>
        <Modal.Header closeButton>
          <Modal.Title>Create Endpoint</Modal.Title>
          <ModalIcon.Plus />
        </Modal.Header>
        <Modal.Body>
          <UrlPreview
            path={formData.get('endpoint', '')}
            baseUrl={props.parameters.getIn(['api', 'baseUrl'], '')}
          />
          <UrlPathControl
            autoFocus
            editing={formData}
            setEditing={setFormData}
            showUsedWarning={pathAlreadyUsed && props.show}
          />
          <hr className="split" />
          <FormGroup>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <Label>Import from cURL command</Label>
            <CodeEditor
              value={formData.get('curl', '')}
              onChange={(value: string) => setFormData(formData.set('curl', value))}
              options={{
                lineNumbers: false,
                mode: 'text/x-sh',
                placeholder: 'curl https://my-api.com/v1/endpoint',
                screenReaderLabel: 'Curl Command',
              }}
            />
            <HelpBlock className="tw-mt-1">
              The endpoint will be created and prefilled based on the cURL command.
            </HelpBlock>
          </FormGroup>
          <hr />
          <ParentForm
            readOnly={false}
            parameters={props.parameters}
            editing={formData}
            setEditing={setFormData}
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            showCancel={false}
            isSaving={isSaving}
            saveButtonType="submit"
            saveLabel="Create Endpoint"
            isDisabled={isDisabled()}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default NewEndpoint;
