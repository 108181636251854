/* eslint-disable */
import string from '@/utils/string';
import CodeMirror from 'codemirror';
import _ from 'underscore';

var tables;
var variables;
var keywords;
var identifierQuote;
var Pos = CodeMirror.Pos;
var cmpPos = CodeMirror.cmpPos;

function getMode(editor) {
  if (editor.doc.modeOption === 'sfsql') return 'text/x-sql';
  return editor.doc.modeOption;
}

function getKeywords(editor) {
  var mode = CodeMirror.resolveMode(getMode(editor));
  if (['sfsql', 'sql'].includes(mode.name)) {
    return mode.keywords;
  } else if (!keywords && ['python', 'r', 'julia'].includes(mode.name)) {
    return CodeMirror.hintWords?.[mode.name] || [];
  }
  return mode.keywords;
}

function getIdentifierQuote(editor) {
  var mode = getMode(editor);
  return CodeMirror.resolveMode(mode).identifierQuote || '`';
}

function getText(item) {
  return typeof item == 'string' ? item : item.text;
}

function wrapTable(name, value) {
  if (_.isArray(value)) value = { columns: value };
  if (!value.text) value.text = `"${name}"`;
  return value;
}

function parseTables(input) {
  var result = {};
  if (_.isArray(input)) {
    for (var i = input.length - 1; i >= 0; i--) {
      var item = input[i];
      result[getText(item).toUpperCase()] = wrapTable(getText(item), item);
    }
  } else if (input) {
    for (var name in input) result[name.toUpperCase()] = wrapTable(name, input[name]);
  }
  return result;
}

function parseVariables(variables) {
  return variables.map((variable) => {
    return {
      text: `{{${variable.name}}}`,
      displayText: `{{${variable.name} (${variable.value})}}`,
    };
  });
}

function getTable(name) {
  return tables[string.trim(name.toUpperCase(), '"')];
}

function match(string, word) {
  var len = string.length;
  var sub = getText(word).substring(0, len);
  return string.toUpperCase() === sub.toUpperCase();
}

function addMatches(result, search, wordlist, formatter) {
  if (_.isArray(wordlist)) {
    for (var i = 0; i < wordlist.length; i++)
      if (match(search, wordlist[i])) result.push(formatter(wordlist[i]));
  } else {
    for (var word in wordlist)
      if (wordlist.hasOwnProperty(word)) {
        var val = wordlist[word];
        if (!val || val === true) val = word;
        else val = val.displayText ? { text: val.text, displayText: val.displayText } : val.text;
        if (match(search, val)) result.push(formatter(val));
      }
  }
}

function cleanName(name) {
  // Get rid name from identifierQuote and preceding dot(.)
  if (name.charAt(0) == '.') {
    name = name.substring(1);
  }
  // replace doublicated identifierQuotes with single identifierQuotes
  // and remove single identifierQuotes
  var nameParts = name.split(identifierQuote + identifierQuote);
  for (var i = 0; i < nameParts.length; i++)
    nameParts[i] = nameParts[i].replace(new RegExp(identifierQuote, 'g'), '');
  return nameParts.join(identifierQuote);
}

function insertIdentifierQuotes(name) {
  var nameParts = getText(name).split('.');
  for (var i = 0; i < nameParts.length; i++)
    nameParts[i] =
      identifierQuote +
      // doublicate identifierQuotes
      nameParts[i].replace(new RegExp(identifierQuote, 'g'), identifierQuote + identifierQuote) +
      identifierQuote;
  var escaped = nameParts.join('.');
  if (typeof name == 'string') return escaped;
  name = _.clone(name);
  name.text = escaped;
  return name;
}

function nameCompletion(cur, token, result, editor) {
  // Try to complete table, column names and return start position of completion
  var useIdentifierQuotes = false;
  var nameParts = [];
  var start = token.start;
  var cont = true;
  while (cont) {
    cont = token.string.charAt(0) == '.';
    useIdentifierQuotes = useIdentifierQuotes || token.string.charAt(0) == identifierQuote;

    start = token.start;
    nameParts.unshift(cleanName(token.string));

    token = editor.getTokenAt(Pos(cur.line, token.start));
    if (token.string == '.') {
      cont = true;
      token = editor.getTokenAt(Pos(cur.line, token.start));
    }
  }

  // Try to complete table names
  var string = nameParts.join('.');
  addMatches(result, string, tables, function (w) {
    return useIdentifierQuotes ? insertIdentifierQuotes(w) : w;
  });

  // Try to complete columns
  string = nameParts.pop();
  var table = nameParts.join('.');
  var columns = getTable(table);
  if (columns && columns.columns) columns = columns.columns;

  if (columns) {
    addMatches(result, string, columns, function (w) {
      var tableInsert = table;
      if (typeof w == 'string') {
        w = tableInsert + '.' + w;
      } else {
        w = _.clone(w);
        w.text = tableInsert + '.' + w.text;
      }
      return useIdentifierQuotes ? insertIdentifierQuotes(w) : w;
    });
  }

  return start;
}

function eachWord(lineText, f) {
  var words = lineText.split(/\s+/);
  for (var i = 0; i < words.length; i++) if (words[i]) f(words[i].replace(/[,;]/g, ''));
}

function objectOrClass(w, className) {
  if (typeof w === 'object') {
    w.className = className;
  } else {
    w = { text: w, className: className };
  }
  return w;
}

const helper = function (editor, options) {
  var result = [];
  var cur = editor.getCursor();
  var token = editor.getTokenAt(cur);

  tables = parseTables(options?.tables);
  variables = parseVariables(options?.variables || []);
  keywords = getKeywords(editor);
  identifierQuote = getIdentifierQuote(editor);

  var start;
  var end;
  var search;
  if (token.end > cur.ch) {
    token.end = cur.ch;
    token.string = token.string.slice(0, cur.ch - token.start);
  }

  if (token.string.match(/^[.`"\{\w@]\w*$/)) {
    search = token.string;
    start = token.start;
    end = token.end;
  } else {
    start = end = cur.ch;
    search = '';
  }

  if (search.charAt(0) == '.' || search.charAt(0) == identifierQuote) {
    start = nameCompletion(cur, token, result, editor);
  } else {
    addMatches(result, search, tables, function (w) {
      return objectOrClass(w, 'CodeMirror-hint-table');
    });
    addMatches(result, search, variables, function (w) {
      return objectOrClass(w, 'CodeMirror-hint-variable');
    });
    addMatches(result, search, keywords, function (w) {
      if (['sql', 'sfsql'].includes(CodeMirror.resolveMode(getMode(editor)).name)) {
        return objectOrClass(w.toUpperCase(), 'CodeMirror-hint-keyword');
      }
      return objectOrClass(w, 'CodeMirror-hint-keyword');
    });
  }

  // check start, end for tables and variables
  if (search.charAt(0) == '"') {
    end++;
  } else if (search.charAt(0) == '{') {
    if (editor.getTokenAt(Pos(cur.line, token.start)).string == '{') {
      start--;
    }
    if (editor.getTokenAt(Pos(cur.line, token.end + 1)).string === '}') {
      end++;
    }
    if (editor.getTokenAt(Pos(cur.line, token.end + 2)).string === '}') {
      end++;
    }
  }

  return { list: result, from: Pos(cur.line, start), to: Pos(cur.line, end) };
};

CodeMirror.registerHelper('hint', 'sql', helper);
CodeMirror.registerHelper('hint', 'sfsql', helper);
CodeMirror.registerHelper('hint', 'plsql', helper);
CodeMirror.registerHelper('hint', 'python', helper);
CodeMirror.registerHelper('hint', 'julia', helper);
CodeMirror.registerHelper('hint', 'r', helper);
