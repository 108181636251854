import React from 'react';

import { ShareTablesIcon } from '@/react/common/icons';
import { TableIconAction } from './TableIconAction';

type Props = {
  tooltipText: string;
  onClick: () => void;
  disabled?: boolean;
};

export const ShareTables = ({ tooltipText, onClick, disabled = false }: Props) => (
  <TableIconAction
    tooltip={tooltipText}
    onClick={onClick}
    disabled={disabled}
    icon={<ShareTablesIcon />}
  />
);
