import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import {
  KEBOOLA_EX_DB_HIVE,
  KEBOOLA_EX_DB_HIVE_CSAS,
  KEBOOLA_EX_DB_HIVE_CSAS_TEST,
} from '@/constants/componentIds';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import ApplicationStore from '@/stores/ApplicationStore';
import { matchByWords } from '@/utils';
import generateId from '@/utils/generateId';
import string from '@/utils/string';
import { hasHiveValidCredentials } from './react/pages/credentials/helpers';
import * as templateFields from './templates/credentials';
import { getFields } from './templates/credentials';
import getDefaultPort from './templates/defaultPorts';
import { hasSshTunnel, supportConfigRows } from './helpers';

const defaultSshPort = 22;

const ROW_CONFIGURATION_TYPE = 'row';
const STANDARD_CONFIGURATION_TYPE = 'standard';

export const SOURCE_TABLES_PATH = ['sourceTables', 'data'];
export const INCREMENTAL_CANDIDATES_PATH = ['sourceTables', 'incrementalCandidates'];
export const SOURCE_TABLES_ERROR_PATH = ['sourceTables', 'error'];
export const LOADING_SOURCE_TABLES_PATH = ['sourceTables', 'loading'];
export const LOADING_COLUMNS_PATH = ['sourceTables', 'columnsLoading'];

function queryFromRow(row) {
  const rowConfig = row.getIn(['configuration', 'parameters'], Map());

  let query = rowConfig.merge({
    id: row.get('id'),
    name: row.get('name'),
    enabled: !row.get('isDisabled'),
    state: row.get('state'),
  });

  if (query.has('query')) {
    query = query.set('advancedMode', true);
  } else if (!query.get('table')) {
    query = query.set('table', null);
  }

  return query;
}

function fetch(componentId, configId) {
  const config = InstalledComponentsStore.getConfigData(componentId, configId) || Map();
  const rows = ConfigurationRowsStore.getRows(componentId, configId);
  const componentGlobalConfig = ComponentsStore.getComponent(componentId).getIn(
    ['data', 'image_parameters', 'global_config'],
    Map(),
  );

  if (supportConfigRows(componentId) && !config.hasIn(['parameters', 'tables'])) {
    return {
      config: config || Map(),
      parameters: config.get('parameters', Map()),
      queries: rows.map(queryFromRow).toList(),
      localState: InstalledComponentsStore.getLocalState(componentId, configId) || Map(),
      configurationType: ROW_CONFIGURATION_TYPE,
      componentGlobalConfig,
      rows,
    };
  }

  return {
    config: config || Map(),
    parameters: config.get('parameters', Map()),
    localState: InstalledComponentsStore.getLocalState(componentId, configId) || Map(),
    configurationType: STANDARD_CONFIGURATION_TYPE,
    componentGlobalConfig,
    rows,
  };
}

function isValidQuery(query) {
  const advancedMode = query.get('advancedMode', false);
  const nameValid = query.get('name', '').trim().length > 0;
  const queryValid = query.get('query', '').trim().length > 0;
  const tableValid = query.getIn(['table', 'tableName'], '').trim().length > 0;
  return nameValid && ((advancedMode && queryValid) || (!advancedMode && tableValid));
}

export function createStore(componentId, configId) {
  const data = fetch(componentId, configId);
  return {
    hasValidCredentials(credentials) {
      if (!credentials) {
        return false;
      } else if (
        [KEBOOLA_EX_DB_HIVE, KEBOOLA_EX_DB_HIVE_CSAS, KEBOOLA_EX_DB_HIVE_CSAS_TEST].includes(
          componentId,
        )
      ) {
        return hasHiveValidCredentials(this.getGlobalDbCredentials().mergeDeep(credentials));
      }

      const configCredentials = this.getCredentials();
      const hasSSH = hasSshTunnel(componentId);
      const fields = templateFields.getFields(componentId);
      const validGeneralCreds = _.reduce(
        fields,
        (memo, field) => {
          let value = credentials.get(field.name, '');
          if (value) {
            value = value.toString();
          }
          const alreadySaved = !_.isEmpty(configCredentials.get(field.name));
          const isValueValid =
            !field.required || !_.isEmpty(value) || (field.protected && alreadySaved);
          return memo && isValueValid;
        },
        true,
      );
      const ssh = credentials.get('ssh', Map());
      const sshFields = [
        { name: 'sshHost', type: 'text' },
        { name: 'user', type: 'text' },
        { name: 'sshPort', type: 'number' },
      ];
      const isValidSSH = _.reduce(
        sshFields,
        (memo, field) => {
          let value = ssh.get(field.name, '');
          if (value) {
            value = value.toString();
          }
          return memo && !_.isEmpty(value);
        },
        true,
      );
      const hasKeys = ssh.hasIn(['keys', '#private']);
      let sshValid = true;
      if (hasSSH && ssh.get('enabled')) {
        sshValid = hasKeys && isValidSSH;
      }
      return validGeneralCreds && sshValid;
    },

    // Credentials -- start --
    getCredentials() {
      return data.parameters.get('db', Map());
    },

    getGlobalDbCredentials() {
      return data.componentGlobalConfig.get('db', Map());
    },

    isEditingCredentials() {
      return !!data.localState.get('editingCredentials');
    },

    isSavingCredentials() {
      return data.localState.get('isSavingCredentials', false);
    },

    isChangedCredentials() {
      return data.localState.get('isChangedCredentials', false);
    },

    getEditingCredentials() {
      return data.localState.get('editingCredentials');
    },

    getNewCredentials() {
      let defaultNewCredentials = data.parameters.get('db', Map());

      if (!defaultNewCredentials.get('port')) {
        defaultNewCredentials = defaultNewCredentials.set('port', getDefaultPort(componentId));
      }

      if (hasSshTunnel(componentId) && !defaultNewCredentials.getIn(['ssh', 'sshPort'])) {
        defaultNewCredentials = defaultNewCredentials.setIn(['ssh', 'sshPort'], defaultSshPort);
      }

      getFields(componentId).forEach((field) => {
        if (!defaultNewCredentials.get(field.name) && field.defaultValue) {
          defaultNewCredentials = defaultNewCredentials.set(field.name, field.defaultValue);
        }
      });

      return defaultNewCredentials;
    },
    // Credentials -- end --

    generateNewQuery(queryId = null, simpleSupport = true) {
      const ids = this.getQueries()
        .map((q) => q.get('id'))
        .toJS();
      let defaultQuery = {
        enabled: true,
        name: '',
        incremental: false,
        outputTable: '',
        table: '',
        columns: [],
        primaryKey: [],
        id: queryId ? queryId : generateId(ids).toString(),
      };
      if (!simpleSupport) {
        defaultQuery.advancedMode = true;
        defaultQuery.query = '';
      }
      const defaultNewQuery = fromJS(defaultQuery);
      data.localState.setIn(['newQueries', defaultNewQuery.get('id')], defaultNewQuery);
      return defaultNewQuery;
    },

    getNewQuery(queryId) {
      return data.localState.getIn(['newQueries', queryId]);
    },

    getNewQueries() {
      return data.localState.getIn(['newQueries']);
    },

    getNewQueriesIdsList() {
      return data.localState.getIn(['newQueriesIdsList'], List([]));
    },

    isEditingQuery(queryId) {
      return !!data.localState.getIn(['editingQueries', queryId]);
    },

    getEditingQuery(queryId) {
      return data.localState.getIn(['editingQueries', queryId]);
    },

    getEditingQueries() {
      return data.localState.getIn(['editingQueries']);
    },

    isSavingQuery(queryId) {
      return !!data.localState.getIn(['isSaving', queryId]);
    },

    isNewQuery(queryID) {
      return !!data.localState.getIn(['newQueries', queryID]);
    },

    isEditingQueryValid(queryId) {
      const query = this.getEditingQuery(queryId);
      if (!query) {
        return true;
      }
      return isValidQuery(query);
    },

    queryNameExists(query) {
      return !!this.getQueries().find(
        (q) => q.get('name') === query.get('name') && q.get('id') !== query.get('id'),
      );
    },

    getDefaultOutputTableId(name) {
      if (!name || name === '') {
        return '';
      }
      const qname = string.sanitizeKbcTableIdString(name);
      const bucketName = string.sanitizeKbcTableIdString(componentId);
      const fullBucketName = ApplicationStore.hasDisableLegacyBucketPrefix()
        ? `in.${bucketName}`
        : `in.c-${bucketName}`;
      const fullBucketNameWithConfigSuffix = `${fullBucketName}-${configId}`;
      if (this.shouldDestinationHaveOldFormat(fullBucketName)) {
        return `${fullBucketName}.${qname}`;
      }
      return `${fullBucketNameWithConfigSuffix}.${qname}`;
    },

    getSavedRowConfigData(queryId) {
      return data.rows
        .find((row) => row.get('id') === queryId?.toString(), null, Map())
        .get('configuration', Map());
    },

    getRowProcessors(queryId) {
      return data.localState.getIn(
        ['editingProcessors', queryId],
        this.getSavedRowProcessors(queryId),
      );
    },

    getSavedRowProcessors(queryId) {
      return this.getSavedRowConfigData(queryId).get('processors', Map());
    },

    shouldDestinationHaveOldFormat(fullBucketName) {
      if (data.parameters.get('tables', List()).count() === 0) {
        return false;
      }
      return (
        data.parameters
          .get('tables', List())
          .filter((table) => {
            return table.get('outputTable').indexOf(fullBucketName + '.') === 0;
          })
          .count() === data.parameters.get('tables', List()).count()
      );
    },

    getQueriesPendingActions() {
      return data.localState.getIn(['pending'], Map());
    },

    getQueriesFilter() {
      return data.localState.get('queriesFilter', '');
    },

    // -------- CONFIGDATA manipulation -----------------
    configData: data.config,
    rows: data.rows,

    getQueries() {
      if (!supportConfigRows(componentId) || data.parameters.has('tables')) {
        return this.prepareQueries(
          data.parameters
            .get('tables', List())
            .map((table) => table.set('id', table.get('id').toString())),
        );
      } else {
        return this.prepareQueries(data.queries);
      }
    },

    prepareQueries(tables) {
      return tables.map((q) => {
        let pk = q.get('primaryKey', List());
        if (!List.isList(pk)) {
          pk = List();
        }
        return q.set('primaryKey', pk);
      });
    },

    getQueriesFiltered() {
      const filterString = this.getQueriesFilter();
      const queries = this.getQueries();
      const filteredQueries = this.filterQueries(filterString, queries);
      return filteredQueries;
    },

    filterQueries(filterString, queries) {
      return queries.filter((query) => {
        return matchByWords([query.get('name'), query.get('outputTable')], filterString);
      });
    },

    getConfigQuery(qid) {
      if (this.isEditingQuery(qid)) {
        return this.getEditingQuery(qid);
      } else if (this.isNewQuery(qid)) {
        return this.getNewQuery(qid);
      }
      let query = this.getSavedOrNewQuery(qid);
      if (query.has('query')) {
        query = query.set('advancedMode', true);
      } else {
        query = query.set('advancedMode', false);
      }
      return query;
    },

    getSavedOrNewQuery(queryId) {
      return this.getQueries().find(
        (query) => query.get('id') === queryId,
        null,
        this.generateNewQuery(queryId),
      );
    },

    getQueryName(qid) {
      return this.getConfigQuery(qid).get('name');
    },

    getSourceTables() {
      return data.localState.getIn(SOURCE_TABLES_PATH);
    },

    getIncrementalCandidates() {
      return data.localState.getIn(INCREMENTAL_CANDIDATES_PATH);
    },

    getSourceTablesLoading() {
      return !!data.localState.getIn(LOADING_SOURCE_TABLES_PATH);
    },

    getQuickstartTables() {
      return data.localState.getIn(['quickstart', 'tables']);
    },

    getLocalState() {
      return data.localState;
    },

    isRowConfiguration() {
      return data.configurationType === ROW_CONFIGURATION_TYPE;
    },
  };
}
