import React, { useLayoutEffect, useMemo } from 'react';
import { Handle, Position, useUpdateNodeInternals } from '@xyflow/react';
import { useShallow } from 'zustand/react/shallow';

import { cn } from '@keboola/design';

import { useOpenNode } from '@/modules/lineage/columns/hooks';
import { COLUMNS_CHUNK_SIZE } from '@/modules/lineage/components/constants';
import { useColumnsStore } from '@/modules/lineage/contexts/columnsStore';
import type { OnSetShownHandles } from '@/modules/lineage/rfTypes';
import { FilterPanel, Truncated } from '@/react/common';
import MarkedText from '@/react/common/MarkedText';
import { matchByWords } from '@/utils';
import Pagination from './Pagination';
import ToggleNodeButton from './ToggleNodeButton';

type Props = {
  nodeId: string;
  columns: string[];
  onSetShownHandles: OnSetShownHandles;
  onSelectColumn: (nodeId: string, column: string) => void;
  onResetColumn: () => void;
};

const columnHandleClassName = 'tw-relative tw-float-right tw-self-center tw-invisible';

const Columns = ({ nodeId, onSetShownHandles, columns, onSelectColumn, onResetColumn }: Props) => {
  const updateNodeInternals = useUpdateNodeInternals();
  const {
    chosenColumn,
    setNodeIdManipulating,
    highlightedColumns,
    highlightedEdges,
    shouldResetColumnsHighlight,
    setShouldResetColumnsHighlight,
  } = useColumnsStore(
    useShallow((state) => ({
      chosenColumn: state.chosenColumn,
      setNodeIdManipulating: state.setNodeIdManipulating,
      highlightedColumns: state.highlightedColumns,
      highlightedEdges: state.highlightedEdges,
      shouldResetColumnsHighlight: state.shouldResetColumnsHighlight,
      setShouldResetColumnsHighlight: state.setShouldResetColumnsHighlight,
    })),
  );

  const [show, setShow] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  const [visibleColumns, setVisibleColumns] = React.useState<string[]>([]);

  const sortedByHighlightedColumns = useMemo(() => {
    if (highlightedColumns.length === 0) {
      return null;
    }

    // we only store my node highlighted columns
    const myHighlightedColumns = highlightedColumns
      .filter((column) => column.nodeId === nodeId && column.column !== 'main')
      .map((column) => column.column);

    if (myHighlightedColumns.length === 0) {
      return null;
    }

    // put highlighted columns to the top
    return [
      ...myHighlightedColumns,
      ...columns.filter((column) => column !== 'main' && !myHighlightedColumns.includes(column)),
    ];
  }, [columns, highlightedColumns, nodeId]);

  const updateVisibleColumns = React.useCallback(
    (columns: string[], search: string, page: number) => {
      const newVisibleColumns = columns
        .filter((column) => matchByWords(column, search))
        .slice((page - 1) * COLUMNS_CHUNK_SIZE, page * COLUMNS_CHUNK_SIZE);

      setVisibleColumns(newVisibleColumns);
      onSetShownHandles(
        nodeId,
        newVisibleColumns,
        chosenColumn?.nodeId ?? null,
        chosenColumn?.column ?? null,
        highlightedEdges,
      );
      updateNodeInternals(nodeId);
    },
    [onSetShownHandles, nodeId, chosenColumn, highlightedEdges, updateNodeInternals],
  );

  const handleUpdateColumnsOnNodeToggle = () => {
    // when opening node we check if we need to move some highlighted columns to the top
    updateVisibleColumns(
      show ? [] : (sortedByHighlightedColumns ?? columns),
      searchValue,
      currentPage,
    );
  };

  useOpenNode({
    show,
    nodeId,
    onToggleNodeShow: setShow,
    onUpdateVisibleColumns: handleUpdateColumnsOnNodeToggle,
  });

  // clicking on column in the table all other tables resort their columns
  useLayoutEffect(() => {
    // if we reset highlighted columns we should sort columns to default sorting (put previously highlighted columns back at its position) and do it only for nodes that are open
    if (shouldResetColumnsHighlight && show) {
      updateVisibleColumns(columns, searchValue, currentPage);
      setShouldResetColumnsHighlight(false);
      return;
    }

    // if node is open so columns are visible, we put highlighted columns to the top
    if (highlightedColumns.length !== 0 && show && sortedByHighlightedColumns !== null) {
      updateVisibleColumns(sortedByHighlightedColumns, searchValue, currentPage);
    }
  }, [
    columns,
    currentPage,
    highlightedColumns,
    searchValue,
    show,
    sortedByHighlightedColumns,
    updateVisibleColumns,
    shouldResetColumnsHighlight,
    setShouldResetColumnsHighlight,
  ]);

  const filteredColumns = React.useMemo(() => {
    return columns.filter((column) => matchByWords(column, searchValue));
  }, [columns, searchValue]);

  const otherColumns = React.useMemo(() => {
    return columns.filter((column) => !visibleColumns.includes(column));
  }, [columns, visibleColumns]);

  if (!columns.length) {
    return null;
  }

  const isCollapseDisabled = !!chosenColumn && nodeId !== chosenColumn?.nodeId;

  return (
    <>
      <ToggleNodeButton
        show={show}
        isDisabled={isCollapseDisabled}
        onToggle={() => {
          setShow(!show);
          handleUpdateColumnsOnNodeToggle();
        }}
        columnsLength={columns.length}
      />
      {show && (
        <div data-bottom-content className="nowheel tw-relative tw-rounded-b-md tw-bg-white">
          <FilterPanel
            asBox={false}
            searchBarClassName="as-input condensed nodrag tw-mb-0 tw-p-1"
            query={searchValue}
            onChange={(value) => {
              setCurrentPage(1);
              setSearchValue(value);
              updateVisibleColumns(columns, value, 1);
            }}
          />
          {visibleColumns.length === 0 ? (
            <p className="tw-m-0 tw-px-2 tw-pb-2">No columns found</p>
          ) : (
            <>
              {visibleColumns.map((column) => {
                return (
                  <div key={column} className="tw-flex tw-text-neutral-400">
                    <Handle
                      id={`${column}-target`}
                      className={cn(columnHandleClassName, '-tw-left-0.5')}
                      type="target"
                      position={Position.Left}
                    />
                    <div
                      className="tw-flex tw-w-full tw-cursor-pointer tw-flex-col"
                      onClick={() => {
                        if (nodeId === chosenColumn?.nodeId && column === chosenColumn?.column) {
                          onResetColumn();
                          return;
                        }
                        onSelectColumn(nodeId, column);
                        setNodeIdManipulating(nodeId);
                      }}
                    >
                      <Truncated
                        className={cn('hover:tw-bg-neutral-100', {
                          'tw-font-medium tw-text-secondary-500':
                            nodeId === chosenColumn?.nodeId && column === chosenColumn?.column,
                        })}
                        text={<MarkedText source={column} mark={searchValue} />}
                      />
                      <div className="separator tw-mx-0 tw-my-1" />
                    </div>
                    <Handle
                      id={`${column}-source`}
                      className={cn(columnHandleClassName, '-tw-right-0.5')}
                      type="source"
                      position={Position.Right}
                    />
                  </div>
                );
              })}
              <Pagination
                onClick={(page) => {
                  setCurrentPage(page);
                  updateVisibleColumns(columns, searchValue, page);
                }}
                currentPage={currentPage}
                allPagesNum={Math.ceil(filteredColumns.length / COLUMNS_CHUNK_SIZE)}
                showPagination={filteredColumns.length > COLUMNS_CHUNK_SIZE}
              />
            </>
          )}
        </div>
      )}
      {otherColumns.map((column) => {
        return (
          <div key={column} className="tw-invisible">
            <Handle id={`${column}-target`} type="target" position={Position.Left} />
            <Handle id={`${column}-source`} type="source" position={Position.Right} />
          </div>
        );
      })}
    </>
  );
};

export default Columns;
