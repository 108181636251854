import React from 'react';

import { IconButton, Tooltip } from '@keboola/design';

const CodeEditorCloseButton = (props: { onClick: () => void }) => {
  return (
    <Tooltip placement="bottom" tooltip="Close">
      <IconButton variant="outline" onClick={() => props.onClick()} icon="xmark" />
    </Tooltip>
  );
};

export default CodeEditorCloseButton;
