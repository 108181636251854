import { useMutation, useQueryClient } from '@tanstack/react-query';
import Promise from 'bluebird';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import installedComponentsApi from '@/modules/components/InstalledComponentsApi';
import type { DeletedComponent } from '@/modules/trash/types';
import { RQ_KEYS } from './keys';

const deleteComponents = (components: DeletedComponent[]) => {
  return Promise.each(components, (component) => {
    return Promise.each(component.configurations, (config) => {
      return installedComponentsApi.deleteConfiguration(component.id, config.id);
    });
  }).then(() => {
    ApplicationActionCreators.sendNotification({
      type: 'info',
      message: 'All configurations were permanently deleted.',
    });
  });
};

export const useDeleteAllComponent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ components }: { components: DeletedComponent[] }) =>
      deleteComponents(components),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: RQ_KEYS.TRASH });
    },
  });
};
