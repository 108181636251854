import React from 'react';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ConfirmModal from '@/react/common/ConfirmModal';

type Props = {
  show: boolean;
  onHide: () => void;
  componentId: string;
  configId: string;
  selected: string[];
};

export const RunSelectedRowsModal = (props: Props) => {
  const [isProcessing, setIsProcessing] = React.useState(false);

  return (
    <ConfirmModal
      closeAfterResolve
      isLoading={isProcessing}
      show={props.show}
      icon="play"
      title="Run selected"
      text={`Are you sure you want to run the ${
        props.selected.length > 1 ? 'selected rows' : 'row'
      }?`}
      buttonLabel="Run selected"
      onConfirm={() => {
        setIsProcessing(true);
        return InstalledComponentsActionCreators.runComponent({
          component: props.componentId,
          data: { config: props.configId, configRowIds: props.selected },
        }).finally(() => setIsProcessing(false));
      }}
      onHide={props.onHide}
    />
  );
};
