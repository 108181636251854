import { createPrefixedString } from '../../utils';

export const genTextInputTestId = (prefix: string = '') => {
  const ps = createPrefixedString(prefix);
  return {
    toString: () => prefix,
    inputWrapper: ps`input-wrapper`,
    input: ps`input`,
    clearButton: ps`clear-button`,
  };
};
