import React from 'react';

import { FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

type Props = {
  value: string;
  onChange: (value: string) => void;
  disabled: boolean;
  label?: React.ReactNode;
  help?: React.ReactNode;
};

const defaultHelp = (
  <span>
    Field delimiter used in the CSV file. The default value is <code>,</code>. Use <code>\t</code>{' '}
    for tabulator.
  </span>
);

const CsvDelimiterInput = ({ value, onChange, disabled, help = defaultHelp }: Props) => {
  return (
    <FormGroup className="tw-mb-4 tw-grid tw-grid-cols-3 tw-items-baseline tw-gap-4">
      <Label htmlFor="delimiter">Delimiter</Label>
      <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
        <TextInput
          id="delimiter"
          variant="secondary"
          value={value.replace('\t', '\\t')}
          onChange={(value) => onChange(value.replace('\\t', '\t'))}
          disabled={disabled}
        />
        <HelpBlock>{help}</HelpBlock>
      </div>
    </FormGroup>
  );
};

export default CsvDelimiterInput;
