import Promise from 'bluebird';
import type { Map } from 'immutable';
import { OrderedMap } from 'immutable';

import {
  KEBOOLA_EX_SAMPLE_DATA,
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_VARIABLES,
} from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import { loadTriggers } from '@/modules/event-trigger/actions';
import * as NotificationActions from '@/modules/notifications/actions';
import JobsActionCreators from '@/modules/queue/actions';
import JobsStore from '@/modules/queue/store';
import folderBreadcrumbHandler from '@/utils/folderBreadcrumbHandler';
import { loadAll, loadFlow } from './actions';
import { routeNames, tabs } from './constants';
import Detail from './Detail';
import DetailHeader from './DetailHeader';
import Index from './Index';
import IndexHeader from './IndexHeader';

const routes = {
  name: routeNames.ROOT,
  title: 'Flows',
  defaultRouteHandler: Index,
  headerButtonsHandler: IndexHeader,
  requireData: [
    (params: { config?: string; tab?: string }) => {
      if (params.config) {
        return Promise.resolve();
      }

      return Promise.all([
        loadAll(),
        NotificationActions.loadNotifications(),
        InstalledComponentsActionCreators.loadComponentsMetadata(KEBOOLA_ORCHESTRATOR),
      ]);
    },
    loadTriggers,
  ],
  childRoutes: [
    {
      name: routeNames.DETAIL,
      path: ':config(/:tab)',
      title(routerState: Map<string, any>) {
        return InstalledComponentsStore.getConfig(
          KEBOOLA_ORCHESTRATOR,
          routerState.getIn(['params', 'config']),
        ).get('name');
      },
      breadcrumbHandler: ({
        breadcrumbs,
        params,
      }: {
        breadcrumbs: React.ReactNode[];
        params: { config: string; tab?: string };
      }) =>
        folderBreadcrumbHandler(breadcrumbs, KEBOOLA_ORCHESTRATOR, params.config, routeNames.ROOT),
      defaultRouteHandler: Detail,
      headerButtonsHandler: DetailHeader,
      requireData: [
        (params: { config: string; tab?: string }) => {
          if (params.tab === tabs.NOTIFICATIONS) {
            return NotificationActions.loadNotifications();
          }

          if (params.tab === tabs.ALL_RUNS) {
            JobsActionCreators.loadComponentConfigurationLatestJobs(
              KEBOOLA_ORCHESTRATOR,
              params.config,
            );
            return;
          }

          if (params.tab === tabs.VERSIONS) {
            return VersionsActionCreators.loadVersions(KEBOOLA_ORCHESTRATOR, params.config);
          }

          // just preload data for last run status visualization, this data is not required for detail page transition
          JobsActionCreators.loadComponentConfigurationLatestJobs(
            KEBOOLA_ORCHESTRATOR,
            params.config,
          ).then(() => {
            const latestJob = JobsStore.getLatestJobs(KEBOOLA_ORCHESTRATOR, params.config)
              .get('jobs', OrderedMap())
              .first();

            if (latestJob) {
              JobsActionCreators.loadChildJobsForce(latestJob);
            }
          });

          InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_VARIABLES);

          return Promise.all([
            loadFlow(params.config),
            InstalledComponentsActionCreators.loadComponentsMetadata(),
            NotificationActions.loadNotifications(),
            StorageActionCreators.loadBucketsAndTables(),
            ComponentsStore.hasComponent(KEBOOLA_EX_SAMPLE_DATA)
              ? InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_EX_SAMPLE_DATA)
              : Promise.resolve(),
            InstalledComponentsActionCreators.loadDeletedComponents(),
          ]);
        },
      ],
      poll: {
        skipFirst: true,
        action(params: { config: string; tab?: string }) {
          return VersionsActionCreators.reloadVersionsAndNotifyIfDoNotMatch(
            KEBOOLA_ORCHESTRATOR,
            params.config,
          );
        },
      },
    },
  ],
};

export default routes;
