import _ from 'underscore';

const clearProcessorsTagOverride = (processors) => {
  return processors?.map((processor) => processor.deleteIn(['definition', 'tag']));
};

const cleanDataBeforeCheck = (configuration) => {
  return configuration
    .delete('authorization')
    .delete('runtime')
    .delete('variables_id')
    .delete('variables_values_id')
    .updateIn(['processors', 'before'], clearProcessorsTagOverride)
    .updateIn(['processors', 'after'], clearProcessorsTagOverride);
};

const isParsableConfiguration = (configuration, parseFunction, createFunction) => {
  const configData = cleanDataBeforeCheck(configuration);

  if (configData.isEmpty()) {
    return true;
  }

  return _.isEqual(
    configData.toJS(),
    cleanDataBeforeCheck(createFunction(parseFunction(configuration))).toJS(),
  );
};

export default isParsableConfiguration;
