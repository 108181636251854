import { createContext, useContext } from 'react';

export type Variant = 'radio' | 'button';
export type Orientation = 'vertical' | 'horizontal';

const radioGroupContext = createContext<{ variant: Variant; orientation: Orientation }>({
  variant: 'radio',
  orientation: 'horizontal',
});

export const RadioGroupProvider = radioGroupContext.Provider;

export const useRadioGroupContext = () => useContext(radioGroupContext);
