import { List, OrderedMap } from 'immutable';

import fromJSOrdered from '@/utils/fromJSOrdered';

const prepareProfiles = (data) => {
  return fromJSOrdered([...(data?.properties || []), ...(data?.profiles || [])].filter(Boolean))
    .reduce((profiles, profile) => {
      return profiles.updateIn(
        [profile.get('accountName'), profile.get('webPropertyName', profile.get('propertyName'))],
        List(),
        (property) => property.push(profile),
      );
    }, OrderedMap())
    .map((profileGroup) => profileGroup.filter((profile) => !profile.isEmpty()))
    .filter((profileGroup) => !profileGroup.isEmpty());
};

const isOldProfile = (profile) => profile.has('webPropertyName');

const isNewProfile = (profile) => profile.has('propertyName');

const hasOldProfiles = (configData) => configData.hasIn(['parameters', 'profiles']);

const hasNewProfiles = (configData) => configData.hasIn(['parameters', 'properties']);

const usingTwoVersionOfApi = (profiles) =>
  profiles.some(isOldProfile) && profiles.some(isNewProfile);

const parseSavedProfiles = (configData) =>
  configData
    .getIn(['parameters', 'profiles'], List())
    .concat(configData.getIn(['parameters', 'properties'], List()))
    .toMap()
    .mapKeys((key, profile) => profile.get('id', profile.get('propertyKey')));

export {
  prepareProfiles,
  isOldProfile,
  hasOldProfiles,
  hasNewProfiles,
  usingTwoVersionOfApi,
  parseSavedProfiles,
};
