import Promise from 'bluebird';
import type { Map } from 'immutable';

import StorageActionCreators from '@/modules/components/StorageActionCreators';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import DataCatalogActions from '@/modules/data-catalog/actions';
import SharedBucketDetail from '@/modules/data-catalog/react/SharedBucketDetail';
import SharedBucketHeaderButton from '@/modules/data-catalog/react/SharedBucketHeaderButton';
import SharedWithYou from '@/modules/data-catalog/react/SharedWithYou';
import ApplicationStore from '@/stores/ApplicationStore';
import HeaderButtons from './react/HeaderButtons';
import MySharedBucketDetail from './react/MySharedBucketDetail';
import MySharedBucketHeaderButton from './react/MySharedBucketHeaderButton';
import SharedFromThisProject from './react/SharedFromThisProject';

export const SHARED_WITH_YOU_ROUTES = {
  shared_with_you: 'data-catalog-shared-with-you',
  shared_with_you_detail: (projectId: number, bucketId: string) =>
    `${SHARED_WITH_YOU_ROUTES.shared_with_you}/${projectId}/${bucketId}`,
} as const;

export const SHARED_FROM_PROJECT_ROUTES = {
  shared_from_project: 'data-catalog-shared-from-this-project',
  shared_from_project_detail: (bucketId: string) =>
    `${SHARED_FROM_PROJECT_ROUTES.shared_from_project}/${bucketId}`,
} as const;

export const sharedWithYouRouter = {
  path: SHARED_WITH_YOU_ROUTES.shared_with_you,
  title: 'Shared with you',
  subtitle: 'Data Catalog',
  defaultRouteHandler: SharedWithYou,
  requireData: [
    () => DataCatalogActions.loadSharedBuckets(),
    () => StorageActionCreators.loadBucketsAndTables(),
  ],
  poll: {
    skipFirst: true,
    action: () => {
      return Promise.all([
        DataCatalogActions.loadSharedBuckets(),
        StorageActionCreators.loadBucketsAndTables(),
      ]);
    },
  },
  childRoutes: [
    {
      path: ':projectId/:bucketId',
      title(routerState: Map<string, any>) {
        const sharedBucketId = routerState.getIn(['params', 'bucketId']);
        const currentProjectId = ApplicationStore.getCurrentProjectId();
        const sharedProjectId = parseInt(routerState.getIn(['params', 'projectId']), 10);
        const sharedBucket = StorageBucketsStore.getSharedBucket(
          sharedBucketId,
          currentProjectId,
          sharedProjectId,
        );
        return `Bucket ${sharedBucket.get('displayName', sharedBucketId)}`;
      },
      defaultRouteHandler: SharedBucketDetail,
      headerButtonsHandler: SharedBucketHeaderButton,
    },
  ],
};

export const sharedFromProjectRouter = {
  path: SHARED_FROM_PROJECT_ROUTES.shared_from_project,
  title: 'Shared from this project',
  subtitle: 'Data Catalog',
  defaultRouteHandler: SharedFromThisProject,
  headerButtonsHandler: HeaderButtons,
  requireData: [() => StorageActionCreators.loadBucketsAndTables()],
  poll: {
    skipFirst: true,
    action: () => StorageActionCreators.loadBucketsAndTables(),
  },
  childRoutes: [
    {
      path: ':bucketId',
      title(routerState: Map<string, any>) {
        const sharedBucket = StorageBucketsStore.getBucket(
          routerState.getIn(['params', 'bucketId']),
        );
        return `Bucket ${sharedBucket.get(
          'displayName',
          routerState.getIn(['params', 'bucketId']),
        )}`;
      },
      defaultRouteHandler: MySharedBucketDetail,
      headerButtonsHandler: MySharedBucketHeaderButton,
    },
  ],
};
