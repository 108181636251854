import { fromJS, Map } from 'immutable';

import { flattenJobs } from '@/modules/ex-generic/helpers';
import fromJSOrdered from '@/utils/fromJSOrdered';
import string from '@/utils/string';

const KEY = 'mapping';

const mapToState = (parameters: Map<string, any>, jobPath: (string | number)[]) => {
  const job = parameters.getIn(['config', 'jobs', ...jobPath], Map());
  const mapping = parameters.getIn(['config', 'mappings', job.get('dataType')]);
  const stringifiedMapping = mapping ? JSON.stringify(mapping, null, '  ') : '';
  const defaultDataType = string.webalize(string.strRightBack(job.get('endpoint', ''), '/'));
  const dataField = job.get('dataField');

  return fromJS({
    [KEY]: {
      dataType: job.get('dataType', defaultDataType),
      dataFieldPath: Map.isMap(dataField) ? dataField.get('path', '.') : '',
      dataFieldDelimeter: Map.isMap(dataField) ? dataField.get('delimiter', '.') : '',
      mapping: stringifiedMapping,
      savedMapping: stringifiedMapping,
    },
  });
};

const mapToJob = (job: Map<string, any>, editing: Map<string, any>) => {
  return job.withMutations((job) => {
    job.set('dataType', editing.getIn([KEY, 'dataType'], ''));

    if (!!editing.getIn([KEY, 'dataFieldPath'])) {
      job.setIn(['dataField', 'path'], editing.getIn([KEY, 'dataFieldPath']));
      job.setIn(['dataField', 'delimiter'], editing.getIn([KEY, 'dataFieldDelimeter']) || '.');
    } else {
      job.delete('dataField');
    }
  });
};

const mapToParameters = (parameters: Map<string, any>, editing: Map<string, any>) => {
  const mapping = editing.getIn([KEY, 'mapping']);
  const dataType = editing.getIn([KEY, 'dataType']);
  const validDataTypes = flattenJobs(parameters).map((job: Map<string, any>) => {
    return job.get('dataType');
  });

  return parameters.updateIn(['config', 'mappings'], Map(), (mappings) => {
    return mappings.withMutations((mappings: Map<string, any>) => {
      if (mapping) {
        mappings.set(dataType, fromJSOrdered(JSON.parse(mapping)));
      } else {
        mappings.delete(dataType);
      }

      // remove mappings which are not used in any job
      mappings.keySeq().forEach((key) => {
        if (key && !validDataTypes.includes(key)) {
          mappings.delete(key);
        }
      });
    });
  });
};

export { KEY, mapToState, mapToJob, mapToParameters };
