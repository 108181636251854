import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ChangedSinceInput from '@/react/common/ChangedSinceInput';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';

const ChangedSinceFilterInput = createReactClass({
  propTypes: {
    mapping: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    labelClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    helpBlock: PropTypes.string,
    label: PropTypes.string,
    groupClassName: PropTypes.string,
    formType: PropTypes.oneOf(['normal', 'horizontal']),
  },

  getDefaultProps() {
    return {
      labelClassName: 'col-xs-2',
      wrapperClassName: 'col-xs-10',
      label: 'Changed in Last',
      groupClassName: 'form-group',
      formType: 'horizontal',
    };
  },

  render() {
    if (this.props.formType === 'horizontal') {
      return (
        <div className={this.props.groupClassName}>
          <label htmlFor="filterInput" className={'control-label ' + this.props.labelClassName}>
            {this.props.label}
          </label>
          <div className={this.props.wrapperClassName}>
            <ChangedSinceInput
              id="filterInput"
              value={this.getChangedSinceValue()}
              disabled={this.props.disabled || !this.props.mapping.get('source')}
              onChange={this.handleChangeChangedSince}
              helpBlock={this.props.helpBlock}
              {...(typeof this.props.mapping.get('source') === 'string' && {
                tableId: this.props.mapping.get('source'),
              })}
            />
          </div>
        </div>
      );
    }

    return (
      <FormGroup>
        <ControlLabel>
          {this.props.label} <OptionalFormLabel />
        </ControlLabel>
        <ChangedSinceInput
          value={this.getChangedSinceValue()}
          disabled={this.props.disabled || !this.props.mapping.get('source')}
          onChange={this.handleChangeChangedSince}
          helpBlock={this.props.helpBlock}
          {...(typeof this.props.mapping.get('source') === 'string' && {
            tableId: this.props.mapping.get('source'),
          })}
        />
      </FormGroup>
    );
  },

  getChangedSinceValue() {
    if (!this.props.mapping.get('changed_since') && this.props.mapping.get('days') > 0) {
      return '-' + this.props.mapping.get('days') + ' days';
    }
    return this.props.mapping.get('changed_since');
  },

  handleChangeChangedSince(changedSince) {
    let value = this.props.mapping;
    if (value.has('days')) {
      value = value.delete('days');
    }
    value = value.set('changed_since', changedSince);
    this.props.onChange(value);
  },
});

export default ChangedSinceFilterInput;
