import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { HelpBlock } from '@keboola/design';

import { hasInputMappingTables } from '@/modules/components/react/components/generic/helpers';
import SourceSearchInEditor from '@/react/common/SourceSearch/SourceSearchInEditor';
import InputMappingSourceInput from './InputMappingSourceInput';

class InputMappingSource extends React.PureComponent {
  render() {
    return (
      <>
        {this.renderInput()}
        {hasInputMappingTables(this.props.value) && this.props.isDestinationDuplicate && (
          <HelpBlock className="tw-mt-1">
            {this.props.disableMultiSelect
              ? 'One or more tables from this bucket are already set in Input Mapping and only the remaining tables will be added. If the destination table name is duplicate, a number suffix will be added to the destination.'
              : 'Already added tables and tables with duplicate name will be used with a number suffix.'}
          </HelpBlock>
        )}
      </>
    );
  }

  renderInput() {
    if (this.props.value.has('source_search')) {
      return <SourceSearchInEditor inputSearch={this.props.value.get('source_search')} />;
    }

    return (
      <InputMappingSourceInput
        id={this.props.id}
        mode={this.props.mode}
        buckets={this.props.buckets}
        tables={this.props.tables}
        value={this.props.value.get('source', '')}
        otherMappings={this.props.otherMappings}
        onChange={this.props.onChange}
        isDisabled={this.props.disabled}
        disableBuckets={this.props.disableBuckets}
        disableMultiSelect={this.props.disableMultiSelect}
        disableExternalBuckets={this.props.disableExternalBuckets}
      />
    );
  }
}

InputMappingSource.propTypes = {
  value: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  mode: PropTypes.string,
  id: PropTypes.string,
  otherMappings: PropTypes.object,
  isDestinationDuplicate: PropTypes.bool,
  disabled: PropTypes.bool,
  disableBuckets: PropTypes.bool,
  disableMultiSelect: PropTypes.bool,
  disableExternalBuckets: PropTypes.bool,
};

InputMappingSource.defaultProps = {
  isDestinationDuplicate: false,
  disabled: false,
  disableBuckets: false,
  disableMultiSelect: false,
};

export default InputMappingSource;
