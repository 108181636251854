import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Map } from 'immutable';

import { Alert } from '@keboola/design';

import type { PARAM_VALUE } from '@/modules/ex-generic/constants';
import { prepareUserParameters } from '@/modules/ex-generic/helpers';
import DocumentationLink from './DocumentationLink';
import NewParameter from './NewParameter';
import PropertyRow from './PropertyRow';

const Parameters = (props: {
  readOnly: boolean;
  parameters: Map<string, any>;
  onSave: (parameters: Map<string, any>, changeDescription: string) => Promise<any>;
}) => {
  const [show, setShow] = React.useState(false);
  const [isDeleting, setDeleting] = React.useState<string | null>(null);
  const [parameter, editParameter] = React.useState<string | null>(null);

  const userParameters = prepareUserParameters(props.parameters);

  const handleDelete = (key: string) => {
    setDeleting(key);
    return props
      .onSave(props.parameters.deleteIn(['config', key]), 'Delete user parameter')
      .finally(() => setDeleting(null));
  };

  const renderBody = () => {
    if (userParameters.isEmpty()) {
      return (
        <div className="box-content tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 !tw-py-10">
          <p className="tw-mb-0 tw-max-w-xl tw-text-center">
            Parameters shared across various configuration contexts, such as query parameters,
            headers, and authentication methods.{' '}
            <DocumentationLink path="configuration/#user-parameters" format="text" />
          </p>
          {!props.readOnly && (
            <>
              <Alert>You can set them here or at any time in the respective contexts.</Alert>
              <Button onClick={() => setShow(true)}>
                <FontAwesomeIcon icon="plus" className="btn-icon" />
                Create New User Parameter
              </Button>
            </>
          )}
        </div>
      );
    }

    return (
      <div className="tw-px-6 tw-py-5">
        <div className="tw-mb-2 tw-grid tw-grid-cols-3 tw-items-start tw-gap-4">
          <div className="tw-text-xs tw-font-medium tw-uppercase tw-text-neutral-500">Name</div>
          <div className="tw-col-span-2 tw-ml-1.5 tw-text-xs tw-font-medium tw-uppercase tw-text-neutral-500">
            Value
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-3">
          {userParameters
            .sortBy((value: PARAM_VALUE, name: string) => name.toLowerCase())
            .map((value: PARAM_VALUE, name: string) => {
              return (
                <PropertyRow
                  key={name}
                  name={name}
                  value={value}
                  readOnly={props.readOnly}
                  isDeleting={isDeleting === name}
                  onEdit={() => {
                    editParameter(name);
                    setShow(true);
                  }}
                  onDelete={() => handleDelete(name)}
                />
              );
            })
            .toArray()}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="tw-flex tw-items-start tw-justify-between">
        <h2 className="tw-mb-5 tw-mt-1 tw-text-2xl tw-font-normal">User Parameters</h2>
        {!props.readOnly && !userParameters.isEmpty() && (
          <Button onClick={() => setShow(true)}>
            <FontAwesomeIcon icon="plus" className="btn-icon" />
            Create Parameter
          </Button>
        )}
      </div>
      <div className="box">{renderBody()}</div>
      <NewParameter
        show={show}
        existingParameter={parameter}
        parameters={props.parameters}
        onSave={props.onSave}
        onHide={() => {
          setShow(false);
          editParameter(null);
        }}
      />
    </>
  );
};

export default Parameters;
