import type { FetchClientError } from './createFetchClient';
import type { ApiResponse, MiddlewareFn } from './types';

export type CreateCallbackMiddlewareOptions = {
  onError?: (error: FetchClientError) => void;
  onSuccess?: () => void;
  onSettled?: () => void;
};

export const createCallbackMiddleware =
  ({ onError, onSettled, onSuccess }: CreateCallbackMiddlewareOptions = {}): MiddlewareFn =>
  async (request, next) => {
    let response: ApiResponse;
    try {
      response = await next(request);
      onSuccess?.();
    } catch (error) {
      onError?.(error as FetchClientError);
      throw error;
    } finally {
      onSettled?.();
    }
    return response;
  };
