import { cva } from 'class-variance-authority';

export const switchVariants = cva(
  [
    // reset
    'tw-bg-transparent',
    'tw-border-0',

    // positioning
    'tw-inline-flex',
    'tw-items-center',
    'tw-shrink-0',

    // color
    'data-[state=checked]:tw-bg-primary-500',
    'data-[state=unchecked]:tw-bg-neutral-200',

    // hover
    'data-[state=checked]:hover:tw-bg-primary-600',
    'data-[state=unchecked]:hover:tw-bg-neutral-300',

    // disabled
    'disabled:tw-cursor-not-allowed',
    'disabled:tw-opacity-50',

    // others
    'tw-cursor-pointer',
    'tw-transition-colors',

    'focus-visible:tw-outline',
    'focus-visible:tw-outline-secondary-500',
    'focus-visible:tw-ring-4',
    'focus-visible:tw-ring-secondary-200',
  ],
  {
    defaultVariants: {
      size: 'medium',
    },
    variants: {
      size: {
        small: ['tw-w-6', 'tw-h-4', 'tw-rounded-lg'],
        medium: ['tw-w-8', 'tw-h-4', 'tw-rounded-lg'],
        large: ['tw-w-10', 'tw-h-5', 'tw-rounded-xl'],
      },
    },
  },
);

export const thumbVariants = cva(
  [
    'tw-block',
    'tw-pointer-events-none',
    'tw-bg-white',
    'tw-rounded-full',
    'tw-transition-transform',
    'tw-easy-in-out',
  ],
  {
    defaultVariants: {
      size: 'medium',
    },
    variants: {
      size: {
        small: [
          'tw-w-3',
          'tw-h-3',

          // translate
          'data-[state=checked]:tw-translate-x-1',
          'data-[state=unchecked]:-tw-translate-x-1',
        ],
        medium: [
          'tw-w-3',
          'tw-h-3',

          // translate
          'data-[state=checked]:tw-translate-x-3',
          'data-[state=unchecked]:-tw-translate-x-1',
        ],
        large: [
          'tw-w-3.5',
          'tw-h-3.5',

          // translate
          'data-[state=checked]:tw-translate-x-4',
          'data-[state=unchecked]:-tw-translate-x-0.5',
        ],
      },
    },
  },
);
