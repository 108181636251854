import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Map } from 'immutable';
import _ from 'underscore';

import { Tooltip } from '@keboola/design';

import { SHARES_TYPES, sortEntities } from '@/constants';
import { canManageBucket, canUnlinkBucket, canWriteBucket } from '@/modules/admin/privileges';
import {
  DISABLED_EXTERNAL_TABLES_MESSAGE,
  DISABLED_WORKSPACE_ACTIONS_MESSAGE,
  FORM_STEPS,
} from '@/modules/sandboxes/Constants';
import { workspaceActionsDisabled } from '@/modules/sandboxes/helpers';
import { SortIcon } from '@/react/common';
import {
  CopyToWorkspace,
  CreateSnapshot,
  CreateWorkspace,
  ExportSelected,
  ShareTables,
} from '@/react/common/TableIconActions';
import type { OpenShareModal, SetSortType, TableData } from '@/types/types';
import string from '@/utils/string';

type Props = {
  allBuckets?: Map<string, any>;
  sapiToken: Map<string, any>;
  sort: Map<string, any>;
  setSort: SetSortType;
  table: TableData;
  canExportTable: boolean;
  canShareTable: boolean;
  openDeleteModal: (selectedRows: Map<string, any>[]) => void;
  openCreateWorkpaceModal: (selectedRows: Map<string, any>[], forceStep?: string) => void;
  openExportTablesModal: (selectedRows: Map<string, any>[]) => void;
  openSnapshotModal: (selectedRows: Map<string, any>[]) => void;
  openShareModal: OpenShareModal;
  hasSnowflakePartnerConnectLimited: boolean;
  isCreatingSnapshot: boolean;
  isExportingTables: boolean;
};

export const HeaderActions = ({
  allBuckets = Map(),
  sort,
  setSort,
  table,
  sapiToken,
  canExportTable,
  canShareTable,
  openDeleteModal,
  openCreateWorkpaceModal,
  openExportTablesModal,
  openSnapshotModal,
  openShareModal,
  hasSnowflakePartnerConnectLimited,
  isCreatingSnapshot,
  isExportingTables,
}: Props) => {
  const selectedRows = table.getSelectedRowModel().flatRows.map((row) => row.original.item);

  const { bucketsCount, tablesCount } = selectedRows.reduce(
    (bucketsAndTables, row) => {
      const key = row.has('stage') ? 'bucketsCount' : 'tablesCount';
      return { ...bucketsAndTables, [key]: bucketsAndTables[key] + 1 };
    },
    {
      bucketsCount: 0,
      tablesCount: 0,
    },
  );
  const areWorkspacesDisabled = selectedRows.some((row) =>
    workspaceActionsDisabled(row.has('stage') ? row : row.get('bucket')),
  );

  const isSortedByName = sort.get('entity') === sortEntities.NAME;
  const selectedTables = selectedRows.filter((row) => !row.has('stage'));

  /** As this component is used in IndexTableResults which does not contain tables inside buckets,
    we get those tables from allBuckets if sent and merge it with selected tables. So when user selects a bucket
    we find all the tables inside it. */
  const uniqueSelectedTables =
    allBuckets.count() === 0
      ? selectedTables
      : _.uniq(
          [
            ...selectedTables,
            ...selectedRows
              .filter((row) => row.has('stage'))
              .flatMap((row) => {
                return allBuckets.getIn([row.get('id'), 'bucketTables'], Map()).toArray();
              }),
          ],
          (table) => table.get('id'),
        );

  const renderActions = () => {
    const actions = [];

    if (canExportTable) {
      actions.push(
        <ExportSelected
          key="export-tables"
          disabled={isExportingTables}
          onClick={() => openExportTablesModal(uniqueSelectedTables)}
        />,
      );
    }

    const canManageAllSelectedTables = uniqueSelectedTables.every((selectedTable) => {
      return canManageBucket(sapiToken, selectedTable.get('bucket'));
    });

    if (canManageAllSelectedTables) {
      actions.push(
        <CreateSnapshot
          key="create-snapshot"
          disabled={isCreatingSnapshot || uniqueSelectedTables.length === 0}
          multiple={uniqueSelectedTables.length > 1}
          onClick={() => openSnapshotModal(uniqueSelectedTables)}
        />,
      );
    }

    if (canShareTable && tablesCount > 0) {
      const containsExternalTables = uniqueSelectedTables.some((selectedTable) =>
        selectedTable.getIn(['bucket', 'hasExternalSchema']),
      );

      actions.push(
        <ShareTables
          key="share-tables"
          disabled={containsExternalTables}
          tooltipText={
            containsExternalTables
              ? DISABLED_EXTERNAL_TABLES_MESSAGE
              : `Share ${string.pluralize(tablesCount, 'table')}`
          }
          onClick={() =>
            openShareModal({ selectedTables, shareModalStep: SHARES_TYPES.SELECTED_TABLES })
          }
        />,
      );
    }

    if (!hasSnowflakePartnerConnectLimited) {
      actions.push(
        <CreateWorkspace
          key="create-workspace"
          tooltip={areWorkspacesDisabled ? DISABLED_WORKSPACE_ACTIONS_MESSAGE : null}
          onClick={() => !areWorkspacesDisabled && openCreateWorkpaceModal(selectedRows)}
          disabled={areWorkspacesDisabled}
        />,
      );
      actions.push(
        <CopyToWorkspace
          key="copy-to-workspace"
          tooltip={areWorkspacesDisabled ? DISABLED_WORKSPACE_ACTIONS_MESSAGE : null}
          onClick={() => {
            if (!areWorkspacesDisabled) {
              openCreateWorkpaceModal(selectedRows, FORM_STEPS.SANDBOX_UPDATE);
            }
          }}
          disabled={areWorkspacesDisabled}
        />,
      );
    }

    const hasPermissionToDeleteSelected = selectedRows.every((row) => {
      if (row.has('sourceBucket')) {
        return canUnlinkBucket(sapiToken, row);
      }

      if (row.has('stage')) {
        return canManageBucket(sapiToken, row) && row.get('linkedBy', List()).isEmpty();
      }

      return canWriteBucket(sapiToken, row.get('bucket'));
    });

    if (hasPermissionToDeleteSelected) {
      if (actions.length > 0) {
        actions.push(<span key="delete-separator" className="group-separator compact" />);
      }

      actions.push(
        <Tooltip key="delete-selected" placement="top" tooltip="Delete selected">
          <Button
            bsStyle="link"
            className="btn-link-inline btn-link-muted"
            onClick={() => openDeleteModal(selectedRows)}
          >
            <FontAwesomeIcon icon="trash" fixedWidth />
          </Button>
        </Tooltip>,
      );
    }

    if (actions.length === 0) {
      actions.push(
        <Tooltip key="no-actions" placement="top" tooltip="No actions available for selected items">
          <Button bsStyle="link" className="btn-link-inline btn-link-muted" disabled>
            <FontAwesomeIcon icon="ban" fixedWidth />
          </Button>
        </Tooltip>,
      );
    }

    return actions;
  };

  return (
    <div className="flex-container flex-start">
      {bucketsCount + tablesCount === 0 ? (
        <span
          className="clickable"
          title="Sort by name"
          onClick={() => {
            setSort({
              entity: sortEntities.NAME,
              order: isSortedByName ? sort.get('order') * -1 : 1,
            });
          }}
        >
          Name
          <SortIcon
            className="icon-addon-left"
            isSorted={isSortedByName}
            isSortedDesc={sort.get('order') === -1}
          />
        </span>
      ) : (
        <strong>
          {!!bucketsCount && `${bucketsCount} ${string.pluralize(bucketsCount, 'bucket')} `}
          {bucketsCount && tablesCount ? 'and ' : ''}
          {!!tablesCount && `${tablesCount} ${string.pluralize(tablesCount, 'table')} `}
          selected
        </strong>
      )}

      {bucketsCount + tablesCount > 0 && (
        <div className="table-action-buttons no-wrap">{renderActions()}</div>
      )}
    </div>
  );
};
