import React from 'react';
import type { ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Switch from 'rc-switch';

import { URLS } from '@keboola/constants';
import { cn, IconButton, Link, Tooltip } from '@keboola/design';

import { getLastUpdate } from '@/modules/lineage/helpers';
import InfoTooltip from '@/react/common/InfoTooltip';
import { UPPER_PANEL_HEIGHT } from './constants';
import ExportToPng from './ExportToPng';
import Search from './Search';

const separator = <div className="tw-inline-block tw-h-6 tw-w-px tw-bg-neutral-200"></div>;

const ActionsPanel = ({
  showActions = true,
  showTablesOnly = false,
  showTablesToggleAction = true,
  onShowTablesToggle = null,
  onClose = null,
  left = null,
  inModal = false,
  onOpenModal = null,
}: {
  showActions?: boolean;
  showTablesOnly?: boolean;
  showTablesToggleAction?: boolean;
  onShowTablesToggle?: (() => void) | null;
  onClose?: (() => void) | null;
  left?: ReactNode;
  inModal?: boolean;
  onOpenModal?: (() => void) | null;
}) => {
  return (
    <div
      className={cn(
        'tw-z-10 tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-4 tw-self-start tw-p-6 tw-leading-5',
        { 'tw-absolute [&:has(+[data-sidebar])]:tw-w-[calc(100%-var(--sidebar-width))]': !inModal },
        { 'tw-bg-white tw-shadow-[0_-1px_0_0_theme(colors.neutral.150)_inset]': inModal },
      )}
      style={{ height: UPPER_PANEL_HEIGHT }}
    >
      {left}
      <div
        className={cn(
          'tw-flex tw-flex-1 tw-items-center tw-gap-4',
          left ? 'tw-justify-end' : 'tw-justify-between',
        )}
      >
        <div className="tw-inline-flex tw-items-center tw-gap-4">
          {showActions && (
            <>
              <span className="tw-flex tw-items-center tw-gap-2">
                <InfoTooltip
                  className="!tw-ml-0"
                  tooltip="Lineage data is refreshed every 30 minutes to ensure up-to-date information."
                />
                <p className="tw-m-0 tw-text-sm">
                  <span className="tw-text-neutral-400">Last update:</span> {getLastUpdate()}
                </p>
              </span>
              {separator}
            </>
          )}
          {showActions && showTablesToggleAction && onShowTablesToggle && (
            <>
              <Tooltip
                className="xl:tw-hidden"
                tooltip="Show tables only"
                placement="left"
                type="action"
                triggerClassName="clickable tw-flex tw-items-center"
                triggerOnClick={onShowTablesToggle}
              >
                <Switch prefixCls="switch" className="btn-icon wider" checked={showTablesOnly} />
                <p className="tw-m-0 tw-hidden tw-text-sm xl:tw-flex">Show tables only</p>
                <FontAwesomeIcon
                  icon="table"
                  className="tw-flex tw-text-base tw-text-neutral-400 xl:tw-hidden"
                />
              </Tooltip>
              {separator}
            </>
          )}
          {showActions && (
            <>
              <Link href={URLS.USER_DOCUMENTATION}>
                <FontAwesomeIcon
                  icon="book-blank"
                  className="tw-mr-2 tw-text-sm tw-text-neutral-400"
                />
                Documentation
              </Link>
              {!!left && separator}
            </>
          )}
        </div>
        <div className="tw-inline-flex tw-items-center tw-gap-4">
          {showActions && (
            <>
              <Search inModal={inModal} />
              <span className="tw-flex tw-items-center">
                <ExportToPng />
              </span>
            </>
          )}
          {showActions && onOpenModal && (
            <span className="tw-flex tw-items-center">
              <Tooltip tooltip="Expand lineage to full screen" placement="left" type="action">
                <IconButton
                  onClick={onOpenModal}
                  variant="outline"
                  icon="up-right-and-down-left-from-center"
                />
              </Tooltip>
            </span>
          )}
          {onClose && (
            <span className="tw-flex tw-items-center">
              <IconButton onClick={onClose} variant="outline" icon="xmark" />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActionsPanel;
