import {
  KDS_TEAM_WR_EXASOL,
  KDS_TEAM_WR_FIREBOLT,
  KDS_TEAM_WR_HIVE_CSAS,
  KDS_TEAM_WR_PAIRITY,
  KEBOOLA_WR_DB_HIVE,
  KEBOOLA_WR_DB_IMPALA,
  KEBOOLA_WR_DB_MSSQL_V_2,
  KEBOOLA_WR_DB_MYSQL,
  KEBOOLA_WR_DB_ORACLE,
  KEBOOLA_WR_DB_PGSQL,
  KEBOOLA_WR_DB_SNOWFLAKE,
  KEBOOLA_WR_DB_SNOWFLAKE_GCS,
  KEBOOLA_WR_DB_SNOWFLAKE_GCS_S3,
  KEBOOLA_WR_DB_SYNAPSE,
  KEBOOLA_WR_GOOGLE_BIGQUERY_V_2,
  KEBOOLA_WR_LOOKER_V2,
  KEBOOLA_WR_REDSHIFT_V_2,
  KEBOOLA_WR_SISENSE,
  KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE,
  KEBOOLA_WR_THOUGHTSPOT,
} from '@/constants/componentIds';

const getComponentDataTypes = (componentId) => {
  return DataTypes[componentId] && DataTypes[componentId].typesList
    ? DataTypes[componentId].typesList
    : defaultDataTypes;
};

const getDisabledColumnFields = (componentId) => {
  return DataTypes[componentId] && DataTypes[componentId].disabledFields
    ? DataTypes[componentId].disabledFields
    : [];
};

const defaultDataTypes = [
  'INT',
  'BIGINT',
  { VARCHAR: { defaultSize: '255' } },
  'TEXT',
  { DECIMAL: { defaultSize: '12,2' } },
  'DATE',
  'DATETIME',
];

const mysql = [
  'INT',
  'INTEGER',
  'TINYINT',
  'SMALLINT',
  'MEDIUMINT',
  'BIGINT',
  { DECIMAL: { defaultSize: '12,2' } },
  { DEC: { defaultSize: '12,2' } },
  { FIXED: { defaultSize: '12,2' } },
  { NUMERIC: { defaultSize: '12,2' } },
  'FLOAT',
  'DOUBLE PRECISION',
  'REAL',
  'DOUBLE',
  'BIT',
  'DATE',
  'TIME',
  'DATETIME',
  'TIMESTAMP',
  'YEAR',
  'CHAR',
  { VARCHAR: { defaultSize: '255' } },
  'BLOB',
  'TEXT',
];

const redshift = [
  'SMALLINT',
  'INT2',
  'INTEGER',
  'INT',
  'INT4',
  'BIGINT',
  'INT8',
  { DECIMAL: { defaultSize: '12,2', maxSize: '38,37' } },
  { NUMERIC: { defaultSize: '12,2', maxSize: '38,37' } },
  'REAL',
  'FLOAT4',
  'DOUBLE PRECISION',
  'FLOAT8',
  'FLOAT',
  'BOOLEAN',
  'BOOL',
  { CHAR: { defaultSize: '255', maxSize: '4096' } },
  'CHARACTER',
  'NCHAR',
  'BPCHAR',
  { VARCHAR: { defaultSize: '255', maxSize: '65535' } },
  'CHARACTER VARYING',
  'NVARCHAR',
  'TEXT',
  'DATE',
  'TIMESTAMP',
  'TIMESTAMP WITH TIME ZONE',
  'TIMESTAMP WITHOUT TIME ZONE',
  'TIMESTAMPTZ',
];

const mssql = [
  'bit',
  'int',
  'tinyint',
  'smallint',
  'bigint',
  'uniqueidentifier',
  'money',
  { decimal: { defaultSize: '12,2' } },
  'real',
  { float: { defaultSize: '12' } },
  'date',
  'datetime',
  'smalldatetime',
  'datetime2',
  { time: { defaultSize: '7' } },
  'timestamp',
  { char: { defaultSize: '255' } },
  'text',
  { varchar: { defaultSize: '255' } },
  { nchar: { defaultSize: '255' } },
  { nvarchar: { defaultSize: '255' } },
  'ntext',
  { binary: { defaultSize: '255', maxSize: '8000' } },
  'image',
  { varbinary: { defaultSize: '8000', maxSize: '8000' } },
];

const impala = [
  'bigint',
  'boolean',
  { char: { defaultSize: '255' } },
  'double',
  { decimal: { defaultSize: '9,0' } },
  'float',
  'int',
  'real',
  'smallint',
  'string',
  'timestamp',
  'tinyint',
  { varchar: { defaultSize: '255' } },
];

const hive = [
  'bigint',
  'boolean',
  { char: { defaultSize: '255' } },
  { decimal: { defaultSize: '9,0' } },
  'double',
  'float',
  'int',
  'real',
  'smallint',
  'string',
  'timestamp',
  'tinyint',
  'date',
  { varchar: { defaultSize: '255' } },
  'binary',
];

const oracle = [
  'bfile',
  'binary_float',
  'binary_double',
  'blob',
  { char: { defaultSize: '255', maxSize: '2000' } },
  'clob',
  'date',
  { nchar: { defaultSize: '255', maxSize: '2000' } },
  'nclob',
  { nvarchar2: { defaultSize: '255', maxSize: '4000' } },
  { number: { defaultSize: '12,2', maxSize: '38,127' } },
  { raw: { defaultSize: '255', maxSize: '2000' } },
  'rowid',
  'timestamp',
  'urowid',
  { varchar2: { defaultSize: '255', maxSize: '4000' } },
];

export const snowflake = [
  { number: { defaultSize: '38,0', maxSize: '38,37' } },
  { dec: { defaultSize: '38,0', maxSize: '38,37' } },
  { decimal: { defaultSize: '38,0', maxSize: '38,37' } },
  { numeric: { defaultSize: '38,0', maxSize: '38,37' } },
  'int',
  'integer',
  'bigint',
  'smallint',
  'tinyint',
  'byteint',
  'float',
  'float4',
  'float8',
  'double',
  'double precision',
  'real',
  {
    varchar: {
      defaultSize: '255',
      maxSize: '16777216',
      sizeOptions: [
        { value: '255', label: '255' },
        { value: '65535', label: '65535' },
        { value: '16777216', label: '16M' },
      ],
    },
  },
  { char: { defaultSize: '255', maxSize: '16777216' } },
  { character: { defaultSize: '255', maxSize: '16777216' } },
  { 'char varying': { defaultSize: '255', maxSize: '16777216' } },
  { 'character varying': { defaultSize: '255', maxSize: '16777216' } },
  { string: { defaultSize: '255', maxSize: '16777216' } },
  { text: { defaultSize: '16777216', maxSize: '16777216' } },
  { 'nchar varying': { defaultSize: '255', maxSize: '16777216' } },
  { nchar: { defaultSize: '255', maxSize: '16777216' } },
  { nvarchar: { defaultSize: '255', maxSize: '16777216' } },
  { nvarchar2: { defaultSize: '255', maxSize: '16777216' } },
  'boolean',
  'date',
  { time: { defaultSize: '9', maxSize: '9' } },
  'datetime',
  'timestamp',
  'timestamp_ltz',
  'timestamp_ntz',
  'timestamp_tz',
  'variant',
  { binary: { defaultSize: '255', maxSize: '8388608' } },
  'varbinary',
  'object',
  'array',
  'geography',
  'geometry',
];

const pgsql = [
  'int',
  'smallint',
  'integer',
  'bigint',
  'uuid',
  { decimal: { defaultSize: '12,2' } },
  { numeric: { defaultSize: '12,0' } },
  'real',
  'double precision',
  'float4',
  'float8',
  'serial',
  'bigserial',
  'smallserial',
  'money',
  'boolean',
  { char: { defaultSize: '255' } },
  { character: { defaultSize: '255' } },
  { varchar: { defaultSize: '255' } },
  { 'character varying': { defaultSize: '255' } },
  'text',
  'bytea',
  'date',
  'time',
  'time with time zone',
  'timestamp',
  'timestamptz',
  'timestamp with time zone',
  'interval',
  { enum: { defaultSize: 'my_enum_type' } },
  'json',
  'jsonb',
  'int[]',
  'smallint[]',
  'integer[]',
  'bigint[]',
  { 'decimal[]': { defaultSize: '12,2' } },
  { 'numeric[]': { defaultSize: '12,0' } },
  'real[]',
  'double precision[]',
  'float4[]',
  'float8[]',
  'serial[]',
  'bigserial[]',
  'smallserial[]',
  'money[]',
  'boolean[]',
  { 'char[]': { defaultSize: '255' } },
  { 'character[]': { defaultSize: '255' } },
  { 'varchar[]': { defaultSize: '255' } },
  { 'character varying[]': { defaultSize: '255' } },
  'text[]',
  'bytea[]',
  'date[]',
  'time[]',
  'time with time zone[]',
  'timestamp[]',
  'timestamptz[]',
  'timestamp with time zone[]',
  'interval[]',
  { 'enum[]': { defaultSize: 'my_enum_type' } },
  'json[]',
  'jsonb[]',
];

const pairity = [
  'int',
  'smallint',
  'integer',
  'bigint',
  { decimal: { defaultSize: '12,2' } },
  { numeric: { defaultSize: '12,0' } },
  'real',
  'double precision',
  'float4',
  'float8',
  'serial',
  'bigserial',
  'smallserial',
  'money',
  'boolean',
  { char: { defaultSize: '255' } },
  { character: { defaultSize: '255' } },
  { varchar: { defaultSize: '255' } },
  { 'character varying': { defaultSize: '255' } },
  'text',
  'bytea',
  'date',
  'time',
  'time with time zone',
  'timestamp',
  'timestamptz',
  'timestamp with time zone',
  'interval',
  { enum: { defaultSize: 'my_enum_type' } },
  'json',
  'jsonb',
  'integer[]',
];

const thoughtspot = [
  'int',
  'bigint',
  'double',
  'float',
  'bool',
  { varchar: { defaultSize: '255' } },
  'timestamp',
  'date',
  'time',
  'datetime',
];

const synapse = [
  { decimal: { defaultSize: '38,0', maxSize: '38,37' } },
  { numeric: { defaultSize: '38,0', maxSize: '38,37' } },
  { float: { defaultSize: '53', maxSize: '53' } },
  'real',
  'money',
  'smallmoney',
  'bigint',
  'int',
  'smallint',
  'tinyint',
  'bit',
  { varchar: { defaultSize: '255', maxSize: '8000' } },
  { nvarchar: { defaultSize: '255', maxSize: '4000' } },
  { nchar: { defaultSize: '1', maxSize: '4000' } },
  { char: { defaultSize: '1', maxSize: '8000' } },
  { varbinary: { defaultSize: '255', maxSize: '8000' } },
  { binary: { defaultSize: '8000', maxSize: '8000' } },
  'uniqueidentifier',
  'datetimeoffset',
  'datetime2',
  'datetime',
  'smalldatetime',
  'date',
  'time',
];

const sisense = [
  'bigint',
  'bit',
  'char',
  'date',
  'datetime',
  'decimal',
  'float',
  'int',
  'integer',
  'smallint',
  'text',
  'time',
  'timestamp',
  'tinyint',
  'varchar',
];

const firebolt = [
  'INT',
  'INTEGER',
  'BIGINT',
  'LONG',
  'FLOAT',
  'DOUBLE',
  'DOUBLE PRECISION',
  'VARCHAR',
  'TEXT',
  'STRING',
  'DATE',
  'TIMESTAMP',
  'DATETIME',
  'BOOLEAN',
];

const exasol = [
  'BOOLEAN',
  { CHAR: { defaultSize: '1', maxSize: '2000' } },
  'DATE',
  'INTEGER',
  { DECIMAL: { defaultSize: '18,0', maxSize: '36,36' } },
  'DOUBLE PRECISION',
  'GEOMETRY',
  'INTERVAL DAY TO SECOND',
  'INTERVAL YEAR TO MONTH',
  'TIMESTAMP',
  'TIMESTAMP WITH LOCAL TIME ZONE',
  { VARCHAR: { defaultSize: '255', maxSize: '2000000' } },
  'HASHTYPE',
];

export const bigquery = [
  'ARRAY',
  'BOOL',
  { BYTES: { defaultSize: '1024', maxSize: '9223372036854775807' } },
  'DATE',
  'DATETIME',
  'TIME',
  'TIMESTAMP',
  'GEOGRAPHY',
  'INTERVAL',
  'JSON',
  'INT64',
  'INT',
  'SMALLINT',
  'INTEGER',
  'BIGINT',
  'TINYINT',
  'BYTEINT',
  { NUMERIC: { defaultSize: '18,0', maxSize: '38,9' } },
  { DECIMAL: { defaultSize: '18,0', maxSize: '38,9' } },
  { BIGNUMERIC: { defaultSize: '38,0', maxSize: '76,38' } },
  { BIGDECIMAL: { defaultSize: '38,0', maxSize: '76,38' } },
  'FLOAT64',
  { STRING: { defaultSize: '255', maxSize: '9223372036854775807' } },
  'STRUCT',
];

const snowflakeDataTypes = {
  typesList: snowflake,
  default: {
    type: 'string',
    size: '255',
  },
};

const DataTypes = {
  [KEBOOLA_WR_DB_SNOWFLAKE]: snowflakeDataTypes,
  [KEBOOLA_WR_DB_SNOWFLAKE_GCS]: snowflakeDataTypes,
  [KEBOOLA_WR_DB_SNOWFLAKE_GCS_S3]: snowflakeDataTypes,
  [KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE]: snowflakeDataTypes,
  [KEBOOLA_WR_LOOKER_V2]: snowflakeDataTypes,
  [KEBOOLA_WR_DB_ORACLE]: {
    typesList: oracle,
    default: {
      type: 'varchar2',
      size: '255',
    },
  },
  [KEBOOLA_WR_DB_IMPALA]: {
    typesList: impala,
    default: {
      type: 'varchar',
      size: '255',
    },
  },
  [KEBOOLA_WR_DB_HIVE]: {
    typesList: hive,
    default: {
      type: 'varchar',
      size: '255',
    },
  },
  [KDS_TEAM_WR_HIVE_CSAS]: {
    typesList: hive,
    default: {
      type: 'varchar',
      size: '255',
    },
  },
  [KEBOOLA_WR_DB_MSSQL_V_2]: {
    typesList: mssql,
    default: {
      type: 'nvarchar',
      size: '255',
    },
  },
  [KEBOOLA_WR_DB_PGSQL]: {
    typesList: pgsql,
    default: {
      type: 'varchar',
      size: '255',
    },
  },
  [KDS_TEAM_WR_PAIRITY]: {
    typesList: pairity,
    default: {
      type: 'varchar',
      size: '255',
    },
  },
  [KEBOOLA_WR_REDSHIFT_V_2]: {
    typesList: redshift,
    default: {
      type: 'VARCHAR',
      size: '255',
    },
  },
  [KEBOOLA_WR_DB_MYSQL]: {
    typesList: mysql,
    default: {
      type: 'TEXT',
    },
  },
  [KEBOOLA_WR_THOUGHTSPOT]: {
    disabledFields: ['nullable', 'default'],
    typesList: thoughtspot,
    default: {
      type: 'varchar',
      size: '255',
    },
  },
  [KEBOOLA_WR_DB_SYNAPSE]: {
    typesList: synapse,
    default: {
      type: 'nvarchar',
      size: '255',
    },
  },
  [KEBOOLA_WR_SISENSE]: {
    disabledFields: ['nullable', 'default'],
    typesList: sisense,
    default: {
      type: 'varchar',
    },
  },
  [KDS_TEAM_WR_FIREBOLT]: {
    disabledFields: ['default'],
    typesList: firebolt,
    default: {
      type: 'VARCHAR',
    },
  },
  [KDS_TEAM_WR_EXASOL]: {
    typesList: exasol,
    default: {
      type: 'VARCHAR',
      size: '255',
    },
  },
  [KEBOOLA_WR_GOOGLE_BIGQUERY_V_2]: {
    typesList: bigquery,
    default: {
      type: 'STRING',
      size: '255',
    },
  },
};

export { getComponentDataTypes, getDisabledColumnFields };

export default DataTypes;
