import React from 'react';
import { Button } from 'react-bootstrap';

import { URLS } from '@keboola/constants';
import { cn, Link } from '@keboola/design';

import { routeNames } from '@/modules/flows/constants';
import { RouterLink } from '@/react/common';
import contactSupport from '@/utils/contactSupport';

const NoResultsFound = ({ entityName, className }: { entityName: string; className?: string }) => {
  if (entityName === 'component') {
    return (
      <div className="box text-center no-component-found">
        <div className="box-header">
          <h2 className="box-title fill-space">Can&apos;t find the component you need?</h2>
        </div>
        <div className="box-content">
          <p className="line-height-20 text-muted">
            Let us know which component is missing and we&apos;ll do our best to make it available
            to you as soon as possible. If you are a developer, you can try creating the component
            on your own.
          </p>
          <div className="flex-container justify-center">
            <Button
              className="mr-1 f-12 line-height-20 color-dark"
              onClick={() => contactSupport({ ticketContext: { requesting_new: 'component' } })}
            >
              Let us know
            </Button>
            <Link
              href={URLS.COMPONENTS}
              className="btn btn-link btn-link-inline ml-1 text-muted f-14"
            >
              Create Own Component
            </Link>
          </div>
        </div>
      </div>
    );
  }

  if (entityName === 'template') {
    return (
      <div className="box text-center no-component-found">
        <div className="box-header">
          <h2 className="box-title fill-space">Can&apos;t find the template you need?</h2>
        </div>
        <div className="box-content">
          <p className="line-height-20 text-muted">
            Create your own flow, or let us know which template is missing, and we&apos;ll do our
            best to make it available to you as soon as possible.
          </p>
          <div className="flex-container justify-center">
            <RouterLink to={routeNames.ROOT} className="btn btn-success mr-2">
              Create Flow
            </RouterLink>
            <Button
              bsStyle="link"
              className="btn-link-inline text-muted"
              onClick={() => contactSupport({ ticketContext: { requesting_new: 'template' } })}
            >
              Let us know
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn('box', className)}>
      <div className="box-content">No {entityName} found.</div>
    </div>
  );
};

export default NoResultsFound;
