import React from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import type { List, Map } from 'immutable';

import { URLS } from '@keboola/constants';

import { routeNames as billingRouteNames } from '@/modules/billing/constants';
import { routeNames as settingsRouteNames } from '@/modules/settings/constants';
import { routeNames as telemetryRouteNames } from '@/modules/telemetry/routes';
import { Truncated } from '@/react/common';
import FragmentWithForwaredProps from '@/react/common/FragmentWithForwaredProps';
import Gravatar from '@/react/common/Gravatar';
import contactSupport from '@/utils/contactSupport';
import LinkMenuItem from './LinkMenuItem';
import UserFeaturesModal from './UserFeaturesModal';

type Props = {
  user: Map<string, any>;
  urlTemplates: Map<string, string>;
  availableFeatures: List<any>;
  activatedFeatures: List<string>;
  canManageApps: boolean;
  hasPayAsYouGo: boolean;
  isMarketplaceProject: boolean;
  hasOrganizationUsage: boolean;
  hasActivityCenter: boolean;
  hasInvalidCustomBackend: boolean;
};

const DividerStyle = '!tw-m-2 !tw-bg-neutral-200';

const CurrentUser = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [showFeaturesModal, setShowFeaturesModal] = React.useState(false);

  return (
    <>
      <div className="user">
        <Dropdown
          id="current-user-dropdown"
          rootCloseEvent="mousedown"
          open={isOpen}
          onToggle={(isOpen: boolean, event: any, data: { source: string }) => {
            if (
              data.source === 'rootClose' &&
              event.composedPath?.().some((el: Element) => el.id === 'current-user-dropdown')
            ) {
              return;
            }

            setIsOpen(isOpen);
          }}
          pullRight
        >
          <Dropdown.Toggle
            noCaret
            bsStyle="link"
            className="tw-flex tw-h-10 tw-items-center tw-p-0 tw-text-left"
          >
            <Gravatar user={props.user} className="user-avatar" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="tw-mt-1 tw-min-w-[240px] tw-border tw-border-neutral-150 tw-p-2">
            <div className="tw-flex tw-flex-col tw-p-2">
              {!!props.user.get('name') && (
                <Truncated className="tw-text-base tw-font-medium" text={props.user.get('name')} />
              )}
              <Truncated
                className="tw-text-xs tw-text-neutral-400"
                text={props.user.get('email')}
              />
            </div>

            {!props.hasInvalidCustomBackend && (
              <FragmentWithForwaredProps>
                {props.canManageApps && (
                  <MenuItem
                    target="_blank"
                    rel="noopener noreferrer"
                    href={props.urlTemplates.get('manageApps')}
                  >
                    Manage Applications
                  </MenuItem>
                )}
                <MenuItem
                  target="_blank"
                  rel="noopener noreferrer"
                  href={props.urlTemplates.get('changePassword')}
                >
                  My Account &amp; Organizations
                </MenuItem>
                <MenuItem onClick={() => setShowFeaturesModal(true)}>User Features</MenuItem>
                <MenuItem divider className={DividerStyle} />
                <LinkMenuItem to={settingsRouteNames.PROJECT} forceProduction>
                  Project Settings
                </LinkMenuItem>
                <LinkMenuItem to={telemetryRouteNames.BILLING_CONSUMPTION}>
                  Project Consumption
                </LinkMenuItem>
                {props.hasOrganizationUsage && (
                  <LinkMenuItem to={telemetryRouteNames.ORGANIZATION_USAGE}>
                    Organization Usage
                  </LinkMenuItem>
                )}
                {props.hasActivityCenter && (
                  <LinkMenuItem to={telemetryRouteNames.ACTIVITY_CENTER}>
                    Activity Center
                  </LinkMenuItem>
                )}
                {props.hasPayAsYouGo && !props.isMarketplaceProject && (
                  <LinkMenuItem to={billingRouteNames.BILLING_OVERVIEW}>Billing</LinkMenuItem>
                )}
                <MenuItem divider className={DividerStyle} />
              </FragmentWithForwaredProps>
            )}
            <MenuItem
              onClick={(e: React.SyntheticEvent) => {
                e.preventDefault();

                contactSupport();
              }}
            >
              Support
            </MenuItem>
            <MenuItem href={URLS.USER_DOCUMENTATION} target="_blank" rel="noopener noreferrer">
              Help
            </MenuItem>
            <MenuItem href={URLS.ACADEMY} target="_blank" rel="noopener noreferrer">
              Academy
            </MenuItem>
            <MenuItem divider className={DividerStyle} />
            <MenuItem href={props.urlTemplates.get('logout')}>Logout</MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <UserFeaturesModal
        show={showFeaturesModal}
        onHide={() => setShowFeaturesModal(false)}
        availableFeatures={props.availableFeatures}
        activatedFeatures={props.activatedFeatures}
      />
    </>
  );
};

export default CurrentUser;
