import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fromJS, type Map } from 'immutable';

import { Clipboard, cn, Tooltip } from '@keboola/design';

import { routeNames } from '@/modules/storage/constants';
import actions from '@/modules/stream/actions';
import { getLatestChange, getLatestImport, getTotalStatistics } from '@/modules/stream/helpers';
import type { StoreSource } from '@/modules/stream/store';
import {
  CreatedDate,
  RouterLink,
  RowActionDropdown,
  RowActionMenuItem,
  SortIcon,
} from '@/react/common';
import ConfirmModal from '@/react/common/ConfirmModal';
import LazyList from '@/react/common/LazyList';
import Loader from '@/react/common/Loader';
import MultiActionsHeader from '@/react/common/MultiActionsHeader';
import MultiActionsSelectCheckbox from '@/react/common/MultiActionsSelectCheckbox';
import { StorageData } from '@/react/common/StorageData';
import string from '@/utils/string';
import RecordResults from './RecordResults';
import { TotalDataStatistics } from './TotalStatistics';

type Props = {
  sources: StoreSource[];
  admins: Map<string, any>;
  tables: Map<string, any>;
  readOnly: boolean;
};

type SortableColumnId = 'name' | 'last-change';

const IndexTable = ({ sources, /* admins, */ tables, readOnly = false }: Props) => {
  const [sort, setSort] = useState<{
    id: SortableColumnId;
    direction: number;
  }>({
    id: 'name',
    direction: 1,
  });
  const [selectedSources, setSelectedSources] = useState<Record<string, boolean>>({});
  const [sourcesToDelete, setSourcesToDelete] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);

  const sortValueGetter = useCallback(
    (source: StoreSource) => {
      if (sort.id === 'last-change') {
        return getLatestChange(source);
      }

      return source[sort.id];
    },
    [sort.id],
  );

  return (
    <div className="box">
      <div className="box-content p-0">
        <div className="table table-hover overflow-break-anywhere">
          <div className="thead">
            <div className="tr tw-text-right">
              <span className="th w-300">
                <MultiActionsHeader
                  hide={readOnly}
                  disabled={isDeleting}
                  totalCount={sources.length}
                  selectedCount={Object.values(selectedSources).length}
                  onToggleAll={(checked) =>
                    setSelectedSources(
                      !checked
                        ? {}
                        : sources.reduce(
                            (selected, { sourceId }) => ({
                              ...selected,
                              [sourceId]: true,
                            }),
                            {},
                          ),
                    )
                  }
                  placeholder={
                    <SortableColumnTitle
                      id="name"
                      direction={sort.direction}
                      isActive={sort.id === 'name'}
                      onClickHandler={setSort}
                    />
                  }
                  entity="data stream"
                >
                  <div className="table-action-buttons">
                    <Tooltip placement="top" tooltip="Delete Selected">
                      <Button
                        bsStyle="link"
                        className="text-muted"
                        onClick={() => setSourcesToDelete(Object.keys(selectedSources))}
                        disabled={isDeleting}
                      >
                        {isDeleting ? <Loader /> : <FontAwesomeIcon icon="trash" fixedWidth />}
                      </Button>
                    </Tooltip>
                  </div>
                </MultiActionsHeader>
              </span>
              <span className="th" />
              <span className="th w-175" />
              <span className="th w-200">Waiting / Imported Data</span>
              <span className="th w-150">Last Import</span>
              <span className="th w-175">Record Results</span>
              <span className="th">
                <SortableColumnTitle
                  id="last-change"
                  label="Last Change"
                  direction={sort.direction}
                  isActive={sort.id === 'last-change'}
                  onClickHandler={setSort}
                />
              </span>
            </div>
          </div>
          <LazyList
            rootElement="div"
            className="tbody"
            items={sources.sort((a, b) => {
              const valueA = sortValueGetter(a);
              const valueB = sortValueGetter(b);
              if (valueA < valueB) return 1 * sort.direction;
              if (valueA > valueB) return -1 * sort.direction;
              return 0;
            })}
            render={(items) =>
              items.map((source: StoreSource) => {
                const sourceTables: Map<string, any> | undefined = fromJS(
                  source.sinks
                    ?.flatMap((sink) => tables.get(sink.table?.tableId ?? ''))
                    .filter(Boolean),
                )?.toMap();

                return (
                  <RouterLink
                    key={source.sourceId}
                    to={routeNames.STREAM_DETAIL}
                    params={{ sourceId: source.sourceId }}
                    className="tr hoverable-actions-with-replacement color-inherit tw-text-right"
                  >
                    <div className="td tw-text-left">
                      {!readOnly && (
                        <MultiActionsSelectCheckbox
                          isChecked={!!selectedSources[source.sourceId]}
                          isDisabled={readOnly || isDeleting}
                          onToggle={(checked) => {
                            const newSelectedSources = { ...selectedSources };

                            if (checked) {
                              newSelectedSources[source.sourceId] = true;
                            } else {
                              delete newSelectedSources[source.sourceId];
                            }

                            setSelectedSources(newSelectedSources);
                          }}
                          entity="data stream"
                        />
                      )}
                      <span className={cn({ 'ml-1': !readOnly })}>{source.name}</span>
                    </div>
                    <div className="td plp-1">
                      {sourceTables && <StorageData tables={sourceTables} />}
                    </div>
                    <div className="td plp-1">
                      {!!source.http && (
                        <Clipboard
                          text={source.http.url}
                          label={<span className="underline color-primary">Copy URL</span>}
                        />
                      )}
                    </div>
                    <div className="td">
                      <TotalDataStatistics totalStatistics={getTotalStatistics(source)} />
                    </div>
                    <div className="td">
                      <CreatedDate createdTime={getLatestImport(source)} />
                    </div>
                    <div className="td">
                      <RecordResults source={source} />
                    </div>
                    <div className="td text-right no-wrap">
                      <div className="actions-container">
                        <div className="not-actions">
                          <CreatedDate createdTime={getLatestChange(source)} />
                        </div>
                        {!readOnly && (
                          <div className="actions">
                            <RowActionDropdown>
                              <RowActionMenuItem
                                disabled={isDeleting}
                                onSelect={() => setSourcesToDelete([source.sourceId])}
                              >
                                <FontAwesomeIcon fixedWidth icon="trash" />
                                Delete Data Stream
                              </RowActionMenuItem>
                            </RowActionDropdown>
                          </div>
                        )}
                      </div>
                    </div>
                  </RouterLink>
                );
              })
            }
          />
        </div>
      </div>
      <ConfirmModal
        closeAfterResolve
        show={!!sourcesToDelete.length}
        icon="trash"
        title="Delete Selected"
        text={`Are you sure you want to delete ${
          sourcesToDelete.length > 1 ? 'selected data streams' : 'data stream'
        }?`}
        buttonLabel="Delete"
        buttonType="danger"
        onConfirm={() => {
          setIsDeleting(true);

          return actions
            .deleteSources(sourcesToDelete)
            .then(() => setSelectedSources({}))
            .finally(() => setIsDeleting(false));
        }}
        onHide={() => setSourcesToDelete([])}
        isLoading={isDeleting}
      />
    </div>
  );
};

const SortableColumnTitle = ({
  id,
  label = id,
  direction,
  isActive,
  onClickHandler,
}: {
  id: SortableColumnId;
  label?: string;
  direction: number;
  isActive: boolean;
  onClickHandler: (sort: { id: SortableColumnId; direction: number }) => void;
}) => {
  return (
    <span
      className="clickable"
      title={`Sort by ${label}`}
      onClick={() => onClickHandler({ id, direction: isActive ? direction * -1 : 1 })}
    >
      <SortIcon isSorted={isActive} isSortedDesc={direction === -1} />
      {string.titleize(label)}
    </span>
  );
};

export default IndexTable;
