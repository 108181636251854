import type { Map } from 'immutable';
import { List } from 'immutable';

import {
  KEBOOLA_EX_HTTP as componentId,
  KEBOOLA_PROCESSOR_MOVE_FILES,
} from '@/constants/componentIds';
import columnTypes from '@/modules/configurations/utils/columnTypeConstants';
import createRoute from '@/modules/configurations/utils/createRoute';
import { CollapsibleSection } from '@/modules/configurations/utils/renderHelpers';
import conform from './adapters/conform';
import {
  createConfiguration as credentialsCreateConfiguration,
  isComplete as credentialsIsComplete,
  parseConfiguration as credentialsParseConfiguration,
} from './adapters/credentials';
import {
  createConfiguration as rowCreateConfiguration,
  createEmptyConfiguration as rowCreateEmptyConfiguration,
  parseConfiguration as rowParseConfiguration,
} from './adapters/row';
import ConfigurationForm from './react/components/Configuration';
import CredentialsForm from './react/components/Credentials';

const routeSettings = {
  componentId,
  index: {
    sections: [
      {
        render: CollapsibleSection({
          title: 'Base URL and Connection Options',
          contentComponent: CredentialsForm,
          options: { includeSaveButtons: true },
        }),
        onSave: credentialsCreateConfiguration,
        onLoad: credentialsParseConfiguration,
        isComplete: credentialsIsComplete,
      },
    ],
  },
  row: {
    onConform: conform,
    sections: [
      {
        render: ConfigurationForm,
        onSave: rowCreateConfiguration,
        onCreate: rowCreateEmptyConfiguration,
        onLoad: rowParseConfiguration,
      },
    ],
    columns: [
      {
        name: 'Storage',
        type: columnTypes.TABLE_LINK_DEFAULT_BUCKET,
        value: (row: Map<string, any>) => {
          return row
            .getIn(['processors', 'after'], List())
            .find((processor: Map<string, any>) => {
              return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_MOVE_FILES;
            })
            ?.getIn(['parameters', 'folder']);
        },
      },
    ],
  },
};

export default createRoute(routeSettings);
