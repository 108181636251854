import React from 'react';
import type Promise from 'bluebird';
import { Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import NewTransformationModal from '@/modules/components/react/components/NewTransformationModal';
import ComponentBoxModal from '@/modules/components-directory/components/ComponentBoxModal';

const NewConfigurationModal = (props: {
  show: boolean;
  onHide: () => void;
  onCreate: (componentId: string, configId: string) => Promise<void>;
  forceTransformationName: string;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  hasConfigurations: boolean;
  task: Map<string, any>;
  folders: Map<string, any>;
  allComponents: Map<string, any>;
  patternComponents: Map<string, any>;
}) => {
  const componentId = props.task.get('componentId');
  const component = props.allComponents.get(componentId, Map());

  if (component.get('type') === componentTypes.TRANSFORMATION) {
    return (
      <NewTransformationModal
        show={props.show}
        onHide={props.onHide}
        onCreate={props.onCreate}
        forceName={props.forceTransformationName}
        forceComponent={component}
        hasPayAsYouGo={props.hasPayAsYouGo}
        patternComponents={props.patternComponents}
        // we can add into flow only allowed transformation, so here we can pass all transformations
        allowedComponents={props.allComponents.filter(
          (component) => component.get('type') === componentTypes.TRANSFORMATION,
        )}
        folders={props.folders}
      />
    );
  }

  return (
    <ComponentBoxModal
      show={props.show}
      onHide={props.onHide}
      onCreate={props.onCreate}
      readOnly={props.readOnly}
      component={component}
      allComponents={props.allComponents}
      hasConfigurations={props.hasConfigurations}
      allowFolder={componentId === KEBOOLA_ORCHESTRATOR}
      folders={props.folders.get(KEBOOLA_ORCHESTRATOR, Map()).toSet().toList()}
    />
  );
};

export default NewConfigurationModal;
