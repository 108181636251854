import React from 'react';
import type { Map } from 'immutable';

import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import { DBT_COMPONENTS } from '@/modules/transformations-v2/constants';
import string from '@/utils/string';

type Props = {
  component: Map<string, any>;
  children?: (name: string) => React.ReactNode;
  showType?: boolean;
  capitalize?: boolean;
};

const ComponentName = ({ component, children, showType = false, capitalize = false }: Props) => {
  const type = getNewComponentTypeLabel(component.get('type'));
  const name =
    capitalize && !DBT_COMPONENTS.includes(component.get('id'))
      ? string.capitalize(component.get('name'))
      : component.get('name');

  if (
    showType &&
    ['extractor', 'writer', 'application', 'transformation'].includes(component.get('type'))
  ) {
    return children ? (
      children(`${name} ${type}`)
    ) : (
      <>
        <span className="font-medium">{name}</span>{' '}
        <small className="component-type text-muted">{type}</small>
      </>
    );
  }

  return children ? children(name) : name;
};

export default ComponentName;
