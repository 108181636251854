import React from 'react';

import { Alert } from '@keboola/design';

const ChangePrimaryKeyWarning = (props: { action: 'update' | 'delete' }) => {
  return (
    <Alert
      variant="warning"
      title={`${props.action === 'update' ? 'Modifying' : 'Deleting'} the primary key may require additional changes`}
      className="tw-mt-4"
    >
      This table may be referenced elsewhere, so review your configurations to ensure that they
      remain valid after making this change.
    </Alert>
  );
};

export default ChangePrimaryKeyWarning;
