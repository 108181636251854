import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { Row } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { List, type Map } from 'immutable';

import { cn } from '@keboola/design';

import { SortIcon, Truncated } from '@/react/common';
import Checkbox from '@/react/common/Checkbox';
import Loader from '@/react/common/Loader';
import MarkedText from '@/react/common/MarkedText';
import hasSelections from '@/utils/hasSelections';

export type SelectedSheets = { file: Map<string, any>; sheet: Map<string, any> }[];

type FileData = { file: Map<string, any> };
type SheetData = { file: Map<string, any>; sheet: Map<string, any> };
type SubRow = Row<{ data: SheetData }>;

type DataRow = Row<{ data: FileData; subRows: SubRow[] }>;

const SheetsSelector = ({
  isLoading,
  files,
  searchQuery,
  savedSheets,
  selectSheets,
}: {
  isLoading: boolean;
  files: List<any>;
  searchQuery: string;
  savedSheets: Map<string, any>;
  selectSheets: (sheets: SelectedSheets, selected: boolean) => void;
}) => {
  const isSaved = React.useCallback(
    (row: SubRow) => {
      return savedSheets.some((sheet) => {
        return (
          sheet.get('fileId') === row.original.data.file.get('id') &&
          sheet.get('sheetId') === row.original.data.sheet.get('sheetId')
        );
      });
    },
    [savedSheets],
  );

  const columns = React.useMemo(() => {
    return [
      {
        accessorKey: 'data',
        sortingFn: (rowA: any, rowB: any) => {
          const [entity, key] = rowA.depth === 0 ? ['file', 'name'] : ['sheet', 'sheetTitle'];

          return rowA.original.data[entity]
            .get(key)
            .toLowerCase()
            .localeCompare(rowB.original.data[entity].get(key).toLowerCase());
        },
        header: ({ table }: any) => {
          const rows = table.getRowModel().rows;
          const isSelectable = rows.some((row: any) =>
            row.subRows.some((subRow: SubRow) => !isSaved(subRow)),
          );
          const isAllSelected =
            isSelectable &&
            rows.every((row: any) => {
              return row.subRows.every((subRow: SubRow) => {
                return subRow.original.data.sheet.get('selected', false) || isSaved(subRow);
              });
            });
          const isSomeSelected =
            !isAllSelected &&
            isSelectable &&
            rows.some((row: any) => {
              return row.subRows.some((subRow: SubRow) => {
                return subRow.original.data.sheet.get('selected', false);
              });
            });

          return (
            <>
              {isSelectable && (
                <Checkbox
                  className="tw-mr-3"
                  checked={isAllSelected}
                  indeterminate={isSomeSelected}
                  onChange={() => {
                    selectSheets(
                      rows
                        .map((row: any) => {
                          return row.subRows
                            .filter((subRow: SubRow) => !isSaved(subRow))
                            .map((subRow: SubRow) => subRow.original.data);
                        })
                        .flat(),
                      isSomeSelected || isAllSelected ? false : true,
                    );
                  }}
                />
              )}
              Sheets
            </>
          );
        },
        cell: ({ row }: any) => {
          if (row.depth === 1) {
            const isSheetAdded = isSaved(row);

            return (
              <div className="tw-ml-4 tw-inline-flex tw-items-center">
                {isSheetAdded ? (
                  <Checkbox disabled tooltip="Sheet is already added" />
                ) : (
                  <Checkbox
                    checked={row.original.data.sheet.get('selected', false)}
                    onChange={(checked) => selectSheets([row.original.data], checked)}
                  />
                )}
                <FontAwesomeIcon icon="table-cells" className="text-muted tw-mx-3" />
                <Truncated
                  tooltip={row.original.data.sheet.get('sheetTitle')}
                  text={
                    <MarkedText
                      source={row.original.data.sheet.get('sheetTitle')}
                      mark={searchQuery}
                    />
                  }
                />
                {isSheetAdded && (
                  <span className="tw-ml-auto tw-text-xs tw-font-medium tw-text-neutral-400">
                    Added
                  </span>
                )}
              </div>
            );
          }

          const isSelectable = row.subRows.some((subRow: SubRow) => !isSaved(subRow));
          const isAllSelected =
            isSelectable &&
            row.subRows.every((subRow: SubRow) => {
              return subRow.original.data.sheet.get('selected', false) || isSaved(subRow);
            });
          const isSomeSelected =
            !isAllSelected &&
            isSelectable &&
            row.subRows.some((subRow: SubRow) => {
              return subRow.original.data.sheet.get('selected', false);
            });

          return (
            <div className="tw-inline-flex tw-items-center">
              {!isSelectable ? (
                <Checkbox disabled tooltip="All sheets already added" />
              ) : (
                <Checkbox
                  checked={isAllSelected}
                  indeterminate={isSomeSelected}
                  onChange={() => {
                    selectSheets(
                      row.subRows
                        .filter((subRow: SubRow) => !isSaved(subRow))
                        .map((subRow: SubRow) => subRow.original.data),
                      isSomeSelected || isAllSelected ? false : true,
                    );
                  }}
                />
              )}
              <FontAwesomeIcon icon="table" className="text-muted tw-mx-3" />
              <Truncated
                tooltip={row.original.data.file.get('name')}
                text={<MarkedText source={row.original.data.file.get('name')} mark={searchQuery} />}
              />
            </div>
          );
        },
      },
    ];
  }, [selectSheets, searchQuery, isSaved]);

  const data = React.useMemo(() => {
    return files
      .map((file) => {
        return {
          data: { file },
          subRows: file
            .get('sheets', List())
            .map((sheet: any) => ({ data: { file, sheet } }))
            .toArray(),
        };
      })
      .toArray();
  }, [files]);

  const initialState = React.useMemo(() => {
    let expanded = {};

    if (!!searchQuery) {
      expanded = files.toArray().reduce((acc, file, index) => ({ ...acc, [index]: true }), {});
    }

    return { sorting: [{ id: 'data', desc: false }], expanded };
  }, [searchQuery, files]);

  const tableInstance = useReactTable({
    columns,
    data,
    initialState,
    enableSortingRemoval: false,
    autoResetExpanded: !!searchQuery,
    getSubRows: (row: any) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const rows: DataRow[] = tableInstance.getRowModel().rows;

  return (
    <table className="table table-hover react-table stretch-modal">
      <thead>
        {tableInstance.getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id}>
                    <div
                      className="tw-inline-flex tw-cursor-pointer tw-items-center"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <SortIcon
                        className="icon-addon-left"
                        isSorted={!!header.column.getIsSorted()}
                        isSortedDesc={header.column.getIsSorted() === 'desc'}
                      />
                    </div>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {!rows.length && (
          <tr className="no-hover">
            <td>
              {isLoading ? (
                <>
                  <Loader className="icon-addon-right" />
                  Loading files sheets...
                </>
              ) : (
                'No files selected'
              )}
            </td>
          </tr>
        )}
        {rows.map((row) => {
          const isSheetAdded = 'sheet' in row.original.data && isSaved(row as unknown as SubRow);

          return (
            <tr
              key={row.id}
              className={cn({ clickable: !isSheetAdded })}
              onClick={() => {
                if (isSheetAdded || hasSelections()) {
                  return;
                }

                if (row.depth === 0) {
                  return row.toggleExpanded();
                }

                const sheetData = row.original.data as SheetData;

                return selectSheets([sheetData], !sheetData.sheet.get('selected'));
              }}
            >
              {row.getAllCells().map((cell) => {
                return (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SheetsSelector;
