import React from 'react';
import Switch from 'rc-switch';

import { URLS } from '@keboola/constants';
import { Button, Link, Tooltip } from '@keboola/design';

import contactSupport from '@/utils/contactSupport';

const DataPersistency = (props: { isActive: boolean }) => {
  return (
    <div className="well pp-6">
      <h3 className="tw-mb-6 tw-mt-0">
        Workspace Data Persistency
        <Tooltip
          placement="top"
          tooltip={`Contact support to ${props.isActive ? 'disable' : 'enable'}`}
        >
          <Switch
            disabled
            prefixCls="switch"
            className="icon-addon-left wider"
            checked={props.isActive}
          />
        </Tooltip>
      </h3>
      <div className="value-list mb-0">
        <p className="tw-mb-6">
          When enabled newly created workspaces will have all the changes saved with Auto Sleep.
          Additional charges are applied, see{' '}
          <Link
            href={`${URLS.USER_DOCUMENTATION}/transformations/workspace/#data-persistency-beta`}
          >
            documentation
          </Link>
          .
        </p>
        <Button variant="outline" size="small" onClick={() => contactSupport()}>
          Contact Support
        </Button>
      </div>
    </div>
  );
};

export default DataPersistency;
