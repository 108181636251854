import { fromJS, List, Map } from 'immutable';

import {
  KEBOOLA_PROCESSOR_ADD_FILENAME_COLUMN,
  KEBOOLA_PROCESSOR_ADD_ROW_NUMBER_COLUMN,
  KEBOOLA_PROCESSOR_CREATE_MANIFEST,
  KEBOOLA_PROCESSOR_DECOMPRESS,
  KEBOOLA_PROCESSOR_FLATTEN_FOLDERS,
  KEBOOLA_PROCESSOR_MOVE_FILES,
  KEBOOLA_PROCESSOR_SKIP_LINES,
} from '@/constants/componentIds';

const createConfiguration = (localState: Map<string, any>) => {
  let processors: List<any> = List([]);

  // DECOMPRESS
  if (localState.get('decompress', false) === true) {
    processors = processors.push(
      ...fromJS([
        { definition: { component: KEBOOLA_PROCESSOR_DECOMPRESS } },
        { definition: { component: KEBOOLA_PROCESSOR_FLATTEN_FOLDERS } },
      ]),
    );
  }

  // MOVE FILES
  processors = processors.push(
    fromJS({
      definition: {
        component: KEBOOLA_PROCESSOR_MOVE_FILES,
      },
      parameters: {
        direction: 'tables',
        addCsvSuffix: true,
        folder: localState.get('name', ''),
      },
    }),
  );

  // CREATE MANIFEST
  let createManifestProcessor = fromJS({
    definition: {
      component: KEBOOLA_PROCESSOR_CREATE_MANIFEST,
    },
    parameters: {
      delimiter: localState.get('delimiter', ','),
      enclosure: localState.get('enclosure', '"'),
      incremental: localState.get('incremental', false),
      primary_key: localState.get('primaryKey', List()),
    },
  });
  if (localState.get('columnsFrom', 'header') === 'header') {
    createManifestProcessor = createManifestProcessor.setIn(
      ['parameters', 'columns_from'],
      'header',
    );
  } else if (localState.get('columnsFrom') === 'auto') {
    createManifestProcessor = createManifestProcessor.setIn(['parameters', 'columns_from'], 'auto');
  } else if (localState.get('columnsFrom', 'manual') === 'manual') {
    createManifestProcessor = createManifestProcessor.setIn(
      ['parameters', 'columns'],
      localState.get('columns', List()),
    );
  }
  processors = processors.push(createManifestProcessor);

  // SKIP FIRST LINE
  if (localState.get('columnsFrom', 'header') === 'header') {
    processors = processors.push(
      fromJS({
        definition: {
          component: KEBOOLA_PROCESSOR_SKIP_LINES,
        },
        parameters: {
          lines: 1,
        },
      }),
    );
  }

  // ADD ROW NUMBER
  if (localState.get('addRowNumberColumn')) {
    processors = processors.push(
      fromJS({
        definition: {
          component: KEBOOLA_PROCESSOR_ADD_ROW_NUMBER_COLUMN,
        },
        parameters: {
          column_name: 'ftp_row_number',
        },
      }),
    );
  }

  // ADD FILE NAME COLUMN
  if (localState.get('addFilenameColumn')) {
    processors = processors.push(
      fromJS({
        definition: {
          component: KEBOOLA_PROCESSOR_ADD_FILENAME_COLUMN,
        },
        parameters: {
          column_name: 'ftp_filename',
        },
      }),
    );
  }

  return fromJS({
    parameters: {
      onlyNewFiles: localState.get('onlyNewFiles', false),
      path: localState.get('path', ''),
    },
    processors: { after: processors },
  });
};

const parseConfiguration = (configuration: Map<string, any>) => {
  const processorCreateManifest = configuration.getIn(['processors', 'after'], List()).find(
    (processor: Map<string, any>) => {
      return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_CREATE_MANIFEST;
    },
    null,
    Map(),
  );
  const processorDecompress = configuration
    .getIn(['processors', 'after'], List())
    .find((processor: Map<string, any>) => {
      return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_DECOMPRESS;
    });
  const processorAddRowNumberColumn = configuration
    .getIn(['processors', 'after'], List())
    .find((processor: Map<string, any>) => {
      return (
        processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_ADD_ROW_NUMBER_COLUMN &&
        processor.getIn(['parameters', 'column_name']) === 'ftp_row_number'
      );
    });
  const processorAddFilenameColumn = configuration
    .getIn(['processors', 'after'], List())
    .find((processor: Map<string, any>) => {
      return (
        processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_ADD_FILENAME_COLUMN &&
        processor.getIn(['parameters', 'column_name']) === 'ftp_filename'
      );
    });
  const processorMoveFiles = configuration.getIn(['processors', 'after'], List()).find(
    (processor: Map<string, any>) => {
      return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_MOVE_FILES;
    },
    null,
    Map(),
  );
  let columnsFrom = processorCreateManifest.getIn(['parameters', 'columns_from'], 'header');
  if (processorCreateManifest.hasIn(['parameters', 'columns'])) {
    columnsFrom = 'manual';
  }
  return fromJS({
    path: configuration.getIn(['parameters', 'path'], ''),
    name: processorMoveFiles.getIn(['parameters', 'folder'], ''),
    onlyNewFiles: configuration.getIn(['parameters', 'onlyNewFiles'], false),
    incremental: processorCreateManifest.getIn(['parameters', 'incremental'], false),
    primaryKey: processorCreateManifest.getIn(['parameters', 'primary_key'], List()).toJS(),
    delimiter: processorCreateManifest.getIn(['parameters', 'delimiter'], ','),
    enclosure: processorCreateManifest.getIn(['parameters', 'enclosure'], '"'),
    columns: processorCreateManifest.getIn(['parameters', 'columns'], List()).toJS(),
    columnsFrom: columnsFrom,
    decompress: processorDecompress ? true : false,
    addRowNumberColumn: processorAddRowNumberColumn ? true : false,
    addFilenameColumn: processorAddFilenameColumn ? true : false,
  });
};

const createEmptyConfiguration = (name: string, webalizedName: string) => {
  return createConfiguration(fromJS({ onlyNewFiles: false, name: webalizedName }));
};

const onConform = (configuration: Map<string, any>) => {
  const processorDecompress = configuration
    .getIn(['processors', 'after'], List())
    .findIndex((processor: Map<string, any>) => {
      return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_DECOMPRESS;
    });

  const processorFlattenFolders = configuration
    .getIn(['processors', 'after'], List())
    .findIndex((processor: Map<string, any>) => {
      return processor.getIn(['definition', 'component']) === KEBOOLA_PROCESSOR_FLATTEN_FOLDERS;
    });

  if (processorDecompress > -1 && processorFlattenFolders === -1) {
    configuration = configuration.updateIn(['processors', 'after'], (processors: List<any>) =>
      processors.insert(
        processorDecompress + 1,
        fromJS({
          definition: { component: KEBOOLA_PROCESSOR_FLATTEN_FOLDERS },
        }),
      ),
    );
  }

  return configuration;
};

export default {
  createConfiguration,
  parseConfiguration,
  createEmptyConfiguration,
  onConform,
};
