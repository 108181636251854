import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { List, Map } from 'immutable';

import { Alert } from '@keboola/design';

import callDockerAction from '@/modules/components/DockerActionsApi';
import { authorizeConfiguration, getProfileName } from '@/modules/ex-google-analytics-v4/helpers';
import { prepareProfiles } from '@/modules/google-utils/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import SearchBar from '@/react/common/SearchBar';
import { matchByWords } from '@/utils';
import SyncActionError from '@/utils/errors/SyncActionError';
import ProfilesSelector from './ProfilesSelector';

class ProfilesManagerModal extends React.Component {
  state = {
    query: '',
    error: List(),
    selectedProfiles: List(),
    loadedProfiles: List(),
    isLoading: false,
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHideFn}
        onEnter={() => {
          this.loadProfiles();
          this.setState({ selectedProfiles: List() });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Profiles to Add</Modal.Title>
          <ModalIcon.Plus />
        </Modal.Header>
        <Modal.Body>
          <SearchBar
            className="as-input"
            query={this.state.query}
            onChange={(query) => this.setState({ query })}
          />
          <ProfilesSelector
            profiles={this.getFilteredProfiles()}
            savedProfiles={this.props.profiles}
            selectProfiles={this.selectProfiles}
            selectedProfiles={this.state.selectedProfiles}
            isLoading={this.state.isLoading}
            searchQuery={this.state.query}
          />
          {this.state.messages?.map((message) => (
            <Alert key={message} variant="warning" className="tw-mb-5">
              {message}
            </Alert>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel="Add Selected Profiles"
            onSave={this.handleSave}
            isSaving={this.props.isSaving}
            isDisabled={this.state.selectedProfiles.isEmpty()}
          />
        </Modal.Footer>
      </Modal>
    );
  }

  getFilteredProfiles = () => {
    if (!this.state.query) {
      return this.state.loadedProfiles;
    }

    return this.state.loadedProfiles
      .map((group, groupName) => {
        if (matchByWords(groupName, this.state.query)) {
          return group;
        }

        return group
          .map((profiles, accoutName) => {
            if (matchByWords(accoutName, this.state.query)) {
              return group;
            }

            return profiles.filter((profile) => {
              return matchByWords(getProfileName(profile), this.state.query);
            });
          })
          .filter((profiles) => !profiles.isEmpty());
      })
      .filter((group) => !group.isEmpty());
  };

  handleSave = () => {
    const newProfiles = List()
      .withMutations((profiles) => {
        this.state.loadedProfiles.forEach((group) => {
          group.forEach((account) => profiles.push(...account));
        });
      })
      .filter((profile) => {
        return this.state.selectedProfiles.includes(getProfileName(profile));
      });

    this.props.onSave(this.props.profiles.concat(newProfiles)).then(this.props.onHideFn);
  };

  selectProfiles = (profiles, selected) => {
    const selectedProfiles = selected
      ? this.state.selectedProfiles.concat(profiles).toSet().toList()
      : this.state.selectedProfiles.filter((profile) => !profiles.includes(profile));

    this.setState({ selectedProfiles });
  };

  loadProfiles = () => {
    this.setState({ isLoading: true });
    return callDockerAction(this.props.componentId, 'getProfilesProperties', {
      configData: authorizeConfiguration(Map(), this.props.oauthCredentials).toJS(),
    })
      .then((result) => {
        if (result.status && result.status === 'error') {
          throw new SyncActionError(
            result.message || 'An error occurred while creating the workbook',
            result.exceptionId,
          );
        }

        this.setState({
          loadedProfiles: prepareProfiles(result),
          error: List(result.messages),
        });
      })
      .finally(() => this.setState({ isLoading: false }));
  };
}

ProfilesManagerModal.propTypes = {
  componentId: PropTypes.string.isRequired,
  profiles: PropTypes.instanceOf(List).isRequired,
  oauthCredentials: PropTypes.instanceOf(Map).isRequired,
  show: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  onHideFn: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ProfilesManagerModal;
