import { createCallbackMiddleware, createGenericFetchClient } from '../../fetchClient';
import type { ClientInitOptions } from '../types';

import { createAuthMiddleware } from './authMiddleware';
import { Features } from './features';
import { Projects } from './projects';

export type GenericFetchClient = ReturnType<typeof createGenericFetchClient>;

export class ManagementClient {
  private readonly client: GenericFetchClient;
  features: Features;
  projects: Projects;

  constructor({
    baseUrl,
    callbacks,
    token,
  }: { token?: string } & Omit<ClientInitOptions, 'token'>) {
    const callbackMiddleware = createCallbackMiddleware(callbacks);
    const authMiddleware = createAuthMiddleware({
      baseUrl,
      accessToken: token,
    });

    this.client = createGenericFetchClient({
      baseUrl: `${baseUrl}/manage`,
      middlewares: [
        callbackMiddleware,
        authMiddleware, // 👈  beware order of middlewares always matter
      ],
    });

    this.projects = new Projects(this.client);
    this.features = new Features(this.client);
  }
}
