import React from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { HelpBlock } from '@keboola/design';

import Checkbox from '@/react/common/Checkbox';
import CodeEditor from '@/react/common/CodeEditor';

const Query = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      advancedMode: PropTypes.bool.isRequired,
      query: PropTypes.string.isRequired,
      useLegacySql: PropTypes.bool.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Advanced Mode</h2>
        </div>
        <div className="box-content">
          <FormGroup>
            <div className="col-xs-8 col-xs-offset-4">
              <Checkbox
                checked={this.props.value.advancedMode}
                disabled={this.props.disabled}
                onChange={(checked) => this.props.onChange({ advancedMode: checked })}
              >
                Create your own query using an SQL editor
              </Checkbox>
            </div>
          </FormGroup>
          {this.props.value.advancedMode && (
            <>
              <FormGroup>
                <div className="col-xs-8 col-xs-offset-4">
                  <Checkbox
                    checked={this.props.value.useLegacySql}
                    disabled={this.props.disabled}
                    onChange={(checked) => this.props.onChange({ useLegacySql: checked })}
                  >
                    Use legacy SQL
                  </Checkbox>
                  <HelpBlock className="tw-mt-1">
                    By default, BigQuery runs queries using legacy SQL. <br /> Uncheck this to run
                    queries using BigQuery&apos;s updated SQL dialect with improved standards
                    compliance.
                  </HelpBlock>
                </div>
              </FormGroup>
              <ControlLabel>SQL Query</ControlLabel>
              <CodeEditor
                value={this.props.value.query}
                onChange={(query) => this.props.onChange({ query })}
                options={{
                  mode: 'text/x-mysql',
                  readOnly: this.props.disabled,
                  placeholder: 'e.g,. SELECT `id`, `name` FROM `myTable`',
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  },
});

export default Query;
