export const APAC_EX_GMAIL_ATTACHMENTS = 'apac.ex-gmail-attachments';
export const APIFY_APIFY = 'apify.apify';
export const EX_GENERIC_V_2 = 'ex-generic-v2';
export const GENEEA_NLP_ANALYSIS_V_2 = 'geneea.nlp-analysis-v2';
export const HTNS_EX_SALESFORCE = 'htns.ex-salesforce';
export const KDS_EX_DV360 = 'kds-team.ex-dv360';
export const KDS_EX_AZURE_BLOB_STORAGE_V2 = 'kds-team.ex-azure-blob-storage-v2';
export const KDS_EX_AZURE_DATA_LAKE_GEN2 = 'kds-team.ex-azure-data-lake-gen2';
export const KDS_TEAM_EX_PROGRESS_DB = 'kds-team.ex-progress-db';
export const KDS_TEAM_EX_NETSUITE = 'kds-team.ex-netsuite';
export const KDS_TEAM_EX_YOUTUBE_ANALYTICS = 'kds-team.ex-youtube-analytics';
export const KDS_TEAM_EX_AWS_COST_AND_USAGE_REPORTS = 'kds-team.ex-aws-cost-and-usage-reports';
export const KDS_TEAM_EX_ZOHO = 'kds-team.ex-zoho';
export const KDS_TEAM_PROCESSOR_CSV2XLS = 'kds-team.processor-csv2xls';
export const KDS_TEAM_PROCESSOR_CREATE_FILE_MANIFEST = 'kds-team.processor-create-file-manifest';
export const KDS_TEAM_PROCESSOR_CONVERT_COLUMN_CASE = 'kds-team.processor-convert-column-case';
export const KDS_TEAM_WR_PAIRITY = 'kds-team.wr-pairity';
export const KDS_TEAM_WR_FIREBOLT = 'kds-team.wr-firebolt';
export const KDS_TEAM_WR_EXASOL = 'kds-team.wr-exasol';
export const KDS_TEAM_WR_HIVE_CSAS = 'kds-team.wr-hive-csas';
export const KEBOOLA_PROJECT_BACKUP = 'keboola.project-backup';
export const KEBOOLA_KEBOOLA_AS_CODE = 'keboola.keboola-as-code';
export const KEBOOLA_APP_TRANSFORMATION_MIGRATE = 'keboola.app-transformation-migrate';
export const KEBOOLA_CSV_IMPORT = 'keboola.csv-import';
export const KEBOOLA_EX_AWS_S_3 = 'keboola.ex-aws-s3';
export const KEBOOLA_EX_DB_DB_2 = 'keboola.ex-db-db2';
export const KEBOOLA_EX_DB_DB_2_BATA = 'keboola.ex-db-db2-bata';
export const KEBOOLA_EX_DB_FIREBIRD = 'keboola.ex-db-firebird';
export const KEBOOLA_EX_DB_IMPALA = 'keboola.ex-db-impala';
export const KEBOOLA_EX_DB_INFORMIX = 'keboola.ex-db-informix';
export const KEBOOLA_EX_DB_HIVE = 'keboola.ex-db-hive';
export const KEBOOLA_EX_DB_HIVE_CSAS = 'keboola.ex-db-hive-csas';
export const KEBOOLA_EX_DB_HIVE_CSAS_TEST = 'keboola.ex-db-hive-csas-test';
export const KEBOOLA_EX_DB_MSSQL = 'keboola.ex-db-mssql';
export const KEBOOLA_EX_DB_MSSQL_CDATA = 'keboola.ex-db-mssql-cdata';
export const KEBOOLA_EX_DB_MYSQL = 'keboola.ex-db-mysql';
export const KEBOOLA_EX_DB_ORACLE = 'keboola.ex-db-oracle';
export const KEBOOLA_EX_DB_PGSQL = 'keboola.ex-db-pgsql';
export const KEBOOLA_EX_DB_REDSHIFT = 'keboola.ex-db-redshift';
export const KEBOOLA_EX_DB_SNOWFLAKE = 'keboola.ex-db-snowflake';
export const KEBOOLA_EX_DB_NETSUITE = 'keboola.ex-db-netsuite';
export const KEBOOLA_EX_EMAIL_ATTACHMENTS = 'keboola.ex-email-attachments';
export const KEBOOLA_EX_FACEBOOK = 'keboola.ex-facebook';
export const KEBOOLA_EX_FACEBOOK_ADS = 'keboola.ex-facebook-ads';
export const KEBOOLA_EX_FTP = 'keboola.ex-ftp';
export const KEBOOLA_EX_FTP_CSAS = 'keboola.ex-ftp-csas';
export const KEBOOLA_EX_GMAIL = 'keboola.ex-gmail';
export const KEBOOLA_EX_GOOGLE_ADS = 'keboola.ex-google-ads';
export const KEBOOLA_EX_GOOGLE_ANALYTICS_V_4 = 'keboola.ex-google-analytics-v4';
export const KEBOOLA_EX_GOOGLE_BIGQUERY = 'keboola.ex-google-bigquery';
export const KEBOOLA_EX_GOOGLE_BIGQUERY_V_2 = 'keboola.ex-google-bigquery-v2';
export const KEBOOLA_EX_GOOGLE_BIGQUERY_V_2_AVRO_FILES = 'keboola.ex-google-bigquery-v2-avro-files';
export const KEBOOLA_EX_GOOGLE_DRIVE = 'keboola.ex-google-drive';
export const KEBOOLA_EX_HTTP = 'keboola.ex-http';
export const KEBOOLA_EX_INSTAGRAM = 'keboola.ex-instagram';
export const KEBOOLA_EX_MONGODB = 'keboola.ex-mongodb';
export const KEBOOLA_EX_ONEDRIVE = 'keboola.ex-onedrive';
export const KEBOOLA_EX_SAMPLE_DATA = 'keboola.ex-sample-data';
export const KEBOOLA_EX_TERADATA = 'keboola.ex-teradata';
export const KEBOOLA_GOODDATA_WRITER = 'keboola.gooddata-writer';
export const KEBOOLA_ORACLE_TRANSFORMATION = 'keboola.oracle-transformation';
export const KEBOOLA_ORCHESTRATOR = 'keboola.orchestrator';
export const KEBOOLA_PROCESSOR_SPLIT_TABLE = 'keboola.processor-split-table';
export const KEBOOLA_PROCESSOR_ADD_FILENAME_COLUMN = 'keboola.processor-add-filename-column';
export const KEBOOLA_PROCESSOR_ADD_ROW_NUMBER_COLUMN = 'keboola.processor-add-row-number-column';
export const KEBOOLA_PROCESSOR_CREATE_MANIFEST = 'keboola.processor-create-manifest';
export const KEBOOLA_PROCESSOR_DECOMPRESS = 'keboola.processor-decompress';
export const KEBOOLA_PROCESSOR_FLATTEN_FOLDERS = 'keboola.processor-flatten-folders';
export const KEBOOLA_PROCESSOR_MOVE_FILES = 'keboola.processor-move-files';
export const KEBOOLA_PROCESSOR_SKIP_LINES = 'keboola.processor-skip-lines';
export const KEBOOLA_SANDBOXES = 'keboola.sandboxes';
export const KEBOOLA_DATA_APPS = 'keboola.data-apps';
export const KEBOOLA_SCHEDULER = 'keboola.scheduler';
export const KEBOOLA_MLFLOW = 'keboola.mlflow';
export const KEBOOLA_APP_MERGE_BRANCH_STORAGE = 'keboola.app-merge-branch-storage';
export const KEBOOLA_SAPI_MERGED_EXPORT = 'keboola.sapi-merged-export';
export const KEBOOLA_SHARED_CODE = 'keboola.shared-code';
export const KEBOOLA_SLICED_FILES_DOWNLOADER = 'keboola.sliced-files-downloader';
export const KEBOOLA_LEGACY_TRANSFORMATION = 'keboola.legacy-transformation';
export const KEBOOLA_SNOWFLAKE_TRANSFORMATION = 'keboola.snowflake-transformation';
export const KEBOOLA_SYNAPSE_TRANSFORMATION = 'keboola.synapse-transformation';
export const KEBOOLA_REDSHIFT_TRANSFORMATION = 'keboola.redshift-transformation';
export const KEBOOLA_PYTHON_TRANSFORMATION_V_2 = 'keboola.python-transformation-v2';
export const KEBOOLA_PYTHON_MLFLOW_TRANSFORMATION = 'keboola.python-mlflow-transformation';
export const KEBOOLA_PYTHON_SNOWPARK_TRANSFORMATION = 'keboola.python-snowpark-transformation';
export const KEBOOLA_CSAS_PYTHON_TRANSFORMATION_V_2 = 'keboola.csas-python-transformation-v2';
export const KEBOOLA_JULIA_TRANSFORMATION = 'keboola.julia-transformation';
export const KEBOOLA_R_TRANSFORMATION_V_2 = 'keboola.r-transformation-v2';
export const KEBOOLA_EXASOL_TRANSFORMATION = 'keboola.exasol-transformation';
export const KEBOOLA_TERADATA_TRANSFORMATION = 'keboola.teradata-transformation';
export const KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION = 'keboola.google-bigquery-transformation';
export const KEBOOLA_DBT_TRANSFORMATION = 'keboola.dbt-transformation';
export const KEBOOLA_DBT_TRANSFORMATION_LOCAL_BIGQUERY =
  'keboola.dbt-transformation-local-bigquery';
export const KEBOOLA_TEST_DBT_TRANSFORMATION = 'keboola-test.dbt-transformation';
export const KEBOOLA_DBT_TRANSFORMATION_REMOTE_BIGQUERY =
  'keboola.dbt-transformation-remote-bigquery';
export const KEBOOLA_DBT_TRANSFORMATION_REMOTE_MSSQL = 'keboola.dbt-transformation-remote-mssql';
export const KEBOOLA_DBT_TRANSFORMATION_REMOTE_POSTGRESQL =
  'keboola.dbt-transformation-remote-postgresql';
export const KEBOOLA_DBT_TRANSFORMATION_REMOTE_REDSHIFT =
  'keboola.dbt-transformation-remote-redshift';
export const KEBOOLA_DBT_TRANSFORMATION_REMOTE_SNOWFLAKE =
  'keboola.dbt-transformation-remote-snowflake';
export const KEBOOLA_NO_CODE_DBT_TRANSFORMATION = 'keboola.no-code-dbt-transformation';
export const KEBOOLA_STORAGE = 'keboola.storage'; // component to get icon and name
export const KEBOOLA_VARIABLES = 'keboola.variables';
export const KEBOOLA_WR_AWS_S_3 = 'keboola.wr-aws-s3';
export const KEBOOLA_WR_AZURE_EVENT_HUB = 'keboola.wr-azure-event-hub';
export const KEBOOLA_WR_DB_IMPALA = 'keboola.wr-db-impala';
export const KEBOOLA_WR_DB_HIVE = 'keboola.wr-db-hive';
export const KEBOOLA_WR_DB_MSSQL_V_2 = 'keboola.wr-db-mssql-v2';
export const KEBOOLA_WR_DB_MYSQL = 'keboola.wr-db-mysql';
export const KEBOOLA_WR_DB_ORACLE = 'keboola.wr-db-oracle';
export const KEBOOLA_WR_DB_PGSQL = 'keboola.wr-db-pgsql';
export const KEBOOLA_WR_DB_SNOWFLAKE = 'keboola.wr-db-snowflake';
export const KEBOOLA_WR_DB_SNOWFLAKE_GCS = 'keboola.wr-db-snowflake-gcs';
export const KEBOOLA_WR_DB_SNOWFLAKE_GCS_S3 = 'keboola.wr-db-snowflake-gcs-s3';
export const KEBOOLA_WR_DB_SYNAPSE = 'keboola.wr-synapse';
export const KEBOOLA_WR_SNOWFLAKE_BLOB_STORAGE = 'keboola.wr-snowflake-blob-storage';
export const KEBOOLA_WR_GOOGLE_BIGQUERY_V_2 = 'keboola.wr-google-bigquery-v2';
export const KEBOOLA_WR_GOOGLE_DRIVE = 'keboola.wr-google-drive';
export const KEBOOLA_WR_GOOGLE_SHEETS = 'keboola.wr-google-sheets';
export const KEBOOLA_WR_LOOKER_V2 = 'keboola.wr-looker-v2';
export const KEBOOLA_WR_ONEDRIVE = 'keboola.wr-onedrive';
export const KEBOOLA_WR_REDSHIFT_V_2 = 'keboola.wr-redshift-v2';
export const KEBOOLA_WR_SISENSE = 'keboola.wr-sisense';
export const KEBOOLA_WR_THOUGHTSPOT = 'keboola.wr-thoughtspot';
export const ORCHESTRATOR = 'orchestrator'; // fake
export const PROVISIONING = 'provisioning'; // fake
export const RADEKTOMASEK_EX_DROPBOX_V_2 = 'radektomasek.ex-dropbox-v2';
export const TDE_EXPORTER = 'tde-exporter';
export const TRANSFORMATION = 'transformation'; // fake
export const SMETRIC_EX_SIMPRO_API = 'smetric.ex-simpro-api';
export const SMETRIC_EX_VEND_API = 'smetric.ex-vend-api';
