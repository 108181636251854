import React from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import type { ExtendedRefs } from '@floating-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Promise from 'bluebird';
import { List, Map } from 'immutable';
import Switch from 'rc-switch';

import { Button, ButtonInline, cn, Tooltip } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { DISABLE_AUTOSAVING_IN_FLOWS } from '@/constants/features';
import keyCodes from '@/constants/keyCodes';
import {
  ensureConfigurationsDetails,
  getComponentTypeColorClassName,
  isComponentDeprecated,
  resolveRouterLinkParams,
} from '@/modules/components/helpers';
import { createConfiguration } from '@/modules/components-directory/actions';
import { disabledInBranch } from '@/modules/flows/constants';
import { getBackendSize, resolveComponentId } from '@/modules/flows/helpers';
import { JOB_FINISHED_STATUSES, JOBS_STATUS, routeNames } from '@/modules/queue/constants';
import { routeNames as trashRouteNames } from '@/modules/trash/constants';
import { RouterLink, Truncated } from '@/react/common';
import BackendSizeButtons from '@/react/common/BackendSizeButtons';
import ComponentBadges from '@/react/common/ComponentBadges';
import FastFade from '@/react/common/FastFade';
import Select from '@/react/common/Select';
import { folderLabel } from '@/react/common/selectLabels';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';
import NewConfigurationModal from './NewConfigurationModal';
import TaskIcon from './TaskIcon';

type Props = {
  configId: string;
  show?: boolean;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  task: Map<string, any>;
  lastJob?: Map<string, any>;
  onEditTask: (id: string, field: string | string[], value: any) => void;
  onSelectConfig: (
    taskId: string,
    componentId: string,
    configId: string,
    options?: { autosave: boolean },
  ) => Promise<any>;
  patternComponents: Map<string, any>;
  allConfigurations: Map<string, any>;
  allComponents: Map<string, any>;
  folders: Map<string, any>;
  showBackendSize: boolean;
  isDevModeActive: boolean;
  hasSnowflakeDynamicBackendSize: boolean;
  hasJobsDynamicBackendSize: boolean;
  onHide: () => void;
  onSetTaskParams: (taskId: string) => void;
  refs: ExtendedRefs<unknown>;
  floatingStyles: React.CSSProperties;
};

type OptionProps = {
  value: string;
  name: string;
  label: React.ReactNode;
  hasFolder: boolean;
};

const TaskDetailModal = ({
  configId,
  show,
  task,
  lastJob,
  readOnly,
  hasPayAsYouGo,
  onEditTask,
  onSelectConfig,
  allConfigurations,
  allComponents,
  folders,
  showBackendSize,
  hasSnowflakeDynamicBackendSize,
  patternComponents,
  hasJobsDynamicBackendSize,
  isDevModeActive,
  onHide,
  onSetTaskParams,
  refs,
  floatingStyles,
}: Props) => {
  const [configurationInputValue, setConfigurationInputValue] = React.useState('');
  const [showNewConfigurationModal, setShowNewConfigurationModal] = React.useState(false);
  const [showSpecifigRows, setShowSpecifigRows] = React.useState(false);

  const hasSelectedConfig = !!task.get('configId') && !task.get('hasDeletedConfiguration');
  const backendSize =
    showBackendSize &&
    getBackendSize(
      allConfigurations.get(task.get('componentId', ''), Map()),
      task,
      hasSnowflakeDynamicBackendSize,
      hasJobsDynamicBackendSize,
    );

  React.useEffect(() => {
    const hide = (e: KeyboardEvent) => e.key === keyCodes.ESCAPE && onHide();
    window.addEventListener('keyup', hide);
    return () => window.removeEventListener('keyup', hide);
  }, [show, onHide]);

  const selectConfigWithRedirect = (
    event?: React.MouseEvent | null,
    componentId?: string,
    config?: string,
  ) => {
    const configurationId = config || task.get('configId');
    const realComponentId =
      componentId || resolveComponentId(allConfigurations, task, configurationId);
    const autosave =
      !shouldUseNewWindow(event) &&
      !ApplicationStore.hasCurrentAdminFeature(DISABLE_AUTOSAVING_IN_FLOWS);

    return onSelectConfig(task.get('id'), realComponentId, configurationId, { autosave }).tap(
      () => {
        const params = resolveRouterLinkParams(realComponentId, configurationId, null, true);

        if (params) {
          if (shouldUseNewWindow(event)) {
            return windowOpen(RoutesStore.getRouter().createHref(params.to, params.params));
          }

          RoutesStore.getRouter().transitionTo(
            params.to,
            params.params,
            null,
            null,
            realComponentId !== KEBOOLA_ORCHESTRATOR
              ? { flowId: configId, scrollY: window.scrollY }
              : {},
          );
        }
      },
    );
  };

  const options = React.useMemo(() => {
    return allConfigurations
      .getIn([task.get('componentId'), 'configurations'], Map())
      .sortBy((config: Map<string, any>) => config.get('name').toLowerCase())
      .map((config: Map<string, any>, configId: string) => {
        const folder = folders.getIn([task.get('componentId'), configId]);

        return {
          value: configId,
          label: folderLabel(config.get('name', configId), folder, configurationInputValue),
          name: folder ? `${folder} ${config.get('name')}` : config.get('name'),
          hasFolder: !!folder,
        };
      })
      .sort((rowA: OptionProps, rowB: OptionProps) => {
        if (rowA.hasFolder && !rowB.hasFolder) return -1;
        if (!rowA.hasFolder && rowB.hasFolder) return 1;

        return rowA.name.localeCompare(rowB.name);
      })
      .toArray();
  }, [allConfigurations, folders, task, configurationInputValue]);

  const renderSelectConfigurationAndRows = () => {
    const componentId = task.get('componentId');
    const component = allComponents.get(componentId, Map());
    const isDeprecated = isComponentDeprecated(component);
    const isDisabledInBranch = isDevModeActive && disabledInBranch.includes(componentId);
    const canSelectRows = !showSpecifigRows && !task.get('availableRows').isEmpty();

    return (
      <FormGroup className={cn({ 'tw-mb-2': canSelectRows })}>
        <ControlLabel htmlFor="select-configuration-as-task" className="flex-container font-medium">
          Configuration
          {!readOnly && (
            <Tooltip
              placement="top"
              type="explanatory"
              forceHide={!isDeprecated && !isDisabledInBranch}
              tooltip={
                isDeprecated
                  ? 'This component has been deprecated. New configuration cannot be created.'
                  : 'This component is disabled in branches. New configuration cannot be created.'
              }
            >
              <ButtonInline
                variant="custom"
                disabled={isDeprecated || isDisabledInBranch}
                className="text-muted f-12"
                onClick={() => {
                  if (isDeprecated || isDisabledInBranch) {
                    return;
                  }

                  setShowNewConfigurationModal(true);
                }}
              >
                <FontAwesomeIcon icon="plus" className="text-success" />
                Create Configuration
              </ButtonInline>
            </Tooltip>
          )}
        </ControlLabel>
        <Select
          clearable={false}
          disabled={readOnly}
          id="select-configuration-as-task"
          placeholder="Select Configuration"
          value={task.get('hasDeletedConfiguration') ? task.get('name') : task.get('configId', '')}
          options={options}
          onChange={(configId: string) => {
            onSelectConfig(
              task.get('id'),
              resolveComponentId(allConfigurations, task, configId),
              configId,
            );
            setShowSpecifigRows(false);
          }}
          inputValue={configurationInputValue}
          {...(!showNewConfigurationModal && { onInputChange: setConfigurationInputValue })}
          noOptionRenderer={() => {
            return (
              <div className="no-option">
                <div className="text-center text-muted">No configurations found</div>
                {!!configurationInputValue && !isDeprecated && !isDisabledInBranch && (
                  <ButtonInline
                    variant="custom"
                    className="color-main underline font-medium break-all tw-w-full tw-justify-center"
                    onClick={() => {
                      if (component.get('type') === componentTypes.TRANSFORMATION) {
                        return setShowNewConfigurationModal(true);
                      }

                      createConfiguration(componentId, configurationInputValue).tap((response) => {
                        return selectConfigWithRedirect(null, componentId, response.id);
                      });
                    }}
                  >
                    <FontAwesomeIcon icon="plus" className="color-success" />
                    Create &quot;{configurationInputValue}&quot; Configuration
                  </ButtonInline>
                )}
              </div>
            );
          }}
        />
        {hasSelectedConfig && (
          <Tooltip
            placement="top"
            type="explanatory"
            forceHide={!isDisabledInBranch}
            tooltip="This component is disabled in branches."
          >
            <Button
              variant="secondary"
              className={cn('tw-w-full tw-mt-2', { disabled: isDisabledInBranch })}
              onClick={(event: React.MouseEvent) => {
                if (!isDisabledInBranch) {
                  selectConfigWithRedirect(event);
                }
              }}
              onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
              onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
            >
              {readOnly ? 'Show' : 'Edit'} Configuration
            </Button>
          </Tooltip>
        )}
        {task.get('hasConfigurationInTrash') && (
          <RouterLink
            to={trashRouteNames.SETTINGS_TRASH}
            className="btn btn-primary btn-block"
            query={{ q: task.get('configId') }}
          >
            View Deleted Configuration
          </RouterLink>
        )}
        {showSpecifigRows && (
          <>
            <ControlLabel
              htmlFor="select-rows-for-task"
              className="flex-container font-medium tw-mt-2"
            >
              Specific Rows to Run
            </ControlLabel>
            <Select
              multi
              hideSelectAllOptions
              disabled={readOnly}
              defaultOpenWithFocus={task.get('specificRows').isEmpty()}
              id="select-rows-for-task"
              placeholder="Select Specific Rows"
              value={task.get('specificRows')}
              options={task.get('availableRows')}
              onChange={(rows: List<string>) => {
                onEditTask(task.get('id'), ['task', 'configRowIds'], rows);
              }}
              onBlur={() => {
                if (task.get('specificRows').isEmpty()) {
                  setShowSpecifigRows(false);
                }
              }}
            />
          </>
        )}
        {canSelectRows && (
          <FormGroup className="flex-container justify-center mb-0">
            <ButtonInline className="tw-mt-2" onClick={() => setShowSpecifigRows(true)}>
              Select Specific Rows
            </ButtonInline>
          </FormGroup>
        )}
      </FormGroup>
    );
  };

  if (!show) {
    return null;
  }
  const flags = allComponents.getIn([task.get('componentId'), 'flags'], List());

  return (
    <FastFade
      in
      appear
      onEnter={() => {
        setShowSpecifigRows(!task.get('specificRows').isEmpty());
        ensureConfigurationsDetails(task.get('componentId'));
      }}
    >
      <div>
        <div className="task-detail" ref={refs.setFloating} style={floatingStyles}>
          <div className="task-detail-header">
            <button className="task-detail-close" onClick={onHide}>
              ×
            </button>
            <div className="flex-container flex-start">
              <TaskIcon src={task.get('iconUrl')} size={32} />
              <div className="font-medium">
                <div className="tw-flex">
                  <Truncated className="line-height-20" text={task.get('component')} />
                  <ComponentBadges flags={flags} className="tw-ml-2" />
                </div>

                {!!task.get('type') && (
                  <div className={cn('f-12', getComponentTypeColorClassName(task.get('type')))}>
                    {task.get('type')}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="task-detail-body">
            {!task.get('invalid') && (
              <>
                {options.length > 0 || task.get('hasDeletedConfiguration') ? (
                  renderSelectConfigurationAndRows()
                ) : (
                  <FormGroup>
                    <Button
                      className="tw-w-full"
                      disabled={readOnly}
                      onClick={() => setShowNewConfigurationModal(true)}
                    >
                      <FontAwesomeIcon icon="plus" />
                      Create New Configuration
                    </Button>
                  </FormGroup>
                )}
                <hr />
                <FormGroup className="flex-container">
                  <ControlLabel
                    className="text-muted"
                    onClick={() =>
                      !readOnly && onEditTask(task.get('id'), 'enabled', !task.get('enabled', true))
                    }
                  >
                    Component Enabled
                  </ControlLabel>
                  <Switch
                    prefixCls="switch"
                    className="wider no-margin"
                    disabled={readOnly}
                    checked={task.get('enabled', true)}
                    onChange={(checked) => onEditTask(task.get('id'), 'enabled', checked)}
                  />
                </FormGroup>
                <FormGroup className="flex-container">
                  <ControlLabel
                    className="text-muted"
                    onClick={() =>
                      !readOnly &&
                      onEditTask(
                        task.get('id'),
                        'continueOnFailure',
                        !task.get('continueOnFailure', false),
                      )
                    }
                  >
                    Continue on Failure
                  </ControlLabel>
                  <Switch
                    prefixCls="switch"
                    className="wider no-margin"
                    disabled={readOnly}
                    checked={task.get('continueOnFailure', false)}
                    onChange={(checked) => onEditTask(task.get('id'), 'continueOnFailure', checked)}
                  />
                </FormGroup>
              </>
            )}
            {backendSize && hasSelectedConfig && (
              <FormGroup className="flex-container">
                <ControlLabel className="text-muted">
                  Task Backend Size
                  <Tooltip
                    placement="top"
                    type="static-explanatory"
                    className="wider"
                    tooltip="When dealing with large amounts of complex data, try increasing the backend of your pipeline to accelerate your transformation process."
                  >
                    <FontAwesomeIcon icon="circle-info" className="f-16" />
                  </Tooltip>
                </ControlLabel>
                <BackendSizeButtons
                  disabled={readOnly}
                  currentSize={backendSize}
                  componentId={task.get('componentId')}
                  onSelect={(value) => {
                    onEditTask(task.get('id'), ['task', 'backend', 'type'], value);
                    return Promise.resolve();
                  }}
                />
              </FormGroup>
            )}
            {!task.get('invalid') &&
              task.get('enabled', true) &&
              lastJob &&
              JOB_FINISHED_STATUSES.includes(lastJob.get('status')) && (
                <FormGroup className="flex-container">
                  <ControlLabel className="text-muted">Last Run</ControlLabel>
                  <ButtonInline
                    onClick={() => {
                      const href = RoutesStore.getRouter().createHref(routeNames.JOB_DETAIL, {
                        jobId: lastJob.get('jobId'),
                      });
                      windowOpen(href);
                    }}
                    className={cn({
                      'color-success': lastJob.get('status') === JOBS_STATUS.SUCCESS,
                      'color-danger': lastJob.get('status') === JOBS_STATUS.ERROR,
                      'color-muted': ![JOBS_STATUS.ERROR, JOBS_STATUS.SUCCESS].includes(
                        lastJob.get('status'),
                      ),
                    })}
                  >
                    {lastJob.get('status') === JOBS_STATUS.ERROR
                      ? 'Debug Error'
                      : string.capitalize(lastJob.get('status'))}
                  </ButtonInline>
                </FormGroup>
              )}
            {(!!task.get('configId') || task.get('invalid')) && (
              <FormGroup className="flex-container justify-center mb-0">
                <ButtonInline className="tw-mb-2" onClick={() => onSetTaskParams(task.get('id'))}>
                  {readOnly ? 'Show' : 'Set'} advanced parameters
                </ButtonInline>
              </FormGroup>
            )}
          </div>
        </div>
        <NewConfigurationModal
          show={showNewConfigurationModal}
          forceTransformationName={configurationInputValue}
          onHide={() => setShowNewConfigurationModal(false)}
          onCreate={(componentId: string, configId: string) => {
            return Promise.resolve().then(() => {
              setConfigurationInputValue('');
              selectConfigWithRedirect(null, componentId, configId);
            });
          }}
          task={task}
          hasConfigurations={
            !allConfigurations.getIn([task.get('componentId'), 'configurations'], Map()).isEmpty()
          }
          patternComponents={patternComponents}
          allComponents={allComponents}
          readOnly={readOnly}
          hasPayAsYouGo={hasPayAsYouGo}
          folders={folders}
        />
      </div>
    </FastFade>
  );
};

const TaskDetailModalMemoized = React.memo(TaskDetailModal);

export default TaskDetailModalMemoized;
