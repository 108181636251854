import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import type Promise from 'bluebird';

import { cn, Tooltip } from '@keboola/design';

import { getTransformationBackendSizes } from '@/modules/components/helpers';
import string from '@/utils/string';
import Loader from './Loader';

type Props = {
  componentId: string;
  onSelect: (value: string) => Promise<void>;
  currentSize?: string;
  className?: string;
  iconSize?: FontAwesomeIconProps['size'];
  disabled?: boolean;
  showNonSqlOptionDisabled?: boolean;
};

const BackendSizeButtons = ({
  componentId,
  onSelect,
  currentSize,
  className,
  iconSize = 'lg',
  disabled,
  showNonSqlOptionDisabled,
}: Props) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const sizes = getTransformationBackendSizes(componentId);
  const selectedIndex = currentSize ? sizes.indexOf(currentSize) : -1;

  return (
    <div className="backend-size-buttons">
      {isSaving && <Loader className="mr-1" />}
      {sizes.map((value: string, index: number) => {
        const isSelected = value === currentSize;
        const disabledForSql = componentId === 'common' && showNonSqlOptionDisabled && index === 0;

        return (
          <Button
            key={value}
            className={cn({ active: index === selectedIndex })}
            bsStyle="link"
            onClick={() => {
              if (disabled || isSaving || isSelected) return;
              setIsSaving(true);
              onSelect(value).then(() => setIsSaving(false));
            }}
            disabled={disabled || disabledForSql}
          >
            <Tooltip
              tooltip={
                disabledForSql
                  ? 'Xsmall backend size is available only for non-SQL transformations.'
                  : string.capitalize(value)
              }
              placement="top"
              type="explanatory"
            >
              <FontAwesomeIcon icon="rocket" className={className} size={iconSize} fixedWidth />
            </Tooltip>
          </Button>
        );
      })}
    </div>
  );
};

export default BackendSizeButtons;
