import React from 'react';
import PropTypes from 'prop-types';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { FormGroup, HelpBlock, Label, Link, TextInput } from '@keboola/design';

const FORM_GROUP_CLASS_NAME = 'tw-grid tw-grid-cols-3 tw-items-start tw-gap-4';

const DataSource = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      query: PropTypes.string.isRequired,
      since: PropTypes.string.isRequired,
      until: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Data Source</h2>
        </div>
        <div className="box-content tw-flex tw-flex-col tw-gap-4">
          <FormGroup className={FORM_GROUP_CLASS_NAME}>
            <Label htmlFor="query">Query</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <TextInput
                id="query"
                variant="secondary"
                value={this.props.value.query}
                onChange={(value) => {
                  this.props.onChange({ query: value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>
                GAQL query for downloading a report. You should pick columns to download from
                allowed report values and FROM clause from allowed report types.
              </HelpBlock>
            </div>
          </FormGroup>

          <FormGroup className={FORM_GROUP_CLASS_NAME}>
            <Label htmlFor="start-date">Start date of downloaded stats (optional)</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <TextInput
                id="start-date"
                variant="secondary"
                value={this.props.value.since}
                onChange={(value) => {
                  this.props.onChange({ since: value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>
                Use <code>strtotime</code> format (
                <Link href="https://php.net/manual/en/function.strtotime.php">
                  https://php.net/manual/en/function.strtotime.php
                </Link>
                )
              </HelpBlock>
            </div>
          </FormGroup>

          <FormGroup className={FORM_GROUP_CLASS_NAME}>
            <Label htmlFor="end-date">End date of downloaded stats (optional)</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <TextInput
                id="end-date"
                variant="secondary"
                value={this.props.value.until}
                onChange={(value) => {
                  this.props.onChange({ until: value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>
                Use <code>strtotime</code> format (
                <Link href="https://php.net/manual/en/function.strtotime.php">
                  https://php.net/manual/en/function.strtotime.php
                </Link>
                )
              </HelpBlock>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  },
});

export default DataSource;
