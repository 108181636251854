import React, { useState } from 'react';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import actionsProvisioning from '@/modules/ex-facebook/actionsProvisioning';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import RoutesStore from '@/stores/RoutesStore';

type Props = {
  component: Map<string, any>;
  configId: string;
  show: boolean;
  readOnly: boolean;
  createQuery: () => void;
  saveQuery: () => void;
  onHide: () => void;
};

const AddQueryModal = ({ component, configId, show, readOnly, onHide }: Props) => {
  const [name, setName] = useState('');
  const [isCreating, setIsCreating] = useState(false);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{component.get('name')}</Modal.Title>
        <ModalIcon.Plus />
      </Modal.Header>

      <form
        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
          e.preventDefault();
          setIsCreating(true);

          const componentId = component.get('id');
          const actions = actionsProvisioning(componentId, configId);
          const query = actions.touchQuery().set('name', name);
          const queryId = query.get('id');

          actions
            .saveQuery(query)
            .then(() => {
              onHide();

              return RoutesStore.getRouter().transitionTo(componentId + '-query-detail', {
                config: configId,
                queryId: queryId,
              });
            })
            .finally(() => {
              setIsCreating(false);
            });
        }}
      >
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
              placeholder="Name your configuration"
              disabled={isCreating || readOnly}
            />
          </FormGroup>
          {/* <FormGroup>
            <ControlLabel>
              Description <OptionalFormLabel />
            </ControlLabel>
            <FormControl
              componentClass="textarea"
              value={description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
              placeholder="Describe your configuration"
              disabled={isCreating || readOnly}
            />
          </FormGroup> */}
        </Modal.Body>

        <Modal.Footer>
          <ConfirmButtons
            block
            saveButtonType="submit"
            isSaving={isCreating}
            isDisabled={isCreating || !name.trim().length}
            saveLabel={isCreating ? 'Creating Query...' : 'Create Query'}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddQueryModal;
