import React from 'react';

import AuditBox from './SourcePath/AuditBox';
import DownloadSettings from './SourcePath/DownloadSettings';
import HeaderPrimaryKey from './SourcePath/HeaderPrimaryKey';
import SaveSettings from './SourcePath/SaveSettings';

export type PathValueProps = {
  path: string;
  onlyNewFiles: boolean;
  decompress: boolean;
  incremental: boolean;
  delimiter: string;
  name: string;
  enclosure: string;
  columnsFrom: 'manual' | 'header' | 'auto';
  columns: string[];
  primaryKey: string[];
  addRowNumberColumn: boolean;
  addFilenameColumn: boolean;
};

type Props = {
  value: PathValueProps;
  onChange: (data: Partial<PathValueProps>) => void;
  disabled: boolean;
};

class SourcePath extends React.Component<Props> {
  render() {
    return (
      <>
        <DownloadSettings
          path={this.props.value.path}
          decompress={this.props.value.decompress}
          onChange={this.props.onChange}
          onlyNewFiles={this.props.value.onlyNewFiles}
          disabled={this.props.disabled}
        />
        <SaveSettings
          name={this.props.value.name}
          enclosure={this.props.value.enclosure}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          delimiter={this.props.value.delimiter}
          incremental={this.props.value.incremental}
        />
        <HeaderPrimaryKey
          columnsFrom={this.props.value.columnsFrom}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          columns={this.props.value.columns}
          primaryKey={this.props.value.primaryKey}
        />
        <AuditBox
          addFilenameColumn={this.props.value.addFilenameColumn}
          addRowNumberColumn={this.props.value.addRowNumberColumn}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
        />
      </>
    );
  }
}

export default SourcePath;
