import React from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { Promise } from 'bluebird';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { Link, Tooltip } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import StorageApi from '@/modules/components/StorageApi';
import { createTrigger, loadTriggers } from '@/modules/event-trigger/actions';
import { DEFAULT_COOLDOWN_PERIOD_MINUTES } from '@/modules/event-trigger/constants';
import actions from '@/modules/queue/actions';
import { routeNames as queueRouteNames } from '@/modules/queue/constants';
import type { StoreSource } from '@/modules/stream/store';
import ConfirmModal from '@/react/common/ConfirmModal';
import JobStatusIcon from '@/react/common/JobStatusIcon';
import JobStatusLabel from '@/react/common/JobStatusLabel';
import RoutesStore from '@/stores/RoutesStore';

const AutomateStreamModal = (props: {
  show: boolean;
  onHide: () => void;
  source: StoreSource;
  triggers: Map<string, any>;
  flows: Map<string, any>;
  latestJobs: List<any>;
}) => {
  const [flowName, setFlowName] = React.useState('');
  const [isProcessing, setIsProcessing] = React.useState(false);

  const tableId = props.source.sinks?.[0]?.table?.tableId;
  const connectedFlows = props.triggers
    .flatten(1)
    .filter((trigger) => {
      return trigger.get('tables', List()).some((table: Map<string, any>) => {
        return table.get('tableId') === tableId;
      });
    })
    .map((trigger) => props.flows.get(trigger.get('configurationId')))
    .filter(Boolean);

  return (
    <ConfirmModal
      closeAfterResolve
      icon="clock"
      buttonType="success"
      buttonLabel="automate"
      title={`Automate Stream ${props.source.name}`}
      show={props.show}
      isDisabled={!flowName}
      isLoading={isProcessing}
      loadOnEnter={() => {
        return Promise.all([
          loadTriggers(),
          InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_ORCHESTRATOR),
          actions.loadLatestJobsForConfigurations(
            [KEBOOLA_ORCHESTRATOR],
            props.flows.keySeq().toArray(),
          ),
        ]);
      }}
      onConfirm={() => {
        setIsProcessing(true);
        return InstalledComponentsActionCreators.createConfiguration(KEBOOLA_ORCHESTRATOR, {
          name: flowName,
        })
          .then((response) => {
            return StorageApi.createTriggerToken(response.id)
              .then((token) => {
                return createTrigger({
                  component: KEBOOLA_ORCHESTRATOR,
                  configurationId: response.id,
                  runWithTokenId: token.id,
                  tableIds: [tableId],
                  coolDownPeriodMinutes: DEFAULT_COOLDOWN_PERIOD_MINUTES,
                });
              })
              .then(() => {
                ApplicationActionCreators.sendNotification({
                  message: () => {
                    return (
                      <>
                        Table has been set as a trigger in{' '}
                        <ComponentConfigurationLink
                          hasFlows
                          configId={response.id}
                          componentId={KEBOOLA_ORCHESTRATOR}
                        >
                          {response.name}
                        </ComponentConfigurationLink>{' '}
                        flow.
                      </>
                    );
                  },
                });
              });
          })
          .finally(() => setIsProcessing(false));
      }}
      onHide={props.onHide}
      text={
        <>
          <p>
            You are about to set table{' '}
            <StorageApiTableLinkEx tableId={tableId} showOnlyDisplayName /> as a trigger in new
            flow.
          </p>
          {!connectedFlows.isEmpty() && (
            <>
              <p className="line-height-24 mt-1">
                This stream table is already being triggered in these Flows:
              </p>
              <div className="table table-hover in-modal condensed">
                <div className="thead">
                  <div className="tr">
                    <div className="th">Name</div>
                    <div className="th">Last Use</div>
                  </div>
                </div>
                <div className="tbody">
                  {connectedFlows
                    .map((flow) => {
                      const lastJob = props.latestJobs.getIn([flow.get('id'), 0]);

                      return (
                        <ComponentConfigurationLink
                          hasFlows
                          className="tr"
                          key={flow.get('id')}
                          configId={flow.get('id')}
                          componentId={KEBOOLA_ORCHESTRATOR}
                        >
                          <div className="td color-main">{flow.get('name')}</div>
                          <div className="td">
                            {lastJob ? (
                              <Tooltip placement="top" tooltip="Open Job Detail">
                                <Link
                                  className="no-wrap no-underline"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    RoutesStore.getRouter().transitionTo(
                                      queueRouteNames.JOB_DETAIL,
                                      { jobId: lastJob.get('id') },
                                    );
                                  }}
                                >
                                  <span className="underline color-primary">Job</span>{' '}
                                  <JobStatusLabel status={lastJob.get('status')} />
                                  <JobStatusIcon
                                    className="icon-addon-left"
                                    status={lastJob.get('status')}
                                  />
                                </Link>
                              </Tooltip>
                            ) : (
                              <span className="text-muted">No run yet</span>
                            )}
                          </div>
                        </ComponentConfigurationLink>
                      );
                    })
                    .toArray()}
                </div>
              </div>
            </>
          )}
          <FormGroup>
            <ControlLabel>Flow name</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              placeholder="Name your flow"
              value={flowName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFlowName(e.target.value)}
            />
          </FormGroup>
        </>
      }
    />
  );
};

export default AutomateStreamModal;
