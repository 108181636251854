import React, { type ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import { FormGroup, Label, type ValidityState } from '@keboola/design';

import type { PARAM_VALUE, VALUE_TYPE } from '@/modules/ex-generic/constants';
import { NEW_FUNCTION_BOILERPLATE, PARAMETER_TYPE } from '@/modules/ex-generic/constants';
import { parameterType } from '@/modules/ex-generic/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import fromJSOrdered from '@/utils/fromJSOrdered';
import string from '@/utils/string';
import { isValidJsonConfig } from '@/utils/validation';
import ParameterTypeInput from './ParameterTypeInput';

const NewUserData = (props: {
  show: boolean;
  parameters: Map<string, any>;
  onSave: (parameters: Map<string, any>, changeDescription: string) => Promise<any>;
  onHide: () => void;
  existingParameter?: string | null;
}) => {
  const [formData, setFormData] = React.useState<Map<string, any>>(Map());
  const [isSaving, setSaving] = React.useState(false);

  const isEditing = !!props.existingParameter;
  const name = formData.get('name', '') as string;

  const isNameValid = (name: string) => {
    const alreadyUsed = props.parameters
      .getIn(['config', 'userData'], Map())
      .some((value: PARAM_VALUE, key: string) => {
        if (key === props.existingParameter) {
          return false;
        }

        return name === key;
      });

    if (alreadyUsed) {
      return 'User data with the same name already exists.';
    }

    return false;
  };

  const showError = props.show && isNameValid(name);

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSaving(true);
    const type = formData.get('type', PARAMETER_TYPE.STRING) as VALUE_TYPE;
    const value =
      type === PARAMETER_TYPE.FUNCTION
        ? fromJSOrdered(JSON.parse(formData.get('value') as string))
        : type === PARAMETER_TYPE.NUMBER
          ? Number(formData.get('value'))
          : formData.get('value');

    const parameters = !props.existingParameter
      ? props.parameters.setIn(['config', 'userData', name], value)
      : props.parameters
          .deleteIn(['config', 'userData', props.existingParameter])
          .setIn(['config', 'userData', name], value);

    return props
      .onSave(parameters, `${isEditing ? 'Update' : 'Add'} user data`)
      .then(props.onHide)
      .finally(() => setSaving(false));
  };

  const isDisabled = () => {
    if (!name || !formData.has('value') || showError) {
      return true;
    }

    if (formData.get('type') === PARAMETER_TYPE.FUNCTION) {
      return !isValidJsonConfig(formData.get('value'));
    }

    return false;
  };

  const fieldNameState = showError ? 'error' : 'default';
  const fieldNameMessages: Partial<Record<ValidityState, ReactNode>> = {
    error: showError,
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onEnter={() => {
        if (!props.existingParameter) {
          return setFormData(Map({ name: '' }));
        }

        const value = props.parameters.getIn(['config', 'userData', props.existingParameter]);

        return setFormData(
          Map({
            name: props.existingParameter,
            type: parameterType(value, props.existingParameter),
            value: Map.isMap(value) ? JSON.stringify(value, null, 2) : value,
          }),
        );
      }}
    >
      <form onSubmit={handleSave}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? 'Update' : 'New'} User Data</Modal.Title>
          {isEditing ? <ModalIcon.Edit /> : <ModalIcon.Plus />}
        </Modal.Header>

        <Modal.Body>
          <FormGroup className="tw-mb-4" state={fieldNameState}>
            <Label htmlFor="name">Name</Label>
            <FormGroup.TextInput
              autoFocus={!isEditing}
              value={name}
              variant="secondary"
              onChange={(value) => {
                const name = string.sanitizeDiacritics(value);
                setFormData(formData.set('name', name));
              }}
            />
            <FormGroup.Help>{fieldNameMessages[fieldNameState]}</FormGroup.Help>
          </FormGroup>

          <FormGroup>
            <Label htmlFor="type">Type</Label>
            <Select
              id="type"
              value={formData.get('type', PARAMETER_TYPE.STRING)}
              onChange={(value: string) => {
                const newFormData = formData.withMutations((data) => {
                  data.set('type', value);

                  value === PARAMETER_TYPE.FUNCTION
                    ? data.set('value', NEW_FUNCTION_BOILERPLATE)
                    : data.delete('value');
                });

                setFormData(newFormData);
              }}
              options={[
                { label: 'String', value: PARAMETER_TYPE.STRING },
                { label: 'Number', value: PARAMETER_TYPE.NUMBER },
                { label: 'Boolean', value: PARAMETER_TYPE.BOOLEAN },
                { label: 'Function', value: PARAMETER_TYPE.FUNCTION },
              ]}
            />
          </FormGroup>
          <ParameterTypeInput
            autoFocus={isEditing}
            type={formData.get('type', PARAMETER_TYPE.STRING)}
            value={formData.get('value', '')}
            onChange={(value) => setFormData(formData.set('value', value))}
            parameters={props.parameters}
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            showCancel={false}
            isSaving={isSaving}
            saveButtonType="submit"
            saveLabel={`${isEditing ? 'Update' : 'Create'} User Data`}
            isDisabled={isDisabled()}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default NewUserData;
