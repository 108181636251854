import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { routeNames as componentRouteNames } from '@/modules/components-directory/constants';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import { supportConfigRows } from '@/modules/ex-db-generic/helpers';
import { LOADING_COLUMNS_PATH } from '@/modules/ex-db-generic/storeProvisioning';
import LinkMenuItem from '@/react/admin/project/LinkMenuItem';
import { RowActionDropdown, RowActionMenuItem } from '@/react/common';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import SaveButtons from '@/react/common/SaveButtons';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import QueryDeleteButton from './QueryDeleteButton';

const QueryActionButtons = function (componentId, actionsProvisioning, storeProvisioning) {
  const actionCreators = actionsProvisioning.createActions(componentId);

  return createReactClass({
    mixins: [
      createStoreMixin(
        RoutesStore,
        ApplicationStore,
        InstalledComponentsStore,
        ConfigurationRowsStore,
      ),
    ],

    getStateFromStores() {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const ExDbStore = storeProvisioning.createStore(componentId, configId);
      const isRowConfiguration = ExDbStore.isRowConfiguration();
      const queryId = RoutesStore.getCurrentRouteParam('query');
      const query = ExDbStore.getConfigQuery(queryId);
      const migrateOnly = supportConfigRows(componentId) && !isRowConfiguration;
      const savedOrNewQuery = ExDbStore.getSavedOrNewQuery(queryId);
      const isQueryChanged = !query.get('advancedMode')
        ? !savedOrNewQuery.equals(query.delete('advancedMode'))
        : !savedOrNewQuery.equals(query);

      return {
        configId,
        queryId,
        query,
        isQueryChanged,
        isRowConfiguration,
        pendingActions: ExDbStore.getQueriesPendingActions().get(queryId, Map()),
        isSaving: ExDbStore.isSavingQuery(queryId),
        isValid: ExDbStore.isEditingQueryValid(queryId) && !ExDbStore.queryNameExists(query),
        localState: ExDbStore.getLocalState(),
        readOnly: ApplicationStore.isReadOnly() || migrateOnly,
        isProcessorsChanged: !ExDbStore.getSavedRowProcessors(queryId).equals(
          ExDbStore.getRowProcessors(queryId),
        ),
      };
    },

    render() {
      return (
        <>
          {!this.state.readOnly && (
            <>
              <CatchUnsavedChanges
                isDirty={this.state.isQueryChanged || this.state.isProcessorsChanged}
                onSave={this.handleSave}
                isSaveDisabled={!this.state.isValid}
                onDirtyLeave={this.handleReset}
              >
                <SaveButtons
                  className="tw-mr-3"
                  isSaving={this.state.localState.getIn(['isSaving', this.state.queryId], false)}
                  isChanged={this.state.isQueryChanged || this.state.isProcessorsChanged}
                  onReset={this.handleReset}
                  onSave={this.handleSave}
                  disabled={
                    this.state.localState.getIn(['isSaving', this.state.queryId], false) ||
                    this.state.localState.getIn(LOADING_COLUMNS_PATH, false) ||
                    !this.state.isValid
                  }
                />
              </CatchUnsavedChanges>
            </>
          )}
          <RunComponentButton
            label="Run"
            buttonBsStyle="success"
            tooltipPlacement="bottom"
            componentId={componentId}
            runParams={this.runParams}
            config={this.state.configId}
            disabled={this.state.isQueryChanged}
          >
            You are about to run an extraction.
          </RunComponentButton>
          {!this.state.readOnly && (
            <RowActionDropdown inHeader>
              <ActivateDeactivateSwitch
                mode="menuitem"
                isActive={this.state.query.get('enabled')}
                isPending={this.state.pendingActions.get('enabled')}
                onChange={this.handleActiveChange}
                buttonDisabled={this.state.isQueryChanged}
              />
              <LinkMenuItem
                to={
                  this.state.isRowConfiguration
                    ? componentRouteNames.GENERIC_CONFIG_ROW_RAW
                    : componentRouteNames.GENERIC_CONFIG_RAW
                }
                params={{
                  component: componentId,
                  config: this.state.configId,
                  row: this.state.queryId,
                }}
              >
                <FontAwesomeIcon icon="bug" className="icon-addon-right" fixedWidth />
                Debug mode
              </LinkMenuItem>
              <RowActionMenuItem divider />
              <QueryDeleteButton
                componentId={componentId}
                query={this.state.query}
                configurationId={this.state.configId}
                isPending={!!this.state.pendingActions.get('deleteQuery')}
                tooltipPlacement="bottom"
                actionsProvisioning={actionsProvisioning}
              />
            </RowActionDropdown>
          )}
        </>
      );
    },

    runParams() {
      return actionCreators.prepareSingleQueryRunData(
        this.state.configId,
        this.state.query,
        'detail',
      );
    },

    handleActiveChange(newValue) {
      actionCreators.changeQueryEnabledState(this.state.configId, this.state.queryId, newValue);
    },

    handleReset() {
      return actionCreators.resetQueryEdit(this.state.configId, this.state.queryId);
    },

    handleSave() {
      return actionCreators.saveQueryEdit(this.state.configId, this.state.queryId);
    },
  });
};

export default QueryActionButtons;
