import React from 'react';

import { cn } from '@keboola/design';

import { CreatedDate, Truncated } from '@/react/common';

const InternalDataAppJob = (props: { time?: string }) => {
  return (
    <div className="tw-inline-flex tw-flex-col">
      {props.time && <CreatedDate createdTime={props.time} absolute />}
      <Truncated
        text="Internal Data App run"
        className={cn({ 'tw-text-neutral-400': props.time })}
      />
    </div>
  );
};

export default InternalDataAppJob;
