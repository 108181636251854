import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type ChosenColumn = { nodeId: string; column: string };

type ColumnsStore = {
  chosenColumn: ChosenColumn | null;
  setChosenColumn: (column: ChosenColumn | null) => void;
  highlightedColumns: ChosenColumn[];
  setHighlightedColumns: (highlightedColumns: ChosenColumn[]) => void;
  highlightedEdges: string[];
  setHighlightedEdges: (highlightedEdges: string[]) => void;
  shouldResetColumnsHighlight: boolean;
  setShouldResetColumnsHighlight: (shouldResortColumns: boolean) => void;
  nodeIdManipulating: string | null;
  setNodeIdManipulating: (nodeId: string | null) => void;
};

export const useColumnsStore = create<ColumnsStore>()(
  devtools(
    (set) => ({
      chosenColumn: null,
      shouldResetColumnsHighlight: false,
      highlightedColumns: [],
      highlightedEdges: [],
      nodeIdManipulating: null,
      setChosenColumn: (chosenColumn) => set(() => ({ chosenColumn }), void 0, 'setChosenColumn'),
      setHighlightedColumns: (highlightedColumns) =>
        set(() => ({ highlightedColumns }), void 0, 'setHighlightedColumns'),
      setHighlightedEdges: (highlightedEdges) =>
        set(() => ({ highlightedEdges }), void 0, 'setHighlightedEdges'),
      setShouldResetColumnsHighlight: (shouldResetColumnsHighlight) =>
        set(() => ({ shouldResetColumnsHighlight }), void 0, 'setShouldResetColumnsHighlight'),
      setNodeIdManipulating: (nodeIdManipulating) =>
        set(() => ({ nodeIdManipulating }), void 0, 'setNodeIdManipulating'),
    }),
    { name: 'Lineage columns' },
  ),
);
