import React from 'react';

import { IconButton, Tooltip } from '@keboola/design';

type Props = {
  onOpen: () => void;
  onDelete: () => void;
  onExploreData?: () => void;
  hasData: boolean;
};

const TaskActions = (props: Props) => {
  return (
    <span className="actions task-actions" onMouseDown={(e) => e.stopPropagation()}>
      <Tooltip placement="top" tooltip="Delete Task">
        <IconButton
          variant="inline"
          className="circle-button tw-flex"
          onClick={props.onDelete}
          icon="trash"
        />
      </Tooltip>
      {props.onExploreData && props.hasData && (
        <Tooltip placement="top" tooltip="Explore Data">
          <IconButton
            variant="inline"
            className="circle-button tw-flex"
            onClick={props.onExploreData}
            icon="warehouse"
          />
        </Tooltip>
      )}
      <Tooltip placement="top" tooltip="Show Details">
        <IconButton
          variant="inline"
          className="circle-button tw-flex"
          onClick={props.onOpen}
          icon="gear"
        />
      </Tooltip>
    </span>
  );
};

export default TaskActions;
