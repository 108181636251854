import React from 'react';

import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import InstalledComponentsActionsCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentNameEdit from '@/modules/components/react/components/ComponentName';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import createVersionsPageRoute from '@/modules/components/utils/createVersionsPageRoute';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import { configPoll } from '@/utils/genericPolls';
import * as DataAppsActions from './actions';
import { routeNames } from './constants';
import Detail from './Detail';
import DetailStatusLabel from './DetailStatusLabel';
import Header from './Header';
import Index from './Index';
import RedeployInfoAlert from './RedeployInfoAlert';

const loadDataApp = (params) => {
  const appId = InstalledComponentsStore.getConfigData(KEBOOLA_DATA_APPS, params.config).getIn([
    'parameters',
    'id',
  ]);

  if (appId) {
    return DataAppsActions.loadDataApp(appId);
  }
};

const routes = {
  name: routeNames.ROOT,
  title: 'Data Apps',
  defaultRouteHandler: Index,
  headerButtonsHandler: Header,
  requireData: [
    (params) => {
      if (params.config) {
        return Promise.resolve();
      }

      return Promise.all([
        DataAppsActions.loadDataApps(),
        InstalledComponentsActionsCreators.loadComponentConfigsData(KEBOOLA_DATA_APPS),
        InstalledComponentsActionsCreators.loadComponentsMetadata(),
      ]);
    },
  ],
  poll: {
    skipFirst: true,
    action: () => {
      return Promise.all([
        DataAppsActions.loadDataApps(),
        InstalledComponentsActionsCreators.loadComponentConfigsDataForce(KEBOOLA_DATA_APPS),
      ]);
    },
  },
  childRoutes: [
    {
      name: routeNames.DATA_APP_DETAIL,
      path: ':config',
      title: (routerState) => {
        return InstalledComponentsStore.getConfig(
          KEBOOLA_DATA_APPS,
          routerState.getIn(['params', 'config']),
        ).get('name', 'Data App');
      },
      nameEdit(params) {
        return (
          <ComponentNameEdit
            key={`${KEBOOLA_DATA_APPS}-${params.config}`}
            componentId={KEBOOLA_DATA_APPS}
            configId={params.config}
          />
        );
      },
      defaultRouteHandler: Detail,
      labelHandler: DetailStatusLabel,
      infoAlertHandler: RedeployInfoAlert,
      poll: configPoll(KEBOOLA_DATA_APPS, loadDataApp),
      requireData: [
        () => StorageActionCreators.loadBucketsAndTables(),
        (params) => {
          return InstalledComponentsActionsCreators.loadComponentConfigData(
            KEBOOLA_DATA_APPS,
            params.config,
          ).then(() => loadDataApp(params));
        },
        (params) => VersionsActionCreators.loadVersions(KEBOOLA_DATA_APPS, params.config),
      ],
      childRoutes: [
        {
          ...createVersionsPageRoute(KEBOOLA_DATA_APPS, 'config', routeNames.DATA_APP_VERSIONS),
        },
      ],
    },
  ],
};

export default routes;
